import React, { useEffect } from "react";
import { gsap } from "gsap";

export default function Footer(props) {
  const isDesctop = props.isDesctop;

  useEffect(() => {
     gsap.timeline({defaults: {duration: 2}, repeat: -1})
        .to('#Jaar-2022',
          {  y: '-11px',}        
        )
        .to('#Jaar-2022',
          { y: 0 }        
        )
       
     gsap.timeline({repeat:-1})
        .to('#Paraplu',
          { duration: 3,  transformOrigin: '80% 80%',  rotation:"+=8", ease:'none'}        
        )
        .to('#Paraplu',
          { duration: 3,  transformOrigin: '80% 80%',  rotation:"-=8", ease:'none'}      
        ) 

  },[]);
  useEffect(() => {
    gsap.timeline({defaults: {duration: 2}, repeat: -1})
        .to('#Jaar-2022-m',
          {  y: '-11px',}        
        )
        .to('#Jaar-2022-m',
          { y: 0 }        
        )
    gsap.timeline({repeat:-1})
        .to('#Paraplu-m',
          { duration: 3,  transformOrigin: '100% 100%',  rotation:"+=8", ease:'none'}        
        )
        .to('#Paraplu-m',
          { duration: 3,  transformOrigin: '100% 100%',  rotation:"-=8", ease:'none'}      
        ) 

  },[]);

 

  if (isDesctop) {
    return (
        <>
          <g id="Balk en Payoff">
            <rect id="Balk" class="cls-23" x="0" y="5082" width="960" height="248.27"></rect>
          </g>
          <text class="cls-42" transform="translate(255.859 5142)"><tspan x="0" y="0">CJG Rijnmond. Ondersteuning bij de</tspan><tspan x="-5.7" y="28.8">grootste opgave in een mensenleven.</tspan></text>
          
          <g id="Jaar">
          <g id="Kinderwagen">
          <g id="Jaar-2022">
          <text class="cls-29" transform="translate(322.453 5005.92)"><tspan class="cls-20" x="0" y="0">2</tspan><tspan class="cls-35" x="35.83" y="0">0</tspan><tspan class="cls-34" x="72.05" y="0">2</tspan><tspan class="cls-34" x="108.67" y="0">2</tspan></text>

          </g>
          <path id="Vector_3" d="M495.36 5011.84L492.72 5038.27C492.72 5045.57 486.8 5051.49 479.5 5051.49H330.28C322.98 5051.49 317.06 5045.57 317.06 5038.27L314.42 5011.84H495.36Z" fill="#FEEBD6"/>
          <path id="Vector_4" d="M479.501 5051.49H330.281C322.981 5051.49 317.061 5045.57 317.061 5038.27H492.711C492.711 5045.57 486.791 5051.49 479.491 5051.49H479.501Z" fill="#F99D31"/>
          <path id="Vector_5" d="M495.37 5005.23H314.42C312.962 5005.23 311.78 5006.41 311.78 5007.87V5009.2C311.78 5010.66 312.962 5011.84 314.42 5011.84H495.37C496.828 5011.84 498.01 5010.66 498.01 5009.2V5007.87C498.01 5006.41 496.828 5005.23 495.37 5005.23Z" fill="#F99D31"/>
          <path id="Vector_6" d="M493.6 4984.29L438.17 5006.11L488.42 4970.99L493.6 4984.29Z" fill="#F99D31"/>
          <path id="Vector_7" d="M371.501 5034.41L315.391 4987.44L319.121 4983.7L448.171 5089.03V5094.32H318.121V5089.02L371.491 5034.41H371.501ZM376.551 5038.28L365.221 5049.38L389.911 5049.65L376.551 5038.28ZM359.931 5054.67L340.631 5073.97H419.771L394.651 5054.18L359.921 5054.67H359.931ZM440.741 5089.03L430.961 5079.25H335.351L325.581 5089.02H440.741V5089.03Z" fill="#CCE9FC"/>
          <path id="Vector_8" d="M376.54 5041.88C380.19 5041.88 383.15 5038.92 383.15 5035.27C383.15 5031.62 380.19 5028.66 376.54 5028.66C372.889 5028.66 369.93 5031.62 369.93 5035.27C369.93 5038.92 372.889 5041.88 376.54 5041.88Z" fill="#00A0AF"/>
          <g id="Group">
          <path id="Vector_9" d="M425.181 5089.03H439.851L429.481 5078.65L431.721 5076.41L442.101 5086.78V5072.11H445.271V5086.78L455.641 5076.41L457.891 5078.65L447.511 5089.03H462.191V5092.2H447.511L457.891 5102.57L455.641 5104.82L445.271 5094.44V5109.11H442.101V5094.44L431.721 5104.82L429.481 5102.57L439.851 5092.2H425.181V5089.03Z" fill="#CCE9FC"/>
          <path id="Vector_10" d="M443.68 5097.22C447.331 5097.22 450.29 5094.26 450.29 5090.61C450.29 5086.96 447.331 5084 443.68 5084C440.03 5084 437.07 5086.96 437.07 5090.61C437.07 5094.26 440.03 5097.22 443.68 5097.22Z" fill="#00A0AF"/>
          <path id="Vector_11" d="M443.68 5070.79C454.63 5070.79 463.5 5079.67 463.5 5090.61C463.5 5101.55 454.62 5110.43 443.68 5110.43C432.74 5110.43 423.86 5101.55 423.86 5090.61C423.86 5079.67 432.74 5070.79 443.68 5070.79ZM443.68 5103.83C450.98 5103.83 456.9 5097.91 456.9 5090.61C456.9 5083.31 450.98 5077.39 443.68 5077.39C436.38 5077.39 430.46 5083.31 430.46 5090.61C430.46 5097.91 436.38 5103.83 443.68 5103.83Z" fill="#00A0AF"/>
          <path id="Vector_12" d="M443.771 5093.25C445.229 5093.25 446.411 5092.07 446.411 5090.61C446.411 5089.15 445.229 5087.97 443.771 5087.97C442.313 5087.97 441.131 5089.15 441.131 5090.61C441.131 5092.07 442.313 5093.25 443.771 5093.25Z" fill="#CCE9FC"/>
          <path id="Vector_13" d="M427.96 5090.56C427.96 5081.8 435.06 5074.7 443.82 5074.7C452.58 5074.7 459.68 5081.8 459.68 5090.56C459.68 5099.32 452.58 5106.42 443.82 5106.42C435.06 5106.42 427.96 5099.32 427.96 5090.56ZM443.73 5103.86C451.03 5103.86 456.95 5097.94 456.95 5090.64C456.95 5083.34 451.03 5077.42 443.73 5077.42C436.43 5077.42 430.51 5083.34 430.51 5090.64C430.51 5097.94 436.43 5103.86 443.73 5103.86Z" fill="#CCE9FC"/>
          </g>
          <g id="Group_2">
          <path id="Vector_14" d="M304.109 5089.03H318.789L308.409 5078.65L310.659 5076.41L321.029 5086.78V5072.11H324.199V5086.78L334.579 5076.41L336.819 5078.65L326.449 5089.03H341.119V5092.2H326.449L336.819 5102.57L334.579 5104.82L324.199 5094.44V5109.11H321.029V5094.44L310.659 5104.82L308.409 5102.57L318.789 5092.2H304.109V5089.03Z" fill="#CCE9FC"/>
          <path id="Vector_15" d="M322.62 5097.22C326.27 5097.22 329.23 5094.26 329.23 5090.61C329.23 5086.96 326.27 5084 322.62 5084C318.969 5084 316.01 5086.96 316.01 5090.61C316.01 5094.26 318.969 5097.22 322.62 5097.22Z" fill="#00A0AF"/>
          <path id="Vector_16" d="M322.62 5070.79C333.57 5070.79 342.44 5079.67 342.44 5090.61C342.44 5101.55 333.56 5110.43 322.62 5110.43C311.68 5110.43 302.8 5101.55 302.8 5090.61C302.8 5079.67 311.68 5070.79 322.62 5070.79ZM322.62 5103.83C329.92 5103.83 335.84 5097.91 335.84 5090.61C335.84 5083.31 329.92 5077.39 322.62 5077.39C315.32 5077.39 309.4 5083.31 309.4 5090.61C309.4 5097.91 315.32 5103.83 322.62 5103.83Z" fill="#00A0AF"/>
          <path id="Vector_17" d="M322.71 5093.25C324.168 5093.25 325.35 5092.07 325.35 5090.61C325.35 5089.15 324.168 5087.97 322.71 5087.97C321.252 5087.97 320.07 5089.15 320.07 5090.61C320.07 5092.07 321.252 5093.25 322.71 5093.25Z" fill="#CCE9FC"/>
          <path id="Vector_18" d="M306.82 5090.59C306.82 5081.83 313.92 5074.73 322.68 5074.73C331.44 5074.73 338.54 5081.83 338.54 5090.59C338.54 5099.35 331.44 5106.45 322.68 5106.45C313.92 5106.45 306.82 5099.35 306.82 5090.59ZM322.59 5103.89C329.89 5103.89 335.81 5097.97 335.81 5090.67C335.81 5083.37 329.89 5077.45 322.59 5077.45C315.29 5077.45 309.37 5083.37 309.37 5090.67C309.37 5097.97 315.29 5103.89 322.59 5103.89Z" fill="#CCE9FC"/>
          </g>
          <path id="Vector_19" d="M285.41 4978.04L319.15 4989.21L320.81 4984.18L287.06 4973.01L285.41 4978.04Z" fill="#CCE9FC"/>
          <path id="Vector_20" d="M319.9 4992.72C322.822 4992.72 325.19 4990.35 325.19 4987.43C325.19 4984.51 322.822 4982.14 319.9 4982.14C316.979 4982.14 314.61 4984.51 314.61 4987.43C314.61 4990.35 316.979 4992.72 319.9 4992.72Z" fill="#00A0AF"/>
          <path id="Vector_21" d="M376.67 5038.52C378.493 5038.52 379.97 5037.04 379.97 5035.22C379.97 5033.4 378.493 5031.92 376.67 5031.92C374.848 5031.92 373.37 5033.4 373.37 5035.22C373.37 5037.04 374.848 5038.52 376.67 5038.52Z" fill="#CCE9FC"/>
          <path id="Vector_22" d="M319.9 4990.07C321.358 4990.07 322.54 4988.89 322.54 4987.43C322.54 4985.97 321.358 4984.79 319.9 4984.79C318.442 4984.79 317.26 4985.97 317.26 4987.43C317.26 4988.89 318.442 4990.07 319.9 4990.07Z" fill="#CCE9FC"/>
          <path id="Vector_23" d="M492.721 4984.64C494.451 4991.07 495.361 4997.96 495.361 5005.23H439.521" fill="#FEEBD6"/>
          </g>
          <g id="Vrouw">
          <path id="Vector_24" d="M262.331 4999.93L256.671 4970.35L220.831 4968.96L212.811 4993.5C214.731 5026.66 223.671 5062.98 227.811 5092.13H234.491L239.771 5000.96L237.641 5090.04H244.681C252.101 5060.08 259.521 5031.22 262.331 4999.92V4999.93Z" fill="#33A9F2"/>
          <path id="Vector_25" d="M175.5 4959.29L179.13 4956.16C180.07 4955.35 181.04 4954.61 182.03 4953.92L180.39 4950.98L173.93 4940.65C172.59 4938.51 170.08 4937.39 167.59 4937.84L166.09 4938.11C164.1 4938.47 163.23 4940.84 164.52 4942.4L172.74 4951.08L175.49 4959.29H175.5Z" fill="#7558A7"/>
          <path id="Vector_26" d="M295.03 4974.79L289.62 4973.81C288.84 4975.87 287.79 4977.84 286.45 4979.7L292.21 4980.16C292.72 4981.24 293.7 4982.14 294.87 4982.34C294.07 4984.51 296.54 4986.8 298.66 4986.84C301.21 4986.88 303.04 4984.68 303.64 4982.39C304.2 4980.23 303.69 4977.83 302.06 4976.26C300.3 4974.56 297.99 4974.58 295.72 4974.71C295.48 4974.72 295.25 4974.76 295.03 4974.8V4974.79Z" fill="#7558A7"/>
          <path id="Vector_27" d="M180.64 4950.6L198.45 4965.6L206.46 4937.55C208.9 4931.63 211.12 4925.82 217.07 4923.8C217.84 4923.54 218.6 4923.35 219.31 4923.26L238.28 4920.8L255.37 4923.3C258.53 4923.97 262.74 4928.15 263.45 4931.3L271.63 4956.12L291.17 4973.67L288.54 4981.91L263.85 4965.11L255.03 4950.75L261.36 4989.27L212.57 4988.49L217.8 4945.44C217.8 4945.44 211.63 4973.86 206.19 4978.98C204.68 4980.4 200.62 4980.5 198.57 4980.22C188.75 4978.9 172.51 4955.02 172.51 4955.02L180.67 4950.58L180.64 4950.6Z" fill="#7BC143"/>
          <path id="Vector_28" d="M232.84 4929.81C232.84 4934.39 245.27 4934.39 245.27 4929.81V4917.93H232.84V4929.81Z" fill="#7558A8"/>
          <path id="Vector_29" d="M256.25 4891.84C255.35 4895.2 256.25 4898.36 258.24 4898.9C260.24 4899.43 262.59 4897.14 263.49 4893.78C264.39 4890.42 263.49 4887.26 261.5 4886.72C259.5 4886.19 257.15 4888.48 256.25 4891.84Z" fill="#EC008C"/>
          <path id="Vector_30" d="M219.961 4891.84C220.861 4895.2 219.961 4898.36 217.971 4898.9C215.971 4899.43 213.621 4897.14 212.721 4893.78C211.821 4890.42 212.721 4887.26 214.711 4886.72C216.711 4886.19 219.061 4888.48 219.961 4891.84Z" fill="#EC008C"/>
          <path id="Vector_31" d="M233.38 4922.66C233.77 4922.76 234.17 4922.85 234.56 4922.93C245.52 4925.18 255.87 4916.96 256.66 4905.8L258.13 4887.78C258.13 4877.05 249.6 4868.13 238.87 4867.96C238.48 4867.96 238.08 4867.96 237.69 4867.96C226.9 4867.97 218.35 4876.7 218.55 4887.48C218.69 4894.71 219.25 4899.91 219.77 4906.16C220.42 4913.97 225.8 4920.7 233.38 4922.67V4922.66Z" fill="#7558A7"/>
          <path id="Vector_32" d="M228.91 4869.51C214.25 4864.7 207.09 4887.3 209.97 4897.75C212.19 4905.79 217.6 4910.6 223.44 4915.99C220.05 4903.98 220.41 4888.25 229.48 4878.71C231.87 4876.19 234.83 4878.31 237.62 4879.67C240.36 4881 242.92 4882.78 245.21 4884.77C249.96 4888.89 253.68 4894.21 255.79 4900.14C256.97 4903.44 256.43 4907.72 255.49 4911.1C260.64 4904.84 267.34 4896.86 266.19 4888.84C265.82 4886.27 264.78 4883.85 263.68 4881.5C258.42 4870.23 249.37 4864.78 236.92 4865.13C233.7 4865.22 230.72 4866.85 228.89 4869.5L228.91 4869.51Z" fill="#522E91"/>
          <g id="Group_3">
          <path id="Vector_33" d="M227.85 5091.09C227.36 5091.69 226.92 5092.32 226.48 5092.88L225.06 5094.51C224.99 5094.59 224.94 5094.68 224.89 5094.77C223.5 5096.33 222.02 5097.83 220.58 5099.34C220.23 5099.7 219.88 5100.11 219.6 5100.54C219.21 5101.13 218.98 5101.78 219.13 5102.46C219.31 5103.25 220.02 5103.85 220.79 5104.1C221.56 5104.35 222.4 5104.31 223.21 5104.22C224.98 5104.02 226.75 5103.56 228.28 5102.64C230.56 5101.27 231.07 5098.53 233.06 5097.16C233.39 5098.16 234.61 5098.89 235.33 5097.53C236.28 5095.74 236.08 5093 234.93 5091.09H227.85Z" fill="#A54499"/>
          <path id="Vector_34" d="M258.151 5099.67C256.061 5096.94 252.251 5096.31 249.331 5094.5C248.811 5094.18 248.331 5093.81 247.881 5093.43C247.781 5093.34 247.691 5093.24 247.591 5093.15C247.251 5092.84 246.921 5092.52 246.621 5092.18C245.921 5091.4 245.311 5090.56 244.781 5089.7H237.591C236.991 5091.16 237.021 5093.01 237.361 5094.37C237.621 5095.4 238.151 5096.37 238.941 5097.09C239.621 5097.71 241.191 5098.35 241.981 5097.52C242.241 5097.24 242.201 5096.81 242.141 5096.43C244.181 5097.73 246.301 5099.1 248.501 5100.1C250.501 5101.01 252.721 5101.43 254.921 5101.41C255.731 5101.41 256.541 5101.33 257.331 5101.21C257.811 5101.13 258.411 5100.93 258.461 5100.44C258.491 5100.16 258.331 5099.91 258.161 5099.69L258.151 5099.67Z" fill="#A54499"/>
          </g>
          <path id="Vector_35" d="M235.49 4864.25C235.49 4865.1 235.35 4865.94 235.1 4866.74C234.35 4869.14 232.51 4871.17 230.22 4872.19C228.77 4872.84 227.17 4873.09 225.59 4873.26C222.97 4873.53 219.89 4873.38 218.29 4871.28C216.36 4872.79 213.29 4872.18 211.73 4870.28C210.17 4868.39 210.03 4865.59 210.94 4863.32C211.85 4861.05 213.67 4859.24 215.69 4857.85C219.21 4855.43 223.68 4854.12 227.82 4855.16C231.96 4856.2 235.51 4859.97 235.49 4864.24V4864.25Z" fill="#522E91"/>
          <path id="Vector_36" d="M239.521 4935.88C240.481 4936.09 241.481 4936.25 242.431 4935.99C243.391 4935.73 244.181 4935.07 244.891 4934.37C248.071 4931.23 250.091 4926.92 250.461 4922.47C248.861 4921.42 246.861 4921 244.981 4921.32C245.051 4924.9 243.641 4928.49 241.161 4931.06C237.671 4928.86 234.701 4925.83 232.561 4922.3C229.321 4923.11 226.081 4923.92 222.851 4924.74C226.761 4930.35 232.841 4934.41 239.531 4935.88H239.521Z" fill="#7558A7"/>
          <path id="Vector_37" d="M214.18 4893.39C214.79 4893.23 215.43 4893.35 216.04 4893.69V4904.62C214.51 4904.22 213 4902.38 212.34 4899.9C211.51 4896.79 212.34 4893.87 214.18 4893.38V4893.39Z" fill="#7558A7"/>
          </g>
          <g id="Paraplu">
          <g id="Group_4">
          <path id="Vector_38" d="M158.66 4857.09L178.78 4981.74L173.08 4984.27L152.63 4859.52L158.66 4857.09Z" fill="#A54499"/>
          <path id="Vector_39" d="M138.53 4809.39L138.96 4794L149.31 4807.58L138.53 4809.39Z" fill="#A54499"/>
          <path id="Vector_40" d="M160.98 4862.9L205.61 4858.58L245.61 4838.89C245.61 4838.89 212.44 4799.96 150.17 4807.43C87.89 4814.9 55 4849.42 55 4849.42L94.08 4867.32L160.97 4862.89L160.98 4862.9Z" fill="#522E91"/>
          </g>
          <g id="Group_5">
          <path id="Vector_41" d="M138.73 4836.6C138.55 4836.41 138.33 4836.27 138.07 4836.19C137.81 4836.11 137.55 4836.08 137.29 4836.11C136.97 4836.15 136.69 4836.24 136.44 4836.39C136.2 4836.54 135.99 4836.73 135.83 4836.96C135.67 4837.19 135.55 4837.45 135.48 4837.74C135.41 4838.03 135.39 4838.34 135.43 4838.65C135.46 4838.95 135.54 4839.22 135.67 4839.48C135.8 4839.74 135.96 4839.95 136.16 4840.14C136.36 4840.32 136.6 4840.46 136.88 4840.54C137.15 4840.63 137.46 4840.65 137.79 4840.62C138.12 4840.59 138.39 4840.48 138.62 4840.32C138.85 4840.16 139.04 4839.95 139.18 4839.69L139.88 4840.1C139.84 4840.16 139.78 4840.26 139.69 4840.38C139.6 4840.5 139.47 4840.63 139.3 4840.76C139.14 4840.89 138.94 4841.01 138.7 4841.12C138.46 4841.23 138.18 4841.3 137.86 4841.34C137.41 4841.39 137 4841.35 136.62 4841.22C136.24 4841.09 135.91 4840.9 135.63 4840.65C135.35 4840.4 135.12 4840.11 134.95 4839.78C134.78 4839.45 134.67 4839.1 134.63 4838.74C134.58 4838.3 134.61 4837.89 134.71 4837.51C134.81 4837.12 134.98 4836.78 135.2 4836.48C135.43 4836.18 135.71 4835.94 136.06 4835.75C136.4 4835.56 136.8 4835.44 137.23 4835.39C137.6 4835.35 137.98 4835.38 138.35 4835.48C138.73 4835.58 139.04 4835.77 139.31 4836.04L138.73 4836.6Z" fill="white"/>
          <path id="Vector_42" d="M142.15 4834.98L142.92 4834.89L143.37 4838.93C143.41 4839.29 143.39 4839.58 143.3 4839.82C143.21 4840.06 143.09 4840.25 142.94 4840.4C142.79 4840.55 142.62 4840.66 142.43 4840.73C142.24 4840.8 142.07 4840.85 141.91 4840.86C141.51 4840.91 141.16 4840.84 140.87 4840.67C140.57 4840.5 140.36 4840.21 140.24 4839.8L140.97 4839.55C141.04 4839.76 141.14 4839.92 141.29 4840.02C141.44 4840.13 141.62 4840.17 141.83 4840.15C142.02 4840.13 142.17 4840.07 142.28 4839.99C142.39 4839.9 142.46 4839.8 142.51 4839.67C142.56 4839.54 142.59 4839.4 142.59 4839.24C142.59 4839.08 142.59 4838.92 142.57 4838.77L142.14 4834.98H142.15Z" fill="white"/>
          <path id="Vector_43" d="M149.25 4836.7L149.56 4839.49C149.43 4839.59 149.28 4839.68 149.11 4839.77C148.94 4839.85 148.77 4839.93 148.58 4840C148.4 4840.07 148.21 4840.12 148.02 4840.16C147.83 4840.2 147.65 4840.23 147.48 4840.25C147 4840.3 146.56 4840.27 146.17 4840.15C145.78 4840.03 145.44 4839.85 145.15 4839.61C144.86 4839.37 144.63 4839.07 144.45 4838.73C144.27 4838.39 144.16 4838.01 144.12 4837.62C144.07 4837.2 144.1 4836.8 144.21 4836.42C144.32 4836.04 144.48 4835.71 144.71 4835.41C144.94 4835.11 145.22 4834.87 145.57 4834.68C145.91 4834.49 146.3 4834.37 146.73 4834.32C147.25 4834.26 147.69 4834.28 148.03 4834.37C148.38 4834.46 148.67 4834.61 148.92 4834.82L148.44 4835.46C148.18 4835.26 147.91 4835.13 147.64 4835.08C147.37 4835.02 147.09 4835.01 146.8 4835.04C146.48 4835.08 146.19 4835.17 145.94 4835.32C145.69 4835.47 145.49 4835.66 145.32 4835.88C145.16 4836.1 145.04 4836.36 144.97 4836.64C144.9 4836.93 144.88 4837.22 144.92 4837.53C144.96 4837.84 145.04 4838.14 145.19 4838.4C145.33 4838.66 145.51 4838.88 145.73 4839.06C145.95 4839.24 146.2 4839.37 146.48 4839.46C146.76 4839.54 147.06 4839.57 147.37 4839.53C147.64 4839.5 147.9 4839.45 148.13 4839.37C148.37 4839.29 148.57 4839.2 148.72 4839.08L148.54 4837.51L147.29 4837.65L147.21 4836.93L149.23 4836.7H149.25Z" fill="white"/>
          <path id="Vector_44" d="M153.55 4836.21L154.56 4836.1C154.71 4836.08 154.86 4836.06 155 4836.01C155.14 4835.97 155.27 4835.91 155.38 4835.83C155.49 4835.75 155.57 4835.65 155.63 4835.52C155.68 4835.39 155.7 4835.23 155.68 4835.04C155.66 4834.85 155.61 4834.7 155.52 4834.58C155.44 4834.47 155.33 4834.39 155.21 4834.34C155.09 4834.29 154.95 4834.26 154.8 4834.25C154.65 4834.25 154.5 4834.25 154.35 4834.26L153.34 4834.37L153.54 4836.19L153.55 4836.21ZM152.5 4833.81L154.49 4833.59C154.85 4833.55 155.16 4833.57 155.4 4833.64C155.64 4833.71 155.84 4833.82 156 4833.95C156.15 4834.09 156.27 4834.25 156.34 4834.43C156.42 4834.61 156.46 4834.79 156.48 4834.97C156.5 4835.15 156.48 4835.33 156.44 4835.5C156.4 4835.67 156.32 4835.84 156.22 4835.99C156.12 4836.14 155.98 4836.27 155.82 4836.38C155.66 4836.49 155.47 4836.57 155.26 4836.62L157.15 4839.02L156.19 4839.13L154.47 4836.8L153.62 4836.9L153.9 4839.39L153.13 4839.48L152.49 4833.83L152.5 4833.81Z" fill="white"/>
          <path id="Vector_45" d="M157.35 4835.16L158.07 4835.08L158.5 4838.86L157.78 4838.94L157.35 4835.16ZM157.03 4833.82C157.01 4833.68 157.05 4833.55 157.14 4833.43C157.23 4833.31 157.35 4833.25 157.49 4833.23C157.63 4833.21 157.76 4833.25 157.88 4833.34C158 4833.43 158.06 4833.55 158.08 4833.69C158.1 4833.83 158.06 4833.96 157.97 4834.08C157.88 4834.2 157.76 4834.26 157.62 4834.28C157.48 4834.3 157.35 4834.26 157.23 4834.17C157.11 4834.08 157.05 4833.96 157.03 4833.82Z" fill="white"/>
          <path id="Vector_46" d="M158.83 4833.62C158.81 4833.48 158.85 4833.35 158.94 4833.23C159.03 4833.11 159.15 4833.05 159.29 4833.03C159.43 4833.01 159.56 4833.05 159.68 4833.14C159.8 4833.23 159.86 4833.35 159.88 4833.49C159.9 4833.63 159.86 4833.76 159.77 4833.88C159.68 4834 159.56 4834.06 159.42 4834.08C159.28 4834.1 159.15 4834.06 159.03 4833.97C158.91 4833.88 158.85 4833.76 158.83 4833.62ZM159.87 4834.88L160.36 4839.21C160.37 4839.31 160.38 4839.43 160.38 4839.59C160.38 4839.75 160.36 4839.9 160.3 4840.06C160.25 4840.21 160.14 4840.35 160 4840.48C159.86 4840.6 159.64 4840.68 159.35 4840.71C159.27 4840.71 159.19 4840.72 159.11 4840.72C159.03 4840.72 158.95 4840.71 158.88 4840.69V4840.02C158.88 4840.02 158.98 4840.04 159.03 4840.05C159.09 4840.05 159.14 4840.05 159.18 4840.05C159.38 4840.03 159.51 4839.95 159.57 4839.82C159.63 4839.69 159.65 4839.5 159.62 4839.26L159.14 4834.97L159.86 4834.89L159.87 4834.88Z" fill="white"/>
          <path id="Vector_47" d="M160.91 4834.76L161.63 4834.68L161.7 4835.26H161.72C161.79 4835.05 161.93 4834.86 162.14 4834.71C162.35 4834.56 162.6 4834.47 162.89 4834.44C163.07 4834.42 163.25 4834.43 163.42 4834.47C163.59 4834.51 163.75 4834.58 163.89 4834.68C164.03 4834.78 164.14 4834.92 164.24 4835.1C164.34 4835.28 164.4 4835.49 164.43 4835.74L164.7 4838.17L163.98 4838.25L163.73 4836.02C163.71 4835.84 163.67 4835.7 163.61 4835.58C163.55 4835.46 163.47 4835.37 163.38 4835.3C163.29 4835.23 163.19 4835.19 163.09 4835.16C162.98 4835.14 162.87 4835.13 162.76 4835.14C162.61 4835.16 162.48 4835.2 162.35 4835.26C162.23 4835.32 162.12 4835.41 162.04 4835.53C161.96 4835.64 161.9 4835.78 161.86 4835.95C161.82 4836.12 161.82 4836.31 161.84 4836.53L162.06 4838.48L161.34 4838.56L160.91 4834.78V4834.76Z" fill="white"/>
          <path id="Vector_48" d="M165.26 4834.27L165.93 4834.19L166 4834.78H166.02C166.03 4834.72 166.06 4834.65 166.12 4834.56C166.18 4834.47 166.25 4834.39 166.35 4834.3C166.45 4834.22 166.56 4834.14 166.7 4834.07C166.84 4834 166.99 4833.96 167.16 4833.94C167.44 4833.91 167.68 4833.94 167.89 4834.04C168.09 4834.14 168.27 4834.29 168.42 4834.51C168.52 4834.26 168.67 4834.07 168.88 4833.92C169.09 4833.78 169.31 4833.69 169.54 4833.67C169.83 4833.64 170.08 4833.66 170.27 4833.73C170.47 4833.8 170.63 4833.91 170.75 4834.06C170.87 4834.21 170.97 4834.37 171.03 4834.57C171.09 4834.76 171.13 4834.97 171.16 4835.18L171.41 4837.38L170.69 4837.46L170.45 4835.35C170.43 4835.21 170.41 4835.07 170.38 4834.94C170.35 4834.81 170.29 4834.7 170.23 4834.6C170.16 4834.51 170.07 4834.43 169.95 4834.38C169.84 4834.33 169.69 4834.32 169.52 4834.34C169.17 4834.38 168.94 4834.51 168.81 4834.74C168.68 4834.97 168.64 4835.25 168.68 4835.59L168.91 4837.66L168.19 4837.74L167.97 4835.76C167.95 4835.58 167.92 4835.42 167.89 4835.27C167.85 4835.12 167.8 4835 167.73 4834.9C167.66 4834.8 167.57 4834.72 167.46 4834.67C167.35 4834.62 167.21 4834.61 167.03 4834.63C166.9 4834.64 166.78 4834.68 166.67 4834.75C166.56 4834.81 166.46 4834.9 166.38 4835.01C166.3 4835.12 166.24 4835.26 166.21 4835.42C166.17 4835.58 166.17 4835.77 166.19 4835.98L166.41 4837.94L165.69 4838.02L165.26 4834.24V4834.27Z" fill="white"/>
          <path id="Vector_49" d="M172.73 4835.34C172.75 4835.53 172.8 4835.7 172.88 4835.86C172.96 4836.02 173.05 4836.14 173.17 4836.25C173.29 4836.35 173.43 4836.43 173.59 4836.48C173.75 4836.53 173.93 4836.54 174.12 4836.52C174.31 4836.5 174.48 4836.45 174.63 4836.36C174.78 4836.28 174.9 4836.17 174.99 4836.04C175.08 4835.91 175.15 4835.77 175.19 4835.6C175.23 4835.43 175.24 4835.25 175.22 4835.06C175.2 4834.87 175.15 4834.7 175.07 4834.54C174.99 4834.38 174.9 4834.26 174.78 4834.15C174.66 4834.05 174.52 4833.97 174.36 4833.92C174.2 4833.87 174.02 4833.86 173.83 4833.88C173.64 4833.9 173.47 4833.95 173.32 4834.04C173.17 4834.12 173.05 4834.23 172.96 4834.36C172.87 4834.49 172.8 4834.63 172.76 4834.8C172.72 4834.97 172.71 4835.15 172.73 4835.34ZM171.96 4835.43C171.93 4835.15 171.96 4834.89 172.03 4834.64C172.11 4834.39 172.23 4834.16 172.39 4833.96C172.55 4833.76 172.75 4833.59 172.98 4833.46C173.21 4833.33 173.47 4833.25 173.75 4833.22C174.03 4833.19 174.3 4833.22 174.56 4833.29C174.82 4833.37 175.05 4833.48 175.25 4833.65C175.45 4833.81 175.62 4834 175.75 4834.23C175.88 4834.46 175.96 4834.71 175.99 4834.99C176.02 4835.27 175.99 4835.53 175.92 4835.79C175.84 4836.04 175.73 4836.27 175.56 4836.47C175.4 4836.67 175.2 4836.83 174.97 4836.96C174.74 4837.09 174.48 4837.17 174.2 4837.21C173.92 4837.25 173.65 4837.21 173.39 4837.14C173.13 4837.06 172.9 4836.95 172.7 4836.79C172.5 4836.63 172.33 4836.44 172.2 4836.21C172.07 4835.98 171.99 4835.73 171.96 4835.45" fill="white"/>
          <path id="Vector_50" d="M176.58 4832.99L177.3 4832.91L177.37 4833.49H177.39C177.46 4833.28 177.6 4833.09 177.81 4832.94C178.02 4832.79 178.27 4832.7 178.56 4832.67C178.74 4832.65 178.92 4832.66 179.09 4832.7C179.26 4832.74 179.42 4832.81 179.56 4832.91C179.7 4833.01 179.81 4833.15 179.91 4833.33C180 4833.51 180.07 4833.72 180.09 4833.97L180.36 4836.4L179.64 4836.48L179.39 4834.25C179.37 4834.07 179.33 4833.93 179.27 4833.81C179.21 4833.69 179.13 4833.6 179.04 4833.53C178.95 4833.46 178.85 4833.42 178.75 4833.39C178.64 4833.37 178.53 4833.36 178.42 4833.37C178.27 4833.39 178.14 4833.43 178.01 4833.49C177.89 4833.55 177.78 4833.64 177.7 4833.76C177.62 4833.87 177.56 4834.01 177.52 4834.18C177.49 4834.35 177.48 4834.54 177.5 4834.76L177.72 4836.71L177 4836.79L176.57 4833.01L176.58 4832.99Z" fill="white"/>
          <path id="Vector_51" d="M183.1 4835.5C183.29 4835.48 183.46 4835.43 183.61 4835.34C183.76 4835.26 183.88 4835.15 183.97 4835.02C184.06 4834.89 184.13 4834.75 184.17 4834.58C184.21 4834.41 184.22 4834.23 184.2 4834.04C184.18 4833.85 184.13 4833.67 184.05 4833.52C183.97 4833.36 183.87 4833.24 183.75 4833.13C183.63 4833.03 183.49 4832.95 183.33 4832.9C183.17 4832.85 182.99 4832.84 182.8 4832.86C182.61 4832.88 182.44 4832.93 182.29 4833.02C182.14 4833.1 182.02 4833.21 181.93 4833.34C181.84 4833.47 181.77 4833.61 181.73 4833.78C181.69 4833.95 181.68 4834.13 181.7 4834.32C181.72 4834.51 181.77 4834.69 181.85 4834.84C181.93 4835 182.02 4835.12 182.14 4835.23C182.26 4835.33 182.4 4835.41 182.56 4835.46C182.72 4835.51 182.9 4835.52 183.09 4835.5M185.12 4835.85L184.4 4835.93L184.34 4835.39H184.32C184.2 4835.61 184.03 4835.79 183.8 4835.93C183.57 4836.07 183.33 4836.15 183.07 4836.18C182.78 4836.21 182.51 4836.19 182.26 4836.12C182.01 4836.05 181.79 4835.93 181.61 4835.77C181.42 4835.61 181.27 4835.41 181.15 4835.18C181.03 4834.95 180.96 4834.7 180.93 4834.41C180.9 4834.12 180.91 4833.86 180.98 4833.61C181.04 4833.36 181.15 4833.13 181.3 4832.94C181.45 4832.74 181.63 4832.58 181.86 4832.45C182.09 4832.32 182.35 4832.24 182.64 4832.21C182.91 4832.18 183.17 4832.21 183.43 4832.29C183.68 4832.38 183.88 4832.51 184.03 4832.7H184.05L183.74 4829.9L184.46 4829.82L185.14 4835.85H185.12Z" fill="white"/>
          </g>
          <g id="Group_6">
          <path id="Vector_52" d="M120.61 4840.43C119.54 4840 118.95 4839.35 118.85 4838.47C118.75 4837.58 119.14 4836.84 119.5 4836.38C120.72 4834.85 122.47 4834.48 123.06 4834.41C123.52 4834.36 124.02 4834.36 124.59 4834.42C125.79 4834.55 126.85 4832.69 126.66 4831C126.5 4829.56 125.45 4828.33 123.91 4827.96C123.4 4827.84 122.86 4827.82 122.32 4827.88C119.99 4828.14 117.49 4829.98 117.76 4832.35C118 4834.48 119.75 4834.71 120.51 4834.96C120.63 4835 120.68 4835.06 120.58 4835.13C114.94 4836.74 112.75 4839.51 112.93 4841.15C113.05 4842.19 114.72 4843.59 115.47 4844.25C115.68 4844.43 115.73 4844.3 115.91 4844.1C116.51 4843.39 117.9 4841.88 120.06 4841.01C120.23 4840.94 121.04 4840.64 120.6 4840.43H120.61ZM126.45 4835.18C126.27 4835.18 126.16 4835.24 126.31 4835.4C126.53 4835.63 126.92 4836.03 127 4836.67C127.08 4837.31 126.8 4837.91 126.4 4838.43C125.48 4839.58 124.18 4839.95 123.28 4840.27C123.96 4840.11 124.68 4840.11 125.31 4840.36C126.7 4840.54 128.15 4840.97 129.14 4841.77C130.12 4842.56 130.52 4843.49 130.08 4845.09C129.99 4845.41 129.78 4845.94 129.8 4846.13C129.8 4846.18 129.84 4846.21 129.89 4846.2C130.07 4846.18 130.26 4846.08 130.5 4845.77C131.87 4844.04 132.02 4841.54 131.9 4840.48C131.55 4837.4 127.8 4835.17 126.45 4835.19V4835.18Z" fill="white"/>
          <path id="Vector_53" d="M127.94 4845.45C126.41 4844.22 125.07 4844.56 124.8 4844.34C124.8 4844.34 124.79 4844.32 124.79 4844.31C124.79 4844.3 124.79 4844.28 124.81 4844.27C124.92 4844.13 125.76 4843.89 126.31 4843.2C126.57 4842.87 126.76 4842.47 126.7 4841.98C126.57 4840.84 125.42 4840.4 125.32 4840.36C124.69 4840.11 123.97 4840.11 123.29 4840.27C121.93 4840.58 120.56 4841.49 120.72 4842.9C120.82 4843.83 121.4 4844.43 121.86 4844.57C121.97 4844.61 122.45 4844.67 122.19 4844.81C121.99 4844.91 121.76 4844.97 121.55 4845.05C121.37 4845.12 118.2 4846.31 118.43 4848.31C118.55 4849.4 119.95 4850.18 120.06 4850.26C120.15 4850.32 120.24 4850.44 120.25 4850.52L120.34 4851.3C120.5 4852.72 124.04 4855.02 126.45 4854.75C127.37 4854.65 127.6 4853.5 127.61 4853.47C127.8 4852.85 127.66 4851.92 127.63 4851.52C127.6 4851.12 127.59 4850.88 127.78 4850.5C127.9 4850.25 128.38 4849.72 128.68 4848.95C128.9 4848.39 129.05 4847.77 128.98 4847.16C128.89 4846.4 128.48 4845.85 127.97 4845.44L127.94 4845.45Z" fill="white"/>
          </g>
          </g>
          </g>
        </>
    )}
    return (
      <>
        <g id="Balk_en_Payoff" data-name="Balk en Payoff">
      <rect id="Balk" className="cls-41" x="0" y="4602.83" width="360" height="108.18"/>
      <text className="cls-37" transform="translate(32.43 4652.58)"><tspan x="0" y="0">CJG Rijnmond. Ondersteuning bij de</tspan><tspan x="-3.8" y="19.2">grootste opgave in een mensenleven.</tspan></text>
        </g>
        <g id="Jaar">
      <g id="Kinderwagen">
        <g id="Jaar-2022-m">
          <text className="cls-9" transform="translate(161.88 4569.65)"><tspan x="0" y="0">2</tspan></text>
          <text className="cls-10" transform="translate(180.1 4569.64)"><tspan className="cls-40" x="0" y="0">0</tspan><tspan className="cls-110" x="18.46" y="0">2</tspan></text>
          <text className="cls-6" transform="translate(217.17 4569.64)"><tspan x="0" y="0">2</tspan></text>
        </g>
        <path className="cls-64" d="m252.1,4572.53l-1.35,13.46c0,3.72-3.01,6.73-6.73,6.73h-75.98c-3.72,0-6.73-3.01-6.73-6.73l-1.35-13.46h92.13Z"/>
        <path className="cls-11" d="m244.03,4592.71h-75.98c-3.72,0-6.73-3.01-6.73-6.73h89.44c0,3.72-3.01,6.73-6.73,6.73Z"/>
        <rect className="cls-11" x="158.62" y="4569.16" width="94.82" height="3.36" rx="1.35" ry="1.35"/>
        <polygon className="cls-11" points="251.2 4558.49 222.98 4569.61 248.56 4551.72 251.2 4558.49"/>
        <path className="cls-88" d="m189.03,4584.02l-28.57-23.92,1.9-1.9,65.71,53.63v2.69h-66.22v-2.7l27.18-27.81Zm2.57,1.97l-5.77,5.65,12.57.14-6.8-5.79Zm-8.46,8.34l-9.83,9.83h40.3l-12.79-10.08-17.68.25Zm41.15,17.5l-4.98-4.98h-48.68l-4.98,4.98h58.64Z"/>
        <circle className="cls-81" cx="191.6" cy="4584.45" r="3.36"/>
        <g>
          <polygon className="cls-88" points="216.36 4611.83 223.84 4611.83 218.55 4606.54 219.69 4605.4 224.98 4610.68 224.98 4603.21 226.59 4603.21 226.59 4610.68 231.88 4605.4 233.02 4606.54 227.73 4611.83 235.21 4611.83 235.21 4613.44 227.73 4613.44 233.02 4618.73 231.88 4619.87 226.59 4614.58 226.59 4622.06 224.98 4622.06 224.98 4614.58 219.69 4619.87 218.55 4618.73 223.84 4613.44 216.36 4613.44 216.36 4611.83"/>
          <circle className="cls-81" cx="225.79" cy="4612.63" r="3.36"/>
          <path className="cls-81" d="m225.79,4602.54c5.58,0,10.09,4.52,10.09,10.09s-4.52,10.09-10.09,10.09-10.09-4.52-10.09-10.09,4.52-10.09,10.09-10.09Zm0,16.82c3.72,0,6.73-3.01,6.73-6.73s-3.01-6.73-6.73-6.73-6.73,3.01-6.73,6.73,3.01,6.73,6.73,6.73Z"/>
          <circle className="cls-88" cx="225.83" cy="4612.63" r="1.35"/>
          <path className="cls-88" d="m217.78,4612.61c0-4.46,3.62-8.08,8.08-8.08s8.08,3.62,8.08,8.08-3.62,8.08-8.08,8.08-8.08-3.62-8.08-8.08Zm8.03,6.77c3.72,0,6.73-3.01,6.73-6.73s-3.01-6.73-6.73-6.73-6.73,3.01-6.73,6.73,3.01,6.73,6.73,6.73Z"/>
        </g>
        <g>
          <polygon className="cls-88" points="154.72 4611.83 162.19 4611.83 156.91 4606.54 158.05 4605.4 163.33 4610.68 163.33 4603.21 164.95 4603.21 164.95 4610.68 170.23 4605.4 171.37 4606.54 166.09 4611.83 173.56 4611.83 173.56 4613.44 166.09 4613.44 171.37 4618.73 170.23 4619.87 164.95 4614.58 164.95 4622.06 163.33 4622.06 163.33 4614.58 158.05 4619.87 156.91 4618.73 162.19 4613.44 154.72 4613.44 154.72 4611.83"/>
          <circle className="cls-81" cx="164.14" cy="4612.63" r="3.36"/>
          <path className="cls-81" d="m164.14,4602.54c5.58,0,10.09,4.52,10.09,10.09s-4.52,10.09-10.09,10.09-10.09-4.52-10.09-10.09,4.52-10.09,10.09-10.09Zm0,16.82c3.72,0,6.73-3.01,6.73-6.73s-3.01-6.73-6.73-6.73-6.73,3.01-6.73,6.73,3.01,6.73,6.73,6.73Z"/>
          <circle className="cls-88" cx="164.19" cy="4612.63" r="1.35"/>
          <path className="cls-88" d="m156.1,4612.62c0-4.46,3.62-8.08,8.08-8.08s8.08,3.62,8.08,8.08-3.62,8.08-8.08,8.08-8.08-3.62-8.08-8.08Zm8.03,6.77c3.72,0,6.73-3.01,6.73-6.73s-3.01-6.73-6.73-6.73-6.73,3.01-6.73,6.73,3.01,6.73,6.73,6.73Z"/>
        </g>
        <polygon className="cls-88" points="145.19 4555.31 162.38 4561 163.22 4558.44 146.03 4552.76 145.19 4555.31"/>
        <circle className="cls-81" cx="162.76" cy="4560.1" r="2.69"/>
        <circle className="cls-88" cx="191.67" cy="4584.43" r="1.68"/>
        <circle className="cls-88" cx="162.76" cy="4560.1" r="1.35"/>
        <path className="cls-64" d="m250.76,4558.68c.88,3.27,1.35,6.78,1.35,10.49h-28.43"/>
      </g>
      <g id="Vrouw">
        <path className="cls-100" d="m133.44,4566.46l-2.88-15.06-18.25-.71-4.08,12.5c.98,16.88,5.53,35.38,7.64,50.22h3.4l2.69-46.42-1.08,45.36h3.59c3.78-15.26,7.55-29.95,8.99-45.89Z"/>
        <path className="cls-102" d="m89.23,4545.77l1.85-1.59c.48-.41.97-.79,1.48-1.14l-.84-1.5-3.29-5.26c-.68-1.09-1.96-1.66-3.23-1.43l-.76.14c-1.02.18-1.46,1.39-.8,2.18l4.19,4.42,1.4,4.18Z"/>
        <path className="cls-102" d="m150.09,4553.66l-2.75-.5c-.4,1.05-.93,2.05-1.61,3l2.93.23c.26.55.76,1.01,1.35,1.11-.41,1.11.85,2.27,1.93,2.29,1.3.02,2.23-1.1,2.54-2.27.29-1.1.02-2.32-.8-3.12-.9-.87-2.07-.86-3.23-.79-.12,0-.24.02-.35.05Z"/>
        <path className="cls-79" d="m91.85,4541.34l9.07,7.64,4.08-14.28c1.24-3.01,2.37-5.97,5.4-7,.39-.13.78-.23,1.14-.27l9.66-1.25,8.7,1.28c1.61.34,3.75,2.47,4.12,4.08l4.16,12.64,9.95,8.94-1.34,4.2-12.57-8.55-4.49-7.31,3.22,19.62-24.84-.4,2.66-21.92s-3.14,14.47-5.91,17.08c-.77.72-2.83.77-3.88.63-5-.67-13.27-12.83-13.27-12.83l4.15-2.26Z"/>
        <path className="cls-103" d="m118.43,4530.75c0,2.33,6.33,2.33,6.33,0v-6.05h-6.33v6.05Z"/>
        <path className="cls-54" d="m130.34,4511.42c-.46,1.71,0,3.32,1.01,3.59,1.02.27,2.21-.9,2.67-2.61.46-1.71,0-3.32-1.01-3.59-1.02-.27-2.21.89-2.67,2.61Z"/>
        <path className="cls-54" d="m111.87,4511.42c.46,1.71,0,3.32-1.01,3.59-1.02.27-2.21-.9-2.67-2.61-.46-1.71,0-3.32,1.01-3.59,1.02-.27,2.21.89,2.67,2.61Z"/>
        <path className="cls-102" d="m118.7,4527.12c.2.05.4.1.6.14,5.58,1.15,10.85-3.04,11.25-8.72l.75-9.18c0-5.46-4.34-10-9.81-10.09-.2,0-.4,0-.6,0-5.49,0-9.85,4.45-9.74,9.94.07,3.68.36,6.33.62,9.51.33,3.97,3.07,7.4,6.93,8.41Z"/>
        <path className="cls-41" d="m116.42,4500.05c-7.46-2.45-11.11,9.06-9.64,14.38,1.13,4.09,3.89,6.54,6.86,9.29-1.72-6.11-1.54-14.12,3.08-18.98,1.22-1.28,2.72-.2,4.15.49,1.39.68,2.7,1.58,3.87,2.6,2.42,2.1,4.31,4.81,5.39,7.83.6,1.68.32,3.86-.15,5.58,2.62-3.19,6.03-7.25,5.45-11.33-.19-1.31-.72-2.54-1.28-3.74-2.68-5.74-7.28-8.51-13.63-8.34-1.64.05-3.16.88-4.09,2.23Z"/>
        <g>
          <path className="cls-92" d="m115.88,4612.88c-.25.3-.48.63-.7.91l-.72.83s-.06.08-.08.13c-.71.8-1.46,1.56-2.2,2.33-.18.19-.36.39-.5.61-.2.3-.31.63-.24.98.09.4.45.71.84.83.39.13.82.11,1.23.06.9-.1,1.8-.34,2.58-.8,1.16-.7,1.42-2.09,2.43-2.79.17.51.79.88,1.16.19.48-.91.38-2.31-.2-3.28h-3.6Z"/>
          <path className="cls-92" d="m131.31,4617.25c-1.06-1.39-3-1.71-4.49-2.63-.27-.16-.51-.35-.74-.55-.05-.05-.1-.1-.15-.14-.17-.16-.34-.32-.5-.49-.35-.4-.67-.82-.94-1.26h-3.66c-.31.74-.29,1.69-.12,2.38.13.53.4,1.02.81,1.38.35.32,1.15.64,1.55.22.13-.14.11-.36.08-.56,1.04.66,2.12,1.36,3.24,1.87,1.02.46,2.15.68,3.27.67.41,0,.82-.04,1.23-.1.25-.04.55-.14.58-.39.02-.14-.06-.27-.15-.38Z"/>
        </g>
        <path className="cls-41" d="m119.78,4497.37c0,.43-.07.86-.2,1.27-.38,1.22-1.32,2.25-2.48,2.77-.74.33-1.55.46-2.36.54-1.34.14-2.9.06-3.71-1.01-.98.77-2.55.46-3.34-.51-.79-.96-.86-2.39-.4-3.54.46-1.16,1.39-2.08,2.42-2.78,1.79-1.23,4.07-1.9,6.18-1.37,2.11.53,3.91,2.45,3.9,4.62Z"/>
        <path className="cls-102" d="m121.83,4533.85c.49.11,1,.19,1.48.06.49-.13.89-.47,1.25-.83,1.62-1.6,2.65-3.79,2.84-6.06-.81-.54-1.83-.75-2.79-.58.04,1.82-.68,3.65-1.95,4.96-1.78-1.12-3.29-2.66-4.38-4.46-1.65.41-3.3.83-4.95,1.24,1.99,2.86,5.09,4.92,8.49,5.67Z"/>
        <path className="cls-102" d="m108.92,4512.21c.31-.08.63-.02.95.15v5.57c-.78-.2-1.55-1.14-1.89-2.4-.42-1.58,0-3.07.94-3.32Z"/>
        <path className="cls-14" d="m119.39,4521.15c.7,1.27,2.61,1.57,4.26.65"/>
        <path className="cls-14" d="m118.01,4514.63c0,.88-.43,1.6-.95,1.6s-.95-.72-.95-1.6.42-1.6.95-1.6.95.72.95,1.6Z"/>
        <path className="cls-14" d="m124.43,4514.63c0-.88.43-1.6.95-1.6s.95.72.95,1.6-.43,1.6-.95,1.6-.95-.72-.95-1.6Z"/>
        <path className="cls-14" d="m117.49,4515.93c0,.23-.5.42-1.12.42s-1.12-.19-1.12-.42.5-.42,1.12-.42,1.12.19,1.12.42Z"/>
        <path className="cls-14" d="m127.25,4515.93c0,.23-.5.42-1.12.42s-1.12-.19-1.12-.42.5-.42,1.12-.42,1.12.19,1.12.42Z"/>
      </g>
      <g id="Paraplu-m">
        <g>
          <polygon className="cls-92" points="80.66 4493.73 90.9 4557.2 88 4558.48 77.59 4494.97 80.66 4493.73"/>
          <polygon className="cls-92" points="70.41 4469.44 70.62 4461.6 75.89 4468.52 70.41 4469.44"/>
          <path className="cls-41" d="m81.84,4496.68l22.73-2.2,20.37-10.03s-16.89-19.82-48.6-16.02c-31.71,3.81-48.46,21.38-48.46,21.38l19.9,9.12,34.06-2.25Z"/>
        </g>
        <g>
          <path className="cls-57" d="m70.51,4483.29c-.09-.1-.21-.17-.34-.21-.13-.04-.26-.06-.4-.04-.16.02-.31.07-.43.14-.12.08-.23.17-.31.29-.08.12-.14.25-.18.4-.04.15-.04.3-.03.47.02.15.06.29.12.42.06.13.15.24.25.33.1.09.23.16.36.21.14.04.29.06.46.04s.31-.07.42-.15c.12-.08.21-.19.29-.32l.36.21s-.05.08-.1.14c-.05.06-.11.13-.2.19-.08.07-.19.13-.31.18-.12.06-.26.09-.43.11-.23.03-.44,0-.63-.06-.19-.07-.36-.16-.51-.29-.14-.13-.26-.27-.35-.44-.09-.17-.14-.35-.16-.53-.03-.22-.01-.43.04-.63.05-.2.14-.37.25-.52.12-.15.26-.28.44-.37.18-.1.37-.16.6-.18.19-.02.38,0,.57.05.19.05.35.15.49.29l-.3.28Z"/>
          <path className="cls-57" d="m72.25,4482.47l.39-.04.23,2.06c.02.18,0,.33-.03.45-.04.12-.1.22-.18.3-.08.08-.16.13-.26.17-.09.04-.18.06-.26.07-.2.02-.38-.01-.53-.1-.15-.09-.26-.24-.32-.44l.37-.13c.03.11.09.19.16.24.07.05.17.08.27.06.1-.01.17-.04.23-.08.05-.04.09-.1.12-.16.03-.06.04-.14.04-.22,0-.08,0-.16-.01-.24l-.22-1.93Z"/>
          <path className="cls-57" d="m75.86,4483.35l.16,1.42c-.07.05-.14.1-.23.14-.09.04-.18.08-.27.12-.09.03-.19.06-.29.08-.1.02-.19.04-.28.05-.24.03-.47.01-.67-.05-.2-.06-.37-.15-.52-.27-.15-.12-.27-.27-.36-.45-.09-.17-.15-.36-.17-.57-.02-.21,0-.42.04-.61.05-.19.14-.36.25-.51.12-.15.26-.27.44-.37.18-.1.37-.16.59-.18.27-.03.49-.02.66.03.18.05.33.12.45.23l-.24.33c-.13-.1-.27-.17-.41-.2-.14-.03-.28-.04-.43-.02-.16.02-.31.07-.44.14-.13.08-.23.17-.31.28-.08.11-.14.24-.18.39-.04.15-.04.3-.03.45s.06.31.14.44c.07.13.16.25.28.34.11.09.24.16.38.2.14.04.29.06.45.04.14-.02.27-.04.39-.08.12-.04.22-.09.3-.15l-.09-.8-.64.07-.04-.37,1.03-.12Z"/>
          <path className="cls-57" d="m78.06,4483.1l.51-.06c.08,0,.15-.02.22-.04.07-.02.14-.05.19-.09.05-.04.1-.09.12-.16.03-.07.04-.15.03-.24s-.04-.17-.08-.23c-.04-.06-.09-.1-.16-.12-.06-.03-.13-.04-.21-.04-.08,0-.15,0-.23,0l-.51.06.1.93Zm-.53-1.22l1.01-.11c.18-.02.34-.01.46.02.12.04.23.09.3.16.08.07.14.15.17.24.04.09.06.18.07.27s0,.18-.02.27c-.02.09-.06.17-.11.25-.05.08-.12.14-.2.2-.08.06-.18.1-.28.12l.96,1.22-.49.05-.87-1.19-.43.05.14,1.27-.39.04-.32-2.88Z"/>
          <path className="cls-57" d="m79.99,4482.56l.37-.04.22,1.93-.37.04-.22-1.93Zm-.16-.68c0-.07.01-.14.06-.2.05-.06.11-.09.18-.1s.14.01.2.06c.06.05.09.11.1.18s-.01.14-.06.2c-.05.06-.11.09-.18.1s-.14-.01-.2-.06c-.06-.05-.09-.11-.1-.18"/>
          <path className="cls-57" d="m80.74,4481.78c0-.07.01-.14.06-.2.05-.06.11-.09.18-.1s.14.01.2.06c.06.05.09.11.1.18s-.01.14-.06.2c-.05.06-.11.09-.18.1s-.14-.01-.2-.06c-.06-.05-.09-.11-.1-.18m.53.64l.25,2.2s0,.11.01.19c0,.08-.01.16-.04.24-.03.08-.08.15-.15.21-.07.06-.18.1-.33.12-.04,0-.08,0-.12,0-.04,0-.08,0-.12-.02v-.34s.05.01.08.01c.03,0,.05,0,.08,0,.1-.01.17-.05.2-.12.03-.07.04-.16.03-.29l-.25-2.19.37-.04Z"/>
          <path className="cls-57" d="m81.8,4482.36l.37-.04.03.3h0c.03-.11.11-.2.21-.28.11-.08.23-.12.38-.14.09-.01.18,0,.27.01.09.02.17.05.24.11.07.05.13.12.18.21.05.09.08.2.09.33l.14,1.24-.37.04-.13-1.14c-.01-.09-.03-.16-.06-.23-.03-.06-.07-.11-.11-.14-.05-.04-.1-.06-.15-.07-.05-.01-.11-.01-.17,0-.08,0-.15.03-.21.06-.06.03-.12.08-.16.14-.04.06-.07.13-.09.21-.02.08-.02.18,0,.29l.11.99-.37.04-.22-1.93Z"/>
          <path className="cls-57" d="m84.01,4482.11l.34-.04.03.3h0s.02-.06.05-.11c.03-.04.07-.09.12-.13.05-.04.11-.08.18-.11.07-.03.15-.06.23-.07.14-.02.27,0,.37.05.1.05.19.13.27.24.05-.13.13-.23.23-.3.11-.07.22-.11.34-.13.15-.02.27,0,.37.03.1.04.18.09.25.17.06.07.11.16.14.26.03.1.05.2.07.31l.13,1.12-.37.04-.12-1.07c0-.07-.02-.14-.04-.21-.02-.07-.04-.12-.08-.17-.04-.05-.08-.08-.14-.11-.06-.02-.13-.03-.22-.02-.18.02-.3.09-.36.2-.06.12-.09.26-.07.43l.12,1.05-.37.04-.11-1.01c-.01-.09-.02-.18-.04-.25-.02-.07-.04-.14-.08-.19-.03-.05-.08-.09-.14-.12-.06-.03-.13-.03-.22-.02-.06,0-.13.03-.18.06-.06.03-.11.08-.15.13-.04.06-.07.13-.09.21-.02.08-.02.18,0,.29l.11,1-.37.04-.22-1.93Z"/>
          <path className="cls-57" d="m87.82,4482.65c.01.1.04.19.08.27.04.08.09.14.15.2.06.05.13.09.21.12.08.03.17.03.27.02s.18-.04.26-.08c.07-.04.14-.1.18-.16.05-.06.08-.14.1-.23.02-.09.03-.18.01-.28s-.04-.19-.07-.27c-.04-.08-.09-.14-.15-.2-.06-.05-.13-.09-.22-.12-.08-.02-.17-.03-.27-.02s-.18.04-.26.08c-.07.04-.14.1-.18.16-.05.06-.08.14-.1.23-.02.09-.03.18-.01.28m-.39.04c-.02-.14,0-.28.03-.4.04-.13.1-.24.18-.35.08-.1.18-.19.3-.25.12-.07.25-.11.39-.12s.28,0,.41.03c.13.04.25.1.35.18.1.08.19.18.25.3.07.12.11.24.12.39s0,.28-.03.41c-.04.13-.1.24-.18.35-.08.1-.18.18-.3.25-.12.07-.25.11-.39.12s-.28,0-.41-.03c-.13-.04-.25-.1-.35-.18-.1-.08-.19-.18-.25-.3-.07-.12-.11-.25-.12-.39"/>
          <path className="cls-57" d="m89.78,4481.46l.37-.04.03.3h0c.03-.11.11-.2.21-.28.11-.08.23-.12.38-.14.09-.01.18,0,.27.01.09.02.17.05.24.11.07.05.13.12.18.21.05.09.08.2.09.33l.14,1.24-.37.04-.13-1.14c-.01-.09-.03-.16-.06-.23-.03-.06-.07-.11-.11-.14-.05-.04-.09-.06-.15-.07-.05-.01-.11-.01-.17,0-.08,0-.15.03-.21.06-.06.03-.11.08-.16.14-.04.06-.07.13-.09.21-.02.08-.02.18,0,.29l.11.99-.37.04-.22-1.93Z"/>
          <path className="cls-57" d="m93.1,4482.74c.1-.01.18-.04.26-.08.07-.04.14-.1.18-.16.05-.06.08-.14.1-.23.02-.09.02-.18.01-.28s-.04-.19-.07-.27c-.04-.08-.09-.14-.15-.2-.06-.05-.13-.09-.21-.12-.08-.03-.17-.03-.27-.02s-.18.04-.26.08c-.07.04-.14.1-.18.16-.05.06-.08.14-.1.23-.02.09-.03.18-.01.28s.04.19.07.27c.04.08.09.14.15.2.06.05.13.09.21.12.08.03.17.03.27.02m1.03.18l-.37.04-.03-.28h0c-.06.11-.15.2-.26.27-.12.07-.24.11-.37.13-.15.02-.29,0-.41-.03-.13-.04-.24-.1-.33-.18-.1-.08-.17-.18-.23-.3-.06-.12-.1-.25-.11-.39s0-.28.02-.41c.03-.13.09-.24.16-.34.08-.1.17-.18.28-.25.11-.06.25-.11.4-.12.14-.02.27,0,.4.04.13.04.23.11.31.21h0s-.16-1.42-.16-1.42l.37-.04.35,3.07Z"/>
        </g>
        <g>
          <path className="cls-57" d="m61.28,4485.25c-.54-.22-.84-.55-.89-1s.15-.83.33-1.06c.62-.78,1.51-.97,1.81-1,.24-.02.49-.02.78,0,.61.07,1.15-.88,1.05-1.74-.08-.73-.62-1.36-1.4-1.55-.26-.06-.54-.07-.81-.04-1.18.13-2.46,1.07-2.32,2.28.12,1.08,1.02,1.2,1.4,1.33.06.02.09.05.03.09-2.87.82-3.99,2.23-3.89,3.07.06.53.91,1.24,1.29,1.58.11.09.13.03.22-.08.3-.36,1.02-1.13,2.11-1.58.09-.04.5-.19.27-.29Zm2.98-2.67c-.09,0-.15.03-.07.11.11.12.31.32.35.65s-.1.63-.31.9c-.47.59-1.13.77-1.59.94.35-.08.71-.08,1.03.05.71.09,1.45.31,1.95.72.5.4.7.88.48,1.69-.04.16-.15.43-.14.53,0,.03.02.04.05.04.09-.01.19-.06.31-.22.7-.88.77-2.15.71-2.69-.18-1.57-2.09-2.7-2.77-2.7Z"/>
          <path className="cls-57" d="m65.01,4487.8c-.78-.63-1.46-.45-1.6-.56,0,0,0,0,0-.01,0,0,0-.01,0-.02.05-.07.48-.19.76-.55.13-.17.23-.37.2-.62-.07-.58-.65-.8-.7-.83-.32-.13-.69-.13-1.03-.05-.69.16-1.39.62-1.31,1.34.05.47.34.78.58.85.05.02.3.05.17.12-.1.05-.22.08-.33.12-.09.04-1.7.64-1.59,1.66.06.56.77.95.83.99.04.03.09.09.1.13l.04.4c.08.72,1.88,1.89,3.11,1.75.47-.05.58-.63.59-.65.09-.32.02-.79,0-1-.01-.2-.02-.33.08-.52.06-.12.3-.4.46-.79.11-.29.19-.6.15-.91-.04-.38-.25-.67-.52-.88Z"/>
        </g>
      </g>
        </g>
      </>
  )

}
