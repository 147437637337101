import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import  { ScrollTrigger }  from "gsap/ScrollTrigger";

// popup window component

import { data } from "./popupWindow/textPopup";
import { settings } from "./popupWindow/sliderSettings";
import WindowPopup from "./popupWindow/windowPopup";

// mp3 file
import mp3 from '../images/corporate_story.mp3';

// buttons

import PulseButton from "./buttons/PulseButton";

// svg_sections

import LineMetBall from './svgSection/LineMetBall';
import Kinderen from './svgSection/Kinderen';
import Ouders from "./svgSection/Ouders";
import Scholen from "./svgSection/Scholen";
import Zorg from "./svgSection/Zorg";
import Gemeenten from "./svgSection/Gemeenten";
import Jeugdgezondheidszorg from "./svgSection/Jeugdgezondheidszorg";
import CJG from "./svgSection/CJG";
import Merkcampagne from "./svgSection/Merkcampagne";
import Footer from "./svgSection/Footer";
import Logo from "./svgSection/Logo";

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

export default function Drawing() {

    const [showPopupKinderen, setShowPopupKinderen] = useState(false);
    const [showPopupOuders, setShowPopupOuders] = useState(false);
    const [showPopupCjg, setShowPopupCjg] = useState(false);
    const [showPopupScholen, setShowPopupScholen] = useState(false);
    const [showPopupZorg, setShowPopupZorg] = useState(false);
    const [showPopupGemeenten, setShowPopupGemeenten] = useState(false);
    const [showPopupLj, setShowPopupLj] = useState(false);
    const [showPopupMerkcampagne, setShowPopupMerkcampagne] = useState(false);
    const [containerHeight, setContainerHeight] = useState(0);

    const domainName = window.location.href;

    let draw = useRef(null);
    let path = useRef(null);
    let hero = useRef(null);
    let mobilePath = useRef(null);
    let mobileHero = useRef(null);

    // For desctop only:
    let section1 = useRef(null);
    let section2 = useRef(null);
    let section3 = useRef(null);
    let section4 = useRef(null);
    let section5 = useRef(null);
    let section6 = useRef(null);
    let section7 = useRef(null);
    let section8 = useRef(null);

    const cont = () => {
        let h = draw.current.getBoundingClientRect();
        setContainerHeight(h.height);
    };

    const navigate = (e, refElement) => {
        refElement.current?.scrollIntoView({behavior: 'smooth' });
    };
    
    // Show popup window;
    const clickHandlerKinderen = () => {
        setShowPopupKinderen(!showPopupKinderen);  
    }
    const clickHandlerOuders = () => {
        setShowPopupOuders(!showPopupOuders);  
    }
    const clickHandlerCjg = () => {
        setShowPopupCjg(!showPopupCjg);  
    }
    const clickHandlerScholen = () => {
        setShowPopupScholen(!showPopupScholen);  
    }
    const clickHandlerZorg = () => {
        setShowPopupZorg(!showPopupZorg);  
    }
    const clickHandlerGemeenten = () => {
        setShowPopupGemeenten(!showPopupGemeenten);  
        console.log("Click gemeneta")
    }
    const clickHandlerLj = () => {
        setShowPopupLj(!showPopupLj);  
    }
    const clickHandlerMerkcampagne = () => {
        setShowPopupMerkcampagne(!showPopupMerkcampagne); 
    }


    useEffect(() => {
        window.addEventListener('load', cont);
    }, [containerHeight]);

    // Pulse btn:
    useEffect(() => {
        gsap.set('.stage1', {opacity: 0, scale: 0.5, transformOrigin:"50% 50%"});
        gsap.set('.stage2', {opacity: 0.2, scale: 0.5, transformOrigin:"50% 50%"});

        gsap.to('.stage1', {
            scale: 1, 
            duration: 1.5,
            strokeWidth: '1px',
            stagger: {
              each: 0.5,
              repeat: -1,
            }
        });

        gsap.to('.stage2', {
            scale: 1, 
            strokeWidth: '2px',
            duration: 1.5,
            stagger: {
                each: 0.5,
                repeat: -1
            }
        });
          
        gsap.to('.stage1', {
            opacity: 0.8,
            duration: 0.75,
            stagger: {
                each: 0.5,
                repeat: -1,
                yoyo: true,
            }
        });

        gsap.to('.stage2', {
            opacity: 0.6, 
            duration: 0.75,
            stagger: {
                each: 0.5,
                repeat: -1,
                yoyo: true
            }
        });

    }, []);

    // Animated ball and the path(desctop):
    useEffect(() => {
        gsap.timeline({
            defaults:{duration:0.7, ease:'none'},
            scrollTrigger: {
              trigger: draw.current,
              start: 'top top',
              end: 'bottom bottom',
              scrub: 1
            }   
          })
          .to(hero.current, {
            transformOrigin: 'center',
            motionPath:{
                path: path.current,
                align: path.current,
                alignOrigin: [0.5, 0.5],
                autoRotate: false,
            }
          })
    }, []);

    // Animated ball and the path(mobile):
    useEffect(() => {
        gsap.timeline({
            defaults:{duration:0.7, ease:'none'},
            scrollTrigger: {
              trigger: draw.current,
              start: 'top top',
              end: 'bottom bottom',
              scrub: 1
            }   
          })
          .to(mobileHero.current, {
            transformOrigin: 'center',
            motionPath:{
                path: mobilePath.current,
                align: mobilePath.current,
                alignOrigin: [0.5, 0.5],
                autoRotate: false,
            }
          })
    }, []);

    // Menu active link
    useEffect(() => {
        let kinderen = document.querySelector('.menu-link-kinderen');
        let ouders = document.querySelector('.menu-link-ouders');
        let scholen = document.querySelector('.menu-link-scholen');
        let zorg = document.querySelector('.menu-link-zorg');
        let gemeenten = document.querySelector('.menu-link-gemeenten');
        let landelijkJeugdgezondheidszorg = document.querySelector('.menu-link-landelijk');
        let cjgRijnmond = document.querySelector('.menu-link-cjg-rijnmond');
        let merkcampagne = document.querySelector('.menu-link-merkcampagne');
            
        gsap.to(kinderen, {
                scrollTrigger: {
                trigger: section1.current, 
                endTrigger: section2.current,
                end: "+=855",
                toggleClass: {targets: kinderen, className: 'active'},      
            }
        })
        gsap.to(ouders, {
            scrollTrigger: {
                trigger: section2.current, 
                end: "+=828px",
                endTrigger: section3.current,
                toggleClass: {targets: ouders, className: 'active'},

            }
        })
        gsap.to(scholen, {
            scrollTrigger: {
                trigger: section3.current, 
                end: "+=475px",
                endTrigger: section4.current,
                toggleClass: {targets: scholen, className: 'active'},
                
            }
        })
        gsap.to(zorg, {
            scrollTrigger: {
                trigger: section4.current, 
                end: "+=687px",
                endTrigger: section5.current,
                toggleClass: {targets: zorg, className: 'active'},
                
            }
        })
        gsap.to(gemeenten, {
            scrollTrigger: {
                trigger: section5.current, 
                end: "+=620px",
                endTrigger: section6.current,
                toggleClass: {targets: gemeenten, className: 'active'},
                
            }
        })
        gsap.to(landelijkJeugdgezondheidszorg, {
            scrollTrigger: {
                trigger: section6.current, 
                end: "+=613px",
                endTrigger: section7.current,
                toggleClass: {targets: landelijkJeugdgezondheidszorg, className: 'active'},
                
            }
        })
        gsap.to(cjgRijnmond, {
            scrollTrigger: {
                trigger: section7.current, 
                end: "+=772px",
                endTrigger: section8.current,
                toggleClass: {targets: cjgRijnmond, className: 'active'},
                
            }
        })
        gsap.to(merkcampagne, {
            scrollTrigger: {
                trigger: section8.current, 
                end: "+=500px",
                toggleClass: {targets: merkcampagne, className: 'active'},
                
            }
        })
    }, []);

    return (
        <>

        <div className="image-container" ref={draw}>
            <div className="navigation">
                <ul>
                    <li onClick={(e) => navigate(e, section1)} className="menu-link-kinderen"><span>Kinderen</span></li>
                    <li onClick={(e) => navigate(e, section2)} className="menu-link-ouders"><span>Ouders</span></li>
                    <li onClick={(e) => navigate(e, section3)} className="menu-link-scholen"><span>Scholen</span></li>
                    <li onClick={(e) => navigate(e, section4)} className="menu-link-zorg"><span>Zorg</span></li>
                    <li onClick={(e) => navigate(e, section5)} className="menu-link-gemeenten"><span>Gemeenten</span></li>
                    <li onClick={(e) => navigate(e, section6)} className="menu-link-landelijk"><span>Jeugdgezondheidszorg</span></li>
                    <li onClick={(e) => navigate(e, section7)} 
                    className="menu-link-cjg-rijnmond"><span>CJG Rijnmond</span></li>
                    <li onClick={(e) => navigate(e, section8)} 
                    className="menu-link-merkcampagne"><span>Merkcampagne</span></li>
                </ul>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" className="desctop-render" viewBox="0 0 960 5280.71">
                <defs>
                    <clipPath id="clippath">
                    <polygon className="cls-1" points="249.6 4297.13 249.92 4303.48 255.06 4305.47 257.2 4301.35 256.45 4296.38 249.6 4297.13"/>
                    </clipPath>
                    <clipPath id="clippath-1">
                    <polygon className="cls-1" points="229.44 4296.38 229.69 4302.66 232.26 4305.9 236.04 4303.3 237.65 4296.38 229.44 4296.38"/>
                    </clipPath>
                    <linearGradient id="linear-gradient" x1="204.42" y1="2939.07" x2="277.52" y2="2939.07" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#6c4da1"/>
                    <stop offset=".17" stopColor="#65449e"/>
                    <stop offset=".18" stopColor="#65449e"/>
                    <stop offset=".35" stopColor="#593696"/>
                    <stop offset=".44" stopColor="#522e91"/>
                    <stop offset=".83" stopColor="#522e91"/>
                    </linearGradient>
                    <clipPath id="clippath-2">
                    <rect className="cls-62" x="522.37" y="1335.87" width="30.75" height="30.75"/>
                    </clipPath>
                    <linearGradient id="linear-gradient-2" x1="-308.18" y1="880.11" x2="-211.83" y2="880.11" gradientTransform="translate(532.97) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#6c4da1"/>
                    <stop offset=".17" stopColor="#65449c"/>
                    <stop offset=".18" stopColor="#65449c"/>
                    <stop offset=".58" stopColor="#65439c"/>
                    <stop offset=".84" stopColor="#522e91"/>
                    <stop offset="1" stopColor="#522e91"/>
                    </linearGradient>
                    <linearGradient id="linear-gradient-3" x1="-315.11" y1="883.4" x2="-234.71" y2="883.4" gradientTransform="translate(532.97) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#6c4da1"/>
                    <stop offset=".17" stopColor="#6c4da0"/>
                    <stop offset=".33" stopColor="#65449c"/>
                    <stop offset=".57" stopColor="#593695"/>
                    <stop offset=".69" stopColor="#522e91"/>
                    <stop offset="1" stopColor="#522e91"/>
                    </linearGradient>
                </defs>
                <g id="Achtergrond">
                    <rect className="cls-70" x="0" width="960" height="5280.71"/>
                    <rect className="cls-38" x="0" y="0" width="960" height="112.29"/>
                </g>
                <path id="Lijn-2" ref={path} data-name="Lijn" className="cls-4" d="m537.24,483.52v651.9c0,38.04,30.84,68.89,68.89,68.89h140.47c19.08,0,34.55,15.47,34.55,34.55v230c0,19.08-15.47,34.55-34.55,34.55h-401.73l-6.12,168.87v145.61c0,19.08,15.47,34.55,34.55,34.55h212.98c38.04,0,68.89,30.84,68.89,68.89v96.25c0,38.04-30.84,68.89-68.89,68.89H174.89v279.24c0,38.04,30.84,68.89,68.89,68.89h411.52v219.98h45.47v159.54c0,19.08-15.47,34.55-34.55,34.55h-339.45l-.36-.58v277.21h-157.12v308.91c0,38.04,30.84,68.89,68.89,68.89h544.97v443.86c0,19.08-15.47,34.55-34.55,34.55h-467.31v741.26c0,19.08,15.47,34.55,34.55,34.55h268.54"/>
                <circle id="Balletje" ref={hero} className="cls-23" cx="537.24" cy="565.56" r="9.77"/>
                <LineMetBall isDesctop={true} url={domainName} />
                <g ref={section1} ><Kinderen isDesctop={true} /></g>
                <g ref={section2} ><Ouders isDesctop={true} /></g>
                <g ref={section3} ><Scholen isDesctop={true} /></g>
                <g ref={section4} ><Zorg isDesctop={true}  /></g>
                <g ref={section5} ><Gemeenten isDesctop={true} clickHandlerGemeenten={clickHandlerGemeenten} /></g>
                <g ref={section6} ><Jeugdgezondheidszorg isDesctop={true} /></g>
                <g ref={section7} ><CJG isDesctop={true} /></g>
                <g ref={section8} ><Merkcampagne isDesctop={true} audio={mp3} /></g>
                <Footer isDesctop={true} />
                <Logo url={domainName} isDesctop={true} />
                <PulseButton 
                    isDesctop={true}
                    clickHandlerKinderen={clickHandlerKinderen} 
                    clickHandlerZorg={clickHandlerZorg} 
                    clickHandlerCjg={clickHandlerCjg} 
                    clickHandlerOuders={clickHandlerOuders} 
                    clickHandlerGemeenten={clickHandlerGemeenten} 
                    clickHandlerLj={clickHandlerLj} 
                    clickHandlerScholen={clickHandlerScholen}
                    clickHandlerMerkcampagne={clickHandlerMerkcampagne} 
                />
                
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" className="mobile-render" viewBox="0 0 360 4711.01">
                <defs>
                    <clipPath id="clippath">
                        <polygon className="cls-14" points="123.88 3999.89 124.05 4003.24 126.76 4004.29 127.89 4002.12 127.49 3999.49 123.88 3999.89"/>
                    </clipPath>
                    <clipPath id="clippath-1">
                        <polygon className="cls-14" points="113.25 3999.49 113.38 4002.81 114.74 4004.52 116.73 4003.14 117.58 3999.49 113.25 3999.49"/>
                    </clipPath>
                    <linearGradient id="linear-gradient" x1="46.67" y1="2882.96" x2="91.95" y2="2882.96" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#6c4da1"/>
                        <stop offset=".17" stopColor="#65449e"/>
                        <stop offset=".18" stopColor="#65449e"/>
                        <stop offset=".35" stopColor="#593696"/>
                        <stop offset=".44" stopColor="#522e91"/>
                        <stop offset=".83" stopColor="#522e91"/>
                    </linearGradient>
                    <clipPath id="clippath-2">
                        <rect className="cls-50" x="221.58" y="1617.96" width="13.13" height="13.13"/>
                    </clipPath>
                    <linearGradient id="linear-gradient-2" x1="-2649.44" y1="1045.11" x2="-2594.41" y2="1045.11" gradientTransform="translate(-2332.41) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#6c4da1"/>
                        <stop offset=".17" stopColor="#65449c"/>
                        <stop offset=".18" stopColor="#65449c"/>
                        <stop offset=".58" stopColor="#65439c"/>
                        <stop offset=".84" stopColor="#522e91"/>
                        <stop offset="1" stopColor="#522e91"/>
                    </linearGradient>
                    <linearGradient id="linear-gradient-3" x1="-2653.39" y1="1046.98" x2="-2607.48" y2="1046.98" gradientTransform="translate(-2332.41) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#6c4da1"/>
                        <stop offset=".17" stopColor="#6c4da0"/>
                        <stop offset=".33" stopColor="#65449c"/>
                        <stop offset=".57" stopColor="#593695"/>
                        <stop offset=".69" stopColor="#522e91"/>
                        <stop offset="1" stopColor="#522e91"/>
                    </linearGradient>
                </defs>
                <g id="Achtergrond">
                    <rect className="cls-65" width="360" height="4711.01"/>
                    <rect className="cls-57" x="0" y="0" width="360" height="112.29"/>
                </g>
                <path id="Lijn-2" ref={mobilePath} data-name="Lijn" className="cls-26" d="m279.25,545.78v154.41s1.68,46.38-43.16,46.38h-61.37s-23.82-.56-23.82,20.74v435.77h159.18v161.42s.84,22.28-21.3,22.28h-125.69s-42.46,1.82-42.46,42.04v156.09s-1.26,22.56,22.42,22.56h30.13s23.96-.7,23.96,22v222.51h-24.1v149.09s-1.54,21.02-24.52,21.02h-59.69s-22.14-.42-22.14,22.14v219.57s.42,44,43.72,44h70.2s23.12-.7,23.12,21.72v294.25h66v120.08s-1.12,42.88-43.16,42.88h-69.08s-22.14,0-22.14,21.86v367.11s.56,22.84,22.56,22.84h49.04s44.28-.7,44.28,45.68v331.24s.7,43.58-43.3,43.58h-88s-22.7-.7-22.7,21.44v267.07h115.6v395"/>
                <circle id="Balletje" ref={mobileHero} className="cls-41" cx="279.34" cy="627.52" r="7.1"/>
                <LineMetBall isDesctop={false} url={domainName} />
                <Kinderen isDesctop={false} />
                <Ouders isDesctop={false} />
                <Scholen isDesctop={false} />
                <Zorg isDesctop={false} />
                <Gemeenten isDesctop={false} />
                <Jeugdgezondheidszorg isDesctop={false} />
                <CJG isDesctop={false} />
                <Merkcampagne isDesctop={false} audio={mp3} />
                <Footer isDesctop={false} /> 
                <Logo url={domainName} isDesctop={false} />
                <PulseButton 
                    isDesctop={false}
                    clickHandlerKinderen={clickHandlerKinderen} 
                    clickHandlerZorg={clickHandlerZorg} 
                    clickHandlerCjg={clickHandlerCjg} 
                    clickHandlerOuders={clickHandlerOuders} 
                    clickHandlerGemeenten={clickHandlerGemeenten} 
                    clickHandlerLj={clickHandlerLj} 
                    clickHandlerScholen={clickHandlerScholen}
                    clickHandlerMerkcampagne={clickHandlerMerkcampagne} 
                />
            </svg>
        </div>

        {
            showPopupKinderen &&  
            <WindowPopup toggle={clickHandlerKinderen}>
                <Slider {...settings}>
                    {
                    data.kinderen.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </WindowPopup>
        }
        {
            showPopupOuders &&  
            <WindowPopup toggle={clickHandlerOuders}>
                <Slider {...settings}>
                    {
                    data.ouders.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </WindowPopup>
        }
        {
            showPopupCjg &&  
            <WindowPopup toggle={clickHandlerCjg}>
                <Slider {...settings}>
                    {
                    data.cjg.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </WindowPopup>
        }
        {
            showPopupScholen &&  
            <WindowPopup toggle={clickHandlerScholen}>
                <Slider {...settings}>
                    {
                    data.scholen.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </WindowPopup>
        }
        {
            showPopupZorg &&  
            <WindowPopup toggle={clickHandlerZorg}>
                <Slider {...settings}>
                    {
                    data.zorg.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </WindowPopup>
        }
        {
            showPopupGemeenten &&  
            <WindowPopup toggle={clickHandlerGemeenten}>
                <Slider {...settings}>
                    {
                    data.gemeenten.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </WindowPopup>
        }
        {
            showPopupLj &&  
            <WindowPopup toggle={clickHandlerLj}>
                <Slider {...settings}>
                    {
                    data.landelijke.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </WindowPopup>
        }
        {
            showPopupMerkcampagne &&  
            <WindowPopup toggle={clickHandlerMerkcampagne}>
                <Slider {...settings}>
                    {
                    data.merkcampagne.map((div, index) => {
                        return <div key={index}>{div}</div>
                    })
                    }
                </Slider>
            </WindowPopup>
        }
        </>
    )
}