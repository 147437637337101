import React, { useEffect } from "react";
import { gsap } from "gsap";


export default function LineMetBall(props) {
    const isDesctop = props.isDesctop;

    // Animated ball and the path(desctop):
    useEffect(() => {
        gsap.set('#Vliegtuig', {scale: 0.8});
        gsap.timeline({
            defaults:{duration:40, ease:'none'},
            repeat: -1
        })
        .to('#Vliegtuig', {
            scale: 1,
            motionPath:{
                path: '#Plane_path',
                align: '#Plane_path',
                alignOrigin: [0.5, 1],
                autoRotate: false
            }
        })
    }, []);

    useEffect(() => {
        // gsap.set('#Vliegtuig-m', {scale: 0.8});
        gsap.set('#Plane_path-m', {
            y: 20,
            x: 0
        });
        gsap.timeline({
            defaults:{duration: 40, ease:'none'},
            repeat: -1
        })
        .to('#Vliegtuig-m', {
            scale: 1,
            motionPath:{
                path: '#Plane_path-m',
                align: '#Plane_path-m',
                alignOrigin: [0.5, 1],
                autoRotate: false
            }
        })
    }, []);


    if (isDesctop) {
        return (
            <>
                <g id="Wolken">
                    <path id="Wolk" className="cls-64" d="m211.76,1153.21h-12.48c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h27.74v-.04c.28.01.56.04.85.04,8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11c-.29,0-.57.03-.85.04v-.04h-84.61c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h1.34c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-59.07c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h35.33c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h.49c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h70.16c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-20.89c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h42c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11Z"/>
                    <path id="Wolk-2" data-name="Wolk" className="cls-64" d="m124.98,2673.76h12.48c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-27.74v.04c-.28-.01-.56-.04-.85-.04-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11c.29,0,.57-.03.85-.04v.04h84.61c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-1.34c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h59.07c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-35.33c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h-.49c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-70.16c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h20.89c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-42c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11Z"/>
                    <path id="Wolk-3" data-name="Wolk" className="cls-64" d="m851.8,3099.36h-12.48c-2,0-3.63,1.62-3.63,3.63s1.62,3.63,3.63,3.63h27.74v.04c.28-.01.56-.04.85-.04,8.9,0,16.11,7.21,16.11,16.11s-7.21,16.11-16.11,16.11c-.29,0-.57-.03-.85-.04v.04h-84.61c-8.9,0-16.11-7.21-16.11-16.11s7.21-16.11,16.11-16.11h1.34c2,0,3.63-1.62,3.63-3.63s-1.62-3.63-3.63-3.63h-42.06c-8.9,0-16.11-7.21-16.11-16.11s7.21-16.11,16.11-16.11h18.32c2,0,3.63-1.62,3.63-3.63s-1.62-3.63-3.63-3.63h-33.53c-8.9,0-16.11-7.21-16.11-16.11s7.21-16.11,16.11-16.11h87.17c8.9,0,16.11,7.21,16.11,16.11s-7.21,16.11-16.11,16.11h-3.89c-2,0-3.63,1.62-3.63,3.63s1.62,3.63,3.63,3.63h42c8.9,0,16.11,7.21,16.11,16.11s-7.21,16.11-16.11,16.11Z"/>
                    <path id="Wolk-4" data-name="Wolk" className="cls-67" d="m867.84,2135.37h-52.92c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h16.11c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-14.08c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h44.66c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-138.05c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h36.74c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-10.02c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h16.11c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-88.63c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h26.99c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-16.11c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h154.71c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-9.4c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h33.88c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11Z"/>
                    <path id="Wolk-5" data-name="Wolk" className="cls-67" d="m108.36,3657.94h52.92c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-16.11c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h14.08c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-27.65c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h95.53c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-11.23c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h44.04c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-5.34c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h43.84c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-26.99c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h2.5c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-136c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h4.29c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-33.88c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11Z"/>
                    <path id="Wolk-6" data-name="Wolk" className="cls-64" d="m885.82,4047.13h-9.52c-1.65-.34-2.89-1.79-2.89-3.54,0-1.96,1.56-3.56,3.51-3.61-.04,0-.07,0-.11,0h-.27c8.81-.12,15.92-7.29,15.92-16.13s-7.23-16.15-16.14-16.15h-61.12c-8.92,0-16.15,7.23-16.15,16.15s7.11,16.02,15.93,16.13c-.03,0-.07,0-.1,0,1.95.05,3.51,1.65,3.51,3.61,0,1.75-1.24,3.2-2.89,3.54h-60.87c-8.92,0-16.14,7.23-16.14,16.15s7.23,16.15,16.14,16.15h17.9c1.66.33,2.91,1.78,2.91,3.54,0,1.96-1.56,3.56-3.51,3.61.03,0,.07,0,.11,0h.27c-8.81.12-15.92,7.29-15.92,16.13s7.23,16.15,16.14,16.15h80.12c8.92,0,16.14-7.23,16.14-16.15s-7.23-16.15-16.14-16.15h-17.88c-1.92-.09-3.44-1.67-3.44-3.61,0-1.76,1.25-3.21,2.91-3.54h51.56c8.91,0,16.14-7.23,16.14-16.15s-7.23-16.15-16.14-16.15Z"/>
                </g>
                <g id="Intro">
                    <path id="Wolk-7" data-name="Wolk" className="cls-64" d="m669.97,347.25h-9.52c-1.65-.34-2.89-1.79-2.89-3.54,0-1.96,1.56-3.56,3.51-3.61-.04,0-.07,0-.11,0h-.27c8.81-.12,15.92-7.29,15.92-16.13s-7.23-16.15-16.14-16.15h-103.64c-8.92,0-16.15,7.23-16.15,16.15s7.11,16.02,15.93,16.13c-.03,0,16.94,0,16.91,0,1.95.05,3.51,1.65,3.51,3.61,0,1.75-1.24,3.2-2.89,3.54h-35.36c-8.92,0-16.14,7.23-16.14,16.15s7.23,16.15,16.14,16.15h17.9c1.66.33,2.91,1.78,2.91,3.54,0,1.96-1.56,3.56-3.51,3.61.03,0,.07,0,.11,0h.27c-8.81.12-15.92,7.29-15.92,16.13s7.23,16.15,16.14,16.15h80.12c8.92,0,16.14-7.23,16.14-16.15s-7.23-16.15-16.14-16.15h-17.88c-1.92-.09-3.44-1.67-3.44-3.61,0-1.76,1.25-3.21,2.91-3.54h51.56c8.91,0,16.14-7.23,16.14-16.15s-7.23-16.15-16.14-16.15Z"/>
                    <rect  id="Achtergrond-9" data-name="Achtergrond" className="cls-23" x="39.26" y="103.08" width="542.9" height="433.04" rx="8.5" ry="8.5"/>
                    <text className="cls-40" transform="translate(64.19 153.24)"><tspan className="cls-135"><tspan x="0" y="0">2022 in beeld </tspan></tspan><tspan className="cls-135" x="0" y="43.2">(en in de buurt)</tspan><tspan className="cls-126" x="288.4" y="43.2"> </tspan></text>
                    <text className="cls-37" transform="translate(66.76 250.56)">
                        <tspan className="cls-118" x="0" y="0">In 2022 hadden wij </tspan>
                        <tspan className="cls-30" x="152.94" y="0">284.234</tspan>
                        <tspan className="cls-118" x="218.19" y="0"> contactmomenten, gaven ouders </tspan>
                        <tspan className="cls-118" x="0" y="20">ons het rapportcijfer </tspan>
                        <tspan className="cls-30" x="163.31" y="20">8,2</tspan>
                        <tspan className="cls-118" x="188.56" y="20" > en verstuurden we </tspan>
                        <tspan className="cls-30" x="340.11" y="20">19.000</tspan>
                        <tspan className="cls-118" x="395.36" y="20" > digitale </tspan>
                        <tspan className="cls-118">
                            <tspan x="0" y="40">peuternieuwsbrieven. </tspan>
                        </tspan>
                        <tspan className="cls-118">
                            <tspan x="0" y="80">Huggy </tspan>
                            <tspan className="cls-122" x="55.63" y="80">W</tspan>
                            <tspan className="cls-128" x="71.1" y="80">uggy was een ‘hit’ en de knoopcelbatterij een gevaar. </tspan>
                            </tspan>
                            <tspan className="cls-30" x="0" y="100">43</tspan>
                            <tspan className="cls-118" x="20" y="100" > pubers haalden de gratis SOA-test. Met honderden collega’s </tspan>
                            <tspan className="cls-118" x="0" y="120">maakten we De </tspan>
                            <tspan className="cls-117" x="122.93" y="120">W</tspan>
                            <tspan className="cls-119" x="138.27" y="120">andeling, ter ere van ons </tspan>
                            <tspan className="cls-30" x="334.68" y="120">12,5-jarig</tspan>
                            <tspan className="cls-118" x="417.83" y="120" > bestaan.  </tspan>
                            <tspan className="cls-118" x="0" y="160">Er was ook veel ‘nieuw’ dit jaar: </tspan>
                            <tspan className="cls-30" x="246.04" y="160">88</tspan>
                            <tspan className="cls-118" x="266.04" y="160" > collega’s, de CJG Rijnmond </tspan>
                            <tspan className="cls-118">
                            <tspan x="0" y="180">merkcampagne en wethouders in diverse gemeenten. </tspan>
                            </tspan><tspan className="cls-118"><tspan className="cls-142" x="0" y="220">W</tspan>
                            <tspan className="cls-141" x="15.34" y="220">andel door ons online jaarverslag 2022 en stop bij de + </tspan>
                            </tspan>
                            <tspan className="cls-118" x="0" y="240">als je meer wilt weten. </tspan>
                            <a href={`${props.url}CJG_Rijnmond_Jaarverslag_2022.pdf`} target="_blank" rel="noopener noreferrer">
                                <tspan className="cls-118 white-text" x="176.13" y="240" >En sta gerust ook even stil bij het </tspan>
                                <tspan className="cls-120 white-text" x="0" y="260">financiële jaarverslag.</tspan>
                            </a>
                    </text>
                    <path id="Wolk-8" data-name="Wolk" className="cls-67" d="m879.88,216.06h-52.92c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h16.11c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-14.08c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h27.65c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-95.53c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h11.23c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-44.04c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h5.34c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-43.84c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h26.99c2,0,3.63,1.62,3.63,3.63s-1.62,3.63-3.63,3.63h-2.5c-8.9,0-16.11,7.21-16.11,16.11s7.21,16.11,16.11,16.11h136c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11h-4.29c-2,0-3.63-1.62-3.63-3.63s1.62-3.63,3.63-3.63h33.88c8.9,0,16.11-7.21,16.11-16.11s-7.21-16.11-16.11-16.11Z"/>
                    <path id="Plane_path" className="cls-plane-path" d="M770.958 316C767.74 316 764.521 316 761.303 316C757.793 316 754.348 317.626 751.111 318.95C743.368 322.118 735.19 323.876 729.119 329.946C724.192 334.873 720 340.445 720 347.618C720 355.122 720.805 361.172 724.172 368.031C726.961 373.711 729.749 378.244 733.678 383.08C738.619 389.161 745.501 392.445 752.721 394.851C762.675 398.169 771.975 399.679 782.461 399.679C792.764 399.679 800.268 394.291 808.506 388.951C816.077 384.044 821.107 379.429 824.211 370.594C826.471 364.163 824.878 358.081 822.87 352.058C820.368 344.551 817.802 336.886 812.261 331.019C810.061 328.69 807.813 326.081 804.99 324.284C803.199 323.145 801.508 321.716 799.507 320.947C797.432 320.149 794.744 319.238 792.832 318.146C789.346 316.154 784.257 316.536 780.345 316.536C777.384 316.536 775.01 316 772.031 316" />
                    <g id="Vliegtuig">
                    <polygon className="cls-97" points="829.01 296.38 856.77 297.87 858.67 311.73 857.78 337.86 846.96 338.29 842.64 310.75 829.01 309.36 829.01 296.38"/>
                    <path className="cls-86" d="m857.63,304.11c1.98-1.99-.86-8.33-.73-11.15.19-4.17-.04-5.57-.37-11.79-.01-.2-.02-.41.09-.58.1-.16.29-.24.47-.31,1.03-.41,2.05-.82,3.07-1.23.19-.07.41-.19.42-.39.01-.12-.05-.23-.12-.33-.78-1.28-1.56-2.56-2.34-3.84-1.1-1.8-2.23-3.64-3.89-4.94-1.27-1-2.78-1.6-4.3-2.11-1.36-.46-2.06-1.19-3.27-1.92-2.4-1.45-5.21-2.11-7.99-1.61-2.94.53-4.4,2.39-6.7,3.9-2.61,1.72-6.54,2.33-9.59,2.49.03,3.39.47,6.78,1.31,10.07,1.56-.18,3.12-.35,4.68-.53.15-.02-.23,20.68.4,22.6"/>
                    <path className="cls-74" d="m822.44,271.47c-.12-.01-.24-.01-.36-.03-2.5-.36-7.25-1.65-7.41-4.97-.01-.24,0-.48.02-.71.02-.38.05-.76-.05-1.13-.18-.68-.75-1.21-1.39-1.46-1.33-.52-3.24-.36-4.3.65-1.01.97-1.36,2.6-.89,3.96.42,1.22,1.3,1.82,2.34,2.42,1.04.59,1.7,1.28,2.66,2.11,2,1.72,4.39,2.93,6.81,3.85,1.02.39,2.13.8,3.13,1l-.57-5.67Z"/>
                    <g>
                        <ellipse className="cls-74" cx="841.49" cy="252.3" rx="20.15" ry="21.14"/>
                        <path className="cls-23" d="m832.41,253.09c0-.83.67-1.5,1.5-1.5s1.5.67,1.5,1.5-.67,1.5-1.5,1.5-1.5-.67-1.5-1.5Z"/>
                        <path className="cls-23" d="m847.08,253.09c0-.83.67-1.5,1.5-1.5s1.5.67,1.5,1.5-.67,1.5-1.5,1.5-1.5-.67-1.5-1.5Z"/>
                        <path className="cls-10" d="m836.36,265.35c1.35,2.44,4.42,3.33,6.86,1.98"/>
                    </g>
                    <path className="cls-74" d="m822.02,253.63c.79,2.96,0,5.73-1.75,6.2-1.76.47-3.82-1.55-4.61-4.5-.79-2.95,0-5.73,1.75-6.2,1.76-.47,3.82,1.55,4.61,4.5Z"/>
                    <path className="cls-74" d="m858.52,253.63c-.79,2.96,0,5.73,1.75,6.2,1.76.47,3.82-1.55,4.61-4.5.79-2.95,0-5.73-1.75-6.2-1.76-.47-3.82,1.55-4.61,4.5Z"/>
                    <path className="cls-56" d="m841.93,222.93c2.44-1.08,5.19-1.48,7.84-1.08,3.51.53,6.68,2.35,9.54,4.45,4.06,2.99,7.86,7.02,8.43,12.03.28,2.46-.72,4.66-.72,7.03,0,.63.97,5.12,1.89,4.93-1.57.33-3.3-.32-4.27-1.6.43.57-2.43,4.8-2.83,5.3-.1.12-.21.25-.36.31-.59.23-.93-.65-.98-1.28-.25-3.87-1.91-7.64-4.58-10.44-2.68-2.81-6.36-4.64-10.21-5.08.98.45,1.54,1.54,1.63,2.62.1,1.07-.2,2.14-.55,3.16-1.38.13-2.16-1.47-3.14-2.45-1.57-1.59-4.06-1.68-6.2-2.32-2.25-.67-4.28-2.05-5.74-3.89-.33.98-5.33,11.51-4.2,12.12-1.01-.54-1.72-1.61-1.81-2.75-2.09,3.85-3.24,8.21-3.33,12.6,0,.28-.08.65-.36.69-1.25-2.21-1.77-5.31-3.45-7.18-.41-.46-1.03-.79-1.64-.68,0-2.85.11-5.7.1-8.54-.01-2.52.04-5.13,1.08-7.47,1.11-2.49,3.33-4.12,5.57-5.53,1.14-.72,2.19-1.33,3.54-1.6.3-.06,3.91-.02,3.77.24,2.19-3.96,6.94-6.35,11.43-5.73-1.18.85-2.1,2.04-2.63,3.39.7-.48,1.44-.89,2.21-1.23Z"/>
                    <g>
                        <path className="cls-23" d="m842.57,287.17h4.47c.92,0,1.67.75,1.67,1.67v10.95c0,.92-.75,1.67-1.67,1.67h-4.47c-.92,0-1.67-.75-1.67-1.67v-10.95c0-.92.75-1.67,1.67-1.67Z" transform="translate(-43.53 400.01) rotate(-25.99)"/>
                        <path className="cls-74" d="m850.18,282.14c-.06,2.15-.24,4.31-.74,6.4-.46,1.9-1.08,2.44-2.71,3.2-1.57.73-2.56,2.41-1.96,4.15.47,1.36,1.88,2.32,3.32,2.31,3.15-.03,3.06-2.97,4.36-4.98,1.3-2.02,2.33-4.32,2.66-6.72.26-1.9.42-4.03.28-6.12-1.76.71-3.47,1.42-5.2,1.78Z"/>
                        <path className="cls-70" d="m843.07,289.43c0-.36-.29-.65-.65-.65s-.65.29-.65.65.29.65.65.65.65-.29.65-.65Z"/>
                    </g>
                    <g>
                        <path className="cls-60" d="m802.87,314.22c-36.52-33.81-41.6-36.64-41.6-36.64-16.13-8.95-21.82,1.9-12.71,24.23,9.11,22.33,33.96,48.04,50.09,56.99l4.22-44.57Z"/>
                        <path className="cls-23" d="m729.79,348.2c40.74-2.55,119.57,10.99,147.82-.8,11.27-2.88,16.61-5.27,19.1-6.08,2.49-.81,4.53-2.2,4.53-4.9v-34.15c0-12.21-2.59-14.41-6.83-14.41-17.29,0-8.46,29.2-28.54,29.2-7.5,0-35.89-17.93-63.38-17.95-24.99-.02-43.48,15.5-56.33,17.64-26.05,4.34-26.05,14.27-26.05,16.96v9.6c0,2.69,5.67,4.9,9.68,4.9Z"/>
                        <path className="cls-60" d="m890.65,333.19c.5,0,.98.17,1.37.47,8.24,6.4,9.02,7.32,9.02,7.32,2.58,3.04.42,4.16-4.82,2.5-3.29-1.04-8.57-3.63-12.68-6.17-1.91-1.18-1.06-4.12,1.18-4.12h5.93Z"/>
                        <path className="cls-60" d="m826.65,338.32c.68-.04,1.33.12,1.83.47,47.63,32.68,47.85,34.4,47.85,34.4,14.29,14.6,2.8,19.62-25.66,11.22-25.73-7.59-57.7-26.77-74.16-41.17-1.83-1.6-.22-4.71,2.52-4.89l47.62-.03Z"/>
                        <path className="cls-23" d="m786.16,268.34s1.11,27.03-.08,31.41,35.74.18,35.74.18l-1.06-32.96-20.65-7.22-13.95,8.59Z"/>
                        <path className="cls-45" d="m791.11,316.59c3.79,1.18,7.93,2.15,11.69.85,3.15-1.08,5.59.22,7.17-2.67,2.99-5.45,4.98-11.86,3-17.88-1.5-4.57-5.28-9.56-10.71-9.26-3.41.19-6.1,2.56-7.74,5.36-.62,1.05-3.01,12.25-1.46,13.04-1.43-1.04-3.86-2.47-4.27-2.67-.86-.41-2.03-.33-2.91.15-1.29.7-2.14,1.96-3.15,3.02-1.01,1.05-3.56,5.38-4.99,5.08.69-1.14-.4-2.76-1.75-2.86s-2.57,1.02-2.93,2.3c-.65,2.34,5.83,1.23,8.14,2.08,2.31.86,4.9.47,7.29-.15-1.21,2.18-2.88,4.1-4.89,5.6-1.12-1.23-2.77-2.57-4.28-1.85"/>
                        <path className="cls-65" d="m787.67,280.92c.78,2.35.26,4.63-1.16,5.09-1.42.46-3.21-1.08-3.99-3.43-.78-2.35-.26-4.63,1.16-5.09,1.42-.46,3.21,1.08,3.99,3.43Z"/>
                        <path className="cls-65" d="m785.06,276.79c.55,9.98,8.92,17.64,18.69,17.1,9.77-.54,17.24-9.07,16.69-19.05s-8.92-17.64-18.69-17.1c-9.77.54-17.24,9.07-16.69,19.05Z"/>
                        <path className="cls-23" d="m803.44,256.72c2.78-1.98,5.49-2.38,8.67-.98,3.06,1.34,6.23,3.93,7.5,7.05.77,1.9,1.15,3.84,1.25,5.88.05,1,.04,1.99,0,2.99-.03.84-.35,1.89-.42,3.18-.71-3.24-3.11-6.08-5.83-8.04,3.34,4.08,5.98,9.04,5.82,14.28-.12,4.05-1.91,7.9-4.24,11.24.2-.29,3.58-6.73,1.36-13.19-3.8-11.07-14.82-15.35-14.81-15.37-1.16,2.14-3.43,3.42-5.44,4.83-5.52,3.87-9.58,9.63-10.5,16.32-.71-1.72-1.15-3.53-1.59-5.33-.39-1.58-.77-3.16-1.16-4.73-.55-2.24-.59-4.97.04-7.19.74-2.61,2.3-4.93,4.14-6.94,1.92-2.09,4.24-3.92,6.95-4.81,2.72-.89,5.88-.75,8.25.83Z"/>
                        <path className="cls-23" d="m794.97,278.02c-.04-.71.51-1.32,1.22-1.36s1.32.51,1.36,1.22-.51,1.32-1.22,1.36-1.32-.51-1.36-1.22Z"/>
                        <path className="cls-23" d="m807.56,277.33c-.04-.71.51-1.32,1.22-1.36s1.32.51,1.36,1.22-.51,1.32-1.22,1.36-1.32-.51-1.36-1.22Z"/>
                        <path className="cls-19" d="m798.58,287.79c1.47,2.35,5.12,2.7,8.16.8"/>
                        <path className="cls-23" d="m790.15,308.98s17.62,1.27,39.38-5.19l-4.57,17.97s-16.13,2.08-28.25,1.51-6.56-14.28-6.56-14.28Z"/>
                        <path className="cls-38" d="m775.87,305.15c.77,1.01,3.69,3.32,14.27,3.83,6.35.31,13.42,14.09,7.07,14.27-.17,0-.34,0-.51.01-16.38.27-33.3-3.95-39.43-9.28-.39-.34-.29-.96.17-1.17,2.77-1.29,11.05-5.14,17.59-7.89.3-.13.64-.03.84.23Z"/>
                        <path className="cls-65" d="m813.41,298.5c-.82,0-1.41-2.53-1.16-2.83.61-.27,5.02-3.26,5.39-3.67,1.44-1.58,2.86-3.25,3.89-5.14.69-1.27.61-1.88.09-3.11-.5-1.19-.21-2.61.97-3.25.93-.51,2.19-.32,2.97.42,1.72,1.61.22,3.17-.06,4.92-.28,1.76-.71,3.65-1.86,5.01-1.98,2.36-4.32,5.43-7.5,6.58-.39.14-2.47,1.08-2.72,1.08Z"/>
                    </g>
                    <path className="cls-56" d="m842.79,347.2c-.42-.15-.74-.5-.95-.89-.71-1.3-.28-2.99.65-4.14s2.26-1.89,3.56-2.61c1.53-.84,3.07-1.68,4.73-2.21,1.66-.53,3.47-.74,5.15-.28.64,1.06.41,2.48-.26,3.52-.68,1.04-1.72,1.78-2.78,2.44-2.61,1.64-5.42,2.98-8.33,3.99-.58.2-1.21.38-1.78.17Z"/>
                    <polygon className="cls-97" points="829.01 303.09 858.1 307.58 858.67 311.73 857.78 337.86 846.96 338.29 842.64 310.75 829.01 303.63 829.01 303.09"/>
                    </g>
                </g>
            </>
        )
    }
    return (
        <>
            
            <g id="Wolken">
    <path id="Wolk" className="cls-52" d="m274.86,2306.45h5.41c.87,0,1.57.7,1.57,1.57s-.7,1.57-1.57,1.57h-12.02v.02c-.12,0-.24-.02-.37-.02-3.86,0-6.98,3.13-6.98,6.98s3.13,6.98,6.98,6.98c.12,0,.25-.01.37-.02v.02h36.66c3.86,0,6.98-3.13,6.98-6.98s-3.13-6.98-6.98-6.98h-.58c-.87,0-1.57-.7-1.57-1.57s.7-1.57,1.57-1.57h25.59c3.86,0,6.98-3.13,6.98-6.98s-3.13-6.98-6.98-6.98h-15.31c-.87,0-1.57-.7-1.57-1.57s.7-1.57,1.57-1.57h-.21c3.85,0,6.98-3.13,6.98-6.98s-3.13-6.98-6.98-6.98h-30.4c-3.86,0-6.98,3.13-6.98,6.98s3.13,6.98,6.98,6.98h9.05c.87,0,1.57.7,1.57,1.57s-.7,1.57-1.57,1.57h-18.2c-3.86,0-6.98,3.13-6.98,6.98s3.13,6.98,6.98,6.98Z"/>
    <path id="Wolk-2" data-name="Wolk" className="cls-62" d="m65.8,3233.59h13.91c.87,0,1.57.7,1.57,1.57s-.7,1.57-1.57,1.57h-.96v.02c-.12,0-.24-.02-.37-.02-3.86,0-6.98,3.13-6.98,6.98s3.13,6.98,6.98,6.98c.12,0,.25-.01.37-.02v.02h25.61c3.86,0,6.98-3.13,6.98-6.98s-3.13-6.98-6.98-6.98h-.58c-.87,0-1.57-.7-1.57-1.57s.7-1.57,1.57-1.57h21.34c3.86,0,6.98-3.13,6.98-6.98s-3.13-6.98-6.98-6.98h-11.06c-.87,0-1.57-.7-1.57-1.57s.7-1.57,1.57-1.57h-.21c3.85,0,6.98-3.13,6.98-6.98s-3.13-6.98-6.98-6.98h-38.91c-3.86,0-6.98,3.13-6.98,6.98s3.13,6.98,6.98,6.98h.55c.87,0,1.57.7,1.57,1.57s-.7,1.57-1.57,1.57h-9.7c-3.86,0-6.98,3.13-6.98,6.98s3.13,6.98,6.98,6.98Z"/>
    <path id="Wolk-3" data-name="Wolk" className="cls-52" d="m92.78,2768.55h-5.43c-.87,0-1.58.71-1.58,1.58s.71,1.58,1.58,1.58h12.06v.02c.12,0,.25-.02.37-.02,3.87,0,7,3.14,7,7s-3.14,7-7,7c-.13,0-.25-.01-.37-.02v.02h-36.79c-3.87,0-7-3.14-7-7s3.14-7,7-7h.58c.87,0,1.58-.71,1.58-1.58s-.71-1.58-1.58-1.58h-18.29c-3.87,0-7-3.14-7-7s3.14-7,7-7h7.96c.87,0,1.58-.71,1.58-1.58s-.71-1.58-1.58-1.58h-14.58c-3.87,0-7-3.14-7-7s3.14-7,7-7h37.9c3.87,0,7,3.14,7,7s-3.14,7-7,7h-1.69c-.87,0-1.58.71-1.58,1.58s.71,1.58,1.58,1.58h18.26c3.87,0,7,3.14,7,7s-3.14,7-7,7Z"/>
    <path id="Wolk-4" data-name="Wolk" className="cls-62" d="m325.42,1960.51h-4.14c-.72-.15-1.26-.78-1.26-1.54,0-.85.68-1.55,1.53-1.57-.02,0-.03,0-.05,0h-.12c3.83-.05,6.92-3.17,6.92-7.01s-3.14-7.02-7.02-7.02h-26.57c-3.88,0-7.02,3.14-7.02,7.02s3.09,6.96,6.92,7.01c-.01,0-.03,0-.04,0,.85.02,1.53.72,1.53,1.57,0,.76-.54,1.39-1.26,1.54h-26.46c-3.88,0-7.02,3.14-7.02,7.02s3.14,7.02,7.02,7.02h7.78c.72.14,1.27.78,1.27,1.54,0,.85-.68,1.55-1.53,1.57.02,0,.03,0,.05,0h.12c-3.83.05-6.92,3.17-6.92,7.01s3.14,7.02,7.02,7.02h34.83c3.88,0,7.02-3.14,7.02-7.02s-3.14-7.02-7.02-7.02h-7.77c-.83-.04-1.5-.73-1.5-1.57,0-.76.54-1.4,1.27-1.54h22.42c3.88,0,7.02-3.14,7.02-7.02s-3.14-7.02-7.02-7.02Z"/>
    <path id="Wolk-5" data-name="Wolk" className="cls-52" d="m321.26,3604.85h-4.14c-.72-.15-1.26-.78-1.26-1.54,0-.85.68-1.55,1.53-1.57-.02,0-.03,0-.05,0h-.12c3.83-.05,6.92-3.17,6.92-7.01s-3.14-7.02-7.02-7.02h-26.57c-3.88,0-7.02,3.14-7.02,7.02s3.09,6.96,6.92,7.01c-.01,0-.03,0-.04,0,.85.02,1.53.72,1.53,1.57,0,.76-.54,1.39-1.26,1.54h-26.46c-3.88,0-7.02,3.14-7.02,7.02s3.14,7.02,7.02,7.02h7.78c.72.14,1.27.78,1.27,1.54,0,.85-.68,1.55-1.53,1.57.02,0,.03,0,.05,0h.12c-3.83.05-6.92,3.17-6.92,7.01s3.14,7.02,7.02,7.02h34.83c3.88,0,7.02-3.14,7.02-7.02s-3.14-7.02-7.02-7.02h-7.77c-.83-.04-1.5-.73-1.5-1.57,0-.76.54-1.4,1.27-1.54h22.42c3.88,0,7.02-3.14,7.02-7.02s-3.14-7.02-7.02-7.02Z"/>
    <path id="Wolk-6" data-name="Wolk" className="cls-52" d="m336.96,1419.9h-5.41c-.87,0-1.57-.7-1.57-1.57s.7-1.57,1.57-1.57h12.02v-.02c.12,0,.24.02.37.02,3.86,0,6.98-3.13,6.98-6.98s-3.13-6.98-6.98-6.98c-.12,0-.25.01-.37.02v-.02h-36.66c-3.86,0-6.98,3.13-6.98,6.98s3.13,6.98,6.98,6.98h.58c.87,0,1.57.7,1.57,1.57s-.7,1.57-1.57,1.57h-25.59c-3.86,0-6.98,3.13-6.98,6.98s3.13,6.98,6.98,6.98h15.31c.87,0,1.57.7,1.57,1.57s-.7,1.57-1.57,1.57h.21c-3.85,0-6.98,3.13-6.98,6.98s3.13,6.98,6.98,6.98h30.4c3.86,0,6.98-3.13,6.98-6.98s-3.13-6.98-6.98-6.98h-9.05c-.87,0-1.57-.7-1.57-1.57s.7-1.57,1.57-1.57h18.2c3.86,0,6.98-3.13,6.98-6.98s-3.13-6.98-6.98-6.98Z"/>
            </g>
            <g id="Intro">
            <path id="Wolk-7" data-name="Wolk" className="cls-62" d="m194.48,608.17h-22.88c-.87,0-1.57-.7-1.57-1.57s.7-1.57,1.57-1.57h6.96c3.85,0,6.96-3.12,6.96-6.96s-3.12-6.96-6.96-6.96h-6.09c-.87,0-1.57-.7-1.57-1.57s.7-1.57,1.57-1.57h11.95c3.85,0,6.96-3.12,6.96-6.96s-3.12-6.96-6.96-6.96h-41.3c-3.85,0-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96h4.86c.87,0,1.57.7,1.57,1.57s-.7,1.57-1.57,1.57h-19.04c-3.85,0-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96h2.31c.87,0,1.57.7,1.57,1.57s-.7,1.57-1.57,1.57h-18.95c-3.85,0-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96h11.67c.87,0,1.57.7,1.57,1.57s-.7,1.57-1.57,1.57h-1.08c-3.85,0-6.96,3.12-6.96,6.96s3.12,6.96,6.96,6.96h58.8c3.85,0,6.96-3.12,6.96-6.96s-3.12-6.96-6.96-6.96h-1.86c-.87,0-1.57-.7-1.57-1.57s.7-1.57,1.57-1.57h14.65c3.85,0,6.96-3.12,6.96-6.96s-3.12-6.96-6.96-6.96Z"/>
            <rect id="Achtergrond-9" data-name="Achtergrond" className="cls-41" x="28.42" y="103.08" width="303.85" height="484.89" rx="7.06" ry="7.06"/>
            <text className="cls-58" transform="translate(44.86 153.24)"><tspan className="cls-123"><tspan x="0" y="0">2022  in </tspan></tspan><tspan className="cls-123"><tspan x="0" y="43.2">beeld (en in </tspan></tspan><tspan className="cls-123" x="0" y="86.4">de buurt)</tspan><tspan className="cls-116" x="171.61" y="86.4"> </tspan></text>
    <g>
      <path className="cls-57" d="m48.55,270.48h1.12v9h-1.12v-9Z"/>
      <path className="cls-57" d="m52.24,273.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m62.94,278.3c0-.93.37-2.13,1.78-2.9l1.03-.56c.91-.49,1.42-.89,1.42-1.87,0-1.22-.66-1.5-1.69-1.5h-2.34v-.98h2.34c1.87,0,2.81.79,2.81,2.48,0,1.31-.73,2-1.97,2.67l-1.03.56c-1.06.56-1.21,1.31-1.21,2.15v.14h4.31v.98h-5.43v-1.18Z"/>
      <path className="cls-57" d="m70.15,274.98c0-3.09.94-4.59,3-4.59s3,1.5,3,4.59-.95,4.59-3,4.59-3-1.5-3-4.59Zm4.87,0c0-2.63-.47-3.61-1.87-3.61s-1.88.98-1.88,3.61.47,3.61,1.88,3.61,1.87-.98,1.87-3.61Z"/>
      <path className="cls-57" d="m77.94,278.3c0-.93.37-2.13,1.78-2.9l1.03-.56c.91-.49,1.42-.89,1.42-1.87,0-1.22-.66-1.5-1.69-1.5h-2.34v-.98h2.34c1.87,0,2.81.79,2.81,2.48,0,1.31-.73,2-1.97,2.67l-1.03.56c-1.06.56-1.21,1.31-1.21,2.15v.14h4.31v.98h-5.43v-1.18Z"/>
      <path className="cls-57" d="m85.44,278.3c0-.93.37-2.13,1.78-2.9l1.03-.56c.91-.49,1.42-.89,1.42-1.87,0-1.22-.66-1.5-1.69-1.5h-2.34v-.98h2.34c1.87,0,2.81.79,2.81,2.48,0,1.31-.73,2-1.97,2.67l-1.03.56c-1.06.56-1.21,1.31-1.21,2.15v.14h4.31v.98h-5.43v-1.18Z"/>
      <path className="cls-57" d="m102.27,279.28c-.36.1-1.08.19-1.83.19h-.13v-4.4c0-1.03-.43-1.32-1.03-1.32-.66,0-1.32.71-1.69,1.08l-.28.29v4.35h-1.03v-8.02c-.4.02-.76.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v3.61l.37-.37c.47-.48,1.03-.9,1.87-.9,1.03,0,1.79.56,1.79,2.25v3.44c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m103.45,277.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m114.03,270.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m121.38,270.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m125.51,276.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m132.63,273.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m142.95,274.61v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.17-2.24,1.17-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.17,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.17-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path className="cls-57" d="m151.9,273.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m155.84,273.11c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m162.78,278.07c0-1.22.42-1.93,1.68-2.82l1.22-.84c.32-.22.93-.66.93-1.31s-.37-.85-1.31-.85h-2.25v-1.78h2.81c1.77,0,2.82.94,2.82,2.53,0,1.4-.78,1.94-1.97,2.76l-1.03.71c-.43.29-.76.61-.76,1.13v.1h3.93v1.78h-6.08v-1.4Z"/>
      <path className="cls-57" d="m170.09,276.95c0-.79.44-1.44,1.03-1.87l.34-.25c-.71-.42-1.18-1.09-1.18-2.05,0-1.68,1.45-2.39,2.95-2.39h.28c1.79,0,2.95.61,2.95,2.39,0,.66-.42,1.37-.84,1.74l-.25.23c.8.44,1.28,1.15,1.28,2.21,0,1.69-1.5,2.63-3.14,2.63h-.28c-1.92,0-3.14-.71-3.14-2.63Zm3.42.84c.66,0,1.08-.32,1.08-.94,0-.74-.52-.95-1.08-1.16l-.62-.25-.36.38c-.26.3-.37.66-.37,1.03,0,.66.42.94,1.08.94h.28Zm.61-3.93c.25-.25.37-.56.37-.94,0-.52-.47-.74-.98-.74h-.28c-.52,0-.98.23-.98.74,0,.61.41.73.94.94l.68.28.25-.28Z"/>
      <path className="cls-57" d="m181.38,278.02h-3.69v-1.36c.94-1.88,1.5-4.41,1.69-6.19h2.24c-.28,2.06-.93,4.08-1.87,5.76h1.63v-1.63h2.06v1.63h.8v1.79h-.8v1.45h-2.06v-1.45Z"/>
      <path className="cls-57" d="m185.56,277.41h2.06v2.06h-2.06v-2.06Z"/>
      <path className="cls-57" d="m189.22,278.07c0-1.22.42-1.93,1.68-2.82l1.22-.84c.32-.22.93-.66.93-1.31s-.37-.85-1.31-.85h-2.25v-1.78h2.81c1.77,0,2.82.94,2.82,2.53,0,1.4-.78,1.94-1.97,2.76l-1.03.71c-.43.29-.76.61-.76,1.13v.1h3.93v1.78h-6.08v-1.4Z"/>
      <path className="cls-57" d="m197,277.7h2.63c.74,0,1.31-.29,1.31-1.03s-.56-.95-1.31-.95h-1.69v-1.5h1.94c.47-.28.88-.67.88-1.16,0-.66-.47-.8-1.13-.8h-2.53v-1.78h2.72c1.73,0,3,.42,3,2.16,0,.64-.37,1.38-1.14,1.91.83.3,1.32.92,1.32,2.12,0,1.78-1.36,2.81-3.18,2.81h-2.82v-1.78Z"/>
      <path className="cls-57" d="m207.82,278.02h-3.69v-1.36c.94-1.88,1.5-4.41,1.69-6.19h2.24c-.28,2.06-.93,4.08-1.87,5.76h1.63v-1.63h2.06v1.63h.8v1.79h-.8v1.45h-2.06v-1.45Z"/>
      <path className="cls-57" d="m218.56,275.07c.05-.24.1-.68.12-1.13-.18-.1-.56-.19-.91-.19-.94,0-1.77.52-1.77,1.88v1.12c0,1.22.65,1.88,1.77,1.88.74,0,1.36-.24,1.69-.42h.08v.94c-.37.18-1.03.42-1.78.42-1.69,0-2.81-.94-2.81-2.82v-1.12c0-1.83,1.4-2.82,2.81-2.82.74,0,1.5.24,1.87.47,0,.76-.1,1.44-.18,1.79h-.9Z"/>
      <path className="cls-57" d="m220.91,276.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m228.74,273.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m236.61,277.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m240.78,277.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m251.74,275.07c.05-.24.1-.68.12-1.13-.18-.1-.56-.19-.91-.19-.94,0-1.77.52-1.77,1.88v1.12c0,1.22.65,1.88,1.77,1.88.74,0,1.36-.24,1.69-.42h.08v.94c-.37.18-1.03.42-1.78.42-1.69,0-2.81-.94-2.81-2.82v-1.12c0-1.83,1.4-2.82,2.81-2.82.74,0,1.5.24,1.87.47,0,.76-.1,1.44-.18,1.79h-.9Z"/>
      <path className="cls-57" d="m254.84,277.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m259.05,274.61h3.38v.94h-3.38v-.94Z"/>
      <path className="cls-57" d="m47.94,289.11c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m59.99,292.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m66.87,289.11c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m78.97,292.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m86.09,289.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m93.96,293.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m98.13,292.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m105.25,289.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m112.8,294.35h1.13v.56l-1.13,2.06h-.71l.71-2.63Z"/>
      <path className="cls-57" d="m119.41,296.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m125.97,293.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m133.28,290.61v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path className="cls-57" d="m139.61,292.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m146.72,289.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m157.04,292.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m163.88,289.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m175.32,286.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m179.45,292.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m185.62,289.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m190.92,295.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m200.07,292.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m207.9,289.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m215.25,295.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m230.92,295.28c-.36.1-1.08.19-1.83.19h-.13v-4.4c0-1.03-.43-1.32-1.03-1.32-.66,0-1.32.71-1.69,1.08l-.28.29v4.35h-1.03v-8.02c-.4.02-.76.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v3.61l.37-.37c.47-.48,1.03-.9,1.87-.9,1.03,0,1.79.56,1.79,2.25v3.44c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m232.1,292.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m239.17,293.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m246.34,289.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m251.46,293.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m258.62,289.11c.35-.1,1.08-.19,1.82-.19h.14v1.22l.47-.47c.48-.48,1.03-.85,1.87-.85,1.03,0,1.88.66,1.88,2.25v1.03c0,2.06-1.69,3.37-3.47,3.37h-.76v2.25h-1.03v-7.84c-.38.02-.74.06-.94.11v-.89Zm1.97,2.06v3.37h.76c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.66,0-1.4.66-1.79,1.03l-.37.38Z"/>
      <path className="cls-57" d="m266.02,289.11c.35-.1,1.08-.19,1.82-.19h.14v1.22l.47-.47c.48-.48,1.03-.85,1.87-.85,1.03,0,1.88.66,1.88,2.25v1.03c0,2.06-1.69,3.37-3.47,3.37h-.76v2.25h-1.03v-7.84c-.38.02-.74.06-.94.11v-.89Zm1.97,2.06v3.37h.76c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.66,0-1.4.66-1.79,1.03l-.37.38Z"/>
      <path className="cls-57" d="m273.57,292.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m280.46,289.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m286.27,293.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m294.04,291.07c.05-.24.1-.68.12-1.13-.18-.1-.56-.19-.91-.19-.94,0-1.77.52-1.77,1.88v1.12c0,1.22.65,1.88,1.77,1.88.74,0,1.36-.24,1.69-.42h.08v.94c-.37.18-1.03.42-1.78.42-1.69,0-2.81-.94-2.81-2.82v-1.12c0-1.83,1.4-2.82,2.81-2.82.74,0,1.5.24,1.87.47,0,.76-.1,1.44-.18,1.79h-.9Z"/>
      <path className="cls-57" d="m296.25,289.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m300.19,289.11c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m304.69,289.85h-1.03v-.74c.32-.1.67-.17,1.03-.19v-.28c0-1.42.85-2.16,2.16-2.16.24,0,.5.05.74.14v.79h-.74c-.84,0-1.13.37-1.13,1.22v.28h1.69v.94h-1.69v5.62h-1.03v-5.62Z"/>
      <path className="cls-57" d="m308.12,292.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m314.3,289.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m47.89,308.95c0-.79.44-1.44,1.03-1.87l.34-.25c-.71-.42-1.18-1.09-1.18-2.05,0-1.68,1.45-2.39,2.95-2.39h.28c1.79,0,2.95.61,2.95,2.39,0,.66-.42,1.37-.84,1.74l-.25.23c.8.44,1.28,1.15,1.28,2.21,0,1.69-1.5,2.63-3.14,2.63h-.28c-1.92,0-3.14-.71-3.14-2.63Zm3.42.84c.66,0,1.08-.32,1.08-.94,0-.74-.52-.95-1.08-1.16l-.62-.25-.36.38c-.26.3-.37.66-.37,1.03,0,.66.42.94,1.08.94h.28Zm.61-3.93c.25-.25.37-.56.37-.94,0-.52-.47-.74-.98-.74h-.28c-.52,0-.98.23-.98.74,0,.61.41.73.94.94l.68.28.25-.28Z"/>
      <path className="cls-57" d="m56,309.41h2.06v.75l-1.55,2.81h-1.45l.94-3.56Z"/>
      <path className="cls-57" d="m59.52,310.07c0-1.22.42-1.93,1.68-2.82l1.22-.84c.32-.22.93-.66.93-1.31s-.37-.85-1.31-.85h-2.25v-1.78h2.81c1.77,0,2.82.94,2.82,2.53,0,1.4-.78,1.94-1.97,2.76l-1.03.71c-.43.29-.76.61-.76,1.13v.1h3.93v1.78h-6.08v-1.4Z"/>
      <path className="cls-57" d="m70.26,308.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m77.37,305.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m87.69,306.61v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path className="cls-57" d="m94.02,308.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m100.2,305.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m105.49,311.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m112.2,309.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m116.13,305.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m124.1,305.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m132.12,305.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m140.45,302.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m144.58,308.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m151.7,305.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m162.02,306.61v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.17-2.24,1.17-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.17,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.17-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path className="cls-57" d="m171.16,308.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m183.21,304.74l-2.16.84h-.1v-1.87l3-1.22h1.31v9h-2.05v-6.74Z"/>
      <path className="cls-57" d="m188.74,309.7h1.97c.94,0,1.68-.56,1.68-1.5v-.02c-.46.22-.96.3-1.5.3-1.87,0-2.81-1.13-2.81-3s1.36-3,3.27-3,3.09,1.13,3.09,3v2.43c0,2.34-1.68,3.56-4.03,3.56h-1.68v-1.78Zm3.65-3.08v-1.14c0-.75-.37-1.22-1.12-1.22s-1.13.43-1.13,1.22.28,1.31,1.13,1.31c.36,0,.76-.05,1.12-.17Z"/>
      <path className="cls-57" d="m195.96,309.41h2.06v2.06h-2.06v-2.06Z"/>
      <path className="cls-57" d="m199.33,306.98c0-3.47,1.13-4.59,3.38-4.59s3.37,1.13,3.37,4.59-1.13,4.59-3.37,4.59-3.38-1.22-3.38-4.59Zm4.69,0c0-2.25-.29-2.81-1.31-2.81s-1.32.55-1.32,2.81.29,2.81,1.32,2.81,1.31-.56,1.31-2.81Z"/>
      <path className="cls-57" d="m206.83,306.98c0-3.47,1.13-4.59,3.38-4.59s3.37,1.13,3.37,4.59-1.13,4.59-3.37,4.59-3.38-1.22-3.38-4.59Zm4.69,0c0-2.25-.29-2.81-1.31-2.81s-1.32.55-1.32,2.81.29,2.81,1.32,2.81,1.31-.56,1.31-2.81Z"/>
      <path className="cls-57" d="m214.33,306.98c0-3.47,1.13-4.59,3.38-4.59s3.37,1.13,3.37,4.59-1.13,4.59-3.37,4.59-3.38-1.22-3.38-4.59Zm4.69,0c0-2.25-.29-2.81-1.31-2.81s-1.32.55-1.32,2.81.29,2.81,1.32,2.81,1.31-.56,1.31-2.81Z"/>
      <path className="cls-57" d="m228.58,302.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m232.52,305.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m237.16,312.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m243.53,305.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m248.36,309.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m252.53,309.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m262.28,311.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.93.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m263.5,308.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m47.94,321.11c.35-.1,1.08-.19,1.82-.19h.14v1.22l.47-.47c.48-.48,1.03-.85,1.87-.85,1.03,0,1.88.66,1.88,2.25v1.03c0,2.06-1.69,3.37-3.47,3.37h-.76v2.25h-1.03v-7.84c-.38.02-.74.06-.94.11v-.89Zm1.97,2.06v3.37h.76c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.66,0-1.4.66-1.79,1.03l-.37.38Z"/>
      <path className="cls-57" d="m55.53,324.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m61.67,321.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m70.57,325.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m74.75,324.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m80.93,321.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m86.78,321.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m93.77,321.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m97.89,324.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m104.03,321.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m112.18,322.61v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.18-2.24,1.18-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.17,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.17-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path className="cls-57" d="m121.51,327.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m127.05,318.67c.35-.1,1.07-.19,1.82-.19h.14v3.61l.42-.42c.48-.48,1.03-.85,1.87-.85,1.03,0,1.87.66,1.87,2.25v1.03c0,2.06-1.68,3.37-3.47,3.37h-1.73v-8.02c-.4.02-.76.06-.94.11v-.89Zm1.97,4.45v3.42h.7c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.65,0-1.4.66-1.78,1.03l-.32.34Z"/>
      <path className="cls-57" d="m134.41,321.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m139.33,321.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m143.46,324.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m149.78,322.61v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path className="cls-57" d="m156.11,324.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m163.22,321.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m170.77,326.35h1.13v1.13h-1.13v-1.13Z"/>
      <path className="cls-57" d="m48.55,350.48h1.13v3.74h4.4v-3.74h1.12v9h-1.12v-4.26h-4.4v4.26h-1.13v-9Z"/>
      <path className="cls-57" d="m56.79,353.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m65.52,360.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m72.6,360.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m80.15,360.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.05c-.4.2-1.12.52-2.06.52-1.03,0-1.97-.66-1.97-2.34v-2.78c-.38.02-.74.06-.94.11v-.89c.36-.1,1.08-.19,1.83-.19h.13v3.75c0,1.03.42,1.4,1.22,1.4.68,0,1.37-.24,1.77-.41v-4.75h1.03v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Z"/>
      <path className="cls-57" d="m89.42,350.58v-.1h1.13c.47,3.24,1.13,5.9,2.06,8.06.89-1.92,1.6-4.82,2.06-8.06h.94c.47,3.24,1.08,5.8,2.06,8.06.84-1.79,1.59-4.82,2.06-8.06h1.03v.1c-.56,3.98-1.74,7.59-2.39,8.9h-1.37c-.48-.97-1.23-3.25-1.87-5.95-.64,2.7-1.38,4.98-1.87,5.95h-1.45c-.66-1.31-1.83-4.92-2.4-8.9Z"/>
      <path className="cls-57" d="m101.22,353.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m109.94,360.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m117.02,360.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m124.57,360.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.05c-.4.2-1.12.52-2.06.52-1.03,0-1.97-.66-1.97-2.34v-2.78c-.38.02-.74.06-.94.11v-.89c.36-.1,1.08-.19,1.83-.19h.13v3.75c0,1.03.42,1.4,1.22,1.4.68,0,1.37-.24,1.77-.41v-4.75h1.03v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Z"/>
      <path className="cls-57" d="m134.23,354.61v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.18-2.24,1.18-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.17,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.17-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path className="cls-57" d="m143.37,357.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m150.91,359.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m160.11,356.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m166.48,356.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m173.6,353.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m184.34,352.54l1.13-2.06h.7l-.7,2.63h-1.13v-.56Z"/>
      <path className="cls-57" d="m193.71,359.28c-.36.1-1.08.19-1.83.19h-.13v-4.4c0-1.03-.43-1.32-1.03-1.32-.66,0-1.32.71-1.69,1.08l-.28.29v4.35h-1.03v-8.02c-.4.02-.76.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v3.61l.37-.37c.47-.48,1.03-.9,1.87-.9,1.03,0,1.79.56,1.79,2.25v3.44c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m194.7,353.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m199.52,357.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m204.07,350.48h1.13v.56l-1.13,2.06h-.71l.71-2.63Z"/>
      <path className="cls-57" d="m210.16,356.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m217.28,353.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m230.87,350.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m235,356.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m244.05,350.67c.35-.1,1.07-.19,1.82-.19h.14v4.96h.65l2.16-2.53h1.18v.1l-2.48,2.85,2.58,3.51v.1h-1.17l-2.25-3.09h-.65v3.09h-1.03v-8.02c-.38.02-.75.06-.93.11v-.89Z"/>
      <path className="cls-57" d="m251.78,353.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m258.91,356.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m265.94,356.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m272.83,353.11c.35-.1,1.08-.19,1.82-.19h.14v1.22l.47-.47c.48-.48,1.03-.85,1.87-.85,1.03,0,1.88.66,1.88,2.25v1.03c0,2.06-1.69,3.37-3.47,3.37h-.76v2.25h-1.03v-7.84c-.38.02-.74.06-.94.11v-.89Zm1.97,2.06v3.37h.76c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.66,0-1.4.66-1.79,1.03l-.37.38Z"/>
      <path className="cls-57" d="m284.02,355.07c.05-.24.1-.68.12-1.13-.18-.1-.56-.19-.91-.19-.94,0-1.77.52-1.77,1.88v1.12c0,1.22.65,1.88,1.77,1.88.74,0,1.36-.24,1.69-.42h.08v.94c-.37.18-1.03.42-1.78.42-1.69,0-2.81-.94-2.81-2.82v-1.12c0-1.83,1.4-2.82,2.81-2.82.74,0,1.5.24,1.87.47,0,.76-.1,1.44-.18,1.79h-.9Z"/>
      <path className="cls-57" d="m286.42,356.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m295.18,359.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.93.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m296.44,354.61h3.38v.94h-3.38v-.94Z"/>
      <path className="cls-57" d="m47.66,366.67c.35-.1,1.07-.19,1.82-.19h.14v3.61l.42-.42c.48-.48,1.03-.85,1.87-.85,1.03,0,1.87.66,1.87,2.25v1.03c0,2.06-1.68,3.37-3.47,3.37h-1.73v-8.02c-.4.02-.76.06-.94.11v-.89Zm1.97,4.45v3.42h.7c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.65,0-1.4.66-1.78,1.03l-.32.34Z"/>
      <path className="cls-57" d="m55.21,373.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m63.26,373.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m68.13,373.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m72.31,372.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m78.49,369.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m83.41,369.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m87.34,369.11c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m94.34,372.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m100.71,372.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m107.83,369.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m118.71,376.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m125.28,372.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m131.6,370.61v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path className="cls-57" d="m137.93,373.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m145.28,373.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m152.44,369.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m157.93,374.35h1.13v1.13h-1.13v-1.13Z"/>
      <path className="cls-57" d="m167.57,374.02h-3.69v-1.36c.94-1.88,1.5-4.41,1.69-6.19h2.24c-.28,2.06-.93,4.08-1.87,5.76h1.63v-1.63h2.06v1.63h.8v1.79h-.8v1.45h-2.06v-1.45Z"/>
      <path className="cls-57" d="m171.75,373.7h2.63c.74,0,1.31-.29,1.31-1.03s-.56-.95-1.31-.95h-1.69v-1.5h1.94c.47-.28.88-.67.88-1.16,0-.66-.47-.8-1.13-.8h-2.53v-1.78h2.72c1.73,0,3,.42,3,2.16,0,.64-.37,1.38-1.14,1.91.83.3,1.32.92,1.32,2.12,0,1.78-1.36,2.81-3.18,2.81h-2.82v-1.78Z"/>
      <path className="cls-57" d="m182.02,369.11c.35-.1,1.08-.19,1.82-.19h.14v1.22l.47-.47c.48-.48,1.03-.85,1.87-.85,1.03,0,1.88.66,1.88,2.25v1.03c0,2.06-1.69,3.37-3.47,3.37h-.76v2.25h-1.03v-7.84c-.38.02-.74.06-.94.11v-.89Zm1.97,2.06v3.37h.76c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.66,0-1.4.66-1.79,1.03l-.37.38Z"/>
      <path className="cls-57" d="m189.38,369.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m197.12,366.67c.35-.1,1.07-.19,1.82-.19h.14v3.61l.42-.42c.48-.48,1.03-.85,1.87-.85,1.03,0,1.87.66,1.87,2.25v1.03c0,2.06-1.68,3.37-3.47,3.37h-1.73v-8.02c-.4.02-.76.06-.94.11v-.89Zm1.97,4.45v3.42h.7c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.65,0-1.4.66-1.78,1.03l-.32.34Z"/>
      <path className="cls-57" d="m204.67,372.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m210.85,369.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m216.14,375.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m231.81,375.28c-.36.1-1.08.19-1.83.19h-.13v-4.4c0-1.03-.43-1.32-1.03-1.32-.66,0-1.32.71-1.69,1.08l-.28.29v4.35h-1.03v-8.02c-.4.02-.76.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v3.61l.37-.37c.47-.48,1.03-.9,1.87-.9,1.03,0,1.79.56,1.79,2.25v3.44c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m232.99,373.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m240.34,373.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m250.08,375.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.93.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m254.54,366.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m258.67,372.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m265.78,369.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m279.38,366.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m283.51,372.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m48.65,392.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m55.02,385.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m60.13,389.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m68.18,389.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m72.16,385.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m76.47,391.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m85.63,391.2v-.98h.1c.46.13,1.55.37,2.24.37h.37c1.13,0,1.88-.42,1.88-1.55,0-.98-.5-1.28-1.32-1.5l-1.21-.32c-.86-.24-1.97-.8-1.97-2.4,0-1.45,1.12-2.43,2.9-2.43h.28c.66,0,1.69.19,2.06.37v.95h-.1c-.43-.14-1.35-.34-1.97-.34h-.28c-1.13,0-1.79.42-1.79,1.45,0,.94.6,1.19,1.32,1.4l1.16.34c.92.26,2.01.74,2.01,2.48,0,1.55-1.12,2.53-3,2.53h-.37c-.74,0-1.97-.24-2.34-.37Z"/>
      <path className="cls-57" d="m92.66,387.35v-.74c0-2.63,1.31-4.22,3.74-4.22s3.75,1.5,3.75,4.22v.74c0,2.63-1.32,4.22-3.75,4.22s-3.74-1.5-3.74-4.22Zm6.37,0v-.74c0-2.25-.89-3.24-2.63-3.24s-2.63.98-2.63,3.24v.74c0,2.25.9,3.24,2.63,3.24s2.63-.98,2.63-3.24Z"/>
      <path className="cls-57" d="m101.08,391.38c.38-1.6.66-2.48,1.03-3.74.56-1.92,1.59-4.45,1.97-5.16h1.5c.38.71,1.42,3.24,1.97,5.16.38,1.26.66,2.15,1.03,3.74v.1h-1.12c-.22-.9-.38-1.55-.56-2.21h-4.14c-.18.66-.34,1.31-.55,2.21h-1.13v-.1Zm1.97-3.09h3.56l-.18-.65c-.47-1.59-1.03-3.14-1.6-4.22-.56,1.08-1.13,2.63-1.59,4.22l-.19.65Z"/>
      <path className="cls-57" d="m109.61,386.61h3.38v.94h-3.38v-.94Z"/>
      <path className="cls-57" d="m115.15,389.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m119.32,388.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m125.88,391.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m132.58,389.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m137.13,390.35h1.13v1.13h-1.13v-1.13Z"/>
      <path className="cls-57" d="m143.65,382.48h.94l3,4.26,3-4.26h.94v9h-1.13v-6.98l-2.61,3.61h-.52l-2.48-3.51v6.88h-1.13v-9Z"/>
      <path className="cls-57" d="m153.36,388.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m160.42,389.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m174.26,391.28c-.36.1-1.08.19-1.83.19h-.13v-4.4c0-1.03-.43-1.32-1.03-1.32-.66,0-1.32.71-1.69,1.08l-.28.29v4.35h-1.03v-8.02c-.4.02-.76.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v3.61l.37-.37c.47-.48,1.03-.9,1.87-.9,1.03,0,1.79.56,1.79,2.25v3.44c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m175.39,388.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m183.21,385.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m193.61,382.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m197.74,388.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m203.92,385.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m212.26,382.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m216.39,388.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m223.51,385.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m237.47,387.07c.05-.24.1-.68.12-1.13-.18-.1-.56-.19-.91-.19-.94,0-1.77.52-1.77,1.88v1.12c0,1.22.65,1.88,1.77,1.88.74,0,1.36-.24,1.69-.42h.08v.94c-.37.18-1.03.42-1.78.42-1.69,0-2.81-.94-2.81-2.82v-1.12c0-1.83,1.4-2.82,2.81-2.82.74,0,1.5.24,1.87.47,0,.76-.1,1.44-.18,1.79h-.9Z"/>
      <path className="cls-57" d="m239.83,388.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m249.29,391.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.93.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m252.91,391.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m254.13,388.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m261.02,392.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m267.58,389.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m275.31,382.48h1.13v.56l-1.13,2.06h-.71l.71-2.63Z"/>
      <path className="cls-57" d="m278.4,391.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m47.94,401.11c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m60.03,405.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m67.39,405.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m74.23,398.67c.35-.1,1.07-.19,1.82-.19h.14v4.96h.65l2.16-2.53h1.18v.1l-2.48,2.85,2.58,3.51v.1h-1.17l-2.25-3.09h-.65v3.09h-1.03v-8.02c-.38.02-.75.06-.93.11v-.89Z"/>
      <path className="cls-57" d="m81.91,405.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m86.08,404.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m93.2,401.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m103.52,402.61v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.17-2.24,1.17-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.17,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.17-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path className="cls-57" d="m112.66,404.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m122.65,398.48h2.66c2.72,0,3.98,1.26,3.98,3.74v1.32c0,2.34-1.07,3.93-3.79,3.93h-2.85v-9Zm2.85,8.01c1.92,0,2.67-.98,2.67-2.95v-1.32c0-1.97-.98-2.76-2.87-2.76h-1.54v7.03h1.73Z"/>
      <path className="cls-57" d="m130.76,404.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m139.89,398.58v-.1h1.13c.47,3.24,1.13,5.9,2.06,8.06.89-1.92,1.6-4.82,2.06-8.06h.94c.47,3.24,1.08,5.8,2.06,8.06.84-1.79,1.59-4.82,2.06-8.06h1.03v.1c-.56,3.98-1.74,7.59-2.39,8.9h-1.37c-.48-.97-1.23-3.25-1.87-5.95-.64,2.7-1.38,4.98-1.87,5.95h-1.45c-.66-1.31-1.83-4.92-2.4-8.9Z"/>
      <path className="cls-57" d="m151.83,405.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m159.93,401.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m170.33,398.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m174.46,404.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m183.22,407.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m184.26,401.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m189.13,401.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m196.82,408.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m203.75,406.35h1.13v.56l-1.13,2.06h-.71l.71-2.63Z"/>
      <path className="cls-57" d="m210.55,405.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m214.72,404.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m220.9,401.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m229.21,404.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m235.39,401.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m240.5,404.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m250.01,402.61v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path className="cls-57" d="m256.34,405.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m264.44,401.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m274.76,404.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m282.58,401.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m289.94,407.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m50.61,416.74l-2.16.84h-.1v-1.87l3-1.22h1.31v9h-2.05v-6.74Z"/>
      <path className="cls-57" d="m55.58,422.07c0-1.22.42-1.93,1.68-2.82l1.22-.84c.32-.22.93-.66.93-1.31s-.37-.85-1.31-.85h-2.25v-1.78h2.81c1.77,0,2.82.94,2.82,2.53,0,1.4-.78,1.94-1.97,2.76l-1.03.71c-.43.29-.76.61-.76,1.13v.1h3.93v1.78h-6.08v-1.4Z"/>
      <path className="cls-57" d="m63.5,421.41h2.06v.75l-1.55,2.81h-1.45l.94-3.56Z"/>
      <path className="cls-57" d="m67.11,421.7h2.61c.89,0,1.5-.24,1.5-1.13,0-.94-.74-1.03-1.68-1.03h-2.25v-5.06h5.62v1.78h-3.66v1.5h.8c1.97,0,3.23.85,3.23,2.82s-1.5,2.9-3.56,2.9h-2.61v-1.78Z"/>
      <path className="cls-57" d="m74.23,418.22h4.12v1.69h-4.12v-1.69Z"/>
      <path className="cls-57" d="m79.05,417.24c.62-.2,1.69-.32,2.4-.32h.55v6.19c0,1.71-1.15,2.63-2.53,2.63-.3,0-.62-.05-.93-.14v-1.64c.22.08.41.1.56.1.54,0,.85-.42.85-1.13v-4.45c-.35.05-.66.12-.9.19v-1.42Zm.9-2.76h2.05v1.69h-2.05v-1.69Z"/>
      <path className="cls-57" d="m90.96,423.15c-.62.2-1.69.32-2.39.32h-.56v-.89l-.47.32c-.6.41-1.13.66-1.83.66-1.36,0-2.1-.8-2.1-2.16v-1.31c0-1.97,1.5-3.19,3.47-3.19h3v5.01c.32-.05.64-.11.89-.19v1.42Zm-2.95-2.06v-2.48h-.76c-1.03,0-1.58.47-1.58,1.5v.94c0,.52.23.74.74.74.37,0,.84-.23,1.22-.47l.37-.23Z"/>
      <path className="cls-57" d="m91.81,417.24c.62-.2,1.69-.32,2.39-.32h.56v.8c.37-.56,1.08-.9,1.74-.9h.53v1.79h-.72c-.7,0-1.21.28-1.55.65v4.22h-2.06v-5.01c-.34.05-.65.12-.89.19v-1.42Z"/>
      <path className="cls-57" d="m101.29,423.15c-.61.2-1.68.32-2.39.32h-.56v-5.01c-.34.05-.65.12-.89.19v-1.42c.62-.2,1.69-.32,2.39-.32h.56v5.01c.34-.05.65-.11.89-.19v1.42Zm-2.95-8.67h2.06v1.69h-2.06v-1.69Z"/>
      <path className="cls-57" d="m103.22,423.8c.54.13,1.14.24,1.69.24,1.02,0,1.87-.36,1.87-1.64v-.05l-.47.24c-.5.24-1.03.52-1.74.52-1.4,0-2.2-.75-2.2-2.16v-.84c0-1.97,1.5-3.19,3.47-3.19h3v5.62c0,2.45-1.81,3.19-3.75,3.19-.62,0-1.26-.06-1.87-.24v-1.69Zm3.56-2.95v-2.24h-.76c-1.03,0-1.58.47-1.58,1.5v.47c0,.52.23.74.74.74.42,0,.91-.17,1.27-.32l.32-.14Z"/>
      <path className="cls-57" d="m113.22,414.67c.35-.1,1.07-.19,1.82-.19h.14v3.61l.42-.42c.48-.48,1.03-.85,1.87-.85,1.03,0,1.87.66,1.87,2.25v1.03c0,2.06-1.68,3.37-3.47,3.37h-1.73v-8.02c-.4.02-.76.06-.94.11v-.89Zm1.97,4.45v3.42h.7c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.65,0-1.4.66-1.78,1.03l-.32.34Z"/>
      <path className="cls-57" d="m120.76,420.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m127.32,423.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m134.02,421.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m138.2,421.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m145.56,421.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m153.65,417.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m161.2,422.35h1.13v1.13h-1.13v-1.13Z"/>
      <path className="cls-57" d="m48.55,453.04v-6.56h5.06v.98h-3.93v2.76h3.55v1h-3.55v1.92c0,.94.47,1.36,1.21,1.36h2.72v.98h-2.63c-1.6,0-2.44-.76-2.44-2.43Z"/>
      <path className="cls-57" d="m54.87,449.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m63.13,450.61v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.17-2.24,1.17-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.17,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.17-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path className="cls-57" d="m72.27,453.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m79.81,455.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m88.96,452.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m95.99,452.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m102.56,446.67c.35-.1,1.07-.19,1.82-.19h.14v4.96h.65l2.16-2.53h1.18v.1l-2.48,2.85,2.58,3.51v.1h-1.17l-2.25-3.09h-.65v3.09h-1.03v-8.02c-.38.02-.75.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m112.69,450.61v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path className="cls-57" d="m119.01,452.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m125.38,452.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m134.14,455.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.93.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m138.93,448.54l1.13-2.06h.7l-.7,2.63h-1.13v-.56Z"/>
      <path className="cls-57" d="m142.58,449.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m149.56,449.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m153.69,452.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m159.82,449.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m167.98,450.61v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.18-2.24,1.18-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.17,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.17-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path className="cls-57" d="m177.5,446.48h1.13v.56l-1.13,2.06h-.71l.71-2.63Z"/>
      <path className="cls-57" d="m186.82,446.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m190.76,449.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m195.58,453.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m202.76,449.11c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m206.56,453.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m213.92,453.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m221.08,449.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m226.57,448.92h1.13v1.13h-1.13v-1.13Zm0,5.43h1.13v1.13h-1.13v-1.13Z"/>
      <path className="cls-57" d="m232.42,452.95c0-.79.44-1.44,1.03-1.87l.34-.25c-.71-.42-1.18-1.09-1.18-2.05,0-1.68,1.45-2.39,2.95-2.39h.28c1.79,0,2.95.61,2.95,2.39,0,.66-.42,1.37-.84,1.74l-.25.23c.8.44,1.28,1.15,1.28,2.21,0,1.69-1.5,2.63-3.14,2.63h-.28c-1.92,0-3.14-.71-3.14-2.63Zm3.42.84c.66,0,1.08-.32,1.08-.94,0-.74-.52-.95-1.08-1.16l-.62-.25-.36.38c-.26.3-.37.66-.37,1.03,0,.66.42.94,1.08.94h.28Zm.61-3.93c.25-.25.37-.56.37-.94,0-.52-.47-.74-.98-.74h-.28c-.52,0-.98.23-.98.74,0,.61.41.73.94.94l.68.28.25-.28Z"/>
      <path className="cls-57" d="m239.92,452.95c0-.79.44-1.44,1.03-1.87l.34-.25c-.71-.42-1.18-1.09-1.18-2.05,0-1.68,1.45-2.39,2.95-2.39h.28c1.79,0,2.95.61,2.95,2.39,0,.66-.42,1.37-.84,1.74l-.25.23c.8.44,1.28,1.15,1.28,2.21,0,1.69-1.5,2.63-3.14,2.63h-.28c-1.92,0-3.14-.71-3.14-2.63Zm3.42.84c.66,0,1.08-.32,1.08-.94,0-.74-.52-.95-1.08-1.16l-.62-.25-.36.38c-.26.3-.37.66-.37,1.03,0,.66.42.94,1.08.94h.28Zm.61-3.93c.25-.25.37-.56.37-.94,0-.52-.47-.74-.98-.74h-.28c-.52,0-.98.23-.98.74,0,.61.41.73.94.94l.68.28.25-.28Z"/>
      <path className="cls-57" d="m254.45,451.07c.05-.24.1-.68.12-1.13-.18-.1-.56-.19-.91-.19-.94,0-1.77.52-1.77,1.88v1.12c0,1.22.65,1.88,1.77,1.88.74,0,1.36-.24,1.69-.42h.08v.94c-.37.18-1.03.42-1.78.42-1.69,0-2.81-.94-2.81-2.82v-1.12c0-1.83,1.4-2.82,2.81-2.82.74,0,1.5.24,1.87.47,0,.76-.1,1.44-.18,1.79h-.9Z"/>
      <path className="cls-57" d="m256.8,452.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m266.27,455.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.93.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m269.88,455.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m271.11,452.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m278,456.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m284.56,453.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m292.29,446.48h1.13v.56l-1.13,2.06h-.71l.71-2.63Z"/>
      <path className="cls-57" d="m295.37,455.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m301.76,454.35h1.13v.56l-1.13,2.06h-.71l.71-2.63Z"/>
      <path className="cls-57" d="m51.36,462.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m55.49,468.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m65,467.54v-1.13c0-2.63,1.68-4.03,4.31-4.03.84,0,1.69.24,2.06.42v2.01h-1.03v-1.31c-.37-.1-.74-.14-1.22-.14-1.68,0-3,.85-3,3.05v1.13c0,1.97,1.03,3.05,2.82,3.05h.56c.67,0,1.43-.24,1.78-.37h.1v.94c-.28.13-1.13.42-1.87.42h-.56c-2.72,0-3.93-1.69-3.93-4.03Z"/>
      <path className="cls-57" d="m72.6,470.49h1.13c.94,0,1.5-.61,1.5-1.64v-5.38h-2.25v-.98h3.37v6.37c0,1.6-1.03,2.63-2.61,2.63h-1.13v-.98Z"/>
      <path className="cls-57" d="m78.21,467.54v-1.22c0-2.61,1.83-3.93,4.32-3.93.94,0,1.97.24,2.34.42v.94h-.1c-.43-.13-1.38-.37-2.24-.37-1.79,0-3.19.76-3.19,2.95v1.22c0,1.97,1.13,3.05,3,3.05h.37c.42,0,.82-.06,1.13-.14v-2.39h-1.87v-.98h2.9v4.03c-.32.18-1.42.47-2.16.47h-.37c-2.71,0-4.13-1.69-4.13-4.03Z"/>
      <path className="cls-57" d="m90.13,462.48h2.9c1.87,0,2.71.84,2.71,2.72,0,1.45-.65,2.15-1.63,2.57,1.08.98,1.98,2.88,2.19,3.61v.1h-1.16c-.19-.76-1.27-2.67-2.11-3.37h-1.79v3.37h-1.12v-9Zm2.62,4.64c1.4,0,1.88-.8,1.88-1.92,0-1.32-.47-1.74-1.69-1.74h-1.69v3.66h1.5Z"/>
      <path className="cls-57" d="m97.39,465.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m101.32,465.11c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m105.87,465.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m112.85,465.11c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m124.9,468.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m132.73,465.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m143.13,462.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m150.26,465.11c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m162.36,468.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m168.53,465.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m173.13,462.67c.35-.1,1.07-.19,1.82-.19h.14v4.96h.65l2.16-2.53h1.18v.1l-2.48,2.85,2.58,3.51v.1h-1.17l-2.25-3.09h-.65v3.09h-1.03v-8.02c-.38.02-.75.06-.93.11v-.89Z"/>
      <path className="cls-57" d="m183.71,467.07c.05-.24.1-.68.12-1.13-.18-.1-.56-.19-.91-.19-.94,0-1.77.52-1.77,1.88v1.12c0,1.22.65,1.88,1.77,1.88.74,0,1.36-.24,1.69-.42h.08v.94c-.37.18-1.03.42-1.78.42-1.69,0-2.81-.94-2.81-2.82v-1.12c0-1.83,1.4-2.82,2.81-2.82.74,0,1.5.24,1.87.47,0,.76-.1,1.44-.18,1.79h-.9Z"/>
      <path className="cls-57" d="m186.11,469.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m193.28,465.11c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m205.18,465.11c.35-.1,1.08-.19,1.82-.19h.14v1.22l.47-.47c.48-.48,1.03-.85,1.87-.85,1.03,0,1.88.66,1.88,2.25v1.03c0,2.06-1.69,3.37-3.47,3.37h-.76v2.25h-1.03v-7.84c-.38.02-.74.06-.94.11v-.89Zm1.97,2.06v3.37h.76c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.66,0-1.4.66-1.79,1.03l-.37.38Z"/>
      <path className="cls-57" d="m212.78,469.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m220.65,472.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m227.96,465.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m235.13,468.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m244.7,468.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m251.81,465.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m48.08,482.61v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.18-2.24,1.18-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.17,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.17-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path className="cls-57" d="m57.23,484.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m64.29,485.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m74.93,487.28c-.36.1-1.08.19-1.83.19h-.13v-4.4c0-1.03-.43-1.32-1.03-1.32-.66,0-1.32.71-1.69,1.08l-.28.29v4.35h-1.03v-8.02c-.4.02-.76.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v3.61l.37-.37c.47-.48,1.03-.9,1.87-.9,1.03,0,1.79.56,1.79,2.25v3.44c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m76.06,484.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m82.91,481.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m94.34,478.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m98.47,484.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m104.65,481.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m109.94,487.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m118.95,481.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m123.82,481.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m137.42,478.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m141.35,481.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m145.43,482.61v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path className="cls-57" d="m151.76,484.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m157.94,481.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m163.23,487.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m169.24,484.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m179.32,488.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m185.89,484.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m192.07,481.11c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m204.16,484.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m210.53,484.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m217.65,481.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m225.52,485.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m229.7,484.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m236.81,481.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m244.36,486.35h1.13v1.13h-1.13v-1.13Z"/>
      <path className="cls-57" d="m47.7,510.58v-.1h1.13c.47,3.24,1.13,5.9,2.06,8.06.89-1.92,1.6-4.82,2.06-8.06h.94c.47,3.24,1.08,5.8,2.06,8.06.84-1.79,1.59-4.82,2.06-8.06h1.03v.1c-.56,3.98-1.74,7.59-2.39,8.9h-1.37c-.48-.97-1.24-3.25-1.87-5.95-.64,2.7-1.38,4.98-1.87,5.95h-1.45c-.66-1.31-1.83-4.92-2.4-8.9Z"/>
      <path className="cls-57" d="m59.64,517.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m67.74,513.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m78.14,510.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m82.27,516.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m91.03,519.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.93.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m98.67,510.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m102.75,516.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m109.78,516.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m116.67,513.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m124.93,516.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m132.75,513.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m140.11,519.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m149.26,516.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m157.09,513.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m166.65,519.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m167.69,513.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m172.56,513.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m179.73,516.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m189.11,513.11c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m192.91,517.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m200.27,517.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m207.43,513.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m212.49,514.61v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path className="cls-57" d="m218.82,516.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m225,513.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m230.29,519.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m238.69,519.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.93.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m239.91,517.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m247.78,520.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m257.86,518.3c0-.93.37-2.13,1.78-2.9l1.03-.56c.91-.49,1.42-.89,1.42-1.87,0-1.22-.66-1.5-1.69-1.5h-2.34v-.98h2.34c1.87,0,2.81.79,2.81,2.48,0,1.31-.73,2-1.97,2.67l-1.03.56c-1.06.56-1.21,1.31-1.21,2.15v.14h4.31v.98h-5.43v-1.18Z"/>
      <path className="cls-57" d="m265.08,514.98c0-3.09.94-4.59,3-4.59s3,1.5,3,4.59-.95,4.59-3,4.59-3-1.5-3-4.59Zm4.87,0c0-2.63-.47-3.61-1.87-3.61s-1.88.98-1.88,3.61.47,3.61,1.88,3.61,1.87-.98,1.87-3.61Z"/>
      <path className="cls-57" d="m272.86,518.3c0-.93.37-2.13,1.78-2.9l1.03-.56c.91-.49,1.42-.89,1.42-1.87,0-1.22-.66-1.5-1.69-1.5h-2.34v-.98h2.34c1.87,0,2.81.79,2.81,2.48,0,1.31-.73,2-1.97,2.67l-1.03.56c-1.06.56-1.21,1.31-1.21,2.15v.14h4.31v.98h-5.43v-1.18Z"/>
      <path className="cls-57" d="m280.36,518.3c0-.93.37-2.13,1.78-2.9l1.03-.56c.91-.49,1.42-.89,1.42-1.87,0-1.22-.66-1.5-1.69-1.5h-2.34v-.98h2.34c1.87,0,2.81.79,2.81,2.48,0,1.31-.73,2-1.97,2.67l-1.03.56c-1.06.56-1.21,1.31-1.21,2.15v.14h4.31v.98h-5.43v-1.18Z"/>
      <path className="cls-57" d="m290.73,516.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m297.85,513.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m48.31,535.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m55.02,533.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m59.15,532.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m66.03,529.11c.35-.1,1.08-.19,1.82-.19h.14v1.22l.47-.47c.48-.48,1.03-.85,1.87-.85,1.03,0,1.88.66,1.88,2.25v1.03c0,2.06-1.69,3.37-3.47,3.37h-.76v2.25h-1.03v-7.84c-.38.02-.74.06-.94.11v-.89Zm1.97,2.06v3.37h.76c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.66,0-1.4.66-1.79,1.03l-.37.38Z"/>
      <path className="cls-57" d="m76.35,526.67c.35-.1,1.07-.19,1.82-.19h.14v3.61l.42-.42c.48-.48,1.03-.85,1.87-.85,1.03,0,1.87.66,1.87,2.25v1.03c0,2.06-1.68,3.37-3.47,3.37h-1.73v-8.02c-.4.02-.76.06-.94.11v-.89Zm1.97,4.45v3.42h.7c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.65,0-1.4.66-1.78,1.03l-.32.34Z"/>
      <path className="cls-57" d="m83.71,529.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m87.65,529.11c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m97.87,526.67c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m102,532.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m114.13,531.54h-2.15v-.94h2.15v-2.16h.95v2.16h2.15v.94h-2.15v2.16h-.95v-2.16Z"/>
      <path className="cls-57" d="m122.25,533.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m132,535.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m133.4,535.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m142.41,529.11c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m146.22,532.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m155.59,529.11c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m167.69,532.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m174.06,532.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m180.24,529.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m188.49,530.61v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.17-2.24,1.17-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.17,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.17-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path className="cls-57" d="m197.44,529.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m203.96,535.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m205.88,533.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m213.2,530.61v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.17-2.24,1.17-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.17,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.17-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path className="cls-57" d="m222.34,532.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m229.41,533.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m233.59,532.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m240.7,529.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m248.25,534.35h1.13v1.13h-1.13v-1.13Z"/>
      <a href={`${props.url}CJG_Rijnmond_Jaarverslag_2022.pdf`} target="_blank" rel="noopener noreferrer" >
        <g>
      <path className="cls-57" d="m254.77,533.04v-6.56h5.06v.98h-3.93v2.76h3.55v1h-3.55v1.92c0,.94.47,1.36,1.21,1.36h2.72v.98h-2.63c-1.6,0-2.44-.76-2.44-2.43Z"/>
      <path className="cls-57" d="m262.03,529.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m272.57,535.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m279.28,533.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m283.46,533.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m253.64,537.2v-.25h36.47v.25h-36.47Z"/>
      <path className="cls-57" d="m48.65,552.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m55.21,548.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m61.39,545.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m66.26,545.11c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m74.65,551.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m81.36,549.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m88.68,548.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m95.71,548.11c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path className="cls-57" d="m102.27,542.67c.35-.1,1.07-.19,1.82-.19h.14v4.96h.65l2.16-2.53h1.18v.1l-2.48,2.85,2.58,3.51v.1h-1.17l-2.25-3.09h-.65v3.09h-1.03v-8.02c-.38.02-.75.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m112.45,548.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m118.77,546.61v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path className="cls-57" d="m125.1,548.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m132.21,545.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m142.76,551.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m149.47,549.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m153.45,545.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m159.97,551.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.93.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m163.92,542.67c.35-.1,1.07-.19,1.82-.19h.14v3.61l.42-.42c.48-.48,1.03-.85,1.87-.85,1.03,0,1.87.66,1.87,2.25v1.03c0,2.06-1.68,3.37-3.47,3.37h-1.73v-8.02c-.4.02-.76.06-.94.11v-.89Zm1.97,4.45v3.42h.7c1.4,0,2.43-.94,2.43-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.65,0-1.4.66-1.78,1.03l-.32.34Z"/>
      <path className="cls-57" d="m171.27,545.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m175.21,545.11c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m188.67,551.28c-.36.1-1.08.19-1.83.19h-.13v-4.4c0-1.03-.43-1.32-1.03-1.32-.66,0-1.32.71-1.69,1.08l-.28.29v4.35h-1.03v-8.02c-.4.02-.76.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v3.61l.37-.37c.47-.48,1.03-.9,1.87-.9,1.03,0,1.79.56,1.79,2.25v3.44c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m189.85,548.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m196.92,549.32v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path className="cls-57" d="m210.98,551.28c-.35.1-1.08.19-1.82.19h-.14v-5.62h-3v5.62h-1.03v-5.62h-1.03v-.74c.32-.1.67-.17,1.03-.19v-.28c0-1.42.85-2.16,2.16-2.16.24,0,.5.05.74.14v.79h-.74c-.84,0-1.13.37-1.13,1.22v.28h4.03v5.6c.37-.02.74-.07.94-.12v.89Zm-1.97-8.8h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m212.95,545.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m220.13,549.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m228.23,545.89c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path className="cls-57" d="m239,547.07c.05-.24.1-.68.12-1.13-.18-.1-.56-.19-.91-.19-.94,0-1.77.52-1.77,1.88v1.12c0,1.22.65,1.88,1.77,1.88.74,0,1.36-.24,1.69-.42h.08v.94c-.37.18-1.03.42-1.78.42-1.69,0-2.81-.94-2.81-2.82v-1.12c0-1.83,1.4-2.82,2.81-2.82.74,0,1.5.24,1.87.47,0,.76-.1,1.44-.18,1.79h-.9Z"/>
      <path className="cls-57" d="m241.21,545.11c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m245.34,548.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm.74-6.09h1.03v1.13h-1.03v-1.13Zm.29,4.87h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Zm2.61-4.87h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m254.1,551.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.93.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m255.32,548.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m47.42,553.2v-.25h216.76v.25H47.42Z"/>
      <path className="cls-57" d="m47.94,561.11c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path className="cls-57" d="m51.74,565.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m59.1,565.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m66.26,561.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m71.32,562.61v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path className="cls-57" d="m77.65,564.95v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path className="cls-57" d="m83.83,561.11c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path className="cls-57" d="m89.12,567.33v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path className="cls-57" d="m97.52,567.28c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.75.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path className="cls-57" d="m98.74,565.32v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path className="cls-57" d="m106.62,568.6h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path className="cls-57" d="m113.55,566.35h1.13v1.13h-1.13v-1.13Z"/>
      </g>
      </a>
      <path className="cls-57" d="m47.42,569.2v-.25h68.33v.25H47.42Z"/>
    </g>
    <path id="Wolk-8" data-name="Wolk" className="cls-52" d="m96.73,653.95h-4.12c-.71-.15-1.25-.77-1.25-1.53,0-.85.68-1.54,1.52-1.56-.02,0-.03,0-.05,0h-.12c3.81-.05,6.88-3.15,6.88-6.98s-3.13-6.98-6.98-6.98h-44.81c-3.86,0-6.98,3.13-6.98,6.98s3.07,6.92,6.89,6.98c-.01,0,7.33,0,7.31,0,.84.02,1.52.71,1.52,1.56,0,.76-.54,1.38-1.25,1.53h-15.29c-3.86,0-6.98,3.13-6.98,6.98s3.12,6.98,6.98,6.98h7.74c.72.14,1.26.77,1.26,1.53,0,.85-.68,1.54-1.52,1.56.02,0,.03,0,.05,0h.11c-3.81.05-6.88,3.15-6.88,6.98s3.13,6.98,6.98,6.98h34.64c3.86,0,6.98-3.13,6.98-6.98s-3.12-6.98-6.98-6.98h-7.73c-.83-.04-1.49-.72-1.49-1.56,0-.76.54-1.39,1.26-1.53h22.29c3.85,0,6.98-3.12,6.98-6.98s-3.13-6.98-6.98-6.98Z"/>
    <path id="Plane_path-m" className="cls-plane-path" d="M113.774 646C111.451 646 109.129 646 106.806 646C104.273 646 101.787 647.283 99.4513 648.327C93.8632 650.825 87.9615 652.212 83.5805 657C80.0253 660.886 77 665.28 77 670.938C77 676.857 77.5809 681.629 80.0107 687.038C82.023 691.519 84.0353 695.094 86.8708 698.908C90.4361 703.704 95.4023 706.294 100.613 708.192C107.796 710.809 114.507 712 122.075 712C129.509 712 134.924 707.75 140.87 703.538C146.333 699.668 149.963 696.028 152.203 689.06C153.834 683.987 152.684 679.191 151.235 674.44C149.43 668.519 147.578 662.473 143.58 657.846C141.992 656.009 140.37 653.951 138.332 652.534C137.04 651.635 135.819 650.509 134.375 649.902C132.878 649.272 130.938 648.554 129.558 647.692C127.043 646.121 123.371 646.423 120.548 646.423C118.41 646.423 116.697 646 114.548 646"/>
    <g id="Vliegtuig-m">
      <polygon className="cls-92" points="201.72 672.73 215.45 673.47 216.39 680.32 215.95 693.24 210.6 693.46 208.46 679.83 201.72 679.15 201.72 672.73"/>
      <path className="cls-81" d="m215.87,676.55c.98-.99-.42-4.12-.36-5.51.09-2.06-.02-2.76-.19-5.83,0-.1,0-.2.05-.29.05-.08.14-.12.23-.15.51-.2,1.01-.4,1.52-.61.09-.04.2-.09.21-.19,0-.06-.03-.11-.06-.16-.39-.63-.77-1.27-1.16-1.9-.54-.89-1.1-1.8-1.92-2.44-.63-.49-1.37-.79-2.12-1.05-.67-.23-1.02-.59-1.62-.95-1.19-.72-2.58-1.04-3.95-.79-1.45.26-2.18,1.18-3.31,1.93-1.29.85-3.23,1.15-4.74,1.23.01,1.68.23,3.35.65,4.98.77-.09,1.54-.17,2.31-.26.08,0-.12,10.22.2,11.18"/>
      <path className="cls-69" d="m198.47,660.41c-.06,0-.12,0-.18-.02-1.24-.18-3.58-.82-3.66-2.46,0-.12,0-.24,0-.35.01-.19.02-.38-.02-.56-.09-.34-.37-.6-.69-.72-.66-.26-1.6-.18-2.12.32-.5.48-.67,1.29-.44,1.96.21.6.64.9,1.16,1.19.52.29.84.63,1.31,1.04.99.85,2.17,1.45,3.37,1.9.5.19,1.05.39,1.55.49l-.28-2.81Z"/>
      <g>
        <ellipse className="cls-69" cx="207.89" cy="650.93" rx="9.96" ry="10.45"/>
        <path className="cls-41" d="m203.4,651.32c0-.41.33-.74.74-.74s.74.33.74.74-.33.74-.74.74-.74-.33-.74-.74Z"/>
        <path className="cls-41" d="m210.66,651.32c0-.41.33-.74.74-.74s.74.33.74.74-.33.74-.74.74-.74-.33-.74-.74Z"/>
        <path className="cls-28" d="m205.35,657.38c.67,1.21,2.18,1.65,3.39.98"/>
      </g>
      <path className="cls-69" d="m198.26,651.59c.39,1.46,0,2.83-.87,3.07-.87.23-1.89-.76-2.28-2.23-.39-1.46,0-2.83.87-3.07.87-.23,1.89.76,2.28,2.23Z"/>
      <path className="cls-69" d="m216.31,651.59c-.39,1.46,0,2.83.86,3.07.87.23,1.89-.76,2.28-2.23.39-1.46,0-2.83-.86-3.07-.87-.23-1.89.76-2.28,2.23Z"/>
      <path className="cls-11" d="m208.11,636.41c1.21-.54,2.57-.73,3.88-.53,1.73.26,3.3,1.16,4.72,2.2,2.01,1.48,3.89,3.47,4.17,5.95.14,1.22-.36,2.3-.36,3.48,0,.31.48,2.53.94,2.44-.78.16-1.63-.16-2.11-.79.21.28-1.2,2.37-1.4,2.62-.05.06-.1.12-.18.15-.29.11-.46-.32-.48-.63-.13-1.91-.94-3.78-2.27-5.16-1.32-1.39-3.14-2.29-5.05-2.51.48.22.76.76.81,1.29.05.53-.1,1.06-.27,1.56-.68.06-1.07-.73-1.55-1.21-.78-.79-2.01-.83-3.07-1.15-1.11-.33-2.12-1.01-2.84-1.92-.16.49-2.64,5.69-2.08,5.99-.5-.27-.85-.8-.9-1.36-1.04,1.91-1.6,4.06-1.64,6.23,0,.14-.04.32-.18.34-.62-1.09-.88-2.63-1.71-3.55-.2-.23-.51-.39-.81-.34,0-1.41.05-2.82.05-4.22,0-1.24.02-2.54.53-3.69.55-1.23,1.64-2.04,2.75-2.73.56-.36,1.08-.66,1.75-.79.15-.03,1.93,0,1.86.12,1.08-1.96,3.43-3.14,5.65-2.84-.58.42-1.04,1.01-1.3,1.68.34-.24.71-.44,1.09-.61Z"/>
      <g>
        <rect className="cls-41" x="207.6" y="668.17" width="3.86" height="7.06" rx=".82" ry=".82" transform="translate(-273.19 159.77) rotate(-25.99)"/>
        <path className="cls-69" d="m212.19,665.69c-.03,1.06-.12,2.13-.37,3.16-.23.94-.53,1.2-1.34,1.58-.78.36-1.26,1.19-.97,2.05.23.67.93,1.15,1.64,1.14,1.56-.02,1.52-1.47,2.16-2.46.64-1,1.15-2.14,1.32-3.33.13-.94.21-1.99.14-3.03-.87.35-1.71.7-2.57.88Z"/>
        <path className="cls-65" d="m208.67,669.29c0-.18-.14-.32-.32-.32s-.32.14-.32.32.14.32.32.32.32-.14.32-.32Z"/>
      </g>
      <g>
        <path className="cls-48" d="m188.79,681.55c-18.06-16.72-20.57-18.12-20.57-18.12-7.98-4.42-10.79.94-6.29,11.98,4.5,11.04,16.8,23.76,24.77,28.18l2.09-22.04Z"/>
        <path className="cls-41" d="m152.65,698.35c20.15-1.26,59.13,5.43,73.1-.4,5.57-1.42,8.21-2.6,9.45-3.01s2.24-1.09,2.24-2.42v-16.89c0-6.04-1.28-7.13-3.38-7.13-8.55,0-4.19,14.44-14.11,14.44-3.71,0-17.75-8.87-31.34-8.88-12.36-.01-21.5,7.66-27.86,8.72-12.88,2.15-12.88,7.06-12.88,8.39v4.75c0,1.33,2.8,2.42,4.79,2.42Z"/>
        <path className="cls-48" d="m232.2,690.93c.25,0,.48.08.68.23,4.08,3.17,4.46,3.62,4.46,3.62,1.28,1.5.21,2.06-2.39,1.24-1.63-.51-4.24-1.8-6.27-3.05-.94-.58-.52-2.04.58-2.04h2.93Z"/>
        <path className="cls-48" d="m200.55,693.47c.34-.02.66.06.91.23,23.56,16.16,23.66,17.01,23.66,17.01,7.07,7.22,1.39,9.7-12.69,5.55-12.72-3.75-28.53-13.24-36.67-20.36-.9-.79-.11-2.33,1.25-2.42h23.55Z"/>
        <path className="cls-41" d="m180.53,658.86s.55,13.37-.04,15.53,17.67.09,17.67.09l-.53-16.3-10.21-3.57-6.9,4.25Z"/>
        <path className="cls-54" d="m182.98,682.72c1.88.58,3.92,1.06,5.78.42,1.56-.54,2.76.11,3.55-1.32,1.48-2.69,2.46-5.87,1.49-8.84-.74-2.26-2.61-4.73-5.3-4.58-1.69.09-3.02,1.27-3.83,2.65-.31.52-1.49,6.06-.72,6.45-.71-.52-1.91-1.22-2.11-1.32-.42-.2-1.01-.16-1.44.07-.64.35-1.06.97-1.56,1.49-.5.52-1.76,2.66-2.47,2.51.34-.57-.2-1.37-.86-1.41s-1.27.5-1.45,1.14c-.32,1.16,2.88.61,4.02,1.03,1.14.42,2.42.23,3.6-.08-.6,1.08-1.43,2.03-2.42,2.77-.56-.61-1.37-1.27-2.12-.91"/>
        <path className="cls-53" d="m181.28,665.09c.39,1.16.13,2.29-.57,2.52-.7.23-1.59-.53-1.97-1.7-.39-1.16-.13-2.29.57-2.52.7-.23,1.59.53,1.97,1.7Z"/>
        <path className="cls-53" d="m179.99,663.04c.27,4.94,4.41,8.72,9.24,8.45s8.53-4.48,8.25-9.42c-.27-4.94-4.41-8.72-9.24-8.45s-8.53,4.48-8.25,9.42Z"/>
        <path className="cls-41" d="m189.07,653.12c1.37-.98,2.71-1.18,4.29-.49,1.51.66,3.08,1.94,3.71,3.48.38.94.57,1.9.62,2.91.02.49.02.99,0,1.48-.01.41-.17.93-.21,1.57-.35-1.6-1.54-3-2.88-3.97,1.65,2.02,2.96,4.47,2.88,7.06-.06,2-.94,3.91-2.1,5.56.1-.15,1.77-3.33.67-6.52-1.88-5.47-7.33-7.59-7.32-7.6-.57,1.06-1.7,1.69-2.69,2.39-2.73,1.91-4.74,4.76-5.19,8.07-.35-.85-.57-1.74-.79-2.64-.19-.78-.38-1.56-.57-2.34-.27-1.11-.29-2.46.02-3.56.37-1.29,1.14-2.44,2.05-3.43.95-1.03,2.09-1.94,3.44-2.38,1.34-.44,2.91-.37,4.08.41Z"/>
        <path className="cls-41" d="m184.89,663.65c-.02-.35.25-.65.6-.67s.65.25.67.6-.25.65-.6.67-.65-.25-.67-.6Z"/>
        <path className="cls-41" d="m191.11,663.31c-.02-.35.25-.65.6-.67s.65.25.67.6-.25.65-.6.67-.65-.25-.67-.6Z"/>
        <path className="cls-18" d="m186.67,668.48c.73,1.16,2.53,1.34,4.04.39"/>
        <path className="cls-41" d="m182.5,678.96s8.71.63,19.47-2.57l-2.26,8.88s-7.98,1.03-13.97.74c-5.99-.28-3.24-7.06-3.24-7.06Z"/>
        <path className="cls-57" d="m175.44,677.07c.38.5,1.82,1.64,7.06,1.89,3.14.15,6.64,6.97,3.49,7.06-.08,0-.17,0-.25,0-8.1.14-16.47-1.96-19.5-4.59-.19-.17-.15-.47.08-.58,1.37-.64,5.47-2.54,8.7-3.9.15-.06.32-.01.42.12Z"/>
        <path className="cls-53" d="m194.01,673.78c-.4,0-.7-1.25-.57-1.4.3-.13,2.48-1.61,2.67-1.82.71-.78,1.41-1.61,1.92-2.54.34-.63.3-.93.04-1.54-.25-.59-.11-1.29.48-1.61.46-.25,1.08-.16,1.47.21.85.8.11,1.57-.03,2.43-.14.87-.35,1.8-.92,2.48-.98,1.17-2.14,2.68-3.71,3.25-.19.07-1.22.54-1.35.53Z"/>
      </g>
      <path className="cls-11" d="m208.53,697.86c-.21-.08-.36-.25-.47-.44-.35-.64-.14-1.48.32-2.05s1.12-.94,1.76-1.29c.76-.41,1.52-.83,2.34-1.09.82-.26,1.72-.36,2.55-.14.32.53.2,1.23-.13,1.74-.33.52-.85.88-1.37,1.21-1.29.81-2.68,1.48-4.12,1.97-.28.1-.6.19-.88.09Z"/>
      <polygon className="cls-92" points="201.72 676.05 216.1 678.27 216.39 680.32 215.95 693.24 210.6 693.46 208.46 679.83 201.72 676.31 201.72 676.05"/>
    </g>
  </g>
        </>
    )
}


