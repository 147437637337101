import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

export default function Gemeenten(props) {
  const isDesctop = props.isDesctop;
  const desctopScreen = useRef();
  const mobileScreen = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const numbers = self.selector('.number');
      numbers.forEach((number) => {
        gsap.from(number, {
          textContent: 0,
          duration: 2,
          snap: { textContent: 1 },
          scrollTrigger: {
            trigger: number,
          },
        });
      });
    }, desctopScreen);
    return () => ctx.revert(); // <- Cleanup!     
  }, [])

  useLayoutEffect(() => {
    const ctxMobile = gsap.context((self) => {
      const numbers = self.selector('.number');
      numbers.forEach((number) => {
        gsap.from(number, {
          textContent: 0,
          duration: 2,
          snap: { textContent: 1 },
          scrollTrigger: {
            trigger: number,
          },
        });
      });
    }, mobileScreen);
    return () => ctxMobile.revert(); // <- Cleanup!     
  }, [])

  if (isDesctop) {
    return (
        <>
        <g ref={desctopScreen} id="Gemeenten">
    <path id="Ondergrond-3" data-name="Ondergrond" className="cls-96" d="m575.69,3188.44h-60.85c-27.8,0-50.33-22.53-50.33-50.33s22.53-50.33,50.33-50.33h38.02c17.27-.04,31.26-14.05,31.26-31.33s-14.03-31.34-31.34-31.34H208.22c-17.31,0-31.34,14.03-31.34,31.34s14.03,31.34,31.34,31.34h-85.37c-21.1,0-37.13,17.11-37.13,38.21s17.11,38.21,38.21,38.21h28.39c-.06,0-.12,0-.19,0-.12,0-.25,0-.37,0-20.06,0-36.32,16.26-36.32,36.32s16.26,36.32,36.32,36.32h423.93c13.36,0,24.2-10.83,24.2-24.2s-10.83-24.2-24.2-24.2Z"/>
    <g id="Theaterstoelen">
      <g>
        <path className="cls-23" d="m155.64,3036.65l24.83-4.51c5.87-.93,12.48.92,13.63,6.29,2.38,11.17,1.72,22.98,5.27,45.36l-39.28,18.54-9.5-56.57c-.4-3.65.9-7.86,5.04-9.1Z"/>
        <path className="cls-99" d="m158.12,3039.8l22.66-4.51c5.36-.93,10.74,1.12,12.43,6.29,0,0,6.79,19.94,6.16,42.21l-39.28,18.54-6.58-53.42c-.36-3.83.82-7.86,4.6-9.1Z"/>
        <path className="cls-23" d="m160.1,3102.32l44.31-6.15,5.92-30.65c-.37-4.46-4.29-7.77-8.75-7.39l-26.63,2.7c-4.46.37-7.57,3.24-8.57,7.87l-6.28,33.63Z"/>
        <polygon className="cls-99" points="204.41 3096.17 210.02 3067.12 174.04 3070.66 165.67 3101.55 204.41 3096.17"/>
        <path className="cls-23" d="m123.17,3053.56l24.83-4.51c5.87-.93,12.48.92,13.63,6.29,2.38,11.17,1.72,22.98,5.27,45.36l-39.28,18.54-9.5-56.57c-.4-3.65.9-7.86,5.04-9.1Z"/>
        <path className="cls-99" d="m125.66,3056.71l22.66-4.51c5.36-.93,10.74,1.12,12.43,6.29,0,0,6.79,19.94,6.16,42.21l-39.28,18.54-6.58-53.42c-.36-3.83.82-7.86,4.6-9.1Z"/>
        <path className="cls-23" d="m127.63,3119.23l44.31-6.15,5.92-30.65c-.37-4.46-4.29-7.77-8.75-7.39l-26.63,2.7c-4.46.37-7.57,3.24-8.57,7.87l-6.28,33.63Z"/>
        <polygon className="cls-99" points="171.94 3113.08 177.55 3084.02 141.57 3087.57 133.2 3118.46 171.94 3113.08"/>
        <path className="cls-23" d="m90.7,3070.46l24.83-4.51c5.87-.93,12.48.92,13.63,6.29,2.38,11.17,1.72,22.98,5.27,45.36l-39.28,18.54-9.5-56.57c-.4-3.65.9-7.86,5.04-9.1Z"/>
        <path className="cls-99" d="m93.19,3073.62l22.66-4.51c5.36-.93,10.74,1.12,12.43,6.29,0,0,6.79,19.94,6.16,42.21l-39.28,18.54-6.58-53.42c-.36-3.83.82-7.86,4.6-9.1Z"/>
        <path className="cls-23" d="m95.16,3136.14l44.31-6.15,5.92-30.65c-.37-4.46-4.29-7.77-8.75-7.39l-26.63,2.7c-4.46.37-7.57,3.24-8.57,7.87l-6.28,33.63Z"/>
        <polygon className="cls-99" points="139.47 3129.99 145.08 3100.93 109.1 3104.48 100.73 3135.36 139.47 3129.99"/>
      </g>
      <g>
        <path className="cls-23" d="m188.28,3077.58l24.83-4.51c5.87-.93,12.48.92,13.63,6.29,2.38,11.17,1.72,22.98,5.27,45.36l-39.28,18.54-9.5-56.57c-.4-3.65.9-7.86,5.04-9.1Z"/>
        <path className="cls-99" d="m190.77,3080.74l22.66-4.51c5.36-.93,10.74,1.12,12.43,6.29,0,0,6.79,19.94,6.16,42.21l-39.28,18.54-6.58-53.42c-.36-3.83.82-7.86,4.6-9.1Z"/>
        <path className="cls-23" d="m192.74,3143.26l44.31-6.15,5.92-30.65c-.37-4.46-4.29-7.77-8.75-7.39l-26.63,2.7c-4.46.37-7.57,3.24-8.57,7.87l-6.28,33.63Z"/>
        <polygon className="cls-99" points="237.05 3137.11 242.66 3108.05 206.68 3111.6 198.31 3142.48 237.05 3137.11"/>
        <path className="cls-23" d="m155.81,3094.49l24.83-4.51c5.87-.93,12.48.92,13.63,6.29,2.38,11.17,1.72,22.98,5.27,45.36l-39.28,18.54-9.5-56.57c-.4-3.65.9-7.86,5.04-9.1Z"/>
        <path className="cls-99" d="m158.3,3097.64l22.66-4.51c5.36-.93,10.74,1.12,12.43,6.29,0,0,6.79,19.94,6.16,42.21l-39.28,18.54-6.58-53.42c-.36-3.83.82-7.86,4.6-9.1Z"/>
        <path className="cls-23" d="m160.27,3160.16l44.31-6.15,5.92-30.65c-.37-4.46-4.29-7.77-8.75-7.39l-26.63,2.7c-4.46.37-7.57,3.24-8.57,7.87l-6.28,33.63Z"/>
        <polygon className="cls-99" points="204.58 3154.01 210.19 3124.96 174.21 3128.5 165.84 3159.39 204.58 3154.01"/>
        <path className="cls-23" d="m123.34,3111.4l24.83-4.51c5.87-.93,12.48.92,13.63,6.29,2.38,11.17,1.72,22.98,5.27,45.36l-39.28,18.54-9.5-56.57c-.4-3.65.9-7.86,5.04-9.1Z"/>
        <path className="cls-99" d="m125.83,3114.55l22.66-4.51c5.36-.93,10.74,1.12,12.43,6.29,0,0,6.79,19.94,6.16,42.21l-39.28,18.54-6.58-53.42c-.36-3.83.82-7.86,4.6-9.1Z"/>
        <path className="cls-23" d="m127.8,3177.07l44.31-6.15,5.92-30.65c-.37-4.46-4.29-7.77-8.75-7.39l-26.63,2.7c-4.46.37-7.57,3.24-8.57,7.87l-6.28,33.63Z"/>
        <polygon className="cls-99" points="172.11 3170.92 177.72 3141.86 141.74 3145.41 133.38 3176.3 172.11 3170.92"/>
      </g>
    </g>
    <g id="Gemeente_gebouw" data-name="Gemeente gebouw">
      <polygon className="cls-23" points="367.9 2820.54 360.26 2820.54 354.38 2815.25 312.5 2815.25 305.48 2820.54 300.08 2820.54 285.5 2829.94 285.5 2836.4 380.12 2836.4 380.12 2829.94 367.9 2820.54"/>
      <polygon className="cls-60" points="384.36 2921.49 384.36 2829.29 281.49 2829.29 281.49 2869.76 249.52 2881.99 249.52 3047.59 281.49 3047.59 283.95 3047.59 369.57 3047.59 384.36 3047.59 404 3047.59 404 2929 384.36 2921.49"/>
      <g>
        <g>
          <rect className="cls-93" x="305.5" y="2892.77" width="20.9" height="22.33"/>
          <rect className="cls-93" x="305.5" y="2848.05" width="20.9" height="22.33"/>
        </g>
        <g>
          <rect className="cls-93" x="344.86" y="2892.77" width="20.91" height="22.33"/>
          <rect className="cls-93" x="344.86" y="2848.05" width="20.91" height="22.33"/>
        </g>
      </g>
      <g>
        <g>
          <rect className="cls-93" x="305.5" y="2984.75" width="20.9" height="22.33"/>
          <rect className="cls-93" x="305.5" y="2940.04" width="20.9" height="22.33"/>
        </g>
        <g>
          <rect className="cls-93" x="344.86" y="2984.75" width="20.91" height="22.33"/>
          <rect className="cls-93" x="344.86" y="2940.04" width="20.91" height="22.33"/>
        </g>
      </g>
      <rect className="cls-93" x="259.45" y="2892.77" width="14.56" height="22.33"/>
      <rect className="cls-93" x="259.45" y="2940.04" width="14.56" height="22.33"/>
      <rect className="cls-93" x="259.45" y="2984.75" width="14.56" height="22.33"/>
    </g>
    <g id="Faouzi_Achbar" data-name="Faouzi Achbar">
      <g>
        <polygon className="cls-83" points="259.9 2977.25 259.9 2959.96 220.31 2958.1 220.31 2973.53 222.55 3050.45 233.08 3050.45 239.21 2989.16 242.32 2993.84 246.83 3050.45 256.44 3050.45 259.9 2977.25"/>
        <path className="cls-23" d="m242.7,2969.81c.04.84-.81,13.25,3.97,14.91,4.78,1.67,16.02-3.39,16.02-3.39l-2.25-19.11"/>
        <path className="cls-23" d="m218.29,2966.16l-2.25,16.3s10.04,3.65,16.26,1.68c6.22-1.97,7.8-14.61,7.8-14.61l-21.81-3.37Z"/>
        <polygon className="cls-76" points="247.09 2897.39 239.15 2891.76 231.67 2894.96 231.67 2917.66 247.09 2917.66 247.09 2897.39"/>
        <path className="cls-36" d="m239.52,2909.85l-7.8-6.75-1.72,3.23s-17.15,3.29-21.16,7.62c-6.27,6.76-4.04,53.34-4.04,53.34l24.05,7.76,4.15-13.55-16.68-6.3,1.71-13.71,2.27,30.49,38.82.45,3.95-37.79,2.17,15.18-12.11,10.06,5.99,12.54,18.39-20.63s-4.27-31.86-10.45-37.87c-3.64-3.54-18.28-6.98-18.28-6.98l-1.76-3.22-7.52,6.11Z"/>
        <path className="cls-76" d="m230.16,2968.09c-.51-2.48,1.46-4.19,3.74-4.33.07,0,.13,0,.2-.01,1.38-.08,2.46,1.01,3.14,2.05.39.59,1.25,1.49,2,1.65.05-.02.1-.04.17-.06.29-.11.58-.22.86-.33.79-.3,1.57-.52,2.35-.74.73-.21,1.37.27,1.74.84,2.18,3.31-2.37,7-5.38,7.15-3.5.18-8.08-2.64-8.82-6.21Z"/>
        <path className="cls-76" d="m238.58,2968.81c.43-1.58,2.03-2.51,3.54-3.16,2.97-1.27,6.14-2.08,9.35-2.39.18-.02.37-.03.53.04.24.11.36.38.45.64.61,1.79.61,3.8-.1,5.56-.71,1.76-2.14,3.23-3.9,3.91-1.08.42-2.25.54-3.41.55-1.6.02-3.27-.18-4.62-1.05-1.35-.87-2.27-2.55-1.84-4.1Z"/>
        <polygon className="cls-38" points="233 2961.5 227.84 2975.06 221.52 2972.68 226.35 2959.7 233 2961.5"/>
        <polygon className="cls-38" points="248.64 2963.63 253.88 2974.87 259.13 2972.43 253.14 2959.89 248.64 2963.63"/>
        <path className="cls-38" d="m231.72,2913.99s1.84,14.87,1.56,23.98,7.3,16.11,7.3,16.11c0,0,5.43-6.18,6.74-12.92,1.31-6.74-.56-30.78-.56-30.78l-7.24-1.54-7.8,5.15Z"/>
        <path className="cls-23" d="m224.26,3047.7l2.7,2.61s3.38-.15,4.51-1.89c1.13-1.74,2.05-4.29,2.05-4.29,0,0,.82,1.32.2,4.19-.61,2.87-1.23,6.44-1.84,10.65-.61,4.2-4.94,15.8-7.58,13.93-3.95-2.8-4.58-7.91-4.35-10.81.29-3.74,4.31-14.38,4.31-14.38Z"/>
        <path className="cls-23" d="m245.66,3045.62l-.31,6.39,10.34,4.4s.76.57,3.84,1.62c4.33,1.46,16.9,1.65,17.41,1.17.51-.48.66-2.49.32-3.04-1.18-1.87-4.26-2.11-6.11-3.03-1.84-.92-14.42-8.99-14.42-8.99l-4.01,4.92"/>
        <polygon className="cls-38" points="231.72 2903.1 228.96 2916.95 239.52 2909.85 250.07 2917.66 247.04 2903.74 239.52 2908.84 231.72 2903.1"/>
      </g>
      <ellipse className="cls-76" cx="242.66" cy="2885.03" rx="15.2" ry="19.7"/>
      <ellipse className="cls-76" cx="227.26" cy="2887.12" rx="3.26" ry="5.33" transform="translate(-599.91 111.71) rotate(-12.1)"/>
      <ellipse className="cls-76" cx="256.06" cy="2887.12" rx="5.33" ry="3.26" transform="translate(-2620.63 2532.54) rotate(-77.9)"/>
      <path className="cls-23" d="m227.63,2882.14s-.1,2.48,1.33,2.08c1.43-.4,2.82-9.7,5.82-11.59,0,0,13.2,9.65,18.92,1.27,0,0,3.27,15.15,4.16,8.31,0,0-.43-7.11-5.56-12.4,0,0-.6-2.4-1.92-2.88-1.32-.49-1.74-.28-1.95-.83s-2.36-.45-3.26-.75c-.9-.3-2.31-.74-3.19-.32s-1.05-.25-2.19.13c-1.14.38-2.43.22-2.86,1.05s-1.59.38-2.44,1.36c-.86.98-1.97.97-2.32,2.32s-4.65,7.39-4.55,12.27Z"/>
    </g>
    <text transform="translate(541.91 3126.45)"><tspan className="cls-24"><tspan x="0" y="0">1.400 – 1.700</tspan></tspan><tspan className="cls-33"><tspan x="-2.64" y="18">Oekraïense kinderen </tspan></tspan><tspan className="cls-33"><tspan x="16.8" y="36">opgevangen in </tspan></tspan><tspan className="cls-33"><tspan x="-25" y="54">regio Rotterdam-Rijnmond</tspan></tspan></text>
    <g id="Vlag">
      <polygon className="cls-38" points="486.56 3119.5 483.44 3119.19 490.85 3028.24 493.97 3028.55 486.56 3119.5"/>
      <path className="cls-60" d="m563.89,3052.36c-24.84,9.32-49.67-19.44-74.51-7.82.73-8.5,1.46-16.97,2.19-25.41,24.84-9.32,49.67,19.44,74.51,7.82-.73,8.5-1.46,16.97-2.19,25.41Z"/>
      <path className="cls-70" d="m561.84,3075.08c-24.84,9.11-49.67-19.05-74.51-7.76.71-8.23,1.41-16.43,2.12-24.6,24.84-9.11,49.67,19.05,74.51,7.76-.71,8.23-1.41,16.43-2.12,24.6Z"/>
    </g>
    <g id="Puber">
      <path className="cls-23" d="m441.59,3220.44c-.43-.16-.76-.51-.97-.91-.73-1.33-.29-3.07.67-4.25.95-1.19,2.32-1.95,3.66-2.67,1.57-.86,3.15-1.72,4.86-2.27,1.71-.55,3.56-.76,5.29-.29.66,1.09.42,2.55-.27,3.62-.69,1.07-1.77,1.83-2.85,2.5-2.68,1.69-5.56,3.06-8.55,4.1-.59.2-1.24.39-1.83.18Z"/>
      <path className="cls-23" d="m480.51,3220.44c.43-.16.76-.51.97-.91.73-1.33.29-3.07-.67-4.25-.95-1.19-2.32-1.95-3.66-2.67-1.57-.86-3.15-1.72-4.86-2.27-1.71-.55-3.56-.76-5.29-.29-.66,1.09-.42,2.55.27,3.62.69,1.07,1.77,1.83,2.85,2.5,2.68,1.69,5.56,3.06,8.55,4.1.59.2,1.24.39,1.83.18Z"/>
      <path className="cls-23" d="m447.4,3217.41c-2.08,1.08-4.4,2.21-6.71,2.22.22.35.52.66.91.81.59.22,1.24.03,1.83-.18,2.99-1.03,5.87-2.41,8.55-4.1,1.08-.68,2.16-1.44,2.85-2.5.49-.75.74-1.69.62-2.56-1.91,2.86-5.05,4.75-8.05,6.31Z"/>
      <path className="cls-23" d="m479.11,3219.15c-2.36-.67-4.53-1.99-6.65-3.19-1.93-1.1-4.54-2.46-5.83-4.43,0,.74.25,1.5.65,2.12.69,1.07,1.77,1.83,2.85,2.5,2.68,1.69,5.56,3.06,8.55,4.1.59.2,1.24.39,1.83.18.43-.16.76-.51.97-.91.05-.1.09-.2.13-.3-.75.18-1.61.18-2.5-.07Z"/>
      <polygon className="cls-70" points="480.41 3164.73 478.46 3150.5 449.18 3148.97 449.18 3161.67 447.99 3210.86 453.6 3210.86 462.11 3164.67 467.16 3163.28 467.01 3210.04 473.72 3210.86 480.41 3164.73"/>
      <polygon className="cls-60" points="473.88 3101.21 464.37 3098.98 456.06 3100.57 453.94 3110.87 476.72 3110.87 473.88 3101.21"/>
      <path className="cls-60" d="m481.77,3149.65c.39-.58,2.07,11.22,2.53,10.71,1.26-1.37,3.19-2.33,5.12-2.77l-2.63-23.85-.53-29.24-12.37-3.3c-.63,4.45-3.65,8.62-8.56,8.26-3.51-.26-5.97-2.58-7.76-5.45-.71-1.13-1.23-2.29-1.51-3.45l-11.34,2.73-13.9,27.94,12.84,17.73,4.67-4.56-8.44-13.84,9.83-15.04-.53,39.63,30.36,2.89.45-15.62,1.78,7.21Z"/>
      <path className="cls-72" d="m458.06,3149.01c-.24.84-.91,1.6-1.7,2.02-3.7,1.99-9.19,1.61-11.08-2.67-.06-.14-.12-.29-.09-.45.02-.12.09-.22.16-.31.72-1,1.44-2,2.16-3,.28-.38.56-.77.93-1.07,2.25-1.78,9.06,1.76,9.65,4.31.09.39.07.78-.04,1.16Z"/>
      <path className="cls-72" d="m490.67,3159.25c.96,2,.87,4.79.55,6.77-.36,2.24-1.5,1.11-2.74.07-2.08-1.75-4.61-4.33-4.9-7.15-.03-.32-.04-.65.03-.97.16-.71,1.15-2.29,1.94-2.33.35-.02.69.1,1.01.23,1.18.47,2.26,1.18,3.18,2.06.39.37.69.83.94,1.33Z"/>
      <g>
        <path className="cls-72" d="m458.83,3103.04c0,3.48,11.39,3.48,11.39,0v-9.03h-11.39v9.03Z"/>
        <path className="cls-72" d="m482.6,3082.54c0,10.47-8.09,18.96-18.08,18.96s-18.08-8.49-18.08-18.96,8.09-18.96,18.08-18.96,18.08,8.49,18.08,18.96Z"/>
        <path className="cls-23" d="m456.38,3083.25c0-.75.6-1.35,1.35-1.35s1.35.6,1.35,1.35-.6,1.35-1.35,1.35-1.35-.6-1.35-1.35Z"/>
        <path className="cls-23" d="m469.54,3083.25c0-.75.6-1.35,1.35-1.35s1.35.6,1.35,1.35-.6,1.35-1.35,1.35-1.35-.6-1.35-1.35Z"/>
        <path className="cls-8" d="m459.21,3094.93c4.42.25,4.12,0,7.66,1.04"/>
        <path className="cls-56" d="m452.68,3076.8l6.38-7.12,16.7,7.81-.1,26.48h4.91c6.37-1.21,7.56-9.47,6.51-17.05-3.48-25.11-16.28-26.38-23.82-26.17-2.35.07-4.53,1.26-5.86,3.19h0c-5.25-1.72-13.96,2.14-15.76,21.7v5.21c0,6.15,4.97,12.54,11.11,12.49"/>
      </g>
      <rect className="cls-23" x="468.26" y="3087.76" width=".89" height=".89"/>
      <rect className="cls-23" x="471.3" y="3087.76" width=".89" height=".89"/>
      <rect className="cls-23" x="470.14" y="3090.15" width=".89" height=".89"/>
      <rect className="cls-23" x="454.89" y="3090.03" width=".89" height=".89"/>
      <rect className="cls-23" x="457.09" y="3087.94" width=".89" height=".89"/>
    </g>
    <g id="Kleuter">
      <path className="cls-23" d="m505.72,3230.04c.34-.12.6-.41.77-.73.58-1.06.23-2.44-.53-3.38-.76-.94-1.85-1.55-2.91-2.13-1.25-.68-2.51-1.37-3.86-1.8-1.36-.43-2.83-.6-4.2-.23-.52.87-.34,2.02.21,2.87.55.85,1.41,1.45,2.26,1.99,2.13,1.34,4.42,2.43,6.8,3.25.47.16.99.31,1.45.14Z"/>
      <path className="cls-23" d="m474.8,3230.04c-.34-.12-.6-.41-.77-.73-.58-1.06-.23-2.44.53-3.38s1.85-1.55,2.91-2.13c1.25-.68,2.51-1.37,3.86-1.8,1.36-.43,2.83-.6,4.2-.23.52.87.34,2.02-.21,2.87-.55.85-1.41,1.45-2.26,1.99-2.13,1.34-4.42,2.43-6.8,3.25-.47.16-.99.31-1.45.14Z"/>
      <path className="cls-23" d="m501.11,3227.63c1.65.86,3.49,1.75,5.33,1.77-.17.28-.41.53-.72.64-.47.17-.98.02-1.45-.14-2.38-.82-4.66-1.91-6.8-3.25-.86-.54-1.71-1.14-2.26-1.99-.39-.6-.59-1.34-.5-2.03,1.52,2.27,4.01,3.78,6.39,5.01Z"/>
      <path className="cls-23" d="m475.91,3229.02c1.87-.54,3.6-1.58,5.29-2.53,1.54-.87,3.61-1.96,4.64-3.52,0,.59-.2,1.19-.52,1.69-.55.85-1.41,1.45-2.26,1.99-2.13,1.34-4.42,2.43-6.8,3.25-.47.16-.99.31-1.45.14-.34-.12-.6-.41-.77-.73-.04-.08-.07-.16-.1-.24.6.15,1.28.15,1.99-.06Z"/>
      <polygon className="cls-70" points="479.47 3201.11 481.02 3189.81 503.66 3188.59 504.28 3198.68 500.64 3222.43 493.75 3222.43 494.01 3201.06 489.99 3199.96 486.47 3222.43 480.19 3222.43 479.47 3201.11"/>
      <path className="cls-60" d="m480.32,3194.9c-1.61-1.63.7-6.79.6-9.09-.15-3.4.03-4.55.31-9.61,0-.16.01-.34-.07-.47-.08-.13-.24-.19-.38-.25-.84-.33-1.67-.67-2.51-1-.15-.06-.33-.15-.35-.32-.01-.1.04-.19.09-.27.64-1.04,1.27-2.09,1.91-3.13.89-1.47,1.82-2.97,3.17-4.03,1.04-.81,2.26-1.31,3.5-1.72,1.11-.37,1.68-.97,2.67-1.57,1.96-1.18,4.25-1.72,6.52-1.31,2.4.43,3.59,1.95,5.46,3.18,2.12,1.4,5.34,1.9,7.82,2.03-.02,2.77-.38,5.53-1.07,8.21-1.27-.14-2.54-.29-3.82-.43-.13-.01.19,16.86-.32,18.44"/>
      <path className="cls-53" d="m509.02,3168.27c.1-.01.19-.01.29-.02,2.04-.29,5.91-1.35,6.04-4.05,0-.19,0-.39-.02-.58-.02-.31-.04-.62.04-.92.14-.56.61-.99,1.13-1.19,1.08-.42,2.64-.3,3.5.53.83.79,1.11,2.12.73,3.23-.34,1-1.06,1.49-1.91,1.97-.85.48-1.39,1.04-2.17,1.72-1.63,1.41-3.58,2.39-5.56,3.14-.83.32-1.73.65-2.55.81l.47-4.63Z"/>
      <path className="cls-53" d="m480.04,3175.17c.58.2,1.05.36,1.01.45-.84,1.88-1.34,6.91.88,8.46.16.11.33.2.5.3.27.15.55.3.76.52.39.42.51,1.04.41,1.59-.22,1.14-1.15,2.4-2.31,2.69-1.11.28-2.39-.18-3.12-1.1-.66-.82-.7-1.69-.66-2.66.04-.98-.15-1.73-.3-2.75-.33-2.13-.13-4.3.29-6.37.17-.87.35-1.09.65-1.87l1.89.75Z"/>
      <g>
        <ellipse className="cls-53" cx="493.48" cy="3152.64" rx="16.43" ry="17.24"/>
        <path className="cls-23" d="m500.88,3153.28c0-.68-.55-1.23-1.23-1.23s-1.23.55-1.23,1.23.55,1.23,1.23,1.23,1.23-.55,1.23-1.23Z"/>
        <path className="cls-23" d="m488.92,3153.28c0-.68-.55-1.23-1.23-1.23s-1.23.55-1.23,1.23.55,1.23,1.23,1.23,1.23-.55,1.23-1.23Z"/>
        <path className="cls-8" d="m496.73,3165.15c-1.2,0-3.04.11-4.65-.25"/>
      </g>
      <path className="cls-53" d="m509.36,3153.72c-.64,2.41,0,4.67,1.43,5.06,1.43.38,3.12-1.26,3.76-3.67.64-2.41,0-4.67-1.43-5.06-1.43-.38-3.12,1.26-3.76,3.67Z"/>
      <path className="cls-53" d="m479.59,3153.72c.64,2.41,0,4.67-1.43,5.06-1.43.38-3.12-1.26-3.76-3.67-.64-2.41,0-4.67,1.43-5.06,1.43-.38,3.12,1.26,3.76,3.67Z"/>
      <path className="cls-23" d="m493.12,3128.69c-1.99-.88-4.24-1.2-6.39-.88-2.86.43-5.45,1.92-7.78,3.63-3.31,2.44-6.41,5.73-6.87,9.81-.23,2.01.59,3.8.59,5.74,0,.51-.79,4.18-1.54,4.02,1.28.27,2.7-.26,3.48-1.31-.35.46,1.98,3.92,2.31,4.32.08.1.17.2.29.25.48.19.76-.53.8-1.04.21-3.16,1.55-6.23,3.74-8.52,2.18-2.29,5.19-3.78,8.33-4.14-.8.37-1.26,1.26-1.33,2.13-.08.88.16,1.75.45,2.58,1.12.11,1.77-1.2,2.56-2,1.28-1.3,3.31-1.37,5.06-1.89,1.84-.55,3.49-1.67,4.69-3.17.27.8,4.35,9.38,3.43,9.88.82-.44,1.4-1.31,1.48-2.24,1.71,3.14,2.65,6.7,2.71,10.28,0,.23.06.53.29.56,1.02-1.8,1.44-4.33,2.81-5.86.34-.38.84-.64,1.34-.56,0-2.32-.09-4.65-.08-6.96,0-2.05-.03-4.18-.88-6.09-.9-2.03-2.71-3.36-4.54-4.51-.93-.59-1.78-1.09-2.89-1.3-.25-.05-3.19-.01-3.07.2-1.79-3.23-5.66-5.18-9.32-4.68.96.69,1.71,1.66,2.15,2.76-.57-.39-1.17-.72-1.8-1Z"/>
    </g>
    <g id="Baby">
      <path className="cls-70" d="m427.66,3201.74l-2.81,4.89s-1.35,2.76-1.77,4.5c-.51,2.11-.38,3-.38,3,0,0-2.36,1.05-3.57,2.1-.94.82-1.59,1.43-1.94,1.77l.76,4.3c3.58-.05,9.68-.25,13.09-.29h.68v-19.35l-4.06-.93Z"/>
      <path className="cls-70" d="m444.31,3216.24c-1.21-1.05-3.57-2.1-3.57-2.1,0,0,.13-.89-.38-3-.42-1.74-1.43-3.01-1.43-3.01l-2.43-5.49-5.04.03v19.35h.95c3.04.03,8.21.19,11.84.26l1.77-4.51c-.38-.36-.94-.88-1.7-1.54Z"/>
      <path className="cls-60" d="m428.06,3201.88l-.42,4.62s.88.91,3.61.83c2.45-.07,3.08-.79,3.08-.79v-4.49h1.94v4.66s1.81,2.23,2.58,3.91c.69,1.49.99,2.16.99,2.16,0,0,1.76.88,3.17,2.02,1.39,1.13,2.87,2.75,2.87,2.75,0,0-1.07.91-1.59,2.08-.51,1.18-.5,2.72-.5,2.72,0,0-3.17.23-5.35.11-2.18-.12-7.37,0-8.94-.12-1.57-.12-5.36.09-7.44.12-1.96.03-3.84-.12-3.84-.12,0,0,.49-1.19.15-2.61-.36-1.5-1-2.04-1-2.04,0,0,.66-1.4,2.06-2.53,1.82-1.47,3.43-2.11,3.43-2.11-.04,0,.57-.77,1.16-2.47.86-2.49,1.81-4.03,1.81-4.03l.56-5.3,1.68.63Z"/>
      <path className="cls-66" d="m438.47,3202.58s2.55-.09,2.81,3.34c.19,2.46-.08,5.68-.97,8.53-1.32,4.22-3.12,5.59-3.12,5.59,0,0-.13-1.19-1.36-1.36-1.23-.18-2.2,0-2.2,0,0,0,0-4.84.79-8.36.79-3.52,1.41-6.69,1.85-6.95.44-.26,2.2-.79,2.2-.79Z"/>
      <path className="cls-66" d="m423.53,3202.58s-2.55-.09-2.81,3.34c-.19,2.46.08,5.68.97,8.53,1.32,4.22,3.12,5.59,3.12,5.59,0,0,.13-1.19,1.36-1.36,1.23-.18,2.2,0,2.2,0,0,0,0-4.84-.79-8.36-.79-3.52-1.41-6.69-1.85-6.95-.44-.26-2.2-.79-2.2-.79Z"/>
      <g>
        <path className="cls-91" d="m449.78,3185.19c.7,10.01-7.31,18.72-17.88,19.46-10.57.74-19.7-6.78-20.4-16.79-.7-10.01,7.31-18.72,17.88-19.46,10.57-.74,19.7,6.78,20.4,16.79Z"/>
        <path className="cls-91" d="m414.7,3195.34c-.36,1.32-1.71,2.1-3.03,1.74-1.32-.36-2.1-1.71-1.74-3.03.36-1.32,1.72-2.1,3.03-1.74,1.32.36,2.1,1.71,1.74,3.03Z"/>
        <path className="cls-91" d="m452.63,3191.38c-.36,1.32-1.72,2.1-3.03,1.74-1.32-.36-2.1-1.71-1.74-3.03.36-1.32,1.72-2.1,3.03-1.74,1.32.36,2.1,1.71,1.74,3.03Z"/>
        <g>
          <g>
            <path className="cls-23" d="m424.42,3187.65c-.07-.68-.68-1.18-1.36-1.11-.68.07-1.18.68-1.1,1.36.07.68.68,1.18,1.36,1.1.68-.07,1.18-.68,1.11-1.36Z"/>
            <path className="cls-23" d="m440.21,3186c-.07-.68-.68-1.18-1.36-1.11-.68.07-1.18.68-1.11,1.36.07.68.68,1.18,1.36,1.1.68-.07,1.18-.68,1.1-1.36Z"/>
          </g>
          <path className="cls-27" d="m431.61,3191.15c1.82,0,2.83,1.48,2.88,1.55l-.3.2s-1.08-1.56-2.9-1.37c-1.82.19-2.44,1.9-2.45,1.92l-.34-.12c.03-.08.7-1.95,2.75-2.16.12-.01.24-.02.36-.02h0Z"/>
          <path className="cls-23" d="m430.66,3171.93c-1.23,0-2.3-.58-2.9-1.59-.21-.36-.35-.74-.39-1.11-.81-.55-1.34-1.53-1.04-2.73.33-1.29,1.16-1.73,1.19-1.75l.25.49s-.65.36-.91,1.39c-.2.79.05,1.45.52,1.9.05-.29.15-.57.31-.82.66-1.03,1.87-1.3,2.61-.95.45.21.83.73.57,1.54-.42,1.32-1.64,1.61-2.67,1.31-.07-.02-.13-.04-.19-.06.06.17.13.35.23.51.55.91,1.56,1.4,2.71,1.31,2.15-.17,2.97-2.01,3-2.09l.5.21c-.04.09-.97,2.22-3.46,2.42-.11,0-.21.01-.31.01h0Zm-2.77-3.02c.14.08.3.14.45.18.71.21,1.67.08,2-.96.1-.3.12-.69-.28-.88-.52-.24-1.43,0-1.91.76-.17.26-.25.58-.25.9h0Z"/>
        </g>
        <circle className="cls-91" cx="420.62" cy="3193.91" r="1.87"/>
        <circle className="cls-91" cx="442.28" cy="3191.72" r="1.87"/>
      </g>
      <path className="cls-91" d="m427.92,3223.88c-.43.08-.92-.06-1.29-.33-1.71-1.25-2.73-3.82-1.2-5.61.05-.06.11-.12.18-.14.06-.02.12,0,.18,0,.61.11,1.22.22,1.83.33.23.04.47.08.69.19,1.3.63,1.17,4.5.14,5.33-.16.13-.34.2-.53.23Z"/>
      <path className="cls-91" d="m436.15,3223.78c-.43.07-.92-.06-1.29-.33-1.71-1.25-2.73-3.82-1.2-5.61.05-.06.11-.12.18-.14.06-.02.12,0,.18,0,.61.11,1.22.22,1.83.33.24.04.47.08.69.19,1.3.63,1.17,4.5.14,5.33-.16.13-.34.2-.53.24Z"/>
      <path className="cls-91" d="m417.58,3222.97c-.65.11-1.38-.09-1.93-.49-2.56-1.87-4.09-5.72-1.8-8.41.08-.09.16-.18.27-.21.09-.02.18,0,.27,0,.91.16,1.83.33,2.74.49.35.06.71.13,1.03.28,1.94.95,1.76,6.74.22,7.97-.24.19-.51.3-.8.35Z"/>
      <path className="cls-91" d="m442.75,3222.65c-.58-.31-1.03-.93-1.22-1.58-.87-3.05.3-7.03,3.76-7.73.11-.02.24-.04.34,0,.08.04.15.1.2.17.62.69,1.24,1.38,1.86,2.08.24.27.48.54.64.86.95,1.94-2.77,6.39-4.74,6.41-.3,0-.59-.08-.85-.21Z"/>
    </g>
    <text transform="translate(91.98 2983.97)"><tspan className="cls-24"><tspan x="0" y="0">16 maa</tspan><tspan className="cls-132" x="80" y="0">r</tspan><tspan x="90.6" y="0">t </tspan></tspan><tspan className="cls-33"><tspan x="-6.08" y="18">gemeenteraads-</tspan></tspan><tspan className="cls-33"><tspan x="6.44" y="36">verkiezingen</tspan></tspan></text>
    <text transform="translate(320.15 3142.86)"><tspan className="cls-24"><tspan x="0" y="0">3</tspan></tspan><tspan className="cls-33"><tspan x="-60.86" y="18">beleidsadviesnota’s </tspan></tspan><tspan className="cls-33"><tspan x="-67.58" y="36">zoals Early Life Stress</tspan></tspan></text>
    <text transform="translate(314.53 3067.94)"><tspan className="cls-24"><tspan  x="0" className="number" y="0">7</tspan><tspan>.</tspan><tspan  className="number">660</tspan></tspan><tspan className="cls-33"><tspan x="-49.62" y="18">echtparen in scheiding </tspan></tspan><tspan className="cls-33"><tspan x="-63.6" y="36">regio Rotterdam-Rijnmond</tspan></tspan></text>
    <text transform="translate(184.1 3186.93)"><tspan className="cls-24"><tspan x="0" y="0">3</tspan></tspan><tspan className="cls-33"><tspan x="-54.26" y="18">theatercolleges in </tspan></tspan><tspan className="cls-33"><tspan x="-48.45" y="36">BAR-gemeenten</tspan></tspan></text>
    <text transform="translate(487.4 2968.95)"><tspan className="cls-25"><tspan className="number" x="0" y="0">14</tspan></tspan><tspan className="cls-33"><tspan x="-15.8" y="18">gemeenten in </tspan></tspan><tspan className="cls-33"><tspan x="-67.96" y="36">Raad voor het Publiek Belang</tspan></tspan></text>
    <g id="Titel-4" data-name="Titel">
      <rect id="Achtergrond-5" data-name="Achtergrond" className="cls-38" x="422.87" y="2864.41" width="243.47" height="55.45" rx="4.2" ry="4.2"/>
      <text className="cls-49" transform="translate(445.78 2903.11)"><tspan x="0" y="0">Gemeenten</tspan></text>
    </g>
        </g>
        </> 
    )
  }
  return (
    <>
    <g ref={mobileScreen} id="Gemeenten">
    <path id="Ondergrond-3" data-name="Ondergrond" className="cls-91" d="m271.66,3064.64h-36.86c-16.84,0-30.49-13.65-30.49-30.49s13.65-30.49,30.49-30.49h23.03c10.46-.02,18.94-8.51,18.94-18.98s-8.5-18.98-18.98-18.98h-50.43c8.1,0,14.66-6.56,14.66-14.66s-6.56-14.66-14.66-14.66H57.59c-8.1,0-14.66,6.56-14.66,14.66s6.56,14.66,14.66,14.66h-8.56c-10.48,0-18.98,8.5-18.98,18.98s8.5,18.99,18.98,18.99h-10.5c-12.78,0-22.5,10.36-22.5,23.15s10.37,23.15,23.15,23.15h17.2s-.08,0-.11,0c-.07,0-.15,0-.22,0-12.15,0-22,9.85-22,22s9.85,22,22,22h215.62c8.1,0,14.66-6.56,14.66-14.66s-6.56-14.66-14.66-14.66Z"/>
    <text transform="translate(165.56 3114.9)"><tspan className="cls-5"><tspan  x="0" y="0">1.400 – 1.700</tspan></tspan><tspan className="cls-115"><tspan x="-6.08" y="16">Oekraïense kinderen </tspan></tspan><tspan className="cls-115"><tspan x="-5.69" y="32">opgevangen in regio </tspan></tspan><tspan className="cls-115"><tspan x="-8.99" y="48">Rotterdam-Rijnmond</tspan></tspan></text>
    <g id="Vlag">
      <polygon className="cls-57" points="217.65 3022.87 215.76 3022.69 220.25 2967.59 222.14 2967.77 217.65 3022.87"/>
      <path className="cls-48" d="m264.5,2982.2c-15.05,5.65-30.09-11.78-45.14-4.74.44-5.15.88-10.28,1.32-15.4,15.05-5.65,30.09,11.78,45.14,4.74-.44,5.15-.88,10.28-1.32,15.4Z"/>
      <path className="cls-65" d="m263.26,2995.97c-15.05,5.52-30.09-11.54-45.14-4.7.43-4.99.86-9.95,1.28-14.9,15.05-5.52,30.09,11.54,45.14,4.7-.43,4.99-.86,9.95-1.28,14.9Z"/>
    </g>
    <text transform="translate(245.73 3022.39)"><tspan className="cls-5"><tspan x="0" y="0">16 maa</tspan><tspan className="cls-119" x="64" y="0">r</tspan><tspan x="72.48" y="0">t </tspan></tspan><tspan className="cls-115"><tspan x="-8.04" y="16">gemeenteraads-</tspan></tspan><tspan className="cls-115"><tspan x="2.69" y="32">verkiezingen</tspan></tspan></text>
    <text transform="translate(98.81 3050.4)"><tspan className="cls-5"><tspan x="0" y="0">3</tspan></tspan><tspan className="cls-115"><tspan x="-52.53" y="16">beleidsadviesnota’s </tspan></tspan><tspan className="cls-115"><tspan x="-58.29" y="32">zoals Early Life Stress</tspan></tspan></text>
    <text transform="translate(49.72 3114.78)"><tspan className="cls-5"><tspan className="number" x="0" y="0">7</tspan><tspan>.</tspan><tspan  className="number">660</tspan></tspan><tspan className="cls-115"><tspan x="-14.33" y="16">echtparen in </tspan></tspan><tspan className="cls-115"><tspan x="-21.86" y="32">scheiding regio </tspan></tspan><tspan className="cls-115"><tspan x="-11.09" y="48">Rotterdam-</tspan></tspan><tspan className="cls-115"><tspan x="-6.15" y="64">Rijnmond</tspan></tspan></text>
    <text transform="translate(133.14 2967.24)"><tspan className="cls-5"><tspan x="0" y="0">3</tspan></tspan><tspan className="cls-115"><tspan x="-46.86" y="16">theatercolleges in </tspan></tspan><tspan className="cls-115"><tspan x="-41.88" y="32">BAR-gemeenten</tspan></tspan></text>
    <g id="Theaterstoelen">
      <g>
        <path className="cls-41" d="m48.93,2976.37l13.79-2.51c3.26-.52,6.93.51,7.57,3.49,1.32,6.2.95,12.76,2.93,25.2l-21.82,10.3-5.28-31.42c-.22-2.03.5-4.37,2.8-5.06Z"/>
        <path className="cls-94" d="m50.31,2978.12l12.59-2.51c2.98-.52,5.96.62,6.91,3.49,0,0,3.77,11.08,3.42,23.45l-21.82,10.3-3.65-29.67c-.2-2.13.45-4.37,2.56-5.06Z"/>
        <path className="cls-41" d="m51.4,3012.84l24.61-3.42,3.29-17.02c-.21-2.48-2.38-4.31-4.86-4.11l-14.79,1.5c-2.48.21-4.21,1.8-4.76,4.37l-3.49,18.68Z"/>
        <polygon className="cls-94" points="76.01 3009.43 79.13 2993.29 59.15 2995.26 54.5 3012.41 76.01 3009.43"/>
        <path className="cls-41" d="m30.89,2985.76l13.79-2.51c3.26-.52,6.93.51,7.57,3.49,1.32,6.2.95,12.76,2.93,25.2l-21.82,10.3-5.28-31.42c-.22-2.03.5-4.37,2.8-5.06Z"/>
        <path className="cls-94" d="m32.27,2987.51l12.59-2.51c2.98-.52,5.96.62,6.91,3.49,0,0,3.77,11.08,3.42,23.45l-21.82,10.3-3.65-29.67c-.2-2.13.45-4.37,2.56-5.06Z"/>
        <path className="cls-41" d="m33.37,3022.24l24.61-3.42,3.29-17.02c-.21-2.48-2.38-4.31-4.86-4.11l-14.79,1.5c-2.48.21-4.21,1.8-4.76,4.37l-3.49,18.68Z"/>
        <polygon className="cls-94" points="57.98 3018.82 61.1 3002.68 41.11 3004.65 36.46 3021.81 57.98 3018.82"/>
        <path className="cls-41" d="m12.86,2995.15l13.79-2.51c3.26-.52,6.93.51,7.57,3.49,1.32,6.2.95,12.76,2.93,25.2l-21.82,10.3-5.28-31.42c-.22-2.03.5-4.37,2.8-5.06Z"/>
        <path className="cls-94" d="m14.24,2996.9l12.59-2.51c2.98-.52,5.96.62,6.91,3.49,0,0,3.77,11.08,3.42,23.45l-21.82,10.3-3.65-29.67c-.2-2.13.45-4.37,2.56-5.06Z"/>
        <path className="cls-41" d="m15.33,3031.63l24.61-3.42,3.29-17.02c-.21-2.48-2.38-4.31-4.86-4.11l-14.79,1.5c-2.48.21-4.21,1.8-4.76,4.37l-3.49,18.68Z"/>
        <polygon className="cls-94" points="39.94 3028.21 43.06 3012.07 23.08 3014.04 18.43 3031.2 39.94 3028.21"/>
      </g>
      <g>
        <path className="cls-41" d="m67.06,2999.1l13.79-2.51c3.26-.52,6.93.51,7.57,3.49,1.32,6.2.95,12.76,2.93,25.2l-21.82,10.3-5.28-31.42c-.22-2.03.5-4.37,2.8-5.06Z"/>
        <path className="cls-94" d="m68.44,3000.85l12.59-2.51c2.98-.52,5.96.62,6.91,3.49,0,0,3.77,11.08,3.42,23.45l-21.82,10.3-3.65-29.67c-.2-2.13.45-4.37,2.56-5.06Z"/>
        <path className="cls-41" d="m69.53,3035.58l24.61-3.42,3.29-17.02c-.21-2.48-2.38-4.31-4.86-4.11l-14.79,1.5c-2.48.21-4.21,1.8-4.76,4.37l-3.49,18.68Z"/>
        <polygon className="cls-94" points="94.15 3032.16 97.26 3016.02 77.28 3017.99 72.63 3035.15 94.15 3032.16"/>
        <path className="cls-41" d="m49.02,3008.49l13.79-2.51c3.26-.52,6.93.51,7.57,3.49,1.32,6.2.95,12.76,2.93,25.2l-21.82,10.3-5.28-31.42c-.22-2.03.5-4.37,2.8-5.06Z"/>
        <path className="cls-94" d="m50.4,3010.24l12.59-2.51c2.98-.52,5.96.62,6.91,3.49,0,0,3.77,11.08,3.42,23.45l-21.82,10.3-3.65-29.67c-.2-2.13.45-4.37,2.56-5.06Z"/>
        <path className="cls-41" d="m51.5,3044.97l24.61-3.42,3.29-17.02c-.21-2.48-2.38-4.31-4.86-4.11l-14.79,1.5c-2.48.21-4.21,1.8-4.76,4.37l-3.49,18.68Z"/>
        <polygon className="cls-94" points="76.11 3041.56 79.23 3025.42 59.24 3027.39 54.59 3044.54 76.11 3041.56"/>
        <path className="cls-41" d="m30.99,3017.88l13.79-2.51c3.26-.52,6.93.51,7.57,3.49,1.32,6.2.95,12.76,2.93,25.2l-21.82,10.3-5.28-31.42c-.22-2.03.5-4.37,2.8-5.06Z"/>
        <path className="cls-94" d="m32.37,3019.63l12.59-2.51c2.98-.52,5.96.62,6.91,3.49,0,0,3.77,11.08,3.42,23.45l-21.82,10.3-3.65-29.67c-.2-2.13.45-4.37,2.56-5.06Z"/>
        <path className="cls-41" d="m33.46,3054.36l24.61-3.42,3.29-17.02c-.21-2.48-2.38-4.31-4.86-4.11l-14.79,1.5c-2.48.21-4.21,1.8-4.76,4.37l-3.49,18.68Z"/>
        <polygon className="cls-94" points="58.08 3050.95 61.19 3034.81 41.21 3036.78 36.56 3053.93 58.08 3050.95"/>
      </g>
    </g>
    <text transform="translate(222.02 2894.13)"><tspan className="cls-4"><tspan className="number" x="0" y="0">14</tspan></tspan><tspan className="cls-115"><tspan x="-45.85" y="16">gemeenten in Raad voor </tspan></tspan><tspan className="cls-115"><tspan x="-28.98" y="32">het Publiek Belang</tspan></tspan></text>
    <g id="Gemeente_gebouw" data-name="Gemeente gebouw">
      <polygon className="cls-41" points="146.24 2809.54 141.51 2809.54 137.86 2806.26 111.92 2806.26 107.57 2809.54 104.23 2809.54 95.2 2815.36 95.2 2819.36 153.81 2819.36 153.81 2815.36 146.24 2809.54"/>
      <polygon className="cls-48" points="156.44 2872.07 156.44 2814.96 92.71 2814.96 92.71 2840.03 72.91 2847.6 72.91 2950.19 92.71 2950.19 94.24 2950.19 147.27 2950.19 156.44 2950.19 168.6 2950.19 168.6 2876.73 156.44 2872.07"/>
      <g>
        <g>
          <rect className="cls-88" x="107.58" y="2854.28" width="12.95" height="13.83"/>
          <rect className="cls-88" x="107.58" y="2826.58" width="12.95" height="13.83"/>
        </g>
        <g>
          <rect className="cls-88" x="131.97" y="2854.28" width="12.95" height="13.83"/>
          <rect className="cls-88" x="131.97" y="2826.58" width="12.95" height="13.83"/>
        </g>
      </g>
      <g>
        <g>
          <rect className="cls-88" x="107.58" y="2911.26" width="12.95" height="13.83"/>
          <rect className="cls-88" x="107.58" y="2883.57" width="12.95" height="13.83"/>
        </g>
        <g>
          <rect className="cls-88" x="131.97" y="2911.26" width="12.95" height="13.83"/>
          <rect className="cls-88" x="131.97" y="2883.57" width="12.95" height="13.83"/>
        </g>
      </g>
      <rect className="cls-88" x="79.06" y="2854.28" width="9.02" height="13.83"/>
      <rect className="cls-88" x="79.06" y="2883.57" width="9.02" height="13.83"/>
      <rect className="cls-88" x="79.06" y="2911.26" width="9.02" height="13.83"/>
    </g>
    <g id="Faouzi_Achbar" data-name="Faouzi Achbar">
      <g>
        <polygon className="cls-78" points="81.04 2906.61 81.04 2895.9 56.51 2894.75 56.51 2904.31 57.9 2951.96 64.43 2951.96 68.22 2913.99 70.15 2916.89 72.94 2951.96 78.89 2951.96 81.04 2906.61"/>
        <path className="cls-41" d="m70.38,2902.01c.02.52-.5,8.2,2.46,9.24,2.96,1.03,9.92-2.1,9.92-2.1l-1.39-11.83"/>
        <path className="cls-41" d="m55.26,2899.74l-1.39,10.1s6.22,2.26,10.07,1.04c3.85-1.22,4.83-9.05,4.83-9.05l-13.51-2.09Z"/>
        <polygon className="cls-71" points="73.1 2857.15 68.18 2853.66 63.55 2855.64 63.55 2869.7 73.1 2869.7 73.1 2857.15"/>
        <path className="cls-38" d="m68.42,2864.86l-4.83-4.18-1.07,2s-10.62,2.04-13.11,4.72c-3.88,4.19-2.5,33.04-2.5,33.04l14.9,4.81,2.57-8.39-10.33-3.9,1.06-8.49,1.41,18.89,24.05.28,2.45-23.41,1.35,9.41-7.5,6.23,3.71,7.77,11.39-12.78s-2.65-19.73-6.47-23.46c-2.26-2.19-11.32-4.33-11.32-4.33l-1.09-2-4.66,3.78Z" />
        <path className="cls-71" d="m62.62,2900.94c-.32-1.54.9-2.6,2.32-2.68.04,0,.08,0,.12,0,.85-.05,1.52.63,1.94,1.27.24.37.77.92,1.24,1.02.03-.01.06-.02.1-.04.18-.07.36-.14.53-.2.49-.19.98-.32,1.46-.46.45-.13.85.17,1.08.52,1.35,2.05-1.47,4.33-3.33,4.43-2.17.11-5.01-1.63-5.47-3.85Z"/>
        <path className="cls-71" d="m67.83,2901.39c.27-.98,1.26-1.55,2.19-1.95,1.84-.79,3.8-1.29,5.79-1.48.11-.01.23-.02.33.03.15.07.23.24.28.39.38,1.11.38,2.36-.06,3.44-.44,1.09-1.32,2-2.42,2.42-.67.26-1.39.33-2.11.34-.99.01-2.03-.11-2.86-.65-.84-.54-1.4-1.58-1.14-2.54Z"/>
        <polygon className="cls-57" points="64.38 2896.86 61.18 2905.26 57.26 2903.78 60.25 2895.74 64.38 2896.86"/>
        <polygon className="cls-57" points="74.06 2898.18 77.31 2905.14 80.56 2903.63 76.85 2895.86 74.06 2898.18"/>
        <path className="cls-57" d="m63.58,2867.43s1.14,9.21.97,14.85c-.17,5.43,4.52,9.98,4.52,9.98,0,0,3.37-3.83,4.18-8.01.81-4.18-.35-19.06-.35-19.06l-4.49-.95-4.83,3.19Z"/>
        <path className="cls-41" d="m58.96,2950.25l1.67,1.62s2.09-.1,2.79-1.17c.7-1.08,1.27-2.66,1.27-2.66,0,0,.51.82.13,2.59-.38,1.78-.76,3.99-1.14,6.59-.38,2.6-3.06,9.79-4.69,8.63-2.45-1.73-2.84-4.9-2.69-6.7.18-2.32,2.67-8.91,2.67-8.91Z"/>
        <path className="cls-41" d="m72.22,2948.97l-.19,3.96,6.41,2.72s.47.36,2.38,1c2.68.91,10.47,1.02,10.78.72.32-.3.41-1.54.2-1.88-.73-1.16-2.64-1.31-3.78-1.88-1.14-.57-8.94-5.57-8.94-5.57l-2.48,3.05"/>
        <polygon className="cls-57" points="63.58 2860.68 61.88 2869.26 68.42 2864.86 74.95 2869.7 73.07 2861.08 68.42 2864.24 63.58 2860.68"/>
      </g>
      <ellipse className="cls-71" cx="70.36" cy="2849.49" rx="9.41" ry="12.2"/>
      <ellipse className="cls-71" cx="60.82" cy="2850.79" rx="2.02" ry="3.3" transform="translate(-595.99 76.03) rotate(-12.1)"/>
      <ellipse className="cls-71" cx="78.66" cy="2850.79" rx="3.3" ry="2.02" transform="translate(-2725.32 2330.35) rotate(-77.9)"/>
      <path className="cls-41" d="m61.05,2847.7s-.06,1.53.83,1.29,1.75-6.01,3.61-7.18c0,0,8.18,5.98,11.72.79,0,0,2.02,9.38,2.57,5.15,0,0-.26-4.4-3.45-7.68,0,0-.37-1.49-1.19-1.79s-1.08-.17-1.2-.52-1.46-.28-2.02-.46-1.43-.46-1.97-.2-.65-.16-1.36.08-1.5.14-1.77.65-.98.24-1.51.85-1.22.6-1.44,1.44-2.88,4.58-2.82,7.6Z"/>
    </g>
    <g id="Puber">
      <path className="cls-41" d="m190.61,3084.65c-.26-.1-.46-.31-.59-.56-.45-.81-.18-1.87.41-2.59.58-.72,1.42-1.19,2.23-1.63.96-.52,1.92-1.05,2.96-1.38,1.04-.33,2.17-.46,3.22-.18.4.67.26,1.55-.16,2.21-.42.65-1.08,1.11-1.74,1.53-1.64,1.03-3.39,1.87-5.21,2.5-.36.12-.76.24-1.11.11Z"/>
      <path className="cls-41" d="m214.33,3084.65c.26-.1.46-.31.59-.56.45-.81.18-1.87-.41-2.59-.58-.72-1.42-1.19-2.23-1.63-.96-.52-1.92-1.05-2.96-1.38-1.04-.33-2.17-.46-3.23-.18-.4.67-.26,1.55.16,2.21.42.65,1.08,1.11,1.74,1.53,1.64,1.03,3.39,1.87,5.21,2.5.36.12.76.24,1.11.11Z"/>
      <path className="cls-41" d="m194.15,3082.8c-1.27.66-2.68,1.35-4.09,1.35.13.22.32.4.55.49.36.13.75.02,1.11-.11,1.83-.63,3.58-1.47,5.21-2.5.66-.41,1.31-.87,1.74-1.53.3-.46.45-1.03.38-1.56-1.17,1.74-3.08,2.9-4.91,3.85Z"/>
      <path className="cls-41" d="m213.48,3083.87c-1.44-.41-2.76-1.21-4.06-1.94-1.18-.67-2.77-1.5-3.56-2.7,0,.45.15.91.4,1.3.42.65,1.08,1.11,1.74,1.53,1.64,1.03,3.39,1.87,5.21,2.5.36.12.76.24,1.11.11.26-.1.46-.31.59-.56.03-.06.05-.12.08-.18-.46.11-.98.11-1.52-.04Z"/>
      <polygon className="cls-65" points="214.27 3050.69 213.08 3042.01 195.24 3041.08 195.24 3048.82 194.51 3078.81 197.93 3078.81 203.12 3050.65 206.2 3049.8 206.1 3078.31 210.2 3078.81 214.27 3050.69"/>
      <polygon className="cls-48" points="210.29 3011.96 204.49 3010.6 199.43 3011.57 198.14 3017.85 212.02 3017.85 210.29 3011.96"/>
      <path className="cls-48" d="m215.1,3041.49c.24-.35,1.26,6.84,1.55,6.53.77-.84,1.94-1.42,3.12-1.69l-1.6-14.54-.33-17.83-7.54-2.01c-.39,2.71-2.23,5.26-5.22,5.04-2.14-.16-3.64-1.57-4.73-3.32-.43-.69-.75-1.4-.92-2.1l-6.91,1.67-8.48,17.03,7.83,10.81,2.85-2.78-5.15-8.44,6-9.17-.32,24.16,18.51,1.76.28-9.52,1.08,4.4Z"/>
      <path className="cls-67" d="m200.65,3041.1c-.14.51-.55.97-1.03,1.23-2.25,1.21-5.6.98-6.75-1.63-.04-.09-.07-.18-.06-.27.01-.07.06-.13.1-.19.44-.61.88-1.22,1.32-1.83.17-.23.34-.47.57-.65,1.37-1.08,5.52,1.07,5.88,2.63.05.24.04.48-.02.7Z"/>
      <path className="cls-67" d="m220.53,3047.35c.59,1.22.53,2.92.33,4.12-.22,1.37-.92.68-1.67.04-1.27-1.07-2.81-2.64-2.99-4.36-.02-.2-.03-.4.02-.59.1-.43.7-1.4,1.18-1.42.21-.01.42.06.62.14.72.29,1.38.72,1.94,1.26.24.23.42.5.57.81Z"/>
      <g>
        <path className="cls-67" d="m201.12,3013.08c0,2.12,6.95,2.12,6.95,0v-5.51h-6.95v5.51Z"/>
        <path className="cls-67" d="m215.61,3000.58c0,6.38-4.93,11.56-11.02,11.56s-11.02-5.18-11.02-11.56,4.93-11.56,11.02-11.56,11.02,5.18,11.02,11.56Z"/>
        <path className="cls-41" d="m199.63,3001.01c0-.45.37-.82.82-.82s.82.37.82.82-.37.82-.82.82-.82-.37-.82-.82Z"/>
        <path className="cls-41" d="m207.65,3001.01c0-.45.37-.82.82-.82s.82.37.82.82-.37.82-.82.82-.82-.37-.82-.82Z"/>
        <path className="cls-20" d="m201.35,3008.13c2.69.15,2.51,0,4.67.63"/>
        <path className="cls-11" d="m197.37,2997.08l3.89-4.34,10.18,4.76-.06,16.14h2.99c3.88-.74,4.61-5.77,3.97-10.39-2.12-15.31-9.92-16.08-14.52-15.95-1.43.04-2.76.77-3.57,1.95h0c-3.2-1.05-8.51,1.3-9.61,13.23v3.18c0,3.75,3.03,7.64,6.78,7.61"/>
      </g>
      <rect className="cls-41" x="206.87" y="3003.76" width=".54" height=".54"/>
      <rect className="cls-41" x="208.72" y="3003.76" width=".54" height=".54"/>
      <rect className="cls-41" x="208.01" y="3005.22" width=".54" height=".54"/>
      <rect className="cls-41" x="198.71" y="3005.15" width=".54" height=".54"/>
      <rect className="cls-41" x="200.05" y="3003.87" width=".54" height=".54"/>
    </g>
    <g id="Kleuter">
      <path className="cls-41" d="m229.7,3090.5c.21-.08.37-.25.47-.44.35-.65.14-1.48-.32-2.06-.46-.57-1.13-.94-1.77-1.3-.76-.42-1.53-.83-2.35-1.1-.83-.26-1.72-.37-2.56-.14-.32.53-.21,1.23.13,1.75.34.52.86.88,1.38,1.21,1.3.82,2.69,1.48,4.14,1.98.29.1.6.19.88.09Z"/>
      <path className="cls-41" d="m210.85,3090.5c-.21-.08-.37-.25-.47-.44-.35-.65-.14-1.48.32-2.06s1.13-.94,1.77-1.3c.76-.42,1.53-.83,2.35-1.1.83-.26,1.73-.37,2.56-.14.32.53.21,1.23-.13,1.75-.34.52-.86.88-1.38,1.21-1.3.82-2.69,1.48-4.14,1.98-.29.1-.6.19-.89.09Z"/>
      <path className="cls-41" d="m226.89,3089.04c1.01.52,2.13,1.07,3.25,1.08-.11.17-.25.32-.44.39-.28.1-.6.01-.89-.09-1.45-.5-2.84-1.17-4.14-1.98-.52-.33-1.04-.7-1.38-1.21-.24-.36-.36-.82-.3-1.24.93,1.38,2.45,2.3,3.9,3.06Z"/>
      <path className="cls-41" d="m211.53,3089.88c1.14-.33,2.19-.96,3.22-1.55.94-.53,2.2-1.19,2.83-2.14,0,.36-.12.73-.32,1.03-.34.52-.86.88-1.38,1.21-1.3.82-2.69,1.48-4.14,1.98-.29.1-.6.19-.89.09-.21-.08-.37-.25-.47-.44-.03-.05-.04-.1-.06-.15.36.09.78.09,1.21-.03Z"/>
      <polygon className="cls-65" points="213.7 3072.87 214.64 3065.97 228.45 3065.23 228.83 3071.38 226.6 3085.86 222.4 3085.86 222.56 3072.84 220.12 3072.17 217.97 3085.86 214.14 3085.86 213.7 3072.87"/>
      <path className="cls-48" d="m214.22,3069.08c-.98-.99.43-4.14.36-5.54-.09-2.07.02-2.77.19-5.86,0-.1,0-.21-.05-.29-.05-.08-.15-.12-.23-.15-.51-.2-1.02-.41-1.53-.61-.09-.04-.2-.09-.21-.19,0-.06.03-.11.06-.17.39-.64.78-1.27,1.17-1.91.55-.89,1.11-1.81,1.93-2.45.63-.5,1.38-.8,2.14-1.05.68-.23,1.03-.59,1.63-.96,1.19-.72,2.59-1.05,3.97-.8,1.46.26,2.19,1.19,3.33,1.94,1.3.85,3.25,1.16,4.77,1.24-.01,1.69-.23,3.37-.65,5.01-.78-.09-1.55-.18-2.33-.26-.08,0,.12,10.28-.2,11.24"/>
      <path className="cls-43" d="m231.72,3052.85c.06,0,.12,0,.18-.02,1.24-.18,3.6-.82,3.68-2.47,0-.12,0-.24,0-.36-.01-.19-.02-.38.02-.56.09-.34.37-.6.69-.73.66-.26,1.61-.18,2.14.32.5.48.68,1.29.44,1.97-.21.61-.65.91-1.16,1.2-.52.3-.84.63-1.32,1.05-.99.86-2.18,1.46-3.39,1.91-.51.19-1.06.4-1.56.5l.29-2.82Z"/>
      <path className="cls-43" d="m214.05,3057.05c.36.12.64.22.62.27-.51,1.15-.82,4.21.54,5.16.1.07.2.12.31.18.17.09.33.18.46.32.24.25.31.63.25.97-.13.7-.7,1.46-1.41,1.64-.68.17-1.46-.11-1.9-.67-.4-.5-.43-1.03-.4-1.62.03-.6-.09-1.05-.19-1.68-.2-1.3-.08-2.62.18-3.89.11-.53.22-.67.4-1.14l1.15.46Z"/>
      <g>
        <ellipse className="cls-43" cx="222.24" cy="3043.32" rx="10.02" ry="10.51"/>
        <path className="cls-41" d="m226.76,3043.71c0-.41-.33-.75-.75-.75s-.75.33-.75.75.33.75.75.75.75-.33.75-.75Z"/>
        <path className="cls-41" d="m219.46,3043.71c0-.41-.33-.75-.75-.75s-.75.33-.75.75.33.75.75.75.75-.33.75-.75Z"/>
        <path className="cls-20" d="m224.22,3050.94c-.73,0-1.85.07-2.84-.15"/>
      </g>
      <path className="cls-43" d="m231.92,3043.97c-.39,1.47,0,2.85.87,3.08.87.23,1.9-.77,2.29-2.24.39-1.47,0-2.85-.87-3.08-.87-.23-1.9.77-2.29,2.24Z"/>
      <path className="cls-43" d="m213.77,3043.97c.39,1.47,0,2.85-.87,3.08-.87.23-1.9-.77-2.29-2.24-.39-1.47,0-2.85.87-3.08.87-.23,1.9.77,2.29,2.24Z"/>
      <path className="cls-41" d="m222.02,3028.71c-1.21-.54-2.58-.73-3.9-.54-1.74.26-3.32,1.17-4.74,2.21-2.02,1.49-3.91,3.49-4.19,5.98-.14,1.22.36,2.31.36,3.5,0,.31-.48,2.55-.94,2.45.78.16,1.64-.16,2.12-.8-.21.28,1.21,2.39,1.41,2.63.05.06.1.12.18.15.29.11.46-.32.48-.64.13-1.92.95-3.8,2.28-5.19,1.33-1.4,3.16-2.31,5.08-2.52-.49.23-.77.77-.81,1.3-.05.53.1,1.06.27,1.57.69.07,1.08-.73,1.56-1.22.78-.79,2.02-.84,3.08-1.15,1.12-.33,2.13-1.02,2.86-1.93.16.49,2.65,5.72,2.09,6.02.5-.27.85-.8.9-1.37,1.04,1.92,1.61,4.08,1.65,6.26,0,.14.04.32.18.34.62-1.1.88-2.64,1.72-3.57.21-.23.51-.39.82-.34,0-1.42-.05-2.84-.05-4.25,0-1.25-.02-2.55-.54-3.71-.55-1.24-1.65-2.05-2.77-2.75-.57-.36-1.09-.66-1.76-.79-.15-.03-1.94,0-1.87.12-1.09-1.97-3.45-3.16-5.68-2.85.59.42,1.05,1.01,1.31,1.69-.35-.24-.71-.44-1.1-.61Z"/>
    </g>
    <g id="Baby">
      <path className="cls-65" d="m182.11,3073.25l-1.71,2.98s-.82,1.68-1.08,2.74c-.31,1.29-.23,1.83-.23,1.83,0,0-1.44.64-2.18,1.28-.57.5-.97.87-1.18,1.08l.46,2.62c2.18-.03,5.9-.15,7.98-.18h.42v-11.8l-2.47-.57Z"/>
      <path className="cls-65" d="m192.26,3082.09c-.74-.64-2.18-1.28-2.18-1.28,0,0,.08-.54-.23-1.83-.26-1.06-.87-1.84-.87-1.84l-1.48-3.34-3.07.02v11.8h.58c1.85.02,5.01.12,7.22.16l1.08-2.75c-.23-.22-.57-.54-1.04-.94Z"/>
      <path className="cls-48" d="m182.36,3073.34l-.26,2.82s.53.56,2.2.51c1.49-.04,1.88-.48,1.88-.48v-2.73h1.18v2.84s1.1,1.36,1.57,2.38c.42.91.6,1.32.6,1.32,0,0,1.07.54,1.93,1.23.84.69,1.75,1.68,1.75,1.68,0,0-.65.55-.97,1.27-.31.72-.3,1.66-.3,1.66,0,0-1.93.14-3.26.06-1.33-.07-4.49,0-5.45-.07-.96-.07-3.27.05-4.54.07-1.2.02-2.34-.07-2.34-.07,0,0,.3-.73.09-1.59-.22-.91-.61-1.24-.61-1.24,0,0,.4-.85,1.26-1.54,1.11-.89,2.09-1.29,2.09-1.29-.03,0,.35-.47.71-1.51.52-1.52,1.11-2.46,1.11-2.46l.34-3.23,1.02.39Z"/>
      <path className="cls-61" d="m188.71,3073.76s1.55-.05,1.72,2.04c.11,1.5-.05,3.46-.59,5.2-.8,2.57-1.9,3.41-1.9,3.41,0,0-.08-.72-.83-.83-.75-.11-1.34,0-1.34,0,0,0,0-2.95.48-5.09.48-2.15.86-4.08,1.13-4.24.27-.16,1.34-.48,1.34-.48Z"/>
      <path className="cls-61" d="m179.6,3073.76s-1.55-.05-1.72,2.04c-.11,1.5.05,3.46.59,5.2.8,2.57,1.9,3.41,1.9,3.41,0,0,.08-.72.83-.83.75-.11,1.34,0,1.34,0,0,0,0-2.95-.48-5.09-.48-2.15-.86-4.08-1.13-4.24-.27-.16-1.34-.48-1.34-.48Z"/>
      <g>
        <path className="cls-86" d="m195.6,3063.16c.42,6.1-4.45,11.41-10.9,11.86-6.44.45-12.01-4.14-12.44-10.24-.42-6.1,4.45-11.41,10.9-11.86,6.44-.45,12.01,4.13,12.44,10.24Z"/>
        <path className="cls-86" d="m174.21,3069.35c-.22.8-1.04,1.28-1.85,1.06-.8-.22-1.28-1.04-1.06-1.85.22-.8,1.05-1.28,1.85-1.06.8.22,1.28,1.04,1.06,1.85Z"/>
        <path className="cls-86" d="m197.34,3066.93c-.22.8-1.05,1.28-1.85,1.06-.8-.22-1.28-1.05-1.06-1.85.22-.8,1.05-1.28,1.85-1.06.8.22,1.28,1.05,1.06,1.85Z"/>
        <g>
          <g>
            <path className="cls-41" d="m180.14,3064.66c-.04-.42-.42-.72-.83-.67-.42.04-.72.42-.67.83.04.41.41.72.83.67.42-.04.72-.42.67-.83Z"/>
            <path className="cls-41" d="m189.77,3063.66c-.04-.42-.41-.72-.83-.67-.42.04-.72.42-.67.83.04.41.42.72.83.67.42-.04.72-.42.67-.83Z"/>
          </g>
          <path className="cls-35" d="m184.52,3066.79c1.11,0,1.73.9,1.76.94l-.18.12s-.66-.95-1.77-.84c-1.11.12-1.49,1.16-1.49,1.17l-.21-.07s.43-1.19,1.68-1.32c.07,0,.15-.01.22-.01h0Z"/>
          <path className="cls-41" d="m183.95,3055.08c-.75,0-1.4-.35-1.77-.97-.13-.22-.21-.45-.24-.68-.5-.34-.82-.93-.63-1.66.2-.79.71-1.05.73-1.06l.15.3s-.4.22-.56.85c-.12.48.03.88.31,1.16.03-.18.09-.35.19-.5.4-.63,1.14-.79,1.59-.58.27.13.5.44.34.94-.26.8-1,.98-1.63.8-.04-.01-.08-.02-.12-.04.03.11.08.21.14.31.33.56.95.86,1.65.8,1.31-.1,1.81-1.23,1.83-1.27l.31.13c-.02.06-.59,1.36-2.11,1.47-.06,0-.13,0-.19,0h0Zm-1.69-1.84c.09.05.18.08.27.11.44.13,1.02.05,1.22-.58.06-.18.08-.42-.17-.54-.32-.15-.87,0-1.17.46-.1.16-.15.35-.15.55h0Z"/>
        </g>
        <circle className="cls-86" cx="177.82" cy="3068.47" r="1.14"/>
        <circle className="cls-86" cx="191.03" cy="3067.14" r="1.14"/>
      </g>
      <path className="cls-86" d="m182.27,3086.75c-.27.05-.56-.04-.79-.2-1.04-.76-1.67-2.33-.73-3.42.03-.04.07-.07.11-.08.04,0,.07,0,.11,0,.37.07.74.13,1.12.2.14.03.29.05.42.12.79.39.72,2.74.09,3.25-.1.08-.21.12-.33.14Z"/>
      <path className="cls-86" d="m187.29,3086.69c-.26.05-.56-.04-.79-.2-1.04-.76-1.67-2.33-.73-3.42.03-.04.07-.07.11-.08.04,0,.07,0,.11,0,.37.07.74.13,1.12.2.14.03.29.05.42.12.79.39.72,2.74.09,3.25-.1.08-.21.12-.33.14Z"/>
      <path className="cls-86" d="m175.97,3086.19c-.4.07-.84-.06-1.18-.3-1.56-1.14-2.5-3.49-1.1-5.12.05-.05.1-.11.17-.13.05-.01.11,0,.16,0,.56.1,1.11.2,1.67.3.21.04.43.08.63.17,1.18.58,1.07,4.11.13,4.86-.14.11-.31.18-.49.21Z"/>
      <path className="cls-86" d="m191.31,3086c-.36-.19-.63-.57-.74-.96-.53-1.86.19-4.29,2.29-4.71.07-.01.14-.03.21,0,.05.02.09.06.12.1.38.42.75.84,1.13,1.27.15.16.29.33.39.52.58,1.18-1.69,3.89-2.89,3.91-.18,0-.36-.05-.52-.13Z"/>
    </g>
    <g id="Titel-4" data-name="Titel">
      <rect id="Achtergrond-5" data-name="Achtergrond" className="cls-57" x="170.55" y="2819.08" width="140.86" height="33" rx="2.5" ry="2.5"/>
      <text className="cls-55" transform="translate(181.2 2841.97)"><tspan x="0" y="0">Gemeenten</tspan></text>
    </g>
    </g>
    
    </> 
)
}