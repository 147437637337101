import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

export default function Jeugdgezondheidszorg(props) {
  const isDesctop = props.isDesctop;
  const desctopScreen = useRef();
  const mobileScreen = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {

      gsap.timeline({repeat:-1})
      .to('#Geldzak', {scale: 1.2, transformOrigin: '50% 50%', rotation:"+=7", duration: 2.3})
      .to('#Geldzak', {scale: 1, transformOrigin: '50% 50%',rotation:"-=7", duration: 2.3})
     

      const numbers = self.selector('.number');
      numbers.forEach((number) => {
        gsap.from(number, {
          textContent: 0,
          duration: 2,
          snap: { textContent: 1 },
          scrollTrigger: {
            trigger: number,
          },
        });
      });
    }, desctopScreen);
    return () => ctx.revert(); // <- Cleanup!     
  }, [])

  useLayoutEffect(() => {
    const ctxMobile = gsap.context((self) => {
      gsap.timeline({repeat:-1})
        .to('#Geldzak', {scale: 1.2, transformOrigin: '50% 50%', rotation:"+=7", duration: 2.3})
        .to('#Geldzak', {scale: 1, transformOrigin: '50% 50%',rotation:"-=7", duration: 2.3})
     
        
      const numbers = self.selector('.number');
      numbers.forEach((number) => {
        gsap.from(number, {
          textContent: 0,
          duration: 2,
          snap: { textContent: 1 },
          scrollTrigger: {
            trigger: number,
          },
        });
      });
    }, mobileScreen);
    return () => ctxMobile.revert(); // <- Cleanup!     
  }, [])

  if (isDesctop) {
    return (
        <>
        <g ref={desctopScreen} id="Landelijke_Jeugdgezondheidszorg" data-name="Landelijke  Jeugdgezondheidszorg">
      <path id="Ondergrond-2" data-name="Ondergrond" className="cls-84" d="m875.57,3447.06c7.58.45,31.65-43.24,32.54-48.59.89-5.35,5.79-37-23.18-43.68-18.32-4.23-13.3-13.26-18.96-23.85-5.85.61-11.69,1.26-17.44,2.5-9.69,4.84-19.27,10.05-29.51,13.1-9.94,2.96-20.43,3.89-30.6,5.78-9.76,1.82-20.48,3.99-28.83,9.63-14.2,9.59-19.65,29.34-18,45.66.75,7.39,3.78,12.28,9.58,16.81,3.47,2.71,6.91,5.01,9.05,9.01,2.02,3.79,2.75,8.14,2.52,12.4-.53,10.09-6.27,16.43-14.18,22.03-4.56,3.22-9.6,5.72-14.17,8.93-4.01,2.83-5.66,7.75-9.44,10.87-2.18,1.79-5.5,1.79-7.04-.91-2.99-5.22-.08-11.21-.8-17.25-.71-5.99,1.22-11.21,3.08-16.79,1.47-4.4-2.55-8.57-3.05-13.28-.49-4.61-3.13-9.54-6.6-12.58-5.8-5.08-13.99-7.6-15.39-16.18-1.28-7.81.78-15.71,1.81-23.43,2.3-17.21,11.98-30.98,27.92-38.25,20.62-9.4,44.49-9.18,66.57-11.93,19.17-2.39,36.58-9.94,51.94-21.31.11-.73.19-1.45.24-2.17-1.58-.69-3.25-1.37-5.05-2.06,0,0-41.46,23.63-59.29,20.5-17.83-3.12-87.81,1.34-95.84,41.9-8.03,40.56-9.81,102.97-24.52,128.82-14.71,25.85-48.59,46.8-46.36,54.38,2.23,7.58,50.37,34.32,50.37,34.32l-13.82,3.12s-37-30.4-44.58-26.79c-7.58,3.61,27.19,26.79,27.19,26.79l-8.91,2.23s-34.77-21.4-41.01-13.82c-6.24,7.58,32.54,21.4,32.54,21.4l-9.81,7.26s-28.53-13.05-36.55-6.81c-8.02,6.24-5.35,17.56-5.35,17.56l39.23,6.95-5.35,10.21s-28.53-16.07-36.55-6.23c-8.02,9.84-6.24,21.43-6.24,21.43,0,0,32.99,8.92,49.48,2.68,16.49-6.24,33.43-20.06,57.06-26.3,23.62-6.24,38.33-4.01,56.61,5.8,18.28,9.81,61.96,32.99,61.96,32.99,0,0-18.72,34.77-13.82,46.36,4.9,11.59,19.17,22.73,28.53,8.92,9.36-13.82,11.59-18.72,7.13-28.08-4.46-9.36,1.78-20.95,1.78-20.95l20.5-34.86s-37-62.85-24.96-73.5c12.04-10.65,23.63-5.75,36.55-3.52,12.93,2.23,39.67-11.14,41.01-17.38,1.34-6.24-14.71-16.94-14.71-16.94,0,0,24.07-6.58,28.53-22.24,4.46-15.65.44-25.91-2.23-25.91s-14.71,4.46-28.53-.89c-13.82-5.35,9.36-34.32,16.94-33.88Z"/>
      <text transform="translate(771.54 3388.28)"><tspan className="cls-24"><tspan x="0" y="0">16 maart</tspan></tspan><tspan className="cls-33"><tspan x="-20.3" y="18">werkbezoek minister </tspan></tspan><tspan className="cls-33"><tspan x="3.95" y="36">Ernst </tspan><tspan className="cls-143" x="43.65" y="36">K</tspan><tspan x="52.17" y="36">uipers</tspan></tspan></text>
      <text transform="translate(486.14 3688.97)"><tspan className="cls-24"><tspan x="0" y="0">-9 maanden tot 23 jaar </tspan></tspan><tspan className="cls-33"><tspan x="-12.7" y="18">Landelijk Professioneel Kader vernieuwd</tspan></tspan></text>
      <g id="Geldzak">
        <g id="Geldzak-2" data-name="Geldzak">
          <path className="cls-23" d="m741.7,3585.78c0,18.3-14.83,33.13-33.13,33.13s-33.13-14.83-33.13-33.13,14.83-33.13,33.13-33.13c10.29,0,16.81,5.62,22.88,12.98,4.73,5.73,10.25,5.37,10.25,20.15Z"/>
          <path className="cls-23" d="m678.96,3570.77l21.54-41.47c10.42,5.63,9.52-3.53,15.69,7.2,6.17,10.73,6.62,12.39,23.06,37.16,7.06,10.63-29.23,15.42-29.23,15.42l-31.06-18.31Z"/>
          <path className="cls-23" d="m701.44,3526.13s-8.08-9.85-4.53-7.88c3.55,1.97,4.46-6.63,6.7-2.36,2.24,4.26,5-5.95,4.96-1.2s7.27-1.16,5.59,2.78c-1.69,3.94-3.85,8.67-3.85,8.67l-3.07,2.56h-5.01l-.79-2.56Z"/>
          <path className="cls-56" d="m700.37,3529.76l1.07-3.63s3.94,2.56,8.87,0l3.85,7.28s-14.87-.02-13.79-3.65Z"/>
        </g>
        <text className="cls-32" transform="translate(696.61 3593.72)"><tspan x="0" y="0">€</tspan></text>
      </g>
      <g id="Ernst_Kuipers" data-name="Ernst Kuipers">
        <rect className="cls-76" x="776.49" y="3610.21" width="7.91" height="10.91" transform="translate(60.3 -12.49) rotate(.95)"/>
        <rect className="cls-23" x="776.31" y="3620.33" width="7.91" height="11.7" transform="translate(60.47 -12.49) rotate(.95)"/>
        <rect className="cls-76" x="798.01" y="3610.38" width="7.91" height="10.91" transform="translate(-235.99 60.55) rotate(-3.77)"/>
        <rect className="cls-23" x="798.7" y="3620.48" width="7.91" height="11.7" transform="translate(-236.68 60.62) rotate(-3.77)"/>
        <path className="cls-108" d="m784.45,3626.41l.63,8.71-7.79,2.21s-.72.59-3.7,1.73c-4.18,1.6-16.46,2.27-16.97,1.82-.52-.45-.74-2.41-.43-2.96,1.08-1.87,4.08-2.23,5.85-3.2,1.76-.97,14.13-7.66,14.13-7.66l.89,3.77"/>
        <path className="cls-76" d="m777.08,3450.75c-.61,10.94,5.74,20.18,14.18,20.65,8.44.47,19.44-12.05,17.39-21.36-2.35-10.7-2.12-12.31-15.52-14.23-10.45-1.5-15.44,4.01-16.05,14.95Z"/>
        <path className="cls-76" d="m788.79,3471.16s-5.95-.62-9.59-2.26c-3.63-1.64-.06-.09-1.37-10.16l-3.15-.44c-.93-.09-1.49-1.07-1.11-1.92l3.96-9.63,11.49,3.86-.23,20.55Z"/>
        <path className="cls-76" d="m731.51,3501.33c1.5-.17,2.85.89,3.92,1.97,2.1,2.12,3.88,4.56,5.24,7.21.07.15.15.3.14.47-.01.25-.2.44-.39.6-1.33,1.14-3.06,1.82-4.81,1.81-1.75-.01-3.5-.74-4.68-2.02-.72-.78-1.23-1.75-1.64-2.74-.56-1.37-.96-2.87-.67-4.32.29-1.45,1.42-2.82,2.89-2.98Z"/>
        <polygon className="cls-38" points="741.32 3506.44 733.28 3515.21 737.78 3518.13 746.07 3509.02 741.32 3506.44"/>
        <path className="cls-108" d="m772.59,3539.9v-15.97l36.56-1.72v14.25l-2.07,81.25s-1.69,1.21-4.48,1.45c-2.75.23-5.25-1.45-5.25-1.45l-5.66-64.26-2.87,4.33-4.16,59.93s-1.66,1.47-4.83,1.47c-2.75,0-4.04-1.47-4.04-1.47l-3.19-77.81Z"/>
        <path className="cls-114" d="m788.47,3533.03c-.03.78.75,12.23-3.67,13.77-4.41,1.54-14.79-3.13-14.79-3.13l2.08-17.64"/>
        <path className="cls-114" d="m811.01,3529.66l2.07,15.05s-9.27,3.37-15.01,1.56c-5.75-1.82-7.2-13.49-7.2-13.49l20.14-3.12Z"/>
        <polygon className="cls-76" points="785.98 3469.44 791.62 3467.04 797.52 3469.44 801.41 3486.81 780.94 3486.81 785.98 3469.44"/>
        <polygon className="cls-23" points="791.41 3477.65 798.61 3471.41 800.2 3474.4 821.26 3484.29 823.47 3530.69 804.92 3536.91 800.87 3524.02 812.83 3519.54 811.25 3506.88 811.61 3532.49 788.47 3533.03 772.09 3526.02 770.01 3505.03 761.42 3530.24 734.16 3516.41 742.89 3506.99 754.93 3513.88 765.96 3481.43 782.84 3474.98 784.46 3472.01 791.41 3477.65"/>
        <path className="cls-38" d="m798.83,3478.69s1.13,28.3-.77,31.93c-1.9,3.63-5.54,22.41-5.54,22.41h-4.04s-3.53-22.64-4.27-26.47c-1.21-6.23.52-28.42.52-28.42l6.69-1.42,7.42,1.97Z"/>
        <polygon className="cls-38" points="798.61 3471.41 799.92 3480.5 791.41 3477.65 783.91 3479.47 784.46 3472.01 791.41 3476.72 798.61 3471.41"/>
        <path className="cls-108" d="m806.73,3628.03l-2.08,2.71s-3.12-.14-4.16-1.75c-1.04-1.61-1.89-3.96-1.89-3.96,0,0-.76,1.22-.19,3.87.57,2.65,1.14,5.95,1.7,9.83.57,3.88,6.65,15.92,8.83,13.9,3.55-3.28,1.85-10.8,1.85-10.8l-4.06-13.81Z"/>
        <path className="cls-76" d="m790.04,3531.92c.37-1.47,1.83-2.35,3.21-2.98,2.72-1.23,4.9-1.18,7.87-1.52.16-.02.34-.04.49.03.23.1.34.35.43.58.6,1.64,1.36,2.65.73,4.28-.62,1.63-1.92,3.02-3.53,3.68-.99.4-2.07.54-3.14.57-1.48.05-3.03-.11-4.29-.89-1.26-.78-2.14-2.32-1.77-3.75Z"/>
        <polygon className="cls-38" points="799.6 3524.42 803.72 3537.21 805.27 3536.57 800.87 3524.02 799.6 3524.42"/>
      </g>


      <text id="" data-name="" transform="translate(394.75 3439.23)">
        <tspan className="cls-29">
          <tspan className="cls-129" x="0" y="0">€</tspan> <tspan className="number cls-129">300</tspan>
          <tspan className="cls-115" x="136.92" y="0" > miljoen</tspan>
          <tspan className="cls-129" x="226.32" y="0"> </tspan>
        </tspan>
        <tspan className="cls-33">
            <tspan x="35.53" y="18">extra investeren in JGZ </tspan>
        </tspan>
        <tspan className="cls-33">
          <tspan x="11.44" y="36">(</tspan>
          <tspan className="cls-127" x="16.37" y="36">T</tspan>
          <tspan x="24.46" y="36">oekomstagenda voor Jeugd)</tspan>
        </tspan>
      </text>

      <g id="Titel-3" data-name="Titel">
        <rect id="Achtergrond-4" data-name="Achtergrond" className="cls-38" x="196.43" y="3330.79" width="452.41" height="55.53" rx="4.21" ry="4.21"/>
        <text className="cls-51" transform="translate(225.02 3369.83)"><tspan x="0" y="0">Jeugdgezondheidszorg</tspan></text>
      </g>
        </g>
        </>
    )
  }
  return (
    <>
    <g ref={mobileScreen} id="Landelijke_Jeugdgezondheidszorg" data-name="Landelijke  Jeugdgezondheidszorg">
    <path id="Ondergrond-2" data-name="Ondergrond" className="cls-79" d="m323.58,3416.09c4.32.25,18.03-24.64,18.54-27.69.51-3.05,3.3-21.08-13.21-24.89-10.44-2.41-7.58-7.56-10.8-13.59-3.33.35-6.66.72-9.94,1.43-5.52,2.76-10.98,5.73-16.82,7.46-5.66,1.69-11.64,2.22-17.44,3.3-5.56,1.04-11.67,2.27-16.43,5.49-8.09,5.46-11.2,16.72-10.26,26.02.43,4.21,2.16,7,5.46,9.58,1.98,1.55,3.94,2.85,5.15,5.14,1.15,2.16,1.57,4.64,1.44,7.07-.3,5.75-3.57,9.36-8.08,12.55-2.6,1.84-5.47,3.26-8.07,5.09-2.29,1.61-3.23,4.42-5.38,6.19-1.24,1.02-3.13,1.02-4.01-.52-1.7-2.97-.05-6.39-.46-9.83-.41-3.42.69-6.39,1.75-9.57.84-2.51-1.45-4.88-1.74-7.57-.28-2.63-1.78-5.43-3.76-7.17-3.31-2.9-7.97-4.33-8.77-9.22-.73-4.45.45-8.95,1.03-13.35,1.31-9.81,6.82-17.65,15.91-21.79,11.75-5.35,25.35-5.23,37.94-6.8,10.92-1.36,20.84-5.66,29.6-12.14.06-.41.11-.83.14-1.23-.9-.39-1.85-.78-2.88-1.17,0,0-23.62,13.46-33.78,11.68-10.16-1.78-50.04.76-54.61,23.88-4.57,23.11-5.59,58.67-13.97,73.41-8.38,14.73-27.69,26.67-26.42,30.99,1.27,4.32,28.7,19.56,28.7,19.56l-7.87,1.78s-21.08-17.32-25.4-15.26c-4.32,2.06,15.49,15.26,15.49,15.26l-5.08,1.27s-19.81-12.19-23.37-7.87c-3.56,4.32,18.54,12.19,18.54,12.19l-5.59,4.13s-16.26-7.44-20.83-3.88c-4.57,3.56-3.05,10.01-3.05,10.01l22.35,3.96-3.05,5.82s-16.26-9.16-20.83-3.55c-4.57,5.61-3.56,12.21-3.56,12.21,0,0,18.8,5.08,28.19,1.52,9.4-3.56,19.05-11.43,32.51-14.99,13.46-3.56,21.84-2.29,32.26,3.3,10.41,5.59,35.31,18.8,35.31,18.8,0,0-10.67,19.81-7.87,26.42,2.79,6.6,10.92,12.95,16.26,5.08,5.33-7.87,6.6-10.67,4.06-16-2.54-5.33,1.02-11.94,1.02-11.94l11.68-19.86s-21.08-35.81-14.22-41.88c6.86-6.07,13.46-3.28,20.83-2.01,7.37,1.27,22.61-6.35,23.37-9.91.76-3.56-8.38-9.65-8.38-9.65,0,0,13.72-3.75,16.26-12.67,2.54-8.92.25-14.76-1.27-14.76s-8.38,2.54-16.26-.51c-7.87-3.05,5.34-19.56,9.65-19.3Z"/>
    <text transform="translate(134.27 3556.59)"><tspan className="cls-5"><tspan x="0" y="0">16 maart</tspan></tspan><tspan className="cls-115"><tspan x="-20.23" y="16">werkbezoek minister </tspan></tspan><tspan className="cls-115"><tspan x=".56" y="32">Ernst </tspan><tspan className="cls-130" x="34.59" y="32">K</tspan><tspan x="41.88" y="32">uipers</tspan></tspan></text>
    <text transform="translate(35.28 3425.66)"><tspan className="cls-5"><tspan x="0" y="0">-9 maanden </tspan></tspan><tspan className="cls-5"><tspan x="8.85" y="19.2">tot 23 jaar </tspan></tspan><tspan className="cls-115"><tspan x="26.4" y="35.2">Landelijk </tspan></tspan><tspan className="cls-115"><tspan x="-4.33" y="51.2">Professioneel Kader </tspan></tspan><tspan className="cls-115"><tspan x="22.7" y="67.2">vernieuwd</tspan></tspan></text>
    <text transform="translate(102.32 3336.26)"><tspan x="0" y="0" className="cls-4 number">300</tspan></text>
    <g id="_14_gemeenten_in_Raad_voor_het_Publiek_Belang-2" data-name="14 gemeenten in Raad voor het Publiek Belang">
      <path className="cls-41" d="m64.81,3319.78v-3.6h3.12c0-6.4,5.48-10.32,11.88-10.32h.96c2.16,0,4.84.64,5.92,1.24v5.8h-.32c-1.4-.64-3.64-1.08-5.6-1.08h-.96c-2.96,0-4.68,1.4-4.68,4.36h9.08v3.6h-9.08v2.8h9.08v3.6h-9.08c0,3.12,2.04,4.36,4.68,4.36h1.28c2.24,0,4.24-.72,5.28-1.24h.32v5.8c-.92.44-3.12,1.4-5.6,1.4h-1.28c-6.08,0-11.88-3.12-11.88-10.32h-3.12v-3.6h3.12v-2.8h-3.12Z"/>
      
      <path className="cls-41" d="m102.01,3354.95c-.83.27-2.26.43-3.19.43h-.75v-5.38c0-.69-.13-1.12-.75-1.12-.43,0-1.01.35-1.57.75l-.43.3v5.44h-2.75v-5.38c0-.69-.13-1.12-.75-1.12-.43,0-1.01.35-1.55.75l-.45.3v5.44h-2.75v-6.69c-.45.06-.87.16-1.19.26v-1.89c.83-.27,2.26-.43,3.19-.43h.75v1.33l.32-.26c.95-.78,1.68-1.2,2.75-1.2,1.15,0,1.92.56,2.24,1.5h.13l.38-.3c.95-.78,1.68-1.2,2.74-1.2,1.63,0,2.45,1.07,2.45,2.75v4.07c.43-.06.85-.14,1.18-.26v1.89Z"/>
      <path className="cls-41" d="m108.24,3354.95c-.82.27-2.24.43-3.19.43h-.75v-6.69c-.45.06-.87.16-1.19.26v-1.89c.83-.27,2.26-.43,3.19-.43h.75v6.69c.45-.06.87-.14,1.19-.26v1.89Zm-3.94-11.57h2.75v2.26h-2.75v-2.26Z"/>
      <path className="cls-41" d="m109.13,3343.8c.82-.27,2.24-.43,3.19-.43h.75v9.94c.43-.06.85-.14,1.19-.26v1.89c-.83.27-2.26.43-3.19.43h-.75v-9.94c-.46.06-.88.16-1.19.26v-1.89Z"/>
      <path className="cls-41" d="m115.25,3347.05c.83-.27,2.26-.43,3.2-.43h.74v8.26c0,2.29-1.54,3.51-3.38,3.51-.4,0-.83-.06-1.25-.19v-2.19c.29.11.54.13.75.13.72,0,1.14-.56,1.14-1.5v-5.94c-.46.06-.88.16-1.2.26v-1.89Zm1.2-3.68h2.74v2.26h-2.74v-2.26Z"/>
      <path className="cls-41" d="m121.07,3351.01c0-3.14,1.63-4.51,4.5-4.51,3.01,0,4.51,1.38,4.51,4.51,0,3.36-1.5,4.5-4.51,4.5s-4.5-1.14-4.5-4.5Zm6.26,0c0-1.57-.51-2.13-1.76-2.13s-1.74.56-1.74,2.13.5,2.11,1.74,2.11,1.76-.56,1.76-2.11Z"/>
      <path className="cls-41" d="m131.33,3351.94v-1.68c0-2.51,1.94-3.76,4.43-3.76,2.19,0,3.94.69,3.94,2.88,0,1.87-1.36,2.56-3.25,2.56h-2.37v.06c0,.94.94,1.12,1.87,1.12s1.94-.11,2.88-.37h.13v2.26c-1.01.37-2.47.5-3.27.5-2.58,0-4.37-1.01-4.37-3.57Zm2.75-1.62h2.19c.56,0,.93-.19.93-.75,0-.75-.69-.82-1.44-.82-1.05,0-1.68.37-1.68,1.44v.13Z"/>
      <path className="cls-41" d="m152.02,3354.95c-.83.27-2.26.43-3.19.43h-.75v-5.38c0-.69-.13-1.12-.82-1.12-.5,0-1.12.38-1.7.75l-.5.3v5.44h-2.75v-6.69c-.45.06-.87.16-1.19.26v-1.89c.83-.27,2.26-.43,3.19-.43h.75v1.33l.38-.26c.99-.72,1.74-1.2,2.87-1.2,1.7,0,2.51,1.07,2.51,2.75v4.07c.43-.06.85-.14,1.19-.26v1.89Z"/>
      <path d="m29.11,3365.01c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path d="m41.02,3365.01c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path d="m47.53,3371.19c-.35.1-1.08.19-1.83.19h-.13v-8.02c-.38.02-.76.06-.94.11v-.89c.35-.1,1.07-.19,1.82-.19h.14v8.04c.37-.02.73-.07.94-.12v.89Z"/>
      <path d="m48.56,3365.01c.35-.1,1.08-.19,1.82-.19h.14v6.56c0,1.69-.89,2.25-2.21,2.25-.32,0-.65-.05-.89-.14v-.89h.05c.19.04.42.08.66.08,1.08,0,1.36-.32,1.36-1.5v-5.4c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path d="m52.32,3368.01c0-1.97.94-3.29,2.86-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.02,0-2.86-1.32-2.86-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path d="m59.4,3368.85v-1.31c0-1.79,1.21-2.82,2.8-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.8-.76-2.8-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m66.52,3365.79c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m76.88,3368.85v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.78,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.6,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.78.56-1.78,1.88v.08Z"/>
      <path d="m82.82,3371.28l2.17-3.18-2.07-3.19v-.1h1.13l1.56,2.39,1.63-2.39h1.03v.1l-2.12,3.12,2.12,3.25v.1h-1.13l-1.61-2.46-1.68,2.46h-1.03v-.1Z"/>
      <path d="m89.95,3369.22v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path d="m93.94,3365.01c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path d="m99.05,3369.22v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.44.94-2.44,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path d="m109.4,3365.01c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path d="m114.28,3365.79c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m121.4,3366.51v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path d="m127.73,3368.85v-1.31c0-1.79,1.21-2.82,2.8-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.8-.76-2.8-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m134.28,3371.23v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.18.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.62,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path d="m140.99,3369.22v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path d="m145.16,3368.85v-1.31c0-1.79,1.21-2.82,2.8-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.8-.76-2.8-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m151.34,3365.01c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path d="m156.45,3368.85v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.78,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.6,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.78.56-1.78,1.88v.08Z"/>
      <path d="m163.57,3365.79c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m173.75,3365.01c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path d="m178.62,3365.79c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m188.84,3370.39h1.13c.94,0,1.5-.61,1.5-1.64v-5.38h-2.25v-.98h3.37v6.37c0,1.6-1.03,2.63-2.61,2.63h-1.13v-.98Z"/>
      <path d="m194.46,3367.44v-1.22c0-2.61,1.84-3.93,4.32-3.93.94,0,1.97.24,2.34.42v.94h-.1c-.43-.13-1.38-.37-2.24-.37-1.79,0-3.19.76-3.19,2.95v1.22c0,1.97,1.13,3.05,3,3.05h.37c.42,0,.82-.06,1.13-.14v-2.39h-1.87v-.98h2.9v4.03c-.32.18-1.42.47-2.16.47h-.37c-2.71,0-4.13-1.69-4.13-4.03Z"/>
      <path d="m202.62,3370.86c1.31-1.69,3.84-5.43,4.64-7.5h-4.46v-.98h5.91v.37c-.8,2.06-3.24,5.95-4.5,7.64h4.58v.98h-6.18v-.52Z"/>
      <path d="m32.3,3383.81v-.37c0-2.22.79-4.08,2.16-5.06h.74v.19c-1.5,1.31-1.87,3.08-1.87,4.87v.37c0,1.79.37,3.56,1.87,4.87v.19h-.74c-1.37-.98-2.16-2.84-2.16-5.06Z"/>
      <path d="m38.96,3379.37h-2.81v-.98h6.74v.98h-2.81v8.01h-1.13v-8.01Z"/>
      <path d="m43.56,3384.01c0-1.97.94-3.29,2.86-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.02,0-2.86-1.32-2.86-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path d="m50.64,3384.85v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.78,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.6,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.78.56-1.78,1.88v.08Z"/>
      <path d="m56.49,3378.57c.35-.1,1.07-.19,1.82-.19h.14v4.97h.65l2.16-2.53h1.18v.1l-2.48,2.86,2.58,3.51v.1h-1.18l-2.25-3.09h-.65v3.09h-1.03v-8.02c-.38.02-.76.06-.94.11v-.89Z"/>
      <path d="m63.43,3384.01c0-1.97.94-3.29,2.86-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.02,0-2.86-1.32-2.86-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path d="m70.32,3381.01c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path d="m82.59,3387.23v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.59-.29,1.59-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.18.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.62,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path d="m89.3,3385.22v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path d="m93.48,3385.22v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.44.94-2.44,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path d="m101.35,3388.5h.08c.29.1.85.18,1.13.18,1.69,0,2.44-.74,2.44-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path d="m107.91,3384.85v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.78,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.6,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.78.56-1.78,1.88v.08Z"/>
      <path d="m115.03,3381.79c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m125.43,3378.57c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.44.94-2.44,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path d="m129.56,3385.22v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.44.94-2.44,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path d="m140.06,3382.51v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.31-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path d="m146.34,3384.01c0-1.97.94-3.29,2.86-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.02,0-2.86-1.32-2.86-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path d="m153.37,3384.01c0-1.97.94-3.29,2.86-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.02,0-2.86-1.32-2.86-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path d="m160.26,3381.01c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path d="m168.42,3386.39h1.13c.94,0,1.5-.61,1.5-1.64v-5.38h-2.25v-.98h3.37v6.37c0,1.6-1.03,2.63-2.61,2.63h-1.13v-.98Z"/>
      <path d="m174,3384.85v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.78,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.6,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.78.56-1.78,1.88v.08Z"/>
      <path d="m180.13,3381.01c.35-.1,1.08-.19,1.82-.19h.14v4.4c0,1.03.42,1.32,1.03,1.32.66,0,1.31-.66,1.69-1.03l.32-.34v-4.35h1.03v5.6c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.16l-.42.41c-.5.47-1.02.85-1.87.85-1.03,0-1.79-.56-1.79-2.25v-3.43c-.38.02-.74.06-.94.11v-.89Z"/>
      <path d="m188.85,3388.5h.08c.29.1.85.18,1.13.18,1.69,0,2.44-.74,2.44-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path d="m198.64,3378.57c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.44.94-2.44,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path d="m202.81,3388.68c1.5-1.31,1.88-3.08,1.88-4.87v-.37c0-1.79-.38-3.56-1.88-4.87v-.19h.76c1.36.98,2.16,2.84,2.16,5.06v.37c0,2.22-.8,4.08-2.16,5.06h-.76v-.19Z"/>
    </g>
    <g id="Geldzak">
      <g id="Geldzak-2" data-name="Geldzak">
        <path className="cls-41" d="m236.56,3469.99c0,11.31-9.17,20.48-20.48,20.48s-20.48-9.17-20.48-20.48,9.17-20.48,20.48-20.48c6.36,0,10.39,3.47,14.14,8.02,2.92,3.54,6.33,3.32,6.33,12.46Z"/>
        <path className="cls-41" d="m197.78,3460.71l13.31-25.63c6.44,3.48,5.89-2.18,9.7,4.45,3.81,6.63,4.09,7.66,14.25,22.97,4.36,6.57-18.07,9.53-18.07,9.53l-19.2-11.32Z"/>
        <path className="cls-41" d="m211.67,3433.12s-4.99-6.09-2.8-4.87c2.19,1.22,2.76-4.1,4.14-1.46,1.39,2.64,3.09-3.68,3.07-.74-.02,2.94,4.5-.72,3.45,1.72s-2.38,5.36-2.38,5.36l-1.9,1.58h-3.1l-.49-1.58Z"/>
        <path className="cls-11" d="m211.01,3435.37l.66-2.24s2.44,1.58,5.48,0l2.38,4.5s-9.19-.01-8.53-2.26Z"/>
      </g>
      <text className="cls-1" transform="translate(208.69 3474.9)"><tspan x="0" y="0">€</tspan></text>
    </g>
    <g id="Ernst_Kuipers" data-name="Ernst Kuipers">
      <rect className="cls-71" x="258.06" y="3485.09" width="4.89" height="6.74" transform="translate(58.11 -3.85) rotate(.95)"/>
      <rect className="cls-41" x="257.95" y="3491.35" width="4.89" height="7.23" transform="translate(58.22 -3.85) rotate(.95)"/>
      <rect className="cls-71" x="271.36" y="3485.2" width="4.89" height="6.74" transform="translate(-228.77 25.55) rotate(-3.77)"/>
      <rect className="cls-41" x="271.79" y="3491.44" width="4.89" height="7.23" transform="translate(-229.19 25.59) rotate(-3.77)"/>
      <path className="cls-102" d="m262.98,3495.11l.39,5.39-4.81,1.37s-.45.37-2.28,1.07c-2.58.99-10.17,1.4-10.49,1.13-.32-.28-.46-1.49-.26-1.83.67-1.16,2.52-1.38,3.62-1.98,1.09-.6,8.74-4.74,8.74-4.74l.55,2.33"/>
      <path className="cls-71" d="m258.43,3386.53c-.38,6.76,3.55,12.48,8.76,12.77,5.22.29,12.02-7.45,10.75-13.21-1.45-6.61-1.31-7.61-9.6-8.8-6.46-.93-9.54,2.48-9.92,9.24Z"/>
      <path className="cls-71" d="m265.66,3399.15s-3.68-.38-5.93-1.4c-2.24-1.01-.04-.06-.84-6.28l-1.95-.27c-.58-.06-.92-.66-.68-1.19l2.45-5.95,7.1,2.39-.14,12.7Z"/>
      <path className="cls-71" d="m230.26,3417.79c.93-.1,1.76.55,2.42,1.21,1.3,1.31,2.4,2.82,3.24,4.46.05.09.09.19.09.29,0,.15-.13.27-.24.37-.82.7-1.89,1.12-2.97,1.12-1.08,0-2.16-.45-2.9-1.25-.45-.49-.76-1.08-1.01-1.69-.35-.85-.59-1.78-.42-2.67.18-.9.88-1.74,1.79-1.84Z"/>
      <polygon className="cls-57" points="236.33 3420.95 231.36 3426.37 234.14 3428.18 239.26 3422.55 236.33 3420.95"/>
      <path className="cls-102" d="m255.65,3441.63v-9.87l22.6-1.06v8.81l-1.28,50.22s-1.04.75-2.77.9-3.24-.9-3.24-.9l-3.5-39.72-1.78,2.68-2.57,37.04s-1.03.91-2.99.91c-1.7,0-2.5-.91-2.5-.91l-1.97-48.1Z"/>
      <path className="cls-108" d="m265.47,3437.39c-.02.48.46,7.56-2.27,8.51-2.73.95-9.14-1.94-9.14-1.94l1.28-10.91"/>
      <path className="cls-108" d="m279.4,3435.3l1.28,9.3s-5.73,2.08-9.28.96c-3.55-1.12-4.45-8.34-4.45-8.34l12.45-1.93Z"/>
      <polygon className="cls-71" points="263.93 3398.08 267.41 3396.6 271.06 3398.08 273.47 3408.82 260.82 3408.82 263.93 3398.08"/>
      <polygon className="cls-41" points="267.28 3403.16 271.74 3399.3 272.72 3401.15 285.74 3407.26 287.1 3435.94 275.63 3439.78 273.13 3431.82 280.52 3429.05 279.55 3421.22 279.77 3437.05 265.47 3437.39 255.34 3433.06 254.06 3420.08 248.75 3435.66 231.9 3427.11 237.29 3421.29 244.74 3425.55 251.56 3405.49 261.99 3401.51 262.99 3399.67 267.28 3403.16"/>
      <path className="cls-57" d="m271.87,3403.8s.7,17.49-.48,19.74c-1.18,2.25-3.43,13.85-3.43,13.85h-2.5s-2.18-13.99-2.64-16.36c-.75-3.85.32-17.57.32-17.57l4.13-.88,4.59,1.22Z"/>
      <polygon className="cls-57" points="271.74 3399.3 272.54 3404.92 267.28 3403.16 262.65 3404.28 262.99 3399.67 267.28 3402.58 271.74 3399.3"/>
      <path className="cls-102" d="m276.76,3496.11l-1.29,1.68s-1.93-.09-2.57-1.08c-.64-.99-1.17-2.45-1.17-2.45,0,0-.47.75-.12,2.39.35,1.64.7,3.68,1.05,6.08.35,2.4,4.11,9.84,5.46,8.59,2.2-2.03,1.14-6.67,1.14-6.67l-2.51-8.53Z"/>
      <path className="cls-71" d="m266.44,3436.7c.23-.91,1.13-1.45,1.99-1.84,1.68-.76,3.03-.73,4.86-.94.1-.01.21-.02.3.02.14.06.21.21.26.36.37,1.02.84,1.64.45,2.65-.39,1.01-1.18,1.86-2.18,2.27-.61.25-1.28.33-1.94.35-.91.03-1.87-.07-2.65-.55-.78-.48-1.32-1.43-1.1-2.32Z"/>
      <polygon className="cls-57" points="272.35 3432.07 274.89 3439.97 275.85 3439.57 273.13 3431.82 272.35 3432.07"/>
    </g>
    <g id="Titel-3" data-name="Titel">
      <rect id="Achtergrond-4" data-name="Achtergrond" className="cls-57" x="14.52" y="3264.08" width="267.24" height="33" rx="2.5" ry="2.5"/>
      <text className="cls-55" transform="translate(28.41 3287.13)"><tspan x="0" y="0">Jeugdgezondheidszorg </tspan></text>
    </g>
    </g>
    </>
  )
}

