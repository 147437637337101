import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

export default function CJG(props) {
    const isDesctop = props.isDesctop;
    const desctopScreen = useRef();
    const mobileScreen = useRef();

    useLayoutEffect(() => {
      const ctx = gsap.context((self) => {
        gsap.timeline({repeat:-1})
        .to('#Locatie',
          { duration: 1,  transformOrigin: '50% 50%',  rotation:"+=30", ease:'none'}        
        )
        .to('#Locatie',
          { duration: 1,  transformOrigin: '50% 50%',  rotation:"-=30", ease:'none'}      
        )

        gsap.timeline({repeat:-1})
        .to('#Flower',
          { duration: 3,  transformOrigin: '50% 50%',  rotation:"+=20", ease:'none'}        
        )
        .to('#Flower',
          { duration: 3,  transformOrigin: '50% 50%',  rotation:"-=20", ease:'none'}      
        ) 


        const numbers = self.selector('.number');
        numbers.forEach((number) => {
          gsap.from(number, {
            textContent: 0,
            duration: 2,
            snap: { textContent: 1 },
            scrollTrigger: {
              trigger: number,
            },
          });
        });
      }, desctopScreen);
      return () => ctx.revert(); // <- Cleanup!     
    }, [])
  
    useLayoutEffect(() => {
      const ctxMobile = gsap.context((self) => {
        gsap.timeline({repeat:-1})
        .to('#Locatie',
          { duration: 1,  transformOrigin: '50% 50%',  rotation:"+=30", ease:'none'}        
        )
        .to('#Locatie',
          { duration: 1,  transformOrigin: '50% 50%',  rotation:"-=30", ease:'none'}      
        )
        gsap.timeline({repeat:-1})
        .to('#Flower',
          { duration: 3,  transformOrigin: '50% 50%',  rotation:"+=20", ease:'none'}        
        )
        .to('#Flower',
          { duration: 3,  transformOrigin: '50% 50%',  rotation:"-=20", ease:'none'}      
        ) 
        const numbers = self.selector('.number');
        numbers.forEach((number) => {
          gsap.from(number, {
            textContent: 0,
            duration: 2,
            snap: { textContent: 1 },
            scrollTrigger: {
              trigger: number,
            },
          });
        });
      }, mobileScreen);
      return () => ctxMobile.revert(); // <- Cleanup!     
    }, [])


    if (isDesctop) {
      return (
        <>
        <g ref={desctopScreen} id="CJG" className="desctop-overview">
    <path id="Ondergrond" className="cls-96" d="m624.96,4123.17h-140.09s53.57,0,53.57,0c-19.08,0-34.55-15.47-34.55-34.55s15.47-34.55,34.55-34.55h74.92c12.34,0,22.34-10,22.34-22.34s-10-22.34-22.34-22.34h-40.39c12.34,0,22.34-10,22.34-22.34s-10-22.34-22.34-22.34H128.19c-12.34,0-22.34,10-22.34,22.34s10,22.34,22.34,22.34h-13.38c-19.08,0-34.55,15.47-34.55,34.55s15.47,34.55,34.55,34.55h30.94c12.34,0,22.34,10,22.34,22.34s-10,22.34-22.34,22.34h-76.11c-19.08,0-34.55,15.47-34.55,34.55s15.47,34.55,34.55,34.55h164.32c-.15,0-.29,0-.44.01-.05,0-.09,0-.13,0-38.04,0-68.89,30.84-68.89,68.89s30.84,68.89,68.89,68.89h286.47c1.73.27,3.5.41,5.3.41,19.08,0,34.55-15.47,34.55-34.55s-15.47-34.55-34.55-34.55h99.8v-.41c38.04,0,68.89-30.84,68.89-68.89s-30.84-68.89-68.89-68.89Z"/>
    <g id="Gebouw_Westblaak" data-name="Gebouw Westblaak">
      <rect className="cls-84" x="142.43" y="3896.33" width="28.66" height="31.9"/>
      <polyline className="cls-60" points="101.72 3882.09 101.72 4048.38 400.65 4048.38 400.65 3882.09"/>
      <g>
        <g>
          <rect className="cls-78" x="145.75" y="3953.44" width="33.94" height="17.28"/>
          <rect className="cls-78" x="182.79" y="3953.44" width="33.94" height="17.28"/>
        </g>
        <g>
          <rect className="cls-78" x="232.92" y="3953.44" width="33.94" height="17.28"/>
          <rect className="cls-78" x="269.96" y="3953.44" width="33.94" height="17.28"/>
        </g>
        <g>
          <rect className="cls-78" x="318.73" y="3953.44" width="33.94" height="17.28"/>
          <rect className="cls-78" x="355.77" y="3953.44" width="33.94" height="17.28"/>
        </g>
        <rect className="cls-78" x="113.92" y="3953.44" width="16.55" height="17.28"/>
      </g>
      <g>
        <g>
          <rect className="cls-78" x="145.75" y="3895.24" width="33.94" height="17.28"/>
          <rect className="cls-78" x="182.79" y="3895.24" width="33.94" height="17.28"/>
        </g>
        <g>
          <rect className="cls-78" x="231.37" y="3895.24" width="33.94" height="17.28"/>
          <rect className="cls-78" x="268.41" y="3895.24" width="33.94" height="17.28"/>
        </g>
        <g>
          <rect className="cls-78" x="318.73" y="3895.24" width="33.94" height="17.28"/>
          <rect className="cls-78" x="355.77" y="3895.24" width="33.94" height="17.28"/>
        </g>
        <rect className="cls-78" x="113.92" y="3895.24" width="16.55" height="17.28"/>
      </g>
      <g>
        <g>
          <rect className="cls-23" x="113.92" y="3924.34" width="25.26" height="17.28"/>
          <rect className="cls-23" x="142.28" y="3924.34" width="33.94" height="17.28"/>
        </g>
        <g>
          <rect className="cls-23" x="188.27" y="3924.34" width="33.94" height="17.28"/>
          <rect className="cls-23" x="225.31" y="3924.34" width="33.94" height="17.28"/>
        </g>
        <g>
          <rect className="cls-78" x="275.1" y="3924.34" width="33.94" height="17.28"/>
          <rect className="cls-78" x="312.14" y="3924.34" width="33.94" height="17.28"/>
        </g>
        <rect className="cls-78" x="360.74" y="3924.34" width="28.96" height="17.28"/>
      </g>
      <g>
        <g>
          <rect className="cls-78" x="113.92" y="3982.54" width="25.26" height="17.28"/>
          <rect className="cls-78" x="142.28" y="3982.54" width="33.94" height="17.28"/>
        </g>
        <g>
          <rect className="cls-78" x="188.27" y="3982.54" width="33.94" height="17.28"/>
          <rect className="cls-78" x="225.31" y="3982.54" width="33.94" height="17.28"/>
        </g>
        <g>
          <rect className="cls-78" x="275.1" y="3982.54" width="33.94" height="17.28"/>
          <rect className="cls-78" x="312.14" y="3982.54" width="33.94" height="17.28"/>
        </g>
        <rect className="cls-78" x="360.74" y="3982.54" width="28.96" height="17.28"/>
      </g>
    </g>
    <g id="Professionals">
      <path className="cls-23" d="m628.8,4090.54h-10.06c-3.65,0-6.64-2.99-6.64-6.64h0c0-3.65,2.99-6.64,6.64-6.64h10.06c3.65,0,6.64,2.99,6.64,6.64h0c0,3.65-2.99,6.64-6.64,6.64Z"/>
      <path className="cls-60" d="m634.37,4130.51l-2.83-14.77-21.37-.69-.53,12.25c.96,16.56,5.42,30.16,7.49,44.71l3.4-3.38,2.57-37.6-1.06,39.94h3.52c3.7-14.96,7.41-24.83,8.81-40.46Z"/>
      <path className="cls-23" d="m626.72,4166.3c-.38,1.56-.77,3.11-1.16,4.68h-3.52l.11-4.68h4.56Z"/>
      <path className="cls-23" d="m620.8,4166.3l-.71,3.02-2.96,2.7c-.26-1.85-.57-3.76-.89-5.72h4.56Z"/>
      <path className="cls-53" d="m604.55,4130.33c-1.04.11-2.11-.04-3.08-.42-.05-.01-.09-.03-.14-.05,0,0-.02,0-.03,0-.09,0-.17-.01-.25-.02l-.79,5.14c-.19,1.25.4,2.49,1.48,3.13l.65.39c.87.52,1.97-.1,1.99-1.11l-.28-5.96.45-1.1Z"/>
      <path className="cls-53" d="m619.91,4091.94c.2.05.39.1.59.14,5.47,1.12,10.64-2.98,11.03-8.55l.73-9c0-5.36-4.26-9.81-9.61-9.9-.2,0-.39,0-.59,0-5.39,0-9.66,4.36-9.55,9.75.07,3.61-2.03,6.8-1.77,9.92.32,3.9,5.39,6.67,9.17,7.65Z"/>
      <path className="cls-56" d="m632.88,4103.16l-1.33,12.59-21.59.31,1.38-6.12-2.12,9.42-4.5,11h-3.7l.13-.87,2.8-13.75,2.54-16.38c.28-3.12,2.33-5.86,5.3-6.86.39-.13.76-.22,1.12-.27l9.47-1.23,7.89,1.68"/>
      <path className="cls-23" d="m632.13,4175.21c.31-.11.54-.37.7-.66.53-.96.21-2.2-.48-3.06-.69-.85-1.67-1.4-2.63-1.92-1.13-.62-2.27-1.24-3.49-1.63-1.23-.39-2.56-.54-3.8-.21-.47.79-.31,1.83.19,2.6.5.77,1.27,1.31,2.05,1.8,1.93,1.21,4,2.2,6.15,2.95.43.15.89.28,1.31.13Z"/>
      <path className="cls-23" d="m610.81,4175.02c-.31-.11-.54-.37-.7-.66-.53-.96-.21-2.21.48-3.06.69-.85,1.67-1.4,2.63-1.92,1.13-.62,2.27-1.24,3.5-1.63,1.23-.39,2.56-.54,3.8-.21.47.79.3,1.83-.19,2.6-.5.77-1.13,1.83-2.05,1.8-1.31-.03-3.82,1.83-6.15,2.95-.41.19-.89.28-1.31.13Z"/>
      <path className="cls-23" d="m617.35,4061.95c-4.99-1.64-8.31,3.49-9.61,7.53-2.33,7.25-1.68,15.4,1.76,22.19.2.39.41.79.75,1.08.42.37.99.51,1.54.63,1.79.4,3.62.69,5.45.87-2.73-4.36-4.08-9.54-3.97-14.67.12-5.43,1.62-9.41,5.33-13.31.09-.09,3.47,1.55,3.82,1.7,1.98.85,3.72,2.14,5.43,3.43,1.48,1.12,2.21,1.9,2.89,3.65.6,1.57,1.01,3.22,1.21,4.89.21,1.67.21,3.37,0,5.04-.14,1.16-.07,2.32-.22,3.46-.06.5-.71,1.68-1.08,1.83,1.73-.69,3.39.8,4.96-.22.78-.51,1.56-1.09,2.02-1.9.58-1.02.56-2.26.52-3.43-.16-4.97-.5-10.03-2.34-14.66-2.38-6-7.35-10.71-14.06-10.52-1.77.05-3.41.95-4.42,2.41Z"/>
      <path className="cls-53" d="m618.98,4093.91c0,2.41,6.55,2.41,6.55,0v-6.26h-6.55v6.26Z"/>
      <path className="cls-53" d="m637.31,4130.26c1.04.11,2.11-.04,3.08-.42.05-.01.09-.03.14-.05,0,0,.02,0,.03,0,.09,0,.17-.01.25-.02l.79,5.14c.19,1.25-.4,2.49-1.48,3.13l-.65.39c-.87.52-1.97-.1-1.99-1.11l.28-5.96-.45-1.1Z"/>
      <path className="cls-56" d="m630.54,4109.87l2.12,9.42,4.5,11h3.7s-.13-.87-.13-.87l-2.8-13.75-2.54-16.38c-.28-3.12-2.23-5.68-5.2-6.68"/>
      <polyline className="cls-56" points="610.32 4115.68 631.92 4115.99 630.54 4109.87"/>
      <path className="cls-23" d="m611.8,4093.38c1.79.4,3.62.69,5.45.87-2.73-4.36-4.08-9.54-3.97-14.67.12-5.43,1.62-9.41,5.33-13.31l-8.23.04"/>
      <ellipse className="cls-53" cx="632.83" cy="4079.12" rx="3.71" ry="1.74" transform="translate(-3468.17 4549.7) rotate(-87.73)"/>
      <path className="cls-108" d="m683.83,4171.45c.15.27.17.6.11.91-.21,1.02-1.2,1.73-2.22,1.92-1.02.2-2.07-.01-3.09-.22-1.2-.25-2.41-.49-3.54-.96-1.13-.47-2.19-1.18-2.89-2.18.14-.86.9-1.52,1.74-1.77.83-.25,1.73-.17,2.59-.05,2.14.3,4.25.86,6.27,1.64.4.15.82.34,1.03.71Z"/>
      <path className="cls-108" d="m656.24,4175.37c-.5-.26-.55-.67-.57-1.42,0-.41.01-.85.22-1.2.13-.22.31-.39.5-.56,1.16-1.07,2.25-1.74,3.78-2.23,1.17-.37,2.43-.52,3.61-.2.93.25-.23,3.21-.55,3.59-.38.42-.83.78-1.33,1.04-.26.14-.54.26-.81.38-.57.24-1.15.49-1.76.62-.78.17-2.07.36-2.84.09-.1-.03-.19-.07-.26-.11Z"/>
      <path className="cls-28" d="m654.28,4113.4s.58,32.24.79,33.02l1.93,23.93,8.94.48c.56-8.01.99-22.36.47-26.39l1.86-23.99c5.2-.11-13.98-7.05-13.98-7.05Z"/>
      <path className="cls-28" d="m666.76,4112.92l9.9-.07,2.66-.02-2.06-.63,2.1,1.21c.28,4.22-1.62,10.19-1.62,10.19l-.49,17.61c2.3,20.58,4.33,28.92-.71,28.35l-6.35,2.11-2.68-26.63-2.17-33,1.43.89Z"/>
      <path className="cls-87" d="m656.26,4087.87c-7.49,5.7-7.03,15.12-8.21,23.56-.11.77.4-1.48,1.02-.86,7.71,7.77,9.31-14.63,9.31-14.63l-2.12-8.07Z"/>
      <path className="cls-87" d="m674.21,4096.73c.5-8.75-11.85-9.19-11.85-9.19l-6.1.33c-5.88,6.99-1.3,21.31-2.99,33.93,2.28,4.88,18.49-1.63,25.08-1.52,0,0-4.48-17.58-4.15-23.55Z"/>
      <path className="cls-92" d="m650.67,4124.39s2.82-8.71,1.91-13.34c-.9-4.63-3.85-1.13-4.44-.04-.59,1.1-.47,11.91-.47,11.91l3,1.47Z"/>
      <path className="cls-92" d="m650.95,4121.89s2.66,3.77.17,4.84c-2.49,1.07-4.26-2.29-3.78-3.54.48-1.25,3.61-1.3,3.61-1.3Z"/>
      <path className="cls-87" d="m667.04,4091.49c-1.72,9.26,5,15.38,9.79,22.43.44.65-.7-1.37.17-1.32,10.93.58-4.02-20.46-4.02-20.46l-5.94-.65Z"/>
      <path className="cls-92" d="m681.26,4123.65s1.01-9.1-.8-13.46c-1.81-4.35-4-.34-4.36.85-.36,1.19,1.92,11.76,1.92,11.76l3.24.84Z"/>
      <path className="cls-92" d="m681.25,4121.3s2.53,3.86,0,4.84c-2.52.98-4.18-2.44-3.66-3.67.53-1.23,3.65-1.18,3.65-1.18Z"/>
      <g id="Contact">
        <path className="cls-23" d="m732.98,4014.54c-.05-.34-.12-.68-.21-1.01-.09-.33-.19-.65-.3-.97-.12-.32-.25-.63-.39-.93-.15-.3-.31-.6-.48-.88-.26-.43-.55-.83-.87-1.22-.21-.26-.44-.5-.67-.74-1.88-1.88-4.47-3.04-7.31-3.04h-88.42c-2.85,0-5.44,1.17-7.31,3.04-.23.23-.46.48-.67.74-.32.38-.61.79-.87,1.22-.17.28-.33.58-.48.88-.15.3-.28.61-.39.93-.12.32-.22.64-.3.97-.09.33-.15.67-.21,1.01-.08.51-.12,1.04-.12,1.57v24.72c0,.53.04,1.06.12,1.57.05.34.12.68.21,1.01.13.5.29.98.49,1.44.33.77.75,1.5,1.25,2.17.2.27.41.52.63.77.22.25.46.48.7.7,1.84,1.67,4.28,2.7,6.95,2.7h.94l-1.92,8.53c-.02.1-.03.19-.04.28,0,.02,0,.04,0,.06,0,.09,0,.17.03.26,0,.01,0,.03.01.04.02.08.05.15.08.22,0,.01,0,.02.01.03.04.07.08.14.13.21.01.01.02.03.03.04.05.06.11.12.18.18,0,0,0,0,.01,0,.06.05.13.09.2.13.02,0,.03.02.05.02.07.03.15.06.23.08.02,0,.03,0,.05,0,.08.02.15.03.23.03.01,0,.02,0,.03,0,.08,0,.17-.01.25-.03.02,0,.04,0,.06-.01.09-.02.17-.05.26-.1l17.72-9.99h69.88c2.67,0,5.11-1.02,6.95-2.7.25-.22.48-.46.7-.7.22-.25.44-.5.63-.77.5-.67.92-1.39,1.25-2.17.2-.46.36-.95.49-1.44.09-.33.16-.67.21-1.01.08-.51.12-1.04.12-1.57v-24.72c0-.53-.04-1.06-.12-1.57Z"/>
      </g>
      <text className="cls-39" transform="translate(638.27 4034.23)"><tspan x="0" y="0">Ik ben pro</tspan></text>
      <path className="cls-91" d="m658.78,4090.03c0,2.41,6.55,2.41,6.55,0v-6.26h-6.55v6.26Z"/>
      <path className="cls-91" d="m669.48,4075.67c0,6.69-.78,11.63-8.31,11.63s-9.07-5.42-9.07-12.1,4.06-12.1,9.07-12.1,8.31,5.89,8.31,12.58Z"/>
      <path className="cls-91" d="m652.8,4075.24c.41,1.54,0,2.98-.91,3.22-.91.24-1.99-.8-2.4-2.34-.41-1.54,0-2.98.91-3.22.91-.24,1.99.8,2.4,2.34Z"/>
      <path className="cls-91" d="m669.37,4075.24c-.41,1.54,0,2.98.91,3.22.91.24,1.99-.8,2.4-2.34.41-1.54,0-2.98-.91-3.22s-1.99.8-2.4,2.34Z"/>
      <path className="cls-60" d="m669.08,4067.13v2.98h-.35l.02,8.05c0,2.05-.55,4.06-1.6,5.82l-3.11.26-5.04.2-3.22-.17-2.65-2.99v-11.18h-1.11c-.04.35-.07.7-.07,1.06v11.46c0,2.1,1.43,3.94,3.46,4.45,3.79.96,7.52.95,11.19.01,2.02-.52,3.43-2.35,3.43-4.44v-11.48c0-1.45-.34-2.82-.95-4.04Z"/>
      <path className="cls-60" d="m665.28,4063.19h0c3.28-1.08,5.14-.08,5.14,5.66l-.39,9.18-1.8-6.81-1.7-4.34-11.06,2.16c-1.26.25-2.17,1.35-2.17,2.63h0l-1.35,6.36-1.35-6.86c-2.42-11.83,5.46-10.43,11.19-9.99,1.41.11,2.7.84,3.5,2.01Z"/>
      <g>
        <path className="cls-38" d="m627.37,4099.93c-.25-.13-.37-.3-.37-.52s.11-.38.21-.49c.33-.34.77-.38.91-.38.11,0,.23.01.37.05.29.06.59-.36.59-.77,0-.35-.22-.68-.58-.81-.12-.04-.25-.06-.38-.06-.57,0-1.22.37-1.22.96,0,.52.42.62.59.7.03.01.04.03.01.04-1.41.23-2.01.84-2.01,1.25,0,.25.37.64.53.82.05.05.06.02.11-.02.16-.16.54-.48,1.09-.63.04-.01.25-.06.15-.13Zm1.55-1.11s-.07,0-.04.05c.05.06.13.17.13.33s-.08.29-.19.41c-.25.25-.58.31-.8.36.17-.02.34,0,.49.08.33.08.67.23.89.44.22.22.29.45.14.83-.03.07-.1.2-.1.24,0,.01,0,.02.02.02.04,0,.09-.02.16-.09.38-.38.48-.98.48-1.24,0-.75-.85-1.39-1.17-1.43Z"/>
        <path className="cls-38" d="m629,4101.34c-.34-.34-.67-.29-.73-.35,0,0,0,0,0,0,0,0,0,0,0,0,.03-.03.24-.07.39-.22.07-.07.13-.16.13-.28,0-.28-.27-.42-.29-.43-.15-.08-.32-.1-.49-.08-.34.04-.69.22-.69.57,0,.23.12.39.23.43.02.01.14.04.07.07-.05.02-.11.03-.16.04-.05.01-.84.21-.84.7,0,.27.32.49.34.52.02.02.04.05.04.07v.19c0,.35.79,1,1.38,1,.23,0,.31-.27.31-.28.06-.15.05-.37.06-.47,0-.1,0-.16.06-.24.04-.06.17-.17.26-.35.07-.13.12-.28.12-.42,0-.18-.09-.33-.2-.44Z"/>
      </g>
      <g>
        <path className="cls-38" d="m667.84,4097.12c-.25-.13-.37-.3-.37-.52s.11-.38.21-.49c.33-.34.77-.38.91-.38.11,0,.23.01.37.05.29.06.59-.36.59-.77,0-.35-.22-.68-.58-.81-.12-.04-.25-.06-.38-.06-.57,0-1.22.37-1.22.96,0,.52.42.62.59.7.03.01.04.03.01.04-1.41.23-2.01.84-2.01,1.25,0,.25.37.64.53.82.05.05.06.02.11-.02.16-.16.54-.48,1.09-.63.04-.01.25-.06.15-.13Zm1.55-1.11s-.07,0-.04.05c.05.06.13.17.13.33s-.08.29-.19.41c-.25.25-.58.31-.8.36.17-.02.34,0,.49.08.33.08.67.23.89.44.22.22.29.45.14.83-.03.07-.1.2-.1.24,0,.01,0,.02.02.02.04,0,.09-.02.16-.09.38-.38.48-.98.48-1.24,0-.75-.85-1.39-1.17-1.43Z"/>
        <path className="cls-38" d="m669.48,4098.53c-.34-.34-.67-.29-.73-.35,0,0,0,0,0,0,0,0,0,0,0,0,.03-.03.24-.07.39-.22.07-.07.13-.16.13-.28,0-.28-.27-.42-.29-.43-.15-.08-.32-.1-.49-.08-.34.04-.69.22-.69.57,0,.23.12.39.23.43.02.01.14.04.07.07-.05.02-.11.03-.16.04-.05.01-.84.21-.84.7,0,.27.32.49.34.52.02.02.04.05.04.07v.19c0,.35.79,1,1.38,1,.23,0,.31-.27.31-.28.06-.15.05-.37.06-.47,0-.1,0-.16.06-.24.04-.06.17-.17.26-.35.07-.13.12-.28.12-.42,0-.18-.09-.33-.2-.44Z"/>
      </g>
    </g>
    <g id="Samen_groeien_De_wandeling" data-name="Samen groeien De wandeling">
      <path className="cls-84" d="m363.79,4245.01c-.17.02-.35.04-.52.06.11-.01.21-.02.31-.03.04,0,.08-.01.13-.02.03,0,.06,0,.09-.01Z"/>
      <path className="cls-38" d="m471.27,4228.78c-.28-.04-.47-.07,0,0h0Z"/>
      <path className="cls-38" d="m364,4244.98c-.07,0-.14.02-.2.02-.03,0-.06,0-.09.01-.04,0-.08.01-.13.02,1.65-.18.98-.11.42-.05Z"/>
      <path className="cls-38" d="m448.52,4268.34c.11.02.21.04.32.05.09.01.18.03.27.04.58.09-.64-.1-.59-.1Z"/>
      <circle className="cls-85" cx="412.12" cy="4186.74" r="61.19"/>
      <g>
        <circle className="cls-45" cx="411.42" cy="4187.8" r="60.34"/>
        <path className="cls-23" d="m411.42,4141.57c25.49,0,46.24,20.74,46.24,46.24s-20.74,46.24-46.24,46.24-46.24-20.74-46.24-46.24,20.74-46.24,46.24-46.24m0-28.22c-41.12,0-74.45,33.33-74.45,74.45s33.33,74.45,74.45,74.45,74.45-33.33,74.45-74.45-33.33-74.45-74.45-74.45h0Z"/>
      </g>
      <path className="cls-84" d="m400.25,4133.79c-1.74-1.41-4.05-1.93-6.22-2.2-2.66-.33-5.37-.28-8.04-.09-3.86.27-7.8.47-10.9,3.03-2.95,2.43-4.85,5.96-6.44,9.36-1.85,3.94-3.32,8.05-5.08,12.02-1.53,3.44-3.41,7.7-6.89,9.58-.47.26.25.32.48.19,2.77-1.5,4.5-4.39,5.88-7.11,1.86-3.63,3.25-7.49,4.83-11.24,1.55-3.69,3.21-7.47,5.76-10.59,1.1-1.34,2.39-2.55,3.93-3.36,1.69-.89,3.57-1.03,5.44-1.24,2.92-.32,5.89-.5,8.83-.32,2.62.16,5.57.56,7.68,2.26.2.16.93-.15.75-.3h0Z"/>
      <path className="cls-84" d="m464.98,4212.07c.8.54,4.03,3.13,5.07,10.78.02.14-.07.27-.2.31-.94.25-4.58.95-8.71-1.57-.17-.1-.39-.01-.42.19-.26,1.9-1.75,9.82-8.8,16.3-.11.1-.27.1-.38,0-.98-.93-5.42-5.6-4.78-14.02.01-.16-.11-.3-.28-.3-1.63.04-9.44.2-12.32-.37-.14-.03-.23-.15-.22-.29.11-1.28.91-7.27,6.72-9.61.15-.06.22-.23.15-.37-.29-.58-1.16-1.96-3.13-2.6-.22-.07-.26-.36-.07-.49,1.87-1.23,8.89-5.06,15.49,1.61.11.11.3.11.4,0,.93-1.05,5.23-5.41,11.61-4.02.1.02.19.1.22.21"/>
      <path className="cls-84" d="m382.8,4232.47c-1.15.52-6.02,3.03-6.53,8.86,0,.1.04.19.12.24.35.23,1.44.78,3.48.66.12,0,.22.06.26.17.24.83,1.6,4.28,7.87,7.21.11.05.25.02.32-.09.46-.74,2.03-3.67.97-7.87-.04-.17.1-.34.27-.31,1.69.28,8.86,1.12,16.29-3.19.11-.07.16-.21.1-.32-.53-1.09-3.35-6.13-10.73-7.82-.14-.03-.23-.18-.18-.32.47-1.39,2.68-8.02,2.96-10.64.01-.12-.07-.24-.19-.26-1.12-.25-6.46-1.15-10.01,3.19-.09.11-.26.13-.36.03-.42-.4-1.37-1.51-1.4-3.37,0-.21-.24-.32-.4-.19-1.55,1.27-6.7,6.26-2.74,13.67.07.13.02.28-.11.34Z"/>
      <path className="cls-84" d="m467.83,4202.37c1.03.17,5.46,1.13,7.12,5.68.03.07,0,.16-.04.22-.23.26-.98.93-2.64,1.27-.09.02-.16.1-.17.19-.02.72-.36,3.77-4.74,7.45-.08.07-.2.07-.27,0-.53-.49-2.41-2.49-2.47-6.08,0-.15-.15-.25-.29-.19-1.29.59-6.84,2.8-13.7.95-.1-.03-.17-.13-.15-.24.19-.98,1.36-5.62,6.89-8.55.11-.06.15-.19.08-.29-.67-1.01-3.87-5.83-4.65-7.86-.04-.1,0-.2.1-.25.84-.44,4.91-2.31,8.68.4.1.07.23.05.29-.05.25-.41.77-1.5.39-2.99-.04-.17.12-.31.28-.24,1.51.68,6.69,3.56,5.13,10.34-.03.12.05.23.16.25Z"/>
      <path className="cls-84" d="m353.02,4205.48c-.88.14-4.66.97-6.08,4.85-.02.06,0,.13.04.18.2.22.84.8,2.25,1.09.08.02.14.08.14.16.01.61.31,3.22,4.05,6.37.07.06.17.06.23,0,.45-.42,2.06-2.13,2.11-5.2,0-.13.13-.21.24-.16,1.1.5,5.85,2.39,11.71.81.09-.02.15-.11.13-.2-.16-.84-1.16-4.8-5.89-7.31-.09-.05-.12-.16-.07-.25.57-.86,3.3-4.99,3.98-6.72.03-.08,0-.17-.08-.21-.72-.37-4.2-1.97-7.42.34-.08.06-.2.04-.25-.05-.21-.35-.65-1.28-.33-2.55.04-.14-.11-.26-.24-.2-1.29.58-5.72,3.04-4.38,8.83.02.1-.04.2-.14.21Z"/>
      <g>
        <path className="cls-38" d="m380.36,4170.63v-2.08s.14,0,.14,0c1,.22,2.19.38,3.25.38,1.2,0,2.35-.28,2.35-1.28,0-.74-.62-1.04-1.36-1.22l-1.68-.38c-1.76-.42-2.75-1.38-2.75-2.99,0-2.15,1.98-3.27,4.77-3.27,1.08,0,2.17.16,2.95.32v2.08s-.14,0-.14,0c-.86-.14-1.84-.3-2.87-.3-1.16,0-2.18.28-2.18,1.14,0,.78.58.92,1.26,1.08l1.48.34c1.62.36,3.05,1.08,3.05,3.19,0,2.59-2.36,3.39-4.89,3.39-1.16,0-2.51-.16-3.39-.4Z"/>
        <path className="cls-38" d="m389.75,4167.34v-1.88c0-3.39,2.66-5.51,5.77-5.51h3.85s0,8.9,0,8.9c.48-.04,1-.1,1.5-.24v1.86c-1.04.28-2.02.4-3.03.4h-.94s0-1.82,0-1.82l-.78.68c-.86.76-1.78,1.3-3.07,1.3-1.96,0-3.29-1.2-3.29-3.69Zm7.14-.3v-5.01s-1.24,0-1.24,0c-2.08,0-3.43,1.24-3.43,3.43v1.64c0,1.38.6,1.8,1.5,1.8.9,0,1.94-.8,2.55-1.34l.62-.52Z"/>
        <path className="cls-38" d="m401.29,4160.36c1.04-.28,2.04-.4,3.03-.4h.94s0,1.88,0,1.88l.62-.58c.9-.9,1.88-1.46,3.21-1.46,1.38,0,2.37.68,2.75,2.12h.1s.7-.66.7-.66c.94-.88,1.88-1.46,3.21-1.46,1.8,0,2.95,1.1,2.95,3.63v5.43c.48-.04,1-.1,1.5-.24v1.86c-1.04.28-2.02.4-3.03.4h-.94s0-7.06,0-7.06c0-1.4-.5-1.88-1.32-1.88-.82,0-1.76.82-2.41,1.4l-.58.52v7.02s-2.46,0-2.46,0v-7.06c0-1.4-.5-1.88-1.33-1.88-.8,0-1.74.82-2.38,1.4l-.6.52v7.02s-2.48,0-2.48,0v-8.9c-.5.06-1.02.14-1.49.24v-1.86Z"/>
        <path className="cls-38" d="m421.05,4166.63v-2.14c0-3.03,2.2-4.69,5.07-4.69,2.41,0,4.25.9,4.25,3.31,0,2.12-1.48,3.15-3.71,3.15h-3.13s0,.4,0,.4c0,1.68,1.2,2.24,2.69,2.24,1.2,0,2.45-.32,3.23-.6h.16s0,2.08,0,2.08c-.84.3-2.39.66-3.53.66-2.91,0-5.03-1.26-5.03-4.41Zm2.48-2.01h2.75c1.28,0,1.74-.48,1.74-1.42,0-1.06-.8-1.32-1.9-1.32-1.64,0-2.59.74-2.59,2.57v.16Z"/>
        <path className="cls-38" d="m430.9,4160.37c1.04-.28,2.04-.4,3.03-.4h.94s0,1.88,0,1.88l.66-.58c.94-.86,1.9-1.46,3.31-1.46,1.88,0,3.03,1.1,3.03,3.63v5.43c.5-.04,1.02-.1,1.52-.24v1.86c-1.04.28-2.04.4-3.03.4h-.94s0-7.06,0-7.06c0-1.4-.54-1.88-1.42-1.88-.9,0-1.86.84-2.49,1.4l-.64.52v7.02s-2.48,0-2.48,0v-8.9c-.5.06-1.02.14-1.49.24v-1.86Z"/>
      </g>
      <path className="cls-84" d="m357.81,4170.71c.59.52,3.28,2.62,6.71,1.4.06-.02.1-.07.1-.13.04-.26.07-1.02-.53-2.14-.03-.06-.02-.14.03-.19.41-.35,2.01-2.02,2.04-6.33,0-.08-.06-.15-.13-.16-.54-.07-2.6-.2-4.71,1.49-.09.07-.22.03-.25-.07-.28-1.03-1.67-5.31-6.03-8.4-.07-.05-.16-.04-.21.03-.48.58-2.6,3.49-1.65,8.1.02.09-.04.18-.13.19-.91.09-5.24.56-6.79,1.08-.07.02-.12.1-.1.18.15.7,1.02,3.96,4.4,4.84.09.02.14.11.11.2-.12.34-.5,1.17-1.54,1.66-.12.06-.12.22,0,.28,1.12.55,5.28,2.17,8.46-1.99.05-.07.16-.08.22-.03Z"/>
      <path className="cls-84" d="m365.45,4164.4c-.34.23-1.74,1.3-1.57,3.17,0,.03.02.06.05.07.12.05.5.17,1.14.01.04,0,.07,0,.09.04.12.25.75,1.27,2.9,1.85.04.01.08,0,.1-.05.1-.26.44-1.28-.13-2.55-.02-.05.01-.11.07-.11.55,0,2.87-.14,4.99-1.92.03-.03.04-.07.01-.11-.23-.32-1.41-1.76-3.84-1.88-.05,0-.08-.04-.08-.09.07-.47.4-2.69.35-3.54,0-.04-.03-.07-.08-.07-.37-.02-2.11,0-3,1.57-.02.04-.07.06-.11.03-.16-.1-.52-.4-.63-.99-.01-.07-.09-.09-.14-.04-.42.49-1.77,2.36-.11,4.49.03.04.02.09-.02.12Z"/>
      <path className="cls-84" d="m388.73,4133.23c.01.37.17,1.98,1.71,2.77.03.01.06.01.08,0,.1-.07.37-.31.56-.88.01-.03.04-.05.08-.05.25.02,1.35.03,2.84-1.36.03-.03.03-.07.01-.1-.15-.21-.78-.96-2.05-1.13-.05,0-.08-.06-.05-.11.26-.43,1.28-2.3.92-4.81,0-.04-.04-.07-.08-.06-.36.03-2.05.24-3.32,2.08-.02.04-.07.04-.11.02-.33-.28-1.9-1.62-2.59-1.99-.03-.02-.07,0-.09.02-.19.28-1.03,1.64-.23,3.09.02.04,0,.08-.03.1-.16.07-.57.21-1.08.01-.06-.02-.11.03-.1.09.18.56.97,2.52,3.44,2.26.04,0,.08.03.08.07Z"/>
      <path className="cls-84" d="m367.21,4155.66s-.46,1.64-2.9,1.92c-2.44.28-4.51-1.09-4.51-1.09,0,0,1.47-.86,1.74-1.26s.31-.57-.11-.76-1.8-.95-2.81-5.47c0,0,2.98-1.07,5.28-.29,0,0,.16.49.35-1.57.18-2.06,1.42-5.99,2.39-7.06,0,0,4.24,3.96,3.78,10.17,0,0,1.99-2.39,4.33-1.78,0,0,.03,3.57-2.08,6.49l.15,1.4s-2.56,2.45-5.61-.7Z"/>
      <path className="cls-84" d="m372.73,4135.87c-.55.07-2.9.49-3.87,2.85-.02.04,0,.08.02.11.12.14.5.51,1.37.72.05.01.08.05.08.1,0,.38.12,1.99,2.35,4.03.04.04.1.04.14,0,.29-.25,1.32-1.27,1.42-3.16,0-.08.08-.13.15-.09.67.34,3.55,1.61,7.21.77.06-.01.09-.07.08-.12-.08-.52-.61-2.99-3.46-4.65-.06-.03-.07-.1-.04-.16.37-.52,2.16-3,2.61-4.05.02-.05,0-.11-.05-.13-.44-.25-2.55-1.32-4.59.04-.05.03-.12.02-.15-.03-.12-.22-.37-.81-.15-1.59.03-.09-.06-.16-.14-.13-.81.33-3.6,1.74-2.91,5.35.01.06-.03.12-.09.13Z"/>
      <path className="cls-84" d="m378.33,4147.91c-2.47-3-6.82-3.82-10.18-1.84-1.7,1-2.87,2.52-3.55,4.36-.9,2.41-1.58,4.91-2.36,7.36-.09.29.44.28.51.06.66-2.08,1.31-4.16,1.97-6.23.59-1.85,1.36-3.53,2.95-4.75,3.17-2.43,7.66-1.78,10.17,1.27.15.19.66-.03.49-.23h0Z"/>
      <path className="cls-84" d="m393.24,4131.58c2.46-.17,4.35-3.09,3.23-5.37-.11-.22-.64-.07-.53.14.95,1.93-.43,4.69-2.65,4.85-.3.02-.42.4-.05.38h0Z"/>
      <path className="cls-84" d="m377.49,4144.99c-.26-.15-1.41-.72-2.58,0-.02.01-.03.03-.03.06,0,.1.05.38.36.75.02.02.02.05,0,.07-.12.16-.59.9-.27,2.49,0,.03.03.05.06.05.2-.02.97-.12,1.62-.91.03-.03.08-.03.1,0,.18.36,1.02,1.83,2.86,2.64.03.01.06,0,.08-.03.13-.25.69-1.48,0-3.11-.01-.03,0-.07.03-.08.33-.1,1.89-.61,2.42-.91.03-.01.04-.05.02-.07-.11-.25-.68-1.38-1.99-1.45-.03,0-.06-.03-.06-.06.02-.14.1-.47.44-.73.04-.03.03-.09-.02-.1-.45-.12-2.11-.4-2.96,1.38-.01.03-.05.04-.08.03Z"/>
      <path className="cls-84" d="m424.34,4134.1c1.83-1.48,4.32-1.92,6.6-2.13,2.68-.25,5.39-.14,8.07.09,1.98.17,4.14.25,6.04.9,1.63.57,3.06,1.6,4.26,2.83,2.72,2.79,4.47,6.43,6.01,9.96,1.66,3.79,3.07,7.69,4.82,11.44,1.49,3.19,3.34,6.87,6.58,8.63.28.15,1-.23.62-.44-3.04-1.65-4.82-5.09-6.25-8.09-1.84-3.87-3.28-7.92-5.01-11.85-1.57-3.58-3.36-7.3-6.15-10.11-1.17-1.17-2.52-2.15-4.07-2.76-1.85-.72-3.95-.82-5.9-1-2.96-.28-5.97-.42-8.94-.15-2.49.23-5.21.74-7.21,2.35-.42.34.23.55.52.32h0Z"/>
      <path className="cls-84" d="m466.14,4170.71c-.59.52-3.28,2.62-6.71,1.4-.06-.02-.1-.07-.1-.13-.04-.26-.07-1.02.53-2.14.03-.06.02-.14-.03-.19-.41-.35-2.01-2.02-2.04-6.33,0-.08.06-.15.13-.16.54-.07,2.6-.2,4.71,1.49.09.07.22.03.25-.07.28-1.03,1.67-5.31,6.03-8.4.07-.05.16-.04.21.03.48.58,2.6,3.49,1.65,8.1-.02.09.04.18.13.19.91.09,5.24.56,6.79,1.08.07.02.12.1.1.18-.15.7-1.02,3.96-4.4,4.84-.09.02-.14.11-.11.2.12.34.5,1.17,1.54,1.66.12.06.12.22,0,.28-1.12.55-5.28,2.17-8.46-1.99-.05-.07-.16-.08-.22-.03Z"/>
      <path className="cls-84" d="m458.5,4164.4c.34.23,1.74,1.3,1.57,3.17,0,.03-.02.06-.05.07-.12.05-.5.17-1.14.01-.04,0-.07,0-.09.04-.12.25-.75,1.27-2.9,1.85-.04.01-.08,0-.1-.05-.1-.26-.44-1.28.13-2.55.02-.05-.01-.11-.07-.11-.55,0-2.87-.14-4.99-1.92-.03-.03-.04-.07-.01-.11.23-.32,1.41-1.76,3.84-1.88.05,0,.08-.04.08-.09-.07-.47-.4-2.69-.35-3.54,0-.04.03-.07.08-.07.37-.02,2.11,0,3,1.57.02.04.07.06.11.03.16-.1.52-.4.63-.99.01-.07.09-.09.14-.04.42.49,1.77,2.36.11,4.49-.03.04-.02.09.02.12Z"/>
      <path className="cls-84" d="m435.22,4133.23c-.01.37-.17,1.98-1.71,2.77-.03.01-.06.01-.08,0-.1-.07-.37-.31-.56-.88-.01-.03-.04-.05-.08-.05-.25.02-1.35.03-2.84-1.36-.03-.03-.03-.07-.01-.1.15-.21.78-.96,2.05-1.13.05,0,.08-.06.05-.11-.26-.43-1.28-2.3-.92-4.81,0-.04.04-.07.08-.06.36.03,2.05.24,3.32,2.08.02.04.07.04.11.02.33-.28,1.9-1.62,2.59-1.99.03-.02.07,0,.09.02.19.28,1.03,1.64.23,3.09-.02.04,0,.08.03.1.16.07.57.21,1.08.01.06-.02.11.03.1.09-.18.56-.97,2.52-3.44,2.26-.04,0-.08.03-.08.07Z"/>
      <path className="cls-84" d="m456.75,4155.66s.46,1.64,2.9,1.92c2.44.28,4.51-1.09,4.51-1.09,0,0-1.47-.86-1.74-1.26s-.31-.57.11-.76c.42-.18,1.8-.95,2.81-5.47,0,0-2.98-1.07-5.28-.29,0,0-.16.49-.35-1.57-.18-2.06-1.42-5.99-2.39-7.06,0,0-4.24,3.96-3.78,10.17,0,0-1.99-2.39-4.33-1.78,0,0-.03,3.57,2.08,6.49l-.15,1.4s2.56,2.45,5.61-.7Z"/>
      <path className="cls-84" d="m451.22,4135.87c.55.07,2.9.49,3.87,2.85.02.04,0,.08-.02.11-.12.14-.5.51-1.37.72-.05.01-.08.05-.08.1,0,.38-.12,1.99-2.35,4.03-.04.04-.1.04-.14,0-.29-.25-1.32-1.27-1.42-3.16,0-.08-.08-.13-.15-.09-.67.34-3.55,1.61-7.21.77-.06-.01-.09-.07-.08-.12.08-.52.61-2.99,3.46-4.65.06-.03.07-.1.04-.16-.37-.52-2.16-3-2.61-4.05-.02-.05,0-.11.05-.13.44-.25,2.55-1.32,4.59.04.05.03.12.02.15-.03.12-.22.37-.81.15-1.59-.03-.09.06-.16.14-.13.81.33,3.6,1.74,2.91,5.35-.01.06.03.12.09.13Z"/>
      <path className="cls-84" d="m446.11,4148.02c2.61-3.17,7.23-3.58,10.37-.95,1.45,1.21,2.17,2.83,2.73,4.59.66,2.08,1.32,4.16,1.97,6.23.05.17.58.02.53-.14-.79-2.49-1.47-5.05-2.4-7.49-.71-1.86-1.96-3.39-3.73-4.33-3.35-1.78-7.58-.81-9.97,2.09-.14.17.39.12.49,0h0Z"/>
      <path className="cls-84" d="m430.85,4131.25c-2.29-.16-3.84-2.97-2.84-5.01.05-.11-.18-.1-.23-.1-.11.01-.25.06-.31.17-1.07,2.17.65,5.04,3.04,5.21.12,0,.29,0,.38-.1.07-.07.08-.16-.05-.17h0Z"/>
      <path className="cls-84" d="m446.47,4144.99c.26-.15,1.41-.72,2.58,0,.02.01.03.03.03.06,0,.1-.05.38-.36.75-.02.02-.02.05,0,.07.12.16.59.9.27,2.49,0,.03-.03.05-.06.05-.2-.02-.97-.12-1.62-.91-.03-.03-.08-.03-.1,0-.18.36-1.02,1.83-2.86,2.64-.03.01-.06,0-.08-.03-.13-.25-.69-1.48,0-3.11.01-.03,0-.07-.03-.08-.33-.1-1.89-.61-2.42-.91-.03-.01-.04-.05-.02-.07.11-.25.68-1.38,1.99-1.45.03,0,.06-.03.06-.06-.02-.14-.1-.47-.44-.73-.04-.03-.03-.09.02-.1.45-.12,2.11-.4,2.96,1.38.01.03.05.04.08.03Z"/>
      <path className="cls-103" d="m451.11,4233.02l-.34-.02c.04-.58.93-14.06,2.15-17.85-.85-.12-5.02-.24-14.88,5.99l-.18-.28c11.32-7.15,15.17-6.05,15.33-6l.16.05-.06.16c-1.23,3.47-2.18,17.8-2.19,17.95Z"/>
      <path className="cls-103" d="m453.15,4215.21l-.09-.05c-6.31-3.66-10.87-4.38-10.91-4.38l.05-.33s4.62.72,10.94,4.38c.53-.32,3.46-2.03,8.77-3.98l.12.31c-5.83,2.14-8.76,3.98-8.79,4l-.09.05Z"/>
      <path className="cls-103" d="m464.98,4221.34s-4.58-4.02-11.88-6.16l.09-.32c7.38,2.16,11.96,6.2,12.01,6.24l-.22.25Z"/>
      <g>
        <path className="cls-103" d="m387.95,4246.83c-5.41-11.88-3.37-15.24-3.28-15.37l.09-.14.15.08c3.53,1.82,19.02,5.5,19.17,5.53l-.09.37c-.63-.15-15.22-3.61-19.09-5.48-.31.77-1.31,4.58,3.38,14.85l-.35.16Z"/>
        <path className="cls-103" d="m377.84,4240.9l-.35-.15s1.78-4.12,7.12-9.24c-.27-.64-1.49-3.66-2.46-8.85l.38-.07c1.09,5.79,2.48,8.85,2.49,8.88l.06.12-.1.09c-5.35,5.1-7.11,9.16-7.13,9.2Z"/>
        <path className="cls-103" d="m385,4231.67l-.33-.2c3.95-6.39,9.35-9.84,9.4-9.87l.2.32c-.05.03-5.38,3.44-9.28,9.75Z"/>
      </g>
      <g>
        <path className="cls-103" d="m354.06,4216.34c-.09-8.94,1.74-10.91,1.81-10.99l.13-.14.12.15c1.4,1.79,8.56,6.73,8.63,6.78l-.19.28c-.29-.2-6.87-4.75-8.56-6.68-.39.6-1.68,3.14-1.6,10.6h-.34Z"/>
        <path className="cls-103" d="m350.31,4210.75l-.27-.2s1.86-2.53,5.79-5.17c0-.47.07-2.73.75-6.42l.33.06c-.76,4.12-.74,6.42-.74,6.45v.09s-.07.05-.07.05c-3.93,2.63-5.76,5.11-5.78,5.14Z"/>
        <path className="cls-103" d="m356.12,4205.59l-.25-.23c3.51-3.74,7.09-5.23,7.13-5.25l.13.31s-3.56,1.49-7.01,5.17Z"/>
      </g>
      <g>
        <path className="cls-103" d="m455.92,4209.27l-.17-.3c.31-.18,7.34-4.28,9.85-5.01-.38-.62-2.17-2.9-9.08-6.05l.14-.31c8.28,3.77,9.31,6.3,9.35,6.41l.07.18-.19.04c-2.25.52-9.9,4.99-9.97,5.03Z"/>
        <path className="cls-103" d="m471.41,4207.69c-3.47-2.47-5.6-3.45-5.62-3.46l-.08-.04v-.09c-.74-4.75-2.24-7.51-2.26-7.54l.3-.17s1.53,2.8,2.27,7.57c.43.21,2.48,1.24,5.6,3.46l-.2.28Z"/>
        <path className="cls-103" d="m467.78,4212.84l-.34-.02s.16-3.92-1.74-8.69l.32-.13c1.93,4.84,1.77,8.79,1.77,8.83Z"/>
      </g>
      <g>
        <path className="cls-103" d="m467.06,4169.42c-.5,0-.72-.08-.75-.09l-.15-.06.07-.15c.85-1.91,1.95-9.97,1.97-10.05l.3.04c-.04.32-1.07,7.76-1.92,9.96.61.09,3.05.17,8.8-2.88l.14.27c-4.79,2.55-7.32,2.95-8.45,2.95Z"/>
        <path className="cls-103" d="m472.54,4172.21s-2.66-.57-6.19-2.84c-.38.19-2.15,1.04-5.24,1.94l-.08-.29c3.47-1.01,5.25-1.94,5.26-1.95l.08-.04.07.05c3.52,2.28,6.14,2.84,6.16,2.84l-.06.3Z"/>
        <path className="cls-103" d="m466.31,4169.34c-4.22-1.48-6.74-3.92-6.77-3.95l.21-.22s2.5,2.42,6.66,3.88l-.1.28Z"/>
      </g>
      <g>
        <path className="cls-103" d="m356.99,4169.35c-.98,0-3.25-.4-7.57-3.02l.15-.24c5.06,3.07,7.25,3.03,7.79,2.95-.69-2.13-1.36-9.38-1.39-9.69l.28-.03c0,.08.73,7.91,1.43,9.77l.05.13-.13.05s-.21.07-.6.07Z"/>
        <path className="cls-103" d="m351.9,4171.95l-.06-.28s2.39-.5,5.62-2.64l.07-.05.08.05s1.58.95,4.66,1.99l-.09.27c-2.72-.92-4.28-1.77-4.63-1.97-3.25,2.14-5.55,2.62-5.65,2.64Z"/>
        <path className="cls-103" d="m357.59,4169.28l-.09-.27c3.78-1.33,6.08-3.61,6.1-3.63l.2.2s-2.37,2.34-6.21,3.7Z"/>
      </g>
      <g>
        <path className="cls-103" d="m366.54,4154.05c-.72,0-2.48-.42-5.67-3.46l.18-.18c3.59,3.42,5.29,3.43,5.69,3.37-.28-2.18.13-9.89.15-10.22h.26c0,.1-.44,8.31-.13,10.3l.02.1-.09.04s-.14.06-.39.06Z"/>
        <path className="cls-103" d="m362.13,4156.57l-.05-.25s1.93-.43,4.73-2.54l.09-.07.08.08s1.11,1.05,3.38,2.25l-.12.23c-1.98-1.05-3.07-1.96-3.36-2.22-2.81,2.1-4.67,2.51-4.75,2.53Z"/>
        <path className="cls-103" d="m366.93,4154l-.1-.24c3.12-1.25,5.22-3.54,5.24-3.57l.19.17s-2.15,2.36-5.33,3.63Z"/>
      </g>
      <g>
        <path className="cls-103" d="m379.8,4140.25s-4.65-3.11-6.02-3.54l-.11-.03.04-.1c.02-.06.67-1.56,5.75-3.47l.07.19c-4.23,1.59-5.34,2.9-5.58,3.26,1.52.56,5.76,3.4,5.95,3.52l-.12.17Z"/>
        <path className="cls-103" d="m370.4,4138.56l-.11-.18c1.91-1.2,3.16-1.73,3.44-1.84.5-2.87,1.45-4.48,1.46-4.5l.18.11s-.95,1.63-1.44,4.49v.06s-.07.02-.07.02c-.01,0-1.32.5-3.45,1.84Z"/>
        <path className="cls-103" d="m372.56,4141.87s-.06-2.44,1.16-5.3l.19.08c-1.21,2.82-1.15,5.19-1.15,5.21h-.21Z"/>
      </g>
      <g>
        <path className="cls-103" d="m451.26,4142.5c-.4-4.4-1.25-5.89-1.5-6.25-.83,1.18-4.15,3.96-4.3,4.08l-.12-.15s3.67-3.07,4.33-4.15l.06-.1.09.08s1.16,1.17,1.63,6.46l-.19.02Z"/>
        <path className="cls-103" d="m453.17,4139.13s-1.16-1.46-3.47-2.95l-.04-.03v-.05s-.13-1.38-.79-3.8l.18-.05c.59,2.19.76,3.53.79,3.79,2.31,1.5,3.47,2.95,3.48,2.97l-.15.12Z"/>
        <path className="cls-103" d="m449.69,4136.16c-2.11-2.12-4.13-2.94-4.15-2.95l.07-.18s2.07.84,4.21,2.99l-.14.14Z"/>
      </g>
      <g>
        <path className="cls-103" d="m456.44,4153.51c-.72,0-2.48-.42-5.67-3.46l.18-.18c3.59,3.42,5.29,3.43,5.69,3.37-.28-2.18.13-9.89.15-10.22h.25c0,.1-.44,8.31-.13,10.3l.02.1-.09.04s-.14.06-.39.06Z"/>
        <path className="cls-103" d="m452.03,4156.02l-.05-.25s1.93-.43,4.73-2.54l.09-.07.08.08s1.11,1.05,3.38,2.25l-.12.23c-1.98-1.05-3.07-1.96-3.36-2.22-2.81,2.1-4.67,2.51-4.75,2.53Z"/>
        <path className="cls-103" d="m456.83,4153.45l-.09-.24c3.12-1.25,5.22-3.54,5.24-3.57l.19.17s-2.15,2.36-5.33,3.63Z"/>
      </g>
      <g>
        <path className="cls-38" d="m352.94,4200.79c1.57.49,3.14.77,4.61.77,3.32,0,5.9-1.5,5.9-5.48v-.52s-.73.35-.73.35c-1.5.66-3.14,1.67-5.59,1.67-3.63,0-6.18-2.2-6.18-6.7v-1.68c0-6.07,4.37-9.63,9.78-9.63h7.05s0,16.55,0,16.55c0,6.25-4.44,9.11-9.74,9.11-1.64,0-3.39-.28-5.1-.8v-3.63Zm10.51-8.48v-9.11s-2.48,0-2.48,0c-3.63,0-5.69,2.16-5.69,6v1.26c0,2.41,1.05,3.39,2.93,3.39,1.64,0,3.21-.56,4.54-1.19l.7-.35Z"/>
        <path className="cls-38" d="m370.22,4180.27c1.82-.49,3.56-.7,5.31-.7h1.64s0,3.42,0,3.42c1.22-1.88,3.07-3.52,5.52-3.52h1.36s0,4.05,0,4.05h-1.71c-2.51,0-3.77,1.36-5.2,2.86v12.29s-4.33,0-4.33,0v-15.57c-.87.1-1.74.24-2.58.42v-3.25Zm6.91,2.72v.07s.04-.07.04-.07h-.04Z"/>
        <path className="cls-38" d="m384.39,4188.97c0-6.18,3.11-9.67,8.97-9.67,6.14,0,8.97,3.49,8.97,9.67,0,6.7-2.93,9.98-8.97,9.98-6.04,0-8.97-3.28-8.97-9.99Zm13.61,0c0-3.91-1.15-5.93-4.64-5.94-3.49,0-4.64,2.02-4.64,5.93,0,3.91,1.15,6.25,4.64,6.25,3.49,0,4.64-2.34,4.64-6.25Z"/>
        <path className="cls-38" d="m404.77,4191.24v-3.74c0-5.31,3.84-8.2,8.87-8.2,4.22,0,7.43,1.57,7.43,5.8,0,3.7-2.58,5.51-6.49,5.51h-5.48s0,.7,0,.7c0,2.93,2.09,3.91,4.71,3.91,2.09,0,4.29-.56,5.65-1.05h.28s0,3.63,0,3.63c-1.47.52-4.19,1.15-6.18,1.15-5.1,0-8.8-2.2-8.79-7.72Zm4.33-3.52h4.82c2.23,0,3.04-.84,3.04-2.48,0-1.85-1.4-2.3-3.32-2.31-2.86,0-4.54,1.29-4.54,4.5v.28Z"/>
        <path className="cls-38" d="m431.92,4197.99c-1.78.49-3.53.7-5.31.7h-1.64s0-15.46,0-15.46l.14-.11c-.91.07-1.85.21-2.76.42v-3.25c1.82-.49,3.56-.7,5.31-.7h1.64s0,15.57,0,15.57c.87-.07,1.75-.17,2.62-.42v3.25Zm-6.94-25.48h4.33s0,3.91,0,3.91h-4.33s0-3.91,0-3.91Z"/>
        <path className="cls-38" d="m433.32,4191.25v-3.73c0-5.31,3.84-8.2,8.87-8.2,4.22,0,7.43,1.57,7.43,5.8,0,3.7-2.58,5.51-6.49,5.51h-5.48s0,.7,0,.7c0,2.93,2.09,3.91,4.71,3.91,2.09,0,4.29-.56,5.66-1.05h.28s0,3.63,0,3.63c-1.47.52-4.19,1.15-6.18,1.15-5.1,0-8.8-2.2-8.79-7.72Zm4.33-3.52h4.82c2.23,0,3.04-.84,3.04-2.48,0-1.85-1.4-2.3-3.32-2.3-2.86,0-4.54,1.29-4.54,4.5v.28Z"/>
        <path className="cls-38" d="m450.63,4180.29c1.82-.49,3.56-.7,5.31-.7h1.64s0,3.28,0,3.28l1.15-1.01c1.64-1.5,3.32-2.55,5.79-2.55,3.28,0,5.31,1.92,5.3,6.35v9.49c.87-.07,1.78-.17,2.65-.42v3.25c-1.82.49-3.56.7-5.31.7h-1.64s0-12.36,0-12.36c0-2.44-.94-3.28-2.48-3.28-1.57,0-3.25,1.47-4.36,2.44l-1.12.91v12.29s-4.33,0-4.33,0v-15.57c-.87.1-1.78.24-2.61.42v-3.25Z"/>
      </g>
      <path className="cls-84" d="m422.17,4226.39s-3.33-1.46-7.09,2.2c-3.77,3.66-4.3,8.95-4.3,8.95,0,0,3.42-1.27,4.44-1.18,1.02.09,1.37.26,1.11,1.2s-.87,4.27,5.26,12.06c0,0,5.82-3.48,7.62-8.35,0,0-.6-.94,3.07,1.55,3.66,2.49,11.85,5.76,14.93,5.59,0,0-.82-12.38-11.73-20.02,0,0,6.66-.07,8.81-4.76,0,0-5.88-4.88-13.56-5.33l-2.13-2.14s-7.52.92-6.43,10.23Z"/>
      <path className="cls-103" d="m422.26,4246.04l-.34-.02c.71-14.41,4.28-16.84,4.43-16.93l.13-.09.09.13c2.46,3.45,15.34,14.23,15.47,14.34l-.21.26c-.52-.44-12.71-10.64-15.43-14.26-.63.58-3.54,3.89-4.16,16.57Z"/>
      <path className="cls-103" d="m415.19,4235.41l-.23-.25s3.72-3.34,11.32-6.05c.05-.67.37-4.3,2.02-10.06l.32.09c-1.82,6.34-2.01,10.06-2.02,10.1v.11s-.11.04-.11.04c-7.6,2.69-11.27,5.98-11.31,6.02Z"/>
      <path className="cls-103" d="m426.54,4229.37l-.19-.28c6.89-4.63,13.7-5.63,13.77-5.64l.05.33c-.07,0-6.8,1-13.63,5.58Z"/>
      <path className="cls-84" d="m374.72,4216.96c1-.75,5.54-3.78,10.86-1.42.09.04.14.12.15.22.03.42-.01,1.63-1.1,3.35-.06.1-.05.22.03.31.61.61,2.96,3.46,2.49,10.33,0,.12-.11.23-.23.23-.86.05-4.17,0-7.32-2.94-.13-.12-.34-.08-.4.09-.57,1.61-3.3,8.25-10.62,12.64-.11.07-.26.04-.33-.07-.69-.99-3.72-5.87-1.64-13.1.04-.14-.04-.29-.19-.31-1.43-.26-8.28-1.53-10.69-2.54-.12-.05-.17-.17-.14-.29.32-1.09,2.11-6.18,7.6-7.18.14-.03.24-.16.2-.3-.15-.56-.66-1.92-2.26-2.84-.18-.1-.16-.36.03-.44,1.85-.74,8.67-2.82,13.23,4.2.08.12.24.15.35.07Z"/>
      <path className="cls-103" d="m369.33,4236.7l-.33-.07c.13-.57,3.08-13.75,4.87-17.31-.82-.25-4.92-1.01-15.62,3.63l-.13-.31c12.29-5.33,15.92-3.64,16.07-3.57l.16.08-.08.15c-1.75,3.24-4.9,17.26-4.93,17.4Z"/>
      <path className="cls-103" d="m374.09,4219.42l-.08-.06c-5.67-4.59-10.06-6-10.11-6.01l.1-.32s4.45,1.42,10.14,6.01c.57-.23,3.73-1.47,9.28-2.58l.07.33c-6.09,1.22-9.27,2.58-9.3,2.6l-.09.04Z"/>
      <path className="cls-103" d="m384.83,4227.29s-3.9-4.68-10.79-7.92l.14-.3c6.96,3.27,10.87,7.96,10.91,8.01l-.26.21Z"/>
    </g>
    <g id="Hans_Butselaar" data-name="Hans Butselaar">
      <g id="Flower">
        <polygon className="cls-84" points="326.27 4142.82 308.08 4217.23 304.83 4215.86 323.54 4145.05 326.27 4142.82"/>
        <path className="cls-84" d="m324.21,4141.57s11.51-3.44,17.52,2.4c0,0-1.07,5.46-6.99,5.54s-11.2-4.47-11.2-4.47l.67-3.48Z"/>
        <path className="cls-84" d="m321.87,4139.85s-6.89-11.88-23.55-3.38c0,0,9.65,11.58,21.46,8.82l2.08-5.44Z"/>
        <path className="cls-84" d="m322.93,4142.01s-9.08-1.89-11.04,11.85c-1.96,13.75-2.34,11.74-2.34,11.74,0,0,24.22-5.37,16.56-21.58l-3.18-2.01Z"/>
        <polygon className="cls-70" points="328.34 4128.26 331.79 4125.53 330.46 4128.25 333.68 4128.69 331.08 4130.75 334.79 4132.85 331.38 4134.19 334.72 4137.45 331.97 4137.79 335.15 4141.81 331.66 4142 332.51 4147.08 328.82 4142.43 326.63 4148.26 324.81 4145.01 320.99 4148.03 319.64 4143.65 315.28 4144.84 315.59 4141.41 311.37 4139.91 314.34 4137.55 311.24 4135.16 314.25 4135.8 311.94 4130.51 315.2 4131.66 314.11 4127.89 317.16 4128.49 317.55 4125.16 320.21 4126.98 321.75 4123.19 322.65 4126.86 326.51 4122.89 326.94 4126.29 329.63 4124.15 328.34 4128.26"/>
        <circle className="cls-23" cx="324.07" cy="4135.98" r="5.02"/>
      </g>
      <polygon className="cls-98" points="249.6 4297.13 249.92 4303.48 255.06 4305.47 257.2 4301.35 256.45 4296.38 249.6 4297.13"/>
      <g className="cls-43">
        <g>
          <circle className="cls-114" cx="251.33" cy="4298.65" r=".7"/>
          <circle className="cls-114" cx="256.5" cy="4298.25" r=".7"/>
          <circle className="cls-114" cx="254.36" cy="4299.64" r=".7"/>
          <circle className="cls-114" cx="251.33" cy="4301.11" r=".7"/>
          <circle className="cls-114" cx="255.75" cy="4302.14" r=".7"/>
        </g>
      </g>
      <polygon className="cls-98" points="229.44 4296.38 229.69 4302.66 232.26 4305.9 236.04 4303.3 237.65 4296.38 229.44 4296.38"/>
      <g className="cls-124">
        <g>
          <circle className="cls-114" cx="231.28" cy="4297.55" r=".7"/>
          <circle className="cls-114" cx="235.72" cy="4299.35" r=".7"/>
          <circle className="cls-114" cx="232.69" cy="4300.99" r=".7"/>
          <circle className="cls-114" cx="234.03" cy="4302.83" r=".7"/>
          <circle className="cls-114" cx="229.97" cy="4300.29" r=".7"/>
        </g>
      </g>
      <polygon className="cls-114" points="260.13 4239.28 260.13 4224.84 227.05 4223.28 227.05 4236.18 228.92 4299.35 237.73 4299.35 242.84 4238.91 245.44 4242.83 249.21 4299.35 257.24 4299.35 260.13 4239.28"/>
      <path className="cls-56" d="m285.66,4193.68l25.25-8.68-4.66-9.52-18.89,6.93-21.23-6.88c-6.52-2.78-15.27-5.83-15.27-5.83l-1.47-2.69-6.28,5.1-6.52-5.64-1.44,2.7s-14.33,2.75-17.68,6.36c-5.24,5.65-3.37,44.57-3.37,44.57l10.66,3.44-1.27,9.24s8.39,3.05,13.58,1.41c3.56-1.12,5.3-6.42,6.04-9.66l2.62.08c.03,3.34.46,9.05,3.35,10.06,3.99,1.39,13.38-2.83,13.38-2.83l-1.67-14.18,1.55-30.35,23.32,6.39Zm-60.51,4.87l.92,12.34-2.35-.89,1.43-11.45Z"/>
      <path className="cls-76" d="m236.78,4169.7c0,3.22,12.65,3.22,12.65,0v-8.34h-12.65v8.34Z"/>
      <path className="cls-76" d="m256.96,4146.39c-.69,2.6,0,5.04,1.54,5.45,1.54.41,3.36-1.36,4.05-3.96.69-2.6,0-5.04-1.54-5.45-1.54-.41-3.36,1.36-4.05,3.95Z"/>
      <path className="cls-76" d="m228.94,4146.39c.69,2.6,0,5.04-1.54,5.45-1.54.41-3.36-1.36-4.05-3.96-.69-2.6,0-5.04,1.54-5.45,1.54-.41,3.36,1.36,4.05,3.95Z"/>
      <ellipse className="cls-76" cx="242.97" cy="4147.1" rx="15.79" ry="19.79"/>
      <path className="cls-76" d="m235.29,4220.76c-.43-2.07,1.22-3.5,3.12-3.62.06,0,.11,0,.17,0,1.15-.07,2.05.85,2.62,1.71.32.5,1.04,1.24,1.67,1.38.04-.02.09-.03.14-.05.24-.09.48-.18.72-.27.66-.25,1.32-.43,1.97-.62.61-.17,1.14.23,1.45.7,1.82,2.76-1.98,5.84-4.5,5.97-2.93.15-6.75-2.2-7.37-5.19Z"/>
      <path className="cls-76" d="m319.06,4177.57c-.21,1.35-1.44,2.28-2.63,2.96-2.34,1.35-4.88,2.34-7.51,2.93-.15.03-.3.06-.44.02-.21-.07-.34-.28-.44-.48-.68-1.41-.88-3.07-.46-4.59.42-1.52,1.46-2.88,2.85-3.62.85-.45,1.81-.67,2.77-.81,1.33-.18,2.73-.19,3.93.39,1.2.58,2.13,1.87,1.93,3.19Z"/>
      <polygon className="cls-38" points="237.66 4215.26 233.35 4226.59 228.06 4224.6 232.1 4213.75 237.66 4215.26"/>
      <path className="cls-69" d="m236.59,4175.56s-.42,16.75,1.3,20.03c1.72,3.29,6.1,13.46,6.1,13.46,0,0,4.54-5.17,5.63-10.8,1.1-5.63-.47-25.71-.47-25.71l-6.05-1.28-6.52,4.3Z"/>
      <polygon className="cls-38" points="236.59 4166.46 236.59 4178.23 243.1 4172.1 249.43 4178.63 249.38 4167 243.1 4171.26 236.59 4166.46"/>
      <path className="cls-107" d="m243.24,4172.75l-3.89,2.87,3.07,1.04s-1.72,7.51-1.56,14.24c.16,6.73.44,12.02.44,12.02l2.69,6.14s4.33-5,4.61-7.51c.28-2.52-5.32-24.77-5.32-24.77l1.96-1.68-2-2.35Z"/>
      <path className="cls-80" d="m229.62,4301.43l2.19,2.12s2.74-.12,3.66-1.54c.91-1.41,1.66-3.48,1.66-3.48,0,0,.66,1.07.17,3.4-.5,2.33-1,5.23-1.5,8.64-.5,3.41-6.08,13.92-6.15,11.31-.08-3-4.57-8.23-4.57-8.23l4.54-12.22Z"/>
      <path className="cls-80" d="m249.41,4301.41l-.22,4.57,7.4,3.15s.37,2.16,2.34,3.39c1.98,1.23,12.09,1.18,12.46.84.37-.34.81-1.19.81-1.19,0,0-2.2-.62-3.52-1.28-1.32-.66-11.35-10.55-11.35-10.55l-2.87,3.52"/>
      <polygon className="cls-38" points="310.9 4185 306.54 4175.34 302 4177.03 306.17 4186.62 310.9 4185"/>
      <g>
        <path className="cls-114" d="m248.96,4147.46c-2.49,0-4.51-1.41-4.51-3.15s2.02-3.15,4.51-3.15,4.51,1.41,4.51,3.15-2.02,3.15-4.51,3.15Zm0-5.64c-2.13,0-3.85,1.12-3.85,2.49s1.73,2.49,3.85,2.49,3.85-1.12,3.85-2.49-1.73-2.49-3.85-2.49Z"/>
        <path className="cls-114" d="m236.97,4147.46c-2.49,0-4.51-1.41-4.51-3.15s2.02-3.15,4.51-3.15,4.51,1.41,4.51,3.15-2.02,3.15-4.51,3.15Zm0-5.64c-2.13,0-3.85,1.12-3.85,2.49s1.73,2.49,3.85,2.49,3.85-1.12,3.85-2.49-1.73-2.49-3.85-2.49Z"/>
      </g>
      <path className="cls-114" d="m244.54,4144.55c-.53-.53-1.08-.79-1.63-.77-.87.03-1.5.74-1.51.75l-.5-.43s.82-.93,1.98-.98c.74-.03,1.46.29,2.12.96l-.46.46Z"/>
      <rect className="cls-114" x="253.11" y="4143.55" width="5.41" height=".66" transform="translate(-658.43 94.02) rotate(-9.19)"/>
      <rect className="cls-114" x="229.69" y="4141.18" width=".66" height="5.41" transform="translate(-3897.44 3709.4) rotate(-80.81)"/>
    </g>
    <g id="Slingers">
      <path className="cls-17" d="m417.3,3965.03c-76.49,23.76-124.17-17.63-124.17-17.63,0,0-88.28,47.2-160.69,4.21,0,0-25.09,32.15-80.16,33.03"/>
      <circle className="cls-114" cx="389.05" cy="3988.72" r="18.2"/>
      <circle className="cls-23" cx="139.41" cy="3970.51" r="22.32"/>
      <circle className="cls-45" cx="259.01" cy="3967.73" r="9.22"/>
      <circle className="cls-45" cx="340.81" cy="3976.95" r="9.22"/>
      <circle className="cls-97" cx="276.68" cy="3966.02" r="12.75"/>
      <circle className="cls-97" cx="71.89" cy="3993.89" r="12.75"/>
    </g>
    <text transform="translate(157.47 3853.17)"><tspan className="cls-26"><tspan className="number" x="0" y="0">88</tspan></tspan><tspan className="cls-33"><tspan x="-36.89" y="18">nieuwe collega’s</tspan></tspan></text>
    <text transform="translate(477.83 4002.48)"><tspan className="cls-26"><tspan x="0" y="0">½</tspan></tspan><tspan className="cls-33"><tspan className="cls-145" x="-45.69" y="18">W</tspan><tspan x="-32.3" y="18">estblaak-locatie </tspan></tspan><tspan className="cls-33"><tspan x="-57.03" y="36">i.v.m. hybride werken</tspan></tspan></text>
    <text transform="translate(328.21 3853.21)"><tspan className="cls-24"><tspan className="number" x="0" y="0">50</tspan></tspan><tspan className="cls-33"><tspan x="-29.33" y="18">CJG-locaties</tspan></tspan></text>
    <g id="Locatie">
      <circle className="cls-23" cx="305.1" cy="3838.05" r="16.61"/>
      <path className="cls-38" d="m299.55,3835.79c-.88-2.08.09-4.49,2.17-5.37,2.08-.88,4.49.09,5.37,2.17.88,2.08-.09,4.49-2.17,5.37-2.08.88-4.49-.09-5.37-2.17m10.17,13.47c1.92-4.75,2.78-14.13,1.03-18.23-1.74-4.1-6.48-6.02-10.59-4.28-4.1,1.74-6.02,6.48-4.28,10.59,1.74,4.11,9.38,10.12,13.83,11.92"/>
    </g>
    <text transform="translate(88.05 4098.91)"><tspan className="cls-24"><tspan className="number" x="0" y="0">103</tspan></tspan><tspan className="cls-33"><tspan x="-14.68" y="18">vacatures</tspan></tspan></text>
    <text transform="translate(117.96 4155.76)"><tspan className="cls-24"><tspan className="number" x="0" y="0">75</tspan></tspan><tspan className="cls-33"><tspan x="-56.34" y="18">klachten van ouders </tspan></tspan></text>
    <text transform="translate(578.13 4176.46)"><tspan className="cls-24"><tspan x="0" y="0">5</tspan></tspan><tspan className="cls-33"><tspan x="-45.76" y="18">roadshows met </tspan></tspan><tspan className="cls-33"><tspan x="-61.94" y="36">Programma CJG Pro</tspan></tspan></text>
    <text transform="translate(299.27 4298)"><tspan className="cls-24"><tspan x="0" y="0">12,5-jarig jubileum</tspan></tspan><tspan className="cls-33"><tspan x="57.29" y="18">De </tspan><tspan className="cls-143" x="78.4" y="18">W</tspan><tspan x="91.83" y="18">andeling </tspan></tspan></text>
    <text transform="translate(233.12 4075.9)"><tspan className="cls-24"><tspan x="0" y="0">15</tspan></tspan><tspan className="cls-33"><tspan x="-51.72" y="18">peuterconsulenten </tspan></tspan><tspan className="cls-33"><tspan x="-17.66" y="36">in dienst</tspan></tspan></text>
    <text id="_14_gemeenten_in_Raad_voor_het_Publiek_Belang" data-name="14 gemeenten in Raad voor het Publiek Belang" transform="translate(475.78 3911.55)"><tspan className="cls-25"><tspan className="number" x="0" y="0">648</tspan></tspan><tspan className="cls-33"><tspan x=".8" y="18">professionals</tspan></tspan></text>
    <g id="Titel-2" data-name="Titel">
      <rect id="Achtergrond-3" data-name="Achtergrond" className="cls-38" x="395.62" y="3801.65" width="284.92" height="55.7" rx="4.22" ry="4.22"/>
      <text className="cls-47" transform="translate(416.28 3843.49)"><tspan x="0" y="0">CJG Rijnmond</tspan></text>
    </g>
        </g>
        </>
    )
    }
    return (
      <>
      <g ref={mobileScreen} id="CJG" className="mobile-overview">
      <path id="Ondergrond" className="cls-91" d="m301.61,3921.17h-53.73c-12.53,0-22.69-10.16-22.69-22.69s9.84-22.36,22.09-22.68h69.89c12.68,0,22.31-10.28,22.31-22.96s-10.28-22.96-22.96-22.96h-22.06c9.04-1.07,16.06-8.75,16.06-18.08,0-10.06-8.16-18.22-18.22-18.22H87.24l.84,52.98H30.67c-5.12,0-9.27,4.15-9.27,9.27s4.15,9.27,9.27,9.27h.8c9,0,16.31,7.3,16.31,16.31s-7.3,16.31-16.31,16.31h-3.55c-7.15,0-12.94,5.79-12.94,12.94s5.79,12.94,12.94,12.94h50.68,0c11.7,0,21.18,9.48,21.18,21.18s-9.48,21.18-21.18,21.18h-24.71c-25.05,0-45.35,20.3-45.35,45.35s20.3,45.35,45.35,45.35v.62h191.16c12.53,0,22.69-10.16,22.69-22.69s-10.16-22.69-22.69-22.69h56.56c25.05,0,45.35-20.3,45.35-45.35s-20.3-45.35-45.35-45.35Z"/>
      <text transform="translate(37.96 3701.62)"><tspan className="cls-2"><tspan className="number" x="0" y="0">88</tspan></tspan><tspan className="cls-115"><tspan x="-5.83" y="16">nieuwe </tspan></tspan><tspan className="cls-115"><tspan x="-10.27" y="32">collega’s</tspan></tspan></text>
      <text transform="translate(247.69 3816)"><tspan className="cls-2"><tspan x="0" y="0">½</tspan></tspan><tspan className="cls-115"><tspan className="cls-131" x="-20.46" y="16">W</tspan><tspan x="-8.98" y="16">estblaak-</tspan></tspan><tspan className="cls-115"><tspan x="-24.79" y="32">locatie i.v.m. </tspan></tspan><tspan className="cls-115"><tspan x="-32.2" y="48">hybride werken</tspan></tspan></text>
      <g id="Gebouw_Westblaak" data-name="Gebouw Westblaak">
      <rect className="cls-79" x="53.27" y="3753.14" width="17.61" height="19.6"/>
      <polyline className="cls-48" points="28.26 3744.39 28.26 3846.55 211.91 3846.55 211.91 3744.39"/>
      <g>
          <g>
          <rect className="cls-73" x="55.31" y="3788.22" width="20.85" height="10.62"/>
          <rect className="cls-73" x="78.06" y="3788.22" width="20.85" height="10.62"/>
          </g>
          <g>
          <rect className="cls-73" x="108.86" y="3788.22" width="20.85" height="10.62"/>
          <rect className="cls-73" x="131.62" y="3788.22" width="20.85" height="10.62"/>
          </g>
          <g>
          <rect className="cls-73" x="161.58" y="3788.22" width="20.85" height="10.62"/>
          <rect className="cls-73" x="184.33" y="3788.22" width="20.85" height="10.62"/>
          </g>
          <rect className="cls-73" x="35.75" y="3788.22" width="10.17" height="10.62"/>
      </g>
      <g>
          <g>
          <rect className="cls-73" x="55.31" y="3752.46" width="20.85" height="10.62"/>
          <rect className="cls-73" x="78.06" y="3752.46" width="20.85" height="10.62"/>
          </g>
          <g>
          <rect className="cls-73" x="107.91" y="3752.46" width="20.85" height="10.62"/>
          <rect className="cls-73" x="130.66" y="3752.46" width="20.85" height="10.62"/>
          </g>
          <g>
          <rect className="cls-73" x="161.58" y="3752.46" width="20.85" height="10.62"/>
          <rect className="cls-73" x="184.33" y="3752.46" width="20.85" height="10.62"/>
          </g>
          <rect className="cls-73" x="35.75" y="3752.46" width="10.17" height="10.62"/>
      </g>
      <g>
          <g>
          <rect className="cls-41" x="35.75" y="3770.34" width="15.52" height="10.62"/>
          <rect className="cls-41" x="53.18" y="3770.34" width="20.85" height="10.62"/>
          </g>
          <g>
          <rect className="cls-41" x="81.42" y="3770.34" width="20.85" height="10.62"/>
          <rect className="cls-41" x="104.18" y="3770.34" width="20.85" height="10.62"/>
          </g>
          <g>
          <rect className="cls-73" x="134.77" y="3770.34" width="20.85" height="10.62"/>
          <rect className="cls-73" x="157.53" y="3770.34" width="20.85" height="10.62"/>
          </g>
          <rect className="cls-73" x="187.39" y="3770.34" width="17.79" height="10.62"/>
      </g>
      <g>
          <g>
          <rect className="cls-73" x="35.75" y="3806.1" width="15.52" height="10.62"/>
          <rect className="cls-73" x="53.18" y="3806.1" width="20.85" height="10.62"/>
          </g>
          <g>
          <rect className="cls-73" x="81.42" y="3806.1" width="20.85" height="10.62"/>
          <rect className="cls-73" x="104.18" y="3806.1" width="20.85" height="10.62"/>
          </g>
          <g>
          <rect className="cls-73" x="134.77" y="3806.1" width="20.85" height="10.62"/>
          <rect className="cls-73" x="157.53" y="3806.1" width="20.85" height="10.62"/>
          </g>
          <rect className="cls-73" x="187.39" y="3806.1" width="17.79" height="10.62"/>
      </g>
      </g>
      <text transform="translate(287.51 3768.77)"><tspan className="cls-5"><tspan className="number" x="0" y="0">50</tspan></tspan><tspan className="cls-115"><tspan x="-25.86" y="16">CJG-locaties</tspan></tspan></text>
      <g id="Locatie">
      <circle className="cls-41" cx="264.71" cy="3757.72" r="16.61"/>
      <path className="cls-57" d="m259.16,3755.47c-.88-2.08.09-4.49,2.17-5.37,2.08-.88,4.49.09,5.37,2.17.88,2.08-.09,4.49-2.17,5.37-2.08.88-4.49-.09-5.37-2.17m10.17,13.47c1.92-4.75,2.78-14.13,1.03-18.23-1.74-4.1-6.48-6.02-10.59-4.28-4.1,1.74-6.02,6.48-4.28,10.59,1.74,4.11,9.38,10.12,13.83,11.92"/>
      </g>
      <text transform="translate(41.63 3942.53)"><tspan className="cls-5"><tspan className="number" x="0" y="0">103</tspan></tspan><tspan className="cls-115"><tspan x="-13.66" y="16">vacatures</tspan></tspan></text>
      <text transform="translate(55.73 4004.37)"><tspan className="cls-5"><tspan className="number" x="0" y="0">75</tspan></tspan><tspan className="cls-115"><tspan x="-27.49" y="16">klachten van </tspan></tspan><tspan className="cls-115"><tspan x="-9.92" y="32">ouders </tspan></tspan></text>
      <text transform="translate(287.99 3952.83)"><tspan className="cls-5"><tspan x="0" y="0">5</tspan></tspan><tspan className="cls-115"><tspan x="-39.58" y="16">roadshows met </tspan></tspan><tspan className="cls-115"><tspan x="-29.89" y="32">Programma </tspan></tspan><tspan className="cls-115"><tspan x="-16.96" y="48">CJG Pro</tspan></tspan></text>
      <g id="Professionals">
      <path className="cls-41" d="m250.86,3899.44h-5.9c-2.14,0-3.89-1.75-3.89-3.89h0c0-2.14,1.75-3.89,3.89-3.89h5.9c2.14,0,3.89,1.75,3.89,3.89h0c0,2.14-1.75,3.89-3.89,3.89Z"/>
      <path className="cls-48" d="m254.12,3922.87l-1.66-8.66-12.53-.41-.31,7.18c.56,9.7,3.18,17.68,4.39,26.21l1.99-1.98,1.51-22.04-.62,23.41h2.06c2.17-8.77,4.34-14.56,5.16-23.72Z"/>
      <path className="cls-41" d="m249.64,3943.84c-.23.91-.45,1.82-.68,2.74h-2.06l.07-2.74h2.67Z"/>
      <path className="cls-41" d="m246.17,3943.84l-.42,1.77-1.73,1.58c-.15-1.09-.33-2.21-.52-3.35h2.67Z"/>
      <path className="cls-43" d="m236.65,3922.76c-.61.07-1.23-.02-1.81-.24-.03,0-.05-.02-.08-.03,0,0-.01,0-.02,0-.05,0-.1,0-.15,0l-.46,3.01c-.11.73.23,1.46.87,1.83l.38.23c.51.3,1.16-.06,1.17-.65l-.17-3.49.26-.64Z"/>
      <path className="cls-43" d="m245.65,3900.25c.12.03.23.06.34.08,3.21.66,6.24-1.75,6.47-5.01l.43-5.27c0-3.14-2.5-5.75-5.64-5.8-.12,0-.23,0-.35,0-3.16,0-5.66,2.56-5.6,5.71.04,2.12-1.19,3.98-1.04,5.81.19,2.28,3.16,3.91,5.38,4.48Z"/>
      <path className="cls-11" d="m253.25,3906.83l-.78,7.38-12.66.18.81-3.59-1.24,5.52-2.64,6.45h-2.17l.08-.51,1.64-8.06,1.49-9.6c.17-1.83,1.36-3.43,3.1-4.02.23-.08.45-.13.66-.16l5.55-.72,4.62.98"/>
      <path className="cls-41" d="m252.81,3949.07c.18-.07.32-.22.41-.38.31-.56.12-1.29-.28-1.79-.4-.5-.98-.82-1.54-1.13-.66-.36-1.33-.73-2.05-.96-.72-.23-1.5-.32-2.23-.12-.28.46-.18,1.07.11,1.53.29.45.75.77,1.2,1.06,1.13.71,2.34,1.29,3.61,1.73.25.09.52.17.77.07Z"/>
      <path className="cls-41" d="m240.31,3948.95c-.18-.07-.32-.22-.41-.38-.31-.56-.12-1.29.28-1.79.4-.5.98-.82,1.54-1.13.66-.36,1.33-.73,2.05-.96.72-.23,1.5-.32,2.23-.12.28.46.18,1.07-.11,1.53-.29.45-.66,1.07-1.2,1.06-.77-.02-2.24,1.07-3.61,1.73-.24.11-.52.17-.77.07Z"/>
      <path className="cls-41" d="m244.15,3882.68c-2.93-.96-4.87,2.04-5.63,4.41-1.36,4.25-.98,9.03,1.03,13.01.12.23.24.46.44.63.25.21.58.3.9.37,1.05.24,2.12.41,3.19.51-1.6-2.55-2.39-5.59-2.33-8.6.07-3.18.95-5.52,3.12-7.8.05-.05,2.03.91,2.24,1,1.16.5,2.18,1.25,3.18,2.01.87.66,1.3,1.11,1.69,2.14.35.92.59,1.89.71,2.86.12.98.12,1.97,0,2.95-.08.68-.04,1.36-.13,2.03-.04.29-.42.99-.63,1.07,1.01-.4,1.99.47,2.9-.13.46-.3.92-.64,1.19-1.11.34-.6.33-1.32.31-2.01-.09-2.91-.3-5.88-1.37-8.59-1.39-3.52-4.31-6.28-8.24-6.17-1.04.03-2,.55-2.59,1.41Z"/>
      <path className="cls-43" d="m245.1,3901.41c0,1.42,3.84,1.42,3.84,0v-3.67h-3.84v3.67Z"/>
      <path className="cls-43" d="m255.85,3922.72c.61.07,1.23-.02,1.81-.24.03,0,.05-.02.08-.03,0,0,.01,0,.02,0,.05,0,.1,0,.15,0l.46,3.01c.11.73-.23,1.46-.87,1.83l-.38.23c-.51.3-1.16-.06-1.17-.65l.17-3.49-.26-.64Z"/>
      <path className="cls-11" d="m251.88,3910.76l1.24,5.52,2.64,6.45h2.17s-.08-.51-.08-.51l-1.64-8.06-1.49-9.6c-.17-1.83-1.31-3.33-3.05-3.92"/>
      <polyline className="cls-11" points="240.03 3914.17 252.68 3914.35 251.88 3910.76"/>
      <path className="cls-41" d="m240.89,3901.1c1.05.24,2.12.41,3.19.51-1.6-2.55-2.39-5.59-2.33-8.6.07-3.18.95-5.52,3.12-7.8l-4.82.02"/>
      <ellipse className="cls-43" cx="253.22" cy="3892.74" rx="2.17" ry="1.02" transform="translate(-3646.5 3991.41) rotate(-87.73)"/>
      <path className="cls-102" d="m283.12,3946.86c.09.16.1.35.06.53-.12.6-.71,1.01-1.3,1.13-.6.12-1.21,0-1.81-.13-.7-.14-1.41-.29-2.07-.56-.66-.28-1.28-.69-1.69-1.28.08-.5.53-.89,1.02-1.04.49-.15,1.01-.1,1.52-.03,1.26.18,2.49.5,3.67.96.23.09.48.2.6.42Z"/>
      <path className="cls-102" d="m266.94,3949.16c-.3-.15-.32-.39-.33-.83,0-.24,0-.5.13-.71.07-.13.18-.23.29-.33.68-.63,1.32-1.02,2.22-1.31.68-.22,1.43-.3,2.12-.12.55.15-.13,1.88-.32,2.1-.22.25-.49.45-.78.61-.15.08-.32.15-.48.22-.34.14-.67.28-1.03.36-.45.1-1.21.21-1.66.05-.06-.02-.11-.04-.15-.06Z"/>
      <path className="cls-42" d="m265.79,3912.83s.34,18.9.46,19.36l1.13,14.02,5.24.28c.33-4.7.58-13.11.28-15.47l1.09-14.06c3.05-.06-8.2-4.13-8.2-4.13Z"/>
      <path className="cls-42" d="m273.11,3912.56l5.8-.04h1.56s-1.21-.38-1.21-.38l1.23.71c.16,2.47-.95,5.97-.95,5.97l-.29,10.32c1.35,12.06,2.54,16.95-.42,16.62l-3.72,1.24-1.57-15.61-1.27-19.35.84.52Z"/>
      <path className="cls-82" d="m266.96,3897.87c-4.39,3.34-4.12,8.86-4.81,13.81-.06.45.24-.87.59-.51,4.52,4.55,5.46-8.57,5.46-8.57l-1.24-4.73Z"/>
      <path className="cls-82" d="m277.48,3903.07c.29-5.13-6.94-5.39-6.94-5.39l-3.57.2c-3.45,4.09-.76,12.49-1.75,19.89,1.34,2.86,10.84-.96,14.7-.89,0,0-2.63-10.3-2.43-13.8Z"/>
      <path className="cls-87" d="m263.68,3919.28s1.65-5.11,1.12-7.82c-.53-2.71-2.26-.66-2.6-.02-.35.64-.28,6.98-.28,6.98l1.76.86Z"/>
      <path className="cls-87" d="m263.84,3917.81s1.56,2.21.1,2.84c-1.46.63-2.5-1.34-2.22-2.07.28-.73,2.11-.76,2.11-.76Z"/>
      <path className="cls-82" d="m273.27,3899.99c-1.01,5.43,2.93,9.02,5.74,13.15.26.38-.41-.8.1-.77,6.4.34-2.36-11.99-2.36-11.99l-3.48-.38Z"/>
      <path className="cls-87" d="m281.61,3918.84s.59-5.33-.47-7.89c-1.06-2.55-2.34-.2-2.56.5-.21.7,1.13,6.89,1.13,6.89l1.9.49Z"/>
      <path className="cls-87" d="m281.6,3917.47s1.48,2.26,0,2.84c-1.48.58-2.45-1.43-2.14-2.15.31-.72,2.14-.69,2.14-.69Z"/>
      <g id="Contact">
          <path className="cls-41" d="m343,3874.58c-.03-.2-.07-.4-.12-.59-.05-.19-.11-.38-.18-.57-.07-.19-.15-.37-.23-.54-.09-.18-.18-.35-.28-.52-.15-.25-.32-.49-.51-.71-.12-.15-.25-.29-.39-.43-1.1-1.1-2.62-1.78-4.29-1.78h-51.83c-1.67,0-3.19.68-4.29,1.78-.14.14-.27.28-.39.43-.19.23-.36.46-.51.71-.1.17-.2.34-.28.52-.09.18-.16.36-.23.54-.07.19-.13.38-.18.57-.05.19-.09.39-.12.59-.05.3-.07.61-.07.92v14.49c0,.31.02.62.07.92.03.2.07.4.12.59.08.29.17.57.29.84.19.45.44.88.73,1.27.12.16.24.31.37.45.13.14.27.28.41.41,1.08.98,2.51,1.58,4.08,1.58h.55l-1.13,5c-.01.06-.02.11-.02.17,0,.01,0,.02,0,.03,0,.05,0,.1.02.15,0,0,0,.02,0,.02.01.04.03.09.05.13,0,0,0,.01,0,.02.02.04.05.08.08.12,0,0,.01.02.02.02.03.04.07.07.1.1,0,0,0,0,0,0,.04.03.08.05.12.08,0,0,.02,0,.03.01.04.02.09.04.14.05,0,0,.02,0,.03,0,.04,0,.09.02.14.02,0,0,.01,0,.02,0,.05,0,.1,0,.15-.02.01,0,.02,0,.03,0,.05-.01.1-.03.15-.06l10.39-5.86h40.96c1.57,0,3-.6,4.08-1.58.14-.13.28-.27.41-.41.13-.14.25-.29.37-.45.29-.39.54-.82.73-1.27.12-.27.21-.55.29-.84.05-.19.09-.39.12-.59.05-.3.07-.61.07-.92v-14.49c0-.31-.02-.62-.07-.92Z"/>
      </g>
      <text className="cls-60" transform="translate(287.49 3886.12)"><tspan x="0" y="0">Ik ben pro</tspan></text>
      <path className="cls-86" d="m268.43,3899.14c0,1.42,3.84,1.42,3.84,0v-3.67h-3.84v3.67Z"/>
      <path className="cls-86" d="m274.71,3890.72c0,3.92-.46,6.82-4.87,6.82s-5.32-3.18-5.32-7.1,2.38-7.1,5.32-7.1,4.87,3.45,4.87,7.37Z"/>
      <path className="cls-86" d="m264.93,3890.46c.24.9,0,1.75-.53,1.89-.53.14-1.16-.47-1.4-1.37-.24-.9,0-1.75.53-1.89.54-.14,1.16.47,1.4,1.37Z"/>
      <path className="cls-86" d="m274.64,3890.46c-.24.9,0,1.75.53,1.89.54.14,1.16-.47,1.4-1.37.24-.9,0-1.75-.53-1.89s-1.16.47-1.4,1.37Z"/>
      <path className="cls-48" d="m274.47,3885.72v1.74h-.21v4.72c.01,1.2-.31,2.38-.93,3.41l-1.82.16-2.96.12-1.89-.1-1.55-1.75v-6.56h-.65c-.02.2-.04.41-.04.62v6.72c0,1.23.84,2.31,2.03,2.61,2.22.56,4.41.56,6.56,0,1.19-.3,2.01-1.38,2.01-2.6v-6.73c0-.85-.2-1.65-.56-2.37Z"/>
      <path className="cls-48" d="m272.24,3883.41h0c1.92-.63,3.01-.05,3.01,3.32l-.23,5.38-1.06-3.99-1-2.55-6.49,1.26c-.74.14-1.27.79-1.27,1.54h0l-.79,3.73-.79-4.02c-1.42-6.93,3.2-6.11,6.56-5.85.83.06,1.58.49,2.05,1.18Z"/>
      <g>
          <path className="cls-57" d="m250.02,3904.94c-.14-.08-.22-.18-.22-.31s.07-.23.13-.28c.2-.2.45-.22.53-.22.07,0,.14,0,.22.03.17.04.35-.21.35-.45,0-.21-.13-.4-.34-.47-.07-.03-.15-.04-.22-.04-.33,0-.72.22-.72.56,0,.31.24.37.35.41.02,0,.02.02,0,.03-.82.14-1.18.49-1.18.73,0,.15.21.37.31.48.03.03.04.01.06-.01.1-.09.32-.28.64-.37.03,0,.15-.04.09-.07Zm.91-.65s-.04,0-.02.03c.03.04.08.1.08.19s-.05.17-.11.24c-.15.15-.34.18-.47.21.1-.01.2,0,.29.05.19.05.39.13.52.26.13.13.17.27.08.48-.02.04-.06.12-.06.14,0,0,0,.01.01.01.03,0,.05-.01.09-.05.22-.22.28-.57.28-.73,0-.44-.5-.82-.69-.84Z"/>
          <path className="cls-57" d="m250.98,3905.77c-.2-.2-.39-.17-.43-.21,0,0,0,0,0,0,0,0,0,0,0,0,.02-.02.14-.04.23-.13.04-.04.08-.1.08-.17,0-.16-.16-.24-.17-.25-.09-.05-.19-.06-.29-.05-.2.02-.41.13-.41.33,0,.13.07.23.13.25.01,0,.08.02.04.04-.03.01-.06.02-.09.02-.03,0-.49.12-.49.41,0,.16.19.29.2.3.01.01.02.03.02.04v.11c0,.2.46.58.81.58.13,0,.18-.16.18-.16.04-.09.03-.22.03-.28,0-.06,0-.09.04-.14.02-.03.1-.1.15-.2.04-.08.07-.16.07-.25,0-.11-.05-.19-.12-.26Z"/>
      </g>
      <g>
          <path className="cls-57" d="m273.74,3903.29c-.14-.08-.22-.18-.22-.31s.07-.23.13-.28c.2-.2.45-.22.53-.22.07,0,.14,0,.22.03.17.04.35-.21.35-.45,0-.21-.13-.4-.34-.47-.07-.03-.15-.04-.22-.04-.33,0-.72.22-.72.56,0,.31.24.37.35.41.02,0,.02.02,0,.03-.82.14-1.18.49-1.18.73,0,.15.21.37.31.48.03.03.04.01.06-.01.1-.09.32-.28.64-.37.03,0,.15-.04.09-.07Zm.91-.65s-.04,0-.02.03c.03.04.08.1.08.19s-.05.17-.11.24c-.15.15-.34.18-.47.21.1-.01.2,0,.29.05.19.05.39.13.52.26.13.13.17.27.08.48-.02.04-.06.12-.06.14,0,0,0,.01.01.01.03,0,.05-.01.09-.05.22-.22.28-.57.28-.73,0-.44-.5-.82-.69-.84Z"/>
          <path className="cls-57" d="m274.7,3904.12c-.2-.2-.39-.17-.43-.21,0,0,0,0,0,0,0,0,0,0,0,0,.02-.02.14-.04.23-.13.04-.04.08-.1.08-.17,0-.16-.16-.24-.17-.25-.09-.05-.19-.06-.29-.05-.2.02-.41.13-.41.33,0,.13.07.23.13.25.01,0,.08.02.04.04-.03.01-.06.02-.09.02-.03,0-.49.12-.49.41,0,.16.19.29.2.3.01.01.02.03.02.04v.11c0,.2.46.58.81.58.13,0,.18-.16.18-.16.04-.09.03-.22.03-.28,0-.06,0-.09.04-.14.02-.03.1-.1.15-.2.04-.08.07-.16.07-.25,0-.11-.05-.19-.12-.26Z"/>
      </g>
      </g>
      <text transform="translate(156.62 4012.93)"><tspan className="cls-5"><tspan x="0" y="0">12,5-jarig </tspan></tspan><tspan className="cls-5"><tspan x="1.58" y="19.2">jubileum</tspan></tspan><tspan className="cls-115"><tspan x="1" y="35.2">De </tspan><tspan className="cls-130" x="19.09" y="35.2">W</tspan><tspan x="30.6" y="35.2">andeling</tspan></tspan></text>
      <g id="Samen_groeien_De_wandeling" data-name="Samen groeien De wandeling">
      <path className="cls-79" d="m175.6,3984.21c-.08,0-.16.02-.25.03.05,0,.1-.01.15-.02.02,0,.04,0,.06,0,.01,0,.03,0,.04,0Z"/>
      <path className="cls-57" d="m226.6,3976.51c-.13-.02-.22-.03,0,0h0Z"/>
      <path className="cls-57" d="m175.7,3984.19s-.06,0-.1.01c-.01,0-.03,0-.04,0-.02,0-.04,0-.06,0,.78-.08.46-.05.2-.02Z"/>
      <path className="cls-57" d="m215.8,3995.27c.05,0,.1.02.15.03.04,0,.09.01.13.02.28.04-.3-.05-.28-.05Z"/>
      <circle className="cls-80" cx="198.53" cy="3956.56" r="29.03"/>
      <g>
          <path className="cls-54" d="m198.2,3985.69c-15.79,0-28.63-12.84-28.63-28.63s12.84-28.63,28.63-28.63,28.63,12.84,28.63,28.63-12.84,28.63-28.63,28.63Z"/>
          <path className="cls-41" d="m198.2,3935.13c12.1,0,21.94,9.84,21.94,21.94s-9.84,21.94-21.94,21.94-21.94-9.84-21.94-21.94,9.84-21.94,21.94-21.94m0-13.39c-19.51,0-35.32,15.81-35.32,35.32s15.81,35.32,35.32,35.32,35.32-15.81,35.32-35.32-15.81-35.32-35.32-35.32h0Z"/>
      </g>
      <path className="cls-79" d="m192.9,3931.44c-.83-.67-1.92-.91-2.95-1.04-1.26-.16-2.55-.13-3.81-.04-1.83.13-3.7.22-5.17,1.44-1.4,1.15-2.3,2.83-3.06,4.44-.88,1.87-1.57,3.82-2.41,5.7-.73,1.63-1.62,3.65-3.27,4.55-.22.12.12.15.23.09,1.31-.71,2.13-2.08,2.79-3.37.88-1.72,1.54-3.55,2.29-5.33.73-1.75,1.52-3.55,2.73-5.03.52-.64,1.13-1.21,1.86-1.59.8-.42,1.69-.49,2.58-.59,1.39-.15,2.79-.24,4.19-.15,1.24.08,2.64.27,3.64,1.07.1.08.44-.07.35-.14h0Z"/>
      <path className="cls-79" d="m223.61,3968.58c.38.25,1.91,1.48,2.41,5.12,0,.07-.03.13-.1.15-.45.12-2.17.45-4.13-.75-.08-.05-.19,0-.2.09-.12.9-.83,4.66-4.18,7.73-.05.05-.13.05-.18,0-.47-.44-2.57-2.66-2.27-6.65,0-.08-.05-.14-.13-.14-.77.02-4.48.09-5.85-.17-.07-.01-.11-.07-.1-.14.05-.61.43-3.45,3.19-4.56.07-.03.11-.11.07-.18-.14-.28-.55-.93-1.48-1.24-.1-.03-.12-.17-.03-.23.89-.58,4.22-2.4,7.35.76.05.05.14.05.19,0,.44-.5,2.48-2.56,5.51-1.91.05.01.09.05.1.1"/>
      <path className="cls-79" d="m184.62,3978.26c-.55.25-2.85,1.44-3.1,4.2,0,.05.02.09.06.11.17.11.68.37,1.65.31.05,0,.11.03.12.08.12.39.76,2.03,3.74,3.42.05.03.12,0,.15-.04.22-.35.96-1.74.46-3.73-.02-.08.05-.16.13-.15.8.13,4.2.53,7.73-1.51.05-.03.08-.1.05-.15-.25-.52-1.59-2.91-5.09-3.71-.07-.02-.11-.08-.09-.15.22-.66,1.27-3.81,1.41-5.05,0-.06-.03-.11-.09-.12-.53-.12-3.06-.55-4.75,1.51-.04.05-.12.06-.17.01-.2-.19-.65-.72-.66-1.6,0-.1-.11-.15-.19-.09-.73.6-3.18,2.97-1.3,6.49.03.06,0,.13-.05.16Z"/>
      <path className="cls-79" d="m224.96,3963.98c.49.08,2.59.54,3.38,2.69.01.04,0,.07-.02.1-.11.12-.47.44-1.25.6-.04,0-.08.05-.08.09,0,.34-.17,1.79-2.25,3.54-.04.03-.09.03-.13,0-.25-.23-1.14-1.18-1.17-2.88,0-.07-.07-.12-.14-.09-.61.28-3.25,1.33-6.5.45-.05-.01-.08-.06-.07-.11.09-.47.65-2.67,3.27-4.06.05-.03.07-.09.04-.14-.32-.48-1.83-2.77-2.21-3.73-.02-.05,0-.1.05-.12.4-.21,2.33-1.09,4.12.19.05.03.11.02.14-.03.12-.2.36-.71.19-1.42-.02-.08.06-.15.13-.11.72.32,3.18,1.69,2.43,4.9-.01.06.02.11.08.12Z"/>
      <path className="cls-79" d="m170.49,3965.45c-.42.07-2.21.46-2.89,2.3-.01.03,0,.06.02.09.09.1.4.38,1.07.52.04,0,.07.04.07.08,0,.29.15,1.53,1.92,3.02.03.03.08.03.11,0,.21-.2.98-1.01,1-2.46,0-.06.06-.1.12-.08.52.24,2.77,1.13,5.55.38.04-.01.07-.05.06-.1-.08-.4-.55-2.28-2.79-3.47-.04-.02-.06-.08-.03-.12.27-.41,1.57-2.37,1.89-3.19.02-.04,0-.08-.04-.1-.34-.18-1.99-.94-3.52.16-.04.03-.09.02-.12-.02-.1-.17-.31-.61-.16-1.21.02-.07-.05-.12-.11-.1-.61.28-2.71,1.44-2.08,4.19.01.05-.02.09-.07.1Z"/>
      <g>
          <path className="cls-57" d="m183.46,3948.92v-.98s.07,0,.07,0c.47.1,1.04.18,1.54.18.57,0,1.12-.13,1.12-.61,0-.35-.29-.49-.64-.58l-.8-.18c-.83-.2-1.31-.65-1.31-1.42,0-1.02.94-1.55,2.26-1.55.51,0,1.03.08,1.4.15v.98s-.07,0-.07,0c-.41-.07-.87-.14-1.36-.14-.55,0-1.03.13-1.03.54,0,.37.27.44.6.51l.7.16c.77.17,1.45.51,1.45,1.52,0,1.23-1.12,1.61-2.32,1.61-.55,0-1.19-.08-1.61-.19Z"/>
          <path className="cls-57" d="m187.92,3947.36v-.89c0-1.61,1.26-2.61,2.74-2.61h1.83s0,4.22,0,4.22c.23-.02.47-.05.71-.11v.88c-.49.13-.96.19-1.44.19h-.45s0-.86,0-.86l-.37.32c-.41.36-.84.62-1.46.61-.93,0-1.56-.57-1.56-1.75Zm3.39-.14v-2.38s-.59,0-.59,0c-.98,0-1.63.59-1.63,1.63v.78c0,.65.28.85.71.85.43,0,.92-.38,1.21-.63l.29-.25Z"/>
          <path className="cls-57" d="m193.39,3944.04c.49-.13.97-.19,1.44-.19h.45s0,.89,0,.89l.29-.27c.43-.43.89-.69,1.52-.69.65,0,1.13.32,1.31,1h.05s.33-.31.33-.31c.45-.42.89-.69,1.52-.69.85,0,1.4.52,1.4,1.72v2.58c.23-.02.47-.05.71-.11v.88c-.49.13-.96.19-1.44.19h-.45s0-3.35,0-3.35c0-.66-.24-.89-.62-.89-.39,0-.83.39-1.15.66l-.27.25v3.33s-1.17,0-1.17,0v-3.35c0-.66-.24-.89-.63-.89-.38,0-.82.39-1.13.66l-.28.25v3.33s-1.17,0-1.17,0v-4.22c-.24.03-.48.07-.71.11v-.88Z"/>
          <path className="cls-57" d="m202.77,3947.02v-1.01c0-1.44,1.04-2.22,2.41-2.22,1.15,0,2.02.43,2.02,1.57,0,1-.7,1.5-1.76,1.5h-1.49s0,.19,0,.19c0,.8.57,1.06,1.28,1.06.57,0,1.16-.15,1.53-.28h.08s0,.98,0,.98c-.4.14-1.14.31-1.68.31-1.38,0-2.39-.6-2.39-2.09Zm1.17-.96h1.31c.61,0,.82-.23.82-.67,0-.5-.38-.62-.9-.63-.78,0-1.23.35-1.23,1.22v.08Z"/>
          <path className="cls-57" d="m207.44,3944.05c.49-.13.97-.19,1.44-.19h.45s0,.89,0,.89l.31-.27c.44-.41.9-.69,1.57-.69.89,0,1.44.52,1.44,1.72v2.58c.24-.02.48-.05.72-.11v.88c-.49.13-.97.19-1.44.19h-.44s0-3.35,0-3.35c0-.66-.26-.89-.67-.89-.43,0-.88.4-1.18.66l-.3.25v3.33s-1.17,0-1.17,0v-4.22c-.24.03-.48.07-.71.11v-.88Z"/>
      </g>
      <path className="cls-79" d="m172.76,3948.95c.28.25,1.56,1.24,3.18.66.03,0,.05-.03.05-.06.02-.12.03-.48-.25-1.02-.02-.03-.01-.07.01-.09.19-.17.95-.96.97-3,0-.04-.03-.07-.06-.07-.25-.03-1.24-.1-2.23.71-.04.03-.1.02-.12-.04-.13-.49-.79-2.52-2.86-3.98-.03-.02-.08-.02-.1.01-.23.28-1.24,1.65-.78,3.84,0,.04-.02.08-.06.09-.43.04-2.49.27-3.22.51-.04.01-.05.05-.05.08.07.33.48,1.88,2.09,2.3.04.01.07.05.05.09-.06.16-.24.55-.73.79-.05.03-.06.1,0,.13.53.26,2.5,1.03,4.01-.94.03-.03.07-.04.11-.01Z"/>
      <path className="cls-79" d="m176.39,3945.96c-.16.11-.82.62-.75,1.51,0,.01.01.03.02.03.06.02.24.08.54,0,.02,0,.04,0,.04.02.06.12.35.6,1.38.88.02,0,.04,0,.05-.02.05-.12.21-.61-.06-1.21-.01-.03,0-.05.03-.05.26,0,1.36-.07,2.37-.91.02-.01.02-.04,0-.05-.11-.15-.67-.83-1.82-.89-.02,0-.04-.02-.04-.04.03-.22.19-1.28.16-1.68,0-.02-.02-.03-.04-.03-.18,0-1,0-1.42.75-.01.02-.04.03-.05.01-.07-.05-.25-.19-.3-.47,0-.03-.04-.04-.07-.02-.2.23-.84,1.12-.05,2.13.01.02.01.04,0,.05Z"/>
      <path className="cls-79" d="m187.43,3931.17c0,.18.08.94.81,1.31.01,0,.03,0,.04,0,.05-.03.18-.15.27-.42,0-.02.02-.03.04-.02.12.01.64.02,1.35-.65.01-.01.02-.03,0-.05-.07-.1-.37-.46-.97-.54-.02,0-.04-.03-.03-.05.13-.21.61-1.09.44-2.28,0-.02-.02-.03-.04-.03-.17.01-.97.11-1.58.98-.01.02-.04.02-.05,0-.16-.13-.9-.77-1.23-.94-.02,0-.03,0-.04.01-.09.13-.49.78-.11,1.47,0,.02,0,.04-.01.05-.07.03-.27.1-.51,0-.03-.01-.05.01-.05.04.08.27.46,1.2,1.63,1.07.02,0,.04.01.04.03Z"/>
      <path className="cls-79" d="m177.22,3941.82s-.22.78-1.37.91c-1.16.13-2.14-.52-2.14-.52,0,0,.7-.41.83-.6s.15-.27-.05-.36-.85-.45-1.33-2.6c0,0,1.42-.51,2.5-.14,0,0,.08.23.17-.74s.68-2.84,1.13-3.35c0,0,2.01,1.88,1.79,4.82,0,0,.95-1.13,2.05-.85,0,0,.01,1.7-.99,3.08l.07.67s-1.21,1.16-2.66-.33Z"/>
      <path className="cls-79" d="m179.84,3932.42c-.26.03-1.38.23-1.84,1.35,0,.02,0,.04,0,.05.06.07.24.24.65.34.02,0,.04.03.04.05,0,.18.05.95,1.12,1.91.02.02.05.02.07,0,.14-.12.63-.6.68-1.5,0-.04.04-.06.07-.04.32.16,1.69.76,3.42.37.03,0,.04-.03.04-.06-.04-.25-.29-1.42-1.64-2.21-.03-.02-.03-.05-.02-.07.18-.25,1.02-1.42,1.24-1.92.01-.02,0-.05-.02-.06-.21-.12-1.21-.62-2.18.02-.02.02-.06,0-.07-.02-.06-.11-.18-.38-.07-.75.01-.04-.03-.08-.07-.06-.38.16-1.71.83-1.38,2.54,0,.03-.01.06-.04.06Z"/>
      <path className="cls-79" d="m182.5,3938.14c-1.17-1.42-3.23-1.81-4.83-.87-.81.47-1.36,1.2-1.69,2.07-.43,1.14-.75,2.33-1.12,3.49-.04.14.21.13.24.03.31-.99.62-1.97.94-2.96.28-.88.65-1.68,1.4-2.25,1.5-1.15,3.63-.84,4.83.6.07.09.31-.01.23-.11h0Z"/>
      <path className="cls-79" d="m189.58,3930.39c1.17-.08,2.06-1.47,1.53-2.55-.05-.11-.3-.03-.25.07.45.92-.2,2.23-1.26,2.3-.14,0-.2.19-.02.18h0Z"/>
      <path className="cls-79" d="m182.1,3936.75c-.12-.07-.67-.34-1.22,0,0,0-.01.02-.01.03,0,.05.02.18.17.36,0,0,0,.02,0,.03-.06.08-.28.43-.13,1.18,0,.01.02.02.03.02.1,0,.46-.06.77-.43.01-.02.04-.01.05,0,.09.17.48.87,1.36,1.25.01,0,.03,0,.04-.01.06-.12.33-.7,0-1.48,0-.01,0-.03.02-.04.16-.05.9-.29,1.15-.43.01,0,.02-.02.01-.03-.05-.12-.32-.65-.94-.69-.02,0-.03-.01-.03-.03,0-.06.05-.22.21-.35.02-.01.01-.04,0-.05-.22-.06-1-.19-1.41.65,0,.01-.02.02-.04.01Z"/>
      <path className="cls-79" d="m204.33,3931.58c.87-.7,2.05-.91,3.13-1.01,1.27-.12,2.56-.07,3.83.04.94.08,1.97.12,2.87.43.78.27,1.45.76,2.02,1.34,1.29,1.32,2.12,3.05,2.85,4.73.79,1.8,1.46,3.65,2.29,5.43.71,1.52,1.59,3.26,3.12,4.09.13.07.47-.11.3-.21-1.44-.78-2.29-2.41-2.97-3.84-.87-1.84-1.56-3.76-2.38-5.62-.75-1.7-1.59-3.46-2.92-4.8-.55-.56-1.2-1.02-1.93-1.31-.88-.34-1.87-.39-2.8-.48-1.41-.13-2.83-.2-4.24-.07-1.18.11-2.47.35-3.42,1.11-.2.16.11.26.25.15h0Z"/>
      <path className="cls-79" d="m224.16,3948.95c-.28.25-1.56,1.24-3.18.66-.03,0-.05-.03-.05-.06-.02-.12-.03-.48.25-1.02.02-.03.01-.07-.01-.09-.19-.17-.95-.96-.97-3,0-.04.03-.07.06-.07.25-.03,1.24-.1,2.23.71.04.03.1.02.12-.04.13-.49.79-2.52,2.86-3.98.03-.02.08-.02.1.01.23.28,1.24,1.65.78,3.84,0,.04.02.08.06.09.43.04,2.49.27,3.22.51.04.01.05.05.05.08-.07.33-.48,1.88-2.09,2.3-.04.01-.07.05-.05.09.06.16.24.55.73.79.05.03.06.1,0,.13-.53.26-2.5,1.03-4.01-.94-.03-.03-.07-.04-.11-.01Z"/>
      <path className="cls-79" d="m220.54,3945.96c.16.11.82.62.75,1.51,0,.01-.01.03-.02.03-.06.02-.24.08-.54,0-.02,0-.04,0-.04.02-.06.12-.35.6-1.38.88-.02,0-.04,0-.05-.02-.05-.12-.21-.61.06-1.21.01-.03,0-.05-.03-.05-.26,0-1.36-.07-2.37-.91-.02-.01-.02-.04,0-.05.11-.15.67-.83,1.82-.89.02,0,.04-.02.04-.04-.03-.22-.19-1.28-.16-1.68,0-.02.02-.03.04-.03.18,0,1,0,1.42.75.01.02.04.03.05.01.07-.05.25-.19.3-.47,0-.03.04-.04.07-.02.2.23.84,1.12.05,2.13-.01.02-.01.04,0,.05Z"/>
      <path className="cls-79" d="m209.49,3931.17c0,.18-.08.94-.81,1.31-.01,0-.03,0-.04,0-.05-.03-.18-.15-.27-.42,0-.02-.02-.03-.04-.02-.12.01-.64.02-1.35-.65-.01-.01-.02-.03,0-.05.07-.1.37-.46.97-.54.02,0,.04-.03.03-.05-.13-.21-.61-1.09-.44-2.28,0-.02.02-.03.04-.03.17.01.97.11,1.58.98.01.02.04.02.05,0,.16-.13.9-.77,1.23-.94.02,0,.03,0,.04.01.09.13.49.78.11,1.47,0,.02,0,.04.01.05.07.03.27.1.51,0,.03-.01.05.01.05.04-.08.27-.46,1.2-1.63,1.07-.02,0-.04.01-.04.03Z"/>
      <path className="cls-79" d="m219.71,3941.82s.22.78,1.37.91,2.14-.52,2.14-.52c0,0-.7-.41-.83-.6s-.15-.27.05-.36.85-.45,1.33-2.6c0,0-1.42-.51-2.5-.14,0,0-.08.23-.17-.74s-.68-2.84-1.13-3.35c0,0-2.01,1.88-1.79,4.82,0,0-.95-1.13-2.05-.85,0,0-.01,1.7.99,3.08l-.07.67s1.21,1.16,2.66-.33Z"/>
      <path className="cls-79" d="m217.08,3932.42c.26.03,1.38.23,1.84,1.35,0,.02,0,.04,0,.05-.06.07-.24.24-.65.34-.02,0-.04.03-.04.05,0,.18-.05.95-1.12,1.91-.02.02-.05.02-.07,0-.14-.12-.63-.6-.68-1.5,0-.04-.04-.06-.07-.04-.32.16-1.69.76-3.42.37-.03,0-.04-.03-.04-.06.04-.25.29-1.42,1.64-2.21.03-.02.03-.05.02-.07-.18-.25-1.02-1.42-1.24-1.92-.01-.02,0-.05.02-.06.21-.12,1.21-.62,2.18.02.02.02.06,0,.07-.02.06-.11.18-.38.07-.75-.01-.04.03-.08.07-.06.38.16,1.71.83,1.38,2.54,0,.03.01.06.04.06Z"/>
      <path className="cls-79" d="m214.66,3938.19c1.24-1.5,3.43-1.7,4.92-.45.69.57,1.03,1.34,1.3,2.18.32.98.62,1.97.94,2.96.03.08.28,0,.25-.07-.37-1.18-.7-2.4-1.14-3.55-.34-.88-.93-1.61-1.77-2.05-1.59-.85-3.6-.38-4.73.99-.06.08.19.06.23,0h0Z"/>
      <path className="cls-79" d="m207.42,3930.23c-1.09-.08-1.82-1.41-1.35-2.38.02-.05-.09-.05-.11-.05-.05,0-.12.03-.15.08-.51,1.03.31,2.39,1.44,2.47.06,0,.14,0,.18-.05.03-.03.04-.08-.02-.08h0Z"/>
      <path className="cls-79" d="m214.83,3936.75c.12-.07.67-.34,1.22,0,0,0,.01.02.01.03,0,.05-.02.18-.17.36,0,0,0,.02,0,.03.06.08.28.43.13,1.18,0,.01-.02.02-.03.02-.1,0-.46-.06-.77-.43-.01-.02-.04-.01-.05,0-.09.17-.48.87-1.36,1.25-.01,0-.03,0-.04-.01-.06-.12-.33-.7,0-1.48,0-.01,0-.03-.02-.04-.16-.05-.9-.29-1.15-.43-.01,0-.02-.02-.01-.03.05-.12.32-.65.94-.69.02,0,.03-.01.03-.03,0-.06-.05-.22-.21-.35-.02-.01-.01-.04,0-.05.22-.06,1-.19,1.41.65,0,.01.02.02.04.01Z"/>
      <path className="cls-98" d="m217.03,3978.52h-.16c.02-.29.44-6.68,1.02-8.48-.41-.06-2.38-.11-7.06,2.84l-.09-.13c5.37-3.39,7.2-2.87,7.27-2.85l.08.03-.03.08c-.59,1.65-1.04,8.45-1.04,8.52Z"/>
      <path className="cls-98" d="m218,3970.07l-.04-.02c-2.99-1.74-5.16-2.08-5.18-2.08l.02-.16s2.19.34,5.19,2.08c.25-.15,1.64-.96,4.16-1.89l.06.15c-2.76,1.02-4.16,1.89-4.17,1.9l-.04.03Z"/>
      <path className="cls-98" d="m223.61,3972.98s-2.17-1.91-5.64-2.92l.04-.15c3.5,1.03,5.68,2.94,5.7,2.96l-.11.12Z"/>
      <g>
          <path className="cls-98" d="m187.06,3985.07c-2.57-5.63-1.6-7.23-1.55-7.29l.04-.07.07.04c1.68.86,9.02,2.61,9.1,2.62l-.04.18c-.3-.07-7.22-1.71-9.06-2.6-.15.37-.62,2.17,1.6,7.05l-.17.08Z"/>
          <path className="cls-98" d="m182.27,3982.26l-.17-.07s.84-1.95,3.38-4.38c-.13-.3-.7-1.74-1.17-4.2l.18-.03c.52,2.75,1.17,4.2,1.18,4.22l.03.06-.05.04c-2.54,2.42-3.37,4.35-3.38,4.36Z"/>
          <path className="cls-98" d="m185.66,3977.88l-.15-.1c1.88-3.03,4.44-4.67,4.46-4.68l.1.15s-2.55,1.63-4.4,4.63Z"/>
      </g>
      <g>
          <path className="cls-98" d="m170.99,3970.61c-.04-4.24.82-5.18.86-5.21l.06-.06.06.07c.67.85,4.06,3.19,4.09,3.22l-.09.13c-.14-.09-3.26-2.25-4.06-3.17-.19.29-.8,1.49-.76,5.03h-.16Z"/>
          <path className="cls-98" d="m169.21,3967.95l-.13-.09s.88-1.2,2.75-2.45c0-.22.03-1.29.35-3.05l.16.03c-.36,1.95-.35,3.05-.35,3.06v.04s-.04.02-.04.02c-1.87,1.25-2.73,2.43-2.74,2.44Z"/>
          <path className="cls-98" d="m171.96,3965.5l-.12-.11c1.66-1.77,3.36-2.48,3.38-2.49l.06.15s-1.69.71-3.33,2.45Z"/>
      </g>
      <g>
          <path className="cls-98" d="m219.31,3967.25l-.08-.14c.15-.09,3.48-2.03,4.68-2.38-.18-.3-1.03-1.38-4.31-2.87l.07-.15c3.93,1.79,4.42,2.99,4.43,3.04l.03.09-.09.02c-1.07.25-4.69,2.37-4.73,2.39Z"/>
          <path className="cls-98" d="m226.66,3966.5c-1.65-1.17-2.66-1.64-2.67-1.64l-.04-.02v-.04c-.35-2.26-1.06-3.57-1.07-3.58l.14-.08s.72,1.33,1.08,3.59c.2.1,1.18.59,2.66,1.64l-.09.13Z"/>
          <path className="cls-98" d="m224.94,3968.94h-.16s.08-1.87-.83-4.13l.15-.06c.92,2.3.84,4.17.84,4.19Z"/>
      </g>
      <g>
          <path className="cls-98" d="m224.6,3948.34c-.24,0-.34-.04-.36-.04l-.07-.03.03-.07c.4-.91.93-4.73.93-4.77l.14.02c-.02.15-.51,3.68-.91,4.73.29.04,1.45.08,4.17-1.37l.07.13c-2.27,1.21-3.47,1.4-4.01,1.4Z"/>
          <path className="cls-98" d="m227.2,3949.67s-1.26-.27-2.94-1.35c-.18.09-1.02.49-2.49.92l-.04-.14c1.65-.48,2.49-.92,2.5-.93l.04-.02.04.02c1.67,1.08,2.91,1.35,2.92,1.35l-.03.14Z"/>
          <path className="cls-98" d="m224.24,3948.3c-2-.7-3.2-1.86-3.21-1.87l.1-.1s1.19,1.15,3.16,1.84l-.05.14Z"/>
      </g>
      <g>
          <path className="cls-98" d="m172.38,3948.31c-.46,0-1.54-.19-3.59-1.43l.07-.11c2.4,1.46,3.44,1.44,3.69,1.4-.33-1.01-.65-4.45-.66-4.6h.13s.35,3.74.68,4.63l.02.06-.06.02s-.1.04-.29.04Z"/>
          <path className="cls-98" d="m169.96,3949.54l-.03-.13s1.13-.24,2.67-1.25l.04-.02.04.02s.75.45,2.21.94l-.04.13c-1.29-.44-2.03-.84-2.2-.93-1.54,1.01-2.63,1.24-2.68,1.25Z"/>
          <path className="cls-98" d="m172.66,3948.28l-.04-.13c1.79-.63,2.88-1.71,2.9-1.72l.09.09s-1.12,1.11-2.95,1.76Z"/>
      </g>
      <g>
          <path className="cls-98" d="m176.91,3941.05c-.34,0-1.18-.2-2.69-1.64l.08-.09c1.7,1.62,2.51,1.63,2.7,1.6-.13-1.04.06-4.69.07-4.85h.12s-.21,3.94-.06,4.89v.05s-.04.02-.04.02c0,0-.07.03-.19.03Z"/>
          <path className="cls-98" d="m174.81,3942.25l-.03-.12s.91-.2,2.24-1.21l.04-.03.04.04s.53.5,1.6,1.07l-.06.11c-.94-.5-1.45-.93-1.59-1.05-1.33,1-2.21,1.19-2.25,1.2Z"/>
          <path className="cls-98" d="m177.09,3941.03l-.05-.11c1.48-.59,2.48-1.68,2.49-1.69l.09.08s-1.02,1.12-2.53,1.72Z"/>
      </g>
      <g>
          <path className="cls-98" d="m183.2,3934.5s-2.21-1.48-2.86-1.68l-.05-.02.02-.05s.32-.74,2.73-1.65l.03.09c-2.01.76-2.53,1.38-2.65,1.54.72.26,2.73,1.61,2.82,1.67l-.05.08Z"/>
          <path className="cls-98" d="m178.74,3933.7l-.05-.08c.9-.57,1.5-.82,1.63-.87.24-1.36.69-2.13.69-2.14l.09.05s-.45.77-.68,2.13v.03s-.03,0-.03,0c0,0-.63.24-1.64.88Z"/>
          <path className="cls-98" d="m179.76,3935.27s-.03-1.16.55-2.51l.09.04c-.57,1.34-.54,2.46-.54,2.47h-.1Z"/>
      </g>
      <g>
          <path className="cls-98" d="m217.1,3935.57c-.19-2.09-.59-2.79-.71-2.96-.39.56-1.97,1.88-2.04,1.94l-.06-.07s1.74-1.46,2.06-1.97l.03-.05.04.04s.55.56.78,3.06h-.09Z"/>
          <path className="cls-98" d="m218.01,3933.97s-.55-.69-1.65-1.4h-.02s0-.03,0-.03c0,0-.06-.65-.37-1.8l.09-.02c.28,1.04.36,1.67.37,1.8,1.1.71,1.65,1.4,1.65,1.41l-.07.06Z"/>
          <path className="cls-98" d="m216.36,3932.56c-1-1.01-1.96-1.39-1.97-1.4l.03-.08s.98.4,2,1.42l-.06.06Z"/>
      </g>
      <g>
          <path className="cls-98" d="m219.56,3940.79c-.34,0-1.18-.2-2.69-1.64l.08-.09c1.7,1.62,2.51,1.63,2.7,1.6-.13-1.04.06-4.69.07-4.85h.12s-.21,3.94-.06,4.89v.05s-.04.02-.04.02c0,0-.07.03-.19.03Z"/>
          <path className="cls-98" d="m217.47,3941.99l-.03-.12s.92-.2,2.24-1.21l.04-.03.04.04s.53.5,1.6,1.07l-.06.11c-.94-.5-1.45-.93-1.59-1.05-1.33.99-2.21,1.19-2.25,1.2Z"/>
          <path className="cls-98" d="m219.75,3940.77l-.04-.11c1.48-.59,2.48-1.68,2.49-1.69l.09.08s-1.02,1.12-2.53,1.72Z"/>
      </g>
      <g>
          <path className="cls-57" d="m170.45,3963.23c.75.23,1.49.36,2.19.37,1.57,0,2.8-.71,2.8-2.6v-.25s-.35.17-.35.17c-.71.31-1.49.79-2.65.79-1.72,0-2.93-1.04-2.93-3.18v-.79c0-2.88,2.07-4.57,4.64-4.57h3.35s0,7.85,0,7.85c0,2.96-2.1,4.32-4.62,4.32-.78,0-1.61-.13-2.42-.38v-1.72Zm4.99-4.02v-4.32s-1.17,0-1.17,0c-1.72,0-2.7,1.03-2.7,2.85v.6c0,1.14.5,1.61,1.39,1.61.78,0,1.52-.26,2.15-.56l.33-.17Z"/>
          <path className="cls-57" d="m178.65,3953.49c.86-.23,1.69-.33,2.52-.33h.78s0,1.62,0,1.62c.58-.89,1.46-1.67,2.62-1.67h.65s0,1.92,0,1.92h-.81c-1.19,0-1.79.65-2.47,1.36v5.83s-2.06,0-2.06,0v-7.39c-.41.05-.83.12-1.22.2v-1.54Zm3.28,1.29v.03s.02-.03.02-.03h-.02Z"/>
          <path className="cls-57" d="m185.37,3957.62c0-2.93,1.48-4.59,4.26-4.59,2.91,0,4.26,1.66,4.25,4.59,0,3.18-1.39,4.74-4.26,4.74-2.86,0-4.26-1.56-4.25-4.74Zm6.46,0c0-1.85-.55-2.82-2.2-2.82-1.66,0-2.2.96-2.2,2.81,0,1.85.55,2.96,2.2,2.97,1.66,0,2.2-1.11,2.2-2.96Z"/>
          <path className="cls-57" d="m195.04,3958.7v-1.77c0-2.52,1.82-3.89,4.21-3.89,2,0,3.53.75,3.53,2.75,0,1.76-1.23,2.62-3.08,2.62h-2.6s0,.33,0,.33c0,1.39.99,1.86,2.24,1.86.99,0,2.04-.26,2.68-.5h.13s0,1.72,0,1.72c-.7.25-1.99.55-2.93.55-2.42,0-4.17-1.04-4.17-3.66Zm2.05-1.67h2.29c1.06,0,1.44-.4,1.44-1.17,0-.88-.66-1.09-1.57-1.09-1.36,0-2.15.61-2.15,2.14v.13Z"/>
          <path className="cls-57" d="m207.93,3961.9c-.84.23-1.67.33-2.52.33h-.78s0-7.34,0-7.34l.07-.05c-.43.03-.88.1-1.31.2v-1.54c.86-.23,1.69-.33,2.52-.33h.78s0,7.39,0,7.39c.41-.03.83-.08,1.24-.2v1.54Zm-3.29-12.09h2.05s0,1.86,0,1.86h-2.05s0-1.86,0-1.86Z"/>
          <path className="cls-57" d="m208.59,3958.7v-1.77c0-2.52,1.82-3.89,4.21-3.89,2,0,3.53.75,3.53,2.75,0,1.76-1.23,2.62-3.08,2.62h-2.6s0,.33,0,.33c0,1.39.99,1.86,2.24,1.86.99,0,2.04-.26,2.68-.5h.13s0,1.72,0,1.72c-.7.25-1.99.55-2.93.55-2.42,0-4.17-1.04-4.17-3.66Zm2.05-1.67h2.29c1.06,0,1.44-.4,1.44-1.17,0-.88-.66-1.09-1.57-1.09-1.36,0-2.15.61-2.15,2.14v.13Z"/>
          <path className="cls-57" d="m216.8,3953.5c.86-.23,1.69-.33,2.52-.33h.78s0,1.56,0,1.56l.55-.48c.78-.71,1.57-1.21,2.75-1.21,1.56,0,2.52.91,2.52,3.01v4.5c.41-.03.84-.08,1.26-.2v1.54c-.86.23-1.69.33-2.52.33h-.78s0-5.86,0-5.86c0-1.16-.45-1.56-1.18-1.56-.75,0-1.54.7-2.07,1.16l-.53.43v5.83s-2.06,0-2.06,0v-7.39c-.41.05-.84.12-1.24.2v-1.54Z"/>
      </g>
      <path className="cls-79" d="m203.3,3975.37s-1.58-.69-3.37,1.04c-1.79,1.74-2.04,4.25-2.04,4.25,0,0,1.62-.6,2.11-.56s.65.12.53.57-.41,2.02,2.5,5.72c0,0,2.76-1.65,3.62-3.96,0,0-.28-.45,1.46.73s5.62,2.73,7.08,2.65c0,0-.39-5.88-5.56-9.5,0,0,3.16-.03,4.18-2.26,0,0-2.79-2.32-6.43-2.53l-1.01-1.02s-3.57.44-3.05,4.85Z"/>
      <path className="cls-98" d="m203.34,3984.7h-.16c.34-6.84,2.03-8,2.1-8.04l.06-.04.04.06c1.17,1.64,7.28,6.75,7.34,6.8l-.1.12c-.25-.21-6.03-5.05-7.32-6.77-.3.27-1.68,1.85-1.97,7.86Z"/>
      <path className="cls-98" d="m199.99,3979.65l-.11-.12s1.76-1.58,5.37-2.87c.02-.32.17-2.04.96-4.77l.15.04c-.86,3.01-.96,4.77-.96,4.79v.05s-.05.02-.05.02c-3.61,1.28-5.35,2.84-5.37,2.86Z"/>
      <path className="cls-98" d="m205.37,3976.79l-.09-.13c3.27-2.19,6.5-2.67,6.53-2.67l.02.16s-3.23.48-6.46,2.65Z"/>
      <path className="cls-79" d="m180.79,3970.9c.48-.36,2.63-1.79,5.15-.67.04.02.07.06.07.1.01.2,0,.77-.52,1.59-.03.05-.03.11.01.14.29.29,1.4,1.64,1.18,4.9,0,.06-.05.11-.11.11-.41.02-1.98,0-3.47-1.4-.06-.06-.16-.04-.19.04-.27.76-1.56,3.92-5.04,6-.05.03-.12.02-.16-.03-.33-.47-1.77-2.79-.78-6.22.02-.07-.02-.14-.09-.15-.68-.12-3.93-.73-5.07-1.21-.05-.02-.08-.08-.07-.14.15-.52,1-2.93,3.6-3.41.07-.01.11-.08.09-.14-.07-.27-.31-.91-1.07-1.35-.08-.05-.08-.17.01-.21.88-.35,4.11-1.34,6.28,1.99.04.06.11.07.17.03Z"/>
      <path className="cls-98" d="m178.23,3980.26l-.16-.03c.06-.27,1.46-6.53,2.31-8.21-.39-.12-2.34-.48-7.41,1.72l-.06-.15c5.83-2.53,7.55-1.73,7.62-1.69l.07.04-.04.07c-.83,1.54-2.32,8.19-2.34,8.25Z"/>
      <path className="cls-98" d="m180.49,3972.06l-.04-.03c-2.69-2.18-4.77-2.85-4.79-2.85l.05-.15s2.11.68,4.81,2.85c.27-.11,1.77-.7,4.4-1.22l.03.16c-2.89.58-4.4,1.23-4.41,1.23l-.04.02Z"/>
      <path className="cls-98" d="m185.58,3975.8s-1.85-2.22-5.12-3.76l.07-.14c3.3,1.55,5.16,3.78,5.17,3.8l-.12.1Z"/>
      </g>
      <g id="Hans_Butselaar" data-name="Hans Butselaar">
      <g id="Flower">
          <polygon className="cls-79" points="164.32 3918.51 154.72 3957.76 153.01 3957.03 162.88 3919.69 164.32 3918.51"/>
          <path className="cls-79" d="m163.23,3917.85s6.07-1.81,9.24,1.27c0,0-.56,2.88-3.69,2.92-3.12.04-5.91-2.36-5.91-2.36l.36-1.84Z"/>
          <path className="cls-79" d="m161.99,3916.95s-3.63-6.26-12.42-1.78c0,0,5.09,6.11,11.32,4.65l1.1-2.87Z"/>
          <path className="cls-79" d="m162.55,3918.09s-4.79-1-5.82,6.25-1.23,6.19-1.23,6.19c0,0,12.78-2.83,8.73-11.38l-1.68-1.06Z"/>
          <polygon className="cls-65" points="165.41 3910.83 167.23 3909.39 166.53 3910.83 168.23 3911.06 166.85 3912.15 168.81 3913.25 167.01 3913.96 168.77 3915.68 167.32 3915.86 169 3917.98 167.16 3918.08 167.61 3920.76 165.66 3918.31 164.51 3921.38 163.54 3919.67 161.53 3921.26 160.82 3918.95 158.52 3919.58 158.68 3917.77 156.46 3916.98 158.03 3915.73 156.39 3914.47 157.98 3914.81 156.76 3912.02 158.48 3912.63 157.9 3910.64 159.51 3910.96 159.72 3909.2 161.12 3910.16 161.93 3908.16 162.41 3910.1 164.44 3908 164.67 3909.8 166.09 3908.67 165.41 3910.83"/>
          <circle className="cls-41" cx="163.16" cy="3914.91" r="2.65"/>
      </g>
      <polygon className="cls-93" points="123.88 3999.89 124.05 4003.24 126.76 4004.29 127.89 4002.12 127.49 3999.49 123.88 3999.89"/>
      <g className="cls-39">
          <g>
          <circle className="cls-108" cx="124.8" cy="4000.7" r=".37"/>
          <circle className="cls-108" cx="127.52" cy="4000.48" r=".37"/>
          <circle className="cls-108" cx="126.39" cy="4001.22" r=".37"/>
          <circle className="cls-108" cx="124.8" cy="4001.99" r=".37"/>
          <circle className="cls-108" cx="127.13" cy="4002.53" r=".37"/>
          </g>
      </g>
      <polygon className="cls-93" points="113.25 3999.49 113.38 4002.81 114.74 4004.52 116.73 4003.14 117.58 3999.49 113.25 3999.49"/>
      <g className="cls-113">
          <g>
          <circle className="cls-108" cx="114.22" cy="4000.11" r=".37"/>
          <circle className="cls-108" cx="116.56" cy="4001.06" r=".37"/>
          <circle className="cls-108" cx="114.96" cy="4001.93" r=".37"/>
          <circle className="cls-108" cx="115.67" cy="4002.9" r=".37"/>
          <circle className="cls-108" cx="113.53" cy="4001.56" r=".37"/>
          </g>
      </g>
      <polygon className="cls-108" points="129.43 3969.39 129.43 3961.77 111.99 3960.95 111.99 3967.75 112.98 4001.06 117.62 4001.06 120.32 3969.19 121.69 3971.25 123.68 4001.06 127.91 4001.06 129.43 3969.39"/>
      <path className="cls-11" d="m142.9,3945.33l13.31-4.58-2.46-5.02-9.96,3.65-11.2-3.63c-3.44-1.46-8.05-3.08-8.05-3.08l-.77-1.42-3.31,2.69-3.44-2.97-.76,1.42s-7.56,1.45-9.32,3.36c-2.76,2.98-1.78,23.5-1.78,23.5l5.62,1.82-.67,4.87s4.42,1.61,7.16.74c1.88-.59,2.79-3.39,3.19-5.09l1.38.04c.01,1.76.24,4.77,1.76,5.31,2.11.73,7.06-1.5,7.06-1.5l-.88-7.48.82-16.01,12.3,3.37Zm-31.91,2.57l.49,6.51-1.24-.47.75-6.04Z"/>
      <path className="cls-71" d="m117.12,3932.69c0,1.7,6.67,1.7,6.67,0v-4.4h-6.67v4.4Z"/>
      <path className="cls-71" d="m127.77,3920.39c-.37,1.37,0,2.66.81,2.87.81.22,1.77-.72,2.14-2.09.37-1.37,0-2.66-.81-2.87-.81-.22-1.77.72-2.14,2.09Z"/>
      <path className="cls-71" d="m112.99,3920.39c.37,1.37,0,2.66-.81,2.87-.81.22-1.77-.72-2.14-2.09-.37-1.37,0-2.66.81-2.87.81-.22,1.77.72,2.14,2.09Z"/>
      <ellipse className="cls-71" cx="120.38" cy="3920.77" rx="8.32" ry="10.44"/>
      <path className="cls-71" d="m116.33,3959.62c-.23-1.09.64-1.85,1.65-1.91.03,0,.06,0,.09,0,.61-.04,1.08.45,1.38.9.17.26.55.66.88.73.02,0,.05-.02.07-.03.13-.05.25-.1.38-.14.35-.13.69-.23,1.04-.33.32-.09.6.12.77.37.96,1.46-1.04,3.08-2.37,3.15-1.54.08-3.56-1.16-3.89-2.74Z"/>
      <path className="cls-71" d="m160.51,3936.84c-.11.71-.76,1.2-1.38,1.56-1.23.71-2.57,1.24-3.96,1.54-.08.02-.16.03-.23,0-.11-.03-.18-.15-.23-.25-.36-.74-.46-1.62-.24-2.42.22-.8.77-1.52,1.5-1.91.45-.24.96-.36,1.46-.42.7-.1,1.44-.1,2.07.21.63.31,1.12.99,1.02,1.68Z"/>
      <polygon className="cls-57" points="117.58 3956.71 115.31 3962.69 112.52 3961.64 114.65 3955.92 117.58 3956.71"/>
      <path className="cls-64" d="m117.02,3935.78s-.22,8.83.69,10.57c.91,1.73,3.22,7.1,3.22,7.1,0,0,2.39-2.72,2.97-5.7.58-2.97-.25-13.56-.25-13.56l-3.19-.68-3.44,2.27Z"/>
      <polygon className="cls-57" points="117.02 3930.98 117.02 3937.18 120.46 3933.96 123.79 3937.4 123.77 3931.26 120.46 3933.51 117.02 3930.98"/>
      <path className="cls-101" d="m120.53,3934.3l-2.05,1.52,1.62.55s-.91,3.96-.83,7.51c.08,3.55.23,6.34.23,6.34l1.42,3.24s2.28-2.64,2.43-3.96c.15-1.33-2.81-13.06-2.81-13.06l1.04-.89-1.05-1.24Z"/>
      <path className="cls-75" d="m113.34,4002.16l1.16,1.12s1.45-.07,1.93-.81c.48-.75.88-1.84.88-1.84,0,0,.35.57.09,1.79-.26,1.23-.53,2.76-.79,4.56-.26,1.8-3.21,7.34-3.24,5.97-.04-1.58-2.41-4.34-2.41-4.34l2.4-6.45Z"/>
      <path className="cls-75" d="m123.78,4002.15l-.12,2.41,3.9,1.66s.19,1.14,1.24,1.79c1.04.65,6.38.62,6.57.44.19-.18.43-.63.43-.63,0,0-1.16-.33-1.86-.67-.69-.35-5.98-5.57-5.98-5.57l-1.51,1.86"/>
      <polygon className="cls-57" points="156.21 3940.75 153.91 3935.66 151.52 3936.55 153.72 3941.61 156.21 3940.75"/>
      <g>
          <path className="cls-108" d="m123.54,3920.96c-1.31,0-2.38-.75-2.38-1.66s1.07-1.66,2.38-1.66,2.38.75,2.38,1.66-1.07,1.66-2.38,1.66Zm0-2.98c-1.12,0-2.03.59-2.03,1.31s.91,1.31,2.03,1.31,2.03-.59,2.03-1.31-.91-1.31-2.03-1.31Z"/>
          <path className="cls-108" d="m117.22,3920.96c-1.31,0-2.38-.75-2.38-1.66s1.07-1.66,2.38-1.66,2.38.75,2.38,1.66-1.07,1.66-2.38,1.66Zm0-2.98c-1.12,0-2.03.59-2.03,1.31s.91,1.31,2.03,1.31,2.03-.59,2.03-1.31-.91-1.31-2.03-1.31Z"/>
      </g>
      <path className="cls-108" d="m121.22,3919.42c-.28-.28-.57-.41-.86-.4-.46.02-.79.39-.8.4l-.26-.23s.43-.49,1.04-.52c.39-.01.77.16,1.12.51l-.24.24Z"/>
      <rect className="cls-108" x="125.73" y="3918.9" width="2.85" height=".35" transform="translate(-624.19 70.6) rotate(-9.19)"/>
      <rect className="cls-108" x="113.38" y="3917.65" width=".35" height="2.85" transform="translate(-3773.38 3405.52) rotate(-80.81)"/>
      </g>
      <g id="Slingers">
      <path className="cls-25" d="m232.8,3795.47c-46.99,14.6-76.29-10.83-76.29-10.83,0,0-54.24,29-98.72,2.59,0,0-15.41,19.75-49.25,20.29"/>
      <circle className="cls-108" cx="215.44" cy="3810.02" r="11.18"/>
      <circle className="cls-41" cx="62.07" cy="3798.84" r="13.71"/>
      <circle className="cls-54" cx="135.55" cy="3797.13" r="5.67"/>
      <circle className="cls-54" cx="185.81" cy="3802.8" r="5.67"/>
      <circle className="cls-92" cx="146.41" cy="3796.08" r="7.83"/>
      <circle className="cls-92" cx="20.59" cy="3813.2" r="7.83"/>
      </g>
      <text transform="translate(99.41 3865.73)"><tspan className="cls-5"><tspan x="0" y="0">15</tspan></tspan><tspan className="cls-115"><tspan x="-45.04" y="16">peuterconsulenten </tspan></tspan><tspan className="cls-115"><tspan x="-15.85" y="32">in dienst</tspan></tspan></text>
      <text id="_14_gemeenten_in_Raad_voor_het_Publiek_Belang" data-name="14 gemeenten in Raad voor het Publiek Belang" transform="translate(169.87 3717.06)"><tspan className="cls-4"><tspan className="number" x="0" y="0">648</tspan></tspan><tspan className="cls-115"><tspan x="-1.59" y="16">professionals</tspan></tspan></text>
      <g id="Titel-2" data-name="Titel">
      <rect id="Achtergrond-3" data-name="Achtergrond" className="cls-57" x="124.17" y="3643.83" width="169.89" height="33" rx="2.5" ry="2.5"/>
      <text className="cls-55" transform="translate(136.59 3668.42)"><tspan x="0" y="0">CJG Rijnmond</tspan></text>
      </g>
      </g>
      </>
  )
    
}