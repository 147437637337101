export default function Logo(props) {
    const isDesctop = props.isDesctop;
    if (isDesctop) {
        return (
            <g id="Logo">
                <a href={`${props.url}`} rel="noopener noreferrer">
                <g id="Logo-2" data-name="Logo">
                <g>
                    <path className="cls-45" d="m87.61,50.73c-.3-.39-.67-.68-1.13-.89-.46-.21-.93-.31-1.41-.31-.59,0-1.13.11-1.61.33-.48.22-.89.53-1.23.91-.34.39-.61.84-.8,1.36-.19.52-.28,1.08-.28,1.67,0,.55.09,1.08.27,1.58.18.49.44.93.77,1.31.34.38.75.67,1.23.89.49.22,1.04.33,1.65.33s1.13-.12,1.59-.37c.46-.25.84-.6,1.16-1.04l1.2.91c-.08.11-.22.27-.42.47-.2.2-.46.41-.79.61-.33.2-.72.38-1.18.54-.46.15-.99.23-1.6.23-.83,0-1.58-.16-2.25-.48-.67-.32-1.24-.73-1.71-1.25-.47-.52-.83-1.1-1.08-1.74-.25-.65-.37-1.31-.37-1.97,0-.81.13-1.56.4-2.25.27-.69.64-1.28,1.12-1.78.48-.5,1.06-.89,1.73-1.17.67-.28,1.42-.42,2.23-.42.69,0,1.37.13,2.04.4.67.27,1.22.68,1.64,1.23l-1.19.91Z"/>
                    <path className="cls-45" d="m94.23,48.46h1.43v7.52c0,.66-.1,1.21-.31,1.63-.21.42-.47.75-.78,1-.31.24-.65.41-1,.51-.36.09-.68.14-.98.14-.74,0-1.37-.19-1.88-.57-.51-.38-.84-.96-.97-1.73l1.4-.31c.08.4.24.71.49.94.25.23.57.34.97.34.36,0,.64-.07.85-.21.21-.14.38-.32.5-.54.12-.22.2-.48.24-.76.04-.29.06-.58.06-.88v-7.06Z"/>
                    <path className="cls-45" d="m106.92,53.09v5.19c-.26.16-.55.3-.88.41-.33.12-.67.22-1.02.31-.35.08-.71.14-1.07.18-.36.04-.7.06-1.02.06-.89,0-1.69-.15-2.38-.45-.7-.3-1.29-.7-1.77-1.2-.48-.51-.85-1.09-1.1-1.76-.25-.67-.38-1.37-.38-2.12,0-.78.14-1.51.41-2.18.27-.67.65-1.26,1.13-1.75.48-.5,1.06-.88,1.73-1.17.67-.28,1.41-.42,2.21-.42.97,0,1.76.12,2.38.36.61.24,1.13.58,1.54,1l-1.01,1.09c-.44-.42-.9-.71-1.38-.87-.49-.16-.99-.24-1.53-.24-.6,0-1.15.11-1.63.33-.49.22-.9.52-1.25.9-.35.38-.61.82-.8,1.33-.19.51-.28,1.05-.28,1.63s.1,1.15.31,1.66c.21.51.49.95.86,1.33.37.38.8.67,1.29.88.5.21,1.04.32,1.62.32.51,0,.98-.05,1.43-.14.45-.09.84-.23,1.15-.41v-2.93h-2.33v-1.34h3.76Z"/>
                    <path className="cls-45" d="m114.93,53.09h1.87c.28,0,.55-.02.82-.07.27-.05.52-.13.73-.24.21-.12.39-.29.51-.51.13-.22.19-.51.19-.87s-.07-.65-.19-.87c-.13-.22-.3-.39-.51-.51-.21-.12-.45-.2-.73-.25-.27-.04-.55-.07-.82-.07h-1.87v3.39Zm-1.43-4.63h3.7c.67,0,1.23.09,1.66.28.43.18.78.42,1.03.7.25.28.43.6.53.95.1.35.15.69.15,1.02s-.06.66-.18.97c-.12.31-.29.59-.51.85-.22.25-.5.47-.82.64-.32.17-.68.28-1.08.32l2.99,4.8h-1.78l-2.67-4.64h-1.59v4.64h-1.43v-10.52Z"/>
                    <path className="cls-45" d="m122.13,51.94h1.34v7.04h-1.34v-7.04Zm-.31-2.53c0-.27.1-.5.29-.69.19-.19.42-.29.69-.29s.5.1.69.29c.19.19.29.42.29.69s-.1.5-.29.69c-.19.19-.43.29-.69.29s-.5-.1-.69-.29c-.19-.19-.29-.42-.29-.69"/>
                    <path className="cls-45" d="m125.16,49.41c0-.27.1-.5.29-.69.19-.19.42-.29.69-.29s.5.1.69.29c.19.19.29.42.29.69s-.1.5-.29.69c-.19.19-.42.29-.69.29s-.5-.1-.69-.29c-.19-.19-.29-.42-.29-.69m1.65,2.53v8.05c0,.18-.01.41-.04.7-.03.29-.1.57-.24.85-.13.27-.35.51-.64.71-.29.2-.71.3-1.24.3-.15,0-.29-.01-.44-.03-.14-.02-.28-.06-.42-.1l.15-1.23c.08.03.17.06.27.08.1.02.19.04.28.04.37,0,.62-.12.77-.35.14-.23.21-.57.21-1.02v-7.99h1.34Z"/>
                    <path className="cls-45" d="m128.74,51.94h1.34v1.08h.03c.17-.38.46-.68.88-.91.42-.23.9-.35,1.44-.35.34,0,.66.05.97.16.31.1.59.26.82.48.23.22.42.5.56.84.14.34.21.75.21,1.21v4.53h-1.34v-4.16c0-.33-.04-.61-.13-.84-.09-.23-.21-.42-.36-.56-.15-.14-.32-.25-.51-.31-.19-.06-.39-.1-.6-.1-.28,0-.54.04-.77.13-.24.09-.45.23-.63.42-.18.19-.32.44-.42.73-.1.3-.15.65-.15,1.06v3.62h-1.34v-7.04Z"/>
                    <path className="cls-45" d="m136.84,51.94h1.25v1.1h.03c.03-.1.11-.22.23-.37.12-.15.28-.29.48-.42.19-.13.42-.25.68-.34.26-.09.55-.14.87-.14.52,0,.96.11,1.32.33.36.22.65.54.88.98.23-.44.55-.76.97-.98.42-.22.84-.33,1.26-.33.54,0,.99.09,1.34.27.35.18.62.41.82.71.2.29.33.62.41,1,.07.37.11.76.11,1.15v4.1h-1.34v-3.92c0-.27-.02-.53-.05-.77-.03-.25-.11-.47-.21-.65-.11-.19-.26-.34-.46-.45-.2-.11-.46-.17-.79-.17-.64,0-1.1.2-1.38.59-.28.4-.42.91-.42,1.53v3.85h-1.34v-3.68c0-.34-.02-.64-.05-.92-.04-.28-.11-.52-.21-.72-.1-.2-.25-.36-.44-.48-.19-.11-.45-.17-.78-.17-.24,0-.47.05-.69.14-.22.09-.42.24-.59.42-.17.19-.3.43-.4.72-.1.29-.15.64-.15,1.03v3.65h-1.34v-7.04Z"/>
                    <path className="cls-45" d="m150.36,55.46c0,.36.06.69.16.99.11.3.26.56.46.77.2.21.44.38.73.51.29.12.61.18.96.18s.68-.06.97-.18c.29-.12.53-.29.73-.51.2-.21.35-.47.46-.77.11-.3.16-.63.16-.99s-.05-.69-.16-.99c-.11-.3-.26-.56-.46-.77-.2-.21-.44-.38-.73-.51-.29-.12-.61-.18-.97-.18s-.68.06-.96.18c-.29.13-.53.29-.73.51-.2.21-.35.47-.46.77-.11.3-.16.63-.16.99m-1.43,0c0-.51.1-1,.29-1.44.19-.44.46-.84.8-1.17.34-.34.73-.6,1.19-.79.46-.19.95-.29,1.47-.29s1.02.1,1.47.29c.46.19.85.46,1.19.79.34.34.6.73.8,1.17.19.45.29.93.29,1.44s-.1,1-.29,1.45c-.19.45-.46.84-.8,1.17-.34.33-.73.59-1.19.79-.46.19-.95.29-1.47.29s-1.01-.1-1.47-.29c-.46-.19-.85-.46-1.19-.79-.34-.33-.6-.72-.8-1.17-.19-.45-.29-.93-.29-1.45"/>
                    <path className="cls-45" d="m157.92,51.94h1.34v1.08h.03c.17-.38.46-.68.88-.91.42-.23.9-.35,1.44-.35.34,0,.66.05.97.16.31.1.58.26.82.48.23.22.42.5.56.84.14.34.21.75.21,1.21v4.53h-1.34v-4.16c0-.33-.04-.61-.13-.84-.09-.23-.21-.42-.36-.56-.15-.14-.32-.25-.51-.31-.19-.06-.39-.1-.6-.1-.28,0-.54.04-.77.13-.24.09-.45.23-.62.42-.18.19-.32.44-.42.73-.1.3-.15.65-.15,1.06v3.62h-1.34v-7.04Z"/>
                    <path className="cls-45" d="m169.39,57.91c.36,0,.68-.06.97-.19.29-.12.53-.29.73-.51.2-.21.35-.47.46-.77.11-.3.16-.63.16-.99s-.05-.69-.16-.99c-.11-.3-.26-.56-.46-.77-.2-.21-.44-.38-.73-.51-.29-.12-.61-.19-.97-.19s-.68.06-.97.19c-.29.12-.53.29-.73.51-.2.21-.35.47-.46.77-.11.3-.16.63-.16.99s.05.69.16.99c.11.3.26.56.46.77.2.21.44.38.73.51.29.13.61.19.97.19m3.65,1.07h-1.34v-1.01h-.03c-.26.38-.61.67-1.06.88-.45.21-.91.31-1.39.31-.54,0-1.04-.09-1.48-.28-.44-.19-.82-.45-1.13-.79-.31-.34-.55-.73-.72-1.17-.17-.44-.25-.93-.25-1.45s.08-1.01.25-1.46c.17-.45.41-.84.72-1.17.31-.33.69-.59,1.13-.78.44-.19.93-.28,1.48-.28.51,0,.98.11,1.43.32.44.21.79.5,1.02.87h.03v-5.2h1.34v11.23Z"/>
                </g>
                <g>
                    <path className="cls-23" d="m53.5,54.03c-1.88-1.02-2.82-2.32-2.82-3.98s.87-2.93,1.63-3.7c2.55-2.56,5.85-2.88,6.94-2.88.86,0,1.78.11,2.82.34,2.18.49,4.51-2.72,4.51-5.87,0-2.68-1.67-5.17-4.43-6.16-.92-.33-1.91-.48-2.9-.48-4.33,0-9.31,2.86-9.31,7.28,0,3.96,3.18,4.76,4.52,5.36.21.09.29.22.09.33-10.7,1.78-15.3,6.43-15.3,9.49,0,1.93,2.78,4.86,4.03,6.22.34.37.48.15.83-.18,1.25-1.18,4.12-3.66,8.27-4.83.33-.09,1.89-.47,1.11-.95Zm11.83-8.45c-.34-.03-.54.06-.3.37.36.47,1,1.29,1,2.48s-.62,2.25-1.47,3.11c-1.92,1.93-4.39,2.33-6.11,2.74,1.29-.16,2.6,0,3.71.59,2.52.62,5.09,1.72,6.74,3.38,1.64,1.65,2.17,3.45,1.05,6.29-.23.57-.72,1.51-.72,1.85,0,.1.06.15.15.15.34,0,.7-.15,1.22-.67,2.88-2.89,3.66-7.46,3.66-9.43,0-5.74-6.43-10.61-8.92-10.86Z"/>
                    <path className="cls-60" d="m65.93,64.77c-2.56-2.57-5.1-2.22-5.55-2.68-.01-.02-.02-.03-.02-.05,0-.03.02-.05.04-.07.22-.23,1.82-.5,2.97-1.66.55-.54.97-1.26.97-2.17,0-2.12-2.02-3.17-2.2-3.26-1.11-.59-2.42-.73-3.71-.59-2.56.3-5.27,1.68-5.27,4.3,0,1.74.93,2.95,1.74,3.31.19.09,1.07.31.55.5-.4.14-.82.21-1.23.31-.35.09-6.41,1.62-6.41,5.35,0,2.04,2.41,3.75,2.59,3.93.14.13.3.37.3.52v1.45c0,2.64,6.02,7.6,10.52,7.6,1.71,0,2.37-2.05,2.39-2.11.47-1.1.41-2.84.43-3.59.03-.74.06-1.19.48-1.84.27-.43,1.26-1.31,1.98-2.66.53-.99.93-2.1.93-3.23,0-1.41-.65-2.51-1.51-3.37Z"/>
                </g>
                </g>
                </a>
            </g>
        )
    }
    return (
        <g  id="Logo">
            <a href={`${props.url}`} rel="noopener noreferrer">
                <g id="Logo-2" data-name="Logo">
                <g>
                    <path className="cls-54" d="m78.62,50.73c-.3-.39-.67-.68-1.13-.89-.46-.21-.93-.31-1.41-.31-.59,0-1.13.11-1.61.33-.48.22-.89.53-1.23.91-.34.39-.61.84-.8,1.36-.19.52-.28,1.08-.28,1.67,0,.55.09,1.08.27,1.58.18.49.44.93.77,1.31.34.38.75.67,1.23.89.49.22,1.04.33,1.65.33s1.13-.12,1.59-.37c.46-.25.84-.6,1.16-1.04l1.2.91c-.08.11-.22.27-.42.47-.2.2-.46.41-.79.61-.33.2-.72.38-1.18.54-.46.15-.99.23-1.6.23-.83,0-1.58-.16-2.25-.48-.67-.32-1.24-.73-1.71-1.25-.47-.52-.83-1.1-1.08-1.74-.25-.65-.37-1.31-.37-1.97,0-.81.13-1.56.4-2.25.27-.69.64-1.28,1.12-1.78.48-.5,1.06-.89,1.73-1.17.67-.28,1.42-.42,2.23-.42.69,0,1.37.13,2.04.4.67.27,1.22.68,1.64,1.23l-1.19.91Z"/>
                    <path className="cls-54" d="m85.25,48.46h1.43v7.52c0,.66-.1,1.21-.31,1.63-.21.42-.47.75-.78,1-.31.24-.65.41-1,.51-.36.09-.68.14-.98.14-.74,0-1.37-.19-1.88-.57-.51-.38-.84-.96-.97-1.73l1.4-.31c.08.4.24.71.49.94.25.23.57.34.97.34.36,0,.64-.07.85-.21.21-.14.38-.32.5-.54.12-.22.2-.48.24-.76.04-.29.06-.58.06-.88v-7.06Z"/>
                    <path className="cls-54" d="m97.94,53.09v5.19c-.26.16-.55.3-.88.41-.33.12-.67.22-1.02.31-.35.08-.71.14-1.07.18-.36.04-.7.06-1.02.06-.89,0-1.69-.15-2.38-.45-.7-.3-1.29-.7-1.77-1.2-.48-.51-.85-1.09-1.1-1.76-.25-.67-.38-1.37-.38-2.12,0-.78.14-1.51.41-2.18.27-.67.65-1.26,1.13-1.75.48-.5,1.06-.88,1.73-1.17.67-.28,1.41-.42,2.21-.42.97,0,1.76.12,2.38.36.61.24,1.13.58,1.54,1l-1.01,1.09c-.44-.42-.9-.71-1.38-.87-.49-.16-.99-.24-1.53-.24-.6,0-1.15.11-1.63.33-.49.22-.9.52-1.25.9-.35.38-.61.82-.8,1.33-.19.51-.28,1.05-.28,1.63s.1,1.15.31,1.66c.21.51.49.95.86,1.33.37.38.8.67,1.29.88.5.21,1.04.32,1.62.32.51,0,.98-.05,1.43-.14.45-.09.84-.23,1.15-.41v-2.93h-2.33v-1.34h3.76Z"/>
                    <path className="cls-54" d="m105.94,53.09h1.87c.28,0,.55-.02.82-.07.27-.05.52-.13.73-.24.21-.12.39-.29.51-.51.13-.22.19-.51.19-.87s-.07-.65-.19-.87c-.13-.22-.3-.39-.51-.51-.21-.12-.45-.2-.73-.25-.27-.04-.55-.07-.82-.07h-1.87v3.39Zm-1.43-4.63h3.7c.67,0,1.23.09,1.66.28.43.18.78.42,1.03.7.25.28.43.6.53.95.1.35.15.69.15,1.02s-.06.66-.18.97c-.12.31-.29.59-.51.85-.22.25-.5.47-.82.64-.32.17-.68.28-1.08.32l2.99,4.8h-1.78l-2.67-4.64h-1.59v4.64h-1.43v-10.52Z"/>
                    <path className="cls-54" d="m113.15,51.94h1.34v7.04h-1.34v-7.04Zm-.31-2.53c0-.27.1-.5.29-.69.19-.19.42-.29.69-.29s.5.1.69.29c.19.19.29.42.29.69s-.1.5-.29.69c-.19.19-.43.29-.69.29s-.5-.1-.69-.29c-.19-.19-.29-.42-.29-.69"/>
                    <path className="cls-54" d="m116.18,49.41c0-.27.1-.5.29-.69.19-.19.42-.29.69-.29s.5.1.69.29c.19.19.29.42.29.69s-.1.5-.29.69c-.19.19-.42.29-.69.29s-.5-.1-.69-.29c-.19-.19-.29-.42-.29-.69m1.65,2.53v8.05c0,.18-.01.41-.04.7-.03.29-.1.57-.24.85-.13.27-.35.51-.64.71-.29.2-.71.3-1.24.3-.15,0-.29-.01-.44-.03-.14-.02-.28-.06-.42-.1l.15-1.23c.08.03.17.06.27.08.1.02.19.04.28.04.37,0,.62-.12.77-.35.14-.23.21-.57.21-1.02v-7.99h1.34Z"/>
                    <path className="cls-54" d="m119.76,51.94h1.34v1.08h.03c.17-.38.46-.68.88-.91.42-.23.9-.35,1.44-.35.34,0,.66.05.97.16.31.1.59.26.82.48.23.22.42.5.56.84.14.34.21.75.21,1.21v4.53h-1.34v-4.16c0-.33-.04-.61-.13-.84-.09-.23-.21-.42-.36-.56-.15-.14-.32-.25-.51-.31-.19-.06-.39-.1-.6-.1-.28,0-.54.04-.77.13-.24.09-.45.23-.63.42-.18.19-.32.44-.42.73-.1.3-.15.65-.15,1.06v3.62h-1.34v-7.04Z"/>
                    <path className="cls-54" d="m127.86,51.94h1.25v1.1h.03c.03-.1.11-.22.23-.37.12-.15.28-.29.48-.42.19-.13.42-.25.68-.34.26-.09.55-.14.87-.14.52,0,.96.11,1.32.33.36.22.65.54.88.98.23-.44.55-.76.97-.98.42-.22.84-.33,1.26-.33.54,0,.99.09,1.34.27.35.18.62.41.82.71.2.29.33.62.41,1,.07.37.11.76.11,1.15v4.1h-1.34v-3.92c0-.27-.02-.53-.05-.77-.03-.25-.11-.47-.21-.65-.11-.19-.26-.34-.46-.45-.2-.11-.46-.17-.79-.17-.64,0-1.1.2-1.38.59-.28.4-.42.91-.42,1.53v3.85h-1.34v-3.68c0-.34-.02-.64-.05-.92-.04-.28-.11-.52-.21-.72-.1-.2-.25-.36-.44-.48-.19-.11-.45-.17-.78-.17-.24,0-.47.05-.69.14-.22.09-.42.24-.59.42-.17.19-.3.43-.4.72-.1.29-.15.64-.15,1.03v3.65h-1.34v-7.04Z"/>
                    <path className="cls-54" d="m141.38,55.46c0,.36.06.69.16.99.11.3.26.56.46.77.2.21.44.38.73.51.29.12.61.18.96.18s.68-.06.97-.18c.29-.12.53-.29.73-.51.2-.21.35-.47.46-.77.11-.3.16-.63.16-.99s-.05-.69-.16-.99c-.11-.3-.26-.56-.46-.77-.2-.21-.44-.38-.73-.51-.29-.12-.61-.18-.97-.18s-.68.06-.96.18c-.29.13-.53.29-.73.51-.2.21-.35.47-.46.77-.11.3-.16.63-.16.99m-1.43,0c0-.51.1-1,.29-1.44.19-.44.46-.84.8-1.17.34-.34.73-.6,1.19-.79.46-.19.95-.29,1.47-.29s1.02.1,1.47.29c.46.19.85.46,1.19.79.34.34.6.73.8,1.17.19.45.29.93.29,1.44s-.1,1-.29,1.45c-.19.45-.46.84-.8,1.17-.34.33-.73.59-1.19.79-.46.19-.95.29-1.47.29s-1.01-.1-1.47-.29c-.46-.19-.85-.46-1.19-.79-.34-.33-.6-.72-.8-1.17-.19-.45-.29-.93-.29-1.45"/>
                    <path className="cls-54" d="m148.94,51.94h1.34v1.08h.03c.17-.38.46-.68.88-.91.42-.23.9-.35,1.44-.35.34,0,.66.05.97.16.31.1.58.26.82.48.23.22.42.5.56.84.14.34.21.75.21,1.21v4.53h-1.34v-4.16c0-.33-.04-.61-.13-.84-.09-.23-.21-.42-.36-.56-.15-.14-.32-.25-.51-.31-.19-.06-.39-.1-.6-.1-.28,0-.54.04-.77.13-.24.09-.45.23-.62.42-.18.19-.32.44-.42.73-.1.3-.15.65-.15,1.06v3.62h-1.34v-7.04Z"/>
                    <path className="cls-54" d="m160.41,57.91c.36,0,.68-.06.97-.19.29-.12.53-.29.73-.51.2-.21.35-.47.46-.77.11-.3.16-.63.16-.99s-.05-.69-.16-.99c-.11-.3-.26-.56-.46-.77-.2-.21-.44-.38-.73-.51-.29-.12-.61-.19-.97-.19s-.68.06-.97.19c-.29.12-.53.29-.73.51-.2.21-.35.47-.46.77-.11.3-.16.63-.16.99s.05.69.16.99c.11.3.26.56.46.77.2.21.44.38.73.51.29.13.61.19.97.19m3.65,1.07h-1.34v-1.01h-.03c-.26.38-.61.67-1.06.88-.45.21-.91.31-1.39.31-.54,0-1.04-.09-1.48-.28-.44-.19-.82-.45-1.13-.79-.31-.34-.55-.73-.72-1.17-.17-.44-.25-.93-.25-1.45s.08-1.01.25-1.46c.17-.45.41-.84.72-1.17.31-.33.69-.59,1.13-.78.44-.19.93-.28,1.48-.28.51,0,.98.11,1.43.32.44.21.79.5,1.02.87h.03v-5.2h1.34v11.23Z"/>
                </g>
                <g>
                    <path className="cls-41" d="m42.66,54.03c-1.88-1.02-2.82-2.32-2.82-3.98s.87-2.93,1.63-3.7c2.55-2.56,5.85-2.88,6.94-2.88.86,0,1.78.11,2.82.34,2.18.49,4.51-2.72,4.51-5.87,0-2.68-1.67-5.17-4.43-6.16-.92-.33-1.91-.48-2.9-.48-4.33,0-9.31,2.86-9.31,7.28,0,3.96,3.18,4.76,4.52,5.36.21.09.29.22.09.33-10.7,1.78-15.3,6.43-15.3,9.49,0,1.93,2.78,4.86,4.03,6.22.34.37.48.15.83-.18,1.25-1.18,4.12-3.66,8.27-4.83.33-.09,1.89-.47,1.11-.95Zm11.83-8.45c-.34-.03-.54.06-.3.37.36.47,1,1.29,1,2.48s-.62,2.25-1.47,3.11c-1.92,1.93-4.39,2.33-6.11,2.74,1.29-.16,2.6,0,3.71.59,2.52.62,5.09,1.72,6.74,3.38,1.64,1.65,2.17,3.45,1.05,6.29-.23.57-.72,1.51-.72,1.85,0,.1.06.15.15.15.34,0,.7-.15,1.22-.67,2.88-2.89,3.66-7.46,3.66-9.43,0-5.74-6.43-10.61-8.92-10.86Z"/>
                    <path className="cls-48" d="m55.1,64.77c-2.56-2.57-5.1-2.22-5.55-2.68-.01-.02-.02-.03-.02-.05,0-.03.02-.05.04-.07.22-.23,1.82-.5,2.97-1.66.55-.54.97-1.26.97-2.17,0-2.12-2.02-3.17-2.2-3.26-1.11-.59-2.42-.73-3.71-.59-2.56.3-5.27,1.68-5.27,4.3,0,1.74.93,2.95,1.74,3.31.19.09,1.07.31.55.5-.4.14-.82.21-1.23.31-.35.09-6.41,1.62-6.41,5.35,0,2.04,2.41,3.75,2.59,3.93.14.13.3.37.3.52v1.45c0,2.64,6.02,7.6,10.52,7.6,1.71,0,2.37-2.05,2.39-2.11.47-1.1.41-2.84.43-3.59.03-.74.06-1.19.48-1.84.27-.43,1.26-1.31,1.98-2.66.53-.99.93-2.1.93-3.23,0-1.41-.65-2.51-1.51-3.37Z"/>
                </g>
                </g>
            </a>
        </g>
    )

}