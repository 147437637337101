import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

export default function Merkcampagne(props) {
  const isDesctop = props.isDesctop;

  const desctopScreen = useRef();
  const mobileScreen = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      gsap.timeline({repeat:-1})
      .to('.con',
        { duration: 1.3,  transformOrigin: '50% 50%', y:"-5px",  rotation:"+=80", ease:'none'}        
      )
      .to('.con',
        { duration: 1.7,  transformOrigin: '50% 50%', y:"0",  rotation:"-=80", ease:'none'}      
      ) 

      gsap.timeline({repeat:-1})
      .to('.con-down',
        { duration: 1.8,  transformOrigin: '50% 50%', y:"+5",  rotation:"-=80", ease:'none'}      
      ) 
      .to('.con-down',
        { duration: 1.9,  transformOrigin: '50% 50%', y:"0",  rotation:"-=80", ease:'none'}      
      ) 
      
      const numbers = self.selector('.number');
      numbers.forEach((number) => {
        gsap.from(number, {
          textContent: 0,
          duration: 2,
          snap: { textContent: 1 },
          scrollTrigger: {
            trigger: number,
          },
        });
      });
    }, desctopScreen);
    return () => ctx.revert(); // <- Cleanup!     
  }, [])

  useLayoutEffect(() => {
    const ctxMobile = gsap.context((self) => {
      gsap.timeline({repeat:-1})
      .to('.con',
        { duration: 1.3,  transformOrigin: '50% 50%', y:"-5px",  rotation:"+=80", ease:'none'}        
      )
      .to('.con',
        { duration: 1.7,  transformOrigin: '50% 50%', y:"0",  rotation:"-=80", ease:'none'}      
      ) 

      gsap.timeline({repeat:-1})
      .to('.con-down',
        { duration: 1.8,  transformOrigin: '50% 50%', y:"+5",  rotation:"-=80", ease:'none'}      
      ) 
      .to('.con-down',
        { duration: 1.9,  transformOrigin: '50% 50%', y:"0",  rotation:"-=80", ease:'none'}      
      ) 
      
      const numbers = self.selector('.number');
      numbers.forEach((number) => {
        gsap.from(number, {
          textContent: 0,
          duration: 2,
          snap: { textContent: 1 },
          scrollTrigger: {
            trigger: number,
          },
        });
      });
    }, mobileScreen);
    return () => ctxMobile.revert(); // <- Cleanup!     
  }, [])

  if (isDesctop) {
    return (
        <>
        <g ref={desctopScreen} id="Merkcampagne">
    <path id="Ondergrond_extra_wolkje" data-name="Ondergrond extra wolkje" className="cls-100" d="m909.83,4765.44h-36.79c-13.13,0-23.77-10.64-23.77-23.76s10.64-23.77,23.77-23.77h10.81c13.13,0,23.77-10.64,23.77-23.77s-10.64-23.77-23.77-23.77h-326.67c-13.13,0-23.77,10.64-23.77,23.77s10.64,23.76,23.76,23.77h-40.41c-20.32,0-36.79,16.47-36.79,36.79s16.47,36.79,36.79,36.79h64.18c-13.13,0-23.77,10.64-23.77,23.77s10.64,23.77,23.77,23.77h328.88c20.32,0,36.79-16.47,36.79-36.79s-16.47-36.79-36.79-36.79Z"/>
    <g id="Team_communicatie" data-name="Team communicatie">
      <g id="Team_communicatie-2" data-name="Team communicatie">
        <path className="cls-23" d="m628.98,4590.79s-6.5-4.53-7.69.61-2.65,8.02-9.97,9.88c0,0,12.86,7.79,16.31.16,3.45-7.63,2.05-8.02,2.05-8.02l-.69-2.63Z"/>
        <path className="cls-86" d="m706.23,4687.3l6.78,12.75-14.24,21.01,7.12,8.89-1.09,4.45s4.24,7.23,16.38,5.29l9.41-47.7-.76-34.83-23.32,5.03-.29,25.12Z"/>
        <path className="cls-94" d="m753.22,4644.28s2.17,24.41-6.14,42.32l1.9,42.49-10.06,5.31-1.5-6.67-7.58-.18-.64-74.32,24.02-8.96Z"/>
        <path className="cls-23" d="m731.49,4727.55s-6.8,3.87-4.64,6.85c0,0,6.13-.82,10.58-6.67l-5.94-.18Z"/>
        <path className="cls-23" d="m738.92,4734.4s-4.09,3.38-1.5,8.14c0,0,6.49,1.9,9.66-8.14,0,0,3.58-1.5,1.9-5.31l-10.06,5.31Z"/>
        <path className="cls-23" d="m704.81,4734.4s-1.09,5.19,1.63,5.29c2.72.1,2.16.43,3.46,2.39,1.3,1.96,13.22,5.35,13.39,2.97s-4.94-7.19-4.94-7.19l-13.54-3.47Z"/>
        <path className="cls-23" d="m700.17,4722.81s-3.27,2.51-1.4,4.04c1.87,1.53,7.12,3.1,7.12,3.1v-5.31l-5.72-1.83Z"/>
        <polygon className="cls-94" points="767.35 4742.54 774.52 4735.63 770.07 4729.95 762.98 4733.22 767.35 4742.54"/>
        <path className="cls-94" d="m760.76,4737.87l-5.55,11.93,2.77,1.15s9.14-3.14,9.36-8.4,0-9.32,0-9.32l-6.59,4.64Z"/>
        <path className="cls-23" d="m755.65,4659.17l1.17,18.2s-8.11,9.05,0,34.92v27.4l16.44-7.61-2.83-33.03,1.53-17.23s9.51-2.56,11.98-27.37l-28.3,4.72Z"/>
        <path className="cls-23" d="m786.57,4584.29s-12.29-5.35-14.23,24.05l-.37,2.28,5.93.3,1.56-6.11s-1.97-14.97,7.63-17.79c0,0,6.68,10.57-.51,19.57v4.59l6.64-3.93s.21-27.94-6.64-22.97Z"/>
        <path className="cls-86" d="m748.98,4651.79l5.8,1.23.88,6.15s31.57,7.03,44.84-24.6l42.12-37.65-3.51-6.57s-19.4,19.11-45.89,16.9c0,0-24.73-1.68-30.7,12.2s-13.53,32.33-13.53,32.33Z"/>
        <polygon className="cls-94" points="713.49 4619.78 709.26 4622.6 704.81 4622.6 706.52 4662.19 729.84 4657.16 730.6 4640.57 728.86 4633.75 722.96 4619.17 716.68 4617.8 713.49 4619.78"/>
        <path className="cls-23" d="m716.56,4608.34l10.29-29.54h7.53l-9.55,41.47,9.29,25.42-4.26.25v-5.87s-3.21-22.54-16.35-20.29l-4.23,2.82h-4.45s7.94,38.66,1.42,64.71c0,0-6.43,6.67-9.41-9.21-2.98-15.88-3.3-18.9-3.3-18.9,0,0-12.39-3.15-6.51-19.13,5.88-15.97-1.42-20.47,14.38-24.66,15.8-4.18,15.16-7.07,15.16-7.07Z"/>
        <path className="cls-23" d="m856.29,4762.17s5.97-.98,12.75,11.49c0,0,3.25,9.03-3.99,4.33,0,0-2.34,2.4-1.21,7.83,1.13,5.43-14.36,15.76-16.11,11.79-1.76-3.97,2.6-6.47,2.41-11.79-.19-5.32-5.12-16.03-5.12-16.03l11.28-7.61Z"/>
        <path className="cls-23" d="m841.07,4738.46s4.8,1.53,5.11,13.34c0,0-1.01,7.93-4.6,1.7,0,0-2.65.89-3.86,5.35-1.22,4.45-16.61,6.26-16.41,2.65s4.38-3.82,6.25-7.84c1.87-4.02,2.27-13.83,2.27-13.83l11.25-1.38Z"/>
        <path className="cls-56" d="m829.56,4655.12v14.53l-16.41,47.95,14.4,36.07,3.83-3.44,10.42,30.09,32.35-11.52-30.5-54.09s20.61-25.81,19.96-39.41c-.65-13.61-3.02-16.96-3.02-16.96l-31.04-3.22Z"/>
        <path className="cls-65" d="m827.93,4596.47s-1.41-13.87-1.58-15.51c-.32-3.12,3.42,3.33,3.42,3.33l8.67-10.3c1.04,1.78-2.74,6.97-2.74,6.97,0,0,5.65-7.32,4.32-2.16s-7.88,13.48-7.88,13.48l-4.2,4.19Z"/>
        <path className="cls-65" d="m880.31,4606s1.13-16.15,16.65-13.4c15.52,2.74-11,11.14-11.85,15.83l-4.8-2.43Z"/>
        <path className="cls-97" d="m840.55,4613.69s-9.55-21.88-1.87-29.73c7.68-7.84,8.87-2.44,8.87-2.44,0,0,4.62-7.68,13.54,5.19,0,0,1.45,3.32,6.64,3.53,5.19.21.42,5.81,3.74,10.38,3.32,4.57,4.36,8.99-1.66,9.27-6.02.28-29.26,3.8-29.26,3.8Z"/>
        <path className="cls-65" d="m840.26,4587.3s-7.12,5.24-1.72,11.35c0,0-1.08,1.8,1.72,5.03,2.8,3.23,6.04,8.41,13.8,5.17,7.76-3.23,3.45-10.56,2.37-13.8-1.08-3.23-11.21-17.46-16.17-7.76Z"/>
        <ellipse className="cls-65" cx="856.11" cy="4593.35" rx="1.84" ry="3.51" transform="translate(-222.51 47.1) rotate(-2.79)"/>
        <path className="cls-23" d="m840.55,4613.69s-7.63-1.54-8.42-21.41l-8.14,4.19s-3.64,24.44,11.71,27.91c0,0-16.54,27.11-3.61,32.36,12.92,5.25,29.74,11.3,30.79-6.04l1.05-17.34s10.16,17.08,18.65,4.99,8.49-33.19,8.49-33.19h-12.17l-1.58,10.26s1.02-4.57-7.5-5.53c-8.52-.96-29.26,3.8-29.26,3.8Z"/>
        <path className="cls-56" d="m658.77,4719.73s-3.25,4.37-2.69,7.82c.56,3.45,4.16,14.87,8.63,13.96,0,0,5.45.34,1.2-8.29-4.25-8.62-2.44-14.88-2.44-14.88l-4.7,1.39Z"/>
        <path className="cls-56" d="m672.42,4717.59s-3.75,5.76-1.75,13.51c0,0,1.13,1.7,1.75-1.15s1.25,8.81,8.01,7.86c6.76-.95-.63-5.83-1.63-10.08-1-4.26-1.14-9.45-1.14-9.45l-5.25-.69Z"/>
        <path className="cls-23" d="m655.16,4645.69l-2.46,8.29v13.02l2.46,53.29,9.55-1.48,2.44-51.16s4.69,21.7,4.69,24.34-.99,26.08-.99,26.08l8.14.25s4.2-27.64,1.97-39c-2.22-11.35-7.31-37.76-7.31-37.76l-18.5,4.13Z"/>
        <polygon className="cls-94" points="655.16 4645.69 671.68 4644.28 677.2 4602.31 665.13 4598.71 652.23 4605.16 655.16 4645.69"/>
        <path className="cls-45" d="m678.48,4572.23l9.12,1.93s-5.45,28.61-9.12,32.57c-3.67,3.96,6.92,42.65,6.92,42.65l-14.51,4.61-4.61-14.97,4.61-33.01-5.76-7.29-7.42.42-3.64,27.44,1.08,19.11-2.46,8.29v15.66l-5.99-14.53-7.37-52.81s9.67,1.48,14.74-3.36,17.73-3.68,17.73-3.68c0,0,6.43-11.51,6.67-23.03Z"/>
        <path className="cls-23" d="m645.06,4594.19s.85-9.7-10.24-8.35c-11.1,1.35-8.65,14.29-6.53,16.47l16.77-8.12Z"/>
        <path className="cls-23" d="m639.07,4727.34s-3.11,2.21-1.27,11.83c0,0,2.09,6.22,3.53.45,0,0,2.02.19,3.65,3.56,1.63,3.36,12.78,1.73,12.01-1.15s-3.75-2.21-5.77-5.09c-2.02-2.88-4.01-10.74-4.01-10.74l-8.16,1.15Z"/>
        <path className="cls-94" d="m637.27,4697.9s3.88,4.21,1.97,8.41c-1.91,4.2-10.08,26.91-10.08,26.91l8.16-1.15s14.71-24.29,14.9-27.8-2.48-8.08-2.48-8.08l-12.47,1.7Z"/>
        <path className="cls-94" d="m655.47,4693.75s1.37,2.45,0,7.85c-1.37,5.4-8.35,25.95-8.35,25.95l-6.88-.39,9.51-29.26,4.48-5.91,1.24,1.76Z"/>
        <path className="cls-23" d="m629.16,4733.22s-3.11,2.21-1.27,11.83c0,0,2.09,6.22,3.53.45,0,0,2.02.19,3.65,3.56,1.63,3.36,12.78,1.73,12.01-1.15s-3.75-2.21-5.77-5.09c-2.02-2.88-4.01-10.74-4.01-10.74l-8.16,1.15Z"/>
        <path className="cls-23" d="m590.38,4609.78l-45.95-19.42s.62-9.37-6.72-4.83c-7.34,4.54-8.02,25.52,1.28,13.78l40.6,24.1-3.35,34.93s9.1,10.89,27.09,3.63l10.73-19.73,4.99-13.55-4.08-15.9-24.59-3Z"/>
        <path className="cls-97" d="m631.65,4610.18l-13.66,9.28s-8.58,25.1-3.02,35.73l3.33,5.36s-3.44,7.83-3.34,14.74l2.51,1.45s-1.5,1.32-1.4,3.95l2.23,1.21-2.13,4.69,2.6.67s.07,7.59,0,8.81,1.55,0,1.55,0l.3,2.53,3.96-1.21,2.01,2.53s4.66.51,6.07-.51c0,0,4.46,1.62,4.61-1.52,0,0,3.39,1.72,6.93.51,3.54-1.21,6.88.71,8.3-1.11,1.42-1.82,3.64-4.96,5.67-5.06,2.02-.1-1.72-9.01-1.72-9.01l2.32-.61-5.26-9.72,2.63-1.42s-3.95-3.04-4.96-9.11c-1.01-6.07-.65-23.3-.65-23.3,0,0,6.13-29.01-7.89-26.54l-10.99-2.35Z"/>
        <path className="cls-94" d="m576.24,4658.34s-9.97,16.18.95,38.06l7.09,16.54s-2.23,21.04,0,37.29l10.04-.43s8.27-41.49,0-65.46l3.68,54.04,8.73-.56s-1.4-30.76,0-42.2c1.4-11.44-.35-23.46-3.4-33.64l-27.09-3.63Z"/>
        <path className="cls-97" d="m602.95,4590.97c-2.36.97-12.62,5.75-6.31,19.48l-6.12-2.04s-.74.93,1.11,2.04c1.86,1.11,12.24,3.52,2.04,8.35,0,0,2.23,2.41,6.12.74,0,0-2.04,9.46,3.15,9.65,5.19.19,8.74-5.62-3.11-22.69l4.22-10.52,7.42.37s1.11,20.6,2.23,24.31c1.11,3.7-2.78,5.93-2.78,5.93,0,0,2.13.74,4.03-1.3,1.9-2.04,6.71-15.69,1.35-25.99-4.77-9.15-9.71-9.83-13.36-8.33Z"/>
        <path className="cls-65" d="m612.77,4614.1l-2.4,6.83s-2.37,1.92-4.57-.97l-1.1-3.01-2.29-5.82,2.15-1.36,8.2,4.32Z"/>
        <path className="cls-70" d="m584.28,4750.23s-4.42,7.15,1.7,10.67c6.12,3.52,17.58,5.96,16.97,1.07-.61-4.89-7.01-7.36-8.63-12.16l-10.04.43Z"/>
        <path className="cls-70" d="m597.99,4738.38s-4.8,6.31,0,9.52,12.29,6.12,14.28,3.21c2.52-3.68-.31-7.34-3.36-7.64-3.06-.31-2.18-5.65-2.18-5.65l-8.73.56Z"/>
        <path className="cls-65" d="m527.18,4591.38c.46,1.51,2.03,2.37,3.51,2.95,2.9,1.15,5.99,1.85,9.11,2.07.17.01.35.02.51-.05.23-.11.34-.38.42-.63.55-1.75.49-3.69-.24-5.37-.73-1.68-2.15-3.06-3.88-3.67-1.05-.38-2.19-.46-3.31-.45-1.55.02-3.16.26-4.44,1.14-1.28.88-2.12,2.53-1.67,4.01Z"/>
        <path className="cls-65" d="m635.72,4589c.58-.06,12.82-.19,9.71,13.98-3.11,14.17-10.87,12.04-13.79,4.27-2.91-7.77-5.63-17.28,4.08-18.25Z"/>
        <ellipse className="cls-65" cx="629.32" cy="4601.87" rx="1.12" ry="2.83" transform="translate(-1719.88 595.06) rotate(-22.6)"/>
        <path className="cls-65" d="m631.65,4607.25v2.93s5.69,10.01,10.99,2.35l.34-2.76-11.34-2.53Z"/>
        <polygon className="cls-84" points="625.37 4683.43 621.73 4694.36 623.55 4694.97 626.18 4686.36 624.57 4697.4 626.18 4697.4 626.99 4696.08 627.7 4697.9 629.16 4697.4 629.16 4695.47 630.33 4695.68 630.33 4682.52 631.45 4695.68 632.66 4695.57 632.66 4675.3 627.2 4677.37 625.37 4683.43"/>
        <path className="cls-65" d="m603.7,4593.34s8.96-3.13,10.87,7.12c1.91,10.26-3.72,19.82-7.6,16.18-3.89-3.64-14.97-17.89-3.27-23.3Z"/>
        <path className="cls-65" d="m629.95,4611.33s-3.53-5.03-5.38-4.09c0,0-4.66-2.05-4.44,1.98s3.43,6.43,3.43,6.43l6.38-4.33Z"/>
        <path className="cls-65" d="m680.75,4574.16s5.35,3,6.85,0c1.5-3-2.35-16.7,0-19.27,2.35-2.57-.07-12.42-3.25-12.63-3.18-.21-1.89,6.64-2.53,6.21-.64-.43-4.83-3.01-2.78,0,3.21,4.71,3.85,19.48,1.71,25.69Z"/>
        <path className="cls-65" d="m657.72,4599.14s-2.92,8.93,0,11.05c2.92,2.11,8-.59,8.16-4.24s-.74-7.22-.74-7.22l-7.42.42Z"/>
        <path className="cls-97" d="m662.1,4578.8s-11.68.05-9.87,21.54l1.26,18.17,6.25-5.15-2.67-19.35s-.72-14.47,5.03-10.98c5.76,3.49,4.71,9.56,3.8,12.19-.92,2.63,2.08,14.56,2.08,14.56,0,0,2.97,6.34,5,0,2.02-6.34-.05-30.61-10.87-30.98Z"/>
        <path className="cls-38" d="m696.83,4634.4c.83,0,27.81-5.2,27.81-5.2l4.57,19.32-27.4,6.61-4.98-20.72Z"/>
        <ellipse className="cls-65" cx="655.16" cy="4592.28" rx="1.12" ry="2.83" transform="translate(-222.7 37.32) rotate(-2.79)"/>
        <ellipse className="cls-65" cx="662.1" cy="4591.42" rx="6.51" ry="10.46"/>
        <path className="cls-65" d="m841.69,4608.42s-10.27,7.91-5.99,11.85c4.28,3.94,11.8-12.08,17.68-9.08s12.61,1.56,5.22-.77c-7.39-2.33-4.54-1.57-4.54-1.57,0,0,11.56-.02,11.8-1.62s5.13-6.83-6.1-4.94c-11.23,1.89,11.48-7.74,6.35-8.3-5.13-.56-15.45,8.54-14.4,4.98,1.05-3.56-2.01-12.94-2.01-5.64s-8.01,15.08-8.01,15.08Z"/>
        <path className="cls-97" d="m781.34,4754.33s-19.15,4.24-5.08,7.82,32.3,1.93,23.54-3.91c-8.75-5.84-18.46-3.91-18.46-3.91Z"/>
        <path className="cls-97" d="m806.08,4737.87s-23.76-.26-4,6.04c19.76,6.3,20.64,2.91,15.47-4.36l-11.48-1.68Z"/>
        <path className="cls-45" d="m810.66,4668.8s11.9,18.13-11.7,32.88l-1.26,6.11,24.55,32.58-15.98,4.38-22.94-34.98-3.66,10.52,21.29,34.03s-.34,14.63-19.62,0l-14.29-30.91s-8.34-9.75,3.39-24.37c14.55-18.12,16.38-31.84,16.38-31.84l23.84,1.59Z"/>
        <path className="cls-65" d="m787.08,4587.03s-11.97-2.06-8.58,14.58c2.69,13.18,10.87,3.82,11.35-1.48.45-4.93-.11-9.51-2.78-13.1Z"/>
        <polygon className="cls-65" points="778.93 4603.33 777.9 4610.93 786.57 4611.2 786.57 4603.33 778.93 4603.33"/>
        <path className="cls-23" d="m797.7,4634.03s1.33-33.01,12.47-32.16c11.15.85,13.44,21.94,13.44,21.94l-4.95,5.34-6.71-3.46-14.25,8.33Z"/>
        <path className="cls-97" d="m836.52,4611.33l1.78,13.04s-26.89,24.78-27.64,44.42l-23.84-1.59s-1.86-11.14,4.51-22.92c4.03-7.45,6.37-10.25,6.37-10.25l38.82-22.7Z"/>
        <path className="cls-65" d="m814.24,4605.74s-10.03-3.67-12.1,5.18c-2.07,8.85-2.33,6.2,0,11.28,2.33,5.08,2.94,10.77,9.81,3.5,6.49-6.86,7.47-7.62,2.29-19.96Z"/>
        <path className="cls-65" d="m750.01,4652.01s-5.84,9.32-1.03,11.56c4.81,2.24,8.51-.28,4.59-11.56h-3.55Z"/>
        <path className="cls-45" d="m732.07,4614.4s-6.42,5.77-8.6-3.07c0,0,3.02.94,2.42-7.27-.6-8.21-.8-14.37,6.18-16.06,6.98-1.69,7.95,1.22,7.71,2.36,0,0,3.5-2.12,3.62,4.28s4.36,14.48-2.89,22.09c0,0-4.11,1.34-4.23-2.17s-4.21-.17-4.21-.17Z"/>
        <path className="cls-65" d="m734.47,4591.42s4.81-1.2,5.16,3.43,2.14,5.49-.71,10.31c-2.85,4.82-7.86,8.28-9.71,1.57s-1.5-14.65,5.26-15.31Z"/>
        <polygon className="cls-65" points="738.92 4605.16 738.08 4610.93 733.25 4621.69 731.08 4608.34 738.92 4605.16"/>
        <path className="cls-97" d="m734.1,4645.69s-3.34-7.5-4.9-13.84-4.42-11.99,0-14.87c4.42-2.88,19.16-12.74,24.02-3.56s4.86,25.98,2.87,28.73c-1.99,2.75-18.3,11.08-18.3,11.08l-1.27-8.96-2.41,1.41Z"/>
        <path className="cls-65" d="m736.52,4644.28s-5.74.52-9.8,3.29c-2.52,1.71-4.45,2.58-4.45,2.58,0,0,9.69,7.23,15.16-.5l-.9-5.37Z"/>
        <path className="cls-60" d="m714.15,4614.6s5.17-3.72,4.28-13.2c-.89-9.47-7.14-5.42-7.14-5.42,0,0-4.74-4.74-9.89,2.33s0,17.09,0,17.09h1.61l.37-1.54,10.76.74Z"/>
        <polygon className="cls-65" points="703.38 4613.86 702.52 4617.43 704.81 4622.6 709.26 4622.6 713.49 4619.78 714.38 4612.69 714.38 4609.08 703.38 4613.86"/>
        <ellipse className="cls-65" cx="702.14" cy="4609.31" rx="1.12" ry="2.83" transform="translate(-1370.95 433.06) rotate(-17.74)"/>
        <path className="cls-65" d="m714.38,4600.03s-3.12-2.35-5.21,0c-2.09,2.35-6.15,3.06-6.28,6.7s-.02,13.18,5.75,12.4c5.77-.78,4.2-5.36,5.75-7.75,2.87-4.46,0-11.34,0-11.34Z"/>
        <path className="cls-65" d="m728.24,4578.8s.81-7.24-4.77-7.89c-5.57-.65-7.08-3.58-4.94-3.25,2.14.33-4.59-9.93,3.74-5.37,8.32,4.56,5.72,8.62,8.81.65,3.09-7.97-.81,10.74,1.3,16.92l-4.14-1.06Z"/>
        <path className="cls-65" d="m708.45,4654.45s3.74-4.95,4.56-4.08c.82.87-3.92,5.29-1.18,4.08s3.83-2.67,3.83-2.67c0,0,9.01-2.97,4.02,2.67-4.98,5.64-11.23,7.13-11.23,7.13v-7.13Z"/>
        <polygon className="cls-23" points="705.48 4654.23 708.89 4653.46 708.45 4662.19 705.48 4662.19 705.48 4654.23"/>
        <path className="cls-84" d="m634.12,4621.55s5.47-1.17,10.95,2.26c5.48,3.43,8.58,1.04,8.58,1.04l-10.67,10.06-16.52-7.8,1.07-3.88,6.58-1.69Z"/>
        <path className="cls-70" d="m660.69,4619.06l-26.43,52.07s-4.27-1.11-8.86-8.86c-4.59-7.76-7.94-20.03-7.94-20.03l-3.62-24.8s35.29,19.19,46.85,1.63Z"/>
        <path className="cls-45" d="m619.49,4620.13l2.44-1.68s4.76.84,4.86,3.1c0,0,2.83-6.24,4.86-4.52,2.03,1.72,4.46,2.33,3.85,3.44s-6.18,3.34-6.18,3.34c0,0-5.59.23-9.83-3.68Z"/>
        <path className="cls-65" d="m627.64,4665.61s-5.61,3.25-1.66,7.6c0,0-2.43,6.07,4.15,3.54,6.58-2.53,2.16-6.62,2.16-6.62l-4.64-4.52Z"/>
      </g>
      <g>
        <path className="cls-7" d="m630.82,4596.54s4.22-3.54,6.09,0c1.87,3.54-5.6,5.6-6.09,0Z"/>
        <path className="cls-7" d="m638.63,4596.67s1.95-3.46,5.56-.68c3.61,2.78-4.05,7.22-5.56.68Z"/>
        <path className="cls-7" d="m630.82,4596.54s-1.76-.22-2.3,1.16"/>
        <path className="cls-7" d="m636.92,4596.87s.74-1.01,1.72-.19"/>
        <path className="cls-7" d="m644.8,4596.61s.3-.73,1.13-.49"/>
      </g>
    </g>
    <text transform="translate(480.11 4875.74)"><tspan className="cls-26"><tspan x="0" y="0">CJG Rijnmond</tspan></tspan><tspan className="cls-33"><tspan x="50.73" y="18">1 naam, 1 website</tspan></tspan></text>
    <text transform="translate(570.87 4512.64)"><tspan className="cls-26"><tspan x="0" y="0">Corporate story</tspan></tspan><tspan className="cls-33"><tspan x="23.41" y="18">het verhaal van CJG Rijnmond</tspan></tspan></text>
    <text transform="translate(396.73 4559.97)"><tspan className="cls-24"><tspan className="number" x="0" y="0">376</tspan><tspan>.</tspan><tspan  className="number">373</tspan></tspan><tspan className="cls-33"><tspan x="-21.16" y="18">magazines GROEI! </tspan></tspan><tspan className="cls-33"><tspan x="-8.14" y="36">huis-aan-huis</tspan></tspan></text>
    <text transform="translate(798.41 4868.23)"><tspan className="cls-24"><tspan x="0" y="0">Campagne</tspan></tspan><tspan className="cls-33"><tspan x="-1.5" y="18">outdoor en online</tspan></tspan></text>
    <g id="Krant">
      <path className="cls-106" d="m425.39,4518.65h25.36c.63,0,1.15-.5,1.15-1.11s-.51-1.11-1.15-1.11h-25.36c-.63,0-1.15.5-1.15,1.11s.51,1.11,1.15,1.11Z"/>
      <path className="cls-106" d="m425.39,4511.05h25.36c.63,0,1.15-.5,1.15-1.11v-10.94c0-.61-.51-1.11-1.15-1.11h-25.36c-.63,0-1.15.5-1.15,1.11v10.94c0,.61.51,1.11,1.15,1.11Z"/>
      <path className="cls-106" d="m425.39,4525.68h25.36c.63,0,1.15-.5,1.15-1.11s-.51-1.11-1.15-1.11h-25.36c-.63,0-1.15.5-1.15,1.11s.51,1.11,1.15,1.11Z"/>
      <path className="cls-38" d="m458.17,4488.08h-28.5c-2,0-3.63,1.58-3.63,3.51v.37h30.89c.53,0,.96.41.96.92v36.88c0,.46-.36.84-.82.91-.05,0-.09.01-.14.01-.53,0-.96-.41-.96-.92v-35.58c0-.2-.17-.37-.38-.37h-35.04c-.21,0-.38.17-.38.37v39.28c0,1.94,1.63,3.51,3.64,3.51h34.38c2,0,3.63-1.58,3.63-3.51v-41.87c0-1.94-1.63-3.51-3.63-3.51Zm-32.78,35.38h25.36c.63,0,1.15.5,1.15,1.11s-.51,1.11-1.15,1.11h-25.36c-.63,0-1.15-.5-1.15-1.11s.51-1.11,1.15-1.11Zm-1.15-5.93c0-.61.51-1.11,1.15-1.11h25.36c.63,0,1.15.5,1.15,1.11s-.51,1.11-1.15,1.11h-25.36c-.63,0-1.15-.5-1.15-1.11Zm27.65-15.89v8.3c0,.61-.51,1.11-1.15,1.11h-25.36c-.63,0-1.15-.5-1.15-1.11v-10.94c0-.61.51-1.11,1.15-1.11h25.36c.63,0,1.15.5,1.15,1.11v2.64Z"/>
    </g>
    <text transform="translate(641.21 4797.19)"><tspan className="cls-24"><tspan x="0" y="0">3 november </tspan></tspan><tspan className="cls-33"><tspan x="-21.61" y="18">finale Galjaardprijs 2022</tspan></tspan></text>
    <g>
      <path className="cls-23" d="m420.69,4633.6l-3.6,1.4h-.16v-3.12l5-2.04h2.18v15h-3.42v-11.24Z"/>
      <path className="cls-23" d="m428.49,4637.34c0-5.78,1.88-7.66,5.64-7.66s5.62,1.88,5.62,7.66-1.88,7.66-5.62,7.66-5.64-2.04-5.64-7.66Zm7.82,0c0-3.76-.48-4.68-2.18-4.68s-2.2.92-2.2,4.68.48,4.68,2.2,4.68,2.18-.94,2.18-4.68Z"/>
      <path className="cls-23" d="m333.12,4662.28c.14-.46.26-.92.34-1.42l-.02.02c-.16-.08-.56-.16-.94-.16-1.18,0-1.88.7-1.88,1.86v1.42c0,1.4.78,2.02,2.18,2.02,1.02,0,2.36-.22,3.28-.54h.16v2.82c-.78.38-2.42.7-3.52.7-3.12,0-5.54-1.1-5.54-4.22v-2.34c0-2.9,2.42-4.7,5.32-4.7,1.4,0,2.66.24,3.74.78v.4c0,1.18-.2,2.32-.54,3.36h-2.58Z"/>
      <path className="cls-23" d="m350.86,4668.3c-1.04.34-2.82.54-3.98.54h-.94v-1.48l-.78.54c-1,.68-1.88,1.1-3.06,1.1-2.26,0-3.5-1.34-3.5-3.6v-2.18c0-3.28,2.5-5.32,5.78-5.32h5v8.36c.54-.08,1.06-.18,1.48-.32v2.36Zm-4.92-3.44v-4.14h-1.26c-1.72,0-2.64.78-2.64,2.5v1.56c0,.86.38,1.24,1.24,1.24.62,0,1.4-.38,2.04-.78l.62-.38Z"/>
      <path className="cls-23" d="m372.42,4668.3c-1.04.34-2.82.54-3.98.54h-.94v-6.72c0-.86-.16-1.4-.94-1.4-.54,0-1.26.44-1.96.94l-.54.38v6.8h-3.44v-6.72c0-.86-.16-1.4-.94-1.4-.54,0-1.26.44-1.94.94l-.56.38v6.8h-3.44v-8.36c-.56.08-1.08.2-1.48.32v-2.36c1.04-.34,2.82-.54,3.98-.54h.94v1.66l.4-.32c1.18-.98,2.1-1.5,3.44-1.5,1.44,0,2.4.7,2.8,1.88h.16l.48-.38c1.18-.98,2.1-1.5,3.42-1.5,2.04,0,3.06,1.34,3.06,3.44v5.08c.54-.08,1.06-.18,1.48-.32v2.36Z"/>
      <path className="cls-23" d="m373.82,4658.44c1.04-.34,2.82-.54,3.98-.54h.94v1.48l.78-.54c1-.7,1.88-1.1,3.06-1.1,2.26,0,3.5,1.34,3.5,3.6v2.18c0,3.28-2.5,5.32-5.78,5.32h-1.56v3.76h-3.44v-12.12c-.56.08-1.08.2-1.48.32v-2.36Zm4.92,3.44v4.14h1.28c1.7,0,2.64-.78,2.64-2.5v-1.56c0-.86-.4-1.24-1.26-1.24-.62,0-1.38.38-2.04.78l-.62.38Z"/>
      <path className="cls-23" d="m400.54,4668.3c-1.04.34-2.82.54-3.98.54h-.94v-1.48l-.78.54c-1,.68-1.88,1.1-3.06,1.1-2.26,0-3.5-1.34-3.5-3.6v-2.18c0-3.28,2.5-5.32,5.78-5.32h5v8.36c.54-.08,1.06-.18,1.48-.32v2.36Zm-4.92-3.44v-4.14h-1.26c-1.72,0-2.64.78-2.64,2.5v1.56c0,.86.38,1.24,1.24,1.24.62,0,1.4-.38,2.04-.78l.62-.38Z"/>
      <path className="cls-23" d="m403.74,4669.38c.9.22,1.9.4,2.82.4,1.7,0,3.12-.6,3.12-2.74v-.08l-.78.4c-.84.4-1.72.86-2.9.86-2.34,0-3.66-1.26-3.66-3.6v-1.4c0-3.28,2.5-5.32,5.78-5.32h5v9.38c0,4.08-3.02,5.32-6.26,5.32-1.04,0-2.1-.1-3.12-.4v-2.82Zm5.94-4.92v-3.74h-1.26c-1.72,0-2.64.78-2.64,2.5v.78c0,.86.38,1.24,1.24,1.24.7,0,1.52-.28,2.12-.54l.54-.24Z"/>
      <path className="cls-23" d="m428.98,4668.3c-1.04.34-2.82.54-3.98.54h-.94v-6.72c0-.86-.16-1.4-1.02-1.4-.62,0-1.4.48-2.12.94l-.62.38v6.8h-3.44v-8.36c-.56.08-1.08.2-1.48.32v-2.36c1.04-.34,2.82-.54,3.98-.54h.94v1.66l.48-.32c1.24-.9,2.18-1.5,3.58-1.5,2.12,0,3.14,1.34,3.14,3.44v5.08c.54-.08,1.06-.18,1.48-.32v2.36Z"/>
      <path className="cls-23" d="m430.46,4664.54v-2.1c0-3.14,2.42-4.7,5.54-4.7,2.74,0,4.92.86,4.92,3.6,0,2.34-1.7,3.2-4.06,3.2h-2.96v.08c0,1.18,1.18,1.4,2.34,1.4s2.42-.14,3.6-.46h.16v2.82c-1.26.46-3.08.62-4.08.62-3.22,0-5.46-1.26-5.46-4.46Zm3.44-2.02h2.74c.7,0,1.16-.24,1.16-.94,0-.94-.86-1.02-1.8-1.02-1.32,0-2.1.46-2.1,1.8v.16Z"/>
      <path className="cls-23" d="m442.42,4654.38c1.02-.34,2.8-.54,3.98-.54h.94v5.54l.78-.54c.98-.7,1.86-1.1,3.04-1.1,2.26,0,3.52,1.34,3.52,3.6v2.18c0,3.28-2.5,5.32-5.78,5.32h-5v-12.42c-.58.08-1.1.2-1.48.32v-2.36Zm4.92,7.5v4.14h1.26c1.7,0,2.64-.78,2.64-2.5v-1.56c0-.86-.4-1.24-1.26-1.24-.62,0-1.38.38-2.02.78l-.62.38Z"/>
      <path className="cls-23" d="m456.54,4664.54v-2.1c0-3.14,2.42-4.7,5.54-4.7,2.74,0,4.92.86,4.92,3.6,0,2.34-1.7,3.2-4.06,3.2h-2.96v.08c0,1.18,1.18,1.4,2.34,1.4s2.42-.14,3.6-.46h.16v2.82c-1.26.46-3.08.62-4.08.62-3.22,0-5.46-1.26-5.46-4.46Zm3.44-2.02h2.74c.7,0,1.16-.24,1.16-.94,0-.94-.86-1.02-1.8-1.02-1.32,0-2.1.46-2.1,1.8v.16Z"/>
      <path className="cls-23" d="m468.88,4664.54v-2.1c0-3.14,2.42-4.7,5.54-4.7,2.74,0,4.92.86,4.92,3.6,0,2.34-1.7,3.2-4.06,3.2h-2.96v.08c0,1.18,1.18,1.4,2.34,1.4s2.42-.14,3.6-.46h.16v2.82c-1.26.46-3.08.62-4.08.62-3.22,0-5.46-1.26-5.46-4.46Zm3.44-2.02h2.74c.7,0,1.16-.24,1.16-.94,0-.94-.86-1.02-1.8-1.02-1.32,0-2.1.46-2.1,1.8v.16Z"/>
      <path className="cls-23" d="m480.84,4654.38c1.02-.34,2.8-.54,3.98-.54h.94v12.42c.54-.08,1.06-.18,1.48-.32v2.36c-1.04.34-2.82.54-3.98.54h-.94v-12.42c-.58.08-1.1.2-1.48.32v-2.36Z"/>
      <path className="cls-23" d="m501.3,4668.3c-1.04.34-2.82.54-3.98.54h-.94v-1.48l-.78.54c-1,.68-1.88,1.1-3.06,1.1-2.26,0-3.5-1.34-3.5-3.6v-2.18c0-3.28,2.5-5.32,5.78-5.32h1.56v-1.48c-.56.08-1.1.2-1.48.32v-2.36c1.02-.34,2.8-.54,3.98-.54h.94v12.42c.54-.08,1.06-.18,1.48-.32v2.36Zm-4.92-3.44v-4.14h-1.26c-1.72,0-2.64.78-2.64,2.5v1.56c0,.86.38,1.24,1.24,1.24.62,0,1.4-.38,2.04-.78l.62-.38Z"/>
      <path className="cls-23" d="m502.78,4664.54v-2.1c0-3.14,2.42-4.7,5.54-4.7,2.74,0,4.92.86,4.92,3.6,0,2.34-1.7,3.2-4.06,3.2h-2.96v.08c0,1.18,1.18,1.4,2.34,1.4s2.42-.14,3.6-.46h.16v2.82c-1.26.46-3.08.62-4.08.62-3.22,0-5.46-1.26-5.46-4.46Zm3.44-2.02h2.74c.7,0,1.16-.24,1.16-.94,0-.94-.86-1.02-1.8-1.02-1.32,0-2.1.46-2.1,1.8v.16Z"/>
      <path className="cls-23" d="m528.64,4668.3c-1.04.34-2.82.54-3.98.54h-.94v-6.72c0-.86-.16-1.4-1.02-1.4-.62,0-1.4.48-2.12.94l-.62.38v6.8h-3.44v-8.36c-.56.08-1.08.2-1.48.32v-2.36c1.04-.34,2.82-.54,3.98-.54h.94v1.66l.48-.32c1.24-.9,2.18-1.5,3.58-1.5,2.12,0,3.14,1.34,3.14,3.44v5.08c.54-.08,1.06-.18,1.48-.32v2.36Z"/>
      <path d="m339,4679.4c.41-.11,1.26-.22,2.13-.22h.17v1.37l.49-.49c.55-.55,1.21-1,2.14-1s1.65.43,1.91,1.6h.11l.56-.55c.55-.55,1.19-1.05,2.12-1.05,1.15,0,2.03.66,2.03,2.63v4.02c.43-.03.87-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-5.14c0-1.21-.49-1.54-1.15-1.54-.71,0-1.47.83-1.92,1.26l-.43.45v4.97h-1.19v-5.14c0-1.21-.56-1.54-1.16-1.54-.71,0-1.47.77-1.92,1.21l-.38.39v5.08h-1.2v-6.53c-.45.03-.87.07-1.09.13v-1.04Z"/>
      <path d="m353.11,4683.89v-1.53c0-2.09,1.42-3.29,3.28-3.29,1.53,0,2.62.66,2.62,2.2,0,1.36-.87,2.19-2.4,2.19h-2.3v.43c0,1.43.87,1.98,2.07,1.98.87,0,1.75-.34,2.07-.49h.11v1.09c-.32.15-1.32.49-2.18.49-1.86,0-3.28-.88-3.28-3.07Zm1.21-1.43h1.96c1.21,0,1.54-.43,1.54-1.19,0-.83-.56-1.11-1.43-1.11-1.32,0-2.07.66-2.07,2.2v.1Z"/>
      <path d="m361.35,4684.32v-4.05h-1.2v-1.09h1.2v-1.75h1.21v1.75h2.18v1.09h-2.18v3.84c0,1.36.34,1.75,1.58,1.75.28,0,.66-.11.88-.17h.06v1.04c-.28.11-.77.22-1.15.22-1.54,0-2.58-.66-2.58-2.63Z"/>
      <path d="m369.73,4679.4c.41-.11,1.26-.22,2.13-.22h.17v1.43l.55-.55c.56-.56,1.2-1,2.18-1,1.21,0,2.2.77,2.2,2.63v1.2c0,2.41-1.97,3.94-4.05,3.94h-.88v2.63h-1.2v-9.16c-.45.03-.87.07-1.09.13v-1.04Zm2.3,2.41v3.94h.88c1.64,0,2.84-1.09,2.84-2.84v-1.2c0-1.21-.55-1.54-1.21-1.54-.77,0-1.64.77-2.09,1.21l-.43.45Z"/>
      <path d="m378.59,4684.32v-1.2c0-2.41,1.96-3.94,4.05-3.94h2.02v6.54c.43-.03.87-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-1.37l-.49.49c-.55.56-1.21,1-2.19,1-1.21,0-2.19-.77-2.19-2.63Zm4.48.34l.38-.41v-3.98h-.81c-1.65,0-2.84,1.09-2.84,2.84v1.2c0,1.21.55,1.54,1.19,1.54.77,0,1.65-.77,2.09-1.21Z"/>
      <path d="m386.57,4676.56c.41-.11,1.25-.22,2.13-.22h.17v5.8h.76l2.52-2.96h1.37v.11l-2.9,3.33,3.01,4.1v.11h-1.37l-2.63-3.61h-.76v3.61h-1.21v-9.37c-.45.03-.88.07-1.09.13v-1.04Z"/>
      <path d="m394.11,4676.56c.41-.11,1.25-.22,2.13-.22h.17v5.8h.76l2.52-2.96h1.37v.11l-2.9,3.33,3.01,4.1v.11h-1.37l-2.63-3.61h-.76v3.61h-1.21v-9.37c-.45.03-.88.07-1.09.13v-1.04Z"/>
      <path d="m402.26,4683.89v-1.53c0-2.09,1.42-3.29,3.28-3.29,1.53,0,2.62.66,2.62,2.2,0,1.36-.87,2.19-2.4,2.19h-2.3v.43c0,1.43.87,1.98,2.07,1.98.87,0,1.75-.34,2.07-.49h.11v1.09c-.32.15-1.32.49-2.18.49-1.86,0-3.28-.88-3.28-3.07Zm1.21-1.43h1.96c1.21,0,1.54-.43,1.54-1.19,0-.83-.56-1.11-1.43-1.11-1.32,0-2.07.66-2.07,2.2v.1Z"/>
      <path d="m410.56,4680.31c-.45.03-.87.07-1.09.13v-1.04c.41-.11,1.26-.22,2.13-.22h.17v1.37l.43-.43c.55-.56,1.2-1.05,2.2-1.05,1.19,0,2.07.66,2.07,2.63v4.02c.43-.03.85-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-5.14c0-1.21-.49-1.54-1.2-1.54-.77,0-1.53.83-1.98,1.26l-.32.34v5.08h-1.2v-6.53Z"/>
      <path d="m422.7,4676.56c.41-.11,1.26-.22,2.13-.22h.17v9.39c.43-.03.87-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-1.37l-.49.49c-.55.56-1.21,1-2.19,1-1.21,0-2.19-.77-2.19-2.63v-1.2c0-2.41,1.96-3.94,4.05-3.94h.81v-1.71c-.45.03-.87.07-1.09.13v-1.04Zm.71,8.1l.38-.41v-3.98h-.81c-1.65,0-2.84,1.09-2.84,2.84v1.2c0,1.21.55,1.54,1.19,1.54.77,0,1.65-.77,2.09-1.21Z"/>
      <path d="m427.52,4683.89v-1.53c0-2.09,1.42-3.29,3.28-3.29,1.53,0,2.62.66,2.62,2.2,0,1.36-.87,2.19-2.4,2.19h-2.3v.43c0,1.43.87,1.98,2.07,1.98.87,0,1.75-.34,2.07-.49h.11v1.09c-.32.15-1.32.49-2.18.49-1.86,0-3.28-.88-3.28-3.07Zm1.21-1.43h1.96c1.21,0,1.54-.43,1.54-1.19,0-.83-.56-1.11-1.43-1.11-1.32,0-2.07.66-2.07,2.2v.1Z"/>
      <path d="m438.62,4685.86c1.3-1.32,3.33-3.84,4.31-5.59h-4.1v-1.09h5.48v.98c-.55,1.43-3.07,4.61-4.16,5.59h4.27v1.09h-5.8v-.98Z"/>
      <path d="m445.77,4679.4c.41-.11,1.26-.22,2.13-.22h.17v6.54c.43-.03.87-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-6.53c-.45.03-.87.07-1.09.13v-1.04Zm1.09-3.07h1.2v1.32h-1.2v-1.32Z"/>
      <path d="m451.46,4680.31c-.45.03-.87.07-1.09.13v-1.04c.41-.11,1.26-.22,2.13-.22h.17v1.37l.43-.43c.55-.56,1.2-1.05,2.2-1.05,1.19,0,2.07.66,2.07,2.63v4.02c.43-.03.85-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-5.14c0-1.21-.49-1.54-1.2-1.54-.77,0-1.53.83-1.98,1.26l-.32.34v5.08h-1.2v-6.53Z"/>
      <path d="m460.7,4680.31c-.45.03-.87.07-1.09.13v-1.04c.41-.11,1.26-.22,2.13-.22h.17v1.37l.43-.43c.55-.56,1.2-1.05,2.2-1.05,1.19,0,2.07.66,2.07,2.63v4.02c.43-.03.85-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-5.14c0-1.21-.49-1.54-1.2-1.54-.77,0-1.53.83-1.98,1.26l-.32.34v5.08h-1.2v-6.53Z"/>
      <path d="m469.07,4683.89v-1.53c0-2.09,1.42-3.29,3.28-3.29,1.53,0,2.62.66,2.62,2.2,0,1.36-.87,2.19-2.4,2.19h-2.3v.43c0,1.43.87,1.98,2.07,1.98.87,0,1.75-.34,2.07-.49h.11v1.09c-.32.15-1.32.49-2.18.49-1.86,0-3.28-.88-3.28-3.07Zm1.21-1.43h1.96c1.21,0,1.54-.43,1.54-1.19,0-.83-.56-1.11-1.43-1.11-1.32,0-2.07.66-2.07,2.2v.1Z"/>
      <path d="m477.37,4680.31c-.45.03-.87.07-1.09.13v-1.04c.41-.11,1.26-.22,2.13-.22h.17v1.37l.43-.43c.55-.56,1.2-1.05,2.2-1.05,1.19,0,2.07.66,2.07,2.63v4.02c.43-.03.85-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-5.14c0-1.21-.49-1.54-1.2-1.54-.77,0-1.53.83-1.98,1.26l-.32.34v5.08h-1.2v-6.53Z"/>
      <path d="m489.41,4682.91c0-2.3,1.09-3.84,3.33-3.84,2.35,0,3.33,1.54,3.33,3.84,0,2.51-1.09,4.05-3.33,4.05-2.35,0-3.33-1.54-3.33-4.05Zm5.46,0c0-1.69-.49-2.75-2.13-2.75s-2.13,1.05-2.13,2.75.49,2.96,2.13,2.96,2.13-1.26,2.13-2.96Z"/>
      <path d="m497.61,4681.15v-1.97h1.2v1.97c0,2.19.87,3.99,1.7,4.8.81-.81,1.7-2.62,1.7-4.8v-1.97h1.2v1.97c0,2.62-1.54,5.03-2.3,5.69h-1.32c-.77-.66-2.19-3.07-2.19-5.69Z"/>
      <path d="m504.99,4683.89v-1.53c0-2.09,1.42-3.29,3.28-3.29,1.53,0,2.62.66,2.62,2.2,0,1.36-.87,2.19-2.4,2.19h-2.3v.43c0,1.43.87,1.98,2.07,1.98.87,0,1.75-.34,2.07-.49h.11v1.09c-.32.15-1.32.49-2.18.49-1.86,0-3.28-.88-3.28-3.07Zm1.21-1.43h1.96c1.21,0,1.54-.43,1.54-1.19,0-.83-.56-1.11-1.43-1.11-1.32,0-2.07.66-2.07,2.2v.1Z"/>
      <path d="m512.2,4679.4c.41-.11,1.26-.22,2.13-.22h.17v1.61l.07-.08c.56-.88,1.25-1.53,2.23-1.53h.49v1.32h-.55c-.55,0-1.08.21-1.42.55l-.83.83v4.97h-1.2v-6.53c-.45.03-.87.07-1.09.13v-1.04Z"/>
      <path d="m350.18,4700.91c0-2.3,1.09-3.84,3.33-3.84,2.35,0,3.33,1.54,3.33,3.84,0,2.51-1.09,4.05-3.33,4.05-2.35,0-3.33-1.54-3.33-4.05Zm5.46,0c0-1.69-.49-2.75-2.13-2.75s-2.13,1.05-2.13,2.75.49,2.96,2.13,2.96,2.13-1.26,2.13-2.96Z"/>
      <path d="m358.21,4697.4c.41-.11,1.26-.22,2.13-.22h.17v1.43l.55-.55c.56-.56,1.2-1,2.18-1,1.21,0,2.2.77,2.2,2.63v1.2c0,2.41-1.97,3.94-4.05,3.94h-.88v2.63h-1.2v-9.16c-.45.03-.87.07-1.09.13v-1.04Zm2.3,2.41v3.94h.88c1.64,0,2.84-1.09,2.84-2.84v-1.2c0-1.21-.55-1.54-1.21-1.54-.77,0-1.64.77-2.09,1.21l-.43.45Z"/>
      <path d="m367.02,4699.15v-1.97h1.2v1.97c0,2.19.87,3.99,1.7,4.8.81-.81,1.7-2.62,1.7-4.8v-1.97h1.2v1.97c0,2.62-1.54,5.03-2.3,5.69h-1.32c-.77-.66-2.19-3.07-2.19-5.69Z"/>
      <path d="m374.34,4700.91c0-2.3,1.09-3.84,3.33-3.84,2.35,0,3.33,1.54,3.33,3.84,0,2.51-1.09,4.05-3.33,4.05-2.35,0-3.33-1.54-3.33-4.05Zm5.46,0c0-1.69-.49-2.75-2.13-2.75s-2.13,1.05-2.13,2.75.49,2.96,2.13,2.96,2.13-1.26,2.13-2.96Z"/>
      <path d="m382.6,4701.89v-1.53c0-2.09,1.42-3.29,3.28-3.29,1.53,0,2.62.66,2.62,2.2,0,1.36-.87,2.19-2.4,2.19h-2.3v.43c0,1.43.87,1.98,2.07,1.98.87,0,1.75-.34,2.07-.49h.11v1.09c-.32.15-1.32.49-2.18.49-1.86,0-3.28-.88-3.28-3.07Zm1.21-1.43h1.96c1.21,0,1.54-.43,1.54-1.19,0-.83-.56-1.11-1.43-1.11-1.32,0-2.07.66-2.07,2.2v.1Z"/>
      <path d="m393.8,4694.56c.41-.11,1.26-.22,2.13-.22h.17v9.39c.43-.03.87-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-1.37l-.49.49c-.55.56-1.21,1-2.19,1-1.21,0-2.19-.77-2.19-2.63v-1.2c0-2.41,1.96-3.94,4.05-3.94h.81v-1.71c-.45.03-.87.07-1.09.13v-1.04Zm.71,8.1l.38-.41v-3.98h-.81c-1.65,0-2.84,1.09-2.84,2.84v1.2c0,1.21.55,1.54,1.19,1.54.77,0,1.65-.77,2.09-1.21Z"/>
      <path d="m398.62,4701.89v-1.53c0-2.09,1.42-3.29,3.28-3.29,1.53,0,2.62.66,2.62,2.2,0,1.36-.87,2.19-2.4,2.19h-2.3v.43c0,1.43.87,1.98,2.07,1.98.87,0,1.75-.34,2.07-.49h.11v1.09c-.32.15-1.32.49-2.18.49-1.86,0-3.28-.88-3.28-3.07Zm1.21-1.43h1.96c1.21,0,1.54-.43,1.54-1.19,0-.83-.56-1.11-1.43-1.11-1.32,0-2.07.66-2.07,2.2v.1Z"/>
      <path d="m406.92,4698.31c-.45.03-.87.07-1.09.13v-1.04c.41-.11,1.26-.22,2.13-.22h.17v1.37l.43-.43c.55-.56,1.2-1.05,2.2-1.05,1.19,0,2.07.66,2.07,2.63v4.02c.43-.03.85-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-5.14c0-1.21-.49-1.54-1.2-1.54-.77,0-1.53.83-1.98,1.26l-.32.34v5.08h-1.2v-6.53Z"/>
      <path d="m419.01,4701.89v-1.53c0-2.09,1.42-3.29,3.28-3.29,1.53,0,2.62.66,2.62,2.2,0,1.36-.87,2.19-2.4,2.19h-2.3v.43c0,1.43.87,1.98,2.07,1.98.87,0,1.75-.34,2.07-.49h.11v1.09c-.32.15-1.32.49-2.18.49-1.86,0-3.28-.88-3.28-3.07Zm1.21-1.43h1.96c1.21,0,1.54-.43,1.54-1.19,0-.83-.56-1.11-1.43-1.11-1.32,0-2.07.66-2.07,2.2v.1Z"/>
      <path d="m427.32,4698.31c-.45.03-.87.07-1.09.13v-1.04c.41-.11,1.26-.22,2.13-.22h.17v1.37l.43-.43c.55-.56,1.2-1.05,2.2-1.05,1.19,0,2.07.66,2.07,2.63v4.02c.43-.03.85-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-5.14c0-1.21-.49-1.54-1.2-1.54-.77,0-1.53.83-1.98,1.26l-.32.34v5.08h-1.2v-6.53Z"/>
      <path d="m439.35,4700.91c0-2.3,1.09-3.84,3.33-3.84,2.35,0,3.33,1.54,3.33,3.84,0,2.51-1.09,4.05-3.33,4.05-2.35,0-3.33-1.54-3.33-4.05Zm5.46,0c0-1.69-.49-2.75-2.13-2.75s-2.13,1.05-2.13,2.75.49,2.96,2.13,2.96,2.13-1.26,2.13-2.96Z"/>
      <path d="m447.39,4697.4c.41-.11,1.26-.22,2.13-.22h.17v1.43l.55-.55c.56-.56,1.2-1,2.18-1,1.21,0,2.2.77,2.2,2.63v1.2c0,2.41-1.97,3.94-4.05,3.94h-.88v2.63h-1.2v-9.16c-.45.03-.87.07-1.09.13v-1.04Zm2.3,2.41v3.94h.88c1.64,0,2.84-1.09,2.84-2.84v-1.2c0-1.21-.55-1.54-1.21-1.54-.77,0-1.64.77-2.09,1.21l-.43.45Z"/>
      <path d="m456.85,4706.16h.1c.34.11,1,.21,1.32.21,1.97,0,2.84-.87,2.84-2.62v-.08l-.06.03c-.6.29-1.3.87-2.41.87-1.3,0-2.4-.81-2.4-2.73v-.81c0-2.52,1.75-3.84,3.82-3.84h2.24v6.46c0,2.63-1.53,3.84-4.05,3.84-.6,0-1.15-.17-1.42-.28v-1.04Zm4.1-3.39l.15-.08v-4.41h-1.04c-1.64,0-2.62.99-2.62,2.75v.81c0,1.21.49,1.64,1.42,1.64.78,0,1.48-.41,2.09-.7Z"/>
      <path d="m464.29,4697.4c.41-.11,1.26-.22,2.13-.22h.17v1.61l.07-.08c.56-.88,1.25-1.53,2.23-1.53h.49v1.32h-.55c-.55,0-1.08.21-1.42.55l-.83.83v4.97h-1.2v-6.53c-.45.03-.87.07-1.09.13v-1.04Z"/>
      <path d="m470.2,4700.91c0-2.3,1.09-3.84,3.33-3.84,2.35,0,3.33,1.54,3.33,3.84,0,2.51-1.09,4.05-3.33,4.05-2.35,0-3.33-1.54-3.33-4.05Zm5.46,0c0-1.69-.49-2.75-2.13-2.75s-2.13,1.05-2.13,2.75.49,2.96,2.13,2.96,2.13-1.26,2.13-2.96Z"/>
      <path d="m478.46,4701.89v-1.53c0-2.09,1.42-3.29,3.28-3.29,1.53,0,2.62.66,2.62,2.2,0,1.36-.87,2.19-2.4,2.19h-2.3v.43c0,1.43.87,1.98,2.07,1.98.87,0,1.75-.34,2.07-.49h.11v1.09c-.32.15-1.32.49-2.18.49-1.86,0-3.28-.88-3.28-3.07Zm1.21-1.43h1.96c1.21,0,1.54-.43,1.54-1.19,0-.83-.56-1.11-1.43-1.11-1.32,0-2.07.66-2.07,2.2v.1Z"/>
      <path d="m485.67,4697.4c.41-.11,1.26-.22,2.13-.22h.17v6.54c.43-.03.87-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-6.53c-.45.03-.87.07-1.09.13v-1.04Zm1.09-3.07h1.2v1.32h-1.2v-1.32Z"/>
      <path d="m490.48,4701.89v-1.53c0-2.09,1.42-3.29,3.28-3.29,1.53,0,2.62.66,2.62,2.2,0,1.36-.87,2.19-2.4,2.19h-2.3v.43c0,1.43.87,1.98,2.07,1.98.87,0,1.75-.34,2.07-.49h.11v1.09c-.32.15-1.32.49-2.18.49-1.86,0-3.28-.88-3.28-3.07Zm1.21-1.43h1.96c1.21,0,1.54-.43,1.54-1.19,0-.83-.56-1.11-1.43-1.11-1.32,0-2.07.66-2.07,2.2v.1Z"/>
      <path d="m498.78,4698.31c-.45.03-.87.07-1.09.13v-1.04c.41-.11,1.26-.22,2.13-.22h.17v1.37l.43-.43c.55-.56,1.2-1.05,2.2-1.05,1.19,0,2.07.66,2.07,2.63v4.02c.43-.03.85-.08,1.09-.14v1.04c-.41.11-1.26.22-2.13.22h-.17v-5.14c0-1.21-.49-1.54-1.2-1.54-.77,0-1.53.83-1.98,1.26l-.32.34v5.08h-1.2v-6.53Z"/>
    </g>
    <g id="Confetti">
      <path className="cls-60 con" d="m838.55,4539.91l-2.35.22c.21,2.26.93,4.57,1.85,4.49l2.35-.22c-.92.08-1.64-2.24-1.85-4.49Z"/>
      <path className="cls-56 con-down" d="m663.49,4567.95l1.19-3.08c-2.96-1.14-6.31-1.68-6.78-.47l-1.19,3.08c.47-1.21,3.83-.67,6.78.47Z"/>
      <path className="cls-84 con" d="m705.47,4555.4l3.96-.36c-.35-3.81-1.56-7.71-3.12-7.57l-3.96.36c1.56-.14,2.77,3.78,3.12,7.57Z"/>
      <path className="cls-56 con-down" d="m585.4,4558.69l-2.55-1.09c-1.04,2.45-1.59,5.25-.59,5.68l2.55,1.09c-1-.43-.45-3.24.59-5.68Z"/>
      <path className="cls-60 con" d="m569.85,4586.18l-2.11,2.68c2.58,2.02,5.72,3.56,6.55,2.51l2.1-2.68c-.83,1.05-3.99-.5-6.55-2.51Z"/>
      <path className="cls-45 con-down" d="m607.66,4580.27l1.44-1.24c-1.19-1.38-2.74-2.56-3.3-2.08l-1.44,1.24c.57-.49,2.12.7,3.3,2.08Z"/>
      <path className="cls-45 con" d="m919.64,4586.11l2.45,1.97c-1.89,2.35-4.39,4.39-5.35,3.62l-2.45-1.97c.96.77,3.47-1.28,5.35-3.62Z"/>
      <path className="cls-56 con-down" d="m811.6,4586.1l-2.82,1.84c-1.77-2.71-3.02-5.93-1.91-6.65l2.82-1.84c-1.11.72.15,3.96,1.91,6.65Z"/>
      <path className="cls-84 con" d="m548.33,4632.96l-2.67-2.08c2-2.56,4.65-4.8,5.7-3.98l2.67,2.08c-1.05-.81-3.72,1.43-5.7,3.98Z"/>
      <path className="cls-60 con-down" d="m630.49,4558.79l-.83-1.34c1.29-.8,2.81-1.34,3.13-.81l.83,1.34c-.33-.53-1.85.02-3.13.81Z"/>
      <path className="cls-84 con" d="m905.37,4610.26l-.83-1.34c1.29-.8,2.81-1.34,3.13-.81l.83,1.34c-.33-.53-1.85.02-3.13.81Z"/>
      <path className="cls-84 con-down" d="m527.48,4568.09l1.76-2.55c2.45,1.69,4.64,4.01,3.94,5.01l-1.76,2.55c.69-1-1.51-3.33-3.94-5.01Z"/>
      <path className="cls-45 con" d="m667.52,4550.31l-1.59-1.43c1.37-1.53,3.15-2.83,3.77-2.27l1.6,1.43c-.63-.56-2.41.74-3.77,2.27Z"/>
      <path className="cls-84 con-down" d="m753.8,4580.89l2.61,1.12c-1.07,2.51-2.75,4.89-3.78,4.46l-2.61-1.12c1.03.44,2.71-1.96,3.78-4.46Z"/>
      <path className="cls-56 con" d="m700.79,4581.69l-2.52-2.62c2.51-2.43,5.68-4.4,6.68-3.37l2.52,2.62c-.99-1.03-4.18.96-6.68,3.37Z"/>
      <path className="cls-45 con-down" d="m557.98,4550.19l-2.07,1.25c-1.2-1.99-2.01-4.34-1.19-4.83l2.07-1.25c-.81.49,0,2.85,1.19,4.83Z"/>
      <path className="cls-84 con" d="m821.88,4557.59l-1.5,1.58c-1.52-1.44-2.78-3.29-2.19-3.91l1.5-1.58c-.59.62.68,2.47,2.19,3.91Z"/>
      <path className="cls-56 con-down" d="m888.03,4545.83l-.74,3.77c-3.62-.71-7.24-2.26-6.96-3.74l.74-3.77c-.29,1.48,3.35,3.03,6.96,3.74Z"/>
      <path className="cls-60 con" d="m756.82,4558.36l2.16,2.07c-1.99,2.07-4.53,3.8-5.38,2.98l-2.16-2.07c.85.81,3.4-.92,5.38-2.99Z"/>
      <path className="cls-45 con-down" d="m791.12,4548.01l-.19,2.94c-2.83-.19-5.74-1.01-5.66-2.17l.19-2.94c-.08,1.16,2.85,1.98,5.66,2.17Z"/>
      <path className="cls-60 con" d="m904.39,4634.24l.15,2.64c-2.54.14-5.23-.27-5.29-1.31l-.15-2.64c.06,1.04,2.76,1.45,5.29,1.31Z"/>
      <path className="cls-60 con-down" d="m878.42,4577.55l2.58-1.16c1.11,2.47,1.72,5.31.71,5.77l-2.58,1.16c1.01-.45.4-3.3-.71-5.76Z"/>
      <path className="cls-45 con" d="m858.77,4557.39l-1.98-.23c.22-1.9.87-3.84,1.65-3.75l1.98.23c-.78-.09-1.43,1.86-1.64,3.75Z"/>
      <path className="cls-84 con-down" d="m901.15,4558.67l2.4-.18c.18,2.3-.15,4.75-1.09,4.82l-2.4.18c.94-.07,1.27-2.53,1.09-4.82Z"/>
    </g>
    <a target="_blank" rel="noopener noreferrer" href={props.audio} >
          <g id="Playbutton_geluid" data-name="Playbutton geluid">
            <circle className="cls-23" cx="545.37" cy="4498.35" r="14.97"/>
            <path className="cls-38" d="m540.66,4507.64v-18.59l13.42,9.29-13.42,9.29Z"/>
          </g>
    </a>

    {/* <audio controls >
        <source src={props.audio} type="audio/mpeg" />
    </audio> */}

    <g id="Titel">
      <rect id="Achtergrond-2" data-name="Achtergrond" className="cls-38" x="429.82" y="4419.13" width="467.75" height="55.7" rx="4.22" ry="4.22"/>
      <text className="cls-47" transform="translate(460.5 4460.97)"><tspan x="0" y="0">Nieuwe merkcampagne</tspan></text>
    </g>
        </g>
        </>
    )
  } 
  return (
    <>
    <g ref={mobileScreen} id="Merkcampagne">
    <path id="Ondergrond_extra_wolkje" data-name="Ondergrond extra wolkje" className="cls-95" d="m311.52,4343.96h-7.32c-7.16,0-12.97-5.81-12.97-12.97s5.81-12.97,12.97-12.97h5.9c7.16,0,12.97-5.81,12.97-12.97s-5.81-12.97-12.97-12.97h-178.24c-7.16,0-12.97,5.81-12.97,12.97s5.8,12.97,12.96,12.97h-22.05c-11.09,0-20.07,8.99-20.07,20.07s8.99,20.07,20.07,20.07h35.02c-7.16,0-12.97,5.81-12.97,12.97s5.81,12.97,12.97,12.97h166.69c11.09,0,20.07-8.99,20.07-20.07s-8.99-20.07-20.07-20.07Z"/>
    <g>
      <path className="cls-41" d="m265.3,4186.5v-1.25c0-3.5,2.5-5.51,6-5.51,1.19,0,2.56.19,3.38.5v3.51h-2.5v-1.5c-.19-.05-.88-.11-1.14-.11-1.87,0-2.87,1.12-2.87,2.99v1.38c0,1.81,1.06,3.12,2.87,3.12h.75c.91,0,2.24-.3,2.75-.5h.13v2.32c-.37.18-1.87.56-2.88.56h-.75c-3.55,0-5.75-1.94-5.75-5.51Z"/>
      <path className="cls-41" d="m275.93,4189.51h1.76c1.12,0,1.49-.51,1.49-1.63v-5.63h-2.74v-2.37h5.49v8c0,2.62-1.38,4-4,4h-2v-2.37Z"/>
      <path className="cls-41" d="m283.8,4186.5v-1.25c0-3.81,2.95-5.51,6.39-5.51,1.25,0,2.56.19,3.75.62v2.38h-.13c-1.19-.45-2.69-.62-3.75-.62-2,0-3.38,1.12-3.38,3.12v1.25c0,1.81,1.18,3.12,2.99,3.12h.62c.29,0,.88-.06,1.14-.11v-1.89h-1.76v-2.37h4.26v6.07c-.62.24-2.13.69-3.63.69h-.62c-3.55,0-5.88-1.94-5.88-5.51Z"/>
      <path className="cls-41" d="m235.67,4199.07h4.39c2.74,0,4.5.88,4.5,4,0,1.92-.95,2.88-1.94,3.5,1.25.99,2.58,3.6,2.69,4.37v.13h-3.01c-.19-.69-1.18-2.95-2.19-3.57h-1.68v3.57h-2.75v-12Zm3.88,6.13c1.5,0,2.13-.5,2.13-2s-.62-1.76-2.13-1.76h-1.12v3.76h1.12Z"/>
      <path className="cls-41" d="m251.1,4210.65c-.82.27-2.24.43-3.19.43h-.75v-6.69c-.45.06-.87.16-1.19.26v-1.89c.83-.27,2.26-.43,3.19-.43h.75v6.69c.45-.06.87-.14,1.19-.26v1.89Zm-3.94-11.57h2.75v2.26h-2.75v-2.26Z"/>
      <path className="cls-41" d="m252.1,4202.75c.83-.27,2.26-.43,3.2-.43h.74v8.26c0,2.29-1.54,3.5-3.38,3.5-.4,0-.83-.06-1.25-.19v-2.19c.29.11.54.13.75.13.72,0,1.14-.56,1.14-1.5v-5.94c-.46.06-.88.16-1.2.26v-1.89Zm1.2-3.68h2.74v2.26h-2.74v-2.26Z"/>
      <path className="cls-41" d="m268.75,4210.65c-.83.27-2.26.43-3.18.43h-.75v-5.38c0-.69-.13-1.12-.82-1.12-.5,0-1.12.38-1.7.75l-.5.3v5.44h-2.75v-6.69c-.45.06-.87.16-1.18.26v-1.89c.83-.27,2.26-.43,3.18-.43h.75v1.33l.38-.26c.99-.72,1.75-1.2,2.87-1.2,1.7,0,2.51,1.07,2.51,2.75v4.07c.43-.06.85-.14,1.18-.26v1.89Z"/>
      <path className="cls-41" d="m286,4210.65c-.83.27-2.26.43-3.18.43h-.75v-5.38c0-.69-.13-1.12-.75-1.12-.43,0-1.01.35-1.57.75l-.43.3v5.44h-2.75v-5.38c0-.69-.13-1.12-.75-1.12-.43,0-1.01.35-1.55.75l-.45.3v5.44h-2.75v-6.69c-.45.06-.87.16-1.18.26v-1.89c.83-.27,2.26-.43,3.18-.43h.75v1.33l.32-.26c.94-.79,1.68-1.2,2.75-1.2,1.15,0,1.92.56,2.24,1.5h.13l.38-.3c.94-.79,1.68-1.2,2.74-1.2,1.63,0,2.45,1.07,2.45,2.75v4.07c.43-.06.85-.14,1.18-.26v1.89Z"/>
      <path className="cls-41" d="m287.17,4206.71c0-3.14,1.63-4.51,4.5-4.51,3.01,0,4.51,1.38,4.51,4.51,0,3.36-1.5,4.5-4.51,4.5s-4.5-1.14-4.5-4.5Zm6.26,0c0-1.57-.51-2.13-1.76-2.13s-1.74.56-1.74,2.13.5,2.11,1.74,2.11,1.76-.56,1.76-2.11Z"/>
      <path className="cls-41" d="m308.25,4210.65c-.83.27-2.26.43-3.18.43h-.75v-5.38c0-.69-.13-1.12-.82-1.12-.5,0-1.12.38-1.7.75l-.5.3v5.44h-2.75v-6.69c-.45.06-.87.16-1.18.26v-1.89c.83-.27,2.26-.43,3.18-.43h.75v1.33l.38-.26c.99-.72,1.75-1.2,2.87-1.2,1.7,0,2.51,1.07,2.51,2.75v4.07c.43-.06.85-.14,1.18-.26v1.89Z"/>
      <path className="cls-41" d="m319.5,4210.65c-.83.27-2.26.43-3.19.43h-.75v-1.18l-.62.43c-.8.54-1.51.88-2.45.88-1.81,0-2.8-1.07-2.8-2.88v-1.75c0-2.62,2-4.26,4.63-4.26h1.25v-1.18c-.45.06-.88.16-1.18.26v-1.89c.82-.27,2.24-.43,3.19-.43h.75v9.94c.43-.06.85-.14,1.18-.26v1.89Zm-3.94-2.75v-3.31h-1.01c-1.38,0-2.11.62-2.11,2v1.25c0,.69.3.99.99.99.5,0,1.12-.3,1.63-.62l.5-.3Z"/>
      <path className="cls-41" d="m321.3,4202.32h2.75v2.75h-2.75v-2.75Zm0,6h2.75v2.75h-2.75v-2.75Z"/>
      <path d="m230.46,4219.31l-2.53,1.02h-.1v-1.02l3-1.22h.74v9h-1.12v-7.77Z"/>
      <path d="m238.85,4221.49c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m246.03,4224.92v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.44.94-2.44,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path d="m253.38,4224.92v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.25Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.44.94-2.44,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path d="m260.55,4220.71c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path d="m273.01,4225.95h1.13v.56l-1.13,2.06h-.71l.71-2.63Z"/>
      <path d="m282.16,4219.31l-2.53,1.02h-.1v-1.02l3-1.22h.74v9h-1.12v-7.77Z"/>
      <path d="m289.75,4222.21v-1.69h1.03v1.69c0,1.87.6,3.42,1.16,4.11.56-.7,1.18-2.24,1.18-4.11v-1.69h1.03v1.69c0,1.87.61,3.42,1.18,4.11.55-.7,1.16-2.24,1.16-4.11v-1.69h1.03v1.69c0,2.24-.94,4.21-1.6,4.87h-1.12c-.34-.37-1.03-1.55-1.18-2.25-.14.71-.84,1.88-1.18,2.25h-1.12c-.66-.66-1.6-2.63-1.6-4.87Z"/>
      <path d="m298.9,4224.55v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.78,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.6,0-2.81-.75-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.78.56-1.78,1.88v.08Z"/>
      <path d="m304.8,4218.28c.35-.1,1.07-.19,1.82-.19h.14v3.61l.42-.42c.48-.48,1.03-.85,1.87-.85,1.03,0,1.87.66,1.87,2.25v1.03c0,2.06-1.68,3.37-3.47,3.37h-1.73v-8.02c-.4.02-.76.06-.94.11v-.89Zm1.97,4.45v3.42h.7c1.4,0,2.44-.94,2.44-2.43v-1.03c0-1.03-.47-1.32-1.03-1.32-.65,0-1.4.66-1.78,1.03l-.32.34Z"/>
      <path d="m312.53,4226.93v-.94h.1c.52.13,1.21.24,1.82.24,1.03,0,1.6-.29,1.6-1.03,0-.56-.48-.82-1.03-.94l-.98-.24c-.89-.2-1.55-.71-1.55-1.73,0-1.17.98-1.88,2.53-1.88.66,0,1.27.1,1.74.19v.94h-.1c-.49-.1-1.02-.19-1.64-.19-.98,0-1.5.29-1.5.95,0,.6.41.66.94.79l.8.19c1.02.24,1.82.65,1.82,1.92,0,1.45-1.26,1.97-2.63,1.97-.65,0-1.45-.1-1.92-.24Z"/>
      <path d="m318.35,4220.71c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path d="m323.17,4224.92v-3.47h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.25Z"/>
      <path d="m327.35,4224.55v-1.31c0-1.79,1.21-2.82,2.8-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.8-.75-2.8-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
    </g>
    <g>
      <path className="cls-41" d="m244.28,4396.62l-2.88,1.12h-.13v-2.5l4-1.63h1.75v12h-2.74v-9Z"/>
      <path className="cls-41" d="m250.52,4399.61c0-4.62,1.5-6.13,4.51-6.13s4.5,1.51,4.5,6.13-1.5,6.13-4.5,6.13-4.51-1.63-4.51-6.13Zm6.26,0c0-3.01-.38-3.75-1.75-3.75s-1.76.74-1.76,3.75.38,3.75,1.76,3.75,1.75-.75,1.75-3.75Z"/>
      <path className="cls-41" d="m174.22,4419.57c.11-.37.21-.74.27-1.14l-.02.02c-.13-.06-.45-.13-.75-.13-.94,0-1.5.56-1.5,1.49v1.13c0,1.12.62,1.62,1.75,1.62.82,0,1.89-.18,2.62-.43h.13v2.26c-.62.3-1.94.56-2.82.56-2.5,0-4.43-.88-4.43-3.38v-1.87c0-2.32,1.94-3.76,4.26-3.76,1.12,0,2.13.19,2.99.62v.32c0,.94-.16,1.86-.43,2.69h-2.07Z"/>
      <path className="cls-41" d="m188.42,4424.38c-.83.27-2.26.43-3.19.43h-.75v-1.19l-.62.43c-.8.54-1.5.88-2.45.88-1.81,0-2.8-1.07-2.8-2.88v-1.74c0-2.62,2-4.26,4.63-4.26h4v6.69c.43-.06.85-.14,1.18-.26v1.89Zm-3.94-2.75v-3.31h-1.01c-1.38,0-2.11.62-2.11,2v1.25c0,.69.3.99.99.99.5,0,1.12-.3,1.63-.62l.5-.3Z"/>
      <path className="cls-41" d="m205.67,4424.38c-.83.27-2.26.43-3.18.43h-.75v-5.38c0-.69-.13-1.12-.75-1.12-.43,0-1.01.35-1.57.75l-.43.3v5.44h-2.75v-5.38c0-.69-.13-1.12-.75-1.12-.43,0-1.01.35-1.55.75l-.45.3v5.44h-2.75v-6.69c-.45.06-.87.16-1.18.26v-1.89c.83-.27,2.26-.43,3.18-.43h.75v1.33l.32-.26c.94-.79,1.68-1.2,2.75-1.2,1.15,0,1.92.56,2.24,1.51h.13l.38-.3c.94-.79,1.68-1.2,2.74-1.2,1.63,0,2.45,1.07,2.45,2.75v4.07c.43-.06.85-.14,1.18-.26v1.89Z"/>
      <path className="cls-41" d="m206.78,4416.49c.83-.27,2.26-.43,3.18-.43h.75v1.18l.62-.43c.8-.56,1.5-.88,2.45-.88,1.81,0,2.8,1.07,2.8,2.88v1.74c0,2.62-2,4.26-4.63,4.26h-1.25v3.01h-2.75v-9.7c-.45.06-.87.16-1.18.26v-1.89Zm3.94,2.75v3.31h1.02c1.36,0,2.11-.62,2.11-2v-1.25c0-.69-.32-.99-1.01-.99-.5,0-1.1.3-1.63.62l-.5.3Z"/>
      <path className="cls-41" d="m228.16,4424.38c-.83.27-2.26.43-3.19.43h-.75v-1.19l-.62.43c-.8.54-1.5.88-2.45.88-1.81,0-2.8-1.07-2.8-2.88v-1.74c0-2.62,2-4.26,4.63-4.26h4v6.69c.43-.06.85-.14,1.18-.26v1.89Zm-3.94-2.75v-3.31h-1.01c-1.38,0-2.11.62-2.11,2v1.25c0,.69.3.99.99.99.5,0,1.12-.3,1.63-.62l.5-.3Z"/>
      <path className="cls-41" d="m230.72,4425.25c.72.18,1.52.32,2.26.32,1.36,0,2.5-.48,2.5-2.19v-.06l-.62.32c-.67.32-1.38.69-2.32.69-1.87,0-2.93-1.01-2.93-2.88v-1.12c0-2.62,2-4.26,4.63-4.26h4v7.51c0,3.26-2.42,4.26-5.01,4.26-.83,0-1.68-.08-2.5-.32v-2.26Zm4.75-3.94v-2.99h-1.01c-1.38,0-2.11.62-2.11,2v.62c0,.69.3.99.99.99.56,0,1.22-.22,1.7-.43l.43-.19Z"/>
      <path className="cls-41" d="m250.91,4424.38c-.83.27-2.26.43-3.18.43h-.75v-5.38c0-.69-.13-1.12-.82-1.12-.5,0-1.12.38-1.7.75l-.5.3v5.44h-2.75v-6.69c-.45.06-.87.16-1.18.26v-1.89c.83-.27,2.26-.43,3.18-.43h.75v1.33l.38-.26c.99-.72,1.75-1.2,2.87-1.2,1.7,0,2.51,1.07,2.51,2.75v4.07c.43-.06.85-.14,1.18-.26v1.89Z"/>
      <path className="cls-41" d="m252.09,4421.38v-1.68c0-2.51,1.94-3.76,4.43-3.76,2.19,0,3.94.69,3.94,2.88,0,1.87-1.36,2.56-3.25,2.56h-2.37v.06c0,.94.94,1.12,1.87,1.12s1.94-.11,2.88-.37h.13v2.26c-1.01.37-2.47.5-3.27.5-2.58,0-4.37-1.01-4.37-3.57Zm2.75-1.62h2.19c.56,0,.93-.19.93-.75,0-.75-.69-.82-1.44-.82-1.06,0-1.68.37-1.68,1.44v.13Z"/>
      <path className="cls-41" d="m261.66,4413.24c.82-.27,2.24-.43,3.19-.43h.75v4.43l.62-.43c.78-.56,1.49-.88,2.43-.88,1.81,0,2.82,1.07,2.82,2.88v1.74c0,2.62-2,4.26-4.63,4.26h-4v-9.94c-.46.06-.88.16-1.18.26v-1.89Zm3.94,6v3.31h1.01c1.36,0,2.11-.62,2.11-2v-1.25c0-.69-.32-.99-1.01-.99-.5,0-1.1.3-1.62.62l-.5.3Z"/>
      <path className="cls-41" d="m272.96,4421.38v-1.68c0-2.51,1.94-3.76,4.43-3.76,2.19,0,3.94.69,3.94,2.88,0,1.87-1.36,2.56-3.25,2.56h-2.37v.06c0,.94.94,1.12,1.87,1.12s1.94-.11,2.88-.37h.13v2.26c-1.01.37-2.47.5-3.27.5-2.58,0-4.37-1.01-4.37-3.57Zm2.75-1.62h2.19c.56,0,.93-.19.93-.75,0-.75-.69-.82-1.44-.82-1.06,0-1.68.37-1.68,1.44v.13Z"/>
      <path className="cls-41" d="m282.83,4421.38v-1.68c0-2.51,1.94-3.76,4.43-3.76,2.19,0,3.94.69,3.94,2.88,0,1.87-1.36,2.56-3.25,2.56h-2.37v.06c0,.94.94,1.12,1.87,1.12s1.94-.11,2.88-.37h.13v2.26c-1.01.37-2.47.5-3.27.5-2.58,0-4.37-1.01-4.37-3.57Zm2.75-1.62h2.19c.56,0,.93-.19.93-.75,0-.75-.69-.82-1.44-.82-1.06,0-1.68.37-1.68,1.44v.13Z"/>
      <path className="cls-41" d="m292.4,4413.24c.82-.27,2.24-.43,3.19-.43h.75v9.94c.43-.06.85-.14,1.18-.26v1.89c-.83.27-2.26.43-3.19.43h-.75v-9.94c-.46.06-.88.16-1.18.26v-1.89Z"/>
      <path className="cls-41" d="m308.77,4424.38c-.83.27-2.26.43-3.19.43h-.75v-1.19l-.62.43c-.8.54-1.5.88-2.45.88-1.81,0-2.8-1.07-2.8-2.88v-1.74c0-2.62,2-4.26,4.63-4.26h1.25v-1.19c-.45.06-.88.16-1.18.26v-1.89c.82-.27,2.24-.43,3.19-.43h.75v9.94c.43-.06.85-.14,1.18-.26v1.89Zm-3.94-2.75v-3.31h-1.01c-1.38,0-2.11.62-2.11,2v1.25c0,.69.3.99.99.99.5,0,1.12-.3,1.63-.62l.5-.3Z"/>
      <path className="cls-41" d="m309.95,4421.38v-1.68c0-2.51,1.94-3.76,4.43-3.76,2.19,0,3.94.69,3.94,2.88,0,1.87-1.36,2.56-3.25,2.56h-2.37v.06c0,.94.94,1.12,1.87,1.12s1.94-.11,2.88-.37h.13v2.26c-1.01.37-2.47.5-3.27.5-2.58,0-4.37-1.01-4.37-3.57Zm2.75-1.62h2.19c.56,0,.93-.19.93-.75,0-.75-.69-.82-1.44-.82-1.06,0-1.68.37-1.68,1.44v.13Z"/>
      <path className="cls-41" d="m330.64,4424.38c-.83.27-2.26.43-3.18.43h-.75v-5.38c0-.69-.13-1.12-.82-1.12-.5,0-1.12.38-1.7.75l-.5.3v5.44h-2.75v-6.69c-.45.06-.87.16-1.18.26v-1.89c.83-.27,2.26-.43,3.18-.43h.75v1.33l.38-.26c.99-.72,1.75-1.2,2.87-1.2,1.7,0,2.51,1.07,2.51,2.75v4.07c.43-.06.85-.14,1.18-.26v1.89Z"/>
      <path d="m173.85,4434.45c.35-.1,1.08-.19,1.82-.19h.14v1.18l.42-.42c.47-.47,1.03-.85,1.83-.85s1.42.37,1.63,1.37h.1l.48-.47c.47-.47,1.02-.9,1.81-.9.98,0,1.74.56,1.74,2.25v3.44c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-.98-1.32-.61,0-1.26.71-1.64,1.08l-.37.38v4.26h-1.02v-4.4c0-1.03-.48-1.32-1-1.32-.61,0-1.26.66-1.64,1.03l-.32.34v4.35h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path d="m185.94,4438.29v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m193.01,4438.66v-3.46h-1.03v-.94h1.03v-1.5h1.03v1.5h1.87v.94h-1.87v3.29c0,1.16.29,1.5,1.36,1.5.24,0,.56-.1.76-.14h.05v.89c-.24.1-.66.19-.98.19-1.32,0-2.21-.56-2.21-2.26Z"/>
      <path d="m200.19,4434.45c.35-.1,1.08-.19,1.82-.19h.14v1.22l.47-.47c.48-.48,1.03-.85,1.87-.85,1.03,0,1.88.66,1.88,2.25v1.03c0,2.06-1.69,3.37-3.47,3.37h-.76v2.25h-1.03v-7.84c-.38.02-.74.06-.94.11v-.89Zm1.97,2.06v3.37h.76c1.4,0,2.43-.94,2.43-2.44v-1.03c0-1.03-.47-1.32-1.03-1.32-.66,0-1.4.66-1.79,1.03l-.37.38Z"/>
      <path d="m207.78,4438.66v-1.03c0-2.06,1.68-3.37,3.47-3.37h1.73v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.26Zm3.84.29l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path d="m214.62,4432.01c.35-.1,1.07-.19,1.82-.19h.14v4.96h.65l2.16-2.53h1.18v.1l-2.48,2.85,2.58,3.52v.1h-1.17l-2.25-3.1h-.65v3.1h-1.03v-8.02c-.38.02-.75.06-.94.11v-.89Z"/>
      <path d="m221.09,4432.01c.35-.1,1.07-.19,1.82-.19h.14v4.96h.65l2.16-2.53h1.18v.1l-2.48,2.85,2.58,3.52v.1h-1.17l-2.25-3.1h-.65v3.1h-1.03v-8.02c-.38.02-.75.06-.93.11v-.89Z"/>
      <path d="m228.07,4438.29v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m235.19,4435.23c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m245.59,4432.01c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.26v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path d="m249.72,4438.29v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m259.24,4439.98c1.12-1.13,2.85-3.29,3.69-4.79h-3.51v-.94h4.69v.84c-.47,1.22-2.63,3.95-3.56,4.79h3.66v.94h-4.97v-.84Z"/>
      <path d="m265.37,4434.45c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path d="m270.24,4435.23c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m278.16,4435.23c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m285.34,4438.29v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m292.45,4435.23c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m302.77,4437.45c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path d="m309.8,4435.95v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.3-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path d="m316.13,4438.29v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m322.31,4434.45c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path d="m183.43,4453.45c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path d="m190.32,4450.45c.35-.1,1.08-.19,1.82-.19h.14v1.22l.47-.47c.48-.48,1.03-.85,1.87-.85,1.03,0,1.88.66,1.88,2.25v1.03c0,2.06-1.69,3.37-3.47,3.37h-.76v2.25h-1.03v-7.84c-.38.02-.74.06-.94.11v-.89Zm1.97,2.06v3.37h.76c1.4,0,2.43-.94,2.43-2.44v-1.03c0-1.03-.47-1.32-1.03-1.32-.66,0-1.4.66-1.79,1.03l-.37.38Z"/>
      <path d="m197.86,4451.95v-1.69h1.03v1.69c0,1.87.74,3.42,1.45,4.11.7-.7,1.45-2.24,1.45-4.11v-1.69h1.03v1.69c0,2.24-1.32,4.3-1.97,4.87h-1.13c-.66-.56-1.87-2.63-1.87-4.87Z"/>
      <path d="m204.14,4453.45c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path d="m211.22,4454.29v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m220.82,4448.01c.35-.1,1.08-.19,1.82-.19h.14v8.04c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-1.18l-.42.42c-.47.48-1.03.85-1.87.85-1.03,0-1.87-.66-1.87-2.26v-1.03c0-2.06,1.68-3.37,3.47-3.37h.7v-1.46c-.38.02-.74.06-.94.11v-.89Zm.61,6.93l.32-.35v-3.41h-.7c-1.42,0-2.43.94-2.43,2.43v1.03c0,1.03.47,1.32,1.02,1.32.66,0,1.42-.66,1.79-1.03Z"/>
      <path d="m224.95,4454.29v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m232.06,4451.23c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m242.43,4454.29v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m249.55,4451.23c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
      <path d="m259.87,4453.45c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path d="m266.76,4450.45c.35-.1,1.08-.19,1.82-.19h.14v1.22l.47-.47c.48-.48,1.03-.85,1.87-.85,1.03,0,1.88.66,1.88,2.25v1.03c0,2.06-1.69,3.37-3.47,3.37h-.76v2.25h-1.03v-7.84c-.38.02-.74.06-.94.11v-.89Zm1.97,2.06v3.37h.76c1.4,0,2.43-.94,2.43-2.44v-1.03c0-1.03-.47-1.32-1.03-1.32-.66,0-1.4.66-1.79,1.03l-.37.38Z"/>
      <path d="m274.87,4457.94h.08c.29.1.85.18,1.13.18,1.69,0,2.43-.74,2.43-2.24v-.07l-.05.02c-.52.25-1.12.74-2.06.74-1.12,0-2.05-.7-2.05-2.34v-.7c0-2.16,1.5-3.29,3.27-3.29h1.92v5.53c0,2.25-1.31,3.29-3.47,3.29-.52,0-.98-.14-1.21-.24v-.89Zm3.51-2.9l.13-.07v-3.78h-.89c-1.4,0-2.24.85-2.24,2.35v.7c0,1.03.42,1.4,1.21,1.4.67,0,1.27-.35,1.79-.6Z"/>
      <path d="m281.24,4450.45c.35-.1,1.08-.19,1.82-.19h.14v1.38l.06-.07c.48-.76,1.07-1.31,1.91-1.31h.42v1.13h-.47c-.47,0-.92.18-1.21.47l-.71.71v4.26h-1.03v-5.59c-.38.02-.74.06-.94.11v-.89Z"/>
      <path d="m286.3,4453.45c0-1.97.94-3.29,2.85-3.29,2.02,0,2.85,1.32,2.85,3.29,0,2.15-.94,3.47-2.85,3.47-2.01,0-2.85-1.32-2.85-3.47Zm4.68,0c0-1.45-.42-2.35-1.82-2.35s-1.82.9-1.82,2.35.42,2.53,1.82,2.53,1.82-1.08,1.82-2.53Z"/>
      <path d="m293.38,4454.29v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m299.56,4450.45c.35-.1,1.08-.19,1.82-.19h.14v5.6c.37-.02.74-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-5.59c-.38.02-.74.06-.94.11v-.89Zm.94-2.63h1.03v1.13h-1.03v-1.13Z"/>
      <path d="m303.69,4454.29v-1.31c0-1.79,1.21-2.82,2.81-2.82,1.31,0,2.24.56,2.24,1.88,0,1.16-.74,1.87-2.05,1.87h-1.97v.37c0,1.22.74,1.69,1.77,1.69.74,0,1.5-.29,1.78-.42h.1v.94c-.28.13-1.13.42-1.87.42-1.59,0-2.81-.76-2.81-2.63Zm1.03-1.22h1.68c1.03,0,1.32-.37,1.32-1.02,0-.71-.48-.95-1.22-.95-1.13,0-1.77.56-1.77,1.88v.08Z"/>
      <path d="m310.81,4451.23c-.38.02-.74.06-.94.11v-.89c.35-.1,1.08-.19,1.82-.19h.14v1.18l.37-.37c.47-.48,1.03-.9,1.88-.9,1.02,0,1.78.56,1.78,2.25v3.44c.37-.02.73-.07.94-.12v.89c-.35.1-1.08.19-1.82.19h-.14v-4.4c0-1.03-.42-1.32-1.03-1.32-.66,0-1.31.71-1.69,1.08l-.28.29v4.35h-1.03v-5.59Z"/>
    </g>
    <text transform="translate(37.4 4260.89)"><tspan className="cls-5"><tspan className="number" x="0" y="0">376</tspan><tspan>.</tspan><tspan  className="number">373</tspan></tspan><tspan className="cls-115"><tspan x=".43" y="16">magazines </tspan></tspan><tspan className="cls-115"><tspan x="13.32" y="32">GROEI! </tspan></tspan><tspan className="cls-115"><tspan x="-9.3" y="48">huis-aan-huis</tspan></tspan></text>
    <text transform="translate(33.89 4394.81)"><tspan className="cls-5"><tspan x="0" y="0">Campagne</tspan></tspan><tspan className="cls-115"><tspan x="-4.66" y="16">outdoor en online</tspan></tspan></text>
    <g id="Krant">
      <path className="cls-100" d="m63.69,4227.9h16.98c.42,0,.77-.33.77-.74s-.34-.74-.77-.74h-16.98c-.42,0-.77.33-.77.74s.34.74.77.74Z"/>
      <path className="cls-100" d="m63.69,4222.82h16.98c.42,0,.77-.33.77-.74v-7.32c0-.41-.34-.74-.77-.74h-16.98c-.42,0-.77.33-.77.74v7.32c0,.41.34.74.77.74Z"/>
      <path className="cls-100" d="m63.69,4232.61h16.98c.42,0,.77-.33.77-.74s-.34-.74-.77-.74h-16.98c-.42,0-.77.33-.77.74s.34.74.77.74Z"/>
      <path className="cls-57" d="m85.64,4207.44h-19.09c-1.34,0-2.43,1.05-2.43,2.35v.25h20.69c.35,0,.64.28.64.62v24.69c0,.31-.24.57-.55.61-.03,0-.06,0-.09,0-.35,0-.64-.28-.64-.62v-23.83c0-.14-.11-.25-.26-.25h-23.47c-.14,0-.26.11-.26.25v26.3c0,1.3,1.09,2.35,2.43,2.35h23.02c1.34,0,2.43-1.05,2.43-2.35v-28.04c0-1.3-1.09-2.35-2.43-2.35Zm-21.95,23.69h16.98c.42,0,.77.33.77.74s-.34.74-.77.74h-16.98c-.42,0-.77-.33-.77-.74s.34-.74.77-.74Zm-.77-3.97c0-.41.34-.74.77-.74h16.98c.42,0,.77.33.77.74s-.34.74-.77.74h-16.98c-.42,0-.77-.33-.77-.74Zm18.52-10.64v5.56c0,.41-.34.74-.77.74h-16.98c-.42,0-.77-.33-.77-.74v-7.32c0-.41.34-.74.77-.74h16.98c.42,0,.77.33.77.74v1.77Z"/>
    </g>
    <text transform="translate(165.68 4357.75)"><tspan className="cls-5"><tspan x="0" y="0">3 november </tspan></tspan><tspan className="cls-115"><tspan x="-22.14" y="16">finale Galjaardprijs 2022</tspan></tspan></text>
    <g id="Team_communicatie" data-name="Team communicatie">
      <g id="Team_communicatie-2" data-name="Team communicatie">
        <path className="cls-41" d="m165.03,4250.55s-3.41-2.38-4.04.32-1.39,4.21-5.23,5.19c0,0,6.75,4.09,8.56.08s1.08-4.21,1.08-4.21l-.36-1.38Z"/>
        <path className="cls-81" d="m205.58,4301.21l3.56,6.69-7.47,11.03,3.74,4.66-.57,2.33s2.22,3.8,8.6,2.78l4.94-25.04-.4-18.28-12.24,2.64-.15,13.18Z"/>
        <path className="cls-89" d="m230.24,4278.62s1.14,12.81-3.22,22.21l1,22.3-5.28,2.78-.79-3.5-3.98-.09-.34-39.01,12.6-4.7Z"/>
        <path className="cls-41" d="m218.83,4322.33s-3.57,2.03-2.44,3.59c0,0,3.22-.43,5.55-3.5l-3.12-.09Z"/>
        <path className="cls-41" d="m222.73,4325.92s-2.14,1.78-.79,4.27c0,0,3.41,1,5.07-4.27,0,0,1.88-.79,1-2.78l-5.28,2.78Z"/>
        <path className="cls-41" d="m204.83,4325.92s-.57,2.72.85,2.78c1.43.05,1.14.23,1.82,1.26.68,1.03,6.94,2.81,7.03,1.56s-2.59-3.77-2.59-3.77l-7.11-1.82Z"/>
        <path className="cls-41" d="m202.39,4319.84s-1.72,1.32-.73,2.12,3.74,1.63,3.74,1.63v-2.79l-3-.96Z"/>
        <polygon className="cls-89" points="237.65 4330.2 241.41 4326.57 239.08 4323.59 235.36 4325.31 237.65 4330.2"/>
        <path className="cls-89" d="m234.19,4327.74l-2.91,6.26,1.46.6s4.8-1.65,4.91-4.41,0-4.89,0-4.89l-3.46,2.44Z"/>
        <path className="cls-41" d="m231.51,4286.44l.61,9.55s-4.26,4.75,0,18.33v14.38l8.63-3.99-1.48-17.34.8-9.04s4.99-1.34,6.29-14.36l-14.85,2.48Z"/>
        <path className="cls-41" d="m247.74,4247.14s-6.45-2.81-7.47,12.62l-.2,1.2,3.11.16.82-3.2s-1.04-7.85,4-9.34c0,0,3.51,5.55-.27,10.27v2.41l3.49-2.06s.11-14.66-3.49-12.06Z"/>
        <path className="cls-81" d="m228.01,4282.57l3.04.65.46,3.23s16.57,3.69,23.53-12.91l22.11-19.76-1.84-3.45s-10.18,10.03-24.09,8.87c0,0-12.98-.88-16.12,6.4-3.14,7.28-7.1,16.97-7.1,16.97Z"/>
        <polygon className="cls-89" points="209.39 4265.77 207.17 4267.24 204.83 4267.24 205.73 4288.02 217.97 4285.38 218.37 4276.68 217.45 4273.1 214.36 4265.45 211.06 4264.73 209.39 4265.77"/>
        <path className="cls-41" d="m210.99,4259.76l5.4-15.5h3.95l-5.01,21.77,4.87,13.34-2.24.13v-3.08s-1.69-11.83-8.58-10.65l-2.22,1.48h-2.34s4.17,20.29.75,33.96c0,0-3.37,3.5-4.94-4.83s-1.73-9.92-1.73-9.92c0,0-6.5-1.66-3.42-10.04,3.08-8.38-.74-10.75,7.55-12.94,8.29-2.19,7.96-3.71,7.96-3.71Z"/>
        <path className="cls-41" d="m284.33,4340.5s3.13-.51,6.69,6.03c0,0,1.7,4.74-2.1,2.27,0,0-1.23,1.26-.64,4.11s-7.54,8.27-8.46,6.19c-.92-2.08,1.37-3.4,1.26-6.19-.1-2.79-2.69-8.41-2.69-8.41l5.92-4Z"/>
        <path className="cls-41" d="m276.35,4328.06s2.52.8,2.68,7c0,0-.53,4.16-2.41.89,0,0-1.39.47-2.03,2.81s-8.72,3.28-8.62,1.39,2.3-2,3.28-4.11,1.19-7.26,1.19-7.26l5.91-.72Z"/>
        <path className="cls-11" d="m270.3,4284.31v7.62l-8.61,25.17,7.56,18.93,2.01-1.8,5.47,15.79,16.98-6.04-16.01-28.39s10.82-13.54,10.48-20.69c-.34-7.14-1.58-8.9-1.58-8.9l-16.29-1.69Z"/>
        <path className="cls-53" d="m269.45,4253.53s-.74-7.28-.83-8.14c-.17-1.64,1.79,1.75,1.79,1.75l4.55-5.41c.54.93-1.44,3.66-1.44,3.66,0,0,2.96-3.84,2.27-1.13s-4.14,7.07-4.14,7.07l-2.21,2.2Z"/>
        <path className="cls-53" d="m296.94,4258.53s.59-8.47,8.74-7.03c8.14,1.44-5.78,5.85-6.22,8.31l-2.52-1.27Z"/>
        <path className="cls-92" d="m276.07,4262.57s-5.01-11.49-.98-15.6c4.03-4.12,4.66-1.28,4.66-1.28,0,0,2.42-4.03,7.11,2.72,0,0,.76,1.74,3.49,1.85,2.72.11.22,3.05,1.96,5.45,1.74,2.4,2.29,4.72-.87,4.87-3.16.14-15.36,2-15.36,2Z"/>
        <path className="cls-53" d="m275.92,4248.72s-3.73,2.75-.91,5.96c0,0-.57.94.91,2.64,1.47,1.7,3.17,4.41,7.24,2.72,4.07-1.7,1.81-5.55,1.24-7.24s-5.88-9.17-8.49-4.07Z"/>
        <ellipse className="cls-53" cx="284.24" cy="4251.89" rx=".96" ry="1.84" transform="translate(-206.57 18.87) rotate(-2.79)"/>
        <path className="cls-41" d="m276.07,4262.57s-4.01-.81-4.42-11.24l-4.27,2.2s-1.91,12.83,6.14,14.65c0,0-8.68,14.23-1.9,16.98,6.78,2.76,15.61,5.93,16.16-3.17l.55-9.1s5.33,8.96,9.79,2.62c4.46-6.34,4.46-17.42,4.46-17.42h-6.39l-.83,5.38s.54-2.4-3.94-2.9c-4.47-.51-15.36,2-15.36,2Z"/>
        <path className="cls-11" d="m180.66,4318.23s-1.7,2.29-1.41,4.1,2.19,7.8,4.53,7.33c0,0,2.86.18.63-4.35-2.23-4.53-1.28-7.81-1.28-7.81l-2.47.73Z"/>
        <path className="cls-11" d="m187.83,4317.1s-1.97,3.02-.92,7.09c0,0,.59.89.92-.61s.66,4.62,4.2,4.13c3.55-.5-.33-3.06-.85-5.29s-.6-4.96-.6-4.96l-2.75-.36Z"/>
        <path className="cls-41" d="m178.77,4279.37l-1.29,4.35v6.83l1.29,27.97,5.01-.78,1.28-26.85s2.46,11.39,2.46,12.77-.52,13.69-.52,13.69l4.27.13s2.2-14.51,1.04-20.47c-1.17-5.96-3.84-19.82-3.84-19.82l-9.71,2.17Z"/>
        <polygon className="cls-89" points="178.77 4279.37 187.44 4278.62 190.34 4256.59 184 4254.71 177.23 4258.09 178.77 4279.37"/>
        <path className="cls-54" d="m191.01,4240.81l4.79,1.01s-2.86,15.01-4.79,17.09,3.63,22.38,3.63,22.38l-7.61,2.42-2.42-7.86,2.42-17.32-3.02-3.83-3.89.22-1.91,14.4.57,10.03-1.29,4.35v8.22l-3.14-7.62-3.87-27.72s5.08.77,7.74-1.76,9.31-1.93,9.31-1.93c0,0,3.37-6.04,3.5-12.09Z"/>
        <path className="cls-41" d="m173.47,4252.33s.45-5.09-5.38-4.38c-5.82.71-4.54,7.5-3.43,8.64l8.8-4.26Z"/>
        <path className="cls-41" d="m170.32,4322.22s-1.63,1.16-.66,6.21c0,0,1.1,3.26,1.85.24,0,0,1.06.1,1.92,1.87.86,1.77,6.71.91,6.3-.61s-1.97-1.16-3.03-2.67c-1.06-1.51-2.1-5.64-2.1-5.64l-4.28.6Z"/>
        <path className="cls-89" d="m169.38,4306.77s2.04,2.21,1.03,4.42c-1,2.2-5.29,14.12-5.29,14.12l4.28-.6s7.72-12.75,7.82-14.59-1.3-4.24-1.3-4.24l-6.54.89Z"/>
        <path className="cls-89" d="m178.93,4304.59s.72,1.29,0,4.12-4.38,13.62-4.38,13.62l-3.61-.2,4.99-15.36,2.35-3.1.65.93Z"/>
        <path className="cls-41" d="m165.12,4325.31s-1.63,1.16-.66,6.21c0,0,1.1,3.26,1.85.24,0,0,1.06.1,1.92,1.87s6.71.91,6.3-.61-1.97-1.16-3.03-2.67c-1.06-1.51-2.1-5.64-2.1-5.64l-4.28.6Z"/>
        <path className="cls-41" d="m144.77,4260.52l-24.12-10.19s.32-4.92-3.53-2.54c-3.85,2.38-4.21,13.39.67,7.23l21.31,12.65-1.76,18.33s4.77,5.71,14.22,1.9l5.63-10.36,2.62-7.11-2.14-8.35-12.9-1.58Z"/>
        <path className="cls-92" d="m166.43,4260.73l-7.17,4.87s-4.5,13.18-1.59,18.75l1.75,2.82s-1.81,4.11-1.75,7.74l1.32.76s-.79.69-.73,2.07l1.17.64-1.12,2.46,1.37.35s.04,3.98,0,4.62.81,0,.81,0l.16,1.33,2.08-.64,1.06,1.33s2.44.27,3.19-.27c0,0,2.34.85,2.42-.8,0,0,1.78.9,3.64.27s3.61.37,4.36-.58,1.91-2.6,2.97-2.66c1.06-.05-.9-4.73-.9-4.73l1.22-.32-2.76-5.1,1.38-.74s-2.07-1.59-2.6-4.78c-.53-3.19-.34-12.23-.34-12.23,0,0,3.22-15.23-4.14-13.93l-5.77-1.24Z"/>
        <path className="cls-89" d="m137.34,4286s-5.23,8.49.5,19.98l3.72,8.68s-1.17,11.04,0,19.57l5.27-.23s4.34-21.78,0-34.36l1.93,28.36,4.58-.3s-.74-16.14,0-22.15-.18-12.31-1.79-17.66l-14.22-1.9Z"/>
        <path className="cls-92" d="m151.37,4250.65c-1.24.51-6.62,3.02-3.31,10.22l-3.21-1.07s-.39.49.58,1.07,6.43,1.85,1.07,4.38c0,0,1.17,1.27,3.21.39,0,0-1.07,4.97,1.66,5.06,2.73.1,4.59-2.95-1.63-11.91l2.22-5.52,3.89.19s.58,10.81,1.17,12.76-1.46,3.11-1.46,3.11c0,0,1.12.39,2.12-.68s3.52-8.24.71-13.64c-2.5-4.8-5.1-5.16-7.01-4.37Z"/>
        <path className="cls-53" d="m156.52,4262.78l-1.26,3.59s-1.24,1.01-2.4-.51l-.58-1.58-1.2-3.05,1.13-.72,4.3,2.27Z"/>
        <path className="cls-65" d="m141.56,4334.23s-2.32,3.75.89,5.6c3.21,1.85,9.23,3.13,8.91.56-.32-2.57-3.68-3.86-4.53-6.38l-5.27.23Z"/>
        <path className="cls-65" d="m148.76,4328.01s-2.52,3.31,0,5c2.52,1.69,6.45,3.21,7.49,1.69,1.32-1.93-.16-3.85-1.77-4.01-1.6-.16-1.15-2.97-1.15-2.97l-4.58.3Z"/>
        <path className="cls-53" d="m111.59,4250.86c.24.79,1.07,1.25,1.84,1.55,1.52.6,3.14.97,4.78,1.08.09,0,.19.01.27-.03.12-.06.18-.2.22-.33.29-.92.26-1.94-.13-2.82-.38-.88-1.13-1.61-2.03-1.93-.55-.2-1.15-.24-1.74-.23-.81.01-1.66.14-2.33.6-.67.46-1.11,1.33-.88,2.11Z"/>
        <path className="cls-53" d="m168.57,4249.61c.3-.03,6.73-.1,5.1,7.34-1.63,7.44-5.71,6.32-7.24,2.24-1.53-4.08-2.96-9.07,2.14-9.58Z"/>
        <ellipse className="cls-53" cx="165.21" cy="4256.37" rx=".59" ry="1.48" transform="translate(-1622.76 390.21) rotate(-22.6)"/>
        <path className="cls-53" d="m166.43,4259.19v1.54s2.99,5.25,5.77,1.24l.18-1.45-5.95-1.33Z"/>
        <polygon className="cls-79" points="163.14 4299.17 161.22 4304.91 162.18 4305.23 163.56 4300.71 162.72 4306.5 163.56 4306.5 163.99 4305.81 164.36 4306.77 165.12 4306.5 165.12 4305.49 165.74 4305.6 165.74 4298.69 166.32 4305.6 166.96 4305.55 166.96 4294.91 164.09 4295.99 163.14 4299.17"/>
        <path className="cls-53" d="m151.76,4251.89s4.7-1.64,5.71,3.74c1,5.38-1.95,10.4-3.99,8.49-2.04-1.91-7.86-9.39-1.72-12.23Z"/>
        <path className="cls-53" d="m165.54,4261.33s-1.86-2.64-2.82-2.14c0,0-2.45-1.07-2.33,1.04s1.8,3.38,1.8,3.38l3.35-2.27Z"/>
        <path className="cls-53" d="m192.2,4241.82s2.81,1.57,3.6,0c.79-1.57-1.24-8.76,0-10.11s-.04-6.52-1.7-6.63c-1.67-.11-.99,3.48-1.33,3.26s-2.54-1.58-1.46,0c1.69,2.47,2.02,10.22.9,13.48Z"/>
        <path className="cls-53" d="m180.11,4254.93s-1.53,4.69,0,5.8c1.53,1.11,4.2-.31,4.28-2.23s-.39-3.79-.39-3.79l-3.89.22Z"/>
        <path className="cls-92" d="m182.41,4244.26s-6.13.03-5.18,11.3l.66,9.54,3.28-2.7-1.4-10.16s-.38-7.59,2.64-5.76c3.02,1.83,2.47,5.02,1.99,6.4s1.09,7.64,1.09,7.64c0,0,1.56,3.33,2.62,0s-.02-16.07-5.71-16.26Z"/>
        <path className="cls-57" d="m200.64,4273.44c.44,0,14.6-2.73,14.6-2.73l2.4,10.14-14.38,3.47-2.61-10.87Z"/>
        <ellipse className="cls-53" cx="178.77" cy="4251.33" rx=".59" ry="1.48" transform="translate(-206.67 13.74) rotate(-2.79)"/>
        <ellipse className="cls-53" cx="182.41" cy="4250.88" rx="3.42" ry="5.49"/>
        <path className="cls-53" d="m276.67,4259.81s-5.39,4.15-3.14,6.22c2.25,2.07,6.19-6.34,9.28-4.76,3.09,1.58,6.62.82,2.74-.4-3.88-1.22-2.38-.82-2.38-.82,0,0,6.06-.01,6.19-.85s2.69-3.58-3.2-2.59c-5.9.99,6.02-4.06,3.33-4.36-2.69-.29-8.11,4.48-7.56,2.62.55-1.87-1.05-6.79-1.05-2.96s-4.2,7.91-4.2,7.91Z"/>
        <path className="cls-92" d="m244.99,4336.38s-10.05,2.23-2.67,4.11c7.38,1.88,16.95,1.01,12.36-2.05-4.59-3.07-9.69-2.05-9.69-2.05Z"/>
        <path className="cls-92" d="m257.98,4327.74s-12.47-.14-2.1,3.17c10.37,3.31,10.84,1.53,8.12-2.29l-6.02-.88Z"/>
        <path className="cls-54" d="m260.38,4291.49s6.25,9.52-6.14,17.26l-.66,3.21,12.88,17.1-8.39,2.3-12.04-18.36-1.92,5.52,11.17,17.86s-.18,7.68-10.3,0l-7.5-16.23s-4.38-5.12,1.78-12.79c7.64-9.51,8.6-16.71,8.6-16.71l12.51.84Z"/>
        <path className="cls-53" d="m248.01,4248.58s-6.28-1.08-4.5,7.65c1.41,6.92,5.71,2,5.96-.78.24-2.59-.06-4.99-1.46-6.88Z"/>
        <polygon className="cls-53" points="243.73 4257.13 243.19 4261.12 247.74 4261.26 247.74 4257.13 243.73 4257.13"/>
        <path className="cls-41" d="m253.58,4273.25s.7-17.32,6.55-16.88c5.85.44,7.06,11.52,7.06,11.52l-2.6,2.8-3.52-1.81-7.48,4.37Z"/>
        <path className="cls-92" d="m273.96,4261.33l.93,6.85s-14.11,13.01-14.51,23.31l-12.51-.84s-.97-5.85,2.37-12.03c2.11-3.91,3.34-5.38,3.34-5.38l20.37-11.92Z"/>
        <path className="cls-53" d="m262.26,4258.4s-5.26-1.93-6.35,2.72c-1.09,4.65-1.22,3.25,0,5.92,1.22,2.67,1.54,5.65,5.15,1.83,3.4-3.6,3.92-4,1.2-10.47Z"/>
        <path className="cls-53" d="m228.55,4282.68s-3.07,4.89-.54,6.07,4.46-.15,2.41-6.07h-1.86Z"/>
        <path className="cls-54" d="m219.13,4262.94s-3.37,3.03-4.51-1.61c0,0,1.58.49,1.27-3.82s-.42-7.54,3.24-8.43,4.17.64,4.05,1.24c0,0,1.84-1.11,1.9,2.25s2.29,7.6-1.52,11.6c0,0-2.16.7-2.22-1.14s-2.21-.09-2.21-.09Z"/>
        <path className="cls-53" d="m220.39,4250.88s2.52-.63,2.71,1.8,1.12,2.88-.37,5.41c-1.5,2.53-4.12,4.35-5.1.82-.98-3.52-.79-7.69,2.76-8.04Z"/>
        <polygon className="cls-53" points="222.73 4258.09 222.29 4261.12 219.76 4266.77 218.62 4259.76 222.73 4258.09"/>
        <path className="cls-92" d="m220.2,4279.37s-1.75-3.93-2.57-7.26-2.32-6.29,0-7.81c2.32-1.51,10.05-6.69,12.6-1.87,2.55,4.82,2.55,13.64,1.5,15.08-1.05,1.44-9.6,5.82-9.6,5.82l-.67-4.7-1.27.74Z"/>
        <path className="cls-53" d="m221.47,4278.62s-3.01.27-5.14,1.73c-1.32.9-2.34,1.35-2.34,1.35,0,0,5.09,3.79,7.95-.26l-.47-2.82Z"/>
        <path className="cls-48" d="m209.73,4263.05s2.71-1.95,2.25-6.93-3.75-2.85-3.75-2.85c0,0-2.49-2.49-5.19,1.23-2.7,3.71,0,8.97,0,8.97h.85l.2-.81,5.65.39Z"/>
        <polygon className="cls-53" points="204.08 4262.66 203.63 4264.53 204.83 4267.24 207.17 4267.24 209.39 4265.77 209.85 4262.05 209.85 4260.15 204.08 4262.66"/>
        <ellipse className="cls-53" cx="203.42" cy="4260.27" rx=".59" ry="1.48" transform="translate(-1288.32 264.52) rotate(-17.74)"/>
        <path className="cls-53" d="m209.85,4255.4s-1.64-1.24-2.74,0c-1.1,1.24-3.23,1.61-3.3,3.52s-.01,6.92,3.02,6.51c3.03-.41,2.2-2.81,3.02-4.07,1.51-2.34,0-5.95,0-5.95Z"/>
        <path className="cls-53" d="m217.12,4244.26s.42-3.8-2.5-4.14-3.72-1.88-2.59-1.71c1.12.17-2.41-5.21,1.96-2.82,4.37,2.39,3,4.53,4.63.34s-.43,5.64.68,8.88l-2.18-.55Z"/>
        <path className="cls-53" d="m206.74,4283.96s1.97-2.6,2.39-2.14-2.06,2.78-.62,2.14c1.44-.64,2.01-1.4,2.01-1.4,0,0,4.73-1.56,2.11,1.4-2.62,2.96-5.9,3.74-5.9,3.74v-3.74Z"/>
        <polygon className="cls-41" points="205.18 4283.85 206.97 4283.44 206.74 4288.02 205.18 4288.02 205.18 4283.85"/>
        <path className="cls-79" d="m167.73,4266.7s2.87-.61,5.75,1.19c2.88,1.8,4.5.55,4.5.55l-5.6,5.28-8.67-4.09.56-2.04,3.46-.89Z"/>
        <path className="cls-65" d="m181.67,4265.39l-13.87,27.33s-2.24-.58-4.65-4.65c-2.41-4.07-4.17-10.51-4.17-10.51l-1.9-13.02s18.52,10.07,24.59.85Z"/>
        <path className="cls-54" d="m160.05,4265.95l1.28-.88s2.5.44,2.55,1.63c0,0,1.48-3.28,2.55-2.37s2.34,1.22,2.02,1.81-3.24,1.75-3.24,1.75c0,0-2.94.12-5.16-1.93Z"/>
        <path className="cls-53" d="m164.33,4289.82s-2.94,1.71-.87,3.99c0,0-1.27,3.19,2.18,1.86,3.45-1.33,1.13-3.48,1.13-3.48l-2.44-2.37Z"/>
      </g>
      <g>
        <path className="cls-19" d="m165.99,4253.57s2.22-1.86,3.2,0c.98,1.86-2.94,2.94-3.2,0Z"/>
        <path className="cls-19" d="m170.09,4253.64s1.02-1.82,2.92-.36-2.13,3.79-2.92.36Z"/>
        <path className="cls-19" d="m165.99,4253.57s-.92-.11-1.21.61"/>
        <path className="cls-19" d="m169.19,4253.74s.39-.53.9-.1"/>
        <path className="cls-19" d="m173.33,4253.6s.16-.38.6-.25"/>
      </g>
    </g>
    <g id="Confetti">
      <path className="cls-48 con" d="m147.78,4223.55l-1.23.11c.11,1.19.49,2.4.97,2.36l1.23-.11c-.48.04-.86-1.18-.97-2.36Z"/>
      <path className="cls-11 con-down" d="m183.14,4238.56l.62-1.62c-1.55-.6-3.31-.88-3.56-.25l-.62,1.62c.24-.64,2.01-.35,3.56.24Z"/>
      <path className="cls-79 con" d="m205.18,4231.98l2.08-.19c-.18-2-.82-4.05-1.64-3.97l-2.08.19c.82-.07,1.45,1.98,1.64,3.97Z"/>
      <path className="cls-11 con-down" d="m142.16,4233.7l-1.34-.57c-.55,1.29-.83,2.75-.31,2.98l1.34.57c-.53-.22-.24-1.7.31-2.98Z"/>
      <path className="cls-48 con" d="m133.99,4248.13l-1.1,1.41c1.35,1.06,3,1.87,3.44,1.32l1.1-1.41c-.43.55-2.09-.26-3.44-1.32Z"/>
      <path className="cls-54 con-down" d="m153.84,4245.03l.75-.65c-.62-.73-1.44-1.34-1.73-1.09l-.76.65c.3-.25,1.11.37,1.73,1.09Z"/>
      <path className="cls-54 con" d="m317.58,4248.09l1.29,1.03c-.99,1.23-2.3,2.3-2.81,1.9l-1.28-1.03c.51.41,1.82-.67,2.81-1.9Z"/>
      <path className="cls-11 con-down" d="m260.88,4248.09l-1.48.97c-.93-1.42-1.58-3.11-1-3.49l1.48-.97c-.58.38.08,2.08,1,3.49Z"/>
      <path className="cls-79 con" d="m122.7,4272.68l-1.4-1.09c1.05-1.35,2.44-2.52,2.99-2.09l1.4,1.09c-.55-.43-1.95.75-2.99,2.09Z"/>
      <path className="cls-48 con-down" d="m165.82,4233.75l-.43-.7c.68-.42,1.47-.7,1.64-.43l.43.7c-.17-.28-.97.01-1.65.43Z"/>
      <path className="cls-79 con" d="m310.09,4260.77l-.43-.7c.68-.42,1.47-.7,1.64-.43l.43.7c-.17-.28-.97.01-1.65.43Z"/>
      <path className="cls-79 con-down" d="m111.75,4238.64l.93-1.34c1.29.89,2.43,2.11,2.07,2.63l-.92,1.34c.36-.53-.79-1.75-2.07-2.63Z"/>
      <path className="cls-54 con" d="m185.26,4229.3l-.84-.75c.72-.8,1.65-1.48,1.98-1.19l.84.75c-.33-.29-1.27.39-1.98,1.19Z"/>
      <path className="cls-79 con-down" d="m230.54,4245.35l1.37.59c-.56,1.32-1.45,2.57-1.98,2.34l-1.37-.59c.54.23,1.42-1.03,1.98-2.34Z"/>
      <path className="cls-11 con" d="m202.72,4245.77l-1.32-1.37c1.32-1.27,2.98-2.31,3.5-1.77l1.32,1.37c-.52-.54-2.19.5-3.5,1.77Z"/>
      <path className="cls-54 con-down" d="m127.76,4229.24l-1.09.65c-.63-1.04-1.05-2.28-.63-2.53l1.09-.65c-.43.26,0,1.5.63,2.53Z"/>
      <path className="cls-79 con" d="m265.27,4238.06l-.79.83c-.8-.76-1.46-1.73-1.15-2.05l.79-.83c-.31.33.36,1.3,1.15,2.05Z"/>
      <path className="cls-11 con-down" d="m168.98,4222.81l-.39,1.98c-1.9-.37-3.8-1.19-3.65-1.96l.39-1.98c-.15.78,1.76,1.59,3.65,1.96Z"/>
      <path className="cls-48 con" d="m232.76,4236.79l1.13,1.09c-1.04,1.09-2.38,1.99-2.82,1.57l-1.13-1.09c.45.43,1.79-.48,2.82-1.57Z"/>
      <path className="cls-54 con-down" d="m247,4238.36l-.1,1.54c-1.48-.1-3.01-.53-2.97-1.14l.1-1.54c-.04.61,1.5,1.04,2.97,1.14Z"/>
      <path className="cls-48 con" d="m309.58,4273.36l.08,1.39c-1.33.08-2.74-.14-2.77-.69l-.08-1.39c.03.55,1.45.76,2.78.69Z"/>
      <path className="cls-48 con-down" d="m295.95,4243.6l1.35-.61c.58,1.3.9,2.79.37,3.03l-1.35.61c.53-.24.21-1.73-.37-3.03Z"/>
      <path className="cls-54 con" d="m283.7,4237.73l-1.04-.12c.11-1,.46-2.01.86-1.97l1.04.12c-.41-.05-.75.98-.86,1.97Z"/>
      <path className="cls-79 con-down" d="m307.88,4233.69l1.26-.1c.09,1.21-.08,2.49-.57,2.53l-1.26.1c.49-.04.67-1.33.57-2.53Z"/>
    </g>
    <text transform="translate(43.67 4184.06)"><tspan className="cls-5"><tspan x="0" y="0">Corporate story</tspan></tspan><tspan className="cls-115"><tspan x="-20.71" y="16">het verhaal van CJG Rijnmond</tspan></tspan></text>
    <a href={props.audio} target="_blank" rel="noopener noreferrer" >
      <g id="Playbutton_geluid" data-name="Playbutton geluid">
        <circle className="cls-41" cx="29.56" cy="4171.93" r="10.09"/>
        <path className="cls-57" d="m26.38,4178.19v-12.53l9.05,6.26-9.05,6.26Z"/>
      </g>
    </a>
    <g id="Titel">
      <rect id="Achtergrond-2" data-name="Achtergrond" className="cls-57" x="26.11" y="4117.42" width="280.44" height="33" rx="2.5" ry="2.5"/>
      <text className="cls-55" transform="translate(43.61 4142.01)"><tspan x="0" y="0">Nieuwe merkcampagne</tspan></text>
    </g>
    </g>
    </>
)

}


