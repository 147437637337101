export const data = {
    'kinderen': 
    [
        <div>
            <h3>Soa-vrij de zomer in!</h3>
            <p>Op 1 juni organiseerden we in samenwerking met GGD Rotterdam-Rijnmond een <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/soa-vrij-de-zomer-door-thuistestactie-voor-jongeren">soa-thuistestactie voor jongeren</a> bij CJG Centrum. En het was een succes! Jeugdverpleegkundigen Radha en Agnita deelden de thuistest uit aan 43 jongeren en beantwoorden ze vragen over relaties en seks.</p>
            
        </div>,
        <div>
            <h3>Peuters in de spotlights</h3>
            <p>In de maand september organiseerden alle CJG-locaties een <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/peuterweek">Peuterweek</a>. Honderden ouders kwamen met hun kind naar het speciale peuterconsult. Ook ontvingen zij een uitnodiging voor gratis webinars, themabijeenkomsten en opvoedquizen.</p>
            
        </div>,
        <div>
            <h3>Huggy Wuggy verovert Nederland</h3>
            <p>Huggy Wuggy is een karakter uit het horrorspel Poppy Playtime. Op het eerste oog ziet hij er knuffelbaar uit, maar als hij zijn mond opent komen er vlijmscherpe tanden tevoorschijn en wil hij je aanvallen. <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/wat-is-huggy-wuggy-en-hoe-ga-je-ermee-om">Onze jeugdverpleegkundige Karen beantwoordde vragen over dit fenomeen onder kinderen</a>. </p>
            
        </div>,
    ],
    'ouders': [
        <div>
            <h3>Nieuw: het zwangerschapsgesprek</h3>
            <p>De eerste 1.000 levensdagen van een kind zijn bepalend voor een goede start. Ze hebben grote invloed op mogelijke lichamelijke en geestelijke problemen in hun verdere leven. Om (aanstaande) ouders en hun gezin voor te bereiden op hun baby, biedt CJG Rijnmond sinds juli 2022 het <a target="_blank" rel="noopener noreferrer" href="http://www.cjgrijnmond.nl/zwangerschapsgesprek">zwangerschapsgesprek</a> aan.</p>
            
        </div>, 
        <div>
            <h3>Puberchallenge: altijd en overal</h3>
            <p><a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/iedr-moment-starten-met-puberchallenge">De Puberchallenge</a>, de gratis pubercursus voor alle (toekomstige) puberouders, is een succesvolle onlinecursus van CJG Rijnmond. Vanaf 2022 kun je deze volgen wanneer het hen uitkomt!</p>
        </div>, 
        <div>
            <h3>Leeftijdsnieuwsbrieven voor alle ouders</h3>
            <p>Naast de consulten ontvangen alle ouders <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuwsbrief-ouders">e-mails over onze dienstverlening</a>, het volgende contactmoment, opvoedinformatie en relevante cursussen. Passend bij de leeftijd van het kind. Zo kunnen we bij iedere leeftijdsfase op het juiste moment én met de juiste informatie ondersteuning bieden.</p>
            
        </div>, 
  
    ],
    'cjg': [
        <div>
            <h3>Ouderportaal wordt Mijn CJG Rijnmond</h3>
            <p>Met de komst van onder andere dienstverlening voor zwangeren en het openstellen van het ouderportaal voor steeds meer leeftijdsgroepen, is het portaal niet meer alleen voor ouders.
            <a rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/ouderportaal-wordt-mijn-cjg-rijnmond">
            Hoog tijd dus voor een nieuwe, passende naam: Mijn CJG Rijnmond.</a></p>
        </div>, 
        <div>
            <h3>CJG Rijnmond bestaat 12,5 jaar</h3>
            <p>Om ons jubileum te vieren, ontmoetten onze collega’s elkaar op een feestelijke locatie in de buitenlucht. Samen maakten we De Wandeling, met onverwachte momenten en bijzondere gesprekken. Onze nieuwe merkcampagne en kernwaarden stonden centraal. <a rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/cjg-rijnmond-bestaat-12-5-jaar">Bekijk de video voor een impressie.</a></p>
            
        </div>,
        <div>
            <h3>Zorg voor vluchtelingen uit Oekraïne</h3>
            <p>Door de oorlog tussen Rusland en Oekraïne kwamen er dit voorjaar tienduizenden vluchtelingen naar onze regio. <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/zorg-voor-vluchtelingen-uit-oekra%C3%AFne">CJG Rijnmond biedt de eerste zorg aan de groep kinderen tussen 0 en 14 maanden oud. </a>Door extra inzet van onze jeugdverpleegkundigen en jeugdartsen, naast de reguliere dienstverlening. </p>
        </div>, 
        <div>
            <h3>Bakkie met…</h3>
            <p><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=Y91W0f6pTi4">In onze videorubriek Bakkie met…</a> vertellen professionals van CJG Rijnmond over hun werk. Zo willen we, in de huidige krappe arbeidsmarkt, nieuwe collega’s vinden én binden. </p>       
        </div>,
        
    ],
    'scholen': [
        <div>
            <h3>PATS in de praktijk</h3>
            <p>Patricia Bender is ondersteuningscoördinator bij Calvijn De Meerpaal in Rotterdam Prins Alexander. Zij heeft veel ervaring met leerlingenzorg en verzuim. <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/pats-in-de-praktijk-ervaringen-bij-calvijn-de-meerpaal?utm_content=content&utm_campaign=pats&utm_source=cd&utm_medium=email">De methode PATS is een welkome toevoeging op school</a>, om haar leerlingen weer op de rit te krijgen.</p>
            
        </div>,
        
    ],
    'zorg': [
        <div>
            <h3>Speciale pagina’s voor professionals</h3>
            <p>De website van CJG Rijnmond wordt vaak gebruikt door zorgprofessionals, om hun cliënten en patiënten van de juiste informatie te voorzien. Voor onze samenwerkingspartners hebben we <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/professionals">alles overzichtelijk bij elkaar gezet</a>. Van producten en diensten tot opvoedinformatie, gratis (online) cursussen, nieuws en meer. Zo maken we gezond en veilig opgroeien samen mogelijk.</p>
            
        </div>,
        <div>
            <h3>Hoog bezoek</h3>
            <p>Minister Ernst Kuipers bracht op 16 maart een <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/minister-ernst-kuipers-brengt-werkbezoek-aan-integrale-geboortezorg-rotterdam-rijnmond">werkbezoek</a> aan de Rijnmondse geboortezorg en een vertegenwoordiging van Ouders van Rijnmond, om te horen hoe integraal samenwerken in de praktijk gaat. De minister nam ruim de tijd om te horen wat er speelt in onze regio. Het werd een vruchtbaar werkbezoek.</p>
            
        </div>,
        <div>
            <h3>Verhaal in Vakblad Vroeg</h3>
            <p>In het Vakblad Vroeg deelt voorzorgverpleegkundige Simone Winterink haar <a target="_blank" rel="noopener noreferrer" href="https://www.vakbladvroeg.nl/licht-gloort-na-verleden-van-misbruik-en-mishandeling/">praktijkverhaal</a>, uit de eerste 1.000 dagen van een ouder en kind.</p>
            
        </div>,
        <div>
            <h3>Hechting</h3>
            <p>Op 10 maart werd de <a target="_blank" rel="noopener noreferrer" href="https://www.rtlnieuws.nl/editienl/artikel/5293853/vrouwenbesnijdenis-meisjesbesnijdenis-gemeente-rotterdam-film-centrum-voor#:~:text=Het%20is%20een%20verfilming%20van,vertoond%20worden%20op%20bijvoorbeeld%20scholen.">verfilming van het toneelstuk Hechting</a> gelanceerd, een film met als doel meisjesbesnijdenis bespreekbaar te maken. Marijke van Dongen, projectleider van ons project Vrouwelijke Genitale Verminking, werd voor EditieNL hierover geïnterviewd.</p> 
        </div>,
    ],
    'gemeenten': [
        <div>
            <h3>Informatieloket Relatie, Ouders & Scheiding</h3>
            <p>Op 9 september, de Dag van de Scheiding, was de officiële opening van het <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/informatieloket-relatie-ouders-scheiding">Informatieloket Relatie, Ouders & Scheiding</a>. Het loket is bedoeld voor Rotterdamse gezinnen, maar ook voor professionals, die op zoek zijn naar advies of hulp op het gebied van relatie, opvoeding en scheiding. </p>
        </div>, 
        <div>
            <h3>Theatercollege in Barendrecht</h3>
            <p>In samenwerking met Theater het Kruispunt organiseerden we op 12 oktober het <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/cursus/theatercollege-how2talk2kids-effectief-communiceren-met-kinderen">theatercollege How2Talk2Kids</a>: effectief communiceren met je kind. Een succesvolle avond was het. Ruim 250 bezoekers zaten in de zaal. </p>
            
        </div>, 
        <div>
            <h3>Toegankelijk voor iedereen</h3>
            <p>Op onze website <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl">cjgrijnmond.nl</a> staat betrouwbare opvoedinformatie voor alle (aanstaande) ouders. Informatie die je gemakkelijk kunt vertalen in het Engels, Oekraïens, Chinees, Frans, Arabisch, Pools, Spaans en Turks. Zo maken we onze informatie voor zoveel mogelijk ouders toegankelijk. </p>
        </div>, 
        <div>
            <h3>Beleidsadvies Scheidingsproblemen bij jongeren</h3>
            <p>De beleidsadviesnota Scheidingsproblemen bij jongeren is samengesteld door het team Beleid & Onderzoek voor alle gemeenten in ons werkgebied. Het is het resultaat van literatuuronderzoek, bestudering van landelijke en lokale programma’s en gesprekken met onze deskundigen. </p>
        </div>, 
      
    ],
    'landelijke': [
        <div>
            <h3>Meer geld voor preventie</h3>
            <p>In de gezamenlijk opgestelde agenda Publieke gezondheid voor jeugd laten JGZ-organisaties zien wat volgens hen nodig is om <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/agenda-jgz-nu-investeren-voor-gezonde-generaties">toekomstige generaties in ons land gezond te laten opgroeien</a>. Zij doen opnieuw een oproep aan het kabinet om structureel 300 miljoen euro extra te investeren in de jeugdgezondheidszorg.</p>
            
        </div>, 
        <div>
            <h3>Landelijk Professioneel Kader vernieuwd</h3>
            <p>Het <a target="_blank" rel="noopener noreferrer" href="https://info.jijenjegezondheid.nl/wp-content/uploads/2022/09/Landelijk-Professioneel-Kader.pdf">Landelijk Professioneel Kader (LPK)</a> is geüpdatet. Het vernieuwde LPK geeft meer duiding bij passende zorg op maat en aandacht voor de implementatie. Ook is rekening gehouden met nieuwe ontwikkelingen, zoals het zwangerschapsgesprek. </p>
            
        </div>, 
    ],
    'merkcampagne': [
        <div>
            <h3>Nieuwe merkcampagne </h3>
            <p>CJG Rijnmond heeft zijn merkpositionering in 2022 opnieuw vastgesteld; met een visie, missie, nieuwe kernwaarden en een corporate story is de basis gelegd voor de toekomst. Onze aansprekende campagne en nieuwe huisstijlmiddelen kom je overal tegen; op straat, op de CJG-locaties en in alle digitale uitingen. <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/themakrant-groei">Met de huis-aan-huis-krant GROEI! trapten we in juni de merkcampagne af</a>!</p>
            
        </div>,
        <div>
            <h3>Ons verhaal</h3>
            <p>Bij een nieuwe merkpositionering hoort een <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/over-ons/ons-verhaal">corporate story</a>. Een verhaal dat vertelt wie we zijn, wat we doen en waarom we het zo doen. Ons verhaal begint zo: <p><span className="italic">Daar sta je dan, met je pasgeboren baby in je armen. Een nieuw leven, weerloos maar vol vertrouwen en belofte. Je hebt negen maanden aan het idee kunnen wennen… </span></p></p>
            
        </div>,
        <div>
            <h3>CJG Rijnmond genomineerd voor Galjaardprijs</h3>
            <p><a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/cjg-rijnmond-genomineerd-voor-beste-publieke-campagne-2022">Super trots zijn we op de nominatie voor de beste landelijke publieke campagne, de Galjaardprijs</a>. Op 3 november grepen we in Bussum nét naast de prijs. We namen in ieder geval een Oorkonde en veel complimenten mee terug naar Rotterdam. </p>
        </div>,
        <div>
            <h3>Alles onder 1 (online) dak</h3>
            <p>Samen groeien. Daar staat CJG Rijnmond voor. En onze website groeit mee. Vanaf 1 juni vind je al onze informatie op <a target="_blank" rel="noopener noreferrer" href="https://cjgrijnmond.nl/nieuws/nieuwe-website-cjgrijnmond">1 website: cjgrijnmond.nl</a>. </p>
            
        </div>,

    ],
}
