import React from "react";
import closeBtn from "../../images/btn-close.svg";

export default function windowPopup({toggle, children}) {
    return (
        <div className="popup">
          <div className="overlay"></div>
          <div className="popup__content">
            <img
              className="btn btn--close"
              src={closeBtn}
              alt="button"
              onClick={toggle}
            />
            {children}
          </div>
        </div>
      );  
}