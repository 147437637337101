import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

export default function Scholen(props) {
  const isDesctop = props.isDesctop;
  const desctopScreen = useRef();
  const mobileScreen = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const numbers = self.selector('.number');
      numbers.forEach((number) => {
        gsap.from(number, {
          textContent: 0,
          duration: 2,
          snap: { textContent: 1 },
          scrollTrigger: {
            trigger: number,
          },
        });
      });
    }, desctopScreen);
    return () => ctx.revert(); // <- Cleanup!     
  }, [])

  useLayoutEffect(() => {
    const ctxMobile = gsap.context((self) => {
      const numbers = self.selector('.number');
      numbers.forEach((number) => {
        gsap.from(number, {
          textContent: 0,
          duration: 2,
          snap: { textContent: 1 },
          scrollTrigger: {
            trigger: number,
          },
        });
      });
    }, mobileScreen);
    return () => ctxMobile.revert(); // <- Cleanup!     
  }, [])

  if (isDesctop) {
      return (
          <>
              <g ref={desctopScreen} id="Scholen">
                  <path id="Ondergrond-5" data-name="Ondergrond" className="cls-96" d="m479.23,2190.64h-111.23c14.49,0,26.24-11.75,26.24-26.24s-11.75-26.24-26.24-26.24h-222.52c14.49,0,26.24,11.75,26.24,26.24s-11.75,26.24-26.24,26.24h-43.15c-13.17,0-23.85,10.68-23.85,23.85s10.68,23.85,23.85,23.85h106.89c-12.34,0-22.34,10-22.34,22.34s10,22.34,22.34,22.34h155.24c12.34,0,22.34-10,22.34-22.34s-10-22.34-22.34-22.34h114.76c13.17,0,23.85-10.68,23.85-23.85s-10.68-23.85-23.85-23.85Z"/>
                  <g id="Schoolgebouw">
                  <g>
                      <path className="cls-60" d="m306.04,2057.3h87.98c1.12,0,2.03.91,2.03,2.03v76.81c0,1.12-.91,2.03-2.03,2.03h-87.98c-1.12,0-2.03-.91-2.03-2.03v-76.81c0-1.12.91-2.02,2.02-2.02Z"/>
                      <path className="cls-23" d="m303.22,2057.48c0,1.11.92,2.02,2.03,2.02h94.67c1.12,0,1.37-.63.56-1.4l-19.94-18.97c-.8-.77-2.38-1.4-3.5-1.4h-71.8c-1.11,0-2.03.91-2.03,2.02v17.72Z"/>
                      <path className="cls-60" d="m105.5,2136.13c0,1.11.92,2.02,2.03,2.02h85.47c1.12,0,2.03-.91,2.03-2.02v-76.2c0-1.12-.91-2.03-2.03-2.03h-85.47c-1.12,0-2.03.91-2.03,2.03v76.2Z"/>
                      <path className="cls-23" d="m195.55,2057.48c0,1.11-.92,2.02-2.03,2.02h-92.54c-1.11,0-1.37-.63-.56-1.4l19.94-18.97c.81-.77,2.39-1.4,3.5-1.4h69.66c1.11,0,2.03.91,2.03,2.02v17.72Z"/>
                      <path className="cls-60" d="m309.3,2138.16h-117.69v-123.5c0-1.11.82-2.42,1.83-2.9l53.97-25.66c1.01-.48,2.66-.49,3.67-.03l56.38,25.72c1.01.46,1.84,1.76,1.84,2.87v123.5Z"/>
                      <path className="cls-23" d="m314.65,2011.17l-63.97-29.24c-1.01-.46-2.67-.45-3.67.04l-62.52,29.87c-1,.48-1.34,1.65-.75,2.59l1.88,2.99c.59.95,1.9,1.33,2.91.84l58.58-28c1.01-.48,2.66-.5,3.67-.04l59.98,27.43c1.02.46,2.31.06,2.89-.89l1.81-3.02c.57-.96.21-2.12-.8-2.58Z"/>
                      <g>
                      <path className="cls-106" d="m141.17,2107.68c0,1.11-.91,2.03-2.02,2.03h-20.24c-1.11,0-2.03-.92-2.03-2.04v-29.99c.01-1.11.92-2.03,2.04-2.03h20.25c1.12,0,2.03.92,2.03,2.04l-.02,29.99Z"/>
                      <path className="cls-106" d="m179.08,2107.68c0,1.11-.91,2.03-2.03,2.03h-20.24c-1.11,0-2.03-.92-2.03-2.04v-29.99c0-1.11.92-2.03,2.03-2.03h20.24c1.12,0,2.03.92,2.03,2.04v29.99Z"/>
                      </g>
                      <g>
                      <path className="cls-106" d="m199.09,2075.65h20.24c1.12,0,2.03.91,2.03,2.03v29.99c0,1.12-.91,2.03-2.03,2.03h-20.25c-1.12,0-2.03-.91-2.03-2.03v-29.99c0-1.12.91-2.03,2.03-2.03Z"/>
                      <path className="cls-106" d="m302.07,2107.68c0,1.11-.92,2.03-2.03,2.03h-20.25c-1.11,0-2.03-.92-2.03-2.04v-29.99c.01-1.11.92-2.03,2.04-2.03h20.25c1.11,0,2.02.92,2.02,2.04v29.99Z"/>
                      </g>
                      <g>
                      <path className="cls-106" d="m344.45,2107.68c0,1.11-.92,2.03-2.03,2.03h-20.24c-1.12,0-2.03-.92-2.03-2.04v-29.99c0-1.11.92-2.03,2.03-2.03h20.25c1.12,0,2.03.92,2.03,2.04v29.99Z"/>
                      <path className="cls-106" d="m382.34,2107.68c0,1.11-.91,2.03-2.03,2.03h-20.23c-1.11,0-2.03-.92-2.03-2.04v-29.99c0-1.11.92-2.03,2.03-2.03h20.25c1.11,0,2.02.92,2.02,2.04v29.99Z"/>
                      </g>
                      <g>
                      <path className="cls-60" d="m260.16,2009.23c0-5.88-4.78-10.64-10.65-10.64s-10.64,4.76-10.64,10.64,4.76,10.65,10.64,10.65,10.65-4.77,10.65-10.65Z"/>
                      <path className="cls-38" d="m249.51,1997.67c-6.39,0-11.56,5.17-11.56,11.55s5.17,11.56,11.56,11.56,11.56-5.17,11.56-11.56-5.19-11.55-11.56-11.55Zm0,21.29c-5.38,0-9.72-4.36-9.74-9.74.01-5.38,4.36-9.72,9.74-9.74,5.36.01,9.73,4.36,9.74,9.74,0,5.38-4.37,9.73-9.74,9.74Z"/>
                      <path className="cls-38" d="m254.42,2011.2c-1.12-.41-4.49-1.64-4.49-1.64l3.77-5.95c.26-.42.14-.98-.29-1.25-.42-.28-.98-.15-1.25.27h0l-3.93,6.26c-.69,1.06-.19,1.9.64,2.2,1.24.45,3.7,1.34,4.94,1.8.1.04.2.05.31.05.37,0,.72-.23.86-.59.17-.47-.07-1-.54-1.16Z"/>
                      </g>
                      <rect className="cls-79" x="228.64" y="2085.77" width="41.89" height="52.39"/>
                      <path className="cls-23" d="m268.72,2074.37c-.32-1.07-1.49-1.94-2.61-1.94h-33.04c-1.12,0-2.29.87-2.61,1.94l-3.7,12.46c-.31,1.07.34,1.94,1.45,1.94h42.76c1.12,0,1.76-.88,1.46-1.94l-3.71-12.46Z"/>
                  </g>
                  <g>
                      <polygon className="cls-38" points="341.76 2151.32 241.93 2151.33 244.3 2146.28 339 2146.26 341.76 2151.32"/>
                      <polygon className="cls-38" points="347.45 2163.97 237 2163.99 240.34 2156.94 343.55 2156.92 347.45 2163.97"/>
                      <polygon className="cls-38" points="355.11 2178.33 230.5 2178.35 234.96 2169.47 349.86 2169.45 355.11 2178.33"/>
                  </g>
                  </g>
                  <g id="Jongetje">
                  <path className="cls-56" d="m212.11,2206.51c.19-.37.2-.82.1-1.23-.33-1.36-1.7-2.27-3.09-2.49-1.39-.22-2.8.11-4.16.44-1.61.39-3.22.78-4.73,1.46-1.5.69-2.9,1.69-3.8,3.08.23,1.15,1.29,2,2.43,2.31,1.14.3,2.34.15,3.5-.06,2.88-.51,5.7-1.35,8.39-2.51.53-.23,1.09-.5,1.36-1.01Z"/>
                  <path className="cls-60" d="m142.77,2159.14s-.87,10.59,1.47,11.36c2.34.77,40.69,1.37,40.69,1.37l9.05,37,11.75-5.11-9.67-34.91s-1.86-8.05-27.55-8.36l-25.74-1.35Z"/>
                  <path className="cls-23" d="m126.51,2127.94h17.54c2.58,0,4.68,2.1,4.68,4.68v25.37c0,2.58-2.1,4.68-4.68,4.68h-17.54v-34.73h0Z"/>
                  <path className="cls-23" d="m130.76,2175.5h41.16c1.81,0,3.27,1.47,3.27,3.27v1.96c0,1.81-1.47,3.27-3.27,3.27h-41.16v-8.5h0Z"/>
                  <rect className="cls-23" x="134.49" y="2158.44" width="6.84" height="18.34"/>
                  <rect className="cls-23" x="134.06" y="2181.03" width="6.84" height="34.94"/>
                  <rect className="cls-23" x="164.65" y="2181.03" width="6.84" height="34.94"/>
                  <path className="cls-76" d="m148.64,2109.93c0,1.27-1.31,2.3-2.92,2.3s-2.92-1.03-2.92-2.3,1.31-2.3,2.92-2.3,2.92,1.03,2.92,2.3Z"/>
                  <path className="cls-56" d="m191.31,2218.7c.39-.15.7-.47.9-.84.67-1.23.27-2.83-.61-3.92-.88-1.09-2.15-1.8-3.38-2.47-1.45-.79-2.91-1.59-4.48-2.09-1.58-.5-3.29-.7-4.88-.27-.61,1.01-.39,2.35.25,3.34.64.99,1.63,1.69,2.63,2.31,2.48,1.56,5.13,2.83,7.89,3.78.54.19,1.15.36,1.69.16Z"/>
                  <polygon className="cls-97" points="160.62 2120.05 152.6 2117.91 143.26 2120.15 143.82 2128.19 163 2128.19 160.62 2120.05"/>
                  <path className="cls-74" d="m148.86,2121.58c0,2.93,9.59,2.93,9.59,0v-7.61h-9.59v7.61Z"/>
                  <path className="cls-74" d="m167.26,2099.31c2.71,8.39-1.58,17.29-9.58,19.87-8,2.58-16.68-2.13-19.39-10.52-2.71-8.39,1.58-17.29,9.58-19.87,8-2.58,16.68,2.13,19.39,10.52Z"/>
                  <path className="cls-56" d="m140.12,2088.67c-1.58,0-3.2-.16-4.69.48-.31.13-.67.42-.55.74.73.18,1.45.42,2.14.72-.85-.12-1.71.27-2.31.89-.59.62-.94,1.44-1.14,2.27-.68,2.72,0,5.61,1.09,8.19.83,1.95,1.88,3.79,2.8,5.7.81,1.68,1.43,3.49,2.35,5.1.37.65.79,1.29,1.4,1.71.61.42,1.46.58,2.1.21-.48-.9-.96-1.81-1.44-2.71.64.44,1.54.44,2.19.02-1.23-.55-2.14-1.65-2.74-2.86-.59-1.21-.9-2.53-1.2-3.84-.31-1.33-.62-2.47-.49-3.82.08-.82.1-1.65.15-2.47.09-1.41,1.05-1.98,1.14-3.39,0-.16,5.17-1.35,5.64-1.39.97-.09,1.98-.18,2.91.13.93.31,1.75,1.14,1.73,2.12,0,.38-.11.82.17,1.08.4.37,1.03-.15,1.22-.66.31-.84.24-1.82-.18-2.61,1.9-.35,3.85-.69,5.75-.31,1.89.38,3.74,1.67,4.19,3.55.3,1.27-.06,2.63.21,3.91.98-.23,1.68-1.31,1.49-2.31.8.84,1.41,2.27,1.99,3.29.56.98.84,2.25,1.64,3.04.52.52,1.29.83,2.01.68.17-.04.35-.1.45-.24.25-.32,0-.78-.26-1.1-.35-.45-.7-.9-1.05-1.35.32.08.66.17.99.08.32-.09.6-.42.51-.74-.05-.19-.22-.32-.38-.44-.77-.57-.57-1.24-1.16-1.99-.73-.94-1.22-1.11-.92-2.28.04-.14-.85-1.12-.71-1.17.17-.05.34.1.52.1.28,0,.4-.34.43-.62.3-2.36-.69-4.85-2.53-6.37.34-.11.68-.23,1.02-.34-.18-1.44-1.21-2.63-2.29-3.6-1.92-1.74-4.18-3.14-6.66-3.87-2.49-.72-5.22-.73-7.63.21.48-.56.96-1.12,1.44-1.68-1.15-.47-2.48-.16-3.63.33-1.98.85-3.31,2.16-4.82,3.62-1.25,1.22-2.37,2.23-2.9,4Z"/>
                  <path className="cls-97" d="m166.02,2133.18c-1.62-2.42-5.2-6.62-7.05-8.84-1.24,1.72-3.11,2.85-5.53,2.67-2.29-.17-4.05-1.39-5.43-3.06-.04.03-.08.06-.12.08l-7.49,7.93c-.21.85-.06,1.17-.12,1.6l-.43,31.92,25.56,2.43.41-14.02-.03-.04.24-20.69Z"/>
                  <path className="cls-60" d="m139.87,2164.14s-.87,10.59,1.47,11.36c2.34.77,35.96,2.57,35.96,2.57l-.77,31.67,11.75,1.55.95-33.22s2.06-12.27-23.62-12.59l-25.74-1.35Z"/>
                  <rect className="cls-23" x="170.33" y="2156.31" width="63.17" height="9.44"/>
                  <rect className="cls-23" x="194.42" y="2162.33" width="13.22" height="57.82"/>
                  <path className="cls-97" d="m197.24,2139.15l-18.77-4.15-9.39-14.84-8.9-.27c.35,1.52.36,3.13-.02,4.58,0,0-.71.45-.71.45,2.52,1.4,2.42,6.34,4.79,8.02l9.9,8.57h0l22.82,3.55.27-5.91Z"/>
                  <path className="cls-97" d="m147.94,2126.71c-.27.01-.55.02-.82.01-1.12-.02-2.91-6.24-3.86-6.58l-2.86,11.81,13.68,26.14,18.41-.94-.96-4.79c-.28-.21-.55-.42-.83-.63l-12.94-.36-6.12-13.84-.32-1.31-2.33-9.61c-.35.04-.7.06-1.05.08Z"/>
                  <path className="cls-74" d="m199.93,2135.08c-.41-.04-.81-.07-1.23-.1-.31-.02-.63-.04-.94-.06-1.29-.07-2.61-.11-3.95-.08-.83.01-1.56.05-2.25.09-1.63.11-2.94.32-4.4.59l-.64.85c3.29-.43,7.54-.45,11.52-.31,5.99.22,11.3.78,11.6.82-2.46-.77-5.89-1.45-9.72-1.8Z"/>
                  <path className="cls-38" d="m199.73,2133.3c.46.37.67,1.18.21,1.78,3.83.35,7.26,1.03,9.72,1.8,0,0,.01,0,.02,0-.8-1.87-1.65-4.27-2.21-7.14-.19-.98-.32-1.91-.43-2.82-.23-2.01-.27-3.83-.22-5.33-2.36,3.9-4.73,7.8-7.09,11.7Z"/>
                  <path className="cls-74" d="m199.73,2133.3c-.54-.43-1.42-.24-1.96,1.62.32.02.63.03.94.06.41.03.82.07,1.23.1.46-.6.25-1.41-.21-1.78Z"/>
                  <path className="cls-86" d="m197.5,2136.98l-11.91.09h-.28s-1.51,2.27-1.51,2.27l-.4.61-5.58,8.39c.88.07,1.32.79.64,2.34-.15-.24-.47-.57-1.04-.65.4.41.64.82.77,1.2-.17.31-.37.64-.62,1.01-.24.35-.5.71-.82,1.11-.12.16-.25.26-.37.37-.87.76-1.73.49-2.37.35l-.27.41-1.28,1.93c.98-.29,2.19-.46,3.65-.49.75-.02,1.54-.01,2.43.05,4.29.3,7.51.32,10.08.28,2.05-.03,3.68-.09,5.04-.08l2.78-3.34,6.97-8.39,6.27-7.55-12.17.09Z"/>
                  <path className="cls-38" d="m209.65,2136.88c-.3-.03-5.61-.6-11.6-.82-3.98-.14-8.23-.12-11.52.31-.18.02-.38.04-.55.07l-.4.62,11.91-.09,12.17-.09s-.01,0-.02,0Z"/>
                  <path className="cls-74" d="m179.12,2147c.49.56.71,1.37.65,2.13-.29,3.52-3.07,7.23-6.96,6.6-.13-.02-.26-.05-.36-.14-.07-.07-.11-.17-.15-.26-.38-.97-.75-1.93-1.13-2.9-.14-.37-.29-.75-.33-1.15-.23-2.4,5.4-5.59,7.47-4.84.32.11.58.31.8.56Z"/>
                  <path className="cls-23" d="m148.05,2105.3c-.1-.66.35-1.28,1.02-1.38.66-.1,1.28.35,1.38,1.02s-.35,1.28-1.02,1.38-1.28-.35-1.38-1.02Z"/>
                  <path className="cls-23" d="m159.74,2103.52c-.1-.66.35-1.28,1.02-1.38s1.28.35,1.38,1.02-.35,1.28-1.02,1.38-1.28-.35-1.38-1.02Z"/>
                  <path className="cls-11" d="m152.3,2114.08c1.58,2.06,5.02,2.06,7.69.01"/>
                  </g>
                  <g id="Pubers">
                  <path className="cls-70" d="m306.94,2131.42c-.19,1.09,1.48,14.95,3.13,15.05,1.65.1,3.2-4.09,3.68-8.63.46-4.33.52-8.32-2.18-9.6-1.48-.7-4.4,1.88-4.63,3.18Z"/>
                  <path className="cls-70" d="m281.25,2131.16c-.98,9.35,2.11,16.71,10.64,19.65,2.35-.85,20.43-13.22,22.06-15.66.33-3.88-.72-6.97-4.2-9.5-4.53-3.28-15.06-2.57-19.92-2.81-4.51-.68-8.12,3.91-8.58,8.32Z"/>
                  <path className="cls-65" d="m292.9,2198.88l-8.82,2.6c-.33-3.63.12-6.21,2.79-5.8l5.21.51,2.8-.2-1.98,2.89Z"/>
                  <rect className="cls-23" x="275.34" y="2200.25" width="123.85" height="25"/>
                  <path className="cls-56" d="m332.79,2230.45c-.16-.3-.16-.66-.08-.99.26-1.1,1.37-1.83,2.49-2.01,1.12-.18,2.25.09,3.35.36,1.3.31,2.6.63,3.81,1.18,1.21.55,2.34,1.36,3.06,2.49-.18.93-1.04,1.62-1.96,1.86-.92.24-1.89.12-2.82-.04-2.32-.41-4.6-1.09-6.76-2.02-.43-.18-.88-.4-1.1-.82Z"/>
                  <path className="cls-60" d="m381.68,2193.06s-1.64,4.5-3.52,5.13c-1.88.62-23.46,4.33-23.46,4.33l-7.3,29.84-9.48-4.12,7.8-28.15s1.5-6.49,22.22-6.74l13.74-.28Z"/>
                  <path className="cls-56" d="m349.56,2240.28c-.32-.12-.56-.38-.73-.68-.54-.99-.21-2.28.5-3.16.71-.88,1.73-1.45,2.72-1.99,1.17-.64,2.35-1.28,3.62-1.69,1.27-.41,2.65-.56,3.94-.22.49.81.32,1.9-.2,2.69-.52.8-1.32,1.36-2.12,1.86-2,1.26-4.14,2.28-6.37,3.05-.44.15-.92.29-1.36.13Z"/>
                  <path className="cls-60" d="m381.68,2193.06s.7,8.54-1.18,9.16c-1.88.62-19.64,5.3-19.64,5.3l.62,25.54-9.48,1.25-.77-26.78s-1.66-9.9,19.05-10.15l11.4-4.32Z"/>
                  <path className="cls-72" d="m377.6,2178.85c-.33.03-3.74.45-2.58.28-1.24.18-2.47.36-3.71.54-4.92.7-9.82,1.41-14.78,1.3l-.35,2.96-6.46,17.83c-.09.1-.18.21-.26.33-.75,1.14-1.45,2.32-1.1,3.74.32,1.31,1.41,2.3,2.7,2.66,1.36.38,3.05.1,3.8-1.24.62-1.11.18-3.07-1.18-3.31.25-.66.08-1.44-.33-2.03l7.69-16.04.09-3.05,17.03-.83.87-3.24c-.47.03-.95.06-1.42.11Z"/>
                  <path className="cls-72" d="m388.8,2201.8l8.82,2.6c.33-3.63-.12-6.21-2.79-5.8l-5.21.51-2.8-.2,1.98,2.89Z"/>
                  <path className="cls-38" d="m386.23,2161.22c-1.32-2.21-3.58-3.69-6.14-4.02l-7.98-1.02-9.44,2.01c-1.89.4-3.37,1.37-3.8,3.26l-8.23,35.4,6.05,4.04,2.35-6.73,23.43-.56-1.21-23.18.2,12.98,4.93,16.93,6.94-1.75-7.1-37.34Z"/>
                  <path className="cls-72" d="m369.5,2155.87c.24.06.47.12.7.16,6.56,1.35,12.76-3.58,13.23-10.25l.88-10.79c0-6.42-5.11-11.76-11.53-11.87-.24,0-.47,0-.71,0-6.46,0-11.58,5.23-11.45,11.69.08,4.33.42,7.44.73,11.18.39,4.67,3.61,8.7,8.15,9.88Z"/>
                  <path className="cls-72" d="m374.98,2159.09c0,1.31-6.76,1.31-6.76,0v-4.96h6.76v4.96Z"/>
                  <path className="cls-23" d="m334.28,2237.29c.34-.04.64-.24.86-.49.76-.84.74-2.17.26-3.19-.48-1.02-1.34-1.81-2.18-2.57-.99-.9-1.98-1.8-3.12-2.49-1.14-.69-2.44-1.17-3.78-1.13-.67.67-.75,1.77-.44,2.66.32.9.96,1.63,1.62,2.31,1.65,1.69,3.49,3.19,5.47,4.46.39.25.83.5,1.29.45Z"/>
                  <path className="cls-86" d="m289.19,2193.06s1.64,4.5,3.52,5.13c1.88.62,23.46,4.33,23.46,4.33l7.3,29.84,9.48-4.12-7.8-28.15s-1.5-6.49-22.22-6.74l-13.74-.28Z"/>
                  <path className="cls-23" d="m321.32,2240.28c.32-.12.56-.38.73-.68.54-.99.21-2.28-.5-3.16-.71-.88-1.73-1.45-2.72-1.99-1.17-.64-2.35-1.28-3.62-1.69-1.27-.41-2.65-.56-3.94-.22-.49.81-.32,1.9.2,2.69.52.8,1.32,1.36,2.12,1.86,2,1.26,4.14,2.28,6.37,3.05.44.15.92.29,1.36.13Z"/>
                  <path className="cls-86" d="m289.19,2193.06s-.7,8.54,1.18,9.16c1.88.62,19.64,5.3,19.64,5.3l-.62,25.54,9.48,1.25.77-26.78s1.66-9.9-19.05-10.15l-11.4-4.32Z"/>
                  <path className="cls-65" d="m282.08,2201.8l-8.82,2.6c-.33-3.63.12-6.21,2.79-5.8l5.21.51,2.8-.2-1.98,2.89Z"/>
                  <path className="cls-56" d="m284.64,2161.22c1.32-2.21,3.58-3.69,6.14-4.02l7.98-1.02,9.44,2.01c1.89.4,3.37,1.37,3.8,3.26l-.94,16.8v11.99l.76,3.91-23.43-.56,1.21-23.18-.2,12.98-4.93,16.93-6.94-1.75,7.1-37.34Z"/>
                  <path className="cls-65" d="m301.38,2155.87c-.24.06-.47.12-.7.16-6.56,1.35-12.76-3.58-13.23-10.25l-.88-10.79c0-6.42,5.11-11.76,11.53-11.87.24,0,.47,0,.71,0,6.46,0,11.58,5.23,11.45,11.69-.08,4.33-.42,7.44-.73,11.18-.39,4.67-3.61,8.7-8.15,9.88Z"/>
                  <path className="cls-65" d="m295.89,2159.09c0,1.31,6.76,1.31,6.76,0v-4.96h-6.76v4.96Z"/>
                  <path className="cls-72" d="m361.02,2138.24c.56,2.09,0,4.05-1.24,4.38-1.24.33-2.7-1.09-3.26-3.18-.56-2.09,0-4.05,1.24-4.38,1.24-.33,2.7,1.09,3.26,3.18Z"/>
                  <path className="cls-72" d="m380.36,2131.82c-.56,2.09,0,4.05,1.24,4.38,1.24.33,2.7-1.09,3.26-3.18.56-2.09,0-4.05-1.24-4.38-1.24-.33-2.7,1.09-3.26,3.18Z"/>
                  <path className="cls-23" d="m377.98,2121.88h0c4.46-1.46,11.86,1.82,13.39,18.43l3.88,44.53c0,5.22-7.89,7.1-13.11,7.06l-7.28-36.19c4.94-2.04,7.63-7.77,7.1-14.74l-17.27-11.11-2.86,5.92.27,13c2.05,3.15,4.43,4.6,6.24,5.49v5.46s-4.61.76-6.05,1.32c-2.79,1.09-3.52,4.96-3.52,4.96l-4.88,17.81c-5.41-1.03,2.47-33.58,3.36-40.02,2.96-21.34,9.34-24.83,15.75-24.65,2,.06,3.85,1.07,4.98,2.71Z"/>
                  <path className="cls-3" d="m297.96,2150.23c1.18,1.53,3.74,1.54,5.74,0"/>
                  <path className="cls-3" d="m365.87,2148.54c1.18,1.53,3.74,1.54,5.74,0"/>
                  <path className="cls-23" d="m295.9,2140.94c-.09-.59.32-1.14.91-1.23s1.14.32,1.23.91-.32,1.14-.91,1.23-1.14-.32-1.23-.91Z"/>
                  <path className="cls-23" d="m304.62,2140.94c-.09-.59.32-1.14.91-1.23s1.14.32,1.23.91-.32,1.14-.91,1.23-1.14-.32-1.23-.91Z"/>
                  <path className="cls-23" d="m363.31,2140.94c-.09-.59.32-1.14.91-1.23s1.14.32,1.23.91-.32,1.14-.91,1.23-1.14-.32-1.23-.91Z"/>
                  <path className="cls-23" d="m372.03,2140.94c-.09-.59.32-1.14.91-1.23s1.14.32,1.23.91-.32,1.14-.91,1.23-1.14-.32-1.23-.91Z"/>
                  <path className="cls-70" d="m290.26,2138.15c.06,5.01-2.74,7.74-4.84,7.98-1.61.19-1.23-6.11-.96-11.12.3-5.73,1.61-7.72,5.37-8.41.07,1.26.37,5.67.44,11.54Z"/>
                  <path className="cls-70" d="m289.97,2133.76c-.46.95-1.57,2.27-2.59,4.21.06-.97-.06-9.47,0-10.93.75-.07,3.69-.35,4.95-.44.07,0,2.37,4.42.85,3.63-3.02-1.58-2.41,1.85-3.21,3.52Z"/>
                  <path className="cls-70" d="m313.18,2125.3c-6.29-4.97-16.24-7.98-21.69-2.1.45.58-5.5,4.4-4.4,4.93,1.83.88,10.89-1.66,11.19-1.52,6.6-2.9,12.37-1.34,14.9-1.31Z"/>
                  <path className="cls-70" d="m316.55,2135c-8.88,3.93-25.75-4.87-25.16-6.92.59-2.05,9.73-5.13,15.99-3.33,6.26,1.8,9.03,9.36,9.17,10.25Z"/>
                  <path className="cls-65" d="m288.6,2142.83c.52,1.94,0,3.77-1.15,4.08-1.15.31-2.51-1.02-3.03-2.96-.52-1.94,0-3.77,1.15-4.08,1.15-.31,2.51,1.02,3.03,2.96Z"/>
                  <path className="cls-97" d="m300.22,2224.77c0,6.66,2.98,12.06-10.57,12.06-12.63,0-10.57-5.4-10.57-12.06s4.52-15.9,10.36-15.9,10.79,9.24,10.79,15.9Z"/>
                  <polygon className="cls-79" points="283.31 2228.73 283.31 2232.92 296.33 2233.47 296.33 2228.37 283.31 2228.73"/>
                  <path className="cls-97" d="m285.73,2210.06s-1.53-3.04-5.29.58c-3.76,3.62-8.27,18.21-.79,21.71,0,0-1.93-3.49-2.11-8.24-.19-5.15,2.86-13.21,7.36-12.87l.83-1.18Z"/>
                  <path className="cls-79" d="m299.58,2223.47s-3.81-13.65-9.48-13.34-8.9,5.69-9.7,14.15l.81.11s.18-12.62,8.78-13.51c5.09-.53,8.32,12.81,8.32,12.81l1.27-.22Z"/>
                  <polygon className="cls-97" points="286.98 2210.09 286.98 2207.78 288.2 2206.99 290.84 2206.99 291.89 2207.95 291.89 2210.34 290.84 2210.11 290.84 2208.1 287.97 2208.22 287.85 2209.96 286.98 2210.09"/>
                  </g>
                  <text transform="translate(365.01 1988.29)"><tspan className="cls-25"><tspan className="number" x="0" y="0">198</tspan></tspan><tspan className="cls-33"><tspan x="-32.12" y="18">onderwijsprofessionals </tspan></tspan><tspan className="cls-33"><tspan x="-37.2" y="36">kregen </tspan><tspan className="cls-130" x="12.17" y="36">P</tspan><tspan className="cls-144" x="20.29" y="36">A</tspan><tspan x="28.78" y="36">TS-nieuwsbrief</tspan></tspan></text>
                  <g id="Titel-6" data-name="Titel">
                  <rect id="Achtergrond-7" data-name="Achtergrond" className="cls-38" x="323.65" y="1882.17" width="175.27" height="55.57" rx="4.21" ry="4.21"/>
                  <text className="cls-44" transform="translate(342.06 1923.77)"><tspan x="0" y="0">Scholen</tspan></text>
                  </g>
              </g>
          </>
      )
  }
  return (
    <>
        <g ref={mobileScreen} id="Scholen">
    <path id="Ondergrond-5" data-name="Ondergrond" className="cls-91" d="m292.76,2211.26h-105.89c8.85,0,16.02-7.17,16.02-16.02s-7.17-16.02-16.02-16.02H51.05c8.85,0,16.02,7.17,16.02,16.02s-7.17,16.02-16.02,16.02h-26.34c-8.04,0-14.56,6.52-14.56,14.56s6.52,14.56,14.56,14.56h68.77c-8.1,0-14.66,6.56-14.66,14.66s6.56,14.66,14.66,14.66h177.02c8.1,0,14.66-6.56,14.66-14.66s-6.56-14.66-14.66-14.66h22.25c8.04,0,14.56-6.52,14.56-14.56s-6.52-14.56-14.56-14.56Z"/>
    <g id="Schoolgebouw">
      <g>
        <path className="cls-48" d="m149.06,2129.87h53.7c.68,0,1.24.55,1.24,1.24v46.88c0,.68-.55,1.24-1.24,1.24h-53.7c-.68,0-1.24-.55-1.24-1.24v-46.88c0-.68.55-1.24,1.24-1.24Z"/>
        <path className="cls-41" d="m147.34,2129.98c0,.68.56,1.23,1.24,1.23h57.79c.68,0,.84-.38.34-.85l-12.17-11.58c-.49-.47-1.45-.85-2.13-.85h-43.82c-.68,0-1.24.56-1.24,1.23v10.81Z"/>
        <path className="cls-48" d="m27.89,2130.24h52.18c.68,0,1.24.55,1.24,1.24v46.51c0,.68-.55,1.24-1.24,1.24H27.89c-.68,0-1.24-.56-1.24-1.24v-46.51c0-.68.55-1.24,1.24-1.24Z"/>
        <path className="cls-41" d="m81.62,2129.98c0,.68-.56,1.23-1.24,1.23H23.89c-.68,0-.83-.38-.34-.85l12.17-11.58c.49-.47,1.46-.85,2.14-.85h42.52c.68,0,1.24.56,1.24,1.23v10.81Z"/>
        <path className="cls-48" d="m151.05,2179.23h-71.84v-75.38c0-.68.5-1.48,1.12-1.77l32.94-15.66c.61-.29,1.62-.3,2.24-.02l34.41,15.7c.62.28,1.12,1.07,1.12,1.75v75.38Z"/>
        <path className="cls-41" d="m154.31,2101.71l-39.05-17.85c-.62-.28-1.63-.27-2.24.02l-38.16,18.23c-.61.29-.82,1.01-.46,1.58l1.14,1.82c.36.58,1.16.81,1.77.51l35.76-17.09c.62-.29,1.62-.3,2.24-.02l36.61,16.75c.62.28,1.41.04,1.76-.55l1.1-1.84c.35-.58.13-1.29-.49-1.57Z"/>
        <g>
          <path className="cls-100" d="m48.43,2160.62c0,.68-.56,1.24-1.23,1.24h-12.36c-.68,0-1.24-.56-1.24-1.24v-18.31c0-.68.56-1.24,1.24-1.24h12.36c.68,0,1.24.56,1.24,1.25v18.3Z"/>
          <path className="cls-100" d="m57.98,2141.07h12.36c.68,0,1.24.55,1.24,1.24v18.31c0,.68-.56,1.24-1.24,1.24h-12.35c-.68,0-1.24-.56-1.24-1.24v-18.31c0-.68.55-1.24,1.24-1.24Z"/>
        </g>
        <g>
          <path className="cls-100" d="m83.78,2141.07h12.36c.68,0,1.24.55,1.24,1.24v18.31c0,.68-.55,1.24-1.24,1.24h-12.35c-.68,0-1.24-.56-1.24-1.24v-18.3c0-.68.56-1.24,1.24-1.24Z"/>
          <path className="cls-100" d="m133.05,2141.07h12.35c.68,0,1.24.55,1.24,1.24v18.31c0,.68-.55,1.23-1.23,1.23h-12.36c-.68,0-1.23-.55-1.23-1.23v-18.31c0-.68.55-1.24,1.24-1.24Z"/>
        </g>
        <g>
          <path className="cls-100" d="m158.91,2141.07h12.36c.68,0,1.24.55,1.24,1.24v18.31c0,.68-.55,1.24-1.24,1.24h-12.35c-.69,0-1.24-.56-1.24-1.24v-18.3c0-.68.55-1.24,1.24-1.24Z"/>
          <path className="cls-100" d="m182.05,2141.07h12.35c.68,0,1.24.55,1.24,1.24v18.31c0,.68-.55,1.24-1.24,1.24h-12.36c-.68,0-1.23-.55-1.23-1.23v-18.31c0-.68.55-1.24,1.24-1.24Z"/>
        </g>
        <g>
          <path className="cls-48" d="m121.05,2100.53c0-3.59-2.92-6.5-6.5-6.5s-6.5,2.91-6.5,6.5,2.9,6.5,6.5,6.5,6.5-2.91,6.5-6.5Z"/>
          <path className="cls-57" d="m114.55,2093.48c-3.9,0-7.05,3.16-7.05,7.05s3.15,7.06,7.05,7.06,7.06-3.16,7.06-7.06-3.17-7.05-7.06-7.05Zm0,13c-3.29,0-5.94-2.66-5.94-5.94,0-3.28,2.66-5.94,5.94-5.94,3.27,0,5.94,2.66,5.94,5.94,0,3.28-2.67,5.94-5.94,5.94Z"/>
          <path className="cls-57" d="m117.55,2101.73c-.68-.25-2.74-1-2.74-1l2.3-3.63c.16-.26.08-.6-.18-.76-.26-.17-.6-.09-.76.17h0l-2.4,3.82c-.42.65-.12,1.16.39,1.34.75.28,2.26.82,3.01,1.1.06.03.12.03.19.03.23,0,.44-.14.52-.36.1-.29-.04-.61-.33-.71Z"/>
        </g>
        <rect className="cls-74" x="101.82" y="2147.25" width="25.57" height="31.98"/>
        <path className="cls-41" d="m126.28,2140.29c-.19-.65-.91-1.19-1.59-1.19h-20.17c-.68,0-1.4.53-1.59,1.19l-2.26,7.6c-.19.65.21,1.19.88,1.19h26.1c.68,0,1.08-.54.89-1.19l-2.26-7.6Z"/>
      </g>
      <g>
        <polygon className="cls-57" points="170.86 2187.26 109.93 2187.27 111.38 2184.18 169.18 2184.17 170.86 2187.26"/>
        <polygon className="cls-57" points="174.34 2194.98 106.92 2194.99 108.96 2190.69 171.96 2190.68 174.34 2194.98"/>
        <polygon className="cls-57" points="179.01 2203.75 102.95 2203.76 105.67 2198.34 175.81 2198.32 179.01 2203.75"/>
      </g>
    </g>
    <g id="Jongetje">
      <path className="cls-11" d="m101.51,2227.61c.13-.25.13-.56.07-.84-.22-.93-1.16-1.55-2.11-1.7-.95-.15-1.91.08-2.84.3-1.1.26-2.2.53-3.22,1-1.03.47-1.98,1.15-2.59,2.1.16.79.88,1.37,1.66,1.57.78.2,1.6.1,2.39-.04,1.96-.35,3.89-.92,5.72-1.71.36-.15.75-.34.93-.69Z"/>
      <path className="cls-48" d="m54.22,2195.3s-.59,7.22,1,7.75,27.75.93,27.75.93l6.17,25.24,8.01-3.49-6.6-23.81s-1.27-5.49-18.79-5.7l-17.55-.92Z"/>
      <path className="cls-41" d="m43.13,2174.03h11.96c1.76,0,3.19,1.43,3.19,3.19v17.31c0,1.76-1.43,3.19-3.19,3.19h-11.96v-23.69h0Z"/>
      <path className="cls-41" d="m46.03,2206.46h28.07c1.23,0,2.23,1,2.23,2.23v1.33c0,1.23-1,2.23-2.23,2.23h-28.07v-5.8h0Z"/>
      <rect className="cls-41" x="48.58" y="2194.83" width="4.67" height="12.51"/>
      <rect className="cls-41" x="46.03" y="2210.23" width="4.67" height="23.83"/>
      <rect className="cls-41" x="67.46" y="2210.23" width="4.67" height="23.83"/>
      <path className="cls-71" d="m58.23,2161.74c0,.87-.89,1.57-1.99,1.57s-1.99-.7-1.99-1.57.89-1.57,1.99-1.57,1.99.7,1.99,1.57Z"/>
      <path className="cls-11" d="m87.33,2235.93c.27-.1.48-.32.61-.57.46-.84.18-1.93-.42-2.68-.6-.75-1.46-1.22-2.3-1.68-.99-.54-1.99-1.08-3.06-1.43-1.07-.34-2.24-.48-3.33-.18-.41.69-.27,1.6.17,2.28.44.67,1.11,1.15,1.79,1.58,1.69,1.06,3.5,1.93,5.38,2.58.37.13.78.25,1.15.11Z"/>
      <polygon className="cls-92" points="66.39 2168.64 60.93 2167.18 54.56 2168.71 54.94 2174.2 68.02 2174.2 66.39 2168.64"/>
      <path className="cls-69" d="m58.37,2169.69c0,2,6.54,2,6.54,0v-5.19h-6.54v5.19Z"/>
      <path className="cls-69" d="m70.93,2154.49c1.85,5.72-1.08,11.79-6.53,13.55-5.46,1.76-11.38-1.45-13.23-7.17-1.85-5.72,1.08-11.79,6.53-13.55,5.46-1.76,11.38,1.45,13.23,7.17Z"/>
      <path className="cls-11" d="m52.41,2147.24c-1.08,0-2.18-.11-3.2.33-.21.09-.45.28-.38.5.5.13.99.29,1.46.49-.58-.08-1.17.19-1.57.61-.4.42-.64.98-.78,1.55-.47,1.86,0,3.83.75,5.59.56,1.33,1.28,2.59,1.91,3.89.55,1.14.97,2.38,1.6,3.48.25.44.54.88.96,1.17.42.29,1,.39,1.44.14-.33-.62-.66-1.23-.98-1.85.43.3,1.05.3,1.49.01-.84-.38-1.46-1.13-1.87-1.95-.41-.82-.62-1.73-.82-2.62-.21-.91-.42-1.68-.34-2.6.05-.56.07-1.12.1-1.68.06-.96.72-1.35.77-2.31,0-.11,3.52-.92,3.84-.95.66-.06,1.35-.12,1.98.09.63.21,1.19.78,1.18,1.45,0,.26-.08.56.11.74.28.25.7-.1.83-.45.21-.57.17-1.24-.12-1.78,1.3-.24,2.63-.47,3.92-.21,1.29.26,2.55,1.14,2.85,2.42.21.87-.04,1.79.14,2.67.67-.16,1.15-.9,1.02-1.57.55.57.96,1.55,1.36,2.24.38.67.57,1.53,1.12,2.08.36.35.88.57,1.37.46.12-.02.24-.07.31-.17.17-.22,0-.53-.18-.75-.24-.31-.48-.61-.71-.92.22.06.45.11.67.05.22-.06.41-.29.35-.51-.04-.13-.15-.22-.26-.3-.52-.39-.39-.84-.79-1.36-.5-.64-.83-.76-.63-1.56.02-.1-.58-.77-.49-.8.12-.04.23.07.36.06.19,0,.27-.24.3-.42.2-1.61-.47-3.31-1.72-4.34.23-.08.46-.16.69-.23-.12-.98-.82-1.79-1.56-2.46-1.31-1.18-2.85-2.14-4.54-2.64-1.7-.49-3.56-.5-5.2.15.33-.38.66-.77.98-1.15-.79-.32-1.69-.11-2.47.23-1.35.58-2.26,1.47-3.28,2.47-.85.83-1.62,1.52-1.98,2.73Z"/>
      <path className="cls-92" d="m70.08,2177.6c-1.1-1.65-3.55-4.52-4.81-6.03-.85,1.17-2.12,1.94-3.77,1.82-1.56-.11-2.76-.95-3.7-2.09-.03.02-.05.04-.08.06l-5.11,5.41c-.14.58-.04.8-.08,1.09l-.29,21.77,17.44,1.66.28-9.56-.02-.03.16-14.11Z"/>
      <path className="cls-48" d="m52.24,2198.72s-.59,7.22,1,7.75,24.53,1.76,24.53,1.76l-.53,21.6,8.02,1.06.65-22.65s1.41-8.37-16.11-8.58l-17.55-.92Z"/>
      <rect className="cls-41" x="73.02" y="2193.38" width="43.09" height="6.43"/>
      <rect className="cls-41" x="90.78" y="2198.72" width="9.01" height="39.44"/>
      <path className="cls-92" d="m91.37,2181.67l-12.8-2.83-6.41-10.12-6.07-.19c.24,1.04.25,2.13-.01,3.12,0,0-.49.31-.48.31,1.72.96,1.65,4.32,3.27,5.47l6.75,5.84h0l15.56,2.42.18-4.03Z"/>
      <path className="cls-92" d="m57.75,2173.19c-.19,0-.37.01-.56,0-.77-.01-1.99-4.26-2.63-4.48l-1.95,8.06,9.33,17.83,12.55-.64-.65-3.26c-.19-.14-.38-.28-.56-.43l-8.83-.24-4.17-9.44-.22-.89-1.59-6.55c-.24.03-.48.04-.72.05Z"/>
      <path className="cls-69" d="m93.21,2178.9c-.28-.03-.55-.05-.84-.07-.21-.01-.43-.03-.64-.04-.88-.05-1.78-.07-2.69-.06-.57,0-1.07.03-1.53.06-1.11.08-2.01.22-3,.41l-.43.58c2.24-.3,5.14-.31,7.85-.21,4.09.15,7.7.53,7.91.56-1.68-.52-4.02-.99-6.63-1.23Z"/>
      <path className="cls-57" d="m93.07,2177.68c.31.25.46.81.14,1.22,2.61.24,4.95.71,6.63,1.23,0,0,0,0,.01,0-.55-1.28-1.12-2.91-1.51-4.87-.13-.67-.22-1.3-.29-1.92-.16-1.37-.19-2.61-.15-3.64-1.61,2.66-3.22,5.32-4.84,7.98Z"/>
      <path className="cls-69" d="m93.07,2177.68c-.37-.3-.97-.17-1.34,1.11.22.01.43.02.64.04.28.02.56.04.84.07.31-.41.17-.96-.14-1.22Z"/>
      <path className="cls-81" d="m91.55,2180.19l-8.13.06h-.19s-1.03,1.55-1.03,1.55l-.28.41-3.8,5.72c.6.05.9.54.44,1.59-.1-.16-.32-.39-.71-.44.27.28.43.56.52.82-.11.21-.25.44-.42.69-.16.24-.34.49-.56.76-.08.11-.17.18-.26.25-.59.52-1.18.34-1.62.24l-.18.28-.87,1.32c.67-.2,1.5-.31,2.49-.34.51-.01,1.05,0,1.65.03,2.93.21,5.12.22,6.87.19,1.4-.02,2.51-.06,3.44-.05l1.89-2.28,4.76-5.72,4.28-5.15-8.3.06Z"/>
      <path className="cls-57" d="m99.84,2180.12c-.21-.02-3.82-.41-7.91-.56-2.71-.1-5.61-.09-7.85.21-.13.02-.26.03-.38.05l-.27.42,8.13-.06,8.3-.06s0,0-.01,0Z"/>
      <path className="cls-69" d="m79.01,2187.03c.33.38.49.94.44,1.45-.2,2.4-2.1,4.93-4.75,4.5-.09-.01-.18-.03-.24-.1-.05-.05-.08-.11-.1-.18-.26-.66-.51-1.32-.77-1.98-.1-.25-.2-.51-.22-.78-.15-1.64,3.68-3.81,5.1-3.3.22.08.4.21.55.38Z"/>
      <path className="cls-41" d="m57.83,2158.58c-.07-.45.24-.87.69-.94s.87.24.94.69-.24.87-.69.94-.87-.24-.94-.69Z"/>
      <path className="cls-41" d="m65.8,2157.37c-.07-.45.24-.87.69-.94s.87.24.94.69-.24.87-.69.94-.87-.24-.94-.69Z"/>
      <path className="cls-22" d="m60.72,2164.57c1.08,1.4,3.42,1.41,5.25,0"/>
    </g>
    <g id="Pubers">
      <path className="cls-65" d="m175.13,2182.78c-.15.82,1.11,11.25,2.36,11.33,1.24.07,2.41-3.08,2.77-6.49.35-3.26.39-6.26-1.64-7.23-1.11-.53-3.31,1.42-3.48,2.39Z"/>
      <path className="cls-65" d="m155.79,2182.58c-.74,7.04,1.59,12.58,8.01,14.79,1.77-.64,15.38-9.95,16.61-11.79.25-2.92-.54-5.25-3.16-7.15-3.41-2.47-11.34-1.93-15-2.12-3.39-.52-6.11,2.94-6.46,6.26Z"/>
      <path className="cls-53" d="m164.57,2233.56l-6.64,1.96c-.25-2.73.09-4.68,2.1-4.36l3.92.38,2.11-.15-1.49,2.18Z"/>
      <rect className="cls-41" x="151.35" y="2234.59" width="93.23" height="18.82"/>
      <path className="cls-11" d="m194.59,2257.32c-.12-.23-.12-.5-.06-.75.2-.83,1.03-1.38,1.88-1.51.84-.13,1.7.07,2.53.27.98.24,1.96.47,2.87.89.91.42,1.76,1.03,2.3,1.87-.14.7-.78,1.22-1.47,1.4-.69.18-1.42.09-2.13-.03-1.75-.31-3.46-.82-5.09-1.52-.32-.14-.66-.3-.82-.61Z"/>
      <path className="cls-48" d="m231.4,2229.17s-1.23,3.39-2.65,3.86c-1.42.47-17.66,3.26-17.66,3.26l-5.49,22.46-7.13-3.1,5.87-21.19s1.13-4.89,16.72-5.08l10.34-.21Z"/>
      <path className="cls-11" d="m207.21,2264.72c-.24-.09-.42-.29-.55-.51-.41-.75-.16-1.72.37-2.38.53-.66,1.3-1.09,2.05-1.5.88-.48,1.77-.97,2.72-1.27.96-.31,2-.42,2.96-.16.37.61.24,1.43-.15,2.03-.39.6-.99,1.02-1.6,1.4-1.5.94-3.11,1.72-4.79,2.29-.33.11-.7.22-1.02.1Z"/>
      <path className="cls-48" d="m231.4,2229.17s.53,6.43-.89,6.9c-1.42.47-14.79,3.99-14.79,3.99l.47,19.22-7.14.94-.58-20.16s-1.25-7.45,14.34-7.64l8.58-3.25Z"/>
      <path className="cls-67" d="m228.33,2218.48c-.25.02-2.81.34-1.94.21-.93.13-1.86.27-2.79.4-3.7.53-7.4,1.06-11.12.98l-.27,2.23-4.86,13.42c-.07.08-.13.16-.19.25-.56.85-1.09,1.75-.83,2.82.24.99,1.06,1.73,2.03,2,1.02.28,2.3.07,2.86-.93.47-.83.13-2.31-.89-2.49.18-.5.06-1.08-.25-1.53l5.79-12.08.07-2.3,12.82-.63.66-2.44c-.36.02-.71.05-1.07.08Z"/>
      <path className="cls-67" d="m236.75,2235.76l6.64,1.96c.25-2.73-.09-4.68-2.1-4.36l-3.92.38-2.11-.15,1.49,2.18Z"/>
      <path className="cls-57" d="m234.82,2205.21c-.99-1.66-2.7-2.78-4.62-3.03l-6.01-.77-7.11,1.51c-1.42.3-2.54,1.03-2.86,2.45l-6.2,26.65,4.56,3.04,1.77-5.06,17.64-.42-.91-17.45.15,9.77,3.71,12.74,5.22-1.32-5.35-28.11Z"/>
      <path className="cls-67" d="m222.22,2201.18c.18.05.35.09.53.12,4.94,1.01,9.6-2.69,9.96-7.72l.66-8.12c0-4.83-3.84-8.85-8.68-8.93-.18,0-.35,0-.53,0-4.86,0-8.72,3.94-8.62,8.8.06,3.26.32,5.6.55,8.42.29,3.52,2.72,6.55,6.13,7.44Z"/>
      <path className="cls-67" d="m226.36,2203.61c0,.99-5.09.99-5.09,0v-3.73h5.09v3.73Z"/>
      <path className="cls-41" d="m195.71,2262.47c.25-.03.48-.18.65-.37.57-.63.56-1.63.2-2.4-.36-.77-1.01-1.37-1.64-1.94-.74-.67-1.49-1.35-2.35-1.87-.86-.52-1.84-.88-2.84-.85-.5.51-.57,1.33-.33,2.01.24.67.72,1.23,1.22,1.74,1.24,1.27,2.62,2.4,4.12,3.36.29.19.62.38.97.34Z"/>
      <path className="cls-81" d="m161.77,2229.17s1.23,3.39,2.65,3.86c1.42.47,17.66,3.26,17.66,3.26l5.49,22.46,7.13-3.1-5.87-21.19s-1.13-4.89-16.72-5.08l-10.34-.21Z"/>
      <path className="cls-41" d="m185.96,2264.72c.24-.09.42-.29.55-.51.41-.75.16-1.72-.37-2.38-.53-.66-1.3-1.09-2.05-1.5-.88-.48-1.77-.97-2.72-1.27-.96-.31-2-.42-2.96-.16-.37.61-.24,1.43.15,2.03.39.6.99,1.02,1.6,1.4,1.5.94,3.11,1.72,4.79,2.29.33.11.7.22,1.02.1Z"/>
      <path className="cls-81" d="m161.77,2229.17s-.53,6.43.89,6.9c1.42.47,14.79,3.99,14.79,3.99l-.47,19.22,7.14.94.58-20.16s1.25-7.45-14.34-7.64l-8.58-3.25Z"/>
      <path className="cls-53" d="m156.42,2235.76l-6.64,1.96c-.25-2.73.09-4.68,2.1-4.36l3.92.38,2.11-.15-1.49,2.18Z"/>
      <path className="cls-11" d="m158.35,2205.21c.99-1.66,2.7-2.78,4.62-3.03l6.01-.77,7.11,1.51c1.42.3,2.54,1.03,2.86,2.45l-.7,12.65v9.03l.57,2.95-17.64-.42.91-17.45-.15,9.77-3.71,12.74-5.22-1.32,5.35-28.11Z"/>
      <path className="cls-53" d="m170.95,2201.18c-.18.05-.35.09-.53.12-4.94,1.01-9.6-2.69-9.96-7.72l-.66-8.12c0-4.83,3.84-8.85,8.68-8.93.18,0,.35,0,.53,0,4.86,0,8.72,3.94,8.62,8.8-.06,3.26-.32,5.6-.55,8.42-.29,3.52-2.72,6.55-6.13,7.44Z"/>
      <path className="cls-53" d="m166.82,2203.61c0,.99,5.09.99,5.09,0v-3.73h-5.09v3.73Z"/>
      <path className="cls-67" d="m215.84,2187.91c.42,1.57,0,3.05-.93,3.3-.93.25-2.03-.82-2.45-2.39-.42-1.57,0-3.05.93-3.3.93-.25,2.03.82,2.45,2.39Z"/>
      <path className="cls-67" d="m230.4,2183.08c-.42,1.57,0,3.05.93,3.3.93.25,2.03-.82,2.45-2.39.42-1.57,0-3.05-.93-3.3-.93-.25-2.03.82-2.45,2.39Z"/>
      <path className="cls-41" d="m228.61,2175.59h0c3.36-1.1,8.93,1.37,10.08,13.88l2.92,33.52c0,3.93-5.94,5.35-9.87,5.32l-5.48-27.24c3.72-1.53,5.74-5.85,5.34-11.09l-13-8.36-2.15,4.46.2,9.79c1.54,2.37,3.34,3.46,4.7,4.13v4.11s-3.47.57-4.55,1c-2.1.82-2.65,3.74-2.65,3.74l-3.67,13.41c-4.07-.78,1.86-25.28,2.53-30.12,2.23-16.06,7.03-18.69,11.86-18.56,1.5.04,2.9.8,3.75,2.04Z"/>
      <path className="cls-31" d="m168.37,2196.94c.89,1.16,2.82,1.16,4.32,0"/>
      <path className="cls-31" d="m219.49,2195.66c.89,1.16,2.82,1.16,4.32,0"/>
      <path className="cls-41" d="m166.83,2189.94c-.07-.44.24-.86.68-.93s.86.24.93.68-.24.86-.68.93-.86-.24-.93-.68Z"/>
      <path className="cls-41" d="m173.39,2189.94c-.07-.44.24-.86.68-.93s.86.24.93.68-.24.86-.68.93-.86-.24-.93-.68Z"/>
      <path className="cls-41" d="m217.57,2189.94c-.07-.44.24-.86.68-.93s.86.24.93.68-.24.86-.68.93-.86-.24-.93-.68Z"/>
      <path className="cls-41" d="m224.13,2189.94c-.07-.44.24-.86.68-.93s.86.24.93.68-.24.86-.68.93-.86-.24-.93-.68Z"/>
      <path className="cls-65" d="m162.58,2187.84c.04,3.77-2.06,5.82-3.65,6.01-1.22.14-.92-4.6-.72-8.37.23-4.32,1.22-5.81,4.04-6.33.05.95.28,4.27.33,8.69Z"/>
      <path className="cls-65" d="m162.36,2184.54c-.34.72-1.18,1.71-1.95,3.17.05-.73-.04-7.13,0-8.23.56-.05,2.78-.26,3.72-.33.06,0,1.78,3.33.64,2.73-2.28-1.19-1.81,1.4-2.42,2.65Z"/>
      <path className="cls-65" d="m179.83,2178.17c-4.74-3.74-12.22-6.01-16.33-1.58.34.43-4.14,3.32-3.31,3.71,1.38.66,8.2-1.25,8.43-1.14,4.97-2.19,9.31-1.01,11.22-.99Z"/>
      <path className="cls-65" d="m182.37,2185.47c-6.68,2.96-19.38-3.67-18.94-5.21.44-1.55,7.33-3.86,12.04-2.51,4.71,1.35,6.8,7.05,6.9,7.72Z"/>
      <path className="cls-53" d="m161.33,2191.36c.39,1.46,0,2.84-.87,3.07-.87.23-1.89-.76-2.28-2.23-.39-1.46,0-2.84.87-3.07.87-.23,1.89.76,2.28,2.23Z"/>
      <path className="cls-92" d="m170.08,2253.05c0,5.02,2.24,9.08-7.96,9.08-9.51,0-7.96-4.07-7.96-9.08s3.4-11.97,7.8-11.97,8.12,6.95,8.12,11.97Z"/>
      <polygon className="cls-74" points="157.35 2256.03 157.35 2259.18 167.14 2259.59 167.14 2255.76 157.35 2256.03"/>
      <path className="cls-92" d="m159.17,2241.98s-1.15-2.29-3.98.44c-2.83,2.73-6.22,13.71-.6,16.34,0,0-1.46-2.63-1.59-6.21-.14-3.88,2.15-9.95,5.54-9.69l.63-.89Z"/>
      <path className="cls-74" d="m169.59,2252.07s-2.87-10.27-7.13-10.04c-4.27.23-6.7,4.28-7.3,10.65l.61.08s.14-9.5,6.61-10.17c3.83-.4,6.26,9.65,6.26,9.65l.95-.17Z"/>
      <polygon className="cls-92" points="160.11 2242 160.11 2240.26 161.03 2239.67 163.02 2239.67 163.81 2240.39 163.81 2242.18 163.02 2242.01 163.02 2240.5 160.85 2240.59 160.77 2241.9 160.11 2242"/>
    </g>
    <text transform="translate(232.89 2120.42)"><tspan className="cls-4"><tspan className="number" x="0" y="0">198</tspan></tspan><tspan className="cls-115"><tspan x="6.9" y="16">onderwijs-</tspan></tspan><tspan className="cls-115"><tspan x="-22.72" y="32">professionals kregen </tspan></tspan><tspan className="cls-115"><tspan className="cls-122" x="-12.97" y="48">P</tspan><tspan className="cls-129" x="-6.01" y="48">A</tspan><tspan x="1.26" y="48">TS-nieuwsbrief</tspan></tspan></text>
    <g id="Titel-6" data-name="Titel">
      <rect id="Achtergrond-7" data-name="Achtergrond" className="cls-57" x="205.8" y="2032.07" width="104.08" height="33" rx="2.5" ry="2.5"/>
      <text className="cls-56" transform="translate(217.69 2056.41)"><tspan x="0" y="0">Scholen</tspan></text>
    </g>
  </g>
    </>
  )  
}