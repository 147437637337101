import React from "react";

// This button bellongs to the sections: coordinates of headers and clickHandler() to show slidere 
export default function PulseButton(props) {
  if (props.isDesctop) {
    return (
      <>
        <g className="pulse-with-lines" id="btn-kinderen" onClick={() => props.clickHandlerKinderen()} >
            <path className="cls-45" d="m805.09,595.56c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
            <path className="cls-38" d="m819.82,597.72h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
            <path className="cls-45 pulse stage1" fill="transparent" d="m821.99,576.74c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
            <path className="cls-45 pulse stage2" fill="transparent" d="m821.99,573.51c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        </g>
     
        <g className="pulse-with-lines" id="btn-ouders" onClick={() => props.clickHandlerOuders()} >
            <path className="cls-45" d="m405.83,1278.83c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
            <path className="cls-38" d="m420.55,1280.99h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
            <path className="pulse stage1 cls-45" fill="transparent" d="m422.73,1260.01c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
            <path className="pulse stage2 cls-45" fill="transparent" d="m422.73,1256.78c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        </g>

        <g className="pulse-with-lines" id="btn-scholen" onClick={() => props.clickHandlerScholen()}>
            <path className="cls-45" d="m484.1,1941.4c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
            <path className="cls-38" d="m498.82,1943.56h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
               
            <path className="pulse stage1 cls-45" fill="transparent" d="m501,1922.58c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
            <path className="pulse stage2 cls-45" fill="transparent" d="m501,1919.36c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        
        </g>
  
        <g className="pulse-with-lines" id="btn-zorg" onClick={() => props.clickHandlerZorg()}>
            <path className="cls-45" d="m585.16,2324.85c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
            <path className="cls-38" d="m599.89,2327.01h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>   
            <path className="pulse stage1 cls-45" fill="transparent" d="m602.06,2306.03c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
            <path className="pulse stage2 cls-45" fill="transparent" d="m602.06,2302.8c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z"  stroke="#EC008C"/>
        </g>
 
        <g className="pulse-with-lines" id="btn-lj" onClick={() => props.clickHandlerLj()} >
            <path className="cls-45" d="m631.93,3390c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
            <path className="cls-38" d="m646.65,3392.16h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
            <path className="pulse stage1 cls-45" fill="transparent" d="m648.83,3371.18c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C" />
            <path className="pulse stage2 cls-45" fill="transparent" d="m648.83,3367.95c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C" />
        </g>
  
      
        <g className="pulse-with-lines" id="btn-gemeenten" onClick={() => props.clickHandlerGemeenten()}>
            <path className="cls-45" d="m649.41,2923.59c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
            <path className="cls-38" d="m664.14,2925.75h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
            <path className="pulse stage1 cls-45" fill="transparent" d="m666.31,2904.77c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
            <path className="pulse stage2 cls-45" fill="transparent" d="m666.31,2901.54c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        </g>
      
        <g className="pulse-with-lines" id="btn-cjg" onClick={() => props.clickHandlerCjg()} >
          <path className="cls-45" d="m666.67,3861.01c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
          <path className="cls-38" d="m681.4,3863.17h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
          <path className="pulse stage1 cls-45" fill="transparent" d="m683.57,3842.19c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C" />
          <path className="pulse stage2 cls-45" fill="transparent" d="m683.57,3838.96c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C" />
        </g>
        
        <g className="pulse-with-lines" id="btn-merkcampagne" onClick={() => props.clickHandlerMerkcampagne()} >
          <path className="cls-45" d="m883.72,4478.5c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
          <path className="cls-38" d="m898.44,4480.66h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
          <path className="pulse stage1 cls-45" fill="transparent" d="m900.62,4459.68c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
          <path className="pulse stage2 cls-45" fill="transparent" d="m900.62,4456.45c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        </g>
      </>
    )
  }
  return (
    <>
       <g className="pulse-with-lines" id="btn-kinderen" onClick={() => props.clickHandlerKinderen()} >
          <path className="cls-54" d="m298.45,830c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
          <path className="cls-57" d="m313.17,832.15h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
          <path className="cls-54 pulse stage1" fill="transparent" d="m315.35,811.17c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
          <path className="cls-54 pulse stage2" fill="transparent" d="m315.35,807.95c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        </g>
     
        <g className="pulse-with-lines" id="btn-ouders" onClick={() => props.clickHandlerOuders()} >
 
          <path className="cls-54" d="m124.35,1653.13c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
          <path className="cls-57" d="m139.08,1655.28h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
    
          <path className="cls-54 pulse stage1" fill="transparent" d="m141.25,1634.31c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>

          <path className="cls-54 pulse stage2" fill="transparent" d="m141.25,1631.08c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
  
        </g>

        <g className="pulse-with-lines" id="btn-scholen" onClick={() => props.clickHandlerScholen()}>
   
          <path className="cls-54" d="m296.89,2068.73c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
          <path className="cls-57" d="m311.62,2070.89h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
      
          <path className="cls-54 pulse stage1" fill="transparent" d="m313.79,2049.91c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
      
          <path className="cls-54 pulse stage2" fill="transparent" d="m313.79,2046.68c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>

        </g>
  
        <g className="pulse-with-lines" id="btn-zorg" onClick={() => props.clickHandlerZorg()}>
          <path className="cls-54" d="m142.78,2404.81c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
          <path className="cls-57" d="m157.5,2406.96h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
          <path className="pulse stage1 cls-54" fill="transparent" d="m159.68,2385.99c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
          <path className="pulse stage2 cls-54" fill="transparent" d="m159.68,2382.76c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        </g>
 
        <g className="pulse-with-lines" id="btn-lj" onClick={() => props.clickHandlerLj()} >
          <path className="cls-54" d="m268.8,3300.8c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
          <path className="cls-57" d="m283.52,3302.95h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
          <path className="pulse stage1 cls-54" fill="transparent" d="m285.7,3281.98c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
          <path className="pulse stage2 cls-54" fill="transparent" d="m285.7,3278.75c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        </g>
  

        <g className="pulse-with-lines" id="btn-gemeenten" onClick={() => props.clickHandlerGemeenten()}>
          <path className="cls-54" d="m298.45,2855.74c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
          <path className="cls-57" d="m313.17,2857.9h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
          <path className="cls-54 pulse stage1" fill="transparent" d="m315.35,2836.92c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
          <path className="cls-54 pulse stage2" fill="transparent" d="m315.35,2833.7c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        </g>
      
        <g className="pulse-with-lines" id="btn-cjg" onClick={() => props.clickHandlerCjg()} >
          <path className="cls-54" d="m281.11,3680.55c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
          <path className="cls-57" d="m295.83,3682.71h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
          <path className="pulse stage1 cls-54" fill="transparent" d="m298.01,3661.73c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
          <path className="pulse stage2 cls-54" fill="transparent" d="m298.01,3658.5c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        </g>
        
        <g className="pulse-with-lines" id="btn-merkcampagne" onClick={() => props.clickHandlerMerkcampagne()} >
          <path  className="cls-54" d="m293.62,4154.05c0-9.33,7.57-16.9,16.9-16.9s16.9,7.57,16.9,16.9-7.57,16.9-16.9,16.9-16.9-7.57-16.9-16.9Z"/>
          <path  className="cls-57" d="m308.34,4156.2h-6.89v-4.31h6.89v-6.93h4.36v6.93h6.88v4.31h-6.88v6.93h-4.36v-6.93Z"/>
          <path  className="pulse stage1 cls-54" fill="transparent" d="m310.52,4135.23c10.38,0,18.82,8.44,18.82,18.82s-8.44,18.82-18.82,18.82-18.82-8.44-18.82-18.82,8.44-18.82,18.82-18.82m0-1c-10.95,0-19.82,8.87-19.82,19.82s8.87,19.82,19.82,19.82,19.82-8.87,19.82-19.82-8.87-19.82-19.82-19.82h0Z" stroke="#EC008C"/>
          <path  className="pulse stage2 cls-54" fill="transparent" d="m310.52,4132c12.16,0,22.05,9.89,22.05,22.05s-9.89,22.05-22.05,22.05-22.05-9.89-22.05-22.05,9.89-22.05,22.05-22.05m0-1.17c-12.82,0-23.22,10.4-23.22,23.22s10.4,23.22,23.22,23.22,23.22-10.4,23.22-23.22-10.4-23.22-23.22-23.22h0Z" stroke="#EC008C"/>
        </g>
    </>
    )
    
}

// Color converter: https://www.w3schools.com/colors/colors_converter.asp



