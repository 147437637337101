import React from "react";

// Component
import Drawing from './components/Drawing';

const App = () => {
  return (
   <>
      <div className="app" id="app">
        <div className="navigation">
        </div>
        <div className="app__content">
          <div className="top-bar"></div>
          <Drawing />
          <div className="footer-bar"></div>
        </div>
      </div>
    </>
  );
}

export default App;
