import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

// gsap.registerPlugin(ScrollTrigger);

export default function Kinderen(props) {
    const isDesctop = props.isDesctop;
    const desctopScreen = useRef();
    const mobileScreen = useRef();

    useLayoutEffect(() => {
      const ctx = gsap.context((self) => {
        gsap.timeline({repeat:-1})
        .to('#Huggy_Wuggy',
          { duration: 1,  transformOrigin: '50% 50%',  rotation:"+=10", ease:'none'}        
        )
        .to('#Huggy_Wuggy',
          { duration: 1,  transformOrigin: '50% 50%',  rotation:"-=10", ease:'none'}      
        )
        const numbers = self.selector('.number');
        numbers.forEach((number) => {
          gsap.from(number, {
            textContent: 0,
            duration: 2,
            snap: { textContent: 1 },
            scrollTrigger: {
              trigger: number,
            },
          });
        });
      }, desctopScreen);
      return () => ctx.revert(); // <- Cleanup!     
    }, [])

    useLayoutEffect(() => {
      const ctxMobile = gsap.context((self) => {
        gsap.timeline({repeat:-1})
        .to('#Huggy_Wuggy',
          { duration: 1,  transformOrigin: '50% 50%',  rotation:"+=10", ease:'none'}        
        )
        .to('#Huggy_Wuggy',
          { duration: 1,  transformOrigin: '50% 50%',  rotation:"-=10", ease:'none'}      
        )
        const numbers = self.selector('.number');
        numbers.forEach((number) => {
          gsap.from(number, {
            textContent: 0,
            duration: 2,
            snap: { textContent: 1 },
            scrollTrigger: {
              trigger: number,
            },
          });
        });
      }, mobileScreen);
      return () => ctxMobile.revert(); // <- Cleanup!     
    }, [])

    if (isDesctop) {
        return (
            <>
                <g ref={desctopScreen}  id="Kinderen">
                    <path id="Ondergrond-7" data-name="Ondergrond" className="cls-105" d="m823.69,868.54h-38.14c12.34,0,22.34-10,22.34-22.34s-10-22.34-22.34-22.34l-155.74-1.51c-15.73-3.23-27.55-17.15-27.55-33.84,0-19.08,15.47-34.55,34.55-34.55h60.48c12.34,0,22.34-10,22.34-22.34s-10-22.34-22.34-22.34h-338.85c-12.34,0-22.34,10-22.34,22.34,0,11.54,8.75,21.04,19.98,22.21h-85.06v.12c-19.08,0-34.55,15.47-34.55,34.55,0,16.34,11.35,30.02,26.59,33.61h-133.18c-25.43,0-46.04,20.61-46.04,46.04s20.61,46.04,46.04,46.04h26.65c25.43,0,46.04,20.61,46.04,46.04s-20.61,46.04-46.04,46.04l-25.39-.02c-16.44,0-29.77,13.33-29.77,29.77s13.33,29.77,29.77,29.77h404.95c16.44,0,29.77-13.33,29.77-29.77s-13.33-29.77-29.77-29.77l216.29.02h71.15c.05,0,.1,0,.16,0,38.04,0,68.89-30.84,68.89-68.89s-30.84-68.89-68.89-68.89Z"/>
                    <text transform="translate(291.77 947.51)">
                      <tspan className="cls-24">
                      <tspan className="number" x="0" y="0">19</tspan>
                      <tspan>.</tspan>
                      <tspan>000</tspan>
                      </tspan>
                      <tspan className="cls-33"><tspan x="-14.68" y="18">nieuwsbrieven </tspan></tspan><tspan className="cls-33"><tspan x="-8.66" y="36">Peuterweken</tspan></tspan></text>
                    <g id="Baby_met_kinderwagen" data-name="Baby met kinderwagen">
                    <g>
                        <path className="cls-93" d="m127.18,941.14l2.74,27.44c0,7.58,6.14,13.72,13.72,13.72h109.75c7.58,0,13.72-6.14,13.72-13.72l2.74-27.44H127.18Z"/>
                        <path className="cls-60" d="m143.64,982.29h109.75c7.58,0,13.72-6.14,13.72-13.72h-137.19c0,7.58,6.14,13.72,13.72,13.72Z"/>
                        <rect className="cls-60" x="124.44" y="934.28" width="148.16" height="6.86" rx="2.74" ry="2.74"/>
                        <path className="cls-60" d="m187.82,861.84c-36.77,6.31-60.64,34.48-60.64,72.44h60.64v-72.44Z"/>
                        <g>
                        <path className="cls-93" d="m187.82,931.39v-69.55c-11.75,2.02-22.18,6.27-30.93,12.35l30.93,57.2Z"/>
                        <path className="cls-93" d="m133.25,903.28c-3.95,9.25-6.07,19.69-6.07,31h57.96l-51.9-31Z"/>
                        <path className="cls-93" d="m184.24,930.54l-29.59-54.72c-8.68,6.56-15.56,15.02-20.26,24.94l49.86,29.78Z"/>
                        </g>
                        <polygon className="cls-60" points="187.82 861.84 187.82 934.28 202.64 861.84 187.82 861.84"/>
                        <path className="cls-101" d="m202.39,957.6l46.57-46.56-3.88-3.88-114.06,114.1v5.49h134.99v-5.5l-63.63-63.65Zm-3.87,3.88l18.62,18.62h-37.24l18.62-18.62Zm24.11,24.11l20.03,20.03h-88.27l20.03-20.03h48.21Zm-83.88,35.67l10.15-10.15h99.25l10.14,10.14h-119.54Z"/>
                        <circle className="cls-65" cx="198.52" cy="957.6" r="6.86"/>
                        <g>
                        <polygon className="cls-101" points="154.89 1021.25 139.66 1021.25 150.43 1010.48 148.1 1008.15 137.33 1018.93 137.33 1003.69 134.04 1003.69 134.04 1018.93 123.27 1008.15 120.94 1010.48 131.71 1021.25 116.48 1021.25 116.48 1024.55 131.71 1024.55 120.94 1035.32 123.27 1037.65 134.04 1026.87 134.04 1042.11 137.33 1042.11 137.33 1026.87 148.1 1037.65 150.43 1035.32 139.66 1024.55 154.89 1024.55 154.89 1021.25"/>
                        <circle className="cls-65" cx="135.69" cy="1022.9" r="6.86"/>
                        <path className="cls-65" d="m135.69,1002.32c-11.37,0-20.58,9.21-20.58,20.58s9.21,20.58,20.58,20.58,20.58-9.21,20.58-20.58-9.21-20.58-20.58-20.58Zm0,34.3c-7.58,0-13.72-6.14-13.72-13.72s6.14-13.72,13.72-13.72,13.72,6.14,13.72,13.72-6.14,13.72-13.72,13.72Z"/>
                        <circle className="cls-101" cx="135.6" cy="1022.9" r="2.74"/>
                        <path className="cls-101" d="m152.01,1022.85c0-9.09-7.37-16.46-16.46-16.46s-16.46,7.37-16.46,16.46,7.37,16.46,16.46,16.46,16.46-7.37,16.46-16.46Zm-16.37,13.81c-7.58,0-13.72-6.14-13.72-13.72s6.14-13.72,13.72-13.72,13.72,6.14,13.72,13.72-6.14,13.72-13.72,13.72Z"/>
                        </g>
                        <g>
                        <polygon className="cls-101" points="280.56 1021.25 265.33 1021.25 276.1 1010.48 273.77 1008.15 263 1018.93 263 1003.69 259.71 1003.69 259.71 1018.93 248.94 1008.15 246.61 1010.48 257.38 1021.25 242.15 1021.25 242.15 1024.55 257.38 1024.55 246.61 1035.32 248.94 1037.65 259.71 1026.87 259.71 1042.11 263 1042.11 263 1026.87 273.77 1037.65 276.1 1035.32 265.33 1024.55 280.56 1024.55 280.56 1021.25"/>
                        <circle className="cls-65" cx="261.35" cy="1022.9" r="6.86"/>
                        <path className="cls-65" d="m261.35,1002.32c-11.37,0-20.58,9.21-20.58,20.58s9.21,20.58,20.58,20.58,20.58-9.21,20.58-20.58-9.21-20.58-20.58-20.58Zm0,34.3c-7.58,0-13.72-6.14-13.72-13.72s6.14-13.72,13.72-13.72,13.72,6.14,13.72,13.72-6.14,13.72-13.72,13.72Z"/>
                        <circle className="cls-101" cx="261.26" cy="1022.9" r="2.74"/>
                        <path className="cls-101" d="m277.75,1022.88c0-9.09-7.37-16.46-16.46-16.46s-16.46,7.37-16.46,16.46,7.37,16.46,16.46,16.46,16.46-7.37,16.46-16.46Zm-16.37,13.81c-7.58,0-13.72-6.14-13.72-13.72s6.14-13.72,13.72-13.72,13.72,6.14,13.72,13.72-6.14,13.72-13.72,13.72Z"/>
                        </g>
                        <polygon className="cls-101" points="277.32 906.07 246.29 912.6 245.17 907.23 276.2 900.7 277.32 906.07"/>
                        <circle className="cls-65" cx="245.07" cy="911.05" r="5.49"/>
                        <circle className="cls-101" cx="198.38" cy="957.55" r="3.43"/>
                        <circle className="cls-101" cx="245.07" cy="911.05" r="2.74"/>
                    </g>
                    <g>
                        <g>
                        <g>
                            <path className="cls-97" d="m208.21,897.18l-5.12,8.92s-2.47,5.03-3.23,8.2c-.93,3.85-.7,5.48-.7,5.48,0,0-4.3,1.91-6.51,3.83-1.72,1.49-2.89,2.6-3.54,3.23l1.38,7.84c6.53-.1,17.65-.45,23.86-.52h1.25v-35.27l-7.39-1.69Z"/>
                            <path className="cls-97" d="m238.56,923.61c-2.21-1.92-6.51-3.83-6.51-3.83,0,0,.23-1.62-.7-5.48-.77-3.17-2.61-5.49-2.61-5.49l-4.43-10-9.19.06v35.27h1.73c5.54.06,14.97.35,21.58.48l3.23-8.22c-.68-.66-1.72-1.61-3.1-2.81Z"/>
                            <path className="cls-97" d="m208.95,897.43l-.76,8.42s1.6,1.67,6.59,1.52c4.47-.13,5.61-1.44,5.61-1.44v-8.18h3.53v8.5s3.3,4.07,4.7,7.13c1.25,2.72,1.8,3.94,1.8,3.94,0,0,3.21,1.6,5.77,3.69,2.53,2.05,5.23,5.01,5.23,5.01,0,0-1.96,1.65-2.89,3.8-.94,2.15-.91,4.96-.91,4.96,0,0-5.78.41-9.75.19-3.97-.22-13.44,0-16.3-.22-2.86-.22-9.77.16-13.57.22-3.58.05-6.99-.22-6.99-.22,0,0,.9-2.18.27-4.76-.66-2.73-1.82-3.72-1.82-3.72,0,0,1.2-2.55,3.76-4.62,3.32-2.68,6.25-3.85,6.25-3.85-.08,0,1.04-1.4,2.11-4.5,1.57-4.54,3.3-7.35,3.3-7.35l1.01-9.66,3.06,1.16Z"/>
                            <path className="cls-90" d="m227.92,898.72s4.65-.16,5.13,6.09c.34,4.48-.14,10.35-1.76,15.55-2.4,7.7-5.69,10.18-5.69,10.18,0,0-.24-2.17-2.48-2.48-2.25-.32-4.01,0-4.01,0,0,0,0-8.82,1.44-15.23,1.44-6.41,2.57-12.19,3.37-12.67.8-.48,4.01-1.44,4.01-1.44Z"/>
                            <path className="cls-90" d="m200.68,898.72s-4.65-.16-5.13,6.09c-.34,4.48.14,10.35,1.76,15.55,2.4,7.7,5.69,10.18,5.69,10.18,0,0,.24-2.17,2.48-2.48,2.24-.32,4.01,0,4.01,0,0,0,0-8.82-1.44-15.23-1.44-6.41-2.56-12.19-3.37-12.67-.8-.48-4.01-1.44-4.01-1.44Z"/>
                        </g>
                        <g>
                            <path className="cls-72" d="m208.74,938.61c-.79.14-1.68-.11-2.35-.6-3.12-2.27-4.99-6.97-2.2-10.23.09-.11.2-.22.33-.25.11-.03.22,0,.33,0,1.11.2,2.23.4,3.34.6.43.08.86.15,1.25.35,2.36,1.15,2.14,8.2.26,9.71-.29.23-.62.37-.97.43Z"/>
                            <path className="cls-72" d="m223.76,938.46c-.79.14-1.68-.12-2.35-.6-3.12-2.27-4.99-6.97-2.19-10.23.09-.11.2-.22.33-.25.11-.03.22,0,.33.01,1.11.2,2.23.4,3.34.6.43.08.86.15,1.25.35,2.37,1.15,2.14,8.2.26,9.71-.29.23-.62.37-.97.43Z"/>
                            <path className="cls-72" d="m194.88,942.21c-1.19.21-2.52-.17-3.52-.9-4.67-3.4-7.46-10.43-3.29-15.32.14-.16.29-.33.5-.38.16-.04.33-.01.49.02,1.67.3,3.33.6,5,.9.64.11,1.29.23,1.88.52,3.54,1.73,3.2,12.28.39,14.53-.43.34-.93.55-1.46.64Z"/>
                            <path className="cls-72" d="m235.78,941.23c-1.06-.57-1.88-1.69-2.22-2.88-1.58-5.55.55-12.81,6.86-14.09.21-.04.43-.08.63,0,.15.07.27.19.37.31,1.13,1.26,2.26,2.52,3.39,3.79.43.49.87.98,1.16,1.57,1.72,3.54-5.05,11.64-8.65,11.68-.55,0-1.07-.14-1.54-.39Z"/>
                        </g>
                        </g>
                        <g>
                        <path className="cls-72" d="m250.56,872.77c-.73-.2-1.46-.18-2.15-.03.21-1.88.27-3.79.13-5.74-1.27-18.25-17.92-31.95-37.19-30.61-19.27,1.34-33.86,17.22-32.59,35.47.19,2.78.75,5.44,1.61,7.97-2.03-.06-3.92,1.26-4.48,3.31-.65,2.4.77,4.88,3.17,5.53,1.85.5,3.73-.23,4.8-1.68,6.58,10.08,18.7,16.41,32.09,15.48,14.25-.99,25.94-9.94,30.55-21.92.49.41,1.06.73,1.71.91,2.4.65,4.88-.77,5.53-3.17.65-2.4-.77-4.88-3.17-5.53Z"/>
                        <g>
                            <g>
                            <path className="cls-23" d="m203.3,887.5c-.13-1.24-1.24-2.15-2.48-2.02-1.24.13-2.14,1.24-2.01,2.48.13,1.24,1.24,2.14,2.48,2.01,1.24-.13,2.15-1.24,2.02-2.48Z"/>
                            <path className="cls-23" d="m232.08,884.5c-.13-1.24-1.24-2.15-2.48-2.02-1.24.13-2.14,1.24-2.02,2.49.13,1.24,1.24,2.14,2.48,2.01,1.24-.13,2.14-1.24,2.01-2.48Z"/>
                            </g>
                            <path className="cls-22" d="m215.81,896.09c-3.32,0-5.17-2.7-5.25-2.82l.55-.37s1.96,2.85,5.28,2.5c3.32-.35,4.45-3.47,4.46-3.5l.62.22c-.05.14-1.28,3.55-5.01,3.94-.22.02-.44.03-.65.03h0Z"/>
                            <path className="cls-23" d="m213.58,854c-2.24,0-4.19-1.06-5.29-2.89-.39-.65-.63-1.35-.72-2.03-1.48-1-2.45-2.78-1.89-4.98.6-2.35,2.11-3.15,2.17-3.18l.45.89s-1.18.65-1.66,2.54c-.37,1.43.1,2.64.94,3.46.09-.53.27-1.05.57-1.5,1.21-1.88,3.41-2.37,4.75-1.74.81.38,1.51,1.33,1.03,2.82-.77,2.4-3,2.93-4.86,2.39-.12-.03-.24-.07-.36-.12.1.32.24.63.43.93.99,1.66,2.84,2.56,4.93,2.39,3.93-.31,5.41-3.66,5.47-3.81l.91.39c-.07.17-1.77,4.05-6.31,4.41-.19.01-.38.02-.57.02h0Zm-5.06-5.51c.26.14.54.25.82.33,1.3.37,3.04.14,3.64-1.74.17-.54.23-1.26-.51-1.61-.95-.45-2.61,0-3.49,1.38-.31.48-.46,1.05-.46,1.65h0Z"/>
                        </g>
                        <path className="cls-55" d="m194.59,893.02c-1.17.7-1.76,1.98-1.64,3.26,1.51,1.04,3.11,1.97,4.78,2.77.12-.05.23-.11.34-.18,1.61-.96,2.14-3.05,1.18-4.67-.96-1.61-3.05-2.14-4.67-1.18Z"/>
                        <path className="cls-55" d="m238.75,890.09c-.96-1.62-3.05-2.14-4.67-1.18-1.62.96-2.14,3.05-1.18,4.67.56.94,1.5,1.51,2.51,1.63,1.36-1.03,2.63-2.16,3.81-3.37,0-.59-.15-1.2-.48-1.75Z"/>
                        </g>
                    </g>
                    </g>
                    <g id="Beer-2" data-name="Beer">
                    <g>
                        <ellipse className="cls-56" cx="290.39" cy="1045.91" rx="18.11" ry="6.17" transform="translate(-616.92 450.05) rotate(-41.19)"/>
                        <ellipse className="cls-56" cx="339.61" cy="1042.71" rx="6.17" ry="18.11" transform="translate(-683.31 647.48) rotate(-50.83)"/>
                        <path className="cls-56" d="m299.52,1037.05s-5.73,21.76-6.19,29.22,3.41,14.89,17.49,15.04c14.08.15,32.97-1.55,33.12-7.12s-7.58-33.59-10.06-37.77c-2.48-4.18-5.57-9.07-5.57-9.07"/>
                        <ellipse className="cls-56" cx="295.1" cy="1072.29" rx="11.31" ry="15.03" transform="translate(-814.84 1002.06) rotate(-70.99)"/>
                        <g>
                        <ellipse className="cls-56" cx="312.67" cy="1015.81" rx="22.98" ry="21.24"/>
                        <ellipse className="cls-38" cx="313.54" cy="1026.4" rx="12.23" ry="10.44" transform="translate(-82.73 29.02) rotate(-4.68)"/>
                        <ellipse className="cls-53" cx="313.36" cy="1024.25" rx="5.85" ry="3.96" transform="translate(-82.56 28.99) rotate(-4.68)"/>
                        <circle className="cls-23" cx="302.15" cy="1014.84" r="1.63"/>
                        <circle className="cls-23" cx="317.78" cy="1013.21" r="1.63"/>
                        <ellipse className="cls-56" cx="291.16" cy="1005.91" rx="8.36" ry="6.47"/>
                        <ellipse className="cls-56" cx="331.25" cy="1002.8" rx="8.36" ry="6.47"/>
                        </g>
                        <ellipse className="cls-56" cx="337.4" cy="1071.02" rx="11.01" ry="12.53" transform="translate(-293.04 141.79) rotate(-16.67)"/>
                        <ellipse className="cls-71" cx="337.87" cy="1072.19" rx="7.41" ry="8.47" transform="translate(-293.36 141.98) rotate(-16.67)"/>
                        <ellipse className="cls-71" cx="293.29" cy="1072.19" rx="7.41" ry="8.47" transform="translate(-770.69 750.41) rotate(-57.87)"/>
                    </g>
                    </g>
                    <g id="Rolstoeler">
                    <path className="cls-65" d="m836.57,958.09c6.07,0,11.96-1.19,17.5-3.53,5.36-2.27,10.16-5.51,14.29-9.64,4.13-4.13,7.37-8.94,9.64-14.29,2.35-5.55,3.53-11.43,3.53-17.5s-1.19-11.96-3.53-17.5c-2.27-5.36-5.51-10.16-9.64-14.29-4.13-4.13-8.94-7.37-14.29-9.64-5.55-2.35-11.43-3.53-17.5-3.53s-11.96,1.19-17.5,3.53c-5.36,2.27-10.16,5.51-14.29,9.64-4.13,4.13-7.37,8.94-9.64,14.29-2.35,5.55-3.53,11.44-3.53,17.5s1.19,11.96,3.53,17.5c2.27,5.36,5.51,10.16,9.64,14.29,4.13,4.13,8.94,7.37,14.29,9.64,5.55,2.35,11.43,3.53,17.5,3.53Zm0-84.55c21.82,0,39.58,17.75,39.58,39.58s-17.76,39.58-39.58,39.58-39.58-17.75-39.58-39.58,17.76-39.58,39.58-39.58Z"/>
                    <polygon className="cls-101" points="723.15 931.37 758.22 827.54 756.16 826.91 721.1 930.74 723.15 931.37"/>
                    <g>
                        <g>
                        <rect className="cls-101" x="790.48" y="869.32" width=".4" height="82.11"/>
                        <rect className="cls-101" x="749.42" y="911.25" width="82.11" height=".4"/>
                        <rect className="cls-101" x="751.05" y="910.17" width="82.3" height=".4" transform="translate(-414.33 885.02) rotate(-47.77)"/>
                        <rect className="cls-101" x="790.27" y="869.57" width=".4" height="82.04" transform="translate(-415.28 897.53) rotate(-48.42)"/>
                        <rect className="cls-101" x="749.37" y="910.6" width="83.06" height=".4" transform="translate(-345.4 1315.67) rotate(-68.64)"/>
                        <rect className="cls-101" x="750.2" y="911.47" width="81.83" height=".4" transform="translate(-302.35 400.46) rotate(-23.99)"/>
                        <rect className="cls-101" x="789.84" y="868.7" width=".4" height="82.05" transform="translate(-298.16 392.44) rotate(-23.6)"/>
                        <rect className="cls-101" x="790.27" y="869.73" width=".4" height="83" transform="translate(-339.32 1334.05) rotate(-69.58)"/>
                        </g>
                        <rect className="cls-101" x="749.42" y="911.25" width="82.11" height=".4"/>
                        <rect className="cls-101" x="750.2" y="911.47" width="81.83" height=".4" transform="translate(-302.35 400.46) rotate(-23.99)"/>
                    </g>
                    <path className="cls-65" d="m790.89,952.61c11.55,0,22.41-4.5,30.58-12.66,8.17-8.17,12.66-19.03,12.66-30.58s-4.5-22.41-12.66-30.58c-8.17-8.17-19.03-12.66-30.58-12.66s-22.41,4.5-30.58,12.66c-8.17,8.17-12.66,19.03-12.66,30.58s4.5,22.41,12.66,30.58c8.17,8.17,19.03,12.66,30.58,12.66Zm0-81.09c20.87,0,37.85,16.98,37.85,37.85s-16.98,37.85-37.85,37.85-37.85-16.98-37.85-37.85,16.98-37.85,37.85-37.85Z"/>
                    <path className="cls-65" d="m790.89,954.58c6.07,0,11.96-1.19,17.5-3.53,5.36-2.27,10.16-5.51,14.29-9.64,4.13-4.13,7.37-8.94,9.64-14.29,2.35-5.55,3.53-11.43,3.53-17.5s-1.19-11.96-3.53-17.5c-2.27-5.36-5.51-10.16-9.64-14.29-4.13-4.13-8.94-7.37-14.29-9.64-5.55-2.35-11.43-3.53-17.5-3.53s-11.96,1.19-17.5,3.53c-5.36,2.27-10.16,5.51-14.29,9.64-4.13,4.13-7.37,8.94-9.64,14.29-2.35,5.55-3.53,11.44-3.53,17.5s1.19,11.96,3.53,17.5c2.27,5.36,5.51,10.16,9.64,14.29,4.13,4.13,8.94,7.37,14.29,9.64,5.55,2.35,11.43,3.53,17.5,3.53Zm0-84.55c21.82,0,39.58,17.75,39.58,39.58s-17.76,39.58-39.58,39.58-39.58-17.75-39.58-39.58,17.76-39.58,39.58-39.58Z"/>
                    <path className="cls-101" d="m790.89,952.61c11.55,0,22.41-4.5,30.58-12.66,8.17-8.17,12.66-19.03,12.66-30.58s-4.5-22.41-12.66-30.58c-8.17-8.17-19.03-12.66-30.58-12.66s-22.41,4.5-30.58,12.66c-8.17,8.17-12.66,19.03-12.66,30.58s4.5,22.41,12.66,30.58c8.17,8.17,19.03,12.66,30.58,12.66Zm0-81.09c20.87,0,37.85,16.98,37.85,37.85s-16.98,37.85-37.85,37.85-37.85-16.98-37.85-37.85,16.98-37.85,37.85-37.85Z"/>
                    <path className="cls-101" d="m790.89,954.58c6.07,0,11.96-1.19,17.5-3.53,5.36-2.27,10.16-5.51,14.29-9.64,4.13-4.13,7.37-8.94,9.64-14.29,2.35-5.55,3.53-11.43,3.53-17.5s-1.19-11.96-3.53-17.5c-2.27-5.36-5.51-10.16-9.64-14.29-4.13-4.13-8.94-7.37-14.29-9.64-5.55-2.35-11.43-3.53-17.5-3.53s-11.96,1.19-17.5,3.53c-5.36,2.27-10.16,5.51-14.29,9.64-4.13,4.13-7.37,8.94-9.64,14.29-2.35,5.55-3.53,11.44-3.53,17.5s1.19,11.96,3.53,17.5c2.27,5.36,5.51,10.16,9.64,14.29,4.13,4.13,8.94,7.37,14.29,9.64,5.55,2.35,11.43,3.53,17.5,3.53Zm0-84.55c21.82,0,39.58,17.75,39.58,39.58s-17.76,39.58-39.58,39.58-39.58-17.75-39.58-39.58,17.76-39.58,39.58-39.58Z"/>
                    <circle className="cls-101" cx="791.14" cy="911.16" r="7.53"/>
                    <circle className="cls-101" cx="791.14" cy="911.16" r="3.46"/>
                    <polygon className="cls-65" points="710.72 928.71 772.14 941.07 779.28 936.47 717.86 924.1 710.72 928.71"/>
                    <path className="cls-23" d="m779.63,870.25h73.7c1.86,0,3.37-1.51,3.37-3.37h0c0-1.86-1.51-3.37-3.37-3.37h-73.7c-1.86,0-3.37,1.51-3.37,3.37h0c0,1.86,1.51,3.37,3.37,3.37Z"/>
                    <path className="cls-23" d="m791.08,866.21c21.55-.46,43.09-.91,64.64-1.37l19.76-69.18c3.13-10.95-8.5-21.17-25.97-22.83h0c-17.47-1.66-34.17,5.86-37.29,16.81-7.05,25.52-14.09,51.05-21.14,76.57Z"/>
                    <g>
                        <g>
                        <rect className="cls-65" x="836.16" y="872.82" width=".4" height="82.11"/>
                        <rect className="cls-65" x="795.09" y="914.75" width="82.11" height=".4"/>
                        <rect className="cls-65" x="796.72" y="913.67" width="82.3" height=".4" transform="translate(-401.95 919.99) rotate(-47.77)"/>
                        <rect className="cls-65" x="835.94" y="873.07" width=".4" height="82.04" transform="translate(-402.54 932.87) rotate(-48.42)"/>
                        <rect className="cls-65" x="795.05" y="914.1" width="83.06" height=".4" transform="translate(-319.62 1360.43) rotate(-68.64)"/>
                        <rect className="cls-65" x="795.87" y="914.97" width="81.83" height=".4" transform="translate(-299.83 419.34) rotate(-23.99)"/>
                        <rect className="cls-65" x="835.51" y="872.2" width=".4" height="82.05" transform="translate(-295.74 411.02) rotate(-23.6)"/>
                        <rect className="cls-65" x="835.94" y="873.24" width=".4" height="83" transform="translate(-312.87 1379.13) rotate(-69.58)"/>
                        </g>
                        <rect className="cls-65" x="795.09" y="914.75" width="82.11" height=".4"/>
                    </g>
                    <g>
                        <path className="cls-101" d="m836.57,956.11c11.55,0,22.41-4.5,30.58-12.66,8.17-8.17,12.66-19.03,12.66-30.58s-4.5-22.41-12.66-30.58c-8.17-8.17-19.03-12.66-30.58-12.66s-22.41,4.5-30.58,12.66c-8.17,8.17-12.66,19.03-12.66,30.58s4.5,22.41,12.66,30.58c8.17,8.17,19.03,12.66,30.58,12.66Zm0-81.09c20.87,0,37.85,16.98,37.85,37.85s-16.98,37.85-37.85,37.85-37.85-16.98-37.85-37.85,16.98-37.85,37.85-37.85Z"/>
                        <path className="cls-101" d="m836.57,956.11c11.55,0,22.41-4.5,30.58-12.66,8.17-8.17,12.66-19.03,12.66-30.58s-4.5-22.41-12.66-30.58c-8.17-8.17-19.03-12.66-30.58-12.66s-22.41,4.5-30.58,12.66c-8.17,8.17-12.66,19.03-12.66,30.58s4.5,22.41,12.66,30.58c8.17,8.17,19.03,12.66,30.58,12.66Zm0-81.09c20.87,0,37.85,16.98,37.85,37.85s-16.98,37.85-37.85,37.85-37.85-16.98-37.85-37.85,16.98-37.85,37.85-37.85Z"/>
                    </g>
                    <path className="cls-65" d="m836.81,924.88c5.64,0,10.22-4.59,10.22-10.22s-4.59-10.22-10.22-10.22-10.22,4.59-10.22,10.22,4.59,10.22,10.22,10.22Zm0-15.06c2.67,0,4.83,2.17,4.83,4.83s-2.17,4.83-4.83,4.83-4.83-2.17-4.83-4.83,2.17-4.83,4.83-4.83Z"/>
                    <circle className="cls-101" cx="836.81" cy="914.66" r="3.46"/>
                    <path className="cls-134" d="m754.33,888.49c2.47-11.37,4-21.93,4.92-31.41.22-2.27,1.11-4.44,2.64-6.13,1.09-1.21,2.37-2.05,3.74-2.34,2.88-1.87,6.15-3.08,9.59-3.54,19.03-2.55,38.06-5.1,57.1-7.66,1.76-.24,3.52.5,4.58,1.94,2.48,3.37,6.18,9.79,3.08,15.04-.55.94-2.98,4.65-15.94,7.16-12.99,2.52-22.11,1.04-34.89.15-3.67-.25-8.15-.49-13.34-.61-1.11,5.92-2.62,12.29-4.68,18.98-5.51,17.87-13.12,32.18-19.96,42.79l-6.36-1.02c3.45-9.66,6.81-20.82,9.54-33.35Z"/>
                    <g>
                        <polygon className="cls-56" points="750.65 927.35 740.17 923.81 746.27 919.3 752.22 921.31 750.65 927.35"/>
                        <path className="cls-23" d="m749.58,931.85c-.72-.08-1.78-.26-3.01-.68-2.23-.76-2.27-1.4-4.07-2.14-2.87-1.18-4.73-.38-7.57-1.56-.93-.39-1.79-.89-1.79-.89-.35-.18-.75-.42-1.17-.76-.26-.22-.49-.45-.7-.68-.56-.64-.27-1.71.55-1.96.31-.1.63-.18.97-.26,2.08-.46,3.83-.24,4.95.01,1.19-.61,2.89-1.21,4.58-.67,1.12.36,1.65,1.04,2.48,1.69,1.19.93,3.15,1.91,6.45,1.91,1.14,1.36,1.3,3.23.49,4.52-.7,1.12-1.87,1.41-2.17,1.47Z"/>
                    </g>
                    <path className="cls-133" d="m767.81,857.57c-.05-1.41.58-2.66,1.31-3.63.73-1.2,1.68-2.13,2.82-2.63,11.05-6.79,62.16-11.36,71.05-12.13,1.09.86,3.32,2.84,4.43,6.14,2.08,6.12-1.47,11.45-1.91,12.1-2.52,3.65-6.53,5.39-13.24,6.79-15.01,3.14-19.06,1.6-35.37.74-4.97-.26-8.57.2-11.54.59.35,4.57.54,9.41.5,14.51-.15,18.7-3.33,34.59-6.84,46.72l-6.39.85c.53-10.25.55-21.9-.44-34.68-.89-11.6-2.46-22.15-4.31-31.5-.25-1.28-.28-2.59-.08-3.86Z"/>
                    <g>
                        <polygon className="cls-56" points="779.84 931.41 768.79 931.03 773.34 924.96 779.61 925.18 779.84 931.41"/>
                        <path className="cls-23" d="m780.11,936.03c-.71.13-1.78.27-3.08.22-2.35-.09-2.57-.69-4.51-.88-3.08-.3-4.64.99-7.7.68-1-.1-1.97-.34-1.97-.34-.39-.07-.84-.19-1.34-.39-.32-.13-.6-.29-.86-.45-.72-.45-.75-1.56-.04-2.04.27-.18.55-.36.86-.53,1.86-1.04,3.6-1.33,4.75-1.41.97-.93,2.42-1.99,4.19-1.96,1.18.02,1.88.52,2.86.9,1.41.55,3.57.92,6.73-.02,1.48.98,2.17,2.72,1.77,4.19-.35,1.27-1.39,1.89-1.65,2.03Z"/>
                    </g>
                    <polygon className="cls-101" points="772.03 941.47 808.68 832.93 806.62 832.31 769.98 940.84 772.03 941.47"/>
                    <path className="cls-65" d="m753.67,832.12h47.02c1.97,0,3.57-1.6,3.57-3.57h0c0-1.97-1.6-3.57-3.57-3.57h-47.02c-1.97,0-3.57,1.6-3.57,3.57h0c0,1.97,1.6,3.57,3.57,3.57Z"/>
                    <path className="cls-65" d="m836.57,958.09c6.07,0,11.96-1.19,17.5-3.53,5.36-2.27,10.16-5.51,14.29-9.64,4.13-4.13,7.37-8.94,9.64-14.29,2.35-5.55,3.53-11.43,3.53-17.5s-1.19-11.96-3.53-17.5c-2.27-5.36-5.51-10.16-9.64-14.29-4.13-4.13-8.94-7.37-14.29-9.64-5.55-2.35-11.43-3.53-17.5-3.53s-11.96,1.19-17.5,3.53c-5.36,2.27-10.16,5.51-14.29,9.64-4.13,4.13-7.37,8.94-9.64,14.29-2.35,5.55-3.53,11.44-3.53,17.5s1.19,11.96,3.53,17.5c2.27,5.36,5.51,10.16,9.64,14.29,4.13,4.13,8.94,7.37,14.29,9.64,5.55,2.35,11.43,3.53,17.5,3.53Zm0-84.55c21.82,0,39.58,17.75,39.58,39.58s-17.76,39.58-39.58,39.58-39.58-17.75-39.58-39.58,17.76-39.58,39.58-39.58Z"/>
                    <g>
                        <path className="cls-56" d="m850.23,848.22c-2.28.99-9.94,3.97-19.35,1.46-8.62-2.3-13.6-7.83-15.24-9.84.34-.98,4.89-14.04,5.74-22.57.16-1.63.35-4.3-.13-7.73-.22-1.59-.65-3.36-1.44-5.43-4.34-11.35-7.17-21.86-3.68-25.78,2.1-2.37,10.52-7.89,20.52-5.26,1.35.35,4.29,1.42,5.53,1.58,1.39.34,7.37,2.37,10.26,8.95,1.11,2.53,2.08,5,2.81,7.84.3,1.14.8,3.34,1.14,6.37.87,7.86-.5,20.02-3.53,31.7-.55,2.14-1.47,5.43-2.07,10.14-.47,3.72-.55,6.75-.55,8.58Z"/>
                        <path className="cls-74" d="m759.72,824.67c.47-1.7,2.21-2.52,4.16-3.45.91-.43,2.44-1.14,4.59-1.27.2-.01.4-.02.59-.02.43-.12.86-.1,1.1-.08,5.47.34,21.41,2.83,30.96,1.84,2.4-.25,4.69-.49,7.17-1.59.58-1.69,1.27-4,1.84-6.79.12-.59.31-1.75.61-3.59.06-.37.12-.74.17-1.1-.01-.01-.02-.03-.04-.04.34-2.07.6-4.38.69-6.9.05-1.37.04-2.67,0-3.91,4.35.91,8.7,1.83,13.05,2.74-.07,2.48-.19,5.05-.4,7.72-.03.38-.06.75-.1,1.13,0,.18-.02.36-.03.53,0,.19-.21,2.51-.42,4.06-.41,3.07-1.27,9.57-4.03,13.21-.05.15-.1.3-.17.45-3.5,8.17-26.28,5.21-28.73,4.89-9.69-1.26-17.21-4.39-21.81-6.69,0,.02-.02.04-.03.06-.29.37-2.88,3.61-6.11,3.13-.43-.06-.93-.2-1.48-.48-.27-.15-1.07-.65-1.48-1.65-.42-1.03-.18-1.96-.11-2.2Z"/>
                        <path className="cls-76" d="m788.98,824.56c.47-1.7,2.21-2.52,4.16-3.45.91-.43,2.44-1.14,4.59-1.27.2-.01.4-.02.59-.02.43-.12.86-.1,1.1-.08,5.47.34,21.41,2.83,30.96,1.84,2.4-.25,4.69-.49,7.17-1.59.58-1.69,1.27-4,1.84-6.79.12-.59.31-1.75.61-3.59.06-.37.12-.74.17-1.1-.01-.01-.02-.03-.04-.04.34-2.07.6-4.38.69-6.9.05-1.37.04-2.67,0-3.91,4.35.91,8.7,1.83,13.05,2.74-.07,2.48-.19,5.05-.4,7.72-.03.38-.06.75-.1,1.13,0,.18-.02.36-.03.53,0,.19-.21,2.51-.42,4.06-.41,3.07-1.27,9.57-4.03,13.21-.05.15-.1.3-.17.45-3.5,8.17-26.28,5.21-28.73,4.89-9.69-1.26-17.21-4.39-21.81-6.69l-.03.06c-.29.37-2.88,3.61-6.11,3.13-.43-.06-.93-.2-1.48-.48-.27-.15-1.07-.65-1.48-1.65-.42-1.03-.18-1.96-.11-2.2Z"/>
                        <path className="cls-56" d="m846.6,780.07c6.98,3.55,7.91,14.33,8.12,16.82.06.68.28,3.59-.6,6.97-.58,2.23-1.22,3.08-1.49,3.41-1.38,1.69-3.22,2.05-4.01,2.2-.58.11-3.03.52-5.65-.95-2.46-1.39-3.69-3.66-4.36-4.93-3.84-7.3-4.63-21.34,1.24-24.22,2.74-1.34,5.88.26,6.74.7Z"/>
                        <path className="cls-56" d="m821.71,779.83c6.98,3.55,7.91,14.33,8.12,16.82.06.68.28,3.59-.6,6.97-.58,2.23-1.22,3.08-1.49,3.41-1.38,1.69-3.22,2.05-4.01,2.2-.58.11-3.03.52-5.65-.95-2.46-1.39-3.69-3.66-4.36-4.93-3.84-7.3-4.63-21.34,1.24-24.22,2.74-1.34,5.88.26,6.74.7Z"/>
                    </g>
                    <path className="cls-74" d="m831.75,774.41c0,4.75-12.89,4.75-12.89,0v-12.9h12.89v12.9Z"/>
                    <path className="cls-74" d="m831.21,770.24c-.39.1-.78.19-1.17.27-10.9,2.24-21.2-5.94-21.97-17.04l-1.46-17.92c0-10.67,8.48-18.56,19.15-18.74.39,0,.78,0,1.17,0,10.73.01,19.24,7.71,19.03,18.44-.14,7.19-.7,12.36-1.21,18.58-.64,7.76-6,14.46-13.54,16.42Z"/>
                    <path className="cls-86" d="m838.68,793.59c-2.9-.23-6.97-.46-9.87-.69.3-4.92.86-9.84,1.99-14.64.21-.91.71-1.77.74-2.71.21-5.19.23-5.16.21-5.46,3.48-1.04,6.35-2.9,8.6-5.63,1.09-5.89.8-19.99.17-24.25-1.33-9-5.12-15.16-7.13-14.22-3.87,1.81-7.69,3.54-11.66,5.01-4.77,1.76-8.23,6.18-9.89,10.86-2.19,6.2-2.32,13.65-.6,19.97,1.01,3.72,5.96,6.72,8.14,7.61,0,0,.02,6.26,0,7.91,0,0-4.02,1.71-4.73,3.79-1.4,4.14-.62,9.41-1.35,13.75-2.81-.49-5.66-.73-8.51-.72.5,0-1.49-21.47-1.7-23.4-.8-7.51-1.41-14.94-1.87-22.49-.54-8.91-.21-18.31,5.53-25.65,4.96-6.35,13.05-9.01,20.9-8.79,1.58.04,3.14.46,4.52,1.22,1.2.66,2.33,2.54,3.47,3.15.1.05.19.09.29.13,1.32.44,2.7-.63,4.05-.46,1.75.22,3.57,1.62,4.82,2.77,4.49,4.13,6.21,10.44,7.07,16.48.83,5.82-1.34,43.28-1.34,43.28,0,0-4.09-3.07-7.72.4,0,0-3.83,6.5-4.15,12.8Z"/>
                    <g>
                        <path className="cls-23" d="m835.23,745.33c0-.91-.74-1.64-1.64-1.64s-1.64.74-1.64,1.64.74,1.64,1.64,1.64,1.64-.74,1.64-1.64Z"/>
                        <path className="cls-23" d="m819.19,745.33c0-.91-.74-1.64-1.64-1.64s-1.64.74-1.64,1.64.74,1.64,1.64,1.64,1.64-.74,1.64-1.64Z"/>
                        <path className="cls-9" d="m830.92,758.73c-1.47,2.67-4.83,3.64-7.5,2.17"/>
                    </g>
                    <rect className="cls-23" x="817.6" y="751.34" width="1.02" height="1.02" transform="translate(1636.21 1503.7) rotate(180)"/>
                    <rect className="cls-23" x="814.1" y="751.34" width="1.02" height="1.02" transform="translate(1629.23 1503.7) rotate(180)"/>
                    <rect className="cls-23" x="815.44" y="754.08" width="1.02" height="1.02" transform="translate(1631.91 1509.18) rotate(180)"/>
                    <rect className="cls-23" x="835.74" y="753.94" width="1.02" height="1.02" transform="translate(1672.5 1508.91) rotate(180)"/>
                    <rect className="cls-23" x="833.21" y="751.54" width="1.02" height="1.02" transform="translate(1667.45 1504.1) rotate(180)"/>
                    <path className="cls-65" d="m807.99,837.78h47.02c1.97,0,3.57-1.6,3.57-3.57h0c0-1.97-1.6-3.57-3.57-3.57h-47.02c-1.97,0-3.57,1.6-3.57,3.57h0c0,1.97,1.6,3.57,3.57,3.57Z"/>
                    </g>
                    <g id="Jongetje-3" data-name="Jongetje">
                    <path className="cls-65" d="m657.35,911.03c-1.66,1.74-3.42,3.39-5.4,4.75-1.8,1.24-2.72,1.21-4.63.63-1.85-.57-3.92.09-4.72,1.97-.63,1.48-.18,3.33,1.03,4.39,2.63,2.32,4.76-.17,7.33-.87,2.59-.7,5.16-1.84,7.23-3.58,1.63-1.38,3.35-3.03,4.81-4.87-1.99-.72-3.93-1.41-5.64-2.41Z"/>
                    <path className="cls-70" d="m707.74,866.22c1.67,15.87-3.58,28.36-18.06,33.34-3.99-1.45-34.67-22.43-37.44-26.57-.55-6.59,1.22-11.83,7.13-16.12,7.69-5.57,25.56-4.36,33.81-4.77,7.65-1.16,13.78,6.64,14.57,14.11Z"/>
                    <path className="cls-70" d="m664.13,866.67c.33,1.84-2.51,25.37-5.32,25.54-2.81.17-5.42-6.93-6.24-14.64-.78-7.35-.88-14.11,3.71-16.29,2.5-1.19,7.46,3.19,7.85,5.39Z"/>
                    <path className="cls-23" d="m642.99,945.75c.35-.35.51-.85.54-1.35.1-1.65-1.13-3.15-2.62-3.88-1.48-.73-3.18-.84-4.83-.95-1.94-.12-3.9-.24-5.83,0-1.93.25-3.85.9-5.34,2.15-.15,1.38.76,2.7,1.93,3.44,1.18.73,2.58.98,3.96,1.15,3.42.42,6.88.45,10.31.09.67-.07,1.4-.18,1.88-.67Z"/>
                    <path className="cls-23" d="m628.38,951.94c-.29-.4-.37-.92-.32-1.42.16-1.65,1.61-2.93,3.19-3.42s3.28-.33,4.92-.17c1.94.19,3.89.38,5.75.93,1.87.55,3.66,1.49,4.93,2.97-.07,1.38-1.17,2.55-2.45,3.09-1.28.54-2.71.56-4.09.51-3.44-.12-6.87-.64-10.19-1.54-.66-.18-1.36-.4-1.75-.96Z"/>
                    <path className="cls-56" d="m695.5,930.56s-.24,26.79-18.92,16.62c-13.25-7.22-16.82-8.16-16.82-8.16l-15.18,7.62-7.98-7.32,15.75-14.13s3.11-3.82,14.19-.3c10,3.18,28.96,5.68,28.96,5.68Z"/>
                    <path className="cls-54" d="m696.35,937.36s-.24,26.79-18.92,16.62c-13.25-7.22-16.82-8.16-16.82-8.16l-15.18,7.62-7.98-7.32,15.75-14.13s3.11-3.82,14.19-.3c10,3.18,28.96,5.68,28.96,5.68Z"/>
                    <path className="cls-60" d="m663.65,930.69c-.68-2.15-.66-1.69-1.48-5.03-1.1-4.53-1.13-4.43-.76-11.36.01-.22.02-.46-.1-.64-.12-.18-.33-.27-.52-.34-1.14-.46-2.29-.91-3.43-1.37-.21-.08-.45-.21-.47-.43-.01-.13.06-.26.13-.37.87-1.43,1.74-2.86,2.62-4.28,1.22-2.01,2.49-4.06,4.34-5.51,1.42-1.11,3.1-1.79,4.79-2.36,1.52-.51,2.3-1.33,3.65-2.15,2.68-1.62,5.81-2.35,8.91-1.79,3.28.59,4.91,2.67,7.47,4.36,2.91,1.92,7.3,2.6,10.69,2.77-.03,3.79-.53,7.57-1.46,11.23q-1.74-.2-3.48-.39c-.17-.02,2.47,12.56,1.84,27.11"/>
                    <path className="cls-65" d="m699.44,903.48c.13-.02.27-.02.4-.03,2.79-.4,8.08-1.84,8.26-5.54.01-.26,0-.53-.02-.8-.03-.43-.05-.85.05-1.26.2-.76.83-1.35,1.55-1.63,1.48-.58,3.62-.41,4.79.72,1.13,1.08,1.52,2.9,1,4.42-.47,1.36-1.45,2.04-2.61,2.7-1.17.66-1.9,1.42-2.96,2.35-2.23,1.92-4.89,3.27-7.6,4.29-1.14.43-2.37.89-3.49,1.11l.64-6.33Z"/>
                    <ellipse className="cls-65" cx="678.19" cy="882.1" rx="22.47" ry="23.58"/>
                    <path className="cls-114" d="m685.36,880.92c0-.93-.75-1.68-1.68-1.68s-1.68.75-1.68,1.68.75,1.68,1.68,1.68,1.68-.75,1.68-1.68Z"/>
                    <path className="cls-114" d="m669,880.92c0-.93-.75-1.68-1.68-1.68s-1.68.75-1.68,1.68.75,1.68,1.68,1.68,1.68-.75,1.68-1.68Z"/>
                    <path className="cls-15" d="m680.96,894.59c-1.5,2.72-4.93,3.71-7.65,2.21"/>
                    <path className="cls-70" d="m692.44,878.08c-.1,8.51,4.65,13.13,8.22,13.54,2.74.31,2.08-10.37,1.63-18.87-.52-9.73-2.74-13.09-9.11-14.27-.12,2.14-.63,9.62-.75,19.59Z"/>
                    <path className="cls-70" d="m691.24,870.63c.77,1.61,2.66,3.86,4.4,7.15-.11-1.64.1-16.07-.01-18.55-1.27-.11-6.26-.59-8.4-.74-.12,0-4.02,7.51-1.44,6.16,5.13-2.68,4.09,3.15,5.45,5.98Z"/>
                    <path className="cls-70" d="m653.54,856.27c10.68-8.44,27.55-13.54,36.82-3.56-2.05,2.61-10.71,5.4-11.53,5.79-11.21-4.93-21-2.28-25.29-2.22Z"/>
                    <path className="cls-70" d="m647.83,872.74c15.07,6.67,43.69-8.26,42.69-11.75-1-3.48-16.52-8.7-27.14-5.65-10.62,3.05-15.33,15.88-15.56,17.4Z"/>
                    <path className="cls-65" d="m695.25,886.02c-.88,3.3,0,6.39,1.95,6.92,1.96.52,4.26-1.72,5.14-5.02.88-3.3,0-6.39-1.95-6.92-1.96-.52-4.26,1.72-5.14,5.02Z"/>
                    </g>
                    <text transform="translate(805.48 1118.74)"><tspan className="cls-24"><tspan x="0" y="0">-3,5%</tspan></tspan><tspan className="cls-33"><tspan x="-48.33" y="18">daling vaccinatiegraad </tspan></tspan><tspan className="cls-33"><tspan x="-34.5" y="36">(zonder MenACWY)</tspan></tspan></text>
                    <text transform="translate(597.83 1041.23)"><tspan className="cls-24"><tspan className="number" x="0" y="0">52</tspan><tspan>.</tspan><tspan>000</tspan></tspan><tspan className="cls-33"><tspan x="-29.88" y="18">uitnodigingen HPV </tspan></tspan><tspan className="cls-33"><tspan x="10.36" y="36"><tspan>10</tspan><tspan> jaar</tspan></tspan></tspan></text>
                    <text transform="translate(605.72 1126.24)"><tspan className="cls-26"><tspan className="number" x="0" y="0">79</tspan><tspan>.</tspan><tspan className="number">318</tspan></tspan><tspan className="cls-33"><tspan x="-24.28" y="18">kinderen gevaccineerd </tspan></tspan><tspan className="cls-33"><tspan x="3.29" y="36">op CJG-locatie</tspan></tspan></text>
                    <text transform="translate(820.1 1041.23)"><tspan className="cls-24"><tspan className="number" x="0" y="0">9</tspan><tspan> jaar</tspan></tspan><tspan className="cls-33"><tspan x="-12.75" y="18">pilot-consult </tspan></tspan><tspan className="cls-33"><tspan x="-13.13" y="36">inclusief prik</tspan></tspan></text>
                    <g id="Prik-2" data-name="Prik">
                    <circle className="cls-23" cx="753.21" cy="1038.99" r="46.88"/>
                    <line className="cls-6" x1="770.18" y1="1011.53" x2="781.09" y2="1022.31"/>
                    <line className="cls-6" x1="775.42" y1="1017.77" x2="766.49" y2="1026.28"/>
                    <line className="cls-6" x1="756.99" y1="1014.37" x2="778.97" y2="1035.78"/>
                    <path className="cls-6" d="m761.95,1019.9s-4.01,3.8-9.13,8.72c-8.83,8.48-20.95,20.27-21.49,21.62-.85,2.13-.28,4.54-.28,4.54l6.52,7.09s3.69.57,5.39-.28c1.7-.85,30.62-30.48,30.62-30.48l-11.63-11.2Z"/>
                    <line className="cls-6" x1="758.13" y1="1034.08" x2="752.82" y2="1028.62"/>
                    <line className="cls-6" x1="753.73" y1="1038.19" x2="748.43" y2="1032.73"/>
                    <line className="cls-6" x1="734.17" y1="1057.61" x2="724.81" y2="1066.68"/>
                    </g>
                    <text className="cls-21" transform="translate(648.77 773.39)"><tspan x="0" y="0">Huggy </tspan><tspan className="cls-121" x="-2.39" y="18">W</tspan><tspan x="13.12" y="18">uggy</tspan><tspan x="-8.1" y="36">populair</tspan></text>
                    <g id="Huggy_Wuggy" data-name="Huggy Wuggy">
                    <path className="cls-56" d="m593.5,837.96s-5.83,5-4.58,9.18c1.25,4.18,6.32,3.9,7.96,2.79s-1.42-8.8,2.37-5.87c1.45,1.12,4.33-2.29-3.02-6.58l-2.74.48Z"/>
                    <path className="cls-56" d="m620.53,823.57s7.33,2.31,7.82,6.64c.5,4.33-4.28,6.07-6.23,5.69s-3.36-9.38-3.86-4.61c-.21,1.99-5.23-.35-.45-7.08l2.71-.64Z"/>
                    <path className="cls-56" d="m568.82,803.07s-4.23,4.44-3.82,10.39,6.83,5.9,7.34-.08,2.65,1.73,3.47-.5-3.47-9.27-3.47-9.27l-3.51-.55Z"/>
                    <path className="cls-56" d="m630.85,780.47s6.03,1.1,9.21,6.14c3.18,5.05-2.04,8.79-5.97,4.26s-1.12,2.96-3.1,1.64c-1.98-1.32-2.66-9.53-2.66-9.53l2.52-2.51Z"/>
                    <path className="cls-60" d="m584.4,739.2s-9.55-10.59-11.13,3.65-2.75,30.17,8.58,29.54c11.33-.63,30.02-5.32,2.54-33.19Z"/>
                    <path className="cls-60" d="m561.67,726.16c-2.73.33-6.28-.2-5.6,4.07.76,4.82,14.22,15.01,22.6,13.85s18.66-31.02,7.58-26.38c-11.91,5-13.07,7.06-24.59,8.45Z"/>
                    <circle className="cls-23" cx="570.6" cy="729.78" r="2.08"/>
                    <circle className="cls-23" cx="578.88" cy="727.13" r="2.08"/>
                    <path className="cls-60" d="m584.4,739.2s19.85,4.19,32.44,19.99c12.59,15.8,23.52,13.81,14.58,25.41-8.95,11.6-13.52-28.34-43.04-41.17l-3.98-4.23Z"/>
                    <path className="cls-60" d="m575.06,741.02s-11.77,16.53-10.34,36.68c1.43,20.15-7.96,26.09,6.48,28.57s-9.24-30,3.79-59.44l.06-5.81Z"/>
                    <path className="cls-60" d="m596.29,765.5s6.88,15.82,17.38,27.08c10.51,11.26,13.46,32.27,7.16,33.67-6.3,1.4-3.54-8.03-3.89-16.43s-13.33-15.74-25.85-40.39l5.2-3.93Z"/>
                    <path className="cls-60" d="m580.96,769.43s3.94,55.45,9.04,66.52c2.55,5.54,3.73,4.25,6.24,3.18,2.15-.92-1.72-10.55-2.2-12.74-.96-4.37-8.56-58.83-8.56-58.83l-4.52,1.87Z"/>
                    <path className="cls-23" d="m568.71,734.86c-1.64-.21-1.89-.48.69.9s5.81,4.57,8.51,3.73c2.69-.84,3.98-4.19,4.72-5.81,1.29-2.81,2.75-5.77.5-2.39-2.49,3.74-6.65-1.63-8.44,1.83-.96,1.85-3.69,2.04-5.97,1.75Z"/>
                    <polygon className="cls-38" points="583.15 730.11 582.08 731.05 578.09 730.81 574.67 731.05 571.62 733.53 567.71 734.62 567.14 734.7 569.54 736 569.72 734.82 571.06 736.2 571.03 734.83 572.29 735.64 571.97 734.7 573.53 735.52 573.19 734.26 574.63 735.41 574.1 733.46 575.68 735.02 574.31 732.18 576.15 734.26 575.44 731.68 576.86 734 576.53 731.56 577.54 733.26 577.54 731.56 578.76 733.53 578.76 731.94 580.11 733.65 579.94 731.86 581.14 733.62 581.33 732.01 582.08 733.05 582.54 731.27 583.17 732.51 583.17 730.45 583.63 731.51 584.72 729.63 583.15 730.11"/>
                    <polygon className="cls-38" points="571.78 738.46 571.43 736.07 572.54 737.98 572.55 736.45 573.87 738.77 573.78 737.25 575.1 739.29 575.1 737.69 576.63 739.46 576.23 737.14 578.05 739.1 577.43 736.93 579.01 738.56 578.44 736.44 579.68 737.85 579.68 735.3 580.86 736.69 580.53 734.56 581.55 735.49 581.46 733.09 582.17 734.46 582.56 732.36 583.09 733.48 582.11 737.04 579.37 739.99 577.17 740.8 571.78 738.46"/>
                    <path className="cls-99" d="m584.15,728.95c-3.18,4.93-6.98-2.28-10.51,2.19-1.64,2.08-3.8,3.11-7.2,2.27-1.99-.49-4.21.14-.28,2.06,3.94,1.92,8.62,7.11,12.76,5.9,4.14-1.21,4.82-6.72,5.99-9.03,2-3.97,2.43-8.33-.75-3.4Zm-1.53,4.73c-.75,1.63-2.03,4.97-4.72,5.81-2.69.84-5.93-2.35-8.51-3.73-2.58-1.38-2.32-1.11-.69-.9,2.29.29,5.02.1,5.97-1.75,1.79-3.46,5.95,1.92,8.44-1.83,2.25-3.38.79-.41-.5,2.39Z"/>
                    </g>
                    <text transform="translate(89.06 787.67)"><tspan className="cls-26"><tspan x="0" y="0" className="number">284</tspan><tspan>.</tspan><tspan className="number">234</tspan></tspan><tspan className="cls-33"><tspan x="-2.03" y="18">contactmomenten</tspan></tspan></text>
                    <g id="Contact-2" data-name="Contact">
                    <path className="cls-88" d="m174.51,722.51h-15.73c-2.4,0-4.36,1.96-4.36,4.36v.26h5.22c4.15,0,7.52,3.37,7.52,7.52v7.23l6.99,3.94c.41.21.88-.17.76-.61l-.81-3.59h.4c2.4,0,4.36-1.96,4.36-4.36v-10.4c0-2.4-1.96-4.36-4.36-4.36Z"/>
                    <path className="cls-23" d="m164.84,733.85c-.03-.17-.06-.34-.11-.51-.04-.17-.09-.33-.15-.49-.06-.16-.13-.32-.2-.47-.07-.15-.16-.3-.24-.45-.13-.22-.28-.42-.44-.62-.11-.13-.22-.25-.34-.37-.95-.95-2.26-1.54-3.71-1.54h-18.96c-1.44,0-2.76.59-3.71,1.54-.12.12-.23.24-.34.37-.16.19-.31.4-.44.62-.09.14-.17.29-.24.45-.07.15-.14.31-.2.47-.06.16-.11.32-.15.49-.04.17-.08.34-.11.51-.04.26-.06.53-.06.8v12.53c0,.27.02.54.06.8.03.17.06.34.11.51.07.25.15.5.25.73.17.39.38.76.63,1.1.1.13.21.26.32.39.11.12.23.24.36.36.93.85,2.17,1.37,3.53,1.37h.48l-.98,4.33s-.02.1-.02.14c0,0,0,.02,0,.03,0,.04,0,.09.01.13,0,0,0,.01,0,.02,0,.04.02.08.04.11,0,0,0,.01,0,.02.02.04.04.07.07.11,0,0,.01.01.02.02.03.03.06.06.09.09,0,0,0,0,0,0,.03.03.07.05.1.07,0,0,.02,0,.02.01.04.02.08.03.12.04,0,0,.02,0,.02,0,.04,0,.08.01.12.01,0,0,.01,0,.02,0,.04,0,.08,0,.13-.02,0,0,.02,0,.03,0,.04-.01.09-.03.13-.05l8.98-5.07h9.56c1.35,0,2.59-.52,3.53-1.37.12-.11.24-.23.36-.36.11-.12.22-.25.32-.39.25-.34.47-.71.63-1.1.1-.24.18-.48.25-.73.04-.17.08-.34.11-.51.04-.26.06-.53.06-.8v-12.53c0-.27-.02-.54-.06-.8Z"/>
                    </g>
                    <text transform="translate(510.38 904.49)">
                      <tspan className="cls-26"><tspan x="0" y="0" className="number">93</tspan>
                      <tspan>,</tspan>
                      <tspan className="number">8</tspan>
                      <tspan>%</tspan>
                      </tspan>
                      <tspan className="cls-33"><tspan x="-10.32" y="18">bereikte kinderen </tspan></tspan><tspan className="cls-33"><tspan x="27.61" y="36">(0-18)</tspan></tspan></text>
                    <g id="Twee_kinderen" data-name="Twee kinderen">
                    <path className="cls-59" d="m424.01,988.03c-.78-.29-1.38-.94-1.78-1.66-1.33-2.43-.52-5.59,1.21-7.75,1.74-2.16,4.24-3.55,6.67-4.88,2.86-1.57,5.75-3.14,8.86-4.14,3.11-.99,6.49-1.38,9.64-.53,1.2,1.99.77,4.65-.49,6.6-1.27,1.95-3.23,3.33-5.19,4.57-4.89,3.07-10.13,5.58-15.59,7.47-1.08.37-2.26.72-3.33.32Z"/>
                    <path className="cls-59" d="m494.94,988.03c.78-.29,1.38-.94,1.78-1.66,1.33-2.43.53-5.59-1.21-7.75-1.74-2.16-4.24-3.55-6.67-4.88-2.86-1.57-5.75-3.14-8.86-4.14-3.11-.99-6.49-1.38-9.64-.53-1.2,1.99-.77,4.65.49,6.6,1.26,1.95,3.23,3.33,5.19,4.57,4.89,3.07,10.13,5.58,15.59,7.47,1.08.37,2.26.72,3.33.32Z"/>
                    <path className="cls-59" d="m434.59,982.51c-3.79,1.97-8.01,4.02-12.24,4.05.4.64.95,1.21,1.65,1.47,1.07.39,2.25.05,3.33-.32,5.46-1.88,10.7-4.39,15.59-7.47,1.97-1.24,3.93-2.62,5.19-4.57.89-1.37,1.35-3.08,1.14-4.67-3.49,5.21-9.2,8.67-14.67,11.5Z"/>
                    <path className="cls-59" d="m492.4,985.69c-4.3-1.23-8.25-3.62-12.13-5.81-3.52-2-8.28-4.49-10.64-8.07,0,1.35.45,2.73,1.19,3.87,1.26,1.95,3.23,3.33,5.19,4.57,4.89,3.07,10.13,5.58,15.59,7.47,1.08.37,2.26.72,3.33.32.78-.29,1.38-.94,1.78-1.66.1-.18.16-.37.23-.55-1.37.34-2.93.34-4.55-.13Z"/>
                    <polygon className="cls-23" points="480.25 852.16 476.7 826.23 423.34 823.44 423.34 846.58 435.68 970.57 451.48 970.57 446.9 852.05 456.11 849.52 468.17 970.57 482.57 970.57 480.25 852.16"/>
                    <polygon className="cls-86" points="473.53 737.63 451.1 731.59 431.1 736.36 432.01 753.99 473.53 753.99 473.53 737.63"/>
                    <path className="cls-86" d="m482.74,824.67c.71-1.06,1.5-2.06,2.34-2.99,2.3-2.5,5.81-4.25,9.33-5.04l-2.52-20.96-.97-53.31-22.56-6.01c-1.16,8.11-6.66,15.72-15.6,15.07-6.39-.47-10.88-4.7-14.14-9.93-1.29-2.06-2.24-4.17-2.75-6.29l-20.67,4.98-25.35,50.93,23.4,32.32,8.52-8.32-15.39-25.23,17.93-27.42-.97,72.25,55.34,5.27.83-28.47,3.24,13.15Z"/>
                    <path className="cls-65" d="m439.51,823.5c-.43,1.54-1.65,2.91-3.09,3.69-6.74,3.62-16.74,2.94-20.19-4.86-.11-.26-.22-.54-.17-.81.04-.21.17-.4.3-.57,1.31-1.82,2.62-3.64,3.94-5.46.51-.7,1.02-1.41,1.7-1.95,4.09-3.24,16.51,3.21,17.59,7.86.16.71.12,1.42-.07,2.11Z"/>
                    <g>
                        <path className="cls-23" d="m482.67,725.71l7.22-20.65c2.98-8.54.86-17.82-5.65-24.68l-17.03-10.25-37.4-1.87-13.5,13.12c-4.33,6.14-5.62,13.56-3.56,20.55,0,0,6.01,22.93,11.25,28.02s25.81,7.58,25.81,7.58l19.82-43.35,13.04,31.54Z"/>
                        <path className="cls-65" d="m440.72,739.15c0,6.35,23.43,6.35,23.43,0v-16.47h-23.43v16.47Z"/>
                        <path className="cls-65" d="m484.05,702.82c0,19.09-14.75,34.57-32.95,34.57s-32.95-15.48-32.95-34.57,14.75-34.57,32.95-34.57,32.95,15.48,32.95,34.57Z"/>
                        <path className="cls-23" d="m436.26,704.11c0-1.36,1.1-2.46,2.46-2.46s2.46,1.1,2.46,2.46-1.1,2.46-2.46,2.46-2.46-1.1-2.46-2.46Z"/>
                        <path className="cls-23" d="m460.25,704.11c0-1.36,1.1-2.46,2.46-2.46s2.46,1.1,2.46,2.46-1.1,2.46-2.46,2.46-2.46-1.1-2.46-2.46Z"/>
                        <path className="cls-16" d="m442.1,723.04c2.54,4.61,9.44,5.67,15.43,2.37"/>
                        <path className="cls-23" d="m409.01,684.01c.64-4.27,5.49-6.7,9.49-7.66.19-5.09,10.16-8.29,12.6-8.7,13.64-2.29,27.85,1.23,27.85,1.23,20.3,1.19,29.07,3.31,27.96,17.87v.03s-5.07-.3-5.07-.3l5.48,3.25-3.27,3.48c-4.47,3.33-12.44,4.98-18.14,3.75l-41.01-8.83s-2.96,7.89-4.32,10.7c-1.27,2.63-5.7,10.45-5.7,10.45,0,0-6.96-18.07-5.88-25.28Z"/>
                        <path className="cls-23" d="m415.56,711.45s1.89,5.02.45,11.3c-.11.46.44.8.81.5l3.88-3.19-5.15-8.61Z"/>
                        <path className="cls-23" d="m419.71,722.68s1.87,6.14,1.09,8.96c-.06.22.07.45.3.47.89.05,3.04-.02,5.95-1.45l-7.34-7.98Z"/>
                        <path className="cls-23" d="m424.03,687.03c3.97,7.18,12.58,11.5,20.71,10.39-2.13-1.54-3.81-3.7-4.77-6.14"/>
                    </g>
                    <g>
                        <path className="cls-23" d="m476.07,791.53c-1.65-.92-4-.89-5.78-.03-2.63,1.27-4.46,3.72-6.57,5.73-2.12,2.01-5.03,3.72-7.84,2.94,1.5-2.23-.48-5.62-3.15-5.95s-5.21,1.77-6.06,4.32c-1.55,4.66,1.84,9.85,6.32,11.83,4.49,1.98,9.66,1.49,14.47.5-2.63,4.26-6.17,7.95-10.32,10.75-2.1-2.6-5.21-5.49-8.29-4.21-3.16,1.31-3.21,5.77-2.29,9.07.91,3.27,2.55,6.74,5.71,7.99,1.82.72,3.86.57,5.77.18,5.37-1.11,10.33-4.12,13.78-8.39,7.39,2.8,15.5,5.21,23.09,3.02,6.36-1.84,11.48-6.75,14.94-12.39,6.52-10.65,11.18-23.37,7.92-35.73-2.47-9.37-9.43-19.87-20.23-19.87-6.79,0-12.38,4.49-15.95,9.94-1.34,2.05-6.57,22.27-3.77,22.27-.34-.88-.97-1.53-1.76-1.97Z"/>
                        <path className="cls-38" d="m498.09,770.66c0,3.71,3.01,6.71,6.73,6.71s6.73-3.01,6.73-6.71-3.01-6.71-6.73-6.71-6.73,3.01-6.73,6.71Z"/>
                        <path className="cls-38" d="m485.79,770.66c0,3.71,3.01,6.71,6.73,6.71s6.73-3.01,6.73-6.71-3.01-6.71-6.73-6.71-6.73,3.01-6.73,6.71Z"/>
                        <path className="cls-53" d="m517.22,773.55c-.99-1.31-2.33-2.35-3.93-2.56.69.09-.84,11.73-1.04,12.83-.76,4.26-1.68,8.57-3.43,12.55-1.17,2.66-2.23,3.28-4.81,3.96-2.49.66-4.38,2.85-3.97,5.54.32,2.12,2.14,3.89,4.24,4.25,4.61.78,5.27-3.54,7.7-6.13,2.44-2.62,4.56-5.71,5.67-9.13,1.98-6.08,3.65-14.03.4-20-.24-.45-.52-.89-.84-1.3Z"/>
                        <path className="cls-53" d="m498.58,799.93c-.33.08-.67.16-1.01.2-4.73.58-7.69-6.06-8.79-9.63-1.22-3.96-1.5-8.15-1.54-12.3-.02-2.51.01-5.16-1.19-7.37-.35-.65-.86-1.28-1.57-1.47-.82-.21-1.68.23-2.33.78-2.28,1.96-2.93,5.21-3.12,8.22-.37,5.99.66,11.28,3.61,16.52,1.88,3.33,4.15,6.53,7.06,9.04,1.4,1.2,2.53,2,3.61,3.38,1.08,1.38,2.14,2.52,3.98,2.84,2.03.35,4.25-.56,5.39-2.28,1.18-1.79.94-4.63-.1-6.46-.51-.88-1.39-1.58-2.39-1.67-.54-.05-1.08.08-1.61.21Z"/>
                        <g>
                        <path className="cls-53" d="m469.31,746.15c1.29,4.83.01,9.37-2.86,10.14-2.87.77-6.25-2.53-7.54-7.36-1.29-4.83-.01-9.37,2.86-10.14,2.87-.77,6.25,2.53,7.54,7.36Z"/>
                        <path className="cls-53" d="m464.59,737.52c0,20.19,15.76,36.56,35.2,36.56s35.2-16.37,35.2-36.56-15.76-36.56-35.2-36.56-35.2,16.37-35.2,36.56Z"/>
                        <path className="cls-23" d="m503.25,699.1c5.73-3.68,11.15-4.19,17.31-1.02,5.92,3.05,11.93,8.61,14.1,15.04,1.31,3.91,1.85,7.88,1.83,12-.01,2.01-.14,4.02-.31,6.03-.15,1.68-.89,3.76-1.19,6.37-1.06-6.62-5.5-12.6-10.68-16.86,6.19,8.59,10.87,18.9,9.97,29.45-.69,8.15-4.65,15.71-9.64,22.19.44-.57,7.84-13.18,4.15-26.46-6.32-22.74-27.71-32.6-27.69-32.64-2.54,4.19-7.18,6.51-11.33,9.13-11.38,7.18-20.06,18.35-22.61,31.74-1.22-3.54-1.9-7.24-2.57-10.92-.59-3.22-1.18-6.45-1.78-9.67-.84-4.57-.62-10.09.87-14.5,1.76-5.17,5.09-9.69,8.97-13.53,4.04-4.01,8.83-7.43,14.32-8.93,5.49-1.5,11.74-.86,16.27,2.59Z"/>
                        <path className="cls-23" d="m488.97,687.94c-4.59-1.77-4.65-.22-9.53.4,8.35,1.48,16.5,4.13,24.13,7.85,1.07-9.93-2.6-20.27-9.68-27.32-3.57,1.15-7.04,2.78-9.83,5.27-2.79,2.5-4.86,5.95-5.16,9.69,5.49.53,5.83.59,10.07,4.1Z"/>
                        <path className="cls-23" d="m507.23,699.65c.33-.25.62-.55.86-.88,1.34-1.96-.8-5.52-2.53-6.69-1.52-1.03-3.65-1.17-5.19-.18-.5.32-.92.73-1.32,1.17-.89.98-1.67,2.21-1.59,3.53.26,4.07,6.78,5.3,9.77,3.05Z"/>
                        <path className="cls-16" d="m492.97,747.19c-1.99-3.02-6.36-3.64-9.78-1.39"/>
                        <path className="cls-16" d="m515.84,747.19c-1.98-3.02-6.36-3.64-9.78-1.39"/>
                        </g>
                    </g>
                    <path className="cls-65" d="m487.91,822.66c2.8-2.91,7.63-4.53,11.21-5.25,4.06-.81,2.85,1.86,1.86,4.64-1.67,4.68-4.46,10.64-9.09,12.93-.53.26-1.08.49-1.67.57-1.32.17-4.64-.51-5.22-1.84-.25-.58-.27-1.24-.26-1.88.06-2.32.57-4.62,1.5-6.74.39-.9.97-1.71,1.67-2.44Z"/>
                    </g>
                    <text transform="translate(202.79 655.72)"><tspan className="cls-26"><tspan className="number" x="0" y="0">{'43'}</tspan></tspan><tspan className="cls-33"><tspan x="-54.09" y="18">jongeren halen gratis </tspan></tspan><tspan className="cls-33"><tspan x="-28.91" y="36">SOA-thuistest</tspan></tspan></text>
                    <g id="Meisje">
                    <path className="cls-56" d="m328.33,592.13h0c-8.59-2.91-16.72,5.23-15.16,35.99v28.47c0,10.39,10.67,10.78,21.15,10.59l-.22-10.54c-9.94-3.94-14.43-12.66-13.41-26.52l13.2-22.44,23.47,12.13,1.5,25.16c-3.95,6.26-8.44,9.4-11.91,11.17l-.05,11.3,11.64,2.63-.54,21.08,5.23-.87c13.25-.31,4.67-43.14,2.88-60.5-4.55-43.95-15.85-41.67-28.18-41.31-3.85.11-7.4.39-9.59,3.67Z"/>
                    <path className="cls-60" d="m363.07,743.28l-3.72-25.1-37.36-1.89-3.12,24.39c-.37,44.94,6.15,51.59,6.55,94.37h7.67l8.62-78.74,4.35,75.91h9.23c4.06-44.06,4.07-46.52,7.76-88.94Z"/>
                    <polygon className="cls-110" points="359.4 721.7 363.32 721.91 363.79 731.26 359.4 730.86 359.4 721.7"/>
                    <polygon className="cls-110" points="350.42 721.22 356.96 721.57 356.96 730.63 350.42 730.02 350.42 721.22"/>
                    <polygon className="cls-110" points="340.22 720.68 340.22 729.07 332.2 728.32 332.2 720.26 340.22 720.68"/>
                    <polygon className="cls-110" points="329.4 728.06 322.66 727.44 324.79 719.86 329.4 720.11 329.4 728.06"/>
                    <path className="cls-23" d="m355.09,830.97c1.55.92,1.9,2.59,4.03,4.46,1.76,1.55,3.44,3.19,5.04,4.91,1.36,1.47,2.71,3.12,3.03,5.13.44,2.74-1.46,5.65-4.07,6.27-2.43.57-4.91-.65-6.93-2.16-5.29-3.94-11.08-12.2-10-19.35.01-.08,6.38.02,6.88.09.87.12,1.51.34,2.02.64Z"/>
                    <path className="cls-23" d="m325.2,830.26h9.15c.69,0-1.75,8.99-1.84,9.23-1.9,4.85-4.5,9.6-8.77,12.61-1.71,1.21-3.74,2.12-5.79,1.85-2.05-.27-4.01-2.04-3.97-4.17.02-1.27.7-2.42,1.38-3.49,2.59-4.03,9.99-10.02,9.87-15.17,0-.29-.02-.57-.03-.86Z"/>
                    <path className="cls-89" d="m376.49,750.22c-.01-.32-.54-11.21-.74-11.44l-1.75-24-5.91-45.07c-.93-4.27-4.19-6.47-8.34-7.39l-20.71-4.55-17.51,2.32c-5.6.74-10.56,4.1-13.46,9.11l-3.84,40.19-.53,24.74,1.1,17.83c2.86,8.53,10.77,1.97,10.77,1.97l-6.16-17.38,6.16-22.77,3.41-23.74,6.82,26.25,37.36,1.89.19,1,3.87,17.39.19,17.92c.37,5.65,9.24.17,9.07-4.27Z"/>
                    <path className="cls-45" d="m308.08,669.21c2.9-5.01,7.86-8.37,13.46-9.11l17.51-2.32,20.71,4.55c4.15.91,7.4,3.11,8.34,7.39l5.88,32.33-11.35,3.89,1.44,26.7h-47.68l2.61-42.59-1.21,16.44-15.19-.55,5.49-36.72Z"/>
                    <polygon className="cls-45" points="366.95 675.58 317.51 675.58 317.51 664.13 362.24 664.49 366.95 675.58"/>
                    <path className="cls-89" d="m331.9,665.66c0,2.8,16.3,2.8,16.3,0v-10.62h-16.3v10.62Z"/>
                    <path className="cls-89" d="m333.67,656.75c.47.13.94.24,1.4.34,13.09,2.78,25.46-7.37,26.39-21.15l1.61-20.4c0-13.24-10.04-24.4-22.85-24.62-.47,0-.94-.01-1.41-.01-12.88.02-23.1,9.1-22.85,22.41.17,8.93.84,15.34,1.46,23.06.77,9.64,7.2,17.95,16.26,20.38Z"/>
                    <path className="cls-23" d="m326.27,629.29c0-1.24.97-2.25,2.17-2.25s2.17,1.01,2.17,2.25-.97,2.25-2.17,2.25-2.17-1.01-2.17-2.25Z"/>
                    <path className="cls-23" d="m347.46,629.29c0-1.24.97-2.25,2.17-2.25s2.17,1.01,2.17,2.25-.97,2.25-2.17,2.25-2.17-1.01-2.17-2.25Z"/>
                    <path className="cls-12" d="m335.08,643.76c1.63,1.95,4.87,1.82,7.24-.29"/>
                    <path className="cls-56" d="m320.69,630.12c3.9-5.21,13.2-22.44,13.2-22.44,0,0,17.28,9.82,23.47,12.13,1.55.58,6.44,1.55,6.44,1.55l-.72-8.99-.98-4.68-3.47-8.26-8.35-7.56-12.66-2.52-8.35,2.75-6.55,3.03-6.05,7.17-2.58,9.52.62,24.71s4.68-4.63,5.99-6.38Z"/>
                    </g>
                    <text transform="translate(608.69 653.68)"><tspan className="cls-25"><tspan className="number" x="0" y="0">275</tspan><tspan>.</tspan><tspan className="number">364</tspan></tspan><tspan className="cls-33"><tspan x="48.27" y="18">kinderen (0-18) </tspan></tspan><tspan className="cls-33"><tspan x="40.01" y="36">in ons werkgebied</tspan></tspan></text>
                    <g id="Titel-8" data-name="Titel">
                    <rect id="Achtergrond_blokje" data-name="Achtergrond blokje" className="cls-38" x="614.23" y="536.13" width="207.77" height="55.76" rx="4.22" ry="4.22"/>
                    <text className="cls-48" transform="translate(637.32 575.09)"><tspan x="0" y="0">Kinderen</tspan></text>
                    </g>
                </g>
            </>
        )
    }
    return (
        <>
            <g ref={mobileScreen} id="Kinderen">
    <path id="Ondergrond-7" data-name="Ondergrond" className="cls-91" d="m304.05,1133.39h-46.65c.14,0,.28-.01.42-.01h9.79c-7.36-.78-13.09-7.01-13.09-14.57,0-8.1,6.56-14.66,14.66-14.66v-.08h47.43c12.53,0,22.69-10.16,22.69-22.69s-10.16-22.69-22.69-22.69h-16.03c7.07-1.05,12.49-7.12,12.49-14.48,0-7.98-6.38-14.46-14.31-14.64v-.02h-63.71v-.06c24.01-1.17,43.11-21,43.11-45.29s-20.3-45.35-45.35-45.35l-139.66-.05s-.01,0-.02,0-.01,0-.02,0h-1.55s0,.06,0,.06c-11.8.81-21.12,10.63-21.12,22.63s8.95,21.42,20.41,22.57h20.25c8.1,0,14.66,6.56,14.66,14.66s-6.56,14.66-14.66,14.66h-36.47v-.07h-18.09c-12.53,0-22.69,10.16-22.69,22.69s10.16,22.69,22.69,22.69c14.79,0,26.79,10.16,26.79,22.69s-11.99,22.69-26.79,22.69h-7.11c-8.1,0-14.66,6.57-14.66,14.66s6.57,14.66,14.66,14.66h-14.24c-12.52-.01-22.68,10.14-22.68,22.67s10.15,22.68,22.68,22.68h37.62c-12.52,0-22.68,10.15-22.68,22.68s10.15,22.68,22.68,22.68h231.02s.07,0,.11,0c.04,0,.07,0,.11,0,25.05,0,45.35-20.3,45.35-45.35s-20.3-45.35-45.35-45.35Z"/>
    <text transform="translate(263.62 1155.78)"><tspan className="cls-5"><tspan className="number" x="0" y="0">19</tspan>
                      <tspan>.</tspan>
                      <tspan>000</tspan></tspan><tspan className="cls-115"><tspan x="-14.56" y="16">nieuwsbrieven </tspan></tspan><tspan className="cls-115"><tspan x="-9.4" y="32">Peuterweken</tspan></tspan></text>
    <g id="Baby_met_kinderwagen" data-name="Baby met kinderwagen">
      <g>
        <path className="cls-88" d="m164.82,1164.2l1.35,13.51c0,3.73,3.03,6.76,6.76,6.76h54.06c3.73,0,6.76-3.03,6.76-6.76l1.35-13.51h-70.27Z"/>
        <path className="cls-48" d="m172.93,1184.47h54.06c3.73,0,6.76-3.03,6.76-6.76h-67.57c0,3.73,3.03,6.76,6.76,6.76Z"/>
        <rect className="cls-48" x="163.47" y="1160.82" width="72.97" height="3.38" rx="1.35" ry="1.35"/>
        <path className="cls-48" d="m194.68,1125.14c-18.11,3.11-29.87,16.98-29.87,35.68h29.87v-35.68Z"/>
        <g>
          <path className="cls-88" d="m194.68,1159.4v-34.26c-5.79.99-10.92,3.09-15.23,6.08l15.23,28.17Z"/>
          <path className="cls-88" d="m167.81,1145.55c-1.94,4.56-2.99,9.7-2.99,15.27h28.55l-25.56-15.27Z"/>
          <path className="cls-88" d="m192.92,1158.98l-14.58-26.95c-4.27,3.23-7.66,7.4-9.98,12.29l24.56,14.67Z"/>
        </g>
        <polygon className="cls-48" points="194.68 1125.14 194.68 1160.82 201.98 1125.14 194.68 1125.14"/>
        <path className="cls-96" d="m201.86,1172.31l22.94-22.93-1.91-1.91-56.18,56.2v2.7h66.49v-2.71l-31.34-31.35Zm-1.9,1.91l9.17,9.17h-18.34l9.17-9.17Zm11.87,11.87l9.87,9.87h-43.48l9.87-9.87h23.75Zm-41.31,17.57l5-5h48.88l5,5h-58.88Z"/>
        <circle className="cls-53" cx="199.95" cy="1172.31" r="3.38"/>
        <g>
          <polygon className="cls-96" points="178.47 1203.66 170.97 1203.66 176.27 1198.35 175.12 1197.21 169.82 1202.51 169.82 1195.01 168.2 1195.01 168.2 1202.51 162.89 1197.21 161.75 1198.35 167.05 1203.66 159.55 1203.66 159.55 1205.28 167.05 1205.28 161.75 1210.58 162.89 1211.73 168.2 1206.43 168.2 1213.93 169.82 1213.93 169.82 1206.43 175.12 1211.73 176.27 1210.58 170.97 1205.28 178.47 1205.28 178.47 1203.66"/>
          <circle className="cls-53" cx="169.01" cy="1204.47" r="3.38"/>
          <path className="cls-53" d="m169.01,1194.33c-5.6,0-10.14,4.54-10.14,10.14s4.54,10.14,10.14,10.14,10.14-4.54,10.14-10.14-4.54-10.14-10.14-10.14Zm0,16.89c-3.73,0-6.76-3.03-6.76-6.76s3.03-6.76,6.76-6.76,6.76,3.03,6.76,6.76-3.03,6.76-6.76,6.76Z"/>
          <circle className="cls-96" cx="168.96" cy="1204.47" r="1.35"/>
          <path className="cls-96" d="m177.05,1204.44c0-4.48-3.63-8.11-8.11-8.11s-8.11,3.63-8.11,8.11,3.63,8.11,8.11,8.11,8.11-3.63,8.11-8.11Zm-8.06,6.8c-3.73,0-6.76-3.03-6.76-6.76s3.03-6.76,6.76-6.76,6.76,3.03,6.76,6.76-3.03,6.76-6.76,6.76Z"/>
        </g>
        <g>
          <polygon className="cls-96" points="240.36 1203.66 232.86 1203.66 238.16 1198.35 237.02 1197.21 231.71 1202.51 231.71 1195.01 230.09 1195.01 230.09 1202.51 224.79 1197.21 223.64 1198.35 228.94 1203.66 221.44 1203.66 221.44 1205.28 228.94 1205.28 223.64 1210.58 224.79 1211.73 230.09 1206.43 230.09 1213.93 231.71 1213.93 231.71 1206.43 237.02 1211.73 238.16 1210.58 232.86 1205.28 240.36 1205.28 240.36 1203.66"/>
          <circle className="cls-53" cx="230.9" cy="1204.47" r="3.38"/>
          <path className="cls-53" d="m230.9,1194.33c-5.6,0-10.14,4.54-10.14,10.14s4.54,10.14,10.14,10.14,10.14-4.54,10.14-10.14-4.54-10.14-10.14-10.14Zm0,16.89c-3.73,0-6.76-3.03-6.76-6.76s3.03-6.76,6.76-6.76,6.76,3.03,6.76,6.76-3.03,6.76-6.76,6.76Z"/>
          <circle className="cls-96" cx="230.86" cy="1204.47" r="1.35"/>
          <path className="cls-96" d="m238.98,1204.46c0-4.48-3.63-8.11-8.11-8.11s-8.11,3.63-8.11,8.11,3.63,8.11,8.11,8.11,8.11-3.63,8.11-8.11Zm-8.06,6.8c-3.73,0-6.76-3.03-6.76-6.76s3.03-6.76,6.76-6.76,6.76,3.03,6.76,6.76-3.03,6.76-6.76,6.76Z"/>
        </g>
        <polygon className="cls-96" points="238.77 1146.93 223.48 1150.14 222.93 1147.5 238.21 1144.28 238.77 1146.93"/>
        <circle className="cls-53" cx="222.88" cy="1149.38" r="2.7"/>
        <circle className="cls-96" cx="199.89" cy="1172.28" r="1.69"/>
        <circle className="cls-96" cx="222.88" cy="1149.38" r="1.35"/>
      </g>
      <g>
        <g>
          <g>
            <path className="cls-92" d="m204.73,1142.55l-2.52,4.39s-1.21,2.48-1.59,4.04c-.46,1.9-.34,2.7-.34,2.7,0,0-2.12.94-3.21,1.88-.85.73-1.42,1.28-1.74,1.59l.68,3.86c3.21-.05,8.69-.22,11.75-.26h.61v-17.37l-3.64-.83Z"/>
            <path className="cls-92" d="m219.67,1155.56c-1.09-.94-3.21-1.88-3.21-1.88,0,0,.11-.8-.34-2.7-.38-1.56-1.29-2.7-1.29-2.7l-2.18-4.93-4.52.03v17.37h.85c2.73.03,7.37.17,10.63.24l1.59-4.05c-.34-.32-.85-.79-1.53-1.38Z"/>
            <path className="cls-92" d="m205.09,1142.67l-.38,4.15s.79.82,3.24.75c2.2-.07,2.76-.71,2.76-.71v-4.03h1.74v4.18s1.62,2,2.31,3.51c.62,1.34.89,1.94.89,1.94,0,0,1.58.79,2.84,1.82,1.24,1.01,2.58,2.47,2.58,2.47,0,0-.96.81-1.42,1.87-.46,1.06-.45,2.44-.45,2.44,0,0-2.85.2-4.8.1-1.95-.11-6.62,0-8.03-.11-1.41-.11-4.81.08-6.68.11-1.76.03-3.44-.11-3.44-.11,0,0,.44-1.07.13-2.35-.33-1.34-.89-1.83-.89-1.83,0,0,.59-1.26,1.85-2.27,1.63-1.32,3.08-1.9,3.08-1.9-.04,0,.51-.69,1.04-2.22.77-2.24,1.63-3.62,1.63-3.62l.5-4.76,1.51.57Z"/>
            <path className="cls-85" d="m214.44,1143.3s2.29-.08,2.53,3c.17,2.21-.07,5.1-.87,7.66-1.18,3.79-2.8,5.02-2.8,5.02,0,0-.12-1.07-1.22-1.22-1.11-.16-1.97,0-1.97,0,0,0,0-4.34.71-7.5.71-3.16,1.26-6,1.66-6.24.39-.24,1.97-.71,1.97-.71Z"/>
            <path className="cls-85" d="m201.02,1143.3s-2.29-.08-2.53,3c-.17,2.21.07,5.1.87,7.66,1.18,3.79,2.8,5.02,2.8,5.02,0,0,.12-1.07,1.22-1.22,1.11-.16,1.97,0,1.97,0,0,0,0-4.34-.71-7.5-.71-3.16-1.26-6-1.66-6.24-.39-.24-1.97-.71-1.97-.71Z"/>
          </g>
          <g>
            <path className="cls-67" d="m204.99,1162.95c-.39.07-.83-.06-1.16-.3-1.54-1.12-2.46-3.43-1.08-5.04.05-.05.1-.11.16-.12.05-.01.11,0,.16,0,.55.1,1.1.2,1.64.29.21.04.43.08.62.17,1.16.57,1.05,4.04.13,4.78-.14.11-.31.18-.48.21Z"/>
            <path className="cls-67" d="m212.38,1162.88c-.39.07-.83-.06-1.16-.3-1.54-1.12-2.46-3.43-1.08-5.04.05-.05.1-.11.16-.12.05-.01.11,0,.16,0,.55.1,1.1.2,1.65.29.21.04.43.08.62.17,1.17.57,1.05,4.04.13,4.78-.14.11-.31.18-.48.21Z"/>
            <path className="cls-67" d="m198.16,1164.73c-.58.1-1.24-.09-1.73-.44-2.3-1.68-3.68-5.14-1.62-7.55.07-.08.14-.16.25-.19.08-.02.16,0,.24,0,.82.15,1.64.29,2.46.44.32.06.64.11.93.26,1.74.85,1.58,6.05.19,7.16-.21.17-.46.27-.72.32Z"/>
            <path className="cls-67" d="m218.31,1164.25c-.52-.28-.93-.83-1.09-1.42-.78-2.74.27-6.31,3.38-6.94.1-.02.21-.04.31,0,.07.03.13.09.18.15.56.62,1.11,1.24,1.67,1.86.21.24.43.48.57.77.85,1.74-2.49,5.73-4.26,5.75-.27,0-.53-.07-.76-.19Z"/>
          </g>
        </g>
        <g>
          <path className="cls-67" d="m225.59,1130.52c-.36-.1-.72-.09-1.06-.01.1-.92.13-1.87.06-2.82-.63-8.99-8.83-15.74-18.32-15.08-9.49.66-16.68,8.48-16.05,17.47.1,1.37.37,2.68.79,3.93-1-.03-1.93.62-2.2,1.63-.32,1.18.38,2.4,1.56,2.72.91.25,1.84-.11,2.36-.83,3.24,4.96,9.21,8.08,15.8,7.63,7.02-.49,12.77-4.9,15.04-10.79.24.2.52.36.84.45,1.18.32,2.4-.38,2.72-1.56.32-1.18-.38-2.4-1.56-2.72Z"/>
          <g>
            <g>
              <path className="cls-41" d="m202.31,1137.78c-.06-.61-.61-1.06-1.22-.99-.61.06-1.06.61-.99,1.22.06.61.61,1.06,1.22.99.61-.06,1.06-.61.99-1.22Z"/>
              <path className="cls-41" d="m216.48,1136.3c-.06-.61-.61-1.06-1.22-.99-.61.06-1.06.61-.99,1.22.06.61.61,1.06,1.22.99.61-.06,1.06-.61.99-1.22Z"/>
            </g>
            <path className="cls-34" d="m208.47,1142.01c-1.63,0-2.54-1.33-2.58-1.39l.27-.18s.97,1.4,2.6,1.23c1.64-.17,2.19-1.71,2.2-1.72l.31.11c-.02.07-.63,1.75-2.47,1.94-.11.01-.22.02-.32.02h0Z"/>
            <path className="cls-41" d="m207.37,1121.28c-1.1,0-2.06-.52-2.61-1.43-.19-.32-.31-.66-.35-1-.73-.49-1.21-1.37-.93-2.45.29-1.16,1.04-1.55,1.07-1.57l.22.44s-.58.32-.82,1.25c-.18.71.05,1.3.46,1.71.04-.26.14-.51.28-.74.6-.93,1.68-1.17,2.34-.86.4.19.74.65.51,1.39-.38,1.18-1.48,1.44-2.4,1.18-.06-.02-.12-.04-.18-.06.05.16.12.31.21.46.49.82,1.4,1.26,2.43,1.18,1.93-.15,2.67-1.8,2.7-1.88l.45.19c-.03.08-.87,2-3.11,2.17-.09,0-.19.01-.28.01h0Zm-2.49-2.71c.13.07.27.12.4.16.64.18,1.5.07,1.79-.86.09-.27.11-.62-.25-.79-.47-.22-1.29,0-1.72.68-.15.24-.23.52-.23.81h0Z"/>
          </g>
          <path className="cls-45" d="m198.02,1140.5c-.57.34-.87.98-.81,1.6.74.51,1.53.97,2.36,1.36.06-.03.11-.05.17-.09.79-.47,1.06-1.5.58-2.3-.47-.79-1.5-1.05-2.3-.58Z"/>
          <path className="cls-45" d="m219.77,1139.05c-.47-.8-1.5-1.06-2.3-.58-.8.48-1.06,1.5-.58,2.3.28.46.74.74,1.24.8.67-.51,1.3-1.06,1.88-1.66,0-.29-.07-.59-.24-.86Z"/>
        </g>
      </g>
    </g>
    <g id="Beer-2" data-name="Beer">
      <g>
        <ellipse className="cls-11" cx="247.37" cy="1216.94" rx="8.25" ry="2.81" transform="translate(-740.2 464.05) rotate(-41.19)"/>
        <ellipse className="cls-11" cx="269.8" cy="1215.47" rx="2.81" ry="8.25" transform="translate(-842.99 657.01) rotate(-50.83)"/>
        <path className="cls-11" d="m251.53,1212.9s-2.61,9.92-2.82,13.32,1.55,6.79,7.97,6.86c6.42.07,15.02-.71,15.09-3.24s-3.46-15.31-4.58-17.21c-1.13-1.9-2.54-4.14-2.54-4.14"/>
        <ellipse className="cls-11" cx="249.52" cy="1228.96" rx="5.15" ry="6.85" transform="translate(-993.7 1064.61) rotate(-70.99)"/>
        <g>
          <ellipse className="cls-11" cx="257.52" cy="1203.22" rx="10.47" ry="9.68"/>
          <ellipse className="cls-57" cx="257.92" cy="1208.05" rx="5.58" ry="4.76" transform="translate(-97.74 25.08) rotate(-4.68)"/>
          <ellipse className="cls-43" cx="257.84" cy="1207.06" rx="2.67" ry="1.8" transform="translate(-97.66 25.07) rotate(-4.68)"/>
          <circle className="cls-41" cx="252.73" cy="1202.77" r=".74"/>
          <circle className="cls-41" cx="259.85" cy="1202.03" r=".74"/>
          <ellipse className="cls-11" cx="247.72" cy="1198.71" rx="3.81" ry="2.95"/>
          <ellipse className="cls-11" cx="265.99" cy="1197.29" rx="3.81" ry="2.95"/>
        </g>
        <ellipse className="cls-11" cx="268.79" cy="1228.38" rx="5.02" ry="5.71" transform="translate(-341.06 128.72) rotate(-16.67)"/>
        <ellipse className="cls-66" cx="269.01" cy="1228.91" rx="3.38" ry="3.86" transform="translate(-341.21 128.81) rotate(-16.67)"/>
        <ellipse className="cls-66" cx="248.69" cy="1228.91" rx="3.38" ry="3.86" transform="translate(-924.3 786.02) rotate(-57.87)"/>
      </g>
    </g>
    <g id="Rolstoeler">
      <path className="cls-53" d="m314.41,1089.64c3.47,0,6.83-.68,10-2.02,3.06-1.29,5.8-3.14,8.16-5.5,2.36-2.36,4.21-5.1,5.5-8.16,1.34-3.17,2.02-6.53,2.02-10s-.68-6.83-2.02-10c-1.29-3.06-3.14-5.8-5.5-8.16-2.36-2.36-5.1-4.21-8.16-5.5-3.17-1.34-6.53-2.02-10-2.02s-6.83.68-10,2.02c-3.06,1.29-5.8,3.14-8.16,5.5-2.36,2.36-4.21,5.1-5.5,8.16-1.34,3.17-2.02,6.53-2.02,10s.68,6.83,2.02,10c1.29,3.06,3.14,5.8,5.5,8.16,2.36,2.36,5.1,4.21,8.16,5.5,3.17,1.34,6.53,2.02,10,2.02Zm0-48.28c12.46,0,22.6,10.14,22.6,22.6s-10.14,22.6-22.6,22.6-22.6-10.14-22.6-22.6,10.14-22.6,22.6-22.6Z"/>
      <polygon className="cls-96" points="249.65 1074.38 269.67 1015.09 268.5 1014.73 248.47 1074.02 249.65 1074.38"/>
      <g>
        <g>
          <rect className="cls-96" x="288.1" y="1038.94" width=".23" height="46.89"/>
          <rect className="cls-96" x="264.64" y="1062.89" width="46.89" height=".23"/>
          <rect className="cls-96" x="265.58" y="1062.27" width="47" height=".23" transform="translate(-691.84 562.34) rotate(-47.77)"/>
          <rect className="cls-96" x="287.97" y="1039.09" width=".23" height="46.85" transform="translate(-697.88 572.83) rotate(-48.42)"/>
          <rect className="cls-96" x="264.62" y="1062.52" width="47.43" height=".23" transform="translate(-806.33 944.15) rotate(-68.64)"/>
          <rect className="cls-96" x="265.09" y="1063.01" width="46.73" height=".23" transform="translate(-407.37 209.16) rotate(-23.99)"/>
          <rect className="cls-96" x="287.73" y="1038.59" width=".23" height="46.86" transform="translate(-401.15 204.1) rotate(-23.6)"/>
          <rect className="cls-96" x="287.97" y="1039.18" width=".23" height="47.4" transform="translate(-808.51 961.97) rotate(-69.58)"/>
        </g>
        <rect className="cls-96" x="264.64" y="1062.89" width="46.89" height=".23"/>
        <rect className="cls-96" x="265.09" y="1063.01" width="46.73" height=".23" transform="translate(-407.37 209.16) rotate(-23.99)"/>
      </g>
      <path className="cls-53" d="m288.33,1086.51c6.6,0,12.8-2.57,17.46-7.23,4.66-4.66,7.23-10.86,7.23-17.46s-2.57-12.8-7.23-17.46c-4.66-4.66-10.86-7.23-17.46-7.23s-12.8,2.57-17.46,7.23c-4.66,4.66-7.23,10.86-7.23,17.46s2.57,12.8,7.23,17.46c4.66,4.66,10.86,7.23,17.46,7.23Zm0-46.31c11.92,0,21.61,9.7,21.61,21.61s-9.7,21.61-21.61,21.61-21.61-9.7-21.61-21.61,9.7-21.61,21.61-21.61Z"/>
      <path className="cls-53" d="m288.33,1087.64c3.47,0,6.83-.68,10-2.02,3.06-1.29,5.8-3.14,8.16-5.5,2.36-2.36,4.21-5.1,5.5-8.16,1.34-3.17,2.02-6.53,2.02-10s-.68-6.83-2.02-10c-1.29-3.06-3.14-5.8-5.5-8.16-2.36-2.36-5.1-4.21-8.16-5.5-3.17-1.34-6.53-2.02-10-2.02s-6.83.68-10,2.02c-3.06,1.29-5.8,3.14-8.16,5.5-2.36,2.36-4.21,5.1-5.5,8.16-1.34,3.17-2.02,6.53-2.02,10s.68,6.83,2.02,10c1.29,3.06,3.14,5.8,5.5,8.16,2.36,2.36,5.1,4.21,8.16,5.5,3.17,1.34,6.53,2.02,10,2.02Zm0-48.28c12.46,0,22.6,10.14,22.6,22.6s-10.14,22.6-22.6,22.6-22.6-10.14-22.6-22.6,10.14-22.6,22.6-22.6Z"/>
      <path className="cls-96" d="m288.33,1086.51c6.6,0,12.8-2.57,17.46-7.23,4.66-4.66,7.23-10.86,7.23-17.46s-2.57-12.8-7.23-17.46c-4.66-4.66-10.86-7.23-17.46-7.23s-12.8,2.57-17.46,7.23c-4.66,4.66-7.23,10.86-7.23,17.46s2.57,12.8,7.23,17.46c4.66,4.66,10.86,7.23,17.46,7.23Zm0-46.31c11.92,0,21.61,9.7,21.61,21.61s-9.7,21.61-21.61,21.61-21.61-9.7-21.61-21.61,9.7-21.61,21.61-21.61Z"/>
      <path className="cls-96" d="m288.33,1087.64c3.47,0,6.83-.68,10-2.02,3.06-1.29,5.8-3.14,8.16-5.5,2.36-2.36,4.21-5.1,5.5-8.16,1.34-3.17,2.02-6.53,2.02-10s-.68-6.83-2.02-10c-1.29-3.06-3.14-5.8-5.5-8.16-2.36-2.36-5.1-4.21-8.16-5.5-3.17-1.34-6.53-2.02-10-2.02s-6.83.68-10,2.02c-3.06,1.29-5.8,3.14-8.16,5.5-2.36,2.36-4.21,5.1-5.5,8.16-1.34,3.17-2.02,6.53-2.02,10s.68,6.83,2.02,10c1.29,3.06,3.14,5.8,5.5,8.16,2.36,2.36,5.1,4.21,8.16,5.5,3.17,1.34,6.53,2.02,10,2.02Zm0-48.28c12.46,0,22.6,10.14,22.6,22.6s-10.14,22.6-22.6,22.6-22.6-10.14-22.6-22.6,10.14-22.6,22.6-22.6Z"/>
      <circle className="cls-96" cx="288.47" cy="1062.84" r="4.3"/>
      <circle className="cls-96" cx="288.47" cy="1062.84" r="1.97"/>
      <polygon className="cls-53" points="242.55 1072.86 277.62 1079.92 281.7 1077.29 246.62 1070.23 242.55 1072.86"/>
      <path className="cls-41" d="m281.9,1039.48h42.08c1.06,0,1.92-.86,1.92-1.92h0c0-1.06-.86-1.92-1.92-1.92h-42.08c-1.06,0-1.92.86-1.92,1.92h0c0,1.06.86,1.92,1.92,1.92Z"/>
      <path className="cls-41" d="m288.43,1037.17c12.3-.26,24.61-.52,36.91-.78l11.29-39.5c1.79-6.25-4.85-12.09-14.83-13.04h0c-9.98-.95-19.51,3.35-21.3,9.6-4.02,14.58-8.05,29.15-12.07,43.73Z"/>
      <g>
        <g>
          <rect className="cls-53" x="314.18" y="1040.94" width=".23" height="46.89"/>
          <rect className="cls-53" x="290.73" y="1064.89" width="46.89" height=".23"/>
          <rect className="cls-53" x="291.66" y="1064.27" width="47" height=".23" transform="translate(-684.77 582.31) rotate(-47.77)"/>
          <rect className="cls-53" x="314.05" y="1041.09" width=".23" height="46.85" transform="translate(-690.61 593.01) rotate(-48.42)"/>
          <rect className="cls-53" x="290.7" y="1064.52" width="47.43" height=".23" transform="translate(-791.61 969.72) rotate(-68.64)"/>
          <rect className="cls-53" x="291.17" y="1065.01" width="46.73" height=".23" transform="translate(-405.93 219.93) rotate(-23.99)"/>
          <rect className="cls-53" x="313.81" y="1040.59" width=".23" height="46.86" transform="translate(-399.77 214.71) rotate(-23.6)"/>
          <rect className="cls-53" x="314.05" y="1041.18" width=".23" height="47.4" transform="translate(-793.41 987.72) rotate(-69.58)"/>
        </g>
        <rect className="cls-53" x="290.73" y="1064.89" width="46.89" height=".23"/>
      </g>
      <g>
        <path className="cls-96" d="m314.41,1088.51c6.6,0,12.8-2.57,17.46-7.23,4.66-4.66,7.23-10.86,7.23-17.46s-2.57-12.8-7.23-17.46c-4.66-4.66-10.86-7.23-17.46-7.23s-12.8,2.57-17.46,7.23c-4.66,4.66-7.23,10.86-7.23,17.46s2.57,12.8,7.23,17.46c4.66,4.66,10.86,7.23,17.46,7.23Zm0-46.31c11.92,0,21.61,9.7,21.61,21.61s-9.7,21.61-21.61,21.61-21.61-9.7-21.61-21.61,9.7-21.61,21.61-21.61Z"/>
        <path className="cls-96" d="m314.41,1088.51c6.6,0,12.8-2.57,17.46-7.23,4.66-4.66,7.23-10.86,7.23-17.46s-2.57-12.8-7.23-17.46c-4.66-4.66-10.86-7.23-17.46-7.23s-12.8,2.57-17.46,7.23c-4.66,4.66-7.23,10.86-7.23,17.46s2.57,12.8,7.23,17.46c4.66,4.66,10.86,7.23,17.46,7.23Zm0-46.31c11.92,0,21.61,9.7,21.61,21.61s-9.7,21.61-21.61,21.61-21.61-9.7-21.61-21.61,9.7-21.61,21.61-21.61Z"/>
      </g>
      <path className="cls-53" d="m314.55,1070.68c3.22,0,5.84-2.62,5.84-5.84s-2.62-5.84-5.84-5.84-5.84,2.62-5.84,5.84,2.62,5.84,5.84,5.84Zm0-8.6c1.52,0,2.76,1.24,2.76,2.76s-1.24,2.76-2.76,2.76-2.76-1.24-2.76-2.76,1.24-2.76,2.76-2.76Z"/>
      <circle className="cls-96" cx="314.55" cy="1064.84" r="1.97"/>
      <path className="cls-121" d="m267.45,1049.89c1.41-6.49,2.28-12.52,2.81-17.94.13-1.3.64-2.53,1.51-3.5.62-.69,1.35-1.17,2.14-1.33,1.65-1.07,3.51-1.76,5.47-2.02,10.87-1.46,21.74-2.92,32.61-4.37,1.01-.14,2.01.29,2.61,1.11,1.42,1.92,3.53,5.59,1.76,8.59-.32.54-1.7,2.66-9.1,4.09-7.42,1.44-12.63.59-19.92.09-2.1-.15-4.65-.28-7.62-.35-.63,3.38-1.5,7.02-2.67,10.84-3.14,10.2-7.49,18.38-11.4,24.43l-3.63-.58c1.97-5.52,3.89-11.89,5.45-19.04Z"/>
      <g>
        <polygon className="cls-11" points="265.35 1072.08 259.36 1070.06 262.85 1067.49 266.24 1068.64 265.35 1072.08"/>
        <path className="cls-41" d="m264.74,1074.65c-.41-.05-1.02-.15-1.72-.39-1.27-.43-1.29-.8-2.32-1.22-1.64-.67-2.7-.22-4.32-.89-.53-.22-1.02-.51-1.02-.51-.2-.1-.43-.24-.67-.43-.15-.13-.28-.26-.4-.39-.32-.36-.15-.98.31-1.12.18-.06.36-.11.56-.15,1.19-.26,2.19-.14,2.83,0,.68-.35,1.65-.69,2.61-.39.64.21.94.59,1.42.96.68.53,1.8,1.09,3.68,1.09.65.78.74,1.84.28,2.58-.4.64-1.07.8-1.24.84Z"/>
      </g>
      <path className="cls-120" d="m275.15,1032.24c-.03-.81.33-1.52.75-2.07.42-.69.96-1.22,1.61-1.5,6.31-3.87,35.5-6.49,40.57-6.93.63.49,1.89,1.62,2.53,3.51,1.19,3.5-.84,6.54-1.09,6.91-1.44,2.09-3.73,3.08-7.56,3.88-8.57,1.79-10.89.91-20.2.43-2.84-.15-4.89.11-6.59.34.2,2.61.31,5.38.29,8.28-.08,10.68-1.9,19.75-3.91,26.68l-3.65.49c.3-5.85.31-12.51-.25-19.8-.51-6.62-1.41-12.65-2.46-17.99-.14-.73-.16-1.48-.05-2.2Z"/>
      <g>
        <polygon className="cls-11" points="282.02 1074.4 275.71 1074.19 278.3 1070.72 281.89 1070.84 282.02 1074.4"/>
        <path className="cls-41" d="m282.17,1077.04c-.41.07-1.02.15-1.76.12-1.34-.05-1.47-.39-2.58-.5-1.76-.17-2.65.57-4.4.39-.57-.06-1.13-.19-1.13-.19-.22-.04-.48-.11-.76-.23-.18-.08-.34-.16-.49-.26-.41-.26-.43-.89-.02-1.16.15-.1.31-.2.49-.3,1.06-.59,2.06-.76,2.71-.8.55-.53,1.38-1.14,2.39-1.12.67.01,1.08.3,1.63.52.81.31,2.04.53,3.84-.01.84.56,1.24,1.55,1.01,2.39-.2.72-.79,1.08-.94,1.16Z"/>
      </g>
      <polygon className="cls-96" points="277.56 1080.15 298.48 1018.17 297.31 1017.81 276.38 1079.79 277.56 1080.15"/>
      <path className="cls-53" d="m267.08,1017.7h26.85c1.13,0,2.04-.91,2.04-2.04h0c0-1.13-.91-2.04-2.04-2.04h-26.85c-1.13,0-2.04.91-2.04,2.04h0c0,1.13.91,2.04,2.04,2.04Z"/>
      <path className="cls-53" d="m314.41,1089.64c3.47,0,6.83-.68,10-2.02,3.06-1.29,5.8-3.14,8.16-5.5,2.36-2.36,4.21-5.1,5.5-8.16,1.34-3.17,2.02-6.53,2.02-10s-.68-6.83-2.02-10c-1.29-3.06-3.14-5.8-5.5-8.16-2.36-2.36-5.1-4.21-8.16-5.5-3.17-1.34-6.53-2.02-10-2.02s-6.83.68-10,2.02c-3.06,1.29-5.8,3.14-8.16,5.5-2.36,2.36-4.21,5.1-5.5,8.16-1.34,3.17-2.02,6.53-2.02,10s.68,6.83,2.02,10c1.29,3.06,3.14,5.8,5.5,8.16,2.36,2.36,5.1,4.21,8.16,5.5,3.17,1.34,6.53,2.02,10,2.02Zm0-48.28c12.46,0,22.6,10.14,22.6,22.6s-10.14,22.6-22.6,22.6-22.6-10.14-22.6-22.6,10.14-22.6,22.6-22.6Z"/>
      <g>
        <path className="cls-11" d="m322.22,1026.9c-1.3.56-5.68,2.26-11.05.83-4.92-1.31-7.76-4.47-8.7-5.62.2-.56,2.79-8.02,3.28-12.89.09-.93.2-2.45-.08-4.41-.13-.91-.37-1.92-.82-3.1-2.48-6.48-4.1-12.48-2.1-14.72,1.2-1.35,6.01-4.51,11.72-3,.77.2,2.45.81,3.16.9.79.19,4.21,1.35,5.86,5.11.63,1.44,1.19,2.86,1.6,4.47.17.65.46,1.91.65,3.64.5,4.49-.29,11.43-2.02,18.1-.32,1.22-.84,3.1-1.18,5.79-.27,2.12-.31,3.85-.31,4.9Z"/>
        <path className="cls-69" d="m270.53,1013.45c.27-.97,1.26-1.44,2.38-1.97.52-.25,1.39-.65,2.62-.72.12,0,.23,0,.34-.01.24-.07.49-.06.63-.05,3.12.19,12.23,1.61,17.68,1.05,1.37-.14,2.68-.28,4.1-.91.33-.97.72-2.28,1.05-3.88.07-.34.18-1,.35-2.05.03-.21.07-.42.1-.63,0,0-.01-.02-.02-.02.19-1.18.34-2.5.39-3.94.03-.78.03-1.53,0-2.23,2.48.52,4.97,1.04,7.45,1.57-.04,1.42-.11,2.89-.23,4.41-.02.22-.04.43-.05.64,0,.1,0,.2-.01.31,0,.11-.12,1.43-.24,2.32-.23,1.75-.73,5.46-2.3,7.54-.03.09-.06.17-.1.26-2,4.66-15.01,2.98-16.41,2.79-5.54-.72-9.83-2.51-12.45-3.82,0,.01,0,.02-.01.03-.17.21-1.64,2.06-3.49,1.79-.24-.04-.53-.11-.85-.28-.16-.09-.61-.37-.85-.94-.24-.59-.1-1.12-.06-1.25Z"/>
        <path className="cls-71" d="m287.23,1013.38c.27-.97,1.26-1.44,2.38-1.97.52-.25,1.39-.65,2.62-.72.12,0,.23,0,.34-.01.24-.07.49-.06.63-.05,3.12.19,12.23,1.61,17.68,1.05,1.37-.14,2.68-.28,4.1-.91.33-.97.72-2.28,1.05-3.88.07-.34.18-1,.35-2.05.03-.21.07-.42.1-.63,0,0-.01-.02-.02-.02.19-1.18.34-2.5.39-3.94.03-.78.03-1.53,0-2.23,2.48.52,4.97,1.04,7.45,1.57-.04,1.42-.11,2.89-.23,4.41-.02.22-.04.43-.05.64,0,.1,0,.2-.01.31,0,.11-.12,1.43-.24,2.32-.23,1.75-.73,5.46-2.3,7.54-.03.09-.06.17-.1.26-2,4.66-15.01,2.98-16.41,2.79-5.54-.72-9.83-2.51-12.45-3.82v.03c-.18.21-1.66,2.06-3.5,1.79-.24-.04-.53-.11-.85-.28-.16-.09-.61-.37-.85-.94-.24-.59-.1-1.12-.06-1.25Z"/>
        <path className="cls-11" d="m320.14,987.98c3.99,2.03,4.51,8.18,4.64,9.61.03.39.16,2.05-.34,3.98-.33,1.27-.69,1.76-.85,1.95-.79.96-1.84,1.17-2.29,1.26-.33.07-1.73.3-3.23-.55-1.41-.79-2.11-2.09-2.49-2.81-2.19-4.17-2.64-12.19.71-13.83,1.56-.77,3.36.15,3.85.4Z"/>
        <path className="cls-11" d="m305.93,987.84c3.99,2.03,4.51,8.18,4.64,9.61.03.39.16,2.05-.34,3.98-.33,1.27-.69,1.76-.85,1.95-.79.96-1.84,1.17-2.29,1.26-.33.07-1.73.3-3.23-.55-1.41-.79-2.11-2.09-2.49-2.81-2.19-4.17-2.64-12.19.71-13.83,1.56-.77,3.36.15,3.85.4Z"/>
      </g>
      <path className="cls-69" d="m311.66,984.75c0,2.71-7.36,2.71-7.36,0v-7.36h7.36v7.36Z"/>
      <path className="cls-69" d="m311.35,982.37c-.22.06-.45.11-.67.16-6.22,1.28-12.11-3.39-12.55-9.73l-.83-10.23c0-6.09,4.84-10.6,10.94-10.7.22,0,.45,0,.67,0,6.13,0,10.98,4.41,10.87,10.53-.08,4.11-.4,7.06-.69,10.61-.37,4.43-3.42,8.26-7.73,9.37Z"/>
      <path className="cls-81" d="m315.62,995.7c-1.66-.13-3.98-.26-5.64-.39.17-2.81.49-5.62,1.14-8.36.12-.52.4-1.01.42-1.55.12-2.97.13-2.95.12-3.12,1.99-.59,3.63-1.66,4.91-3.22.62-3.36.46-11.42.1-13.85-.76-5.14-2.93-8.66-4.07-8.12-2.21,1.03-4.39,2.02-6.66,2.86-2.72,1.01-4.7,3.53-5.64,6.2-1.25,3.54-1.33,7.8-.35,11.41.58,2.12,3.4,3.84,4.65,4.35,0,0,.01,3.57,0,4.52,0,0-2.3.97-2.7,2.17-.8,2.37-.36,5.37-.77,7.85-1.6-.28-3.23-.42-4.86-.41.28,0-.85-12.26-.97-13.36-.46-4.29-.81-8.53-1.07-12.85-.31-5.09-.12-10.46,3.16-14.65,2.83-3.63,7.45-5.15,11.93-5.02.9.03,1.79.26,2.58.7.69.37,1.33,1.45,1.98,1.8.06.03.11.05.17.07.75.25,1.54-.36,2.31-.26,1,.13,2.04.92,2.75,1.58,2.56,2.36,3.54,5.96,4.04,9.41.47,3.32-.76,24.71-.76,24.71,0,0-2.34-1.76-4.41.23,0,0-2.19,3.71-2.37,7.31Z"/>
      <g>
        <path className="cls-41" d="m313.65,968.14c0-.52-.42-.94-.94-.94s-.94.42-.94.94.42.94.94.94.94-.42.94-.94Z"/>
        <path className="cls-41" d="m304.49,968.14c0-.52-.42-.94-.94-.94s-.94.42-.94.94.42.94.94.94.94-.42.94-.94Z"/>
        <path className="cls-17" d="m311.19,975.79c-.84,1.52-2.76,2.08-4.28,1.24"/>
      </g>
      <rect className="cls-41" x="303.58" y="971.57" width=".58" height=".58" transform="translate(607.74 1943.73) rotate(180)"/>
      <rect className="cls-41" x="301.58" y="971.57" width=".58" height=".58" transform="translate(603.75 1943.73) rotate(180)"/>
      <rect className="cls-41" x="302.35" y="973.14" width=".58" height=".58" transform="translate(605.28 1946.86) rotate(180)"/>
      <rect className="cls-41" x="313.94" y="973.06" width=".58" height=".58" transform="translate(628.46 1946.71) rotate(180)"/>
      <rect className="cls-41" x="312.5" y="971.69" width=".58" height=".58" transform="translate(625.58 1943.96) rotate(180)"/>
      <path className="cls-53" d="m298.09,1020.94h26.85c1.13,0,2.04-.91,2.04-2.04h0c0-1.13-.91-2.04-2.04-2.04h-26.85c-1.13,0-2.04.91-2.04,2.04h0c0,1.13.91,2.04,2.04,2.04Z"/>
    </g>
    <g id="Jongetje-3" data-name="Jongetje">
      <path className="cls-53" d="m215.04,1042.53c-.95.99-1.95,1.94-3.08,2.71-1.03.71-1.55.69-2.64.36-1.06-.32-2.24.05-2.7,1.12-.36.84-.1,1.9.59,2.51,1.5,1.33,2.72-.1,4.19-.5,1.48-.4,2.95-1.05,4.13-2.05.93-.79,1.91-1.73,2.74-2.78-1.14-.41-2.25-.81-3.22-1.38Z"/>
      <path className="cls-65" d="m243.81,1016.93c.95,9.06-2.05,16.2-10.31,19.04-2.28-.83-19.8-12.81-21.38-15.17-.32-3.76.69-6.76,4.07-9.2,4.39-3.18,14.6-2.49,19.31-2.72,4.37-.66,7.87,3.79,8.32,8.06Z"/>
      <path className="cls-65" d="m218.91,1017.19c.19,1.05-1.43,14.49-3.04,14.58-1.6.09-3.1-3.96-3.57-8.36-.45-4.2-.5-8.06,2.12-9.3,1.43-.68,4.26,1.82,4.48,3.08Z"/>
      <path className="cls-41" d="m206.84,1062.35c.2-.2.29-.49.31-.77.06-.94-.65-1.8-1.49-2.21-.85-.42-1.82-.48-2.76-.54-1.11-.07-2.23-.14-3.33,0-1.1.14-2.2.51-3.05,1.23-.08.79.43,1.54,1.1,1.96.67.42,1.48.56,2.26.66,1.95.24,3.93.26,5.89.05.38-.04.8-.11,1.07-.38Z"/>
      <path className="cls-41" d="m198.5,1065.89c-.16-.23-.21-.53-.18-.81.09-.94.92-1.67,1.82-1.95s1.87-.19,2.81-.1c1.11.11,2.22.21,3.29.53,1.07.31,2.09.85,2.82,1.7-.04.79-.67,1.46-1.4,1.76-.73.31-1.54.32-2.34.29-1.97-.07-3.92-.36-5.82-.88-.37-.1-.77-.23-1-.55Z"/>
      <path className="cls-11" d="m236.82,1053.67s-.14,15.3-10.8,9.49c-7.57-4.12-9.6-4.66-9.6-4.66l-8.67,4.35-4.56-4.18,8.99-8.07s1.78-2.18,8.1-.17c5.71,1.81,16.54,3.24,16.54,3.24Z"/>
      <path className="cls-44" d="m237.31,1057.56s-.14,15.3-10.8,9.49c-7.57-4.12-9.6-4.66-9.6-4.66l-8.67,4.35-4.56-4.18,8.99-8.07s1.78-2.18,8.1-.17c5.71,1.81,16.54,3.24,16.54,3.24Z"/>
      <path className="cls-48" d="m218.64,1053.75c-.39-1.23-.38-.97-.84-2.87-.63-2.58-.65-2.53-.43-6.49,0-.13.01-.26-.06-.37-.07-.1-.19-.15-.3-.2-.65-.26-1.31-.52-1.96-.78-.12-.05-.26-.12-.27-.25,0-.08.03-.15.07-.21.5-.82,1-1.63,1.49-2.45.7-1.15,1.42-2.32,2.48-3.14.81-.64,1.77-1.02,2.74-1.35.87-.29,1.31-.76,2.09-1.23,1.53-.92,3.32-1.34,5.09-1.02,1.87.34,2.81,1.53,4.27,2.49,1.66,1.09,4.17,1.49,6.11,1.58-.02,2.16-.3,4.32-.84,6.42q-.99-.11-1.99-.22c-.1-.01,1.41,7.17,1.05,15.48"/>
      <path className="cls-53" d="m239.07,1038.21c.08,0,.15,0,.23-.02,1.59-.23,4.62-1.05,4.72-3.17,0-.15,0-.3-.01-.46-.02-.24-.03-.49.03-.72.11-.43.48-.77.88-.93.85-.33,2.07-.23,2.74.41.65.62.87,1.66.57,2.52-.27.78-.83,1.16-1.49,1.54-.67.38-1.08.81-1.69,1.34-1.27,1.1-2.79,1.87-4.34,2.45-.65.25-1.35.51-1.99.64l.37-3.61Z"/>
      <ellipse className="cls-53" cx="226.94" cy="1026" rx="12.83" ry="13.46"/>
      <path className="cls-108" d="m231.04,1025.33c0-.53-.43-.96-.96-.96s-.96.43-.96.96.43.96.96.96.96-.43.96-.96Z"/>
      <path className="cls-108" d="m221.69,1025.33c0-.53-.43-.96-.96-.96s-.96.43-.96.96.43.96.96.96.96-.43.96-.96Z"/>
      <path className="cls-16" d="m228.52,1033.14c-.86,1.56-2.81,2.12-4.37,1.26"/>
      <path className="cls-65" d="m235.08,1023.71c-.06,4.86,2.65,7.5,4.7,7.73,1.57.18,1.19-5.92.93-10.77-.29-5.56-1.57-7.48-5.2-8.15-.07,1.22-.36,5.49-.43,11.19Z"/>
      <path className="cls-65" d="m234.39,1019.45c.44.92,1.52,2.2,2.51,4.08-.06-.94.06-9.18,0-10.59-.72-.06-3.57-.34-4.79-.42-.07,0-2.29,4.29-.82,3.52,2.93-1.53,2.34,1.8,3.11,3.42Z"/>
      <path className="cls-65" d="m212.86,1011.25c6.1-4.82,15.74-7.73,21.02-2.04-1.17,1.49-6.12,3.08-6.58,3.3-6.4-2.81-11.99-1.3-14.44-1.27Z"/>
      <path className="cls-65" d="m209.6,1020.66c8.6,3.81,24.95-4.72,24.38-6.71-.57-1.99-9.43-4.97-15.5-3.23-6.06,1.74-8.75,9.07-8.88,9.94Z"/>
      <path className="cls-53" d="m236.68,1028.24c-.5,1.88,0,3.65,1.11,3.95,1.12.3,2.43-.98,2.94-2.87.5-1.88,0-3.65-1.11-3.95-1.12-.3-2.43.98-2.94,2.87Z"/>
    </g>
    <text transform="translate(200.85 1319.68)"><tspan className="cls-5"><tspan x="0" y="0">-3,5%</tspan></tspan><tspan className="cls-115"><tspan x="-28.55" y="16">daling vaccinatie-</tspan></tspan><tspan className="cls-115"><tspan x="-16.56" y="32">graad (zonder </tspan></tspan><tspan className="cls-115"><tspan x="-7.64" y="48">MenACWY)</tspan></tspan></text>
    <text transform="translate(41.68 1257.45)"><tspan className="cls-5"><tspan className="number" x="0" y="0">52</tspan>
                      <tspan>.</tspan>
                      <tspan>000</tspan></tspan><tspan className="cls-115"><tspan x="-14.04" y="16">uitnodigingen </tspan></tspan><tspan className="cls-115"><tspan x="-6.64" y="32">HPV 10 jaar</tspan></tspan></text>
    <text transform="translate(66.47 1325.15)"><tspan className="cls-2"><tspan className="number" x="0" y="0">79</tspan><tspan>.</tspan><tspan  className="number">318</tspan></tspan><tspan className="cls-115"><tspan x="15.8" y="16">kinderen </tspan></tspan><tspan className="cls-115"><tspan x="3.46" y="32">gevaccineerd </tspan></tspan><tspan className="cls-115"><tspan x="-.14" y="48">op CJG-locatie</tspan></tspan></text>
    <text transform="translate(232.15 1257.45)"><tspan className="cls-5"><tspan className="number" x="0" y="0">9</tspan><tsapn>jaar</tsapn></tspan><tspan className="cls-115"><tspan x="-12.71" y="16">pilot-consult </tspan></tspan><tspan className="cls-115"><tspan x="-13.04" y="32">inclusief prik</tspan></tspan></text>
    <g id="Prik-2" data-name="Prik">
      <circle className="cls-41" cx="169.01" cy="1258.85" r="40.09"/>
      <line className="cls-27" x1="183.51" y1="1235.38" x2="192.85" y2="1244.59"/>
      <line className="cls-27" x1="188" y1="1240.71" x2="180.36" y2="1247.98"/>
      <line className="cls-27" x1="172.24" y1="1237.8" x2="191.03" y2="1256.11"/>
      <path className="cls-27" d="m176.48,1242.53s-3.43,3.25-7.81,7.46c-7.55,7.25-17.92,17.34-18.38,18.49-.73,1.82-.24,3.88-.24,3.88l5.58,6.06s3.15.48,4.61-.24,26.19-26.07,26.19-26.07l-9.94-9.58Z"/>
      <line className="cls-27" x1="173.21" y1="1254.65" x2="168.67" y2="1249.99"/>
      <line className="cls-27" x1="169.45" y1="1258.17" x2="164.91" y2="1253.5"/>
      <line className="cls-27" x1="152.72" y1="1274.78" x2="144.72" y2="1282.54"/>
    </g>
    <text className="cls-7" transform="translate(96.33 1164.66)"><tspan x="0" y="0">Huggy </tspan><tspan className="cls-112" x="-2.05" y="16">W</tspan><tspan x="11.24" y="16">uggy</tspan><tspan x="-6.94" y="32">populair</tspan></text>
    <g id="Huggy_Wuggy" data-name="Huggy Wuggy">
      <path className="cls-11" d="m64.54,1207.32s-3.05,2.61-2.39,4.8,3.3,2.04,4.16,1.46-.74-4.6,1.24-3.07c.76.58,2.26-1.2-1.58-3.44l-1.43.25Z"/>
      <path className="cls-11" d="m78.66,1199.8s3.83,1.21,4.09,3.47-2.24,3.17-3.25,2.97c-1.02-.2-1.76-4.9-2.02-2.41-.11,1.04-2.73-.18-.23-3.7l1.41-.33Z"/>
      <path className="cls-11" d="m51.65,1189.09s-2.21,2.32-2,5.43,3.57,3.08,3.83-.04,1.38.91,1.81-.26-1.81-4.84-1.81-4.84l-1.84-.29Z"/>
      <path className="cls-11" d="m84.06,1177.28s3.15.57,4.81,3.21c1.66,2.64-1.07,4.59-3.12,2.23s-.58,1.55-1.62.86-1.39-4.98-1.39-4.98l1.31-1.31Z"/>
      <path className="cls-48" d="m59.79,1155.72s-4.99-5.53-5.81,1.9c-.82,7.44-1.44,15.76,4.48,15.43,5.92-.33,15.69-2.78,1.33-17.34Z"/>
      <path className="cls-48" d="m47.91,1148.91c-1.43.17-3.28-.11-2.93,2.13.4,2.52,7.43,7.84,11.81,7.24,4.38-.61,9.75-16.21,3.96-13.78-6.22,2.61-6.83,3.69-12.85,4.42Z"/>
      <circle className="cls-41" cx="52.58" cy="1150.79" r="1.09"/>
      <circle className="cls-41" cx="56.9" cy="1149.41" r="1.09"/>
      <path className="cls-48" d="m59.79,1155.72s10.37,2.19,16.95,10.45c6.58,8.26,12.29,7.22,7.62,13.28-4.67,6.06-7.06-14.8-22.49-21.51l-2.08-2.21Z"/>
      <path className="cls-48" d="m54.91,1156.67s-6.15,8.64-5.4,19.17-4.16,13.63,3.38,14.93c7.54,1.29-4.83-15.68,1.98-31.06l.03-3.04Z"/>
      <path className="cls-48" d="m66,1169.46s3.59,8.27,9.08,14.15,7.03,16.86,3.74,17.59-1.85-4.19-2.03-8.59c-.18-4.39-6.97-8.22-13.51-21.1l2.72-2.05Z"/>
      <path className="cls-48" d="m57.99,1171.51s2.06,28.97,4.73,34.76c1.33,2.89,1.95,2.22,3.26,1.66,1.12-.48-.9-5.51-1.15-6.66-.5-2.28-4.47-30.74-4.47-30.74l-2.36.98Z"/>
      <path className="cls-41" d="m51.59,1153.45c-.85-.11-.99-.25.36.47s3.04,2.39,4.44,1.95c1.41-.44,2.08-2.19,2.47-3.04.67-1.47,1.43-3.02.26-1.25-1.3,1.96-3.48-.85-4.41.95-.5.97-1.93,1.07-3.12.92Z"/>
      <polygon className="cls-57" points="59.13 1150.97 58.58 1151.46 56.49 1151.34 54.71 1151.46 53.11 1152.75 51.07 1153.33 50.77 1153.37 52.02 1154.05 52.12 1153.43 52.82 1154.15 52.8 1153.43 53.46 1153.86 53.29 1153.37 54.11 1153.8 53.93 1153.14 54.68 1153.74 54.4 1152.72 55.23 1153.53 54.52 1152.05 55.48 1153.14 55.11 1151.79 55.85 1153 55.67 1151.72 56.2 1152.62 56.2 1151.72 56.84 1152.75 56.84 1151.93 57.54 1152.82 57.46 1151.88 58.09 1152.81 58.19 1151.96 58.58 1152.5 58.81 1151.57 59.14 1152.22 59.14 1151.15 59.38 1151.7 59.96 1150.72 59.13 1150.97"/>
      <polygon className="cls-57" points="53.19 1155.33 53.01 1154.08 53.59 1155.08 53.6 1154.28 54.29 1155.49 54.24 1154.7 54.93 1155.76 54.93 1154.93 55.73 1155.85 55.52 1154.64 56.47 1155.67 56.14 1154.53 56.97 1155.38 56.67 1154.28 57.32 1155.01 57.32 1153.68 57.94 1154.41 57.77 1153.3 58.3 1153.78 58.25 1152.53 58.62 1153.24 58.83 1152.15 59.1 1152.73 58.59 1154.59 57.16 1156.13 56.01 1156.56 53.19 1155.33"/>
      <path className="cls-94" d="m59.66,1150.36c-1.66,2.57-3.65-1.19-5.49,1.15-.86,1.08-1.99,1.62-3.76,1.19-1.04-.25-2.2.07-.14,1.08,2.06,1,4.5,3.72,6.67,3.08,2.16-.63,2.52-3.51,3.13-4.72,1.05-2.07,1.27-4.35-.39-1.78Zm-.8,2.47c-.39.85-1.06,2.6-2.47,3.04-1.41.44-3.1-1.23-4.44-1.95-1.35-.72-1.21-.58-.36-.47,1.19.15,2.62.05,3.12-.92.93-1.81,3.11,1,4.41-.95,1.17-1.77.41-.21-.26,1.25Z"/>
    </g>
    <text transform="translate(21.9 898.84)"><tspan className="cls-3">
      <tspan className="number" x="0" y="0">284</tspan>
      <tspan>.</tspan>
      <tspan className="number">234</tspan>
      </tspan><tspan className="cls-115"><tspan x="16.32" y="16">contact-</tspan></tspan><tspan className="cls-115"><tspan x="8.63" y="32">momenten</tspan></tspan></text>
    <g id="Contact-2" data-name="Contact">
      <path className="cls-83" d="m79.65,840.2h-15.73c-2.4,0-4.36,1.96-4.36,4.36v.26h5.22c4.15,0,7.52,3.37,7.52,7.52v7.23l6.99,3.94c.41.21.88-.17.76-.61l-.81-3.59h.4c2.4,0,4.36-1.96,4.36-4.36v-10.4c0-2.4-1.96-4.36-4.36-4.36Z"/>
      <path className="cls-41" d="m69.98,851.54c-.03-.17-.06-.34-.11-.51-.04-.17-.09-.33-.15-.49-.06-.16-.13-.32-.2-.47-.07-.15-.16-.3-.24-.45-.13-.22-.28-.42-.44-.62-.11-.13-.22-.25-.34-.37-.95-.95-2.26-1.54-3.71-1.54h-18.96c-1.44,0-2.76.59-3.71,1.54-.12.12-.23.24-.34.37-.16.19-.31.4-.44.62-.09.14-.17.29-.24.45-.07.15-.14.31-.2.47-.06.16-.11.32-.15.49-.04.17-.08.34-.11.51-.04.26-.06.53-.06.8v12.53c0,.27.02.54.06.8.03.17.06.34.11.51.07.25.15.5.25.73.17.39.38.76.63,1.1.1.13.21.26.32.39.11.12.23.24.36.36.93.85,2.17,1.37,3.53,1.37h.48l-.98,4.33s-.02.1-.02.14c0,0,0,.02,0,.03,0,.04,0,.09.01.13,0,0,0,.01,0,.02,0,.04.02.08.04.11,0,0,0,.01,0,.02.02.04.04.07.07.11,0,0,.01.01.02.02.03.03.06.06.09.09,0,0,0,0,0,0,.03.03.07.05.1.07,0,0,.02,0,.02.01.04.02.08.03.12.04,0,0,.02,0,.02,0,.04,0,.08.01.12.01,0,0,.01,0,.02,0,.04,0,.08,0,.13-.02,0,0,.02,0,.03,0,.04-.01.09-.03.13-.05l8.98-5.07h9.56c1.35,0,2.59-.52,3.53-1.37.12-.11.24-.23.36-.36.11-.12.22-.25.32-.39.25-.34.47-.71.63-1.1.1-.24.18-.48.25-.73.04-.17.08-.34.11-.51.04-.26.06-.53.06-.8v-12.53c0-.27-.02-.54-.06-.8Z"/>
    </g>
    <text transform="translate(155.95 967.69)"><tspan className="cls-2">
      <tspan className="number"  x="0" y="0">93</tspan><tspan>,</tspan><tspan className="number">8</tspan><tspan>%</tspan>
      
      </tspan><tspan className="cls-115"><tspan x="-11.62" y="16">bereikte kinderen </tspan></tspan><tspan className="cls-115"><tspan x="20.9" y="32">(0-18)</tspan></tspan></text>
    <g id="Twee_kinderen" data-name="Twee kinderen">
      <path className="cls-47" d="m111.83,961.73c-.39-.14-.68-.46-.88-.82-.66-1.2-.26-2.77.6-3.84.86-1.07,2.1-1.76,3.3-2.41,1.42-.78,2.85-1.56,4.39-2.05,1.54-.49,3.22-.68,4.78-.26.59.99.38,2.3-.24,3.27-.63.97-1.6,1.65-2.57,2.26-2.42,1.52-5.02,2.77-7.72,3.7-.53.18-1.12.36-1.65.16Z"/>
      <path className="cls-47" d="m146.96,961.73c.39-.14.68-.46.88-.82.66-1.2.26-2.77-.6-3.84-.86-1.07-2.1-1.76-3.3-2.41-1.42-.78-2.85-1.56-4.39-2.05-1.54-.49-3.22-.68-4.78-.26-.6.99-.38,2.3.24,3.27.63.97,1.6,1.65,2.57,2.26,2.42,1.52,5.02,2.77,7.72,3.7.53.18,1.12.36,1.65.16Z"/>
      <path className="cls-47" d="m117.07,959c-1.88.97-3.97,1.99-6.06,2.01.2.32.47.6.82.73.53.19,1.12.02,1.65-.16,2.7-.93,5.3-2.17,7.72-3.7.97-.61,1.95-1.3,2.57-2.26.44-.68.67-1.52.56-2.31-1.73,2.58-4.56,4.29-7.27,5.7Z"/>
      <path className="cls-47" d="m145.7,960.57c-2.13-.61-4.09-1.79-6.01-2.88-1.75-.99-4.1-2.22-5.27-4,0,.67.22,1.35.59,1.92.63.97,1.6,1.65,2.57,2.26,2.42,1.52,5.02,2.77,7.72,3.7.53.18,1.12.36,1.65.16.39-.14.68-.46.88-.82.05-.09.08-.18.12-.27-.68.17-1.45.17-2.25-.06Z"/>
      <polygon className="cls-41" points="139.68 894.44 137.92 881.6 111.49 880.22 111.49 891.68 117.6 953.08 125.43 953.08 123.16 894.39 127.73 893.14 133.7 953.08 140.83 953.08 139.68 894.44"/>
      <polygon className="cls-81" points="136.35 837.72 125.24 834.73 115.34 837.09 115.79 845.82 136.35 845.82 136.35 837.72"/>
      <path className="cls-81" d="m140.91,880.83c.35-.53.74-1.02,1.16-1.48,1.14-1.24,2.88-2.11,4.62-2.5l-1.25-10.38-.48-26.4-11.17-2.98c-.57,4.02-3.3,7.79-7.73,7.46-3.17-.23-5.39-2.33-7-4.92-.64-1.02-1.11-2.07-1.36-3.11l-10.24,2.47-12.55,25.22,11.59,16,4.22-4.12-7.62-12.5,8.88-13.58-.48,35.78,27.41,2.61.41-14.1,1.6,6.51Z"/>
      <path className="cls-53" d="m119.51,880.25c-.22.76-.82,1.44-1.53,1.83-3.34,1.79-8.29,1.45-10-2.41-.06-.13-.11-.27-.08-.4.02-.11.08-.2.15-.28.65-.9,1.3-1.8,1.95-2.7.25-.35.51-.7.84-.97,2.03-1.61,8.18,1.59,8.71,3.89.08.35.06.71-.03,1.04Z"/>
      <g>
        <path className="cls-41" d="m140.88,831.82l3.58-10.23c1.48-4.23.43-8.82-2.8-12.22l-8.43-5.08-18.52-.93-6.68,6.5c-2.14,3.04-2.78,6.72-1.76,10.18,0,0,2.97,11.36,5.57,13.88s12.78,3.76,12.78,3.76l9.82-21.47,6.46,15.62Z"/>
        <path className="cls-53" d="m120.1,838.47c0,3.15,11.61,3.15,11.61,0v-8.16h-11.61v8.16Z"/>
        <path className="cls-53" d="m141.56,820.48c0,9.45-7.31,17.12-16.32,17.12s-16.32-7.66-16.32-17.12,7.31-17.12,16.32-17.12,16.32,7.66,16.32,17.12Z"/>
        <path className="cls-41" d="m117.89,821.12c0-.67.55-1.22,1.22-1.22s1.22.55,1.22,1.22-.55,1.22-1.22,1.22-1.22-.55-1.22-1.22Z"/>
        <path className="cls-41" d="m129.78,821.12c0-.67.55-1.22,1.22-1.22s1.22.55,1.22,1.22-.55,1.22-1.22,1.22-1.22-.55-1.22-1.22Z"/>
        <path className="cls-32" d="m120.78,830.5c1.26,2.28,4.68,2.81,7.64,1.17"/>
        <path className="cls-41" d="m104.4,811.16c.32-2.12,2.72-3.32,4.7-3.79.09-2.52,5.03-4.11,6.24-4.31,6.76-1.13,13.79.61,13.79.61,10.05.59,14.39,1.64,13.85,8.85h0s-2.51-.13-2.51-.13l2.72,1.61-1.62,1.72c-2.22,1.65-6.16,2.47-8.99,1.86l-20.31-4.37s-1.46,3.91-2.14,5.3c-.63,1.3-2.82,5.18-2.82,5.18,0,0-3.45-8.95-2.91-12.52Z"/>
        <path className="cls-41" d="m107.64,824.75s.94,2.48.23,5.6c-.05.23.22.4.4.25l1.92-1.58-2.55-4.26Z"/>
        <path className="cls-41" d="m109.7,830.32s.92,3.04.54,4.44c-.03.11.04.22.15.23.44.03,1.5,0,2.94-.72l-3.64-3.95Z"/>
        <path className="cls-41" d="m111.84,812.66c1.97,3.56,6.23,5.69,10.26,5.14-1.06-.76-1.89-1.83-2.36-3.04"/>
      </g>
      <g>
        <path className="cls-41" d="m137.61,864.41c-.82-.46-1.98-.44-2.86-.01-1.3.63-2.21,1.84-3.26,2.84-1.05,1-2.49,1.84-3.88,1.46.74-1.1-.24-2.78-1.56-2.95s-2.58.88-3,2.14c-.77,2.31.91,4.88,3.13,5.86,2.22.98,4.79.74,7.17.25-1.3,2.11-3.06,3.94-5.11,5.32-1.04-1.29-2.58-2.72-4.11-2.08-1.56.65-1.59,2.86-1.14,4.49.45,1.62,1.26,3.34,2.83,3.96.9.36,1.91.28,2.86.09,2.66-.55,5.12-2.04,6.82-4.16,3.66,1.39,7.68,2.58,11.44,1.49,3.15-.91,5.69-3.34,7.4-6.14,3.23-5.27,5.54-11.57,3.92-17.69-1.22-4.64-4.67-9.84-10.02-9.84-3.36,0-6.13,2.22-7.9,4.92-.66,1.01-3.25,11.03-1.87,11.03-.17-.44-.48-.76-.87-.97Z"/>
        <path className="cls-57" d="m148.51,854.08c0,1.84,1.49,3.33,3.33,3.33s3.33-1.49,3.33-3.33-1.49-3.33-3.33-3.33-3.33,1.49-3.33,3.33Z"/>
        <path className="cls-57" d="m142.42,854.08c0,1.84,1.49,3.33,3.33,3.33s3.33-1.49,3.33-3.33-1.49-3.33-3.33-3.33-3.33,1.49-3.33,3.33Z"/>
        <path className="cls-43" d="m157.99,855.51c-.49-.65-1.15-1.16-1.95-1.27.34.05-.42,5.81-.51,6.35-.38,2.11-.83,4.25-1.7,6.21-.58,1.32-1.1,1.62-2.38,1.96-1.23.32-2.17,1.41-1.97,2.75.16,1.05,1.06,1.93,2.1,2.11,2.28.39,2.61-1.75,3.81-3.04,1.21-1.3,2.26-2.83,2.81-4.52.98-3.01,1.81-6.95.2-9.91-.12-.22-.26-.44-.42-.64Z"/>
        <path className="cls-43" d="m148.76,868.57c-.17.04-.33.08-.5.1-2.34.29-3.81-3-4.36-4.77-.6-1.96-.74-4.04-.76-6.09-.01-1.25,0-2.55-.59-3.65-.17-.32-.42-.63-.78-.73-.41-.11-.83.11-1.15.39-1.13.97-1.45,2.58-1.54,4.07-.18,2.97.32,5.59,1.79,8.18.93,1.65,2.06,3.24,3.5,4.47.69.6,1.25.99,1.79,1.67.54.68,1.06,1.25,1.97,1.41,1.01.18,2.11-.28,2.67-1.13.58-.89.47-2.29-.05-3.2-.25-.43-.69-.78-1.19-.83-.27-.02-.53.04-.8.1Z"/>
        <g>
          <path className="cls-43" d="m134.26,841.94c.64,2.39,0,4.64-1.42,5.02-1.42.38-3.09-1.25-3.73-3.65-.64-2.39,0-4.64,1.42-5.02,1.42-.38,3.09,1.25,3.73,3.65Z"/>
          <path className="cls-43" d="m131.93,837.67c0,10,7.8,18.1,17.43,18.1s17.43-8.11,17.43-18.1-7.8-18.1-17.43-18.1-17.43,8.11-17.43,18.1Z"/>
          <path className="cls-41" d="m151.07,818.64c2.84-1.82,5.52-2.07,8.57-.5,2.93,1.51,5.91,4.27,6.98,7.45.65,1.93.92,3.9.91,5.94,0,1-.07,1.99-.16,2.98-.07.83-.44,1.86-.59,3.15-.53-3.28-2.72-6.24-5.29-8.35,3.06,4.25,5.38,9.36,4.94,14.59-.34,4.04-2.31,7.78-4.78,10.99.22-.28,3.88-6.53,2.06-13.1-3.13-11.26-13.72-16.14-13.71-16.17-1.26,2.08-3.56,3.23-5.61,4.52-5.64,3.56-9.93,9.09-11.2,15.72-.6-1.75-.94-3.59-1.28-5.41-.29-1.6-.59-3.19-.88-4.79-.42-2.26-.31-5,.43-7.18.87-2.56,2.52-4.8,4.44-6.7,2-1.98,4.37-3.68,7.09-4.42,2.72-.74,5.81-.42,8.06,1.28Z"/>
          <path className="cls-41" d="m144,813.11c-2.27-.88-2.3-.11-4.72.2,4.14.73,8.17,2.05,11.95,3.89.53-4.92-1.29-10.04-4.79-13.53-1.77.57-3.49,1.37-4.87,2.61-1.38,1.24-2.41,2.95-2.55,4.8,2.72.26,2.89.29,4.99,2.03Z"/>
          <path className="cls-41" d="m153.04,818.91c.17-.12.31-.27.42-.44.66-.97-.4-2.73-1.25-3.32-.75-.51-1.81-.58-2.57-.09-.25.16-.46.36-.65.58-.44.49-.83,1.09-.79,1.75.13,2.02,3.36,2.62,4.84,1.51Z"/>
          <path className="cls-32" d="m145.98,842.45c-.98-1.49-3.15-1.8-4.84-.69"/>
          <path className="cls-32" d="m157.31,842.45c-.98-1.49-3.15-1.8-4.84-.69"/>
        </g>
      </g>
      <path className="cls-53" d="m143.47,879.83c1.38-1.44,3.78-2.24,5.55-2.6,2.01-.4,1.41.92.92,2.3-.83,2.32-2.21,5.27-4.5,6.4-.26.13-.54.24-.83.28-.65.09-2.3-.25-2.59-.91-.13-.29-.14-.61-.13-.93.03-1.15.28-2.29.74-3.34.2-.45.48-.85.83-1.21Z"/>
    </g>
    <text transform="translate(137.04 1045.79)"><tspan className="cls-2"><tspan className="number" x="0" y="0">{'43'}</tspan></tspan><tspan className="cls-115"><tspan x="-10.61" y="16">jongeren </tspan></tspan><tspan className="cls-115"><tspan x="-20.23" y="32">halen gratis </tspan></tspan><tspan className="cls-115"><tspan x="-25.85" y="48">SOA-thuistest</tspan></tspan></text>
    <g id="Meisje">
      <path className="cls-48" d="m89.07,1054.21l-3.92-20.29-20.11-1.02-2.42,19.49c-.2,24.19,4.06,47.35,4.27,70.38h4.13l5.28-66.51-3.06,64.99h4.97c2.18-23.72,8.87-44.2,10.86-67.04Z"/>
      <polygon className="cls-104" points="85.18 1035.82 87.29 1035.93 87.54 1040.96 85.18 1040.74 85.18 1035.82"/>
      <polygon className="cls-104" points="80.34 1035.56 83.86 1035.75 83.86 1040.62 80.34 1040.29 80.34 1035.56"/>
      <polygon className="cls-104" points="74.85 1035.27 74.85 1039.78 70.53 1039.38 70.53 1035.04 74.85 1035.27"/>
      <polygon className="cls-104" points="69.03 1039.24 65.4 1038.9 66.55 1034.83 69.03 1034.96 69.03 1039.24"/>
      <path className="cls-41" d="m78.1,1120.58c.83.49,1.03,1.4,2.17,2.4.95.83,1.85,1.72,2.71,2.64.73.79,1.46,1.68,1.63,2.76.24,1.47-.78,3.04-2.19,3.38-1.31.31-2.64-.35-3.73-1.16-2.85-2.12-5.96-6.57-5.38-10.42,0-.04,3.44.01,3.7.05.47.06.81.18,1.09.34Z"/>
      <path className="cls-41" d="m66.77,1120.19h4.92c.37,0-.94,4.84-.99,4.97-1.02,2.61-2.42,5.17-4.72,6.79-.92.65-2.02,1.14-3.12.99-1.1-.14-2.16-1.1-2.14-2.25.01-.69.38-1.31.74-1.88,1.39-2.17,5.38-5.39,5.32-8.17,0-.16-.01-.31-.02-.46Z"/>
      <path className="cls-84" d="m94.91,1063.19c0-.17-.29-6.03-.4-6.16l-1.47-21.76-3.18-27.45c-.5-2.3-2.25-3.48-4.49-3.98l-11.15-2.45-9.43,1.25c-3.02.4-5.69,2.21-7.25,4.91l-2.07,25.35-4,23.7.59,9.6c1.54,4.59,5.8,1.06,5.8,1.06l-3.32-9.36,7.03-22.64,1.84-16.49,3.67,14.13,20.11,1.02.1,3.72,2.62,18.2.1,9.65c.2,3.04,4.97.09,4.88-2.3Z"/>
      <path className="cls-54" d="m57.55,1007.55c1.56-2.7,4.23-4.51,7.25-4.91l9.43-1.25,11.15,2.45c2.23.49,3.99,1.67,4.49,3.98l3.16,17.4-6.11,2.09,2.33,24.08-25.95-3.79.13-28.84-.65,8.85-8.18-.3,2.95-19.77Z"/>
      <rect className="cls-54" x="62.63" y="1004.82" width="26.62" height="6.17"/>
      <path className="cls-84" d="m70.37,1008.98c0,1.51,8.78,1.51,8.78,0v-5.72h-8.78v5.72Z"/>
      <path className="cls-84" d="m70.22,1004.6c.32.09.63.16.95.23,8.83,1.87,17.18-4.98,17.81-14.27l1.09-13.77c0-8.94-6.78-16.47-15.43-16.62-.32,0-.63,0-.95,0-8.7.01-15.59,6.14-15.43,15.13.11,6.03.57,10.36.98,15.57.52,6.5,4.86,12.12,10.97,13.75Z"/>
      <path className="cls-11" d="m66.62,960.98h0c-5.8-1.96-11.29,3.53-10.23,24.29v19.22c0,7.01,7.2,7.28,14.27,7.15l-.15-7.11c-6.71-2.66-9.74-8.55-9.05-17.9l8.91-15.15,15.84,8.19,1.01,16.98c-2.67,4.23-5.69,6.34-8.04,7.54l-.04,7.63,7.85,1.78-.37,14.23,3.53-.59c8.94-.21,3.15-29.12,1.94-40.84-3.07-29.67-10.7-28.13-19.02-27.89-2.6.07-5,.27-6.47,2.48Z"/>
      <path className="cls-41" d="m67.34,984.64c0-.67.52-1.21,1.17-1.21s1.17.54,1.17,1.21-.52,1.21-1.17,1.21-1.17-.54-1.17-1.21Z"/>
      <path className="cls-41" d="m78.75,984.64c0-.67.52-1.21,1.17-1.21s1.17.54,1.17,1.21-.52,1.21-1.17,1.21-1.17-.54-1.17-1.21Z"/>
      <path className="cls-23" d="m72.08,995.28c.88,1.05,2.62.98,3.9-.16"/>
    </g>
    <text transform="translate(169.15 884.38)"><tspan className="cls-4"><tspan x="0" y="0" className="number">275</tspan><tspan>.</tspan><tspan  className="number">364</tspan></tspan><tspan className="cls-115"><tspan x="36.42" y="16">kinderen (0-18) </tspan></tspan><tspan className="cls-115"><tspan x="29.34" y="32">in ons werkgebied</tspan></tspan></text>
    <g id="Titel-8" data-name="Titel">
      <rect id="Achtergrond_blokje" data-name="Achtergrond blokje" className="cls-57" x="194.43" y="793.31" width="117.03" height="33" rx="2.5" ry="2.5"/>
      <text className="cls-55" transform="translate(205.67 816.19)"><tspan x="0" y="0">Kinderen</tspan></text>
    </g>
            </g>
        </>
    )
}

