import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";

export default function Scholen(props) {
  const isDesctop = props.isDesctop;
  const desctopScreen = useRef();
  const mobileScreen = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      gsap.timeline({repeat:-1})
      .to('#Corona',
        { duration: 3,  transformOrigin: '50% 50%',  rotation:"-=360", ease:'none'}   
      );
      const numbers = self.selector('.number');
      numbers.forEach((number) => {
        gsap.from(number, {
          textContent: 0,
          duration: 2,
          snap: { textContent: 1 },
          scrollTrigger: {
            trigger: number,
          },
        });
      });
    }, desctopScreen);
    return () => ctx.revert(); // <- Cleanup!     
  }, [])

  useLayoutEffect(() => {
    gsap.timeline({repeat:-1})
    .to('#Corona',
        { duration: 3,  transformOrigin: '50% 50%',  rotation:"-=360", ease:'none'}   
      );
    const ctxMobile = gsap.context((self) => {
      const numbers = self.selector('.number');
      numbers.forEach((number) => {
        gsap.from(number, {
          textContent: 0,
          duration: 2,
          snap: { textContent: 1 },
          scrollTrigger: {
            trigger: number,
          },
        });
      });
    }, mobileScreen);
    return () => ctxMobile.revert(); // <- Cleanup!     
  }, [])

  if (isDesctop) {
    return (
        <>
          <g ref={desctopScreen} id="Zorg">
      <path id="Ondergrond-4" data-name="Ondergrond" className="cls-84" d="m893.22,2447.92s0,0,0,0h-337.47s0,0,0,0c-19.08,0-34.55,15.47-34.55,34.55s15.47,34.55,34.55,34.55l-204.79-.24h0c-19.08,0-34.55,15.47-34.55,34.55s15.47,34.55,34.55,34.55h85.35c12.34,0,22.34,10,22.34,22.34s-10,22.34-22.34,22.34l-38.25.15h0c-12.34,0-22.34,10-22.34,22.34s10,22.34,22.34,22.34h0v.08h18.36c-12.34,0-22.34,10-22.34,22.34s10,22.34,22.34,22.34h255.42c12.34,0,22.34-10,22.34-22.34s-10-22.34-22.34-22.34h137.52c12.34,0,22.34-10,22.34-22.34s-10-22.34-22.34-22.34c0,0,0,0,0,0h-53.26c-19.08,0-34.55-15.47-34.55-34.55s15.47-34.55,34.55-34.55h118.6c19.08,0,34.55-15.47,34.55-34.55s-15.47-34.55-34.55-34.55h18.56c12.34,0,22.34-10,22.34-22.34s-10-22.34-22.34-22.34Z"/>
      <g id="Ziekenhuis">
        <polygon className="cls-60" points="785.48 2478.73 785.48 2350.68 628.43 2350.68 628.43 2406.74 628.43 2478.73 785.48 2478.73"/>
        <g>
          <polygon className="cls-62" points="761.32 2324.05 763.69 2345.3 650.22 2345.3 652.6 2324.05 761.32 2324.05"/>
          <polygon className="cls-60" points="779.84 2335 782.96 2352.16 630.95 2352.16 634.08 2335 779.84 2335"/>
          <polygon className="cls-62" points="785.48 2362.44 628.43 2362.44 624.02 2345.28 789.89 2345.28 785.48 2362.44"/>
        </g>
        <g>
          <g>
            <g>
              <rect className="cls-60" x="664.19" y="2368.7" width="18.56" height="21.04"/>
              <rect className="cls-93" x="664.19" y="2368.7" width="18.56" height="21.04"/>
            </g>
            <g>
              <rect className="cls-60" x="641.77" y="2368.7" width="18.56" height="21.04"/>
              <rect className="cls-93" x="641.77" y="2368.7" width="18.56" height="21.04"/>
            </g>
            <g>
              <rect className="cls-60" x="708.88" y="2368.7" width="18.57" height="21.04"/>
              <rect className="cls-93" x="708.88" y="2368.7" width="18.57" height="21.04"/>
            </g>
            <g>
              <rect className="cls-60" x="686.46" y="2368.7" width="18.56" height="21.04"/>
              <rect className="cls-93" x="686.46" y="2368.7" width="18.56" height="21.04"/>
            </g>
            <g>
              <rect className="cls-60" x="753.58" y="2368.7" width="18.57" height="21.04"/>
              <rect className="cls-93" x="753.58" y="2368.7" width="18.57" height="21.04"/>
            </g>
            <g>
              <rect className="cls-60" x="731.16" y="2368.7" width="18.56" height="21.04"/>
              <rect className="cls-93" x="731.16" y="2368.7" width="18.56" height="21.04"/>
            </g>
          </g>
          <g>
            <g>
              <rect className="cls-60" x="664.19" y="2394.74" width="18.56" height="21.04"/>
              <rect className="cls-93" x="664.19" y="2394.74" width="18.56" height="21.04"/>
            </g>
            <g>
              <rect className="cls-60" x="641.77" y="2394.74" width="18.56" height="21.04"/>
              <rect className="cls-93" x="641.77" y="2394.74" width="18.56" height="21.04"/>
            </g>
            <g>
              <rect className="cls-60" x="708.88" y="2394.74" width="18.57" height="21.04"/>
              <rect className="cls-93" x="708.88" y="2394.74" width="18.57" height="21.04"/>
            </g>
            <g>
              <rect className="cls-60" x="686.46" y="2394.74" width="18.56" height="21.04"/>
              <rect className="cls-93" x="686.46" y="2394.74" width="18.56" height="21.04"/>
            </g>
            <g>
              <rect className="cls-60" x="753.58" y="2394.74" width="18.57" height="21.04"/>
              <rect className="cls-93" x="753.58" y="2394.74" width="18.57" height="21.04"/>
            </g>
            <g>
              <rect className="cls-60" x="731.16" y="2394.74" width="18.56" height="21.04"/>
              <rect className="cls-93" x="731.16" y="2394.74" width="18.56" height="21.04"/>
            </g>
          </g>
          <g>
            <g>
              <rect className="cls-60" x="664.19" y="2420.45" width="18.56" height="21.03"/>
              <rect className="cls-93" x="664.19" y="2420.45" width="18.56" height="21.03"/>
            </g>
            <g>
              <rect className="cls-60" x="641.77" y="2420.45" width="18.56" height="21.03"/>
              <rect className="cls-93" x="641.77" y="2420.45" width="18.56" height="21.03"/>
            </g>
            <g>
              <rect className="cls-60" x="708.88" y="2420.45" width="18.57" height="21.03"/>
              <rect className="cls-93" x="708.88" y="2420.45" width="18.57" height="21.03"/>
            </g>
            <g>
              <rect className="cls-60" x="686.46" y="2420.45" width="18.56" height="21.03"/>
              <rect className="cls-93" x="686.46" y="2420.45" width="18.56" height="21.03"/>
            </g>
            <g>
              <rect className="cls-60" x="753.58" y="2420.45" width="18.57" height="21.03"/>
              <rect className="cls-93" x="753.58" y="2420.45" width="18.57" height="21.03"/>
            </g>
            <g>
              <rect className="cls-60" x="731.16" y="2420.45" width="18.56" height="21.03"/>
              <rect className="cls-93" x="731.16" y="2420.45" width="18.56" height="21.03"/>
            </g>
          </g>
        </g>
        <g>
          <path className="cls-65" d="m725.75,2339.21c0,10.38-8.41,18.8-18.8,18.8s-18.8-8.42-18.8-18.8,8.41-18.8,18.8-18.8,18.8,8.41,18.8,18.8Z"/>
          <polygon className="cls-38" points="719.63 2335.01 711.16 2335.01 711.16 2326.54 702.76 2326.54 702.76 2335.01 694.29 2335.01 694.29 2343.41 702.76 2343.41 702.76 2351.88 711.16 2351.88 711.16 2343.41 719.63 2343.41 719.63 2335.01"/>
        </g>
      </g>
      <g id="Prikken">
        <path className="cls-92" d="m878.62,2377.69c0,.55,2.19,16.77,2.19,16.77l-16.04,11.12-1.09-17.32,14.95-10.57Z"/>
        <path className="cls-57" d="m881.16,2388.91c.24.16.58.4.76.55.18.14.53.42.79.61.74.56,2.65,2.3,3.34,3.04,1.51,1.62,2.68,3.35,3.99,5.92,2.19,4.3,3.82,10,4.97,17.41,1.27,8.16-.49,18.94-.49,29.59,0,2.36-.02,4.35-.04,4.41-.02.09-.11.14-.26.16-.49.08-7.47.11-11.11.04-4.78-.08-7.26-.19-11.86-.51-2.5-.17-3.22-.24-6.17-.59-3.63-.42-4.28-.53-4.29-.72,0-.05-.01-.2-.02-.33-.21-2.06-.67-8.24-.79-10.78-.24-4.89-.23-11.3.03-16.12.35-6.43,1.16-12.5,2.36-17.62.51-2.16,1.19-4.49,1.31-4.49.06,0,.18.28.18.42,0,.18.43,1.06.67,1.38.55.71,1.23,1.09,2.04,1.13,1.49.08,2.97-.6,4.78-2.18,1.5-1.32,5.53-4.87,6.75-6.99.42-.73.49-.86,1.26-2.38,1.03-2.03,1.15-2.23,1.27-2.23.06,0,.31.13.54.28Z"/>
        <path className="cls-92" d="m867.57,2416.92c.92.15,3.15.32,5.02.38,1.4.04,2.1.07,3.88.18,1.16.07,4.67.26,4.83.26.02,0,.05.21.05.46,0,.76-.22,4.45-.41,6.82-.25,3.14-.29,3.61-.5,5.84-.05.48-.19,2.16-.32,3.73-.37,4.48-.7,7.78-.88,8.75-.12.68-.56,2.5-.8,3.29-.2.66-.51,1.26-.77,1.51-.43.4-1.45.94-2.63,1.39-.56.21-2.55.83-3.81,1.19-2.36.66-5.4,1.26-8.37,1.64-1.79.23-5.17.63-6.46.77-2.34.25-9.27,1.13-10.61,1.35-1.78.29-3.03.66-3.68,1.06-.63.4-.79.62-2.01,2.81-1.4,2.51-1.83,3.15-2.42,3.64-.53.43-.86.58-1.31.59-.48,0-.84-.19-1.1-.59-.32-.48-.39-.78-.39-1.79,0-.84,0-.95.16-1.47.21-.7.45-1.23.85-1.94.18-.31.4-.72.49-.91.38-.76,1.3-2,1.96-2.61.68-.65,2.41-2.04,3.29-2.64.12-.09.48-.34,1.14-.82,1.17-.85,3.31-2.23,5.26-3.41,1.52-.92,3.05-1.89,4.49-2.83,1.35-.88,2.07-1.35,3.17-2.04.68-.43.99-.63,1.95-1.26.28-.18.95-.6,1.47-.95l.96-.62.04.52c.03.28.1,1.04.16,1.69.13,1.3.29,3.2.29,3.38,0,.15-.06.16,1.47-.4,2.69-.99,4.09-1.71,4.9-2.52.65-.65.7-.83.81-2.94.06-1.37.05-2.06-.13-7.07-.03-.82-.08-3.06-.12-4.96-.04-1.91-.09-4.22-.12-5.14-.1-3.02-.14-4.4-.11-4.4.01,0,.15.03.32.06Z"/>
        <path className="cls-61" d="m836.8,2520.25s1.49.05,3.22.08c1.74.04,3.18.07,3.19.08.05.06.02,2.16-.04,2.51-.17.95-.56,1.91-.9,2.19-.68.56-2.1.82-5.24.98-2.46.12-5.26.19-6.14.15-2.54-.11-4.98-.39-5.77-.65-1.1-.38-1.87-1.13-1.87-1.84,0-.74.76-1.51,2.14-2.2,1.67-.83,3.48-1.13,7.19-1.22,1.25-.03,2.68-.07,3.2-.08.52-.01.96-.01,1,0Z"/>
        <path className="cls-41" d="m846.63,2469.21c.65-.48,1.59-.59,5.06-.57,2.49.02,6.45.14,7.93.25.78.06,3.6.19,6.28.29.89.04,2.16.11,2.82.16,1.57.12,3.52.13,5.14,0,1.58-.12,2.11-.15,3.47-.19,1.45-.05,4.58-.19,5.58-.26,2.18-.15,9.34-.33,10.25-.26,1.84.15,2.27.23,2.74.58.24.18.33.29.44.55.12.32.14.42.15,2.16.04,2.94-.15,4.25-.68,4.8-.09.1-.33.22-.63.32-.45.15-.52.16-1.85.16-.76,0-1.93.01-2.61.02l-1.22.02.4,43.77.03,2.45h-.31c-.18,0-1.14.02-2.14.04-1.06.02-1.84.01-1.86-.02l-.2-46.18s-3.12.01-6.91.06c-8.23.1-6.22.1-14.48,0-3.79-.05-6.89-.07-6.91-.06-.04.04-.09,11.64-.13,29.07-.02,9.39-.05,17.08-.07,17.11-.02.03-.82.04-1.94.02-1.05-.02-2.01-.04-2.14-.04h-.22l.04-4.06.33-38.66.02-3.47-1.56-.05c-.85-.02-1.92-.03-2.37-.01-.46.02-.96,0-1.13-.03-1.61-.31-1.79-.7-1.89-4.32-.08-2.74,0-3.21.58-3.65Z"/>
        <path className="cls-136" d="m861.28,2448.97s.22.04.43.03c.43-.02,1.9.15,3.39.37,2.13.32,5.07.6,8.22.79.58.04,1.68.1,2.44.15.88.05,3.27.11,6.64.14,2.89.04,6.2.08,7.34.11,1.15.02,2.65.02,3.34,0,.82-.03,1.28-.03,1.33.01.18.15.14,4.94-.06,7.19-.17,1.84-.48,3.66-.77,4.53-.19.56-.65,1.56-1.04,2.28-.56,1-1.1,1.62-2.04,2.27-1.49,1.05-3.8,1.68-8.31,2.27-5.58.73-8.96.96-16.36,1.06-3.88.05-6.59.11-11.16.23-1.12.03-2.89.06-3.95.06h-1.92s-.03.19-.03.19c-.02.11-.05.5-.06.87-.02.37-.15,1.69-.3,2.94-.58,4.96-.96,8.35-1.05,9.19-.05.48-.17,1.58-.26,2.44-.21,1.87-.43,3.95-.59,5.58-.06.66-.28,2.55-.47,4.2-.19,1.64-.45,4-.59,5.23-.13,1.23-.35,3.21-.5,4.4-.14,1.19-.41,3.41-.58,4.93-.55,4.7-.97,8.18-1.1,9.14l-.13.92-.3.04c-1.11.14-6.95.07-7.1-.08-.06-.06-.18-1.12-.31-2.67-.05-.65-.13-1.69-.18-2.32-.05-.63-.21-2.32-.35-3.76-.7-6.98-.99-10.32-1.34-15.71-.36-5.52-.45-8.32-.45-14.12,0-8.12.19-11.69.86-15.71.38-2.24.66-3.31,1.29-4.73.59-1.34,1.24-2.44,2.12-3.59.71-.94,2.06-2.17,3.22-2.94,1.09-.73,3.63-1.93,5.17-2.45.62-.21,3.85-1.22,4.58-1.44,2.58-.76,5.6-1.39,8.31-1.74.53-.06,1.27-.18,1.65-.23.74-.13.89-.14.96-.08Z"/>
        <path className="cls-92" d="m863.32,2386.93c-.03-.06-.23-.04-.67.04-1.99.41-4.56-.52-6.36-2.31-1.7-1.69-2.97-4.69-3.54-8.37-.16-.98-.17-1.35-.17-3.62,0-1.91.04-2.83.13-3.76.9-8.25,3.86-14.4,9.36-19.4,3.13-2.86,6.11-4.35,9.39-4.7.95-.1,2.3-.09,3.19.03,1.47.2,2.58.55,3.91,1.23,1.27.64,2.26,1.34,3.25,2.29.98.95,1.61,1.76,2.55,3.3,1.68,2.75,2.38,4.7,2.73,7.54.14,1.12.12,3.95-.04,5.23-.42,3.55-1.39,7-2.98,10.62-.24.53-.97,2.02-1.63,3.31l-1.2,2.34v.06c-5.07,4.01-11.05,6.72-17.35,9.29-.08-.44-.15-.88-.23-1.33-.16-.95-.3-1.76-.33-1.81Z"/>
        <path className="cls-41" d="m874.81,2369.53c.21.22.16.44-.15.64-.35.25-2.43,2.29-3.04,3.01-.7.81-.71,1.12-.13,2.08.73,1.21,1.32,1.79,1.8,1.79.57,0,1-.26,2.57-1.56.42-.35.53-.36.69-.11.11.17.11.2,0,.36-.07.1-.38.41-.7.68-1.03.91-1.95,1.82-3.06,3.04-1.81,1.99-2.56,2.69-4.41,4.07-1.38,1.03-3.98,2.88-4.75,3.37-1.12.72-2.34,1.07-3.37.97-1.18-.11-2.73-.74-3.45-1.38-.5-.46-.68-.8-.75-1.36-.05-.41-.05-.42-.32-.45-1.2-.19-2.14-.97-2.81-2.31-.42-.87-.57-1.47-.58-2.34,0-.92.15-1.45.57-1.91.51-.54.57-.57,2.83-.91,1.88-.29,2.63-.45,4.41-.94,1.89-.51,3.54-1.04,5.84-1.86,2.08-.74,2.7-1.02,4.32-1.97,1.26-.74,1.47-.89,3.07-2.17.6-.48,1.14-.88,1.2-.88.05,0,.15.06.22.13Z"/>
        <path className="cls-41" d="m853.68,2379.14c1.03.69,1.64,1.17,1.64,1.3,0,.06-.04.14-.09.18-.12.11-.35,0-.88-.44-.23-.19-.63-.48-.9-.65-.26-.18-.5-.35-.51-.39-.01-.04,0-.16.02-.26l.04-.19.68.46Z"/>
        <path className="cls-28" d="m875.76,2342.54c1.82.12,4,.57,5.54,1.15,2.53.95,5.06,2.75,6.77,4.81.95,1.13,2.18,3.26,2.82,4.88.68,1.72,1.03,5.08.79,7.55-.31,3.01-1.13,6.25-2.16,8.48-1.64,3.55-4.8,8.7-7.49,12.22-.54.71-.55.72-.83.68-.74-.1-2.03-.33-2.41-.45l-.42-.11-.14-.46c-.08-.25-.31-.99-.5-1.64-.19-.65-.57-1.9-.82-2.75-.26-.86-.61-2.07-.78-2.68-.45-1.64-.84-2.9-1.33-4.32-.25-.69-.61-1.83-.83-2.52-.6-1.96-.56-1.9-1.81-2.69-1.3-.83-3.24-2-4.34-2.61-2.63-1.47-7.26-3.45-10.43-4.48-1.27-.42-1.43-.48-1.43-.63,0-.23.39-1.79.71-2.84,1.1-3.62,2.83-6.46,4.99-8.2,3.23-2.6,8.06-3.75,14.11-3.36Z"/>
        <path className="cls-28" d="m802.17,2442.43c.5.02,1.73.06,2.73.09,1,.02,1.84.05,1.86.06.09.05,7.39.11,12.94.11,5.74,0,8.43-.03,11.62-.13l1.29-.04-.04.85c-.02.46-.05,1.19-.06,1.61-.02.42-.06,1.36-.09,2.08-.04.73-.1,2.25-.15,3.38s-.11,2.63-.15,3.32c-.16,3.37-.25,5.09-.32,6.45-.04.82-.09,1.83-.12,2.23-.02.41-.07,1.46-.12,2.35-.13,2.36-.18,3.6-.44,9.6-.03.74-.07,1.55-.08,1.79-.02.24-.05.76-.06,1.15-.11,2.31-.55,8.43-.88,12.33-.02.19-.06.72-.09,1.17-.04.45-.09,1.14-.12,1.53-.03.39-.08,1.07-.12,1.53-.04.45-.1,1.34-.15,1.97-.05.63-.15,2.07-.23,3.2-.18,2.51-.36,4.74-.68,8.25-.44,5.03-.63,7.68-.94,13.01-.13,2.26-.25,4.28-.27,4.47l-.04.36-1.01.04c-1.24.05-4.89.14-6.2.16l-.97.02-.07-.82c-.14-1.67-.32-4.39-.55-8.28-.51-8.76-.6-11.04-.73-16.62-.11-4.93-.18-7.46-.23-8.9-.03-.8-.05-1.46-.15-4.38-.04-1.07-.14-3.98-.24-6.46-.13-3.29-.18-5.6-.21-8.5-.02-2.19-.04-3.97-.05-3.96-.04.04-.76,7.11-1.14,11.05-.49,5.19-.76,8.39-.97,11.42-.09,1.3-.48,6.8-.65,9.19-.05.68-.14,2-.21,2.94-.07.93-.21,2.91-.32,4.38-.11,1.47-.24,3.33-.29,4.14-.54,8.92-.97,15.72-.99,15.75-.04.05-6.43.27-6.64.23-.06-.02-.29-.03-.51-.03h-.39s-.04-.49-.04-.49c-.06-.8-.68-11.67-.77-13.58-.02-.36-.07-1.43-.12-2.38-.05-.95-.11-2.33-.15-3.05-.09-1.9-.22-4.75-.32-6.75-.05-.95-.13-2.55-.18-3.55-.12-2.37-.33-5.93-.59-9.75-.26-4.02-.38-6.36-.5-10.87-.05-1.93-.08-2.6-.15-4.35-.02-.39-.07-1.67-.12-2.85-.25-5.93-.32-7.83-.38-9.02-.14-3.04-.23-5.02-.26-5.43-.04-.45-.17-3.45-.24-5.17-.02-.53-.04-2.3-.04-3.93,0-2.54,0-2.97.08-2.97.05,0,.49.01.99.04h0Z"/>
        <path className="cls-41" d="m834.62,2402.04c-.18-.05-.18-.04-.06,1.19.29,2.95.58,6.57.94,11.92.53,7.85.78,11.21,1.35,18.24.8,9.83,1.32,17.14,1.73,24.2.09,1.7.21,3.52.24,4.05.22,3.51.06,18.45-.27,25.05-.05.97-.11,1.87-.12,2l-.03.24-.42.06c-1.99.31-5.4.66-7.97.83-6.42.42-12.26.39-18.44-.09-5.27-.41-13.08-1.56-18.99-2.8-1.27-.26-1.47-.32-1.5-.42-.05-.12-.11-1.09-.25-3.76-.05-1.08-.01-3.87.09-6.67.02-.43.06-1.49.09-2.35.17-4.59.6-12.64,1.2-22.32.98-15.75,1.8-24.87,3.27-36.12.68-5.23,1.96-13.48,2.9-18.56,1.17-6.4,2.55-12.18,3.69-15.53.97-2.82,2.2-5.26,3.44-6.81,1.18-1.47,2.14-2.29,4.3-3.65.6-.38,1.33-.7,2.21-1,1.17-.39,2.44-.53,3.92-.43,1.2.08,2.95.48,4.4,1,.75.28,2.02.9,2.74,1.34,1.99,1.23,3.26,3.33,4.63,5.53.33.55,1.13,1.19,2.96,3.1.68.71,1.19,1.64,1.54,2.03.35.39.6,1.7,1.06,2.17.45.47.58,2.05,1.1,2.64"/>
        <path className="cls-41" d="m828.07,2525.92c3.17,2.61,4.22,3.63,5.09,4.93.41.6.51.85.51,1.22,0,.37-.1.57-.43.79-1.17.79-3.84-.39-9.4-4.18-1.13-.76-1.74-1.23-3.29-2.47l-1.02-.82.73-.04c.65-.03,2.97-.11,5.84-.19l1-.03.97.8Z"/>
        <path className="cls-41" d="m813.48,2527.15c2.1,1.58,4.45,3.55,5.51,4.62.8.8,1.22,1.34,1.54,1.97.18.36.22.51.24.86.02.36,0,.46-.1.63-.35.56-1.52.71-2.85.36-1.01-.26-2.92-1.11-3.85-1.7-1.81-1.16-5.03-3.56-6.52-4.88-.86-.75-2.42-2.38-2.34-2.45.04-.04,4.83-.15,6.16-.15h1.21s1,.75,1,.75Z"/>
        <path className="cls-77" d="m839.24,2332.18c2.81.39,5.15,1.56,7.02,3.5,1.01,1.06,1.98,2.61,2.62,4.21.37.93,1.71,5.01,1.92,5.86.9,3.6.95,7.64.18,12.24-1.15,6.8-4.78,13.73-8.64,16.52-1.39,1.01-2.52,1.51-4.15,1.85-2.78.58-5.95-.24-7.91-2.06-.12-.11-.27-.18-.32-.15-.23.14-1.55,2.35-2.51,4.21l-.66,1.27-.2-.28c-.46-.61-3.8-3.43-5.15-4.34-1.36-.91-4.39-2.26-5.79-2.59-.52-.12-.65-.19-.63-.29.3-1.08,1.78-5.82,2.68-8.58l1.16-3.55-.15-1.82c-.12-1.51-.13-2.06-.05-3.12.31-4.18,1.77-8.89,4.03-12.93,3.04-5.44,6.61-8.51,11.25-9.65,1.79-.44,3.54-.54,5.29-.3Z"/>
        <path className="cls-61" d="m829.48,2319.06c.92.12,2.39.49,3.27.82,1.32.49,2.21,1.02,3.35,1.93l.6.49.69-.11c1.12-.17,3.24-.23,4.38-.12,2.3.22,4.74.96,6.59,1.99,3.56,1.96,5.87,5.04,6.49,8.59.08.45.14.98.14,1.19,0,.36.03.4.78,1.13,1.13,1.08,1.87,2.22,2.34,3.63.31.92.4,1.38.48,2.45.12,1.61-.05,3-.56,4.51-1.01,3.01-2.82,5.35-5.61,7.23-.48.34-.91.6-.95.6-.04,0-.22-.24-.4-.53-1.03-1.63-1.67-2.37-2.67-3.1-2.18-1.61-4.99-2.31-8.89-2.19-3.83.1-7.34,1-11.07,2.81-2.68,1.3-4.36,2.56-6.01,4.48-1.14,1.33-1.64,2.06-2.77,4.08-.55.99-1.02,1.82-1.03,1.84-.1.13-3.6-1.29-5.5-2.23-2.08-1.02-3.22-1.78-4.35-2.92-1.82-1.82-2.66-3.8-2.66-6.35,0-1.8.41-3.19,1.34-4.55.21-.31.38-.59.38-.64,0-.05-.15-.39-.34-.76-.84-1.69-1.08-2.78-1.08-4.92,0-1.38.03-1.73.23-2.67.64-3,1.88-5.48,3.57-7.07,1.27-1.2,2.49-1.83,4.19-2.17.38-.08.77-.15.88-.18.11-.03.31-.25.51-.58,2.59-4.29,7.13-6.81,12.18-6.76.53,0,1.2.04,1.49.08Z"/>
        <path className="cls-41" d="m832.44,2351.59c2.08,2.14,2.89,2.82,5.23,4.42.49.33,1.47,1.02,2.19,1.54,1.94,1.38,2.74,1.9,3.8,2.43l.95.46,1.12.05c1.35.06,2.42.22,3.4.52,1.26.39,1.44.65,1.35,1.92-.1,1.58-.65,3.08-1.81,5.06-1.02,1.73-2.12,3.11-3.75,4.77-1.63,1.63-2.63,2.37-4.47,3.25-1.45.69-1.85.79-3.41.84-2.07.07-3.53-.19-4.94-.89-.65-.33-.95-.54-1.48-1.08-.77-.77-1.06-1.2-2.06-2.95-2.33-4.08-4.83-9.81-6.38-14.62-.57-1.76-.57-1.56.02-2.19l.5-.55.2.75.21.74.27-.14c.43-.22,2.25-1.37,2.87-1.81.95-.68,2.86-2.17,3.39-2.62l.49-.44-.38-.54-.39-.55.46-.21c.24-.12.49-.22.53-.22.05,0,1,.94,2.11,2.07Z"/>
        <g id="Buzzy_tegen_prikanst" data-name="Buzzy tegen prikanst">
          <path className="cls-137" d="m877.93,2420.18s2.73-1.5,3.59.72-1.14,2.81-1.2,4.35c-.06,1.55,2.46,6.19-4.22,5.25-6.68-.95-1.39-10.17-1.39-10.17l3.22-.15Z"/>
          <path className="cls-137" d="m872.01,2418.07s-1.62-2.66-3.47-1.16c-1.84,1.5-.41,3-1.13,4.37-.72,1.37-5.22,4.14,1.05,6.65,6.27,2.51,6.27-8.13,6.27-8.13l-2.72-1.73Z"/>
          <ellipse className="cls-23" cx="872.13" cy="2425.65" rx="4.4" ry="4.06" transform="translate(-1642.56 1859.33) rotate(-57.53)"/>
          <ellipse className="cls-23" cx="874.98" cy="2419.96" rx="3.82" ry="3.52" transform="translate(-1636.44 1859.09) rotate(-57.53)"/>
          <circle className="cls-38" cx="874.23" cy="2418.4" r=".64"/>
          <circle className="cls-38" cx="876.66" cy="2419.47" r=".64"/>
        </g>
        <path className="cls-61" d="m869.05,2419.28c.58.49,1,.91,1.99,2.02,1.71,1.92,2.2,2.88,1.81,3.57-.1.17-.17.22-.42.29-.49.13-1.08.03-4.29-.75-2.96-.72-6.28-1.31-7.75-1.37-.89-.03-1.58-.12-1.73-.21-.14-.08-.14-.09.16-1.52.26-1.25.76-3.25.93-3.78.06-.16.08-.17.76-.18,2.6-.04,5.1.24,6.44.72.61.22,1.6.79,2.09,1.21Z"/>
        <path className="cls-41" d="m829.59,2379.53c1.01.71,2,1.79,2.81,3.06,1.24,1.95,2.46,5.14,3.34,8.75,1.42,5.85,2.33,11.4,2.54,15.56.03.56.05,1.05.06,1.1,0,.07.34,4.57,1.2,4.72,1.45.23,3.17.58,4.83.96,2.02.47,5.28,1.18,6.39,1.39,3.22.61,9.81,1.95,9.85,2.08.09.28-.68,3.82-1.12,5.77l-.14.61-.62.23c-1.36.5-9.37.18-11.31.04-5.72-.3-6.8-.25-9.96-.71-1.78.02-2.09-.37-4.07-1.01-.92-.42-1.26-.74-2.04-1.21-1.79-1.08-3.31-2.49-4.84-4.53-1.39-1.84-3.45-5.13-4.14-6.59-1.6-3.4-3.14-7.59-4.01-10.95-1.33-5.14-1.48-10.13-.4-13.78.63-2.15,1.72-3.76,3.4-5.05,1.36-1.05,3.26-1.62,4.95-1.51.98.06,1.54.19,2.32.55.37.17.8.39.96.5Z"/>
        <path className="cls-140" d="m818.37,2385.28c1.01.71,2,1.79,2.81,3.06,1.24,1.95,2.46,5.14,3.34,8.75,1.42,5.85,2.33,11.4,2.54,15.56.03.56.05,1.05.06,1.1,0,.07.34,4.57,1.2,4.72,1.45.23,3.17.58,4.83.96,2.02.47,5.28,1.18,6.39,1.39,3.22.61,9.81,1.95,9.85,2.08.09.28-.68,3.82-1.12,5.77l-.14.61-.62.23c-1.36.5-9.37.18-11.31.04-5.72-.3-6.8-.25-9.96-.71-1.78.02-2.09-.37-4.07-1.01-.92-.42-1.26-.74-2.04-1.21-1.79-1.08-3.31-2.49-4.84-4.53-1.39-1.84-3.45-5.13-4.14-6.59-1.6-3.4-3.14-7.59-4.01-10.95-1.33-5.14-1.48-10.13-.4-13.78.63-2.15,1.72-3.76,3.4-5.05,1.36-1.05,3.26-1.62,4.95-1.51.98.06,1.54.19,2.32.55.37.17.8.39.96.5Z"/>
        <g>
          <path className="cls-58" d="m848.91,2422.77s4.96,1.44,5.69,1.22c.73-.22,4.66-1.96,5.59-1.85.92.11,5.52.56,5.93.67.42.11.62,1.47-.76,1.85-1.38.38-2.84.16-3.09.16s-1.16.35-1.1.55c.05.2,3.26,1.08,4.19,1.19.92.11,5.72.08,6.41.59.69.52-.28,1.72-2.27,1.71-1.99,0-3.25,0-3.25,0,0,0-1.28,2.76-1.91,3.23-.63.47-3.32.52-5.59.25-2.27-.27-4.41-1.4-5.26-2.22l-5.59-.93,1.02-6.42Z"/>
          <g>
            <g>
              <path className="cls-102" d="m866.25,2427.56l1.76-.78,4.35,6.57c-.15.13-.42.34-.77.5-.29.13-.58.21-.87.22l-4.48-6.51Z"/>
              <path className="cls-60" d="m869.28,2430.29l.91-.35,2.35,3.41s-.41.88-1.89.86l-2.4-3.48,1.03-.44Z"/>
              <path className="cls-38" d="m868.13,2426.39l-2.35,1.04,4.77,6.93c.47.02.88-.1,1.22-.25.62-.28,1-.7,1-.7l-4.65-7.02Zm3.47,7.46c-.29.13-.58.21-.87.22l-4.48-6.51,1.76-.78,4.35,6.57c-.15.13-.42.34-.77.5Z"/>
            </g>
            <path className="cls-38" d="m867.13,2426.58s-.25.35-.65.34l-1.76-2.56.72-.32,1.69,2.54Z"/>
            <path className="cls-38" d="m872.18,2433.69l.59.9s-.43.46-1.04.44l-.59-.85c.35-.16.7-.32,1.04-.49Z"/>
            <polygon className="cls-38" points="865.22 2427.73 868.79 2426.13 868.34 2425.48 864.77 2427.08 865.22 2427.73"/>
            <polygon className="cls-38" points="864.12 2424.84 866.16 2423.93 865.9 2423.55 863.87 2424.46 864.12 2424.84"/>
            <path className="cls-38" d="m866.19,2426.5s.41-.12.81-.1l2.57,3.72-.72.32-2.66-3.94Z"/>
            <polygon className="cls-38" points="870.17 2429.65 868.14 2430.56 868.39 2430.93 870.42 2430.02 870.17 2429.65"/>
            <polygon className="cls-38" points="872.34 2434.72 874 2437.39 872.13 2434.83 872.34 2434.72"/>
          </g>
          <path className="cls-58" d="m865.84,2430.95s.98-.45,1.27-.75c.29-.31.37-.96.43-1.24s-.7-2.37-1.2-2.54c-.5-.16-1.27.51-1.03,1.86.24,1.35.53,2.67.53,2.67Z"/>
          <path className="cls-58" d="m864.24,2431.68s1.19.24,1.84-.14c.65-.37.57-2.81.33-3.38-.24-.57-1.25-.9-1.86.29-.61,1.19-.31,3.23-.31,3.23Z"/>
          <path className="cls-58" d="m862.05,2431.73c.08.36.43.78,1.3.66.87-.12,1.13-.16,1.29-.35.16-.19.61-1.41.52-1.81-.09-.4-.38-2.14-.94-2.33-.56-.19-1.4.51-1.16,1.58.23,1.07.24,1.3.24,1.3,0,0-1.52-.29-1.25.94Z"/>
        </g>
      </g>
      <g id="Vrouw_rechts" data-name="Vrouw rechts">
        <path className="cls-28" d="m629.42,2614.96s1.4-1.29,3.01-2.83c1.62-1.53,2.96-2.8,2.98-2.8.1,0,1.97,1.95,2.22,2.32.7,1.02,1.2,2.25,1.15,2.81-.11,1.12-1.18,2.64-3.9,5.61-2.14,2.32-4.63,4.91-5.46,5.67-2.42,2.19-4.89,4.13-5.85,4.6-1.35.65-2.72.65-3.36,0-.66-.68-.67-2.06-.02-3.93.78-2.26,2.15-4.16,5.46-7.59,1.11-1.15,2.38-2.48,2.85-2.96.46-.48.87-.88.91-.9Z"/>
        <path className="cls-28" d="m638.95,2621.75s1.4-1.29,3.02-2.83c1.62-1.53,2.96-2.8,2.98-2.8.1,0,1.97,1.95,2.23,2.32.7,1.02,1.21,2.25,1.15,2.81-.11,1.12-1.18,2.64-3.89,5.61-2.14,2.32-4.63,4.91-5.46,5.67-2.42,2.19-4.89,4.13-5.85,4.6-1.35.65-2.72.65-3.36,0-.66-.68-.67-2.06-.02-3.93.78-2.26,2.15-4.17,5.46-7.6,1.11-1.15,2.38-2.48,2.85-2.96.46-.48.87-.88.91-.9Z"/>
        <g id="chair_2">
          <rect className="cls-56" x="671.33" y="2577.94" width="4.93" height="44.48" transform="translate(1347.58 5200.36) rotate(180)"/>
          <path className="cls-56" d="m673.79,2622.42h0c-7.09,0-24.87,4.12-24.87,9.19h49.75c0-5.08-17.79-9.19-24.87-9.19Z"/>
          <path className="cls-56" d="m708.07,2497.41s-31.81-2.12-33.12,23.55c-.92,18.08-2.43,33.38-7.24,35.23-4.9,1.89-10.96,1.37-19.24,2.78-13.74,2.35-12.54,12.17-12.54,12.17,0,0,35.22,11.05,52.12,4.98,13.4-4.81,16.85-20.72,18.18-29.56,3.39-22.51,1.85-49.15,1.85-49.15Z"/>
        </g>
        <g>
          <path className="cls-87" d="m681.22,2513.48l6.81,6.37s9.82,15.26,8.54,27.28c-1.28,12.02-5,17.95-16.52,17.95h-32.96c-.76,0,15.21,50.69,15.68,52.93.47,2.24-21.17,11.22-30.83,6.25,0,0-.64-.56-.76-2.16-.12-1.6-.06-3.65-.06-3.65,0,0-16.4-6.49-18.79-11.7,0,0-.23-.78.01-1.94.19-.88,6.76-31.82,13.62-50.18,8.12-21.74,21.33-19.26,37.1-18.94,0,0-.71-6.3-.37-8.55.34-2.25,1.03-3.77,2.24-4.08.9-.23-.09-1.18-.67-1.66,5.62-3.63,16.93-7.92,16.93-7.92Z"/>
          <path className="cls-87" d="m681.22,2513.48l6.81,5.32s9.82,12.73,8.54,22.75c-1.28,10.02-5,14.97-16.52,14.97h-32.96s-3.05-7.66-3.49-9.13c-.44-1.47-.73-3.04-.09-2.77.64.27,1.28,7.42,14.19,7.42s27.34-7.55,26.29-13.97c-1.05-6.41-9.54-6.06-11.29-6.06h-9.65s-.71-5.25-.37-7.13c.34-1.88,1.03-3.14,2.24-3.41.9-.19-.09-.98-.67-1.38,5.62-3.03,16.93-6.6,16.93-6.6Z"/>
          <path className="cls-87" d="m631.13,2618.46s-5.3-2.1-10.32-4.87c1.61-2.08,4.24-5.69,4.56-7.56.47-2.72,3.37-33.76,3.65-33.86.28-.1.32.36.32.36l1.79,45.93Z"/>
          <path className="cls-87" d="m690.18,2483.03c-7.16-4.87-13.09-3.21-22.51-3.02-3.86.79-5.47,3.38-6.96,6.73-1.23,2.77-.1,15.85-.11,17.05-.31,2.81-.52,6.59,2.53,11.46.89,6.17-1.53,16.61-1.41,18.56.56,9.13,30.09,2.85,28.99-1.31-5.49-20.76.8-31.42.47-39.4-.23-5.63-.65-9.05-1.01-10.07Z"/>
          <path className="cls-87" d="m664.14,2488.28s3.95,7.34,4.64,9.21c.68,1.88-2.58,31.7-2.81,32.44-.23.74-2.85,2.42-2.85,2.42l.03.32-3.83-.11s.33-17.62.18-19.63c-.15-2.01-3.8-12.14-4.33-14.93-.53-2.79,4.93-17.37,4.93-17.37l4.04,7.66Z"/>
          <path className="cls-28" d="m685.24,2482.81s5.95.85,5.79,5.92c0,0,1.05-.45,1.34-1.54.29-1.09,2.93-14.12,2.47-16.3-.46-2.18-2.14-5.48-5.47-9.01,0,0-10.15,13.59-4.13,20.93Z"/>
          <path className="cls-87" d="m675.37,2475.42s-9.78,27.76-10.01,28.64c-.23.87,8.25,26.46,8.78,27.25.53.79,4.56.96,6.54,1.4,1.98.44,13.89,2.34,16.15,4.45,0,0,.81-.6.54-2-.27-1.4-5.93-15.46-6.16-16.37-.23-.92.37-28.76.37-28.76,0,0-2.69-18.52-16.22-14.6Z"/>
          <path className="cls-139" d="m677.35,2455.98l-.37,3.35s-.51,6.9-.59,7.94c-.08,1.05.66,2.96,3.3,3.37,2.16.33-5.25,3.89-9.65,2.88-4.56-1.05-5.63-5.36-3.87-5.64.55-.09,1.12-.56,1.47-.93,1.1-1.16,1.47-6.77.57-8.33-.89-1.56,9.15-2.64,9.15-2.64Z"/>
          <path className="cls-28" d="m664.46,2428.23c2.04-3.45,5.13-6.09,9.45-6.7,8.49-1.2,17.46,3.51,19.37,16,.55,3.61.63,7.53.52,11.47l-3.24,3.07-.77,2.01-4.93-1.61-15.89-4.24c1.56-4.17,2.66-8.41,2.66-8.41l-8.71,3.05s-1.07-9.07,1.55-14.64Z"/>
          <path className="cls-28" d="m670.49,2491.4l-1.71,2.47s-.58,2.04-3.44-1.06c-2.86-3.1-5.44-13.41-6.4-16.73-.96-3.32.72-7.04,5.53-11.51,4.81-4.47,5.69-5.96,5.69-5.96l17.26-9.45.46,14.31-17.38,27.92Z"/>
          <path className="cls-28" d="m666.75,2456.06l-2.13,2.14s-1.78.89-1.97,4.32c-.2,3.44,2.64,10.95,4.39,15.78,1.76,4.84,5.59,3.71,12.33-2.7,6.74-6.41,9.13-15.33,9.13-15.33l-1.53-6.53-20.22,2.31Z"/>
          <path className="cls-28" d="m682.18,2450.29l2.24,5.85s-.32,6.56-5.26,12.67c-4.94,6.11-13.16,13.69-12.69,27.88,0,0,.04,1.03.88,1.16.84.13,5,.25,7.76,1.17,0,0,1.95.64,2.34-1.56,4.49-25.47,6.95-16.76,13.24-35.39,2.85-8.46,3.98-15.52,2.6-24.54-1.49-9.73-7.26-14.73-13.75-15.91,3.28,2.88,7.23,10.01,5.56,27.65l-2.92,1.01Z"/>
          <path className="cls-87" d="m668.72,2441.72l-5.61-.67c.39-5.81.49-13.35,7.07-13.74,7.94-.46,15.82,13.46,14.92,21.97l-16.38-7.56Z"/>
          <path className="cls-73" d="m685.06,2450.4l-.4,4.13c-.68,7.14-6.32,12.33-12.54,11.55h0c-6.21-.79-10.74-7.27-10.05-14.4l.98-10.28h0s.85-6.95,6.43-8.87c5.58-1.92,16.36,7.21,15.57,17.88Z"/>
          <path className="cls-41" d="m663.57,2439.25c.71-2.21,2.35-5.5,5.92-6.73,4.2-1.44,11.36,3.38,14.29,10.41-11.16-11.4-17.34-7.97-20.21-3.68Z"/>
          <path className="cls-87" d="m679.53,2478.91c-6.78,13.55-14.94,33.64-15.68,34.6-.87,1.11-1.4,1.35-2.88,1.12-1.48-.23-17.77-7.61-18.28-7.54-.51.07-2.75,3.55-2.81,4.21-.05.67,17.93,12.29,20.68,12.97,2.75.68,7.06-1.26,9.14-4.01,2.08-2.75,15.52-26,15.52-26,11.06-14.93-1.14-24.42-5.68-15.36Z"/>
          <ellipse className="cls-86" cx="670.06" cy="2523.18" rx="21.14" ry="25.69"/>
        </g>
        <path className="cls-72" d="m640,2511.67l-3.15-3.78c-1.79-1.47-2.9-3.59-2.82-5.35l.05-1.06c.07-1.41,1.8-1.54,3.14-.23l5.47,5.83"/>
      </g>
      <g id="Vrouw_links" data-name="Vrouw links">
        <g id="woman_1">
          <path className="cls-65" d="m622.1,2608.03l3.84,1.96s7,4.28,8.22,6.52c1.21,2.24.94,10.1.34,11.22-.61,1.12-5.66.63-5.66.63l-10.38-13.01,3.64-7.32Z"/>
          <path className="cls-23" d="m562.53,2600.6s-6.44-6.75-7.27-8.15c-1.19-2.03-5.33-25.33-5.33-25.33l9.45-5.33,10.36,36.87-7.22,1.94Z"/>
          <path className="cls-23" d="m554.51,2540.98s19.23.39,27.02,1.17c1.4.14,2.44.29,2.91.46,3.13,1.09,5.32,30.14,6.02,35.22.7,5.07,1.76,19.4,1.76,19.4h-27.27s-7.66-24.91-9.22-29.08c-1.57-4.17-4.18-18.29-3.65-23,.52-4.71,2.44-4.17,2.44-4.17Z"/>
          <path className="cls-65" d="m630.42,2635.16l-16.27,6.03c.02,3.45-8.21,60.77-8.21,60.77l13.29,3.86s1.56.1,2.67-.67l-8.98-3.19,17.5-66.8Z"/>
          <path className="cls-65" d="m597.94,2701.96l15.22-60.92-16.03.04-6.29,60.88,15.18,4.49,1.34-.67-9.42-3.82Z"/>
          <path className="cls-23" d="m564.95,2597.23s-7.48,7.97-9.4,25.36c-1.02,9.25-12.39,23.32,42.74,18.67.47,0,.29,4.95.29,4.95,0,0,27.53-2.2,32.77-9.57-.16-9.82.42-5.64-.16-9.82-.9-6.45-6.74-9.68-23.04-13.49-14.03-3.28-11.94-9.22-15.94-16.1-31.5,0-27.27,0-27.27,0Z"/>
          <path className="cls-23" d="m609.15,2706.45c-1.17-.84-2.19-1-2.92-.94-1.35.1-2.71-.11-4.03-.47l-11.33-3.08-.04,7.63,20.45-.09s.21-1.38-2.14-3.05Z"/>
          <path className="cls-23" d="m626.01,2707.02c-.55-.48-1.41-1.14-4.07-1.87-.98.67-2.35.67-2.35.67l-13.64-3.86.06,7.54,21.5.3s-.37-1.8-1.49-2.79Z"/>
          <path className="cls-65" d="m583.75,2508.92s.61,22.37-2.26,23c-2.87.63-5.39-.18-5.39-.18l-7.66-5.25s-2.26-13.77,4-17.12c6.26-3.35,11.31-.45,11.31-.45Z"/>
          <path className="cls-65" d="m569.75,2526.49s6.6,4.26,6.34,5.25c-.26,1,0,8.06,0,8.06l-10.96-.51,4.62-12.81Z"/>
          <path className="cls-23" d="m583,2542.35s0,0,0,0c3.52-.03,6.64,1.32,8.75,7.51,1.94,5.7,5.29,30.28,6.32,38.11.2,1.53,1.02,2.91,2.25,3.78l22.04,15.75-4.18,8.4-28.37-14.22c-1.46-.73-2.52-2.12-2.87-3.77l-8.96-42.05s-3.17-12.69,5.02-13.52Z"/>
          <path className="cls-23" d="m560.17,2539.26s13.49,0,15.92.54c2.44.54,4.02,2.22,4.02,2.22l-20.73-1.06.78-1.7Z"/>
          <path className="cls-60" d="m582.47,2504.39s-4.52-8.45-16.71-6.28c-12.18,2.17-14.27,10.45-13.76,17.74.53,7.62-2.34,10.15-5.1,12.59-5.54,4.9-2.73,10.21-2.26,13.57.7,4.99-6.66,9.11-3.7,18,1.8,5.4,7.08,7.3,12.38,7.45,4.07.11,12.39-.29,13.19-7.38.96-8.49,5.89-8.33,7.05-15.27,1.45-8.62-2.29-8.45.14-16.1,1.5-4.73,9.74-13.95,10.06-18.66.32-4.71-1.3-5.67-1.3-5.67Z"/>
        </g>
        <g id="chair_2-2" data-name="chair_2">
          <rect className="cls-56" x="569.31" y="2651.48" width="4.96" height="49.99"/>
          <path className="cls-56" d="m571.79,2701.15h0c7.14,0,25.05,3.87,25.05,8.65h-50.11c0-4.78,17.91-8.65,25.05-8.65Z"/>
          <path className="cls-56" d="m537.27,2575.7s32.04-1.99,33.36,22.16c.93,17.02,2.44,31.41,7.29,33.16,4.94,1.78,11.04,1.29,19.38,2.62,13.84,2.21,12.63,11.45,12.63,11.45,0,0-35.47,10.4-52.49,4.69-13.5-4.53-16.97-19.5-18.31-27.82-3.41-21.19-1.86-46.26-1.86-46.26Z"/>
        </g>
      </g>
      <g id="Cadeautjes">
        <g>
          <rect className="cls-54" x="757.72" y="2633.68" width="17.01" height="23.92"/>
          <rect className="cls-56" x="734.62" y="2633.68" width="16" height="23.92"/>
          <polygon className="cls-56" points="734.57 2633.68 734.62 2633.68 734.62 2657.6 730.07 2657.6 730.07 2633.68 734.57 2633.68"/>
          <rect className="cls-56" x="724.25" y="2633.68" width="2.84" height="23.92"/>
          <polygon className="cls-56" points="750.62 2630.31 734.62 2630.31 734.57 2630.31 733.6 2630.31 733.6 2616.39 750.62 2616.39 750.62 2630.31"/>
          <polygon className="cls-56" points="757.72 2616.39 775.89 2616.39 775.89 2630.31 774.73 2630.31 757.72 2630.31 757.72 2616.39"/>
          <polygon className="cls-56" points="727.09 2616.39 727.09 2630.31 724.25 2630.31 723.61 2630.31 723.61 2616.39 727.09 2616.39"/>
          <rect className="cls-56" x="730.07" y="2616.39" width="3.53" height="13.92"/>
          <rect className="cls-56" x="757.72" y="2627.6" width="17.01" height="6.08"/>
          <rect className="cls-56" x="734.62" y="2630.31" width="16" height="3.37"/>
          <polygon className="cls-56" points="734.57 2630.31 734.62 2630.31 734.62 2633.68 734.57 2633.68 730.07 2633.68 730.07 2630.31 733.6 2630.31 734.57 2630.31"/>
          <rect className="cls-56" x="724.25" y="2630.31" width="2.84" height="3.37"/>
          <polygon className="cls-86" points="750.62 2630.31 750.62 2616.39 757.72 2616.39 757.72 2630.31 757.72 2633.68 757.72 2657.6 750.62 2657.6 750.62 2633.68 750.62 2630.31"/>
          <polygon className="cls-86" points="727.09 2630.31 727.09 2616.39 730.07 2616.39 730.07 2630.31 730.07 2633.68 730.07 2657.6 727.09 2657.6 727.09 2633.68 727.09 2630.31"/>
          <path className="cls-86" d="m766.42,2616.39h-14.1,0s-4.8,0-4.8,0h0s0,0,0,0h-14.25c-2.76,0-5.11-2.01-5.53-4.74-.4-2.58-.58-5.7.22-8.03,1.64-4.73,10.92-2.82,15.29,1.64,4.37,4.46,4.83,10.33,4.83,10.33h0c.4-.42,1.04-.85,1.95-.8.74.04,1.25.29,1.6.59.13-1.08.93-6.16,4.8-10.12,4.37-4.46,13.65-6.37,15.29-1.64.81,2.33.62,5.45.22,8.03-.42,2.73-2.77,4.74-5.53,4.74Zm-23.94-8.47c-3.13-3.07-9.77-4.39-10.94-1.13-.42,1.16-.46,2.62-.35,4.01.23,2.71,2.51,4.79,5.23,4.79h9.6s-.42-4.6-3.55-7.67Zm25.68-1.13c-1.17-3.26-7.81-1.94-10.94,1.13-3.13,3.07-3.65,7.67-3.65,7.67h.59s9.11,0,9.11,0c2.72,0,5.01-2.08,5.23-4.79.12-1.39.07-2.84-.35-4.01Z"/>
        </g>
        <g>
          <rect className="cls-95" x="782.74" y="2644.43" width="15.12" height="18"/>
          <rect className="cls-95" x="762.22" y="2644.43" width="14.22" height="18"/>
          <rect className="cls-95" x="753" y="2644.43" width="2.52" height="18"/>
          <polygon className="cls-95" points="762.17 2644.43 762.22 2644.43 762.22 2662.43 758.17 2662.43 758.17 2644.43 762.17 2644.43"/>
          <polygon className="cls-95" points="776.43 2641.89 762.22 2641.89 762.17 2641.89 761.31 2641.89 761.31 2631.42 763.94 2631.42 763.94 2631.42 768.26 2631.42 768.26 2631.42 775.87 2631.42 775.87 2631.42 775.87 2631.42 775.87 2631.42 776.43 2631.42 776.43 2641.89"/>
          <polygon className="cls-95" points="782.74 2631.42 786.08 2631.42 786.08 2631.42 790.41 2631.42 790.41 2631.42 798.89 2631.42 798.89 2641.89 797.86 2641.89 782.74 2641.89 782.74 2631.42"/>
          <polygon className="cls-95" points="755.52 2631.42 755.52 2641.89 753.47 2641.89 753 2641.89 753 2631.42 755.52 2631.42"/>
          <rect className="cls-95" x="758.17" y="2631.42" width="3.14" height="10.47"/>
          <rect className="cls-95" x="782.74" y="2641.89" width="15.12" height="2.54"/>
          <rect className="cls-95" x="762.22" y="2641.89" width="14.22" height="2.54"/>
          <polygon className="cls-95" points="762.17 2641.89 762.22 2641.89 762.22 2644.43 762.17 2644.43 758.17 2644.43 758.17 2641.89 761.31 2641.89 762.17 2641.89"/>
          <rect className="cls-95" x="753" y="2641.89" width="2.52" height="2.54"/>
          <polygon className="cls-23" points="776.43 2641.89 776.43 2631.42 778.48 2631.42 782.74 2631.42 782.74 2641.89 782.74 2644.43 782.74 2662.43 776.43 2662.43 776.43 2644.43 776.43 2641.89"/>
          <polygon className="cls-23" points="755.52 2641.89 755.52 2631.42 758.17 2631.42 758.17 2641.89 758.17 2644.43 758.17 2662.43 755.52 2662.43 755.52 2644.43 755.52 2641.89"/>
          <path className="cls-23" d="m786.08,2631.42h0c3.56-1.86-.03-5.32-2.15-4.36-2.11.96-5.45,4.35-5.46,4.36v-1.92h0c3.58-8.08,8.34-7.18,10.91-4.92,2.56,2.26,1.03,6.84,1.03,6.84h0s-4.33,0-4.33,0Z"/>
          <path className="cls-23" d="m775.87,2631.42s-3.35-3.4-5.46-4.36c-2.12-.96-5.7,2.5-2.15,4.36h0s-4.33,0-4.33,0h0s-1.54-4.59,1.03-6.84c2.56-2.26,7.33-3.16,10.91,4.92v1.92h0s0,0,0,0Z"/>
          <path className="cls-23" d="m778.48,2631.42h0s-2.04,0-2.04,0h-.57v-1.92c1.27-1.13,2.6,0,2.61,0h0v1.92Z"/>
        </g>
        <g>
          <path className="cls-23" d="m720.72,2647.32c-.03.89-.08,1.47-.08,1.47l1.37-1.47h.61l1.19,1.47s0,0-.01-1.47h2.78v13.69h-8.66v-13.69h2.8Z"/>
          <path className="cls-23" d="m708.15,2647.32q-.01,1.47-.01,1.47l1.19-1.47h.61l1.37,1.47s-.05-.58-.08-1.47h3.08v13.69h-8.14v-13.69h1.98Z"/>
          <rect className="cls-23" x="700.88" y="2647.32" width="1.45" height="13.69"/>
          <polygon className="cls-23" points="706.14 2647.32 706.16 2647.32 706.16 2661.01 703.84 2661.01 703.84 2647.32 706.14 2647.32"/>
          <path className="cls-23" d="m713.49,2637.43h.81v7.97h-3.1c.05-2.94.47-6.89,2.29-7.97Z"/>
          <path className="cls-23" d="m717.92,2637.43h.53c1.82,1.07,2.24,5.02,2.29,7.97h-2.82v-7.97Z"/>
          <path className="cls-23" d="m723.77,2643.4c-.04-5.39-2.25-5.97-2.25-5.97h-2.9s3.98,0,3.98,0c0,0,0,0,0,0h4.58v7.97h-3.39c0-.56,0-1.22-.02-1.99Z"/>
          <path className="cls-23" d="m706.14,2645.39h-.49v-7.97h4.16s0,0,0,0h.65s0,0,0,0h2.49s.11,0,.11,0h0s.31,0,.31,0h-2.9s-2.21.59-2.25,5.98c0,.77-.01,1.43-.02,1.99h-2.03Z"/>
          <rect className="cls-23" x="703.84" y="2637.43" width="1.8" height="7.97"/>
          <polygon className="cls-23" points="702.33 2637.43 702.33 2645.39 700.88 2645.39 700.56 2645.39 700.56 2637.43 702.33 2637.43"/>
          <polygon className="cls-23" points="709.93 2647.32 709.33 2647.32 709.61 2646.97 709.93 2647.32"/>
          <path className="cls-23" d="m711.21,2645.39h3.1v1.93h-3.08c-.02-.54-.03-1.21-.02-1.93Z"/>
          <path className="cls-23" d="m708.16,2645.39c0,.83-.01,1.45-.02,1.93h-1.98v-1.93h2Z"/>
          <polygon className="cls-23" points="722.33 2646.97 722.62 2647.32 722.01 2647.32 722.33 2646.97"/>
          <path className="cls-23" d="m717.92,2645.39h2.82c.01.72,0,1.38-.02,1.93h-2.8v-1.93Z"/>
          <path className="cls-23" d="m723.78,2645.39h2.8v1.93h-2.78c0-.47,0-1.1-.02-1.93Z"/>
          <rect className="cls-23" x="700.88" y="2645.39" width="1.45" height="1.93"/>
          <polygon className="cls-23" points="706.14 2645.39 706.16 2645.39 706.16 2647.32 706.14 2647.32 703.84 2647.32 703.84 2645.39 705.64 2645.39 706.14 2645.39"/>
          <path className="cls-97" d="m714.31,2645.39v-7.97h-.81c-1.82,1.07-2.24,5.02-2.29,7.97-.01.72,0,1.38.02,1.93.03.89.08,1.47.08,1.47l-1.37-1.47-.33-.35-.28.35-1.19,1.47s0,0,.01-1.47c0-.47,0-1.1.02-1.93,0-.56,0-1.22.02-1.99.04-5.39,2.25-5.97,2.25-5.97h2.9s-.31,0-.31,0h0s1.28,0,1.28,0h.66s0-.01,0-.02c.16,0,.34.02.54.02h.65c4.32-.06,4.51-3.76,2.25-3.61-2.26.15-4.5,2.55-4.5,2.55,0,0,0,0,0,0-.26-.08-.56-.13-.87-.13h0s3.74-6.14,6.65-6.31c2.91-.17,5.78,5.17,2.91,7.5,0,0,0,0,0,0h-3.98,2.9s2.21.59,2.25,5.98c0,.77.01,1.43.02,1.99,0,.83.01,1.45.02,1.93q.01,1.47.01,1.47l-1.19-1.47-.28-.35-.33.35-1.37,1.47s.05-.58.08-1.47c.02-.54.03-1.21.02-1.93-.05-2.94-.47-6.89-2.29-7.97h-.53v23.59h-3.61v-15.62Z"/>
          <polygon className="cls-97" points="702.33 2645.39 702.33 2637.43 703.36 2637.43 703.36 2637.43 703.84 2637.43 703.84 2645.39 703.84 2647.32 703.84 2661.01 702.33 2661.01 702.33 2647.32 702.33 2645.39"/>
          <path className="cls-97" d="m712.05,2636.37s-2.24-2.39-4.5-2.55c-2.26-.15-2.08,3.55,2.25,3.61,0,0,0,0,0,0h-6.44s0,0,0,0c-2.87-2.33,0-7.67,2.91-7.5,2.91.17,6.65,6.31,6.65,6.31h0c-.3.01-.58.06-.83.15-.02-.01-.04-.02-.04-.02Z"/>
          <path className="cls-68" d="m710.43,2637.43s0,0,0,0c.24,0,.46-.01.65-.02,0,0,0,.02,0,.02h1.83s-2.49,0-2.49,0Z"/>
          <path className="cls-97" d="m713.9,2636.37c.63.19,1.06.59,1.07,1.04,0,0,0,.01,0,.02h-3.88s0-.02,0-.02c.01-.44.42-.81,1-1.01.25-.08.53-.14.83-.15.04,0,.07,0,.11,0h0c.31,0,.61.05.87.13Z"/>
        </g>
        <g>
          <path className="cls-70" d="m720.09,2655.55c0,1.84.41,3.48.41,3.48l1.28-2.05,1.67,2.05s-.1-1.73.02-3.48h7.1v7.09h-14.89v-7.09h4.41Z"/>
          <rect className="cls-70" x="735.24" y="2655.55" width="16.08" height="7.09"/>
          <rect className="cls-70" x="706.47" y="2655.55" width="2.52" height="7.09"/>
          <polygon className="cls-70" points="715.64 2655.55 715.68 2655.55 715.68 2662.63 711.64 2662.63 711.64 2655.55 715.64 2655.55"/>
          <path className="cls-70" d="m718.97,2650.43s0,0,0,0h4.15c-.52.16-1.01.39-1.45.7-1.02.72-1.43,2.05-1.54,3.42h-5.35v-4.12h1.18s0,0,0,0h3.01Z"/>
          <polygon className="cls-70" points="735.24 2650.43 739.9 2650.43 739.9 2650.43 742.92 2650.43 742.92 2650.43 752.36 2650.43 752.36 2654.55 751.33 2654.55 735.24 2654.55 735.24 2650.43"/>
          <path className="cls-70" d="m724.25,2652.27c.86-1.03,3.3-1.63,4.33-1.83.02,0,.04,0,.06-.01h1.93v4.12h-7.01c.12-.97.33-1.84.69-2.28Z"/>
          <polygon className="cls-70" points="708.99 2650.43 708.99 2654.55 706.47 2654.55 705.9 2654.55 705.9 2650.43 708.99 2650.43"/>
          <rect className="cls-70" x="711.64" y="2650.43" width="3.14" height="4.12"/>
          <polyline className="cls-70" points="751.33 2654.55 751.33 2655.55 735.24 2655.55 735.24 2654.55"/>
          <path className="cls-70" d="m720.13,2654.55c-.03.33-.04.67-.04,1h-4.41v-1h4.45Z"/>
          <path className="cls-70" d="m730.57,2654.55v1h-7.1c.02-.34.05-.67.09-1h7.01Z"/>
          <rect className="cls-70" x="706.47" y="2654.55" width="2.52" height="1"/>
          <polygon className="cls-70" points="715.64 2654.55 715.68 2654.55 715.68 2655.55 715.64 2655.55 711.64 2655.55 711.64 2654.55 714.77 2654.55 715.64 2654.55"/>
          <polygon className="cls-45" points="730.57 2654.55 730.57 2650.43 730.57 2650.43 735.24 2650.43 735.24 2654.55 735.24 2655.55 735.24 2662.63 730.57 2662.63 730.57 2655.55 730.57 2654.55"/>
          <polygon className="cls-70" points="708.99 2654.55 708.99 2650.43 711.64 2650.43 711.64 2654.55 711.64 2655.55 711.64 2662.63 708.99 2662.63 708.99 2655.55 708.99 2654.55"/>
          <path className="cls-45" d="m739.9,2650.43s0,0,0,0c1.15-2.08-.19-3.88-2.69-3.5-2,.3-5.37,2.36-6.64,3.17,0-.27-.13-.51-.32-.68,1.12-1.22,3.39-3.27,6.96-4.48,5.32-1.79,6.86,3.53,5.7,5.48,0,0,0,0,0,0h-3.01Z"/>
          <path className="cls-45" d="m728.33,2650.12h0c-1.24-.79-4.66-2.89-6.68-3.19-2.5-.38-3.85,1.42-2.69,3.5,0,0,0,0,0,0h-3.01s0,0,0,0c-1.15-1.95.38-7.28,5.7-5.48,3.58,1.21,5.86,3.27,6.97,4.49-.18.17-.3.41-.3.68Z"/>
          <path className="cls-45" d="m720.13,2654.55c.12-1.37.52-2.7,1.54-3.42.44-.31.93-.54,1.45-.7,1.94-.62,4.2-.34,5.21-.16v.16h.31s-.04,0-.06.01c-1.03.21-3.48.8-4.33,1.83-.36.43-.57,1.3-.69,2.28-.04.33-.07.66-.09,1-.11,1.75-.02,3.48-.02,3.48l-1.67-2.05-1.28,2.05s-.4-1.64-.41-3.48c0-.33,0-.66.04-1Z"/>
          <path className="cls-45" d="m730.57,2650.1s0,.01,0,.02v.31h0s-.5,0-.5,0h-1.73v-.3h0c0-.27.12-.51.3-.68.17-.15.39-.25.63-.25h.38c.23,0,.45.09.61.23.19.17.31.41.32.68Z"/>
        </g>
      </g>
      <text transform="translate(427.68 2470.55)"><tspan className="cls-24"><tspan x="0" y="0">10 jaar </tspan></tspan><tspan className="cls-33"><tspan x="-11.08" y="18">Prema</tspan><tspan className="cls-121" x="33.09" y="18">T</tspan><tspan x="41.06" y="18">uurlijk </tspan></tspan><tspan className="cls-33"><tspan x="3.27" y="36">spreekuur</tspan></tspan></text>
      <text transform="translate(418.18 2537.77)"><tspan className="cls-24"><tspan className="number" x="0" y="0">90</tspan></tspan><tspan className="cls-33"><tspan x="-50.22" y="18">bezoekers speciale </tspan></tspan><tspan className="cls-33"><tspan x="-78.13" y="36">zwangerschapsgesprekken</tspan></tspan></text>
      <text transform="translate(456.69 2647.6)"><tspan className="cls-24"><tspan className="number" x="0" y="0">253</tspan></tspan><tspan className="cls-33"><tspan x="-52.28" y="18">zorgverleners krijgen </tspan></tspan><tspan className="cls-33"><tspan x="-37.08" y="36">nieuwsbrief over </tspan></tspan><tspan className="cls-33"><tspan x="-42.18" y="54">prenataal aanbod</tspan></tspan></text>
      <text transform="translate(806.7 2586.25)"><tspan className="cls-24"><tspan className="number" x="0" y="0">6</tspan><tspan>.</tspan><tspan>000 </tspan></tspan><tspan className="cls-33"><tspan x="-15.79" y="18">Sint cadeaus </tspan></tspan><tspan className="cls-33"><tspan className="cls-127" x="-16.36" y="36">T</tspan><tspan x="-8.27" y="36">oys 'n </tspan><tspan className="cls-123" x="36.42" y="36">W</tspan><tspan x="49.96" y="36">rap</tspan></tspan></text>
      <text transform="translate(491.04 2374.57)"><tspan className="cls-25"><tspan className="number" x="0" y="0">40</tspan></tspan><tspan className="cls-33"><tspan x="-28.83" y="18">CJG’ers helpen bij </tspan></tspan><tspan className="cls-33"><tspan x="-48.1" y="36">GGD-Boostercampagne</tspan></tspan></text>
      <g id="Corona">
        <circle className="cls-116" cx="445.9" cy="2368.71" r="9.01"/>
        <line className="cls-5" x1="461.36" y1="2366.31" x2="454.78" y2="2367.23"/>
        <circle className="cls-5" cx="431.73" cy="2355.83" r="1.29"/>
        <polyline className="cls-5" points="448.08 2359.97 450.59 2353.17 450.61 2353.17"/>
        <line className="cls-5" x1="449.45" y1="2382.91" x2="448.08" y2="2377.45"/>
        <line className="cls-5" x1="439.12" y1="2362.79" x2="432.67" y2="2356.72"/>
        <line className="cls-5" x1="438.61" y1="2374" x2="432.77" y2="2378.25"/>
        <circle className="cls-5" cx="431.73" cy="2379.01" r="1.29"/>
        <circle className="cls-5" cx="449.76" cy="2384.16" r="1.29"/>
        <circle className="cls-52" cx="462.63" cy="2366.13" r="1.29"/>
        <circle className="cls-5" cx="442.68" cy="2366.78" r=".64"/>
        <circle className="cls-5" cx="446.54" cy="2371.93" r=".64"/>
        <path className="cls-5" d="m451.05,2353.26c-.15,0-.31-.03-.45-.09-.49-.18-.84-.64-.84-1.2,0-.71.58-1.29,1.29-1.29s1.29.58,1.29,1.29-.58,1.29-1.29,1.29Z"/>
      </g>
      <g id="Titel-5" data-name="Titel">
        <rect id="Achtergrond-6" data-name="Achtergrond" className="cls-38" x="436.12" y="2265.57" width="162.97" height="55.58" rx="4.21" ry="4.21"/>
        <text className="cls-50" transform="translate(478.13 2307.14)"><tspan x="0" y="0">Zorg</tspan></text>
      </g>
          </g>
        </>
    )
  }
  return (
    <>
      <g ref={mobileScreen} id="Zorg">
        <path id="Ondergrond-4" data-name="Ondergrond" className="cls-79" d="m249.04,2561.36h66.29c12.53,0,22.69-10.16,22.69-22.69s-10.16-22.69-22.69-22.69c-.33,0-.66.01-.98.02h-24.85c8.1,0,14.66-6.56,14.66-14.66s-6.56-14.66-14.66-14.66H121.72c-.53-.03-1.06-.04-1.59-.04-16.21,0-29.34,13.14-29.34,29.34s13.14,29.34,29.34,29.34h-41.01c-8.1,0-14.66,6.56-14.66,14.66s6.56,14.66,14.66,14.66c.18,0,.35-.02.52-.03h22.85c.13,0,.26,0,.39,0h.3c16.21,0,29.34,13.14,29.34,29.34s-13.14,29.34-29.34,29.34h-60.21c-8.86,0-16.03,7.19-16.03,16.04s7.18,16.03,16.03,16.03h270.17c8.1,0,14.66-6.56,14.66-14.66s-6.56-14.66-14.66-14.66h-30.19c12.53,0,22.69-10.16,22.69-22.69s-10.16-22.69-22.69-22.69h-33.91c-8.1,0-14.66-6.56-14.66-14.66s6.56-14.66,14.66-14.66Z"/>
        <g id="Ziekenhuis">
          <polygon className="cls-48" points="278.24 2502.4 278.24 2424.26 182.4 2424.26 182.4 2458.47 182.4 2502.4 278.24 2502.4"/>
          <g>
            <polygon className="cls-50" points="263.49 2408.01 264.94 2420.98 195.7 2420.98 197.15 2408.01 263.49 2408.01"/>
            <polygon className="cls-48" points="274.79 2414.7 276.7 2425.16 183.94 2425.16 185.85 2414.7 274.79 2414.7"/>
            <polygon className="cls-50" points="278.24 2431.44 182.4 2431.44 179.71 2420.97 280.93 2420.97 278.24 2431.44"/>
          </g>
          <g>
            <g>
              <g>
                <rect className="cls-48" x="204.22" y="2435.26" width="11.33" height="12.84"/>
                <rect className="cls-88" x="204.22" y="2435.26" width="11.33" height="12.84"/>
              </g>
              <g>
                <rect className="cls-48" x="190.54" y="2435.26" width="11.33" height="12.84"/>
                <rect className="cls-88" x="190.54" y="2435.26" width="11.33" height="12.84"/>
              </g>
              <g>
                <rect className="cls-48" x="231.5" y="2435.26" width="11.33" height="12.84"/>
                <rect className="cls-88" x="231.5" y="2435.26" width="11.33" height="12.84"/>
              </g>
              <g>
                <rect className="cls-48" x="217.82" y="2435.26" width="11.33" height="12.84"/>
                <rect className="cls-88" x="217.82" y="2435.26" width="11.33" height="12.84"/>
              </g>
              <g>
                <rect className="cls-48" x="258.77" y="2435.26" width="11.33" height="12.84"/>
                <rect className="cls-88" x="258.77" y="2435.26" width="11.33" height="12.84"/>
              </g>
              <g>
                <rect className="cls-48" x="245.09" y="2435.26" width="11.33" height="12.84"/>
                <rect className="cls-88" x="245.09" y="2435.26" width="11.33" height="12.84"/>
              </g>
            </g>
            <g>
              <g>
                <rect className="cls-48" x="204.22" y="2451.15" width="11.33" height="12.84"/>
                <rect className="cls-88" x="204.22" y="2451.15" width="11.33" height="12.84"/>
              </g>
              <g>
                <rect className="cls-48" x="190.54" y="2451.15" width="11.33" height="12.84"/>
                <rect className="cls-88" x="190.54" y="2451.15" width="11.33" height="12.84"/>
              </g>
              <g>
                <rect className="cls-48" x="231.5" y="2451.15" width="11.33" height="12.84"/>
                <rect className="cls-88" x="231.5" y="2451.15" width="11.33" height="12.84"/>
              </g>
              <g>
                <rect className="cls-48" x="217.82" y="2451.15" width="11.33" height="12.84"/>
                <rect className="cls-88" x="217.82" y="2451.15" width="11.33" height="12.84"/>
              </g>
              <g>
                <rect className="cls-48" x="258.77" y="2451.15" width="11.33" height="12.84"/>
                <rect className="cls-88" x="258.77" y="2451.15" width="11.33" height="12.84"/>
              </g>
              <g>
                <rect className="cls-48" x="245.09" y="2451.15" width="11.33" height="12.84"/>
                <rect className="cls-88" x="245.09" y="2451.15" width="11.33" height="12.84"/>
              </g>
            </g>
            <g>
              <g>
                <rect className="cls-48" x="204.22" y="2466.84" width="11.33" height="12.83"/>
                <rect className="cls-88" x="204.22" y="2466.84" width="11.33" height="12.83"/>
              </g>
              <g>
                <rect className="cls-48" x="190.54" y="2466.84" width="11.33" height="12.83"/>
                <rect className="cls-88" x="190.54" y="2466.84" width="11.33" height="12.83"/>
              </g>
              <g>
                <rect className="cls-48" x="231.5" y="2466.84" width="11.33" height="12.83"/>
                <rect className="cls-88" x="231.5" y="2466.84" width="11.33" height="12.83"/>
              </g>
              <g>
                <rect className="cls-48" x="217.82" y="2466.84" width="11.33" height="12.83"/>
                <rect className="cls-88" x="217.82" y="2466.84" width="11.33" height="12.83"/>
              </g>
              <g>
                <rect className="cls-48" x="258.77" y="2466.84" width="11.33" height="12.83"/>
                <rect className="cls-88" x="258.77" y="2466.84" width="11.33" height="12.83"/>
              </g>
              <g>
                <rect className="cls-48" x="245.09" y="2466.84" width="11.33" height="12.83"/>
                <rect className="cls-88" x="245.09" y="2466.84" width="11.33" height="12.83"/>
              </g>
            </g>
          </g>
          <g>
            <path className="cls-53" d="m241.79,2417.27c0,6.33-5.13,11.47-11.47,11.47s-11.47-5.14-11.47-11.47,5.13-11.47,11.47-11.47,11.47,5.13,11.47,11.47Z"/>
            <polygon className="cls-57" points="238.05 2414.7 232.88 2414.7 232.88 2409.53 227.76 2409.53 227.76 2414.7 222.59 2414.7 222.59 2419.83 227.76 2419.83 227.76 2425 232.88 2425 232.88 2419.83 238.05 2419.83 238.05 2414.7"/>
          </g>
        </g>
        <g id="Prikken">
          <path className="cls-87" d="m327.61,2451.4c0,.32,1.28,9.85,1.28,9.85l-9.42,6.53-.64-10.17,8.78-6.21Z"/>
          <path className="cls-13" d="m329.1,2457.99c.14.09.34.23.44.32.1.08.31.24.46.36.43.33,1.55,1.35,1.96,1.78.89.95,1.57,1.97,2.34,3.48,1.29,2.52,2.24,5.87,2.92,10.22.75,4.79-.29,11.12-.29,17.37,0,1.38-.01,2.55-.02,2.59-.01.05-.06.08-.15.1-.29.04-4.39.06-6.52.02-2.81-.05-4.26-.11-6.96-.3-1.47-.1-1.89-.14-3.62-.34-2.13-.25-2.51-.31-2.52-.42,0-.03,0-.12-.01-.19-.12-1.21-.39-4.84-.47-6.33-.14-2.87-.13-6.63.02-9.46.2-3.77.68-7.34,1.38-10.34.3-1.27.7-2.64.77-2.64.04,0,.11.16.11.24,0,.11.25.62.39.81.32.42.72.64,1.2.66.87.04,1.74-.36,2.81-1.28.88-.77,3.24-2.86,3.96-4.1.25-.43.29-.5.74-1.4.61-1.19.68-1.31.75-1.31.03,0,.18.08.32.17Z"/>
          <path className="cls-87" d="m321.12,2474.43c.54.09,1.85.19,2.95.22.82.02,1.23.04,2.28.11.68.04,2.74.15,2.83.15.01,0,.03.12.03.27,0,.44-.13,2.61-.24,4-.14,1.84-.17,2.12-.29,3.43-.03.28-.11,1.27-.19,2.19-.22,2.63-.41,4.56-.52,5.14-.07.4-.33,1.47-.47,1.93-.12.39-.3.74-.45.89-.25.23-.85.55-1.54.81-.33.12-1.5.49-2.24.7-1.39.39-3.17.74-4.92.96-1.05.13-3.03.37-3.79.45-1.37.14-5.44.66-6.23.79-1.04.17-1.78.39-2.16.62-.37.23-.47.37-1.18,1.65-.82,1.48-1.07,1.85-1.42,2.14-.31.26-.51.34-.77.34-.28,0-.49-.11-.65-.35-.19-.28-.23-.46-.23-1.05,0-.49,0-.56.1-.86.12-.41.26-.72.5-1.14.1-.18.23-.42.29-.53.22-.44.77-1.17,1.15-1.53.4-.38,1.42-1.2,1.93-1.55.07-.05.28-.2.67-.48.69-.5,1.94-1.31,3.09-2,.89-.54,1.79-1.11,2.64-1.66.79-.52,1.22-.79,1.86-1.2.4-.25.58-.37,1.14-.74.17-.11.56-.36.86-.56l.56-.36.02.3c.02.17.06.61.1.99.08.76.17,1.88.17,1.99,0,.09-.04.1.86-.23,1.58-.58,2.4-1.01,2.88-1.48.38-.38.41-.49.48-1.73.04-.8.03-1.21-.08-4.15-.02-.48-.05-1.8-.07-2.91-.02-1.12-.05-2.48-.07-3.02-.06-1.78-.08-2.59-.07-2.58,0,0,.09.02.19.03Z"/>
          <path className="cls-49" d="m303.06,2535.09s.87.03,1.89.05c1.02.02,1.87.04,1.87.05.03.03.01,1.27-.02,1.47-.1.56-.33,1.12-.53,1.29-.4.33-1.23.48-3.08.58-1.44.07-3.09.11-3.6.09-1.49-.07-2.92-.23-3.39-.38-.65-.22-1.1-.67-1.1-1.08,0-.43.44-.89,1.26-1.29.98-.49,2.04-.67,4.22-.72.73-.02,1.58-.04,1.88-.05.3,0,.57,0,.59,0Z"/>
          <path className="cls-59" d="m308.83,2505.12c.38-.28.93-.34,2.97-.33,1.46.01,3.79.08,4.65.15.46.03,2.11.11,3.69.17.52.02,1.27.06,1.66.09.92.07,2.07.08,3.02,0,.93-.07,1.24-.09,2.03-.11.85-.03,2.69-.11,3.27-.16,1.28-.09,5.48-.19,6.02-.15,1.08.09,1.33.14,1.61.34.14.1.2.17.26.32.07.19.08.25.09,1.27.02,1.72-.09,2.5-.4,2.82-.06.06-.19.13-.37.19-.27.09-.3.09-1.09.09-.44,0-1.13,0-1.53.01h-.72s.23,25.7.23,25.7l.02,1.44h-.18c-.1,0-.67.01-1.26.02-.62.01-1.08,0-1.09-.01l-.12-27.11s-1.83,0-4.05.03c-4.83.06-3.65.06-8.5,0-2.22-.03-4.05-.04-4.05-.03-.02.02-.06,6.83-.08,17.06-.01,5.51-.03,10.03-.04,10.05-.01.02-.48.02-1.14.01-.62-.01-1.18-.02-1.26-.02h-.13l.02-2.39.2-22.69v-2.03s-.9-.03-.9-.03c-.5-.01-1.13-.02-1.39,0-.27.01-.56,0-.67-.02-.95-.18-1.05-.41-1.11-2.53-.05-1.61,0-1.89.34-2.14Z"/>
          <path className="cls-124" d="m317.43,2493.24s.13.02.26.02c.25-.01,1.12.09,1.99.22,1.25.19,2.98.36,4.83.47.34.02.99.06,1.43.09.52.03,1.92.06,3.9.08,1.7.02,3.64.05,4.31.06.67.01,1.55.01,1.96,0,.48-.02.75-.02.78,0,.11.09.08,2.9-.03,4.22-.1,1.08-.28,2.15-.45,2.66-.11.33-.38.92-.61,1.34-.33.59-.65.95-1.2,1.33-.88.61-2.23.99-4.88,1.33-3.28.43-5.26.56-9.6.62-2.28.03-3.87.07-6.55.14-.65.02-1.7.03-2.32.03h-1.13s-.02.11-.02.11c-.01.06-.03.29-.04.51-.01.22-.09.99-.18,1.72-.34,2.91-.57,4.9-.62,5.4-.03.28-.1.93-.16,1.43-.12,1.1-.25,2.32-.34,3.28-.04.39-.16,1.5-.28,2.46-.11.97-.27,2.35-.34,3.07-.08.72-.21,1.88-.29,2.59-.08.7-.24,2-.34,2.9-.32,2.76-.57,4.8-.64,5.36l-.08.54-.18.02c-.65.08-4.08.04-4.17-.05-.04-.03-.11-.66-.18-1.57-.03-.38-.08-.99-.11-1.36-.03-.37-.12-1.36-.21-2.21-.41-4.1-.58-6.06-.79-9.22-.21-3.24-.27-4.88-.27-8.29,0-4.77.11-6.86.51-9.22.22-1.32.39-1.94.76-2.78.35-.79.73-1.43,1.24-2.11.42-.55,1.21-1.27,1.89-1.72.64-.43,2.13-1.13,3.03-1.44.36-.12,2.26-.72,2.69-.84,1.52-.44,3.29-.82,4.88-1.02.31-.04.75-.1.97-.14.43-.08.52-.08.56-.04Z"/>
          <path className="cls-87" d="m318.63,2456.83s-.14-.03-.39.03c-1.17.24-2.68-.31-3.74-1.35-1-.99-1.74-2.75-2.08-4.91-.09-.57-.1-.79-.1-2.12,0-1.12.02-1.66.08-2.21.53-4.84,2.27-8.45,5.49-11.39,1.83-1.68,3.59-2.55,5.51-2.76.55-.06,1.35-.05,1.87.02.86.12,1.51.32,2.29.72.75.38,1.33.79,1.91,1.35.58.55.94,1.04,1.49,1.94.98,1.62,1.4,2.76,1.6,4.43.08.66.07,2.32-.02,3.07-.25,2.08-.82,4.11-1.75,6.23-.14.31-.57,1.19-.96,1.94l-.7,1.38v.03c-2.97,2.35-6.48,3.94-10.19,5.45-.05-.26-.09-.52-.13-.78-.09-.55-.18-1.04-.2-1.06Z"/>
          <path className="cls-59" d="m325.37,2446.61c.12.13.09.26-.09.38-.21.14-1.42,1.35-1.79,1.77-.41.48-.42.66-.08,1.22.43.71.78,1.05,1.06,1.05.34,0,.59-.15,1.51-.92.25-.21.31-.21.41-.07.07.1.07.12,0,.21-.04.06-.22.24-.41.4-.6.53-1.14,1.07-1.8,1.79-1.06,1.17-1.5,1.58-2.59,2.39-.81.6-2.34,1.69-2.79,1.98-.66.42-1.38.63-1.98.57-.69-.07-1.6-.43-2.02-.81-.29-.27-.4-.47-.44-.8-.03-.24-.03-.24-.19-.27-.7-.11-1.26-.57-1.65-1.35-.25-.51-.34-.86-.34-1.38,0-.54.09-.85.33-1.12.3-.32.34-.33,1.66-.53,1.11-.17,1.54-.26,2.59-.55,1.11-.3,2.08-.61,3.43-1.09,1.22-.43,1.59-.6,2.53-1.16.74-.44.86-.52,1.8-1.28.35-.28.67-.52.7-.52.03,0,.09.03.13.08Z"/>
          <path className="cls-59" d="m312.97,2452.25c.61.41.97.69.97.76,0,.03-.02.08-.05.11-.07.06-.2,0-.52-.26-.13-.11-.37-.28-.53-.38-.16-.1-.29-.21-.3-.23,0-.02,0-.1.01-.16l.02-.11.4.27Z"/>
          <path className="cls-42" d="m325.93,2430.77c1.07.07,2.35.33,3.25.67,1.49.56,2.97,1.62,3.98,2.82.56.66,1.28,1.91,1.66,2.87.4,1.01.61,2.98.46,4.43-.18,1.77-.66,3.67-1.27,4.98-.97,2.08-2.82,5.11-4.4,7.17-.32.42-.32.42-.48.4-.44-.06-1.19-.2-1.42-.26l-.25-.07-.08-.27c-.05-.14-.18-.58-.29-.97-.11-.38-.33-1.11-.48-1.62-.15-.51-.36-1.21-.46-1.57-.27-.96-.49-1.7-.78-2.54-.14-.41-.36-1.07-.48-1.48-.35-1.15-.33-1.11-1.06-1.58-.76-.49-1.9-1.18-2.55-1.53-1.55-.86-4.26-2.03-6.13-2.63-.74-.24-.84-.28-.84-.37,0-.14.23-1.05.42-1.66.65-2.13,1.66-3.79,2.93-4.81,1.9-1.52,4.73-2.2,8.28-1.97Z"/>
          <path className="cls-42" d="m282.74,2489.4c.29.01,1.01.04,1.6.05.59.01,1.08.03,1.09.04.05.03,4.34.07,7.6.07,3.37,0,4.95-.02,6.82-.08l.75-.02-.02.5c-.01.27-.03.7-.04.94-.01.24-.03.8-.05,1.22-.02.43-.06,1.32-.09,1.99s-.07,1.54-.09,1.95c-.09,1.98-.14,2.99-.19,3.79-.02.48-.06,1.07-.07,1.31-.01.24-.04.86-.07,1.38-.08,1.39-.11,2.11-.26,5.64-.02.43-.04.91-.05,1.05-.01.14-.03.44-.04.67-.07,1.35-.32,4.95-.52,7.24-.01.11-.03.42-.05.69-.02.27-.05.67-.07.9-.02.23-.05.63-.07.9-.02.27-.06.79-.09,1.15-.03.37-.09,1.21-.14,1.88-.11,1.47-.21,2.78-.4,4.84-.26,2.95-.37,4.51-.55,7.64-.08,1.33-.15,2.51-.16,2.62l-.02.21-.59.02c-.73.03-2.87.08-3.64.1h-.57s-.04-.47-.04-.47c-.08-.98-.19-2.58-.32-4.86-.3-5.14-.36-6.48-.43-9.76-.07-2.89-.1-4.38-.14-5.22-.02-.47-.03-.86-.09-2.57-.02-.63-.08-2.33-.14-3.79-.08-1.93-.11-3.29-.12-4.99-.01-1.29-.02-2.33-.03-2.32-.02.02-.45,4.17-.67,6.49-.29,3.04-.45,4.93-.57,6.71-.05.77-.28,3.99-.38,5.4-.03.4-.08,1.17-.12,1.72-.04.55-.12,1.71-.19,2.57-.07.86-.14,1.95-.17,2.43-.32,5.23-.57,9.23-.58,9.25-.02.03-3.77.16-3.9.13-.04-.01-.17-.02-.3-.02h-.23s-.02-.29-.02-.29c-.03-.47-.4-6.85-.45-7.97-.01-.21-.04-.84-.07-1.4-.03-.56-.07-1.37-.09-1.79-.05-1.11-.13-2.79-.19-3.96-.03-.56-.08-1.5-.1-2.09-.07-1.39-.2-3.48-.34-5.72-.16-2.36-.22-3.73-.29-6.38-.03-1.13-.04-1.53-.09-2.55-.01-.23-.04-.98-.07-1.67-.14-3.48-.19-4.6-.22-5.29-.08-1.78-.14-2.95-.16-3.19-.02-.27-.1-2.03-.14-3.03-.01-.31-.02-1.35-.02-2.31,0-1.49,0-1.74.05-1.74.03,0,.29,0,.58.02h0Z"/>
          <path className="cls-59" d="m301.78,2465.7c-.11-.03-.11-.02-.03.7.17,1.73.34,3.85.55,6.99.31,4.61.46,6.58.79,10.7.47,5.77.78,10.06,1.02,14.2.06,1,.12,2.07.14,2.38.13,2.06.03,10.83-.16,14.7-.03.57-.06,1.1-.07,1.18l-.02.14-.25.04c-1.17.18-3.17.39-4.68.49-3.77.25-7.2.23-10.82-.05-3.1-.24-7.68-.91-11.15-1.64-.74-.16-.86-.19-.88-.25-.03-.07-.06-.64-.14-2.21-.03-.63,0-2.27.06-3.91.01-.26.03-.88.05-1.38.1-2.7.36-7.42.71-13.1.58-9.24,1.06-14.6,1.92-21.2.4-3.07,1.15-7.91,1.7-10.89.69-3.76,1.5-7.15,2.17-9.12.57-1.66,1.29-3.09,2.02-4,.69-.87,1.26-1.34,2.52-2.14.36-.22.78-.41,1.3-.59.69-.23,1.43-.31,2.3-.26.71.04,1.73.28,2.59.59.44.16,1.19.53,1.61.79,1.17.72,1.92,1.96,2.72,3.25.2.32.66.7,1.74,1.82.4.42.7.96.9,1.19.21.23.35,1,.62,1.27.27.28.34,1.21.64,1.55"/>
          <path className="cls-59" d="m297.94,2538.41c1.86,1.53,2.48,2.13,2.99,2.89.24.36.3.5.3.71,0,.22-.06.33-.25.47-.69.47-2.25-.23-5.52-2.45-.66-.45-1.02-.72-1.93-1.45l-.6-.48.43-.02c.38-.02,1.74-.07,3.43-.11l.59-.02.57.47Z"/>
          <path className="cls-59" d="m289.38,2539.14c1.23.93,2.61,2.08,3.24,2.71.47.47.72.79.9,1.15.11.21.13.3.14.51.01.21,0,.27-.06.37-.2.33-.89.42-1.68.21-.59-.16-1.72-.65-2.26-1-1.07-.68-2.95-2.09-3.83-2.86-.51-.44-1.42-1.4-1.38-1.44.02-.02,2.84-.09,3.62-.09h.71s.59.44.59.44Z"/>
          <path className="cls-72" d="m304.49,2424.69c1.65.23,3.02.91,4.12,2.05.6.62,1.16,1.53,1.54,2.47.22.55,1,2.94,1.13,3.44.53,2.12.56,4.48.11,7.19-.68,3.99-2.81,8.06-5.07,9.69-.82.59-1.48.89-2.43,1.09-1.63.34-3.49-.14-4.65-1.21-.07-.07-.16-.11-.19-.09-.14.08-.91,1.38-1.47,2.47l-.39.75-.12-.16c-.27-.36-2.23-2.01-3.02-2.55-.8-.54-2.58-1.33-3.4-1.52-.3-.07-.38-.11-.37-.17.18-.64,1.05-3.42,1.58-5.03l.68-2.09-.09-1.07c-.07-.89-.08-1.21-.03-1.83.18-2.45,1.04-5.22,2.37-7.59,1.78-3.19,3.88-4.99,6.6-5.67,1.05-.26,2.08-.32,3.11-.18Z"/>
          <path className="cls-49" d="m298.76,2416.99c.54.07,1.4.29,1.92.48.77.29,1.3.6,1.97,1.13l.35.29.4-.07c.66-.1,1.9-.14,2.57-.07,1.35.13,2.78.57,3.87,1.17,2.09,1.15,3.45,2.96,3.81,5.04.05.26.08.58.08.7,0,.21.02.24.46.66.66.63,1.1,1.3,1.37,2.13.18.54.23.81.28,1.44.07.94-.03,1.76-.33,2.65-.59,1.77-1.66,3.14-3.29,4.25-.28.2-.54.35-.56.35-.03,0-.13-.14-.24-.31-.61-.95-.98-1.39-1.57-1.82-1.28-.94-2.93-1.35-5.22-1.29-2.25.06-4.31.59-6.5,1.65-1.57.76-2.56,1.5-3.53,2.63-.67.78-.96,1.21-1.63,2.39-.32.58-.6,1.07-.61,1.08-.06.08-2.11-.76-3.23-1.31-1.22-.6-1.89-1.05-2.55-1.71-1.07-1.07-1.56-2.23-1.56-3.73,0-1.06.24-1.87.79-2.67.12-.18.22-.35.22-.37,0-.03-.09-.23-.2-.44-.49-.99-.64-1.63-.63-2.89,0-.81.02-1.01.14-1.57.37-1.76,1.11-3.22,2.1-4.15.75-.71,1.46-1.08,2.46-1.27.22-.05.45-.09.52-.11.07-.02.18-.15.3-.34,1.52-2.52,4.19-4,7.15-3.97.31,0,.71.03.87.05Z"/>
          <path className="cls-59" d="m300.5,2436.08c1.22,1.26,1.7,1.66,3.07,2.6.29.19.86.6,1.29.9,1.14.81,1.61,1.12,2.23,1.42l.56.27.66.03c.79.04,1.42.13,1.99.3.74.23.84.38.79,1.13-.06.93-.38,1.81-1.07,2.97-.6,1.01-1.24,1.83-2.2,2.8-.95.96-1.55,1.39-2.63,1.91-.85.4-1.09.46-2,.49-1.22.04-2.07-.11-2.9-.53-.38-.19-.56-.32-.87-.63-.45-.45-.62-.7-1.21-1.73-1.37-2.39-2.84-5.76-3.75-8.58-.33-1.04-.33-.91.01-1.29l.29-.32.12.44.12.43.16-.08c.25-.13,1.32-.8,1.69-1.07.56-.4,1.68-1.27,1.99-1.54l.29-.26-.22-.32-.23-.32.27-.12c.14-.07.29-.13.31-.13.03,0,.59.55,1.24,1.22Z"/>
          <g id="Buzzy_tegen_prikanst" data-name="Buzzy tegen prikanst">
            <path className="cls-125" d="m327.21,2476.34s1.61-.88,2.1.42-.67,1.65-.7,2.55,1.45,3.64-2.48,3.08-.82-5.97-.82-5.97l1.89-.09Z"/>
            <path className="cls-125" d="m323.73,2475.11s-.95-1.56-2.04-.68c-1.08.88-.24,1.76-.66,2.57s-3.06,2.43.62,3.9c3.68,1.47,3.68-4.77,3.68-4.77l-1.6-1.02Z"/>
            <ellipse className="cls-41" cx="323.8" cy="2479.56" rx="2.59" ry="2.38" transform="translate(-1942.01 1421.67) rotate(-57.53)"/>
            <ellipse className="cls-41" cx="325.47" cy="2476.21" rx="2.24" ry="2.07" transform="translate(-1938.42 1421.53) rotate(-57.53)"/>
            <circle className="cls-57" cx="325.03" cy="2475.3" r=".38"/>
            <circle className="cls-57" cx="326.46" cy="2475.92" r=".38"/>
          </g>
          <path className="cls-49" d="m321.99,2475.82c.34.29.59.54,1.17,1.19,1,1.12,1.29,1.69,1.06,2.09-.06.1-.1.13-.25.17-.29.08-.63.02-2.52-.44-1.74-.42-3.69-.77-4.55-.8-.52-.02-.93-.07-1.02-.12-.08-.05-.09-.05.09-.89.15-.73.45-1.91.55-2.22.03-.1.05-.1.45-.11,1.52-.02,3,.14,3.78.42.36.13.94.47,1.23.71Z"/>
          <path className="cls-59" d="m298.83,2452.48c.59.42,1.18,1.05,1.65,1.8.73,1.14,1.45,3.02,1.96,5.14.84,3.43,1.37,6.69,1.49,9.14.02.33.03.62.03.65,0,.04.2,2.68.71,2.77.85.14,1.86.34,2.83.56,1.19.27,3.1.69,3.75.81,1.89.36,5.76,1.15,5.78,1.22.05.16-.4,2.24-.65,3.38l-.08.36-.36.14c-.8.29-5.5.11-6.64.02-3.36-.18-3.99-.15-5.85-.41-1.05,0-1.23-.22-2.39-.6-.54-.25-.74-.43-1.2-.71-1.05-.63-1.94-1.46-2.84-2.66-.81-1.08-2.03-3.01-2.43-3.87-.94-1.99-1.84-4.46-2.35-6.43-.78-3.02-.87-5.95-.23-8.09.37-1.26,1.01-2.21,1.99-2.96.8-.61,1.91-.95,2.9-.89.57.04.9.11,1.36.32.22.1.47.23.57.3Z"/>
          <path className="cls-128" d="m292.25,2455.86c.59.42,1.18,1.05,1.65,1.8.73,1.14,1.45,3.02,1.96,5.14.84,3.43,1.37,6.69,1.49,9.14.02.33.03.62.03.65,0,.04.2,2.68.71,2.77.85.14,1.86.34,2.83.56,1.19.27,3.1.69,3.75.81,1.89.36,5.76,1.15,5.78,1.22.05.16-.4,2.24-.65,3.38l-.08.36-.36.14c-.8.29-5.5.11-6.64.02-3.36-.18-3.99-.15-5.85-.41-1.05,0-1.23-.22-2.39-.6-.54-.25-.74-.43-1.2-.71-1.05-.63-1.94-1.46-2.84-2.66-.81-1.08-2.03-3.01-2.43-3.87-.94-1.99-1.84-4.46-2.35-6.43-.78-3.02-.87-5.95-.23-8.09.37-1.26,1.01-2.21,1.99-2.96.8-.61,1.91-.95,2.9-.89.57.04.9.11,1.36.32.22.1.47.23.57.3Z"/>
          <g>
            <path className="cls-46" d="m310.17,2477.86s2.91.84,3.34.71c.43-.13,2.74-1.15,3.28-1.09.54.06,3.24.33,3.48.39.25.06.36.86-.45,1.08-.81.22-1.66.09-1.81.09s-.68.21-.65.32c.03.12,1.92.63,2.46.7.54.06,3.36.05,3.76.35.41.3-.17,1.01-1.34,1.01-1.17,0-1.91,0-1.91,0,0,0-.75,1.62-1.12,1.9-.37.28-1.95.3-3.28.14s-2.59-.82-3.09-1.3l-3.28-.54.6-3.77Z"/>
            <g>
              <g>
                <path className="cls-97" d="m320.35,2480.68l1.04-.46,2.56,3.86c-.09.08-.25.2-.45.29-.17.08-.34.12-.51.13l-2.63-3.82Z"/>
                <path className="cls-48" d="m322.13,2482.28l.53-.21,1.38,2s-.24.52-1.11.51l-1.41-2.04.61-.26Z"/>
                <path className="cls-57" d="m321.45,2479.99l-1.38.61,2.8,4.07c.27.01.52-.06.72-.15.37-.17.59-.41.59-.41l-2.73-4.12Zm2.04,4.38c-.17.08-.34.12-.51.13l-2.63-3.82,1.04-.46,2.56,3.86c-.09.08-.25.2-.45.29Z"/>
              </g>
              <path className="cls-57" d="m320.87,2480.1s-.14.21-.38.2l-1.03-1.5.42-.19.99,1.49Z"/>
              <path className="cls-57" d="m323.83,2484.28l.35.53s-.25.27-.61.26l-.34-.5c.21-.09.41-.19.61-.29Z"/>
              <polygon className="cls-57" points="319.74 2480.77 321.84 2479.84 321.58 2479.45 319.48 2480.39 319.74 2480.77"/>
              <polygon className="cls-57" points="319.1 2479.08 320.29 2478.54 320.15 2478.32 318.95 2478.86 319.1 2479.08"/>
              <path className="cls-57" d="m320.32,2480.05s.24-.07.48-.06l1.51,2.19-.42.19-1.56-2.31Z"/>
              <polygon className="cls-57" points="322.65 2481.9 321.46 2482.44 321.61 2482.65 322.8 2482.12 322.65 2481.9"/>
              <polygon className="cls-57" points="323.93 2484.88 324.9 2486.45 323.8 2484.94 323.93 2484.88"/>
            </g>
            <path className="cls-46" d="m320.11,2482.66s.57-.26.75-.44c.17-.18.22-.56.25-.73s-.41-1.39-.71-1.49c-.29-.1-.75.3-.6,1.09.14.79.31,1.57.31,1.57Z"/>
            <path className="cls-46" d="m319.17,2483.09s.7.14,1.08-.08.33-1.65.19-1.99c-.14-.34-.73-.53-1.09.17-.36.7-.18,1.9-.18,1.9Z"/>
            <path className="cls-46" d="m317.89,2483.12c.05.21.25.46.76.39s.67-.09.76-.2c.09-.11.36-.83.31-1.06-.05-.24-.22-1.26-.55-1.37-.33-.11-.82.3-.68.93.14.63.14.76.14.76,0,0-.89-.17-.73.55Z"/>
          </g>
        </g>
        <text transform="translate(39.3 2589.44)"><tspan className="cls-5"><tspan x="0" y="0">10 jaar </tspan></tspan><tspan className="cls-115"><tspan x="-11.63" y="16">Prema</tspan><tspan className="cls-112" x="26.23" y="16">T</tspan><tspan x="33.05" y="16">uurlijk </tspan></tspan><tspan className="cls-115"><tspan x=".66" y="32">spreekuur</tspan></tspan></text>
        <text transform="translate(219.14 2604.82)"><tspan className="cls-5"><tspan className="number" x="0" y="0">90</tspan></tspan><tspan className="cls-115"><tspan x="-43.76" y="16">bezoekers speciale </tspan></tspan><tspan className="cls-115"><tspan x="-37.34" y="32">zwangerschaps</tspan><tspan x="52.47" y="32">-</tspan></tspan><tspan className="cls-115"><tspan x="-22.78" y="48">gesprekken</tspan></tspan></text>
        <text transform="translate(256.15 2349.57)"><tspan className="cls-5"><tspan className="number" x="0" y="0">253</tspan></tspan><tspan className="cls-115"><tspan x="-45.88" y="16">zorgverleners krijgen </tspan></tspan><tspan className="cls-115"><tspan x="-32.86" y="32">nieuwsbrief over </tspan></tspan><tspan className="cls-115"><tspan x="-37.23" y="48">prenataal aanbod</tspan></tspan></text>
        <g id="Vrouw_rechts" data-name="Vrouw rechts">
          <path className="cls-42" d="m170.05,2590.18s.86-.79,1.84-1.73c.99-.94,1.81-1.71,1.82-1.71.06,0,1.2,1.19,1.36,1.41.43.62.73,1.37.7,1.72-.07.68-.72,1.61-2.38,3.43-1.31,1.42-2.83,3-3.34,3.46-1.48,1.34-2.99,2.53-3.57,2.81-.82.4-1.66.4-2.05,0-.4-.42-.41-1.26-.01-2.4.47-1.38,1.31-2.54,3.34-4.64.68-.7,1.46-1.52,1.74-1.81.28-.29.53-.54.56-.55Z"/>
          <path className="cls-42" d="m175.88,2594.32s.86-.79,1.84-1.73c.99-.94,1.81-1.71,1.82-1.71.06,0,1.2,1.19,1.36,1.42.43.62.74,1.37.7,1.72-.07.68-.72,1.62-2.38,3.43-1.31,1.42-2.83,3-3.34,3.46-1.48,1.34-2.99,2.53-3.57,2.81-.82.4-1.66.4-2.06,0-.4-.42-.41-1.26-.01-2.4.47-1.38,1.31-2.55,3.34-4.64.68-.7,1.46-1.52,1.74-1.81.28-.29.53-.54.56-.55Z"/>
          <g id="chair_2">
            <rect className="cls-11" x="195.66" y="2567.55" width="3.01" height="27.18" transform="translate(394.33 5162.29) rotate(180)"/>
            <path className="cls-11" d="m197.16,2594.73h0c-4.33,0-15.2,2.52-15.2,5.62h30.4c0-3.1-10.87-5.62-15.2-5.62Z"/>
            <path className="cls-11" d="m218.1,2518.36s-19.43-1.29-20.23,14.39c-.56,11.05-1.48,20.39-4.42,21.53-2.99,1.15-6.7.84-11.76,1.7-8.4,1.44-7.66,7.43-7.66,7.43,0,0,21.52,6.75,31.84,3.05,8.19-2.94,10.29-12.66,11.11-18.06,2.07-13.76,1.13-30.03,1.13-30.03Z"/>
          </g>
          <g>
            <path className="cls-82" d="m201.7,2528.17l4.16,3.89s6,9.32,5.22,16.67c-.78,7.34-3.06,10.97-10.09,10.97h-20.14c-.46,0,9.29,30.97,9.58,32.34.28,1.37-12.94,6.86-18.84,3.82,0,0-.39-.34-.46-1.32-.07-.98-.04-2.23-.04-2.23,0,0-10.02-3.97-11.48-7.15,0,0-.14-.48,0-1.19.11-.53,4.13-19.44,8.32-30.66,4.96-13.28,13.03-11.77,22.67-11.57,0,0-.43-3.85-.23-5.23.21-1.38.63-2.3,1.37-2.5.55-.14-.05-.72-.41-1.01,3.43-2.22,10.35-4.84,10.35-4.84Z"/>
            <path className="cls-82" d="m201.7,2528.17l4.16,3.25s6,7.78,5.22,13.9c-.78,6.12-3.06,9.15-10.09,9.15h-20.14s-1.86-4.68-2.13-5.58c-.27-.9-.44-1.86-.05-1.69.39.16.78,4.53,8.67,4.53s16.71-4.61,16.07-8.53c-.64-3.92-5.83-3.7-6.9-3.7h-5.89s-.43-3.21-.23-4.36c.21-1.15.63-1.92,1.37-2.08.55-.12-.05-.6-.41-.84,3.43-1.85,10.35-4.04,10.35-4.04Z"/>
            <path className="cls-82" d="m171.1,2592.31s-3.24-1.28-6.3-2.97c.99-1.27,2.59-3.48,2.79-4.62.28-1.66,2.06-20.62,2.23-20.69.17-.06.2.22.2.22l1.09,28.06Z"/>
            <path className="cls-82" d="m207.17,2509.57c-4.38-2.97-8-1.96-13.75-1.85-2.36.49-3.34,2.07-4.25,4.11-.75,1.69-.06,9.68-.07,10.42-.19,1.71-.32,4.02,1.55,7,.54,3.77-.93,10.15-.86,11.34.34,5.58,18.39,1.74,17.71-.8-3.36-12.68.49-19.2.29-24.07-.14-3.44-.4-5.53-.62-6.15Z"/>
            <path className="cls-82" d="m191.27,2512.77s2.42,4.48,2.83,5.63c.42,1.15-1.58,19.37-1.72,19.82-.14.45-1.74,1.48-1.74,1.48l.02.19-2.34-.07s.2-10.77.11-11.99c-.09-1.23-2.32-7.42-2.65-9.12-.33-1.71,3.01-10.61,3.01-10.61l2.47,4.68Z"/>
            <path className="cls-42" d="m204.15,2509.43s3.64.52,3.54,3.62c0,0,.64-.28.82-.94.18-.67,1.79-8.63,1.51-9.96-.28-1.33-1.31-3.35-3.34-5.5,0,0-6.2,8.31-2.52,12.79Z"/>
            <path className="cls-82" d="m198.13,2504.92s-5.97,16.96-6.11,17.5c-.14.53,5.04,16.17,5.36,16.65.33.48,2.79.59,3.99.85,1.21.27,8.49,1.43,9.86,2.72,0,0,.49-.37.33-1.22-.16-.85-3.62-9.44-3.76-10-.14-.56.23-17.57.23-17.57,0,0-1.65-11.31-9.91-8.92Z"/>
            <path className="cls-127" d="m199.34,2493.04l-.23,2.05s-.31,4.21-.36,4.85c-.05.64.4,1.81,2.01,2.06,1.32.2-3.21,2.38-5.9,1.76-2.79-.64-3.44-3.28-2.37-3.45.33-.05.69-.34.9-.57.67-.71.9-4.13.35-5.09-.55-.96,5.59-1.61,5.59-1.61Z"/>
            <path className="cls-42" d="m191.46,2476.08c1.25-2.11,3.14-3.72,5.77-4.09,5.18-.73,10.67,2.15,11.84,9.78.34,2.21.39,4.6.31,7.01l-1.98,1.87-.47,1.23-3.01-.99-9.71-2.59c.95-2.55,1.62-5.14,1.62-5.14l-5.32,1.87s-.65-5.54.95-8.95Z"/>
            <path className="cls-42" d="m195.15,2514.68l-1.05,1.51s-.36,1.25-2.1-.65c-1.75-1.89-3.32-8.19-3.91-10.22-.59-2.03.44-4.3,3.38-7.03,2.94-2.73,3.48-3.64,3.48-3.64l10.54-5.77.28,8.74-10.62,17.06Z"/>
            <path className="cls-42" d="m192.86,2493.09l-1.3,1.31s-1.09.54-1.2,2.64c-.12,2.1,1.61,6.69,2.69,9.64,1.07,2.95,3.42,2.27,7.54-1.65,4.12-3.91,5.58-9.37,5.58-9.37l-.94-3.99-12.36,1.41Z"/>
            <path className="cls-42" d="m202.29,2489.56l1.37,3.57s-.19,4.01-3.21,7.74c-3.02,3.73-8.04,8.36-7.75,17.03,0,0,.02.63.54.71.51.08,3.05.15,4.74.72,0,0,1.19.39,1.43-.95,2.75-15.56,4.25-10.24,8.09-21.62,1.74-5.17,2.43-9.48,1.59-14.99-.91-5.94-4.44-9-8.4-9.72,2,1.76,4.42,6.12,3.4,16.9l-1.78.62Z"/>
            <path className="cls-82" d="m194.07,2484.33l-3.43-.41c.24-3.55.3-8.16,4.32-8.39,4.85-.28,9.67,8.22,9.11,13.42l-10.01-4.62Z"/>
            <path className="cls-68" d="m204.05,2489.63l-.24,2.52c-.42,4.36-3.86,7.54-7.66,7.06h0c-3.8-.48-6.56-4.44-6.14-8.8l.6-6.28h0s.52-4.25,3.93-5.42c3.41-1.17,10,4.41,9.51,10.92Z"/>
            <path className="cls-59" d="m190.92,2482.82c.44-1.35,1.43-3.36,3.62-4.11,2.57-.88,6.94,2.07,8.73,6.36-6.82-6.97-10.59-4.87-12.35-2.25Z"/>
            <path className="cls-82" d="m200.67,2507.05c-4.14,8.28-9.13,20.56-9.58,21.14-.53.68-.86.83-1.76.69-.91-.14-10.86-4.65-11.17-4.61-.31.04-1.68,2.17-1.71,2.57-.03.41,10.95,7.51,12.63,7.92,1.68.41,4.31-.77,5.58-2.45,1.27-1.68,9.48-15.89,9.48-15.89,6.76-9.12-.7-14.92-3.47-9.38Z"/>
            <ellipse className="cls-81" cx="194.88" cy="2534.1" rx="12.92" ry="15.69"/>
          </g>
          <path className="cls-67" d="m176.52,2527.07l-1.92-2.31c-1.09-.9-1.77-2.19-1.72-3.27l.03-.65c.04-.86,1.1-.94,1.92-.14l3.34,3.56"/>
        </g>
        <g id="Vrouw_links" data-name="Vrouw links">
          <g id="woman_1">
            <path className="cls-53" d="m165.47,2585.96l2.34,1.2s4.27,2.61,5.01,3.98c.74,1.37.58,6.16.21,6.85-.37.68-3.45.39-3.45.39l-6.33-7.94,2.22-4.47Z"/>
            <path className="cls-41" d="m129.12,2581.43s-3.93-4.12-4.44-4.98c-.73-1.24-3.25-15.46-3.25-15.46l5.77-3.25,6.32,22.5-4.41,1.18Z"/>
            <path className="cls-41" d="m124.23,2545.05s11.74.24,16.49.71c.86.09,1.49.18,1.78.28,1.91.66,3.25,18.39,3.67,21.49.42,3.09,1.07,11.84,1.07,11.84h-16.64s-4.67-15.2-5.63-17.74c-.96-2.54-2.55-11.16-2.23-14.04.32-2.87,1.49-2.54,1.49-2.54Z"/>
            <path className="cls-53" d="m170.55,2602.52l-9.93,3.68c0,2.1-5.01,37.08-5.01,37.08l8.11,2.36s.95.06,1.63-.41l-5.48-1.95,10.68-40.76Z"/>
            <path className="cls-53" d="m150.73,2643.28l9.29-37.17-9.78.02-3.84,37.15,9.26,2.74.82-.41-5.75-2.33Z"/>
            <path className="cls-41" d="m130.6,2579.38s-4.57,4.86-5.73,15.47c-.62,5.64-7.56,14.23,26.08,11.39.29,0,.18,3.02.18,3.02,0,0,16.8-1.34,20-5.84-.1-5.99.26-3.44-.1-5.99-.55-3.94-4.11-5.9-14.06-8.23-8.56-2-7.28-5.62-9.73-9.82-19.22,0-16.64,0-16.64,0Z"/>
            <path className="cls-41" d="m157.57,2646.02c-.72-.51-1.34-.61-1.78-.58-.83.06-1.66-.07-2.46-.28l-6.91-1.88-.02,4.66,12.48-.06s.13-.84-1.31-1.86Z"/>
            <path className="cls-41" d="m167.86,2646.37c-.33-.29-.86-.69-2.48-1.14-.6.41-1.44.41-1.44.41l-8.33-2.36.04,4.6,13.12.18s-.23-1.1-.91-1.7Z"/>
            <path className="cls-53" d="m142.07,2525.49s.37,13.65-1.38,14.04c-1.75.39-3.29-.11-3.29-.11l-4.67-3.21s-1.38-8.4,2.44-10.45c3.82-2.04,6.9-.28,6.9-.28Z"/>
            <path className="cls-53" d="m133.53,2536.21s4.03,2.6,3.87,3.21c-.16.61,0,4.92,0,4.92l-6.69-.31,2.82-7.81Z"/>
            <path className="cls-41" d="m141.61,2545.88s0,0,0,0c2.15-.02,4.05.8,5.34,4.58,1.18,3.48,3.23,18.48,3.86,23.26.12.94.62,1.77,1.37,2.31l13.45,9.61-2.55,5.13-17.31-8.68c-.89-.45-1.54-1.29-1.75-2.3l-5.47-25.66s-1.93-7.74,3.06-8.25Z"/>
            <path className="cls-41" d="m127.68,2544s8.23,0,9.72.33c1.49.33,2.45,1.35,2.45,1.35l-12.65-.65.48-1.04Z"/>
            <path className="cls-48" d="m141.29,2522.72s-2.76-5.16-10.19-3.83c-7.43,1.33-8.71,6.38-8.39,10.83.32,4.65-1.43,6.19-3.11,7.68-3.38,2.99-1.67,6.23-1.38,8.28.43,3.04-4.07,5.56-2.26,10.98,1.1,3.3,4.32,4.46,7.55,4.55,2.48.07,7.56-.18,8.05-4.5.59-5.18,3.59-5.09,4.3-9.32.88-5.26-1.4-5.15.09-9.83.92-2.89,5.94-8.51,6.14-11.38.2-2.87-.79-3.46-.79-3.46Z"/>
          </g>
          <g id="chair_2-2" data-name="chair_2">
            <rect className="cls-11" x="133.26" y="2612.48" width="3.03" height="30.51"/>
            <path className="cls-11" d="m134.77,2642.79h0c4.36,0,15.29,2.36,15.29,5.28h-30.58c0-2.92,10.93-5.28,15.29-5.28Z"/>
            <path className="cls-11" d="m113.71,2566.24s19.55-1.22,20.36,13.52c.57,10.38,1.49,19.17,4.45,20.23,3.01,1.08,6.74.78,11.83,1.6,8.45,1.35,7.71,6.99,7.71,6.99,0,0-21.65,6.35-32.03,2.86-8.24-2.76-10.35-11.9-11.17-16.98-2.08-12.93-1.14-28.23-1.14-28.23Z"/>
          </g>
        </g>
        <text transform="translate(94.95 2683.08)"><tspan className="cls-5"><tspan className="number" x="0" y="0">6</tspan><tspan>.</tspan><tspan>000</tspan></tspan><tspan className="cls-115"><tspan x="-15.15" y="16">Sint cadeaus </tspan></tspan><tspan className="cls-115"><tspan className="cls-118" x="-15.64" y="32">T</tspan><tspan className="cls-117" x="-8.7" y="32">oys 'n </tspan><tspan className="cls-111" x="29.6" y="32">W</tspan><tspan x="41.21" y="32">rap</tspan></tspan></text>
        <g id="Cadeautjes">
          <g>
            <rect className="cls-44" x="97.35" y="2644.31" width="10.38" height="14.6"/>
            <rect className="cls-11" x="83.25" y="2644.31" width="9.76" height="14.6"/>
            <polygon className="cls-11" points="83.22 2644.31 83.25 2644.31 83.25 2658.9 80.48 2658.9 80.48 2644.31 83.22 2644.31"/>
            <rect className="cls-11" x="76.93" y="2644.31" width="1.73" height="14.6"/>
            <polygon className="cls-11" points="93.02 2642.25 83.25 2642.25 83.22 2642.25 82.63 2642.25 82.63 2633.76 93.02 2633.76 93.02 2642.25"/>
            <polygon className="cls-11" points="97.35 2633.76 108.44 2633.76 108.44 2642.25 107.73 2642.25 97.35 2642.25 97.35 2633.76"/>
            <polygon className="cls-11" points="78.66 2633.76 78.66 2642.25 76.93 2642.25 76.53 2642.25 76.53 2633.76 78.66 2633.76"/>
            <rect className="cls-11" x="80.48" y="2633.76" width="2.15" height="8.49"/>
            <rect className="cls-11" x="97.35" y="2640.6" width="10.38" height="3.71"/>
            <rect className="cls-11" x="83.25" y="2642.25" width="9.76" height="2.06"/>
            <polygon className="cls-11" points="83.22 2642.25 83.25 2642.25 83.25 2644.31 83.22 2644.31 80.48 2644.31 80.48 2642.25 82.63 2642.25 83.22 2642.25"/>
            <rect className="cls-11" x="76.93" y="2642.25" width="1.73" height="2.06"/>
            <polygon className="cls-81" points="93.02 2642.25 93.02 2633.76 97.35 2633.76 97.35 2642.25 97.35 2644.31 97.35 2658.9 93.02 2658.9 93.02 2644.31 93.02 2642.25"/>
            <polygon className="cls-81" points="78.66 2642.25 78.66 2633.76 80.48 2633.76 80.48 2642.25 80.48 2644.31 80.48 2658.9 78.66 2658.9 78.66 2644.31 78.66 2642.25"/>
            <path className="cls-81" d="m102.66,2633.76h-8.6,0s-2.93,0-2.93,0h0s0,0,0,0h-8.7c-1.68,0-3.12-1.23-3.37-2.89-.24-1.57-.35-3.48.14-4.9,1-2.89,6.66-1.72,9.33,1,2.66,2.72,2.95,6.3,2.95,6.3h0c.25-.26.64-.52,1.19-.49.45.03.76.18.97.36.08-.66.56-3.76,2.93-6.17,2.66-2.72,8.33-3.89,9.33-1,.49,1.42.38,3.33.14,4.9-.26,1.66-1.69,2.89-3.37,2.89Zm-14.61-5.17c-1.91-1.87-5.96-2.68-6.68-.69-.26.71-.28,1.6-.21,2.45.14,1.66,1.53,2.92,3.19,2.92h5.86s-.26-2.8-2.17-4.68Zm15.67-.69c-.72-1.99-4.77-1.19-6.68.69-1.91,1.88-2.23,4.68-2.23,4.68h.36s5.56,0,5.56,0c1.66,0,3.06-1.27,3.19-2.92.07-.85.04-1.74-.21-2.45Z"/>
          </g>
          <g>
            <rect className="cls-90" x="112.62" y="2650.87" width="9.23" height="10.99"/>
            <rect className="cls-90" x="100.09" y="2650.87" width="8.68" height="10.99"/>
            <rect className="cls-90" x="94.47" y="2650.87" width="1.54" height="10.99"/>
            <polygon className="cls-90" points="100.06 2650.87 100.09 2650.87 100.09 2661.85 97.62 2661.85 97.62 2650.87 100.06 2650.87"/>
            <polygon className="cls-90" points="108.77 2649.32 100.09 2649.32 100.06 2649.32 99.54 2649.32 99.54 2642.93 101.14 2642.93 101.14 2642.93 103.78 2642.93 103.78 2642.93 108.42 2642.93 108.42 2642.93 108.42 2642.93 108.42 2642.93 108.77 2642.93 108.77 2649.32"/>
            <polygon className="cls-90" points="112.62 2642.93 114.66 2642.93 114.66 2642.93 117.3 2642.93 117.3 2642.93 122.47 2642.93 122.47 2649.32 121.84 2649.32 112.62 2649.32 112.62 2642.93"/>
            <polygon className="cls-90" points="96.01 2642.93 96.01 2649.32 94.75 2649.32 94.47 2649.32 94.47 2642.93 96.01 2642.93"/>
            <rect className="cls-90" x="97.62" y="2642.93" width="1.91" height="6.39"/>
            <rect className="cls-90" x="112.62" y="2649.32" width="9.23" height="1.55"/>
            <rect className="cls-90" x="100.09" y="2649.32" width="8.68" height="1.55"/>
            <polygon className="cls-90" points="100.06 2649.32 100.09 2649.32 100.09 2650.87 100.06 2650.87 97.62 2650.87 97.62 2649.32 99.54 2649.32 100.06 2649.32"/>
            <rect className="cls-90" x="94.47" y="2649.32" width="1.54" height="1.55"/>
            <polygon className="cls-41" points="108.77 2649.32 108.77 2642.93 110.02 2642.93 112.62 2642.93 112.62 2649.32 112.62 2650.87 112.62 2661.85 108.77 2661.85 108.77 2650.87 108.77 2649.32"/>
            <polygon className="cls-41" points="96.01 2649.32 96.01 2642.93 97.62 2642.93 97.62 2649.32 97.62 2650.87 97.62 2661.85 96.01 2661.85 96.01 2650.87 96.01 2649.32"/>
            <path className="cls-41" d="m114.66,2642.93h0c2.17-1.13-.02-3.25-1.31-2.66-1.29.59-3.33,2.65-3.33,2.66v-1.17h0c2.19-4.93,5.09-4.38,6.66-3,1.56,1.38.63,4.17.63,4.18h0s-2.64,0-2.64,0Z"/>
            <path className="cls-41" d="m108.42,2642.93s-2.04-2.07-3.33-2.66c-1.29-.59-3.48,1.52-1.31,2.66h0s-2.64,0-2.64,0h0s-.94-2.8.63-4.18c1.56-1.38,4.47-1.93,6.66,3v1.17h0s0,0,0,0Z"/>
            <path className="cls-41" d="m110.02,2642.93h0s-1.25,0-1.25,0h-.34v-1.17c.77-.69,1.59,0,1.59,0h0v1.17Z"/>
          </g>
          <g>
            <path className="cls-41" d="m74.77,2652.63c-.02.55-.05.9-.05.9l.83-.9h.37l.73.9s0,0,0-.9h1.7v8.35h-5.28v-8.35h1.71Z"/>
            <path className="cls-41" d="m67.1,2652.63q0,.9,0,.9l.73-.9h.37l.83.9s-.03-.35-.05-.9h1.88v8.35h-4.97v-8.35h1.21Z"/>
            <rect className="cls-41" x="62.67" y="2652.63" width=".88" height="8.35"/>
            <polygon className="cls-41" points="65.87 2652.63 65.89 2652.63 65.89 2660.99 64.47 2660.99 64.47 2652.63 65.87 2652.63"/>
            <path className="cls-41" d="m70.36,2646.59h.5v4.86h-1.89c.03-1.8.28-4.21,1.4-4.86Z"/>
            <path className="cls-41" d="m73.06,2646.59h.32c1.11.66,1.37,3.06,1.4,4.86h-1.72v-4.86Z"/>
            <path className="cls-41" d="m76.63,2650.24c-.03-3.29-1.38-3.65-1.38-3.65h-1.77s2.43,0,2.43,0c0,0,0,0,0,0h2.79v4.86h-2.07c0-.34,0-.75,0-1.21Z"/>
            <path className="cls-41" d="m65.87,2651.46h-.3v-4.86h2.54s0,0,0,0h.39s0,0,0,0h1.52s.07,0,.07,0h0s.19,0,.19,0h-1.77s-1.35.36-1.38,3.65c0,.47,0,.87,0,1.21h-1.24Z"/>
            <rect className="cls-41" x="64.47" y="2646.59" width="1.1" height="4.86"/>
            <polygon className="cls-41" points="63.55 2646.59 63.55 2651.46 62.67 2651.46 62.47 2651.46 62.47 2646.59 63.55 2646.59"/>
            <polygon className="cls-41" points="68.19 2652.63 67.82 2652.63 67.99 2652.42 68.19 2652.63"/>
            <path className="cls-41" d="m68.97,2651.46h1.89v1.18h-1.88c-.01-.33-.02-.74-.01-1.18Z"/>
            <path className="cls-41" d="m67.11,2651.46c0,.51,0,.89,0,1.18h-1.21v-1.18h1.22Z"/>
            <polygon className="cls-41" points="75.75 2652.42 75.93 2652.63 75.56 2652.63 75.75 2652.42"/>
            <path className="cls-41" d="m73.06,2651.46h1.72c0,.44,0,.84-.01,1.18h-1.71v-1.18Z"/>
            <path className="cls-41" d="m76.64,2651.46h1.71v1.18h-1.7c0-.29,0-.67,0-1.18Z"/>
            <rect className="cls-41" x="62.67" y="2651.46" width=".88" height="1.18"/>
            <polygon className="cls-41" points="65.87 2651.46 65.89 2651.46 65.89 2652.63 65.87 2652.63 64.47 2652.63 64.47 2651.46 65.57 2651.46 65.87 2651.46"/>
            <path className="cls-92" d="m70.86,2651.46v-4.86h-.5c-1.11.66-1.37,3.06-1.4,4.86,0,.44,0,.84.01,1.18.02.55.05.9.05.9l-.83-.9-.2-.21-.17.21-.73.9s0,0,0-.9c0-.29,0-.67,0-1.18,0-.34,0-.75,0-1.21.03-3.29,1.38-3.65,1.38-3.65h1.77s-.19,0-.19,0h0s.78,0,.78,0h.41s0,0,0-.01c.1,0,.21,0,.33.01h.39c2.64-.04,2.75-2.3,1.37-2.2-1.38.09-2.75,1.56-2.75,1.56,0,0,0,0,0,0-.16-.05-.34-.08-.53-.08h0s2.28-3.74,4.06-3.85c1.77-.11,3.53,3.15,1.78,4.57,0,0,0,0,0,0h-2.43,1.77s1.35.36,1.38,3.65c0,.47,0,.87,0,1.21,0,.51,0,.89,0,1.18q0,.9,0,.9l-.73-.9-.17-.21-.2.21-.83.9s.03-.35.05-.9c.01-.33.02-.74.01-1.18-.03-1.8-.28-4.21-1.4-4.86h-.32v14.39h-2.2v-9.53Z"/>
            <polygon className="cls-92" points="63.55 2651.46 63.55 2646.59 64.18 2646.59 64.18 2646.59 64.47 2646.59 64.47 2651.46 64.47 2652.63 64.47 2660.99 63.55 2660.99 63.55 2652.63 63.55 2651.46"/>
            <path className="cls-92" d="m69.48,2645.95s-1.37-1.46-2.75-1.56c-1.38-.09-1.27,2.16,1.37,2.2,0,0,0,0,0,0h-3.93s0,0,0,0c-1.75-1.42,0-4.68,1.78-4.57,1.78.11,4.06,3.85,4.06,3.85h0c-.18,0-.36.04-.51.09-.01,0-.02-.01-.02-.01Z"/>
            <path className="cls-63" d="m68.5,2646.59s0,0,0,0c.15,0,.28,0,.4-.02,0,0,0,.01,0,.02h1.12s-1.52,0-1.52,0Z"/>
            <path className="cls-92" d="m70.61,2645.95c.38.12.65.36.65.64,0,0,0,0,0,.01h-2.37s0-.01,0-.02c0-.27.25-.5.61-.62.15-.05.32-.08.51-.09.02,0,.04,0,.07,0h0c.19,0,.37.03.53.08Z"/>
          </g>
          <g>
            <path className="cls-65" d="m74.39,2657.65c0,1.12.25,2.12.25,2.12l.78-1.25,1.02,1.25s-.06-1.06.01-2.12h4.33v4.32h-9.09v-4.32h2.69Z"/>
            <rect className="cls-65" x="83.63" y="2657.65" width="9.81" height="4.32"/>
            <rect className="cls-65" x="66.07" y="2657.65" width="1.54" height="4.32"/>
            <polygon className="cls-65" points="71.67 2657.65 71.7 2657.65 71.7 2661.98 69.23 2661.98 69.23 2657.65 71.67 2657.65"/>
            <path className="cls-65" d="m73.7,2654.53s0,0,0,0h2.53c-.32.1-.62.24-.89.43-.62.44-.87,1.25-.94,2.09h-3.27v-2.52h.72s0,0,0,0h1.84Z"/>
            <polygon className="cls-65" points="83.63 2654.53 86.48 2654.53 86.48 2654.53 88.32 2654.53 88.32 2654.53 94.08 2654.53 94.08 2657.04 93.45 2657.04 83.63 2657.04 83.63 2654.53"/>
            <path className="cls-65" d="m76.93,2655.66c.52-.63,2.01-.99,2.64-1.12.01,0,.02,0,.04,0h1.18v2.52h-4.28c.07-.59.2-1.13.42-1.39Z"/>
            <polygon className="cls-65" points="67.61 2654.53 67.61 2657.04 66.07 2657.04 65.73 2657.04 65.73 2654.53 67.61 2654.53"/>
            <rect className="cls-65" x="69.23" y="2654.53" width="1.91" height="2.52"/>
            <polyline className="cls-65" points="93.45 2657.04 93.45 2657.65 83.63 2657.65 83.63 2657.04"/>
            <path className="cls-65" d="m74.41,2657.04c-.02.2-.02.41-.02.61h-2.69v-.61h2.71Z"/>
            <path className="cls-65" d="m80.78,2657.04v.61h-4.33c.01-.21.03-.41.06-.61h4.28Z"/>
            <rect className="cls-65" x="66.07" y="2657.04" width="1.54" height=".61"/>
            <polygon className="cls-65" points="71.67 2657.04 71.7 2657.04 71.7 2657.65 71.67 2657.65 69.23 2657.65 69.23 2657.04 71.14 2657.04 71.67 2657.04"/>
            <polygon className="cls-54" points="80.78 2657.04 80.78 2654.53 80.78 2654.53 83.63 2654.53 83.63 2657.04 83.63 2657.65 83.63 2661.98 80.78 2661.98 80.78 2657.65 80.78 2657.04"/>
            <polygon className="cls-65" points="67.61 2657.04 67.61 2654.53 69.23 2654.53 69.23 2657.04 69.23 2657.65 69.23 2661.98 67.61 2661.98 67.61 2657.65 67.61 2657.04"/>
            <path className="cls-54" d="m86.48,2654.53s0,0,0,0c.7-1.27-.12-2.37-1.64-2.14-1.22.18-3.28,1.44-4.05,1.94,0-.17-.08-.31-.19-.41.68-.75,2.07-2,4.25-2.73,3.25-1.1,4.18,2.15,3.48,3.34,0,0,0,0,0,0h-1.84Z"/>
            <path className="cls-54" d="m79.42,2654.34h0c-.76-.48-2.84-1.76-4.07-1.95-1.53-.23-2.35.87-1.64,2.14,0,0,0,0,0,0h-1.84s0,0,0,0c-.7-1.19.23-4.44,3.48-3.34,2.19.74,3.58,2,4.26,2.74-.11.1-.18.25-.18.42Z"/>
            <path className="cls-54" d="m74.41,2657.04c.07-.83.32-1.65.94-2.09.27-.19.57-.33.89-.43,1.18-.38,2.56-.21,3.18-.1v.1h.19s-.02,0-.04,0c-.63.13-2.12.49-2.64,1.12-.22.26-.35.79-.42,1.39-.02.2-.04.4-.06.61-.07,1.07-.01,2.12-.01,2.12l-1.02-1.25-.78,1.25s-.25-1-.25-2.12c0-.2,0-.41.02-.61Z"/>
            <path className="cls-54" d="m80.78,2654.33s0,0,0,.01v.19h0s-.31,0-.31,0h-1.06v-.19h0c0-.17.07-.31.18-.42.1-.09.24-.15.38-.15h.23c.14,0,.27.05.37.14.12.1.19.25.19.41Z"/>
          </g>
        </g>
        <text transform="translate(79.43 2441.51)"><tspan className="cls-4"><tspan className="number" x="0" y="0">40</tspan></tspan><tspan className="cls-115"><tspan x="-26.23" y="16">CJG’ers helpen bij </tspan></tspan><tspan className="cls-115"><tspan x="-42.75" y="32">GGD-Boostercampagne</tspan></tspan></text>
        <g id="Corona">
          <circle className="cls-33" cx="38.54" cy="2437.9" r="7"/>
          <line className="cls-15" x1="50.55" y1="2436.04" x2="45.44" y2="2436.75"/>
          <circle className="cls-15" cx="27.54" cy="2427.9" r="1"/>
          <polyline className="cls-15" points="40.24 2431.11 42.19 2425.83 42.2 2425.83"/>
          <line className="cls-15" x1="41.3" y1="2448.93" x2="40.24" y2="2444.69"/>
          <line className="cls-15" x1="33.28" y1="2433.3" x2="28.27" y2="2428.59"/>
          <line className="cls-15" x1="32.88" y1="2442.01" x2="28.35" y2="2445.31"/>
          <circle className="cls-15" cx="27.54" cy="2445.9" r="1"/>
          <circle className="cls-15" cx="41.54" cy="2449.9" r="1"/>
          <circle className="cls-12" cx="51.54" cy="2435.9" r="1"/>
          <circle className="cls-15" cx="36.04" cy="2436.4" r=".5"/>
          <circle className="cls-15" cx="39.04" cy="2440.4" r=".5"/>
          <path className="cls-15" d="m42.54,2425.9c-.12,0-.24-.02-.35-.07-.38-.14-.65-.5-.65-.93,0-.55.45-1,1-1s1,.45,1,1-.45,1-1,1Z"/>
        </g>
        <g id="Titel-5" data-name="Titel">
          <rect id="Achtergrond-6" data-name="Achtergrond" className="cls-57" x="58.99" y="2368.18" width="96.76" height="33" rx="2.5" ry="2.5"/>
          <text className="cls-56" transform="translate(84.44 2392.53)"><tspan x="0" y="0">Zorg</tspan></text>
        </g>
      </g>
    </>
)
}
